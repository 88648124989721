import { PropertyCompletionStatusType } from 'pf-frontend-common/src/module/stats/types';

export class PropertyStatsSnowplow2CompletionStatusAdapter {
  /**
   * Backend values to snowplow2 values mapping
   */
  private mapping: {
    [key: string]: PropertyCompletionStatusType;
  } = {
    off_plan: 'off_plan',
    'off plan': 'off_plan',
    completed: 'completed',
  };

  /**
   * Constructor
   */
  constructor(private completionStatus: string) {}

  /**
   * Get transformed data
   */
  public getData(): PropertyCompletionStatusType {
    return this.mapping[this.completionStatus] || null;
  }
}
