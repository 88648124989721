import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class UserAutoRegisterModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'user_auto_register';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserAutoRegisterModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserAutoRegisterModel> = ['first_name', 'last_name', 'email'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof UserAutoRegisterModel> = ['user'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['token'];

  // tslint:disable:variable-name

  /**
   * First name
   */
  public first_name: string = '';

  /**
   * Last name
   */
  public last_name: string = '';

  // tslint:enable:variable-name

  /**
   * Email
   */
  public email: string = '';

  /**
   * Phone
   */
  public phone: string = '';

  /**
   * User
   */
  public user: UserModel;

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Authorization token
     */
    token: string;

    /**
     * Refresh token
     */
    refresh_token: string;
  };
}
