import { CountryCodeEnum } from 'common/data/country/code.enum';
import { configGetCountry } from 'common/helper/config/get-country';

/**
 * Enable email verification feature for specific country
 */
export function emailVerificationEnabled(): boolean {
  return [CountryCodeEnum.bh, CountryCodeEnum.qa, CountryCodeEnum.eg, CountryCodeEnum.ae, CountryCodeEnum.sa].includes(
    configGetCountry().code
  );
}
