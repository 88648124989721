import { rangeSelectorGetValueAsNumber } from 'library/range-selector/get-value-as-number';
import { RangeSelectorValueInterface } from 'library/range-selector/value.interface';

/**
 * switch values and returns it if min is bigger than max or max is lower than min
 * @param value RangeSelectorValueInterface
 * @returns RangeSelectorValueInterface
 */
export const rangeSelectorGetSwitchedValues = (value: RangeSelectorValueInterface): RangeSelectorValueInterface => {
  const minValueInt = rangeSelectorGetValueAsNumber(value.min);
  const maxValueInt = rangeSelectorGetValueAsNumber(value.max);

  const haveAllValues = minValueInt !== undefined && maxValueInt !== undefined;
  const isSwitchNeeded = minValueInt > maxValueInt || maxValueInt < minValueInt;

  if (haveAllValues && isSwitchNeeded) {
    [value.min, value.max] = [value.max, value.min];
  }

  return value;
};
