import { CountryCodeEnum } from 'common/data/country/code.enum';

import { NewEverydayCardInterface } from './card.interface';

export const NewEverydayCardRent: Partial<Record<CountryCodeEnum, NewEverydayCardInterface[]>> = {
  [CountryCodeEnum.ae]: [
    {
      id: '1.50',
      neighborhood: 'marina',
      city: 'Dubai',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/marina.jpg'),
        webp: __('assets/common/new-everyday-images/ae/marina.webp'),
      },
    },
    {
      id: '1.41',
      neighborhood: 'downtown',
      city: 'Dubai',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/downtown.jpg'),
        webp: __('assets/common/new-everyday-images/ae/downtown.webp'),
      },
    },
    {
      id: '1.86',
      neighborhood: 'the-palm-jumeirah',
      city: 'Dubai',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/the_palm.jpg'),
        webp: __('assets/common/new-everyday-images/ae/the_palm.webp'),
      },
    },
    {
      id: '6.279',
      neighborhood: 'al-reem-island',
      city: 'Abu Dhabi',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/al_reem.jpg'),
        webp: __('assets/common/new-everyday-images/ae/al_reem.webp'),
      },
    },
    {
      id: '1.36',
      neighborhood: 'business-bay',
      city: 'Dubai',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/business_bay.jpg'),
        webp: __('assets/common/new-everyday-images/ae/business_bay.webp'),
      },
    },

    {
      id: '1.73',
      neighborhood: 'jumeirah-village-circle',
      city: 'Dubai',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/jvc.jpg'),
        webp: __('assets/common/new-everyday-images/ae/jvc.webp'),
      },
    },
    {
      id: '6.299',
      neighborhood: 'khalifa-city',
      city: 'Abu Dhabi',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/khalifa_city.jpg'),
        webp: __('assets/common/new-everyday-images/ae/khalifa_city.webp'),
      },
    },
    {
      id: '1.67',
      neighborhood: 'jbr',
      city: 'Dubai',
      src: {
        jpeg: __('assets/common/new-everyday-images/ae/jbr.jpg'),
        webp: __('assets/common/new-everyday-images/ae/jbr.webp'),
      },
    },
  ],
};
