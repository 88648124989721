import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class TransactionsModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'transactions';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = TransactionsModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof TransactionsModel> = ['date', 'price', 'size'];

  // tslint:disable:variable-name

  /**
   * Transaction date
   */
  public date: string = '';

  /**
   * Transaction price
   */
  public price: number;

  /**
   * Size of the apartment
   */
  public size: number;

  // tslint:enable:variable-name
}
