import { i18nTranslate } from 'common/helper/i18n/translate';

const alwaysVisibleChipsN = 1;

/**
 * Compute value for the inactive state of autocomplete component
 * @param props
 */
export const multiSelectionAutocompleteComputeInactiveRenderValue = <T extends unknown>(
  props: MultiSelectionAutocompleteComputeInactiveRenderValuePropsInterface<T>
) => {
  const renderValue: Array<MultiSelectionAutocompleteInactiveRenderValueInterface<T>> = [];

  for (let i = 0; i < alwaysVisibleChipsN; i++) {
    if (props.value[i]) {
      renderValue.push({ title: props.getChipTitle(props.value[i]), item: props.value[i] });
    }
  }

  if (props.value.length > alwaysVisibleChipsN) {
    renderValue.push({
      title: i18nTranslate('{N} more').replace('{N}', String(props.value.length - alwaysVisibleChipsN)),
      isMore: true,
    } as MultiSelectionAutocompleteInactiveRenderValueInterface<T>);
  }

  return renderValue;
};
