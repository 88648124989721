import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

import { UserAuthTracker } from './tracker';

const tracker = new UserAuthTracker(StatsTealiumProviderService(), UserAuthenticationService());

export function UserAuthTrackerFactory(): UserAuthTracker {
  return tracker;
}
