import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class PropertySavedModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'saved_property';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = PropertySavedModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof PropertySavedModel> = ['property_id', 'save_date'];

  // tslint:disable:variable-name

  /**
   * Property id
   */
  public property_id: number;

  /**
   * Save date
   */
  public save_date: string;

  // tslint:enable:variable-name
}
