import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { BrowserCookie } from 'common/module/browser/cookie';
import { BrowserCookieInterface } from 'common/module/browser/cookie.interface';

const browserCookieService = new BrowserCookie(WindowService());

export function BrowserCookieService(): BrowserCookieInterface {
  return browserCookieService;
}
