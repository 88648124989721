export const enum UserPopupLoginStatusEnum {
  /**
   * User is login
   */
  loggedIn = 'Logged In',

  /**
   * User is not log in
   */
  notLoggedIn = 'Not Logged In',
}
