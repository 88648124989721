import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class UserPhoneNumberLoginModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'phone_login_authenticate';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserPhoneNumberLoginModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserPhoneNumberLoginModel> = ['phone', 'otp'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof UserPhoneNumberLoginModel> = ['user'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['token', 'refresh_token'];

  public phone: string = '';

  public otp: string = '';

  public user: UserModel;

  public meta: {
    token: string;

    refresh_token: string;
  };
}
