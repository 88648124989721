import { GtmWindowInterface } from 'pf-frontend-common/src/module/gtm/window.interface';
import { StatsPlatformEnum } from 'pf-frontend-common/src/module/stats/platform.enum';
import { StatsDebuggerServiceInterface } from 'pf-frontend-common/src/service/stats-debugger/service.interface';
import { StatsGuardServiceInterface } from 'pf-frontend-common/src/service/stats-guard/service.interface';

import { StatsGtmDataLayerInterface } from 'common/module/stats/gtm/data-layer.interface';
import { StatsGtmProviderInterface } from 'common/module/stats/gtm/provider.interface';

export class StatsGtmProvider implements StatsGtmProviderInterface {
  /**
   * Constructor
   */
  constructor(
    private gtm: GtmWindowInterface,
    private statsDebugger: StatsDebuggerServiceInterface,
    private statsGuardService: StatsGuardServiceInterface
  ) {}

  /**
   * @inheritdoc
   */
  public send(data: StatsGtmDataLayerInterface | StatsGtmDataLayerInterface[]): void {
    this.statsDebugger.log(StatsPlatformEnum.gtm, 'gtm', data);

    // Guard
    if (this.statsGuardService.isAuthorized() === false) {
      return;
    }

    if (Array.isArray(data)) {
      data.map((item) => this.gtm.dataLayer.push(item));

      return;
    }

    this.gtm.dataLayer.push(data);
  }
}
