import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { LeadEnquiryTypeEnum } from 'common/data/lead/enquiry-type.enum';
import { LeadModelInterface } from 'common/data/lead/model.interface';

export class BrokerLeadModel extends JsonApiModel implements LeadModelInterface {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'broker_lead';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = BrokerLeadModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof BrokerLeadModel> = [
    'name',
    'message',
    'email',
    'phone',
    'enquiry_type',
    'captcha_token',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof BrokerLeadModel> = [];

  // tslint:disable:variable-name

  /**
   * Lead author name
   */
  public name: string = '';

  /**
   * Message
   */
  public message: string = '';

  /**
   * Email
   */
  public email: string = '';

  /**
   * Phone
   */
  public phone: string = '';

  /**
   * Enquiry type
   */
  public enquiry_type: LeadEnquiryTypeEnum = LeadEnquiryTypeEnum.none;

  /**
   * Accept terms and conditions
   */
  public acceptTerms: boolean = false;

  /**
   * Accept receive advertising
   */
  public receiveAdvertising: boolean = false;

  /**
   * Captcha verification token
   */
  public captcha_token?: string;

  // tslint:enable:variable-name
}
