import { FunctionComponent, h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { BedAndBathSelectorTemplatePropsInterface } from 'common/module/bed-and-bath-selector/template-props.interface';
import { DropdownWrapperComponent } from 'common/module/dropdown-wrapper/component';
import { DropdownComponent } from 'library/dropdown/component';
import { DropdownSizeEnum } from 'library/dropdown/size.enum';
import { DropdownVariantEnum } from 'library/dropdown/variant.enum';

import { FilterParamsEnum } from '../filter/params.enum';
import { BedAndBathSelectorFieldTemplate } from './field/template';
import { bedAndBathSelectorLabelCompute } from './label/compute';
import { bedAndBathSelectorLabelMerge } from './label/merge';

export const BedAndBathSelectorTemplate: FunctionComponent<BedAndBathSelectorTemplatePropsInterface> = ({
  options,
  isOpen,
  onChange,
  onDropdownOpenStatusChange,
  style,
  className,
  dropdownSize = DropdownSizeEnum.normal,
}) => {
  const bedroomCheckedOptions = options.bedrooms.filter((item) => item.checked).sort((a, b) => a.num - b.num);
  const bathroomCheckedOptions = options.bathrooms.filter((item) => item.checked).sort((a, b) => a.num - b.num);

  const haveDefaultValues = bedroomCheckedOptions.length || bathroomCheckedOptions.length;

  return (
    <DropdownComponent
      isOpen={isOpen}
      className={className}
      text={bedAndBathSelectorLabelMerge(
        bedAndBathSelectorLabelCompute(bedroomCheckedOptions, 'Beds'),
        bedAndBathSelectorLabelCompute(bathroomCheckedOptions, 'Baths')
      )}
      label={i18nTranslate('Beds & Baths')}
      variant={DropdownVariantEnum.primary}
      onChangeOpen={onDropdownOpenStatusChange}
      style={style}
      size={dropdownSize}
    >
      <DropdownWrapperComponent
        showActions
        wrapperClassName='bed-and-bath-selector__wrapper'
        actions={
          haveDefaultValues
            ? [
                <button
                  className='bed-and-bath-selector__clear-button'
                  type='button'
                  onClick={() => {
                    onChange({
                      [FilterParamsEnum.bedrooms]: { value: [], choices: [] },
                      [FilterParamsEnum.bathrooms]: { value: [], choices: [] },
                    });
                  }}
                >
                  {i18nTranslate('Reset')}
                </button>,
              ]
            : undefined
        }
      >
        <BedAndBathSelectorFieldTemplate
          title={i18nTranslate('Bedrooms')}
          name='bedrooms'
          options={options.bedrooms}
          onChange={(selected) =>
            onChange({ [FilterParamsEnum.bedrooms]: { value: selected.map((item) => item.value), choices: [] } })
          }
        />
        <BedAndBathSelectorFieldTemplate
          title={i18nTranslate('Bathrooms')}
          name='bathrooms'
          options={options.bathrooms}
          onChange={(selected) =>
            onChange({ [FilterParamsEnum.bathrooms]: { value: selected.map((item) => item.value), choices: [] } })
          }
        />
      </DropdownWrapperComponent>
    </DropdownComponent>
  );
};
