import { KeywordsByCategory } from 'common/data/keywords/by-category';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { stringMakeCaseInsensetiveTester } from 'common/helper/string/make-insensitive-tester';

const MAX_NUMBER_OF_KEYWORDS = 8;

export const keywordsMakeQueryForInputValue = (category: PropertyCategoryIdentifierEnum) => (inputValue: string) => {
  const searchResults = [];
  const matchesInputValueCaseInsensitive = stringMakeCaseInsensetiveTester(inputValue);

  for (const keyword of KeywordsByCategory[category]) {
    if (matchesInputValueCaseInsensitive(keyword)) {
      searchResults.push(keyword);
      if (searchResults.length === MAX_NUMBER_OF_KEYWORDS) {
        break;
      }
    }
  }

  return Promise.resolve(searchResults);
};
