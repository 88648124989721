export enum SaveSearchPayloadFilterKeysEnum {
  CATEGORY_ID = 'category_id',
  PROPERTY_TYPE_ID = 'property_type_id',
  MIN_PRICE = 'min_price',
  MAX_PRICE = 'max_price',
  MIN_AREA = 'min_area',
  MAX_AREA = 'max_area',
  PRICE_TYPE = 'price_type',
  LOCATION_IDS = 'location_ids',
  QUERY = 'query',
  KEYWORD = 'keyword',
  AMENITIES = 'amenities',
  BEDROOMS = 'number_of_bedrooms',
  BATHROOMS = 'number_of_bathrooms',
  FURNISHED = 'furnished',
  COMPLETION_STATUS = 'completion_status',
  VIRTUAL_VIEWING = 'virtual_viewing',
  IS_DEVELOPER_PROPERTY = 'is_developer_property',
  MIN_INSTALLMENT_YEARS = 'min_installment_years',
  MAX_INSTALLMENT_YEARS = 'max_installment_years',
  LISTED_WITHIN = 'listed_within',
  PAYMENT_METHODS = 'payment_methods',
}
