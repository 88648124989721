import { configGetLanguage } from 'common/helper/config/get-language';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';

export class ApiEndpoint implements ApiEndpointServiceInterface {
  /**
   * Api path
   */
  public static API_PATH: string = '/api';

  /**
   * Manager api path
   */
  public static MANAGER_API_PATH: string = '/manager/manager-api';

  /**
   * @inheritDoc
   */
  public getPath(uri: string): string {
    return `/${configGetLanguage().current + ApiEndpoint.API_PATH + uri}`;
  }

  /**
   * @inheritDoc
   */
  public getManagerPath(uri: string): string {
    return ApiEndpoint.MANAGER_API_PATH + uri;
  }
}
