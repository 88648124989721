import { DataKeyValueStringInterface } from 'pf-frontend-common/dist/module/data/key-value/string.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/dist/module/http/api-response.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { FilterSettingsModel } from 'common/data/filter-settings/model';
import { FilterSettingsParametersInterface } from 'common/data/filter-settings/parameters.interface';
import { JsonApiSyncedInterface } from 'common/helper/json-api/synced.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';

export class FilterSettingsStore {
  /**
   * API endpoints to connect with the backend
   */
  // TODO[TPNX-1083] - remove the below comment
  // @ts-ignore
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    fetch: {
      path: this.apiEndpointService.getPath('/v3/form-settings'),
      method: 'GET',
    },
  };

  /**
   * Constructor
   */
  public constructor(
    // TODO[TPNX-1083] - remove the below comment
    // @ts-ignore
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private jsonApiStore: JsonApiStore<FilterSettingsModel>
  ) {}

  /**
   * Fetch cache checksum
   *
   */
  public fetchChecksum(params?: Partial<FilterSettingsParametersInterface>): Promise<{ etag: string }> {
    return this.apiService
      .request('HEAD', this.endpoint.fetch.path, params)
      .then(({ headers: { etag } }) => ({ etag }));
  }

  public fetch(
    params?: Partial<FilterSettingsParametersInterface>
  ): Promise<JsonApiSyncedInterface<FilterSettingsModel>> {
    return this.apiService
      .request(this.endpoint.fetch.method, this.endpoint.fetch.path, params)
      .then(this.transformResponse);
  }

  /**
   * Return the synced data
   */
  private transformResponse = (response: HttpApiResponseInterface): JsonApiSyncedInterface<FilterSettingsModel> => {
    return {
      ...this.jsonApiStore.syncWithMeta(response.data),
      headers: response.headers,
    };
  };
}
