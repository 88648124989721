import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { BrokerModel } from 'common/data/broker/model';

export class AgentModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'agent';

  /**
   * @inheritDoc
   */
  public static readonly VERIFICATION_STATUS_VERFIED: string = 'verified';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = AgentModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof AgentModel> = [
    'id',
    'name',
    'email',
    'phone',
    'phone_is_mobile',
    'phone_did',
    'position',
    'nationality',
    'ranking',
    'languages',
    'country_name',
    'linkedin_address',
    'are_transactions_visible',
    'agent_broker_license_no',
    'transactions_count',
    'total_properties',
    'properties_residential_for_rent_count',
    'properties_residential_for_sale_count',
    'properties_commercial_count',
    'user_id',
    'listing_level',
    'smart_ad',
    'verification_status',
    'years_of_experience',
    'whatsapp_response_time_readable',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof AgentModel> = ['broker'];

  /**
   * @inheritDoc
   */
  public jsonApiLinks: string[] = ['profile', 'image_desktop'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['broker_name', 'broker_id'];

  // tslint:disable:variable-name

  /**
   * @inheritDoc
   */
  public meta: {
    broker_name: string;
    broker_id: string;
  };

  /**
   * ID
   */
  public id: string;

  /**
   * Name
   */
  public name: string;

  /**
   * Email
   */
  public email: string;

  /**
   * Phone
   */
  public phone: string;

  /**
   * Is mobile public phone ?
   */
  public phone_is_mobile: boolean;

  /**
   * Phone number assigned by PF (to track calls) - should be used instead of publicPhone
   */
  public phone_did?: string;

  /**
   * Job position
   */
  public position: string;

  /**
   * Nationality
   */
  public nationality: string;

  /**
   * Ranking
   */
  public ranking: number;

  /**
   * Broker
   */
  public broker?: BrokerModel;

  /**
   * Languages
   */
  public languages?: Array<{
    name: string;
  }>;

  /**
   * Additional links
   */
  public links: {
    /**
     * Profile URL
     */
    profile: string;

    /**
     * Image URL
     */
    image_desktop: string;
  };

  /**
   * Country name
   */
  public country_name: string;

  /**
   * LinkedIn URL
   *
   */
  public linkedin_address: string;

  /**
   * Are agent's transactions visible
   */
  public are_transactions_visible: boolean;

  /**
   * License number
   */
  public agent_broker_license_no: string;

  /**
   * Total number of transactions in last 6 months
   */
  public transactions_count: number;

  /**
   * Total number of properties
   */
  public total_properties: number;

  /**
   * Total number of properties residential for rent
   */
  public properties_residential_for_rent_count: number;

  /**
   * Total number of properties residential for sale
   */
  public properties_residential_for_sale_count: number;

  /**
   * Total number of commercial properties
   */
  public properties_commercial_count: number;

  /**
   * User id
   */
  public user_id: number;

  /**
   * Listing level
   */
  public listing_level: string;

  /**
   * Whether is smart ad
   */
  public smart_ad: boolean;

  /**
   * Verification status
   */
  public verification_status: string;

  /**
   * Years of experience
   */
  public years_of_experience: number;

  /**
   *
   */
  public is_trusted: boolean;

  /**
   * Whatsapp response time message (ex.; within 2 hours)
   */
  public whatsapp_response_time_readable: boolean;

  /**
   * Returns public phone (DID or public phone)
   */
  get public_phone(): string {
    return this.phone_did ? this.phone_did : this.phone;
  }

  /**
   * Returns the public image large size, if not defined falls back to a placeholder image
   */
  get public_image_large(): string {
    return this.links.image_desktop || __('assets/common/agent-tile-placeholder.jpg');
  }

  /**
   * Returns the public list of languages as one string
   */
  get public_languages(): string {
    return this.languages.map((language) => language.name).join(', ');
  }

  // tslint:enable:variable-name

  /**
   * Set the smart ad status of the model
   */
  public setSmartAd(isSmartAd: boolean): void {
    this.smart_ad = isSmartAd;
  }

  /**
   * Check if agent is verified
   */
  public isVerified(): boolean {
    return this.verification_status === AgentModel.VERIFICATION_STATUS_VERFIED;
  }
}
