import { FunctionalComponent, h } from 'preact';

import { stringToggle } from 'common/helper/string/toggle';

import { ChipComponent } from '../chip/component';
import { ChipChoicePropsInterface } from './props.interface';

export const ChipChoiceTemplate: FunctionalComponent<ChipChoicePropsInterface> = (props) => (
  <ul className={props.containerClassName || ''}>
    {props.options.map((option, id) => (
      <li className={`chip-choice__item ${stringToggle(props.className)(!!props.className)}`}>
        <input
          key={`${props.name}-${id}`}
          type='radio'
          autoComplete='off'
          disabled={option.isDisabled}
          id={option.value}
          value={option.value}
          name={props.name}
          checked={props.selected === option.value}
          className='chip-choice__item-input'
        />
        <ChipComponent
          label={option.text}
          className={props.chipClassName}
          htmlFor={option.value}
          isSelected={props.selected === option.value}
          isDisabled={option.isDisabled}
          onClick={(e) => {
            props.onCheck(e, option);
          }}
        />
      </li>
    ))}
  </ul>
);
