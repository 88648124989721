import { Component, h } from 'preact';

import { ChipPropsInterface } from './props.interface';
import { ChipTemplate } from './template';

/**
 * Chips are compact elements that represent an input, attribute, or action.
 *
 * Design: https://www.figma.com/file/gRARY1Vi4W2Ow1vRLw02am/PF_Consumer-Web-Kit?node-id=14%3A550
 */
export class ChipComponent extends Component<ChipPropsInterface> {
  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    const Template = this.props.template || ChipTemplate;

    return <Template {...this.props} />;
  }
}
