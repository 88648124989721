export enum AutocompleteStoreEvent {
  searchLocationsStart = 'search-locations-start',
  searchLocationsReset = 'search-locations-reset',
  searchLocationsSuccess = 'search-locations-success',
  searchAgentsStart = 'search-agents-start',
  searchAgentsReset = 'search-agents-reset',
  searchAgentsSuccess = 'search-agents-success',
  searchBrokersReset = 'search-brokers-reset',
  searchBrokersStart = 'search-brokers-start',
  searchBrokersSuccess = 'search-brokers-success',
}
