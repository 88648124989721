import { WindowService } from 'pf-frontend-common/src/service/window/service';

/**
 * Debounces execution of a <callback> to <wait> ms
 *
 * Normal event
 * event      | |      |
 * time     ----------------
 * callback   | |      |
 *
 * Call log only when it's been 100ms since the last sroll
 * event     | |      |
 * time     ----------------
 * callback         |      |
 *              |100|  |100|
 *
 * @example
 *
 * ```javascript
 * const debounced = asyncDebounce(() => {
 *  alert('A');
 * }, 100)
 * ```
 * will alert A after 100 miliseconds
 *
 * @param callback - function which would be debounced
 * @param wait - debounce time in ms
 * @param immediate - if true then will execute first call instead of last one
 */
export function asyncDebounce<A>(
  callback: (...args: A[]) => void,
  wait: number,
  immediate: boolean = false
): (...args: A[]) => void {
  let timeout: number = null;

  return (...args: A[]) => {
    clearTimeout(timeout);

    // Window is needed to use browser setTimeout typing instead of nodejs
    timeout = WindowService()
      .getNative()
      .setTimeout(() => {
        timeout = null;

        if (!immediate) {
          // Run last callback
          callback(...args);
        }
      }, wait);

    if (immediate && !timeout) {
      // Run fist callback
      callback(...args);
    }
  };
}
