import { WindowServiceInterface } from 'pf-frontend-common/dist/service/window/service.interface';

import { BrowserCookieInterface } from 'common/module/browser/cookie.interface';

/**
 * @inheritDoc
 */
export class BrowserCookie implements BrowserCookieInterface {
  /**
   * Constructor
   */
  constructor(private windowService: WindowServiceInterface) {}

  /**
   * Set cookie
   */
  public setData(name: string, value: number | string, expireDays?: number, path?: string): void {
    const date: Date = new Date();
    date.setTime(date.getTime() + expireDays * 24 * 60 * 60 * 1000);

    const expires: string = expireDays ? `expires=${date.toUTCString()}` : '';
    const cPath: string = path ? `; path=${path}` : '';

    this.windowService.getNative().document.cookie = `${name}=${value}; ${expires}${cPath}`;
  }

  /**
   * Get cookie by name
   */
  public getData(name: string): null | string {
    const cookies: string[] = this.windowService.getNative().document.cookie.split(';');
    const cookieName = `${name}=`;
    let c: string;

    for (const cookie of cookies) {
      c = cookie.replace(/^\s+/g, '');
      if (c.indexOf(cookieName) === 0) {
        return c.substring(cookieName.length, c.length);
      }
    }
    return '';
  }

  /**
   * Remove cookie
   */
  public removeData(name: string, path?: string): void {
    path = path ? `path=${path}` : '';

    this.windowService.getNative().document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;${path}`;
  }

  /**
   * @inheritDoc
   */
  public setStorage(): void {
    return;
  }
}
