import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';

import { configGetJsonApi } from 'common/helper/config/get-json-api';

/**
 * Usage example: JsonApiStoreFactory<AgentModel>(AgentModel.JSONAPI_TYPE);
 *
 * @param jsonApiType - Primary JsonApiModel's resource type handled by this store, ex: 'property', 'location', ...
 */
export function JsonApiStoreFactory<Model extends JsonApiModel>(jsonApiType: string): JsonApiStore<Model> {
  const jsonApiConfig = configGetJsonApi();

  return new JsonApiStore<Model>(jsonApiConfig.mapping, jsonApiType);
}
