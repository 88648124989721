import { domClassToggle } from 'pf-frontend-common/src/helper/dom/class-toggle';
import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';
import { DomElementEvent } from 'pf-frontend-common/src/module/dom/element.event';
import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { ViewOptionsInterface } from 'pf-frontend-common/src/module/view/options.interface';

import { PropertySavedStoreEvent } from 'common/data/property-saved/store.event';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { settingsGetPageType } from 'common/helper/settings/get-page-type';
import { HeaderSavedPropertiesBundleSettingsInterface } from 'common/module/header-saved-properties/bundle-settings.interface';
import { HeaderSavedPropertiesCssClassEnum } from 'common/module/header-saved-properties/css-class.enum';
import { HeaderSavedPropertiesCssSelectorEnum } from 'common/module/header-saved-properties/css-selector.enum';
import { HeaderSavedPropertiesHtmlElementInterface } from 'common/module/header-saved-properties/html-element.interface';
import { HeaderSavedPropertiesStatsGtmClick } from 'common/module/header-saved-properties/stats/gtm/click';
import { StatsGtmProviderInterface } from 'common/module/stats/gtm/provider.interface';
import { PropertySavedStoreServiceInterface } from 'common/service/property-saved-store/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

import { UserAuthenticationEvent } from '../user/authentication.event';

export class HeaderSavedPropertiesView implements ViewBasicInterface {
  /**
   * Max count of properties for which counter should be shown
   */
  private readonly maxCount: number = 9;

  /**
   * View's main HTML element
   */
  private element: HTMLElement;

  /**
   * HTML elements
   */
  private htmlElement: HeaderSavedPropertiesHtmlElementInterface;

  /**
   * Constructor
   */
  constructor(
    private propertySavedStoreService: PropertySavedStoreServiceInterface,
    private statsGtm: StatsGtmProviderInterface,
    private settings: HeaderSavedPropertiesBundleSettingsInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: ViewOptionsInterface): void {
    // Keep element
    this.element = options.element;

    // Update HTML elements
    this.htmlElement = {
      button: domQuerySelector(this.element, HeaderSavedPropertiesCssSelectorEnum.button),
      counter: domQuerySelector(this.element, HeaderSavedPropertiesCssSelectorEnum.counter),
      notification: domQuerySelector(this.element, HeaderSavedPropertiesCssSelectorEnum.notification),
      label: domQuerySelector(this.element, HeaderSavedPropertiesCssSelectorEnum.label),
    };

    // Attach observers
    this.observers();
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    // Update label
    this.setLabel();

    // Update count
    this.setCount(this.propertySavedStoreService.getAllFromCache().length);

    // Update visibility
    this.setVisibility();
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.htmlElement.button.addEventListener(DomElementEvent.click, this.onClickElement);

    // Saved properties store
    this.propertySavedStoreService
      .getEventEmitter()
      .addListener(PropertySavedStoreEvent.sync, this.onSyncPropertySavedStore);

    // User authentication service
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSucceededUserAuthentication);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onRegistrationSucceededUserAuthentication);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.logOutSucceeded, this.onLogOutSucceeded);
  }

  /**
   * Set count value displayed in icon
   */
  private setCount(count: number): void {
    // Update count HTML
    this.htmlElement.counter.innerHTML = count ? String(count) : '';

    // Update counter visibility
    domClassToggle(
      this.htmlElement.counter,
      HeaderSavedPropertiesCssClassEnum.counterHidden,
      !count || count > this.maxCount
    );
    domClassToggle(
      this.htmlElement.notification,
      HeaderSavedPropertiesCssClassEnum.notificationHidden,
      !count || count <= this.maxCount
    );
  }

  /**
   * Set label displayed underneath icon
   */
  private setLabel(): void {
    if (this.htmlElement.label) {
      const isLoggedIn = this.userAuthenticationService && !!this.userAuthenticationService.getToken();
      // Update label text
      this.htmlElement.label.innerHTML = !isLoggedIn ? i18nTranslate('Saved') : '';
      // Update button element class
      domClassToggle(this.htmlElement.button, HeaderSavedPropertiesCssClassEnum.withIconLabel, !isLoggedIn);
      // update counter element class
      domClassToggle(this.htmlElement.counter, HeaderSavedPropertiesCssClassEnum.counterWithIconLabel, !isLoggedIn);
    }
  }

  /**
   * Set visibility classes of this element
   */
  private setVisibility(): void {
    const isLoggedIn =
      (this.userAuthenticationService && !!this.userAuthenticationService.getToken()) ||
      this.settings.isHeaderSavedPropertiesAlwaysVisible;
    domClassToggle(this.element, HeaderSavedPropertiesCssClassEnum.invisible, false);
    domClassToggle(this.element, HeaderSavedPropertiesCssClassEnum.loggedIn, isLoggedIn);
    domClassToggle(this.element, HeaderSavedPropertiesCssClassEnum.loggedOut, !isLoggedIn);
  }

  /**
   * Synced saved properties store
   */
  private onSyncPropertySavedStore = (propertyIds: string[]): void => {
    this.setCount(propertyIds.length);
  };

  /**
   * Sign in succeeded
   */
  private onSignInSucceededUserAuthentication = (): void => {
    this.setLabel();
    this.setVisibility();
  };

  /**
   * Registration in succeeded
   */
  private onRegistrationSucceededUserAuthentication = (): void => {
    this.setLabel();
    this.setVisibility();
  };

  /**
   * User logged out
   */
  private onLogOutSucceeded = (): void => {
    this.setLabel();
    this.setVisibility();
  };

  /**
   * Handle click on element
   */
  private onClickElement = () => {
    this.statsGtm.send(HeaderSavedPropertiesStatsGtmClick(settingsGetPageType(this.settings)));
  };
}
