import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { CountryCodeEnum } from 'common/data/country/code.enum';
import { configGetCountry } from 'common/helper/config/get-country';
import { configGetSettings } from 'common/helper/config/get-settings';
import { FilterSearchUrlAdapterFactory } from 'common/module/filter/search/url/adapter.factory';
import { FilterService } from 'common/service/filter/service';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';
import { HomePageBundleSettingsInterface } from 'desktop/entry/home-page/bundle/settings.interface';

import { HomePageSearchFormComponentFactory } from './component.factory';

const bundleSettings = configGetSettings<HomePageBundleSettingsInterface>();

export const HomePageSearchFormComponent = HomePageSearchFormComponentFactory({
  countryCode: configGetCountry().code,
  searchUri:
    configGetCountry().code === CountryCodeEnum.sa
      ? bundleSettings?.search?.uri.replace('/ar/', '/')
      : bundleSettings?.search?.uri,
  filterStore: FilterService(),
  gtmProvider: StatsGtmProviderService(),
  filterSearchUrlAdapter: FilterSearchUrlAdapterFactory(),
  WindowService: WindowService(),
});
