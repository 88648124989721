import { RefObject } from 'preact';

import { numberFormat } from 'common/helper/number/format';
import { stringRemoveCharByIndex } from 'common/helper/string/remove-char-by-index';
import { rangeSelectorFieldFormatLabel } from 'library/range-selector/field/format-label';
import { rangeSelectorGetValueAsNumber } from 'library/range-selector/get-value-as-number';

export const rangeSelectorFieldComputeLabel = (previousValue: string, positionRef: RefObject<number>) => {
  let memoizedValue = previousValue;
  let memoizedCommaCount = 0;
  return (currentValue: string, caretPosition: number): string => {
    const isCurrentValueDirty: boolean = !!currentValue.replace(/,/g, '').match(/\D+/g)?.length;
    let inputNumericInt = rangeSelectorGetValueAsNumber(currentValue);
    let inputNumberStr = inputNumericInt?.toString() || '';

    if (isCurrentValueDirty) {
      return rangeSelectorFieldFormatLabel(inputNumberStr);
    }

    const memoizedNumberStr = memoizedValue.replace(/\D+/g, '');
    const memoizedValueInt = parseInt(memoizedNumberStr, 10);
    let inputValueWithComma = numberFormat(inputNumericInt);
    const commaCountOnLeft = inputValueWithComma.slice(0, caretPosition).match(/,/g)?.length || 0;

    positionRef.current = caretPosition;

    if (inputNumberStr && inputNumericInt === memoizedValueInt) {
      // comma is removed
      positionRef.current -= commaCountOnLeft;
      inputNumberStr = stringRemoveCharByIndex(inputNumberStr, positionRef.current - 1);
      inputNumericInt = parseInt(inputNumberStr, 10);
      inputValueWithComma = rangeSelectorFieldFormatLabel(inputNumberStr);
      memoizedValue = inputNumberStr;

      return inputValueWithComma;
    }

    if (commaCountOnLeft !== memoizedCommaCount) {
      positionRef.current += commaCountOnLeft > memoizedCommaCount ? 1 : -1;
    }

    memoizedValue = inputNumberStr;
    memoizedCommaCount = commaCountOnLeft;

    return rangeSelectorFieldFormatLabel(inputNumberStr);
  };
};
