import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class UserRefreshTokenModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'user_refresh_token';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserRefreshTokenModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserRefreshTokenModel> = ['value'];

  /**
   * Token value
   */
  public value: string = '';
}
