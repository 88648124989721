import { Component, createRef, h, RefObject } from 'preact';

import { functionNoop } from 'common/helper/function/noop';
import { functionSelf } from 'common/helper/function/self';
import { objectCompare } from 'common/helper/object/compare';
import { RangeSelectorComponentPropsInterface } from 'library/range-selector/component-props.interface';
import { RangeSelectorEditTypeEnum } from 'library/range-selector/edit-type.enum';
import { RangeSelectorFieldsEnum } from 'library/range-selector/fields.enum';
import { rangeSelectorGetSwitchedValues } from 'library/range-selector/get-switched-values';
import { RangeSelectorStateInterface } from 'library/range-selector/state.interface';
import { RangeSelectorTemplate } from 'library/range-selector/template';

// TODO-FE[TPNX-2387] Reuse RangeSelectorInput
export class RangeSelectorComponent extends Component<
  RangeSelectorComponentPropsInterface,
  RangeSelectorStateInterface
> {
  /**
   * @inheritDoc
   */
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    rtl: false,
    showClear: false,
    onChange: () => null as RangeSelectorComponentPropsInterface['onChange'],
    onClear: () => null as RangeSelectorComponentPropsInterface['onClear'],
    mapToLabel: functionSelf,
    onDropdownOpenStatusChange: functionNoop,
    onInputChange: functionNoop,
  };

  /**
   * initial state
   */
  public readonly state: RangeSelectorStateInterface = {
    listOpenedOn: null,
    isDropdownOpen: false,
  };

  /**
   * ref for min field to listen mouse event globally
   */
  private readonly minRef: RefObject<HTMLDivElement> = createRef();

  /**
   * ref for max field to listen mouse event globally
   */
  private readonly maxRef: RefObject<HTMLDivElement> = createRef();

  /**
   * ref for current caret position
   */
  private readonly caretPositionRef: RefObject<number> = createRef();

  /**
   * mapping for fields and DOMs
   */
  private readonly mappingFieldsRef: {
    [field in RangeSelectorFieldsEnum]: RefObject<HTMLDivElement>;
  } = {
    [RangeSelectorFieldsEnum.min]: this.minRef,
    [RangeSelectorFieldsEnum.max]: this.maxRef,
  };

  /**
   * focused input field
   */
  private inputFocusOn: RangeSelectorFieldsEnum = null;

  /**
   * @inheritdoc
   */
  public componentDidUpdate(prevProps: RangeSelectorComponentPropsInterface): void {
    if (!objectCompare(prevProps.value, this.props.value) && !!this.caretPositionRef.current && this.inputFocusOn) {
      const { current: cursorIndex } = this.caretPositionRef;
      this.mappingFieldsRef[this.inputFocusOn].current
        .querySelector('input')
        .setSelectionRange(cursorIndex, cursorIndex);
    }
  }

  /**
   * @inheritdoc
   */
  public render(): preact.JSX.Element {
    const { isDropdownOpen, listOpenedOn } = this.state;
    const { value, children, rtl, mapToLabel, inputContainerClassName } = this.props;
    const options = this.props.options.map((option) => option.toString());

    return (
      <RangeSelectorTemplate
        rtl={rtl}
        inputContainerClassName={inputContainerClassName}
        isDropdownOpen={isDropdownOpen}
        value={value}
        label={this.props.label}
        text={this.props.text}
        listOpenedOn={listOpenedOn}
        minRef={this.minRef}
        minPlaceholder={this.props.min.placeholder}
        options={options}
        maxRef={this.maxRef}
        maxPlaceholder={this.props.max.placeholder}
        mapToLabel={mapToLabel}
        showClear={this.props.showClear}
        onDropdownOpenChanged={this.onDropdownOpenChanged}
        onOptionSelect={this.onSelectOption}
        onInputBlur={this.onInputBlur}
        onInputFocus={this.onInputFocus}
        onClear={this.onClearInputs}
        onInputClick={this.onInputClick}
        onInputChange={this.onInputChange}
        caretPositionRef={this.caretPositionRef}
        dropdownSize={this.props.dropdownSize}
      >
        {children}
      </RangeSelectorTemplate>
    );
  }

  /**
   * handle input event on range-selection input
   * @param field RangeSelectorFieldsEnum
   */
  private onInputChange = (field: RangeSelectorFieldsEnum) => (value: string) => {
    this.props.onChange(
      {
        ...this.props.value,
        [field]: value,
      },
      this.state.editType
    );
  };

  /**
   * handle main dropdown changes
   * @param isOpened boolean
   */
  private onDropdownOpenChanged = (isOpened: boolean) => {
    this.setState({ isDropdownOpen: isOpened }, () => {
      this.props.onDropdownOpenStatusChange(isOpened);
    });
  };

  /**
   * handle focus event on range-selection input
   * @param field RangeSelectorFieldsEnum
   */
  private onInputFocus = (field: RangeSelectorFieldsEnum) => () => {
    this.setState(
      {
        editType: RangeSelectorEditTypeEnum.text,
        listOpenedOn: field,
      },
      this.focusOnInputField(field)
    );
  };

  /**
   * handle blur event on range-selection input
   * @param field RangeSelectorFieldsEnum
   */
  private onInputBlur = (e: Event) => {
    this.setState({ listOpenedOn: null }, () => {
      const value = rangeSelectorGetSwitchedValues({
        ...this.props.value,
        [this.inputFocusOn]: (e.target as HTMLInputElement).value,
      });

      this.props.onChange(value, RangeSelectorEditTypeEnum.text);
      this.inputFocusOn = null;
    });
  };

  /**
   * focuses the input of the active range-selector field
   * @param field RangeSelectorFieldsEnum
   */
  private focusOnInputField = (field: RangeSelectorFieldsEnum) => () => {
    this.setState({ listOpenedOn: field }, () => {
      if (this.state.listOpenedOn) {
        this.inputFocusOn = field;
        this.mappingFieldsRef[field].current.querySelector('input').focus();
      }
    });
  };

  /**
   * handle select event on range-selection field
   * @param selectedValue selected option value
   */
  private onSelectOption = (selectedValue: string) => {
    const { listOpenedOn } = this.state;
    this.inputFocusOn = listOpenedOn;
    this.setState(
      {
        editType: RangeSelectorEditTypeEnum.select,
        listOpenedOn: null,
      },
      () => {
        const value = {
          ...this.props.value,
          [listOpenedOn]: selectedValue.replace(/,/g, ''),
        };
        this.caretPositionRef.current = null;
        this.props.onChange(value, this.state.editType);
      }
    );
  };

  /**
   * @description reopens choices dropdown if closed on clicked field
   * @param field RangeSelectorFieldsEnum
   */
  private onInputClick = (field: RangeSelectorFieldsEnum): void => {
    if (!this.state.listOpenedOn) {
      this.setState({ listOpenedOn: field });
    }
  };

  /**
   * clears the values and close the opened list
   */
  private onClearInputs = () => {
    this.setState({ listOpenedOn: null }, this.props.onClear);
  };
}
