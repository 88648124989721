import { CountryCodeEnum } from 'common/data/country/code.enum';
import { configGetCountry } from 'common/helper/config/get-country';

export const agentHubGetLink = (): string => {
  let link;

  switch (configGetCountry().code) {
    case CountryCodeEnum.ae:
      link = 'https://www.propertyfinder.ae/hub/register/';
      break;
    case CountryCodeEnum.eg:
      link = 'https://www.propertyfinder.eg/hub';
      break;
  }

  return link;
};
