import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { BrowserStorageLocalService } from 'pf-frontend-common/src/service/browser-storage-local/service';
import { StatsContexterService } from 'pf-frontend-common/src/service/stats-contexter/service';
import { StatsDataService } from 'pf-frontend-common/src/service/stats-data/service';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { UserStatsDataAdapterFactory } from 'common/data/user/stats/data-adapter.factory';
import { UserAuthentication } from 'common/module/user/authentication';
import { JwtTokenService } from 'common/service/jwt-token/service';
import { UserAuthenticationStoreService } from 'common/service/user-authentication-store/service';
import { UserFrontendLoginService } from 'common/service/user-frontend-login/service';

export function UserAuthenticationFactory(): UserAuthentication {
  return new UserAuthentication(
    EventEmitterFactory(),
    UserAuthenticationStoreService(),
    BrowserStorageLocalService(),
    UserFrontendLoginService(),
    JwtTokenService(),
    StatsContexterService(),
    UserStatsDataAdapterFactory(),
    StatsDataService(),
    WindowService()
  );
}
