import { h, RefObject } from 'preact';
import { forwardRef, memo } from 'preact/compat';

import { numberFormat } from 'common/helper/number/format';
import { DropdownListComponent } from 'library/dropdown-list/component';
import { rangeSelectorFieldComputeLabel } from 'library/range-selector/field/compute-label';
import { rangeSelectorFieldFormatLabel } from 'library/range-selector/field/format-label';
import { TextFieldComponent } from 'library/text-field/component';

import { RangeSelectorInputFieldTemplateProps } from './template-props.interface';

export const RangeSelectorInputFieldTemplate = memo(
  forwardRef(
    (
      {
        value,
        placeholder,
        isListOpened,
        inputContainerClassName,
        options,
        onInputBlur,
        onOptionSelect,
        mapToLabel,
        onInputFocus,
        onInputClick,
        onInputChange,
        caretPositionRef,
      }: RangeSelectorInputFieldTemplateProps,
      ref: RefObject<HTMLDivElement>
    ) => {
      return (
        <div className='range-selector-input__container' ref={ref}>
          <div className='range-selector-input__wrapper'>
            <div className={inputContainerClassName ? inputContainerClassName : ''}>
              <TextFieldComponent
                placeholder={placeholder}
                containerClassName='range-selector-input__input-container'
                inputClassName='range-selector-input__input'
                value={rangeSelectorFieldFormatLabel(value)}
                onValueChange={onInputChange}
                onFocus={onInputFocus}
                onBlur={onInputBlur}
                onBeforeChange={rangeSelectorFieldComputeLabel(value, caretPositionRef)}
                onClick={onInputClick}
              />
            </div>
          </div>
          {isListOpened && !!options.length && (
            <div
              onMouseDown={(e: Event) => {
                e.preventDefault();
              }}
            >
              <DropdownListComponent
                listClassName='range-selector-input__list-container'
                onChange={onOptionSelect}
                options={options.map((option) => numberFormat(parseInt(option, 10)))}
                mapToLabel={mapToLabel}
              />
            </div>
          )}
        </div>
      );
    }
  )
);
