import { MultiLocationSelectorMakeQueryForInputValuePropsInterface } from './make-query-for-input-value-props.interface';

const MAX_SUGGESTIONS = 8;

export const multiLocationSelectorMakeQueryForInputValue =
  ({
    autocompleteStore,
    locationAutocompleteHistoryService,
  }: MultiLocationSelectorMakeQueryForInputValuePropsInterface) =>
  (inputValue: string) => {
    if (!inputValue) {
      const locationsFromHistory = locationAutocompleteHistoryService.getLocations() || [];
      return Promise.resolve(locationsFromHistory);
    }

    return autocompleteStore
      .searchLocations(inputValue, { 'page[limit]': MAX_SUGGESTIONS })
      .then((suggestions) => suggestions || []); // fallback to empty array if autocomplete store returns undefined
  };
