import { IDBPDatabase, OpenDBCallbacks } from 'idb';

import { BackendConfigLanguageInterface } from 'common/data/backend/config/language.interface';
import { DatabaseProviderInterface } from 'common/module/database/provider.interface';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { DatabaseServiceInterface } from 'common/service/database/service.interface';

export class DatabaseStore implements DatabaseServiceInterface {
  /**
   * Database name
   */
  private readonly databaseName: string = 'propertyfinder';

  /**
   * Current database version
   */
  private databaseVersion: number = 7;

  /**
   * Database
   */
  private database: Promise<IDBPDatabase>;

  /**
   * Constructor
   */
  public constructor(private databaseProvider: DatabaseProviderInterface) {}

  /**
   * initialize - fixes ios/Safari bug
   * [ISSUE] https://bugs.webkit.org/show_bug.cgi?id=226547
   */
  public initialize(): void {
    if (window.navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i)) {
      this.pingIndexedDB();
    }
  }

  /**
   * @inheritDoc
   */
  public openDatabase(migrationData: { languages: BackendConfigLanguageInterface }): Promise<IDBPDatabase> {
    if (!this.database) {
      this.database = this.databaseProvider.openDB(this.databaseName, this.databaseVersion, {
        upgrade: this.onUpgradeDatabase(migrationData),
        blocking: this.onBlockingDatabase,
      });
    }

    return this.database;
  }

  /**
   * onBlocked callback
   */
  private onBlockingDatabase = () => {
    this.database.then((db) => {
      db.close();
    });
  };

  /**
   * Upgrade database. Declare your migrations here
   */
  private onUpgradeDatabase =
    (migrationData: { languages: BackendConfigLanguageInterface }): OpenDBCallbacks<any>['upgrade'] =>
    (database, oldVersion) => {
      if (oldVersion < 1) {
        // Create locations tables for all languages
        migrationData.languages.list.forEach((language) => {
          database.createObjectStore(language + '_' + DatabaseTableEnum.locations, { autoIncrement: true });
        });
      }

      if (oldVersion < 2) {
        database.createObjectStore(DatabaseTableEnum.savedProperties, { autoIncrement: true });
        database.createObjectStore(DatabaseTableEnum.contactedProperties, { autoIncrement: true });
      }

      if (oldVersion < 3) {
        // Create locations tables for each available language
        migrationData.languages.list.forEach((language) => {
          database.createObjectStore(language + '_' + DatabaseTableEnum.autocompleteLocations, { autoIncrement: true });
          database.createObjectStore(language + '_' + DatabaseTableEnum.autocompleteBrokers, { autoIncrement: true });
          database.createObjectStore(language + '_' + DatabaseTableEnum.autocompleteAgents, { autoIncrement: true });
        });
      }

      if (oldVersion < 7) {
        // Create filter settings table for each available language
        migrationData.languages.list.forEach((language) => {
          const objectStoreName = `${language}_${DatabaseTableEnum.filterSettings}`;
          if (!database.objectStoreNames.contains(objectStoreName)) {
            database.createObjectStore(objectStoreName, { autoIncrement: true });
          }
        });
      }

      return;
    };

  /**
   * references indexedDB to prevent iOS/safari bug
   */
  private pingIndexedDB = () => {
    const { indexedDB } = window;
    indexedDB.deleteDatabase('test');
  };
}
