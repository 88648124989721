import { ViewBasicInterface } from 'pf-frontend-common/dist/module/view/basic.interface';
import { Component, toChildArray, VNode } from 'preact';

interface preactConnectViewProps {
  forceUpdate?: number;
}
export const preactConnectView = <View extends ViewBasicInterface>(
  view: View | (() => View),
  initializeView?: (view: View, self: Component) => void
) =>
  class PreactConnectedViewComponent extends Component<preactConnectViewProps> {
    /**
     * Store instance
     */
    private view: View;

    /**
     * Leave the update logic to the component itself
     */
    public shouldComponentUpdate(nextProps: preactConnectViewProps): boolean {
      return !(nextProps.forceUpdate === this.props.forceUpdate);
    }

    /**
     * @inheritdoc
     */
    public componentDidMount(): void {
      this.view = view instanceof Function ? view() : view;
      if (initializeView) {
        initializeView(this.view, this);
      }
    }

    /**
     * @inheritdoc
     */
    public render(): preact.JSX.Element {
      return (toChildArray(this.props.children) as VNode[])[0];
    }
  };
