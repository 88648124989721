import { domClassMerge } from '@propertyfinder/helpers';
import { IconSpriteChevronDown } from '@propertyfinder/icons';
import { FunctionalComponent, h } from 'preact';

import { DropdownBodyPositionEnum } from './body-position.enum';
import { DropdownSizeEnum } from './size.enum';
import { DropdownTemplatePropsInterface } from './template-props.interface';
import { DropdownVariantEnum } from './variant.enum';

const getDropdownHeadClasses = (
  props: Pick<DropdownTemplatePropsInterface, 'size' | 'variant' | 'isInvalid' | 'text' | 'headClassName'>
): string[] => {
  const mapSizeToClassName: Record<DropdownSizeEnum, string> = {
    [DropdownSizeEnum.normal]: 'dd--normal',
    [DropdownSizeEnum.small]: 'dd--small',
  };

  const mapVariantToClassName: Record<DropdownVariantEnum, string> = {
    [DropdownVariantEnum.primary]: 'dd--primary',
    [DropdownVariantEnum.secondary]: 'dd--secondary',
  };

  return [
    mapSizeToClassName[props.size],
    mapVariantToClassName[props.variant],
    ...(props.isInvalid ? ['dd--invalid'] : []),
    ...(props.text ? ['dd--filled'] : []),
    ...(props.headClassName ? [props.headClassName] : []),
  ];
};

export const DropdownTemplate: FunctionalComponent<DropdownTemplatePropsInterface> = ({
  isOpen,
  variant,
  disabled,
  tabIndex,
  size,
  isInvalid,
  label,
  text,
  isFloatingLabel,
  errorMessage,
  isLabelVisible,
  position,
  componentRef,
  bodyRef,
  children,
  onClick,
  style,
  className,
  iconClassName,
  headClassName,
  icon,
}) => {
  const mapPositionToClassName: Record<DropdownBodyPositionEnum, string> = {
    [DropdownBodyPositionEnum.bottomLeft]: 'dd__body--bottom-left',
    [DropdownBodyPositionEnum.bottomRight]: 'dd__body--bottom-right',
    [DropdownBodyPositionEnum.bottomCenter]: 'dd__body--bottom-center',
    [DropdownBodyPositionEnum.topLeft]: 'dd__body--top-left',
    [DropdownBodyPositionEnum.topRight]: 'dd__body--top-right',
    [DropdownBodyPositionEnum.topCenter]: 'dd__body--top-center',
  };

  const mapSizeToLabelClassName: Record<DropdownSizeEnum, string> = {
    [DropdownSizeEnum.normal]: 'dd__label--normal',
    [DropdownSizeEnum.small]: 'dd__label--small',
  };

  return (
    <div
      className={`
      dd
      ${disabled ? 'dd--disabled' : 'dd--enabled'}
      ${className || ''}
    `}
      ref={componentRef}
      tabIndex={tabIndex}
      style={style}
    >
      <div
        className={['dd__head']
          .concat(getDropdownHeadClasses({ size, variant, isInvalid, text, headClassName }))
          .join(' ')}
        onClick={onClick}
      >
        {(label || text) && (
          <div className={['dd__label', mapSizeToLabelClassName[size]].join(' ')}>
            {isLabelVisible && (
              <span
                className={`
                dd__placeholder
                ${isFloatingLabel ? 'dd__placeholder--floating' : ''}
                ${isFloatingLabel && text ? 'dd__placeholder--floating-move' : ''}
              `}
              >
                {label}
              </span>
            )}
            {text && (
              <span
                className={`
                dd__text
                ${isFloatingLabel ? 'dd__text--floating' : ''}
              `}
              >
                {text}
              </span>
            )}
            &nbsp;
          </div>
        )}
        {icon ? (
          <div className={`dd__icon${iconClassName ? ` ${iconClassName}` : ''}`}>{icon}</div>
        ) : (
          <IconSpriteChevronDown
            className={domClassMerge('dd__icon-svg', {
              'dd__icon-svg--filled': !!text,
            })}
          />
        )}
      </div>
      {errorMessage && <div className='dd__error-message'>{errorMessage}</div>}
      {isOpen && (
        <div
          ref={bodyRef}
          className={`
            dd__body
            ${mapPositionToClassName[position] || ''}
          `}
        >
          {children}
        </div>
      )}
    </div>
  );
};
