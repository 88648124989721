import { preactConnectStore } from 'common/module/preact/connect-store';
import { PropertySerpLightViewStore } from 'common/module/property/serp/light/view-store';
import { PropertySerpLightViewStoreStateInterface } from 'common/module/property/serp/light/view-store-state.interface';
import { PropertySerpLightAutocompleteTemplate } from 'desktop/module/property/serp/light/autocomplete/template';
import { PropertySerpLightViewStoreService } from 'desktop/service/property-serp-light-view-store/service';

export const PropertySerpLightAutocompleteComponent = preactConnectStore<
  PropertySerpLightViewStore,
  PropertySerpLightViewStoreStateInterface
>(PropertySerpLightViewStoreService, (store) => ({
  ...store.getState(),
}))(PropertySerpLightAutocompleteTemplate);
