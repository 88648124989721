import { FunctionComponent, h } from 'preact';

import { BedAndBathSelectorFieldPropsInterface } from 'common/module/bed-and-bath-selector/field/props.interface';
import { ChipFilterComponent } from 'library/chips-filter/component';

export const BedAndBathSelectorFieldTemplate: FunctionComponent<BedAndBathSelectorFieldPropsInterface> = ({
  title,
  name,
  options,
  onChange,
}) => {
  return (
    <div className='bed-and-bath-selector__field'>
      <h3 className='bed-and-bath-selector__field__title'>{title}</h3>
      <ChipFilterComponent
        options={options}
        className='bed-and-bath-selector__field__choices'
        classNameItem='bed-and-bath-selector__choice'
        chipClassName='bed-and-bath-selector__choice-chip'
        name={`choices-${name}`}
        selected={options.filter((option) => option.checked)}
        onCheck={(e, selectedOption) => onChange(selectedOption)}
      />
    </div>
  );
};
