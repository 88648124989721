import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

import { SaveSearchModel } from './model';
import { SaveSearchStore } from './store';

export function SaveSearchStoreFactory(): SaveSearchStore {
  return new SaveSearchStore(
    EventEmitterFactory(),
    ApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<SaveSearchModel>(SaveSearchModel.JSONAPI_TYPE),
    UserAuthenticationService()
  );
}
