import { objectReduce } from './reduce';
/**
 * Return the source object excluding the provided keys.
 * @param source the object.
 * @param keys the excluded keys of the source object.
 */
export const objectDrop = <T extends object, K extends keyof T>(source: T, ...keys: K[]) => {
  return objectReduce(
    source,
    (accumulated, key: K) => {
      if (keys.indexOf(key) >= 0) {
        return accumulated;
      }
      return {
        ...accumulated,
        [key]: source[key],
      };
    },
    {} as Partial<Pick<T, Exclude<keyof T, K>>>
  );
};
