import { Component, h } from 'preact';

import { IconCheckTemplate } from 'common/module/icon/check';

import { ChipFilterPropsInterface } from './props.interface';
import { ChipFilterTemplate } from './template';

/**
 * Chip filter allow users to make multiple selections from a list of values.
 *
 * Design: https://www.figma.com/file/gRARY1Vi4W2Ow1vRLw02am/PF_Consumer-Web-Kit?node-id=16%3A781
 */
export class ChipFilterComponent extends Component<ChipFilterPropsInterface> {
  /**
   * @inheritDoc
   */
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    selectedIcon: IconCheckTemplate,
    template: ChipFilterTemplate,
    iconPosition: 'start',
    component: 'li',
    isList: true,
    showIcon: false,
  };

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    const { template: Template } = this.props;

    return <Template {...this.props} />;
  }
}
