import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

export class BrowserSupport {
  /**
   * Cache for webp support
   */
  private isWebpSupported: Promise<boolean> = null;

  /**
   * Constructor
   */
  public constructor(private image: HTMLImageElement, private windowService: WindowServiceInterface) {}

  /**
   * Is webp supported
   */
  public testWebp(): Promise<boolean> {
    const webp = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';

    if (this.isWebpSupported) {
      return this.isWebpSupported;
    }

    this.isWebpSupported = new Promise((resolve) => {
      // if the event is from 'onload', check if the image's width is
      // 1 pixel (which indicates support). otherwise, it fails
      const testLoad = (event: Event) => {
        const result = event && event.type === 'load' ? this.image.width === 1 : false;
        resolve(Boolean(result));
      };
      this.image.onerror = testLoad;
      this.image.onload = testLoad;

      this.image.src = webp;
    });

    return this.isWebpSupported;
  }

  /**
   * Is an iOS device
   */
  public isIos(): boolean {
    return !!this.windowService.getNative().navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)/i);
  }
}
