import { CountryCodeEnum } from 'common/data/country/code.enum';
import { configGetCountry } from 'common/helper/config/get-country';
import { defaultFilterParamsAE } from 'common/module/filter/default-params/ae';
import { defaultFilterParamsBH } from 'common/module/filter/default-params/bh';
import { defaultFilterParamsEG } from 'common/module/filter/default-params/eg';
import { defaultFilterParamsLB } from 'common/module/filter/default-params/lb';
import { defaultFilterParamsMA } from 'common/module/filter/default-params/ma';
import { defaultFilterParamsQA } from 'common/module/filter/default-params/qa';
import { defaultFilterParamsSA } from 'common/module/filter/default-params/sa';

import { FilterValueBaseInterface } from './value-base.interface';

const countryCodeToFilterParamsMap = {
  [CountryCodeEnum.ma]: defaultFilterParamsMA,
  [CountryCodeEnum.ae]: defaultFilterParamsAE,
  [CountryCodeEnum.sa]: defaultFilterParamsSA,
  [CountryCodeEnum.eg]: defaultFilterParamsEG,
  [CountryCodeEnum.bh]: defaultFilterParamsBH,
  [CountryCodeEnum.lb]: defaultFilterParamsLB,
  [CountryCodeEnum.qa]: defaultFilterParamsQA,
};

export const getFilterDefaultParams = (): FilterValueBaseInterface => {
  const countryCode = configGetCountry().code;

  return countryCodeToFilterParamsMap[countryCode] || defaultFilterParamsAE;
};
