import { h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { RangeSelectorInputComponent } from 'library/range-selector-input/component';

import { SearchBarMoreFiltersSectionTemplate } from '../section/template';
import { SearchBarMoreFiltersAreaSelectorTemplatePropsInterface } from './template-props.interface';

export const SearchBarMoreFiltersAreaSelectorTemplate = ({
  value,
  options,
  onChange,
  title,
  icon,
  unit,
}: SearchBarMoreFiltersAreaSelectorTemplatePropsInterface) => (
  <SearchBarMoreFiltersSectionTemplate title={`${title} (${i18nTranslate(unit)})`} icon={icon}>
    <RangeSelectorInputComponent
      inputContainerClassName='more-filters__area-section-input-container'
      value={value}
      options={options.map((option) => option.value)}
      mapToLabel={(option) => `${option} ${i18nTranslate(unit)}`}
      minPlaceholder={i18nTranslate('Min Area')}
      maxPlaceholder={i18nTranslate('Max Area')}
      onChange={onChange}
    />
  </SearchBarMoreFiltersSectionTemplate>
);
