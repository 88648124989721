import { Component, h } from 'preact';

import { functionNoop } from 'common/helper/function/noop';
import { highlightString } from 'common/helper/highlight/string';
import { objectCompare } from 'common/helper/object/compare';
import { stringMakeCaseInsensetiveTester } from 'common/helper/string/make-insensitive-tester';

import { AmenitiesSelectorOptionInterface } from './option.interface';
import { AmenitiesSelectorPropsInterface } from './props.interface';
import { AmenitiesSelectorStateInterface } from './state.interface';
import { AmenitiesSelectorComponentTemplate } from './template';

export class AmenitiesSelectorComponent extends Component<
  AmenitiesSelectorPropsInterface,
  AmenitiesSelectorStateInterface
> {
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    columns: 3,
    dropdownEnabled: false,
    searchable: false,
    onChange: functionNoop,
    onDropdownOpenStatusChange: functionNoop,
    onReset: functionNoop,
    onSearchChange: functionNoop,
  };

  constructor(props: AmenitiesSelectorPropsInterface) {
    super(props);

    this.state = {
      displayedOptions: props.options || [],
      options: props.options || [],
      query: '',
      showAllAmenities: false,
      onSelection: this.onSelection,
      onSearch: this.onSearch,
      onResetClick: this.onResetClick,
      onDropdownStatusChange: this.onDropdownStatusChange,
      onShowAllAmenitiesButtonClick: this.onShowAllAmenitiesButtonClick,
    };
  }

  /**
   * @inheritdoc
   */
  public componentDidUpdate(prevProps: AmenitiesSelectorPropsInterface): void {
    // if options are changed from outside
    if (
      !objectCompare(this.props.options, prevProps.options) &&
      !objectCompare(this.props.options, this.state.options)
    ) {
      this.setState({
        options: this.props.options,
        displayedOptions: this.props.options,
      });
    }
  }

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return <AmenitiesSelectorComponentTemplate {...this.props} {...this.state} />;
  }

  /**
   * @param _ callback parameter not used
   * @param lastCheckboxClicked last checkbox clicked data
   * @description handles the selection of the checkbox
   */
  private onSelection = (
    _: AmenitiesSelectorOptionInterface[],
    lastCheckboxClicked: AmenitiesSelectorOptionInterface
  ) => {
    const updatedList = this.state.options.map((item) =>
      item.value === lastCheckboxClicked.value
        ? {
            ...item,
            checked: lastCheckboxClicked.checked,
          }
        : item
    );
    this.setState(
      {
        options: updatedList,
        displayedOptions: this.updateDisplayedProperties(updatedList, this.state.query),
      },
      () => {
        this.props.onChange(this.state.options.filter(({ checked }) => checked));
      }
    );
  };

  /**
   * @param query search query
   * @description handles the value of the input and filters options accordingly
   */
  private onSearch = (query: string) => {
    if (query !== this.state.query) {
      const filteredOptions: AmenitiesSelectorOptionInterface[] = this.updateDisplayedProperties(
        this.state.options,
        query
      );
      this.setState(
        {
          query,
          displayedOptions: filteredOptions,
          displayNotFoundMessage: !filteredOptions.length,
        },
        () => {
          this.props.onSearchChange(query);
        }
      );
    }
  };

  /**
   * @param updatedList list with the latest values to be proceced
   * @param query string to create the regex necessary to filter the options
   * @description filters options and modifies the label of the remaining options
   */
  private updateDisplayedProperties(
    updatedList: AmenitiesSelectorOptionInterface[],
    query: string
  ): AmenitiesSelectorOptionInterface[] {
    if (query) {
      const matchesQueryCaseInsensitive = stringMakeCaseInsensetiveTester(query);
      return updatedList
        .filter(({ label }) => typeof label === 'string' && matchesQueryCaseInsensitive(label))
        .map((item) => {
          return {
            ...item,
            label: (
              <span dangerouslySetInnerHTML={{ __html: highlightString(query, item.label as string) as string }} />
            ),
          };
        });
    }
    return updatedList;
  }

  /**
   * @description handles the status change of the dropdown component
   */
  private onDropdownStatusChange = (isOpen: boolean): void => {
    this.props.onDropdownOpenStatusChange(isOpen);
    this.onSearch('');
  };

  /**
   * @description handles the click on the reset button
   */
  private onResetClick = () => {
    const newList = this.state.options.map((item) => ({
      ...item,
      checked: false,
    }));

    this.setState(
      {
        displayedOptions: this.updateDisplayedProperties(newList, this.state.query),
        displayNotFoundMessage: false,
        options: newList,
      },
      () => {
        this.props.onReset();
        this.props.onChange([]);
      }
    );
  };

  /**
   * @description handles click on the show amenities button
   */
  private onShowAllAmenitiesButtonClick = () => {
    this.setState({
      showAllAmenities: !this.state.showAllAmenities,
    });
  };
}
