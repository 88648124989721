import { Fragment, h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';

import { SearchBarMoreFiltersSectionTemplatePropsInterface } from './template-props.interface';

export const SearchBarMoreFiltersSectionTemplate = ({
  title,
  icon,
  children,
  isNew,
}: SearchBarMoreFiltersSectionTemplatePropsInterface) => (
  <Fragment>
    <div className='more-filters__section__header'>
      {icon}
      <h3 className='more-filters-section__title'>{title}</h3>
      {isNew && (
        <div className='tag tag--style6 text--size1 text--uppercase more-filters__section-new'>
          {i18nTranslate('new')}
        </div>
      )}
    </div>
    {children}
  </Fragment>
);
