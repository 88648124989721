import { configGetAds } from 'common/helper/config/get-ads';
import { PropertyCtaService } from 'common/module/property-cta/service';
import { PropertyCtaStatsService } from 'common/module/property-cta/stats/service';
import { AbTestService } from 'common/service/ab-test/service';
import { AdService } from 'common/service/ad/service';
import { AutocompleteCacheLocationsDelayedService } from 'common/service/autocomplete-cache-locations/delayed/service';
import { DfpAdService } from 'common/service/dfp-ad/service';
import { FooterService } from 'common/service/footer/service';
import { HeaderService } from 'common/service/header/service';
import { LanguageSelectorService } from 'common/service/language-selector/service';
import { PrivacyPromptService } from 'common/service/privacy-prompt/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';
import { HomePageViewFactory } from 'desktop/module/home-page/view.factory';
import { UserAvatarComponent } from 'desktop/module/user/avatar/component';
AbTestService().initialize();

// Common services
HeaderService()
  .setComponents({
    UserAvatarComponent,
  })
  .render();
UserAuthenticationService();
FooterService();

LanguageSelectorService();
DfpAdService();
AdService().setTargeting(configGetAds().ad_targeting);

AutocompleteCacheLocationsDelayedService();

PropertyCtaService();
PropertyCtaStatsService();

PrivacyPromptService();

// Initialize and render home page
HomePageViewFactory().initialize().render();
