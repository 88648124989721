import { StatsGuardService } from '@propertyfinder/pf-frontend-common/src/service/stats-guard/service';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { BrowserCookie } from 'common/module/browser/cookie';
import { StatsTealiumProvider } from 'common/module/stats/tealium/provider';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

const service = new StatsTealiumProvider(
  WindowService().getNative(),
  UserAuthenticationService(),
  StatsGuardService(),
  StatsGtmProviderService(),
  new BrowserCookie(WindowService())
);

service.initialize();

export function StatsTealiumProviderService(): StatsTealiumProviderInterface {
  return service;
}
