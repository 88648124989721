export enum DropdownListOptionTypeEnum {
  /**
   * Default item
   */
  default = 'default',

  /**
   * Non interactive item
   */
  section = 'section',

  /**
   * List divider
   */
  divider = 'divider',
}
