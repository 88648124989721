import { FilterModel } from 'common/data/filter/model';
import { FilterSettingsCacheStore } from 'common/data/filter-settings/cache/store';
import { FilterSettingsModel } from 'common/data/filter-settings/model';
import { FilterSettingsStore as FilterSettingsDataStore } from 'common/data/filter-settings/store';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { arrayFilterNonValue } from 'common/helper/array/filter/non-value';
import { arrayLast } from 'common/helper/array/last';
import { stringToNumber } from 'common/helper/string/to-number';
import { ValueOf } from 'common/helper/types';
import { filterSettingsAdditional } from 'common/module/filter/settings/additional';
import { FilterSettingsParametersMapping } from 'common/module/filter/settings/parameters-mapping';

import { getFilterDefaultParams } from '../get-filter-default-params';
import { FilterParametersFieldChoiceInterface } from '../parameters/field-choice.interface';
import { FilterParametersBaseInterface } from '../parameters-base.interface';
import { FilterParamsEnum } from '../params.enum';
import { FilterValueBaseInterface } from '../value-base.interface';
import { filterSettingsGenerateLabel } from './generate-label';
import { FilterSettingsTypeEnum } from './type.enum';

export class FilterSettingsStore {
  /**
   * Range fields that can have any number as value
   */
  public static readonly RANGE_NUMBER_FIELDS: FilterSettingsTypeEnum[] = [
    FilterSettingsTypeEnum.price,
    FilterSettingsTypeEnum.area,
  ];
  /**
   * Applied filter setting promise
   */
  private settings: Promise<FilterSettingsModel[]>;

  /**
   * Lest of filter for clearing
   */
  private fieldsToReset: Array<keyof FilterParametersBaseInterface>;

  /**
   * Constructor
   */
  constructor(
    private filterSettingsCacheStore: FilterSettingsCacheStore,
    private filterSettingsDataStore: FilterSettingsDataStore
  ) {
    this.filterSettingsCacheStore.initialize();
  }
  /**
   * @inheritDoc
   */
  public initialize(): Promise<FilterSettingsModel[]> {
    this.settings = this.filterSettingsCacheStore.getAll();
    return this.settings;
  }
  /**
   * Validate supplied filter parameters against settings
   */
  public validate(params: FilterParametersBaseInterface): Promise<FilterParametersBaseInterface> {
    return this.settings.then((settings) => {
      if (!settings || !Object.keys(settings).length) {
        // Log error to Datadog
        console.error('Form settings data is empty');

        return this.filterSettingsDataStore.fetch().then((result) => {
          this.settings = Promise.resolve(result.data as FilterSettingsModel[]);
          return this.onValidate(result.data as FilterSettingsModel[], params);
        });
      }

      return this.onValidate(settings, params);
    });
  }

  /**
   * On filter settings validate
   */
  private onValidate = (settings: FilterSettingsModel[], params: FilterParametersBaseInterface) => {
    // Clear list of filters to clean
    this.fieldsToReset = [];

    const result = {
      ...this.extractParams(settings as FilterModel[], params),
      // Add additional settings. Like page limit.
      ...this.toFilterValue(filterSettingsAdditional, params),
    };

    return this.filterInvalidParams(result, this.fieldsToReset);
  };

  /**
   * Filter out params that are invilid for current state of params
   */
  private filterInvalidParams(
    params: FilterParametersBaseInterface,
    toRemove: Array<keyof FilterParametersBaseInterface>
  ): FilterParametersBaseInterface {
    const validationParams: any = { ...params };

    // Remove price period if sale category is selected
    if (new PropertyCategoryIdentifierAdapter().isSale(params[FilterParamsEnum.categoryId]?.value)) {
      delete validationParams[FilterParamsEnum.pricePeriod];
    }

    toRemove.map((key) => {
      if (validationParams?.[key]) {
        delete validationParams[key];
      }
    });

    return validationParams;
  }

  /**
   * Recursive parsing of filter settings object in order to extract the
   * filter params based on params what user selected.
   *
   * Filter settings represents the tree structure of JsonApiModel, so in order
   * to understand whether we need to go deeper in the tree we use jsonApiRelationships
   * that represents the list of depended on current category settings.
   * In order to extract static fields we need to check 'fields' object.
   */
  private extractParams(settings: FilterModel[], params: FilterParametersBaseInterface): FilterParametersBaseInterface {
    const firstLevelFilters = this.toFilterParams(settings, params);

    // Select active filter or default
    let selectedSetting = settings.find((setting) => {
      const filterName = FilterSettingsParametersMapping[setting.jsonApiType as FilterSettingsTypeEnum];

      if (filterName) {
        // find from the passed params
        return (
          String(setting.value) === String(params[filterName?.[0]]?.value) ||
          // or find it from the value that just set (default value)
          String(setting.value) === String(firstLevelFilters[filterName?.[0]]?.value)
        );
      }
    });

    if (!selectedSetting && settings.length === 1) {
      // for price type sale we have to parse the relationships to get the price.
      selectedSetting = settings[0];
    }

    const relationships =
      selectedSetting?.jsonApiRelationships.filter((relationship) => {
        if ((selectedSetting[relationship] as FilterModel[])?.[0]) {
          return true;
        }

        // Create the list of filters that is present in default list but
        // should be removed from final result
        this.fieldsToReset.push(...FilterSettingsParametersMapping[relationship as FilterSettingsTypeEnum]);
        return;
      }) || [];

    const filtersFromRelationship = relationships.reduce((acc, relationship) => {
      const filters = this.extractParams(selectedSetting[relationship] as FilterModel[], params);

      return {
        ...acc,
        ...filters,
      };
    }, {});

    const extraFilters = this.toFilterValue(selectedSetting?.fields, params);

    return {
      ...firstLevelFilters,
      ...filtersFromRelationship,
      ...extraFilters,
    };
  }

  /**
   * Add new choice in the choices if not already there and sort them.
   */
  private addNumberChoices(
    choices: Array<FilterParametersFieldChoiceInterface<string>>,
    choice: FilterParametersFieldChoiceInterface<string>
  ): Array<FilterParametersFieldChoiceInterface<string>> {
    if (!choice.value || choices.find((item) => item.value === choice.value)) {
      return choices;
    }

    return choices.concat(choice).sort((itemA, itemB) => parseInt(itemA.value, 10) - parseInt(itemB.value, 10));
  }

  /**
   * Adapt to filter params interface
   * Ex. Convert price filter to MinPrice and MaxPrice.
   */
  private toFilterParams(
    settingsList: FilterModel[] | FilterModel,
    params: FilterParametersBaseInterface
  ): FilterParametersBaseInterface {
    const settings = Array.isArray(settingsList) ? settingsList : [settingsList];

    // Determine filter name
    const filterName = settings[0].jsonApiType as FilterSettingsTypeEnum;

    const isRangeNumberFields = FilterSettingsStore.RANGE_NUMBER_FIELDS.includes(filterName);

    // Create filter value choices
    const choices = Array.isArray(settingsList)
      ? settings.map((setting: FilterModel) => ({
          value: String(setting.value),
          label: setting.name || null,
        }))
      : null;

    if (FilterSettingsParametersMapping[filterName]) {
      // If this filter is a range filter
      if (FilterSettingsParametersMapping[filterName].length > 1) {
        // Determine applied filter min/max values (or use default values)
        const filterValueMin = this.constrainFilterValue(
          params[FilterSettingsParametersMapping[filterName][0]]?.value,
          getFilterDefaultParams()[FilterSettingsParametersMapping[filterName][0]],
          settings,
          true,
          isRangeNumberFields
        );
        const filterValueMax = this.constrainFilterValue(
          params[FilterSettingsParametersMapping[filterName][1]]?.value,
          getFilterDefaultParams()[FilterSettingsParametersMapping[filterName][1]],
          settings,
          true,
          isRangeNumberFields
        );

        if (isRangeNumberFields) {
          // Convert to range filter (min. max.)
          return {
            [FilterSettingsParametersMapping[filterName][0]]: {
              value: filterValueMin,
              choices: this.addNumberChoices(choices, {
                value: String(filterValueMin),
                label: filterSettingsGenerateLabel(String(filterValueMin), arrayLast(choices).label),
              }),
            },
            [FilterSettingsParametersMapping[filterName][1]]: {
              value: filterValueMax,
              choices: this.addNumberChoices(choices, {
                value: String(filterValueMax),
                label: filterSettingsGenerateLabel(String(filterValueMax), arrayLast(choices).label),
              }),
            },
          };
        }

        // Convert to range filter (min. max.)
        return {
          [FilterSettingsParametersMapping[filterName][0]]: {
            value: filterValueMin,
            choices,
          },
          [FilterSettingsParametersMapping[filterName][1]]: {
            value: filterValueMax,
            choices,
          },
        };
      }

      // Determine applied filter value (or use default value)
      const filterValue = this.constrainFilterValue(
        params[FilterSettingsParametersMapping[filterName][0]]?.value,
        getFilterDefaultParams()[FilterSettingsParametersMapping[filterName][0]],
        settings
      );

      return {
        [FilterSettingsParametersMapping[filterName][0]]: {
          value: filterValue,
          ...(Array.isArray(settingsList) && { choices }),
        },
      };
    }
    return null;
  }

  /**
   * Constrains a filter value based on the supplied settings values
   */
  private constrainFilterValue(
    filterValue: string | number | string[],
    defaultFilterValue: ValueOf<FilterValueBaseInterface>,
    settings: FilterModel[],
    isRangeable?: boolean,
    skipValidation?: boolean
  ): ValueOf<FilterValueBaseInterface> {
    let validValues = arrayFilterNonValue([].concat(filterValue));

    // Empty value, or empty choices
    if (!validValues?.length || !settings?.length) {
      return filterValue || defaultFilterValue;
    }

    if (isRangeable && !skipValidation) {
      // Check value is in range
      const [minValue, maxValue] = [
        stringToNumber(settings[0].value),
        stringToNumber(settings[settings.length - 1].value),
      ];
      validValues = validValues.filter(
        (value) => stringToNumber(value) >= minValue && stringToNumber(value) <= maxValue
      );
    } else if (!skipValidation) {
      // Check value is in list of choices
      validValues = validValues.filter((value) => settings.find((choice) => String(value) === String(choice.value)));
    }

    // Revert to default value
    if (!validValues.length) {
      validValues = [].concat(defaultFilterValue);
    }

    return Array.isArray(filterValue) ? validValues : validValues[0];
  }

  /**
   * Convert to filter params interface
   */
  private toFilterValue(
    fields: FilterModel['fields'],
    params: FilterParametersBaseInterface
  ): FilterParametersBaseInterface {
    return fields?.reduce(
      (acc, cur) => ({
        ...acc,
        [FilterSettingsParametersMapping[cur.id as FilterSettingsTypeEnum][0]]: {
          value:
            params[FilterSettingsParametersMapping[cur.id as FilterSettingsTypeEnum][0]]?.value ||
            getFilterDefaultParams()[FilterSettingsParametersMapping[cur.id as FilterSettingsTypeEnum][0]],
        },
      }),
      {}
    );
  }
}
