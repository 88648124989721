import { ApiService } from '@propertyfinder/pf-frontend-common/src/service/api/service';

import { configGetFeature } from 'common/helper/config/get-feature';
import { BrowserCookieService } from 'common/service/browser-cookie/service';

import { cookieAuthenticatorWrapHttpApi } from './wrap-http-api';

const service = cookieAuthenticatorWrapHttpApi(
  ApiService(),
  BrowserCookieService(),
  configGetFeature('captcha_authenticator').enabled
);

export const CookieAuthenticatorApiService = (): typeof service => service;
