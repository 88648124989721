import { AutocompleteStoreFactory } from 'common/module/autocomplete/store.factory';
import { AutocompleteStoreCacheableDecoratorFactory } from 'common/module/autocomplete/store/cacheable-decorator.factory';
import { FilterStore } from 'common/module/filter/store';
import { PropertyAutocompleteStoreDecorator } from 'common/module/property/autocomplete/store/decorator';
import { PropertyAutocompleteStoreDecoratorInterface } from 'common/module/property/autocomplete/store/decorator.interface';
import { PropertyAutocompleteStoreHistoryDecorator } from 'common/module/property/autocomplete/store/history-decorator';
import { PropertyAutocompleteStoreSubSelectionDecorator } from 'common/module/property/autocomplete/store/sub-selection-decorator';
import { LocationAutocompleteHistoryService } from 'common/service/location-autocomplete-history/service';
import { LocationStoreService } from 'common/service/location-store/service';

export function PropertyAutocompleteStoreFactory(
  filterStore: FilterStore
): PropertyAutocompleteStoreDecoratorInterface {
  let autocompleteStore = AutocompleteStoreFactory();

  autocompleteStore = AutocompleteStoreCacheableDecoratorFactory(autocompleteStore);

  // Add property serp specific autocomplete logic
  autocompleteStore = new PropertyAutocompleteStoreDecorator(autocompleteStore, filterStore);

  // Remove selection if sublocation is selected
  autocompleteStore = new PropertyAutocompleteStoreSubSelectionDecorator(
    <PropertyAutocompleteStoreDecoratorInterface>autocompleteStore,
    LocationStoreService(),
    filterStore
  );

  // Add history functionality
  autocompleteStore = new PropertyAutocompleteStoreHistoryDecorator(
    <PropertyAutocompleteStoreDecoratorInterface>autocompleteStore,
    LocationAutocompleteHistoryService(),
    LocationStoreService()
  );

  return <PropertyAutocompleteStoreDecoratorInterface>autocompleteStore;
}
