/**
 * List of button component types
 * TODO-FE[WEB-4816] - update to real type names
 */
export enum ButtonComponentTypeEnum {
  /**
   * Supportive button
   */
  type1 = 'supportive',

  /**
   * Primary button
   */
  type2 = 'primary',

  /**
   * Floating button
   */
  type3 = 'floating',

  /**
   * Light button
   */
  type4 = 'light',
}
