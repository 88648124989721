import { NewEverydayCardInterface } from './card.interface';

export const convertSomethingNewlist = (
  newEveryDayCards: NewEverydayCardInterface[],
  data: NewEverydayCardInterface[],
  type: number
): NewEverydayCardInterface[] => {
  return newEveryDayCards.map((card) => {
    let currentCard: object;
    data.some((el) => {
      currentCard = el;
      return el.id === card.id && type === el.type;
    });
    return { ...currentCard, ...card };
  });
};
