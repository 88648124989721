import { BrowserDocumentService } from '@propertyfinder/pf-frontend-common/src/service/browser-document/service';
import { WindowService } from '@propertyfinder/pf-frontend-common/src/service/window/service';

import { LoginSocialStoreFactory } from 'common/data/login-social/store.factory';
import { configGetKeys } from 'common/helper/config/get-keys';
import { UserFrontendLoginService } from 'common/service/user-frontend-login/service';

import { UserFrontendLoginProviderGoogle } from './google';

export const UserFrontendLoginProviderGoogleFactory = () =>
  new UserFrontendLoginProviderGoogle(
    LoginSocialStoreFactory(),
    BrowserDocumentService(),
    WindowService(),
    configGetKeys().googleAuth,
    UserFrontendLoginService()
  );
