export const bedAndBathSelectorLabelMerge = (bedrooms?: string, bathrooms?: string): string => {
  const text: string[] = [];

  if (bedrooms) {
    text.push(bedrooms);
  }

  if (bathrooms) {
    text.push(bathrooms);
  }

  return text.join('; ');
};
