import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { EmailAlertAdditionalParamsInterface } from 'common/data/email-alert/additional-params.interface';

export class EmailAlertModel extends JsonApiModel implements EmailAlertAdditionalParamsInterface {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'email_alert';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = EmailAlertModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   * TODO-BE: CX-1064 - Add support for Number of Bed/Baths in Email Alert API
   */
  public jsonApiAttributes: Array<keyof EmailAlertModel> = [
    'email',
    'category_ids',
    'order_by',
    'location_ids',
    'price_type_id',
    'price_min',
    'price_max',
    'area_min',
    'area_max',
    'type_id',
    'amenities',
    'furnished',
    'number_of_bathrooms',
    'number_of_bedrooms',
    'is_developer_property',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof EmailAlertModel> = [];

  // tslint:disable:variable-name

  /**
   * Sender's email
   */
  public email: string;

  /**
   * Property category (rent, sale, ect.)
   */
  public category_ids: number[] = [];

  /**
   * Location id
   */
  public location_ids: number[] = [];

  /**
   * Order
   */
  public order_by?: string;

  /**
   * Price type (yearly, monthly, ect.)
   */
  public price_type_id?: number;

  /**
   * Property type (apartment, villa, hotel, ect.)
   */
  public type_id?: number;

  /**
   * Min price
   */
  public price_min?: number;

  /**
   * Max price
   */
  public price_max?: number;

  /**
   * Min area
   */
  public area_min?: number;

  /**
   * Max area
   */
  public area_max?: number;

  /**
   * Number of Bedrooms
   */
  public number_of_bedrooms?: string[] = [];

  /**
   * Number of Bathrooms
   */
  public number_of_bathrooms?: string[] = [];

  /**
   * Amenities
   */
  public amenities?: string[] = [];

  /**
   * furnished
   */
  public furnished?: number;

  /**
   * Is developer property
   */
  public is_developer_property?: boolean;

  // tslint:enable:variable-name
}
