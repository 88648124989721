import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from './template-props.interface';

export const IconFurnishing: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg className={props.class} viewBox='0 0 18 8'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.5 2.5V2C12.5 1.72386 12.2761 1.5 12 1.5H3C2.72386 1.5 2.5 1.72386 2.5 2V2.5H1V2C1 0.89543 1.89543 0 3 0H12C13.1046 0 14 0.89543 14 2V2.5H12.5ZM4 8.5V9.5H11V8.5H4ZM4 7H11V6C11 4.89543 11.8954 4 13 4C14.1046 4 15 4.89543 15 6V10.75C15 10.8881 14.8881 11 14.75 11H0.25C0.111929 11 0 10.8881 0 10.75V6C0 4.89543 0.89543 4 2 4C3.10457 4 4 4.89543 4 6V7ZM1.5 9.5H2.5V6C2.5 5.72386 2.27614 5.5 2 5.5C1.72386 5.5 1.5 5.72386 1.5 6V9.5ZM12.5 9.5H13.5V6C13.5 5.72386 13.2761 5.5 13 5.5C12.7239 5.5 12.5 5.72386 12.5 6V9.5Z'
    />
  </svg>
);
