import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from 'common/module/icon/template-props.interface';
// tslint:disable:max-line-length
export const IconTimeTemplate: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg viewBox='0 0 18 18' className={props.class}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18C13.9706 18 18 13.9706 18 9ZM1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9ZM8.56626 9.18594C8.56626 9.24696 8.5784 9.30514 8.6004 9.35819C8.62236 9.41127 8.65491 9.461 8.69806 9.50414L10.6189 11.425C10.7947 11.6008 11.0796 11.6008 11.2553 11.425C11.4311 11.2493 11.4311 10.9644 11.2553 10.7886L9.46626 8.99955V4.69338C9.46626 4.44485 9.26478 4.24338 9.01626 4.24338C8.76773 4.24338 8.56626 4.44485 8.56626 4.69338V9.18594ZM8.6004 9.35819C8.6008 9.35917 8.60121 9.36014 8.60162 9.3611L8.59921 9.35528C8.5996 9.35625 8.6 9.35722 8.6004 9.35819Z'
    />
  </svg>
);
