import { AmenityModel } from 'common/data/amenity/model';
import { AreaModel } from 'common/data/area/model';
import { BathroomModel } from 'common/data/bathroom/model';
import { BedroomModel } from 'common/data/bedroom/model';
import { CompletionStatusModel } from 'common/data/completion-status/model';
import { FilterModel } from 'common/data/filter/model';
import { FurnishedModel } from 'common/data/furnished/model';
import { PropertyTypeModel } from 'common/data/property-type/model';
import { VirtualViewingModel } from 'common/data/virtual-viewing/model';

export class FilterSettingsModel extends FilterModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'category_id';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = FilterSettingsModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof FilterSettingsModel> = ['id', 'fields'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof FilterSettingsModel> = [
    // Default setting
    'amenities',
    'bathroom',
    'bedroom',
    'area',
    // Category relations
    'property_types',
    'price_types',
    'price',
    'furnishings',
    'virtual_viewings',
    'completion_statuses',
    'utilities_price_type',
    'order_by',
    'payment_methods',
    'is_developer_property',
    'installment_years',
    'verified',
  ];

  /**
   * ID
   */
  public id: string;

  /**
   * Value
   */
  public value: string;

  /**
   * Keyword
   */
  public query: {
    name: string;
    label: string;
    type: string;
  };

  /**
   * Furnishings
   */
  public furnishings: FurnishedModel[];

  // tslint:disable:variable-name

  /**
   * Fields
   */
  public fields: Array<{
    value: string;
    id: string;
  }>;

  /**
   * Property type amenities
   */
  public amenities: AmenityModel[];

  /**
   * Property type bathroom
   */
  public bathroom: BathroomModel[];

  /**
   * Property type area
   */
  public area: AreaModel[];

  /**
   * Property type bedroom
   */
  public bedroom: BedroomModel[];

  /**
   * Property type
   */
  public property_types: PropertyTypeModel[];

  /**
   * Virtual Viewings
   */
  public virtual_viewings: VirtualViewingModel[];

  /**
   * Completion Statuses
   */
  public completion_statuses: CompletionStatusModel[];

  /**
   * Utilities Price Type
   */
  public utilities_price_type: Array<FilterModel & { jsonApiType: 'utilities_price_type' }>;

  /**
   * Price type
   */
  public price_types: Array<FilterModel & { jsonApiType: 'price_type' }>;

  /**
   * Price
   */
  public price: Array<FilterModel & { jsonApiType: 'price' }>;

  /**
   * Order By
   */
  public order_by: Array<FilterModel & { jsonApiType: 'order_by' }>;

  /**
   * Payment Method
   */
  public payment_methods: Array<FilterModel & { jsonApiType: 'payment_method' }>;

  /**
   * Is developer property
   */
  public is_developer_property: Array<FilterModel & { jsonApiType: 'is_developer_property' }>;

  /**
   * Installment years
   */
  public installment_years: Array<FilterModel & { jsonApiType: 'installment_years' }>;

  /**
   * Verified
   */
  public verified: Array<FilterModel & { jsonApiType: 'verified' }>;
}
