import { Button, ButtonThemesEnum } from '@propertyfinder/button';
import { DrawerComponent } from '@propertyfinder/drawer';
import { domClassMerge } from '@propertyfinder/helpers';
import { BrowserStorageLocalService } from '@propertyfinder/pf-frontend-common/src/service/browser-storage-local/service';
import { FunctionComponent, h } from 'preact';
import { useRef } from 'preact/hooks';

import { configGetCountry } from 'common/helper/config/get-country';
import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';

import { PRIVACY_PROMPT } from './constant';

const browserStorageLocalService = BrowserStorageLocalService();

export const PrivacyPromptComponent: FunctionComponent = () => {
  const closeRef = useRef<Function | null>(null);
  const isRtl = configGetLanguage().isRtl;

  const isPrivacyPromptShown = browserStorageLocalService.getData(PRIVACY_PROMPT) === 'true';

  const content = i18nTranslate('PrivacyPrompt_Content');
  const contentParts = content.split('{link}');

  return (
    !isPrivacyPromptShown && (
      <DrawerComponent
        className='privacy-prompt__drawer__container'
        contentClassName={domClassMerge('privacy-prompt__drawer', { 'privacy-prompt__drawer--rtl': isRtl })}
        openOnLoad
        header={<p className={'privacy-prompt__title'}>{i18nTranslate('PrivacyPrompt_Title')}</p>}
        headerClassName={domClassMerge('privacy-prompt__drawer__header', {
          'privacy-prompt__drawer__header--rtl': isRtl,
        })}
        onClosing={(): void => {
          browserStorageLocalService.setData(PRIVACY_PROMPT, 'true');
        }}
        bindClose={(close: () => void): void => {
          closeRef.current = close;
        }}
      >
        <div>
          <p className={'privacy-prompt__content'}>
            {contentParts[0]}
            <a href={`${configGetCountry().cookiesPolicyUrl}`} target='_blank' rel='noreferrer'>
              {i18nTranslate('Cookies Policy')}
            </a>
            {contentParts[1]}
            <a href={`${configGetCountry().privacyPolicyUrl}`} target='_blank' rel='noreferrer'>
              {i18nTranslate('Privacy Policy')}
            </a>
          </p>
          <Button
            type='submit'
            theme={ButtonThemesEnum.PRIMARY}
            className={'privacy-prompt__button'}
            onClick={(): void => closeRef.current?.()}
            htmlAttributes={{ 'data-testid': 'privacy-prompt-accept-button' }}
          >
            {i18nTranslate('Accept')}
          </Button>
        </div>
      </DrawerComponent>
    )
  );
};
