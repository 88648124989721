import { Link } from '@propertyfinder/link';
import { FunctionComponent, h } from 'preact';
import { useCallback } from 'preact/hooks';

import { StatsTealiumDataLayerConversionEventInterface } from 'common/module/stats/tealium/data-layer/conversion-event.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';

const tracking = StatsTealiumProviderService();

export const HomePagePrestigeComponent: FunctionComponent = () => {
  const onLinkClick = useCallback(() => {
    tracking.send({
      sendToGa: true,
      event_action: 'prestige_banner_click',
      event_label: 'UAE-Residential-Real-Estate-Market-Watch-2023',
      event_category: StatsTealiumDataLayerEventCategoryEnum.bannerClick,
      tealium_event: 'prestige_banner_click',
      page_country: window.propertyfinder.countryConfig.code,
      page_currency_code: window.propertyfinder.countryConfig.currencyCode,
      page_lang: window.propertyfinder.language.current,
      page_category: 'Portal Home',
    } as StatsTealiumDataLayerConversionEventInterface);
  }, [tracking]);

  return (
    <Link
      href='https://www.propertyfinder.ae/blog/wp-content/uploads/2023/11/MW_Dubai_Q3_2023_16Nov.pdf'
      target='_blank'
      onClick={onLinkClick}
    >
      <img
        alt='market-watch-2023'
        src={`${__('assets/desktop/market-watch-2023.png')}`}
        className='prestige__image-banner'
      />
    </Link>
  );
};
