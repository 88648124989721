import { h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { IconHyphen } from 'common/module/icon/hyphen';
import { RangeSelectorFieldsEnum } from 'library/range-selector/fields.enum';
import { rangeSelectorFilterOptions } from 'library/range-selector/filter-options';

import { RangeSelectorInputFieldTemplate } from './field/template';
import { RangeSelectorInputTemplatePropsInterface } from './template-props.interface';

export const RangeSelectorInputTemplate = ({
  value,
  options,
  listOpenedOn,
  inputContainerClassName,
  minPlaceholder,
  maxPlaceholder,
  minRef,
  mapToLabel,
  maxRef,
  caretPositionRef,
  onInputClick,
  onInputBlur,
  onInputChange,
  onOptionSelect,
}: RangeSelectorInputTemplatePropsInterface) => {
  const [minOptions, maxOptions] = rangeSelectorFilterOptions(options, value);

  return (
    <div className='range-selector-input'>
      <RangeSelectorInputFieldTemplate
        ref={minRef}
        inputContainerClassName={inputContainerClassName}
        value={value.min}
        isListOpened={listOpenedOn === RangeSelectorFieldsEnum.min}
        placeholder={minPlaceholder || i18nTranslate('Any')}
        options={minOptions}
        mapToLabel={mapToLabel}
        onInputFocus={() => onInputClick(RangeSelectorFieldsEnum.min)}
        onInputBlur={onInputBlur}
        onOptionSelect={onOptionSelect}
        onInputClick={() => onInputClick(RangeSelectorFieldsEnum.min)}
        onInputChange={onInputChange(RangeSelectorFieldsEnum.min)}
        caretPositionRef={caretPositionRef}
      />
      <div className='range-selector-input__divider'>
        <IconHyphen class='range-selector-input__divider-svg' />
      </div>
      <RangeSelectorInputFieldTemplate
        ref={maxRef}
        inputContainerClassName={inputContainerClassName}
        value={value.max}
        isListOpened={listOpenedOn === RangeSelectorFieldsEnum.max}
        placeholder={maxPlaceholder || i18nTranslate('Any')}
        options={maxOptions}
        mapToLabel={mapToLabel}
        onInputFocus={() => onInputClick(RangeSelectorFieldsEnum.max)}
        onInputBlur={onInputBlur}
        onOptionSelect={onOptionSelect}
        onInputClick={() => onInputClick(RangeSelectorFieldsEnum.max)}
        onInputChange={onInputChange(RangeSelectorFieldsEnum.max)}
        caretPositionRef={caretPositionRef}
      />
    </div>
  );
};
