import { h } from 'preact';
import { useRef, useState } from 'preact/hooks';

import { KeepSameWidthWhenOpenedComponentPropsInterface } from './component-props.interface';
import { KeepSameWidthWhenOpenedRequiredComponentPropsInterface } from './required-component-props.interface';

export const KeepSameWidthWhenOpened = <CP extends KeepSameWidthWhenOpenedRequiredComponentPropsInterface>({
  Component,
  componentProps,
}: KeepSameWidthWhenOpenedComponentPropsInterface<CP>) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const props: CP = {
    ...(componentProps as any),
    ref,
    style: {
      ...componentProps.style,
      width: isOpen ? `${ref.current.base.getBoundingClientRect().width}px` : null,
    },
    onDropdownOpenStatusChange: setIsOpen,
  };

  return <Component {...props} />;
};
