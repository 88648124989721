import { StatsDebuggerService } from 'pf-frontend-common/src/service/stats-debugger/service';
import { StatsGuardService } from 'pf-frontend-common/src/service/stats-guard/service';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { StatsGtmProvider } from 'common/module/stats/gtm/provider';
import { StatsGtmProviderInterface } from 'common/module/stats/gtm/provider.interface';

const service = new StatsGtmProvider(WindowService().getNative(), StatsDebuggerService(), StatsGuardService());

export function StatsGtmProviderService(): StatsGtmProviderInterface {
  return service;
}
