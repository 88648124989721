import { useEffect, useState } from 'preact/hooks';

import { DataStoreEvent } from '../../data/store.event';
import { FilterStore } from '../store';

/**
 * returns updated filter params
 * @param filterStore FilterStore
 * @returns FilterParametersInterface
 */
export const useFilterStoreParams = (filterStore: FilterStore) => {
  const [filterParams, setFilterParams] = useState(filterStore.getState());

  useEffect(() => {
    const onStateChange = () => {
      setFilterParams(filterStore.getState());
    };
    filterStore.getEventEmitter().addListener(DataStoreEvent.updateState, onStateChange);
    return () => {
      filterStore.getEventEmitter().removeListener(DataStoreEvent.updateState, onStateChange);
    };
  }, []);

  return filterParams;
};
