import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class UserFrontendLoginModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'frontend_user_login';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserFrontendLoginModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserFrontendLoginModel> = ['email', 'password', 'rememberMe'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof UserFrontendLoginModel> = ['user'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['token', 'refresh_token'];

  /**
   * Email
   */
  public email: string = '';

  /**
   * Password
   */
  public password: string = '';

  /**
   * Remember me?
   */
  public rememberMe: boolean = false;

  /**
   * User
   */
  public user: UserModel;

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Authorization token
     */
    token: string;

    /**
     * Refresh token
     */
    refresh_token: string;
  };
}
