import { h } from 'preact';

import { countryGetAreaUnit } from 'common/data/country/get-area-unit';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { configGetCountry } from 'common/helper/config/get-country';
import { configGetFeature } from 'common/helper/config/get-feature';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { AmenitiesSelectorComponent } from 'common/module/amenities-selector/component';
import { AreaSelectorComponent } from 'common/module/area-selector/component';
import { BedAndBathSelectorComponent } from 'common/module/bed-and-bath-selector/component';
import { FilterChoicesIsDeveloperPropertyEnum } from 'common/module/filter/choices/is-developer-property.enum';
import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterSingleSelectionTemplate } from 'common/module/filter-single-selection/template';
import {
  IconBrick,
  IconDeveloperTemplate,
  IconFurnishing,
  IconMagnifierTemplate,
  IconPlayCircle,
  IconThickPriceTemplate,
  IconThickPropertySize,
} from 'common/module/icon';
import { KeywordsComponent } from 'common/module/keywords/component';
import { PriceSelectorComponent } from 'common/module/price-selector/component';
import { SortParamsEnum } from 'common/module/sort/params.enum';
import { IsDeveloperPropertyComponent } from 'desktop/module/is-developer-property/component';
import { propertySerpFormToRangeOptions } from 'desktop/module/property/serp/form/to-range-options';

import { PropertySerpFormPlaceholder } from '../property/serp/form/placeholder';
import { SearchBarMoreFiltersAreaSelectorTemplate } from '../search-bar/more-filters/area-selector/template';
import { SearchBarMoreFiltersChipChoiceSectionTemplate } from '../search-bar/more-filters/chip-choice-section/template';
import { SearchBarMoreFiltersSectionTemplate } from '../search-bar/more-filters/section/template';
import { FilterWidgetComponentPropsInterface } from './component-props.interface';
import { FilterWidgetTypeEnum } from './type.enum';

const categoryIdentifier = new PropertyCategoryIdentifierAdapter();

export const filterWidgetRenderMap: Record<
  FilterWidgetTypeEnum,
  {
    Component: (props: FilterWidgetComponentPropsInterface) => preact.JSX.Element;
    MoreFiltersComponent?: (props: FilterWidgetComponentPropsInterface) => preact.JSX.Element;
    /**
     * Should render the component. If not defined component will be rendered
     */
    shouldRender?: ({ filterParams }: { filterParams: FilterParametersBaseInterface }) => boolean;
  }
> = {
  [FilterWidgetTypeEnum.bedBath]: {
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, style, filterParams, className, dropdownSize }) => {
      return (
        <BedAndBathSelectorComponent
          className={className}
          options={{
            bedrooms: filterParams[FilterParamsEnum.bedrooms].choices.map((option, index) => {
              const filterBedrooms = filterParams[FilterParamsEnum.bedrooms];
              let text = option.value;

              // If it is the first index, we can use label as text
              if (option.value === '0') {
                text = option.label;
              }

              // if the value is 8, set the text to 7+
              if (option.value === '8') {
                text = `7+`;
              }

              return {
                text,
                value: option.value,
                num: parseInt(option.value, 10),
                checked: filterBedrooms.value.includes(option.value),
              };
            }),
            bathrooms: filterParams[FilterParamsEnum.bathrooms].choices.map((option, index) => {
              const filterBathrooms = filterParams[FilterParamsEnum.bathrooms];
              let text = option.value;

              // if the value is 8, set the text to 7+
              if (option.value === '8') {
                text = `7+`;
              }

              return {
                text,
                value: option.value,
                num: parseInt(option.value, 10),
                checked: filterBathrooms.value.includes(option.value),
              };
            }),
          }}
          onChange={onChangeFilters}
          style={style}
          onDropdownOpenStatusChange={onDropdownOpenStatusChange}
          dropdownSize={dropdownSize}
        />
      );
    },
    shouldRender: ({ filterParams }) =>
      !!(filterParams[FilterParamsEnum.bedrooms] && filterParams[FilterParamsEnum.bathrooms]),
  },
  [FilterWidgetTypeEnum.propertyType]: {
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, filterParams, style, className, dropdownSize }) => {
      const { choices, value: propertyTypeId } = filterParams[FilterParamsEnum.propertyTypeId];
      return (
        <FilterSingleSelectionTemplate
          className={className}
          label={i18nTranslate('Property type')}
          choices={[
            {
              value: getFilterDefaultParams()[FilterParamsEnum.propertyTypeId],
              label: PropertySerpFormPlaceholder[FilterParamsEnum.propertyTypeId],
            },
            ...choices,
          ]}
          value={propertyTypeId}
          onChange={({ value }) => {
            onChangeFilters({
              [FilterParamsEnum.propertyTypeId]: { value, choices },
            });
          }}
          style={style}
          onDropdownOpenStatusChange={onDropdownOpenStatusChange}
          dropdownSize={dropdownSize}
        />
      );
    },
    shouldRender: ({ filterParams }) => !!filterParams[FilterParamsEnum.propertyTypeId],
  },
  [FilterWidgetTypeEnum.furnished]: {
    MoreFiltersComponent: ({ onChangeFilters, filterParams }) => (
      <SearchBarMoreFiltersChipChoiceSectionTemplate
        title={i18nTranslate('Furnishings')}
        icon={<IconFurnishing class='more-filters__header-icon' />}
        onChange={(value) => {
          onChangeFilters({
            [FilterParamsEnum.furnishing]: { value, choices: [] },
          });
        }}
        value={filterParams[FilterParamsEnum.furnishing].value}
        choices={filterParams[FilterParamsEnum.furnishing].choices}
      />
    ),
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, filterParams, style, className }) => {
      const { choices, value: furnishingValue } = filterParams[FilterParamsEnum.furnishing];
      return (
        <FilterSingleSelectionTemplate
          className={className}
          label={i18nTranslate('Furnishing')}
          choices={choices}
          value={furnishingValue}
          onChange={({ value }) => {
            onChangeFilters({
              [FilterParamsEnum.furnishing]: { value, choices },
            });
          }}
          style={style}
          onDropdownOpenStatusChange={onDropdownOpenStatusChange}
        />
      );
    },
    shouldRender: ({ filterParams }) => !!filterParams[FilterParamsEnum.furnishing],
  },
  [FilterWidgetTypeEnum.amenities]: {
    MoreFiltersComponent: ({ onChangeFilters, filterParams, className }) => (
      <AmenitiesSelectorComponent
        className={className}
        options={filterParams[FilterParamsEnum.amenities].choices.map((option) => ({
          ...option,
          checked: filterParams[FilterParamsEnum.amenities].value.includes(option.value),
        }))}
        onChange={(selected) =>
          onChangeFilters({
            [FilterParamsEnum.amenities]: selected.reduce(
              (result, { value, label }) => {
                result.value.push(value);
                result.choices.push({ value, label: label as string });
                return result;
              },
              { value: [], choices: [] }
            ),
          })
        }
      />
    ),
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, filterParams, style, className }) => (
      <AmenitiesSelectorComponent
        className={className}
        columns={2}
        dropdownEnabled={true}
        searchable={true}
        options={filterParams[FilterParamsEnum.amenities].choices.map((option) => ({
          ...option,
          checked: filterParams[FilterParamsEnum.amenities].value.includes(option.value),
        }))}
        onChange={(selected) =>
          onChangeFilters({
            [FilterParamsEnum.amenities]: selected.reduce(
              (result, { value, label }) => {
                result.value.push(value);
                result.choices.push({ value, label: label as string });
                return result;
              },
              { value: [], choices: [] }
            ),
          })
        }
        style={style}
        onDropdownOpenStatusChange={onDropdownOpenStatusChange}
      />
    ),
    shouldRender: ({ filterParams }) => !!filterParams[FilterParamsEnum.amenities],
  },
  [FilterWidgetTypeEnum.price]: {
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, filterParams, style, className, dropdownSize }) => {
      const { choices: priceChoices, value: minValue } = filterParams[FilterParamsEnum.minPrice];
      const periodChoices = filterParams[FilterParamsEnum.pricePeriod]?.choices || [];

      return (
        <PriceSelectorComponent
          className={className}
          value={{
            min: minValue,
            max: filterParams[FilterParamsEnum.maxPrice].value,
            period: filterParams[FilterParamsEnum.pricePeriod]?.value || '',
          }}
          activeCategoryId={filterParams[FilterParamsEnum.categoryId].value}
          // [TODO-FE] TPNX-2208: choices should not include custom inputs
          priceOptions={priceChoices.filter((priceOption) => priceOption.value !== minValue)}
          currencyCode={configGetCountry().currencyCode}
          priceTypes={periodChoices}
          onChange={({ min, max, period }) => {
            onChangeFilters({
              [FilterParamsEnum.pricePeriod]: {
                choices: periodChoices,
                value: period,
              },
              [FilterParamsEnum.minPrice]: {
                choices: priceChoices,
                value: min,
              },
              [FilterParamsEnum.maxPrice]: {
                choices: priceChoices,
                value: max,
              },
            });
          }}
          style={style}
          onDropdownOpenStatusChange={onDropdownOpenStatusChange}
          dropdownSize={dropdownSize}
        />
      );
    },
    shouldRender: ({ filterParams }) =>
      !!(filterParams[FilterParamsEnum.minPrice] && filterParams[FilterParamsEnum.maxPrice]),
  },
  [FilterWidgetTypeEnum.utilitiesPriceType]: {
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, filterParams, style, className }) => {
      const { choices, value: priceTypeValue } = filterParams[FilterParamsEnum.utilitiesPriceType];

      return (
        <FilterSingleSelectionTemplate
          className={className}
          label={i18nTranslate('Price incl/excl')}
          forcePrimary={priceTypeValue !== ''}
          choices={[
            {
              value: getFilterDefaultParams()[FilterParamsEnum.utilitiesPriceType],
              label: i18nTranslate(PropertySerpFormPlaceholder[FilterParamsEnum.utilitiesPriceType]),
            },
            ...choices.map((choice) => ({
              value: choice.value,
              label: i18nTranslate(choice.label),
            })),
          ]}
          value={priceTypeValue}
          onChange={({ value }) => {
            onChangeFilters({
              [FilterParamsEnum.utilitiesPriceType]: { value, choices },
            });
          }}
          style={style}
          onDropdownOpenStatusChange={onDropdownOpenStatusChange}
        />
      );
    },
    MoreFiltersComponent: ({ onChangeFilters, filterParams }) => {
      const { choices, value: priceTypeValue } = filterParams[FilterParamsEnum.utilitiesPriceType];
      return (
        <SearchBarMoreFiltersChipChoiceSectionTemplate
          title={i18nTranslate('Price incl/excl')}
          icon={<IconThickPriceTemplate class='more-filters__header-icon' />}
          onChange={(value) => {
            onChangeFilters({
              [FilterParamsEnum.utilitiesPriceType]: { value, choices: [] },
            });
          }}
          value={priceTypeValue}
          choices={[
            {
              value: getFilterDefaultParams()[FilterParamsEnum.utilitiesPriceType],
              label: i18nTranslate(PropertySerpFormPlaceholder[FilterParamsEnum.utilitiesPriceType]),
            },
            ...choices.map((choice) => ({
              value: choice.value,
              label: i18nTranslate(choice.label),
            })),
          ]}
        />
      );
    },
    shouldRender: ({ filterParams }) => !!filterParams[FilterParamsEnum.utilitiesPriceType],
  },
  [FilterWidgetTypeEnum.area]: {
    MoreFiltersComponent: ({ onChangeFilters, filterParams }) => {
      const { choices, value: minValue } = filterParams[FilterParamsEnum.minArea];

      return (
        <SearchBarMoreFiltersAreaSelectorTemplate
          title={i18nTranslate('Property area')}
          unit={countryGetAreaUnit()}
          icon={<IconThickPropertySize class='more-filters__header-icon' />}
          value={{
            min: minValue,
            max: filterParams[FilterParamsEnum.maxArea].value,
          }}
          // [TODO-FE] TPNX-2208: choices should not include custom inputs
          options={choices.filter((choice) => choice.value !== minValue)}
          onChange={({ min, max }) => {
            onChangeFilters({
              [FilterParamsEnum.minArea]: { choices, value: min },
              [FilterParamsEnum.maxArea]: { choices, value: max },
            });
          }}
        />
      );
    },
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, filterParams, style, className, dropdownSize }) => {
      const { choices, value: minValue } = filterParams[FilterParamsEnum.minArea];
      return (
        <AreaSelectorComponent
          className={className}
          value={{
            min: minValue,
            max: filterParams[FilterParamsEnum.maxArea].value,
          }}
          unit={countryGetAreaUnit()}
          // [TODO-FE] TPNX-2208: choices should not include custom inputs
          options={choices.filter((choice) => choice.value !== minValue)}
          onChange={({ min, max }) => {
            onChangeFilters({
              [FilterParamsEnum.minArea]: { choices, value: min },
              [FilterParamsEnum.maxArea]: { choices, value: max },
            });
          }}
          style={style}
          onDropdownOpenStatusChange={onDropdownOpenStatusChange}
          dropdownSize={dropdownSize}
        />
      );
    },
    shouldRender: ({ filterParams }) =>
      !!(filterParams[FilterParamsEnum.minArea] && filterParams[FilterParamsEnum.maxArea]),
  },
  [FilterWidgetTypeEnum.completion]: {
    MoreFiltersComponent: ({ onChangeFilters, filterParams }) => (
      <SearchBarMoreFiltersChipChoiceSectionTemplate
        title={i18nTranslate('Completion status')}
        icon={<IconBrick class='more-filters__header-icon' />}
        onChange={(value) => {
          onChangeFilters({
            [FilterParamsEnum.completionStatus]: { value, choices: [] },
          });
        }}
        value={filterParams[FilterParamsEnum.completionStatus].value}
        choices={[
          {
            value: getFilterDefaultParams()[FilterParamsEnum.completionStatus],
            label: i18nTranslate('Any'),
          },
          ...filterParams[FilterParamsEnum.completionStatus].choices,
        ]}
      />
    ),
    Component: ({ onChangeFilters, onDropdownOpenStatusChange, filterParams, style, className }) => {
      const { choices, value: completionStatus } = filterParams[FilterParamsEnum.completionStatus];
      return (
        <FilterSingleSelectionTemplate
          className={className}
          label={i18nTranslate('Completion status')}
          forcePrimary={completionStatus !== ''}
          choices={[
            {
              value: getFilterDefaultParams()[FilterParamsEnum.completionStatus],
              label: PropertySerpFormPlaceholder[FilterParamsEnum.completionStatus],
            },
            ...choices,
          ]}
          value={completionStatus}
          onChange={({ value }) => {
            onChangeFilters({
              [FilterParamsEnum.completionStatus]: { value, choices: [] },
            });
          }}
          style={style}
          onDropdownOpenStatusChange={onDropdownOpenStatusChange}
        />
      );
    },
    shouldRender: ({ filterParams }) => !!filterParams[FilterParamsEnum.completionStatus],
  },
  [FilterWidgetTypeEnum.virtualViewings]: {
    MoreFiltersComponent: ({ onChangeFilters, filterParams }) => (
      <SearchBarMoreFiltersChipChoiceSectionTemplate
        title={i18nTranslate('Virtual Viewings')}
        icon={<IconPlayCircle class='more-filters__header-icon' />}
        onChange={(value) => {
          onChangeFilters({
            [FilterParamsEnum.virtualViewings]: { value, choices: [] },
          });
        }}
        value={filterParams[FilterParamsEnum.virtualViewings].value}
        choices={[
          {
            value: getFilterDefaultParams()[FilterParamsEnum.virtualViewings],
            label: i18nTranslate('Any'),
          },
          ...filterParams[FilterParamsEnum.virtualViewings].choices,
        ]}
      />
    ),
    Component: () => null,
    shouldRender: ({ filterParams }) => !!filterParams[FilterParamsEnum.virtualViewings],
  },
  [FilterWidgetTypeEnum.keywords]: {
    MoreFiltersComponent: ({ filterParams, onChangeFilters }) => {
      const category = categoryIdentifier.getData(
        Number(filterParams[FilterParamsEnum.categoryId].value)
      ) as PropertyCategoryIdentifierEnum;
      return (
        <SearchBarMoreFiltersSectionTemplate
          title={i18nTranslate('search:filters:keywords_title')}
          icon={<IconMagnifierTemplate clipped={false} />}
        >
          <KeywordsComponent
            chipsOnTheBottom
            className='more-filters__keywords'
            chipClassName='more-filters__keywords-chip'
            category={category}
            value={filterParams[FilterParamsEnum.keyword]?.value || ''}
            onChange={(keywords) => onChangeFilters({ [FilterParamsEnum.keyword]: { value: keywords } })}
          />
        </SearchBarMoreFiltersSectionTemplate>
      );
    },
    Component: () => null,
  },
  [FilterWidgetTypeEnum.isDeveloperProperty]: {
    MoreFiltersComponent: ({ filterParams, onChangeFilters }) => {
      const currentValue = filterParams[FilterParamsEnum.isDeveloperProperty]?.value;
      const installmentMinField = filterParams?.[FilterParamsEnum.minInstallmentYears];
      const installmentMaxField = filterParams?.[FilterParamsEnum.maxInstallmentYears];
      const installmentMinOptions = propertySerpFormToRangeOptions(
        installmentMinField?.choices || [],
        installmentMaxField?.value,
        true,
        true
      );
      const installmentMaxOptions = propertySerpFormToRangeOptions(
        installmentMaxField?.choices || [],
        installmentMinField?.value,
        false,
        true
      );
      return (
        <SearchBarMoreFiltersSectionTemplate
          title={i18nTranslate('filters:is-developer-property:label')}
          icon={
            <IconDeveloperTemplate class='more-filters__header-icon more-filters__header-icon--square more-filters__header-icon--no-fill more-filters__header-icon--minus-spacing-top' />
          }
        >
          <IsDeveloperPropertyComponent
            checkBoxValue={!!Number(currentValue)}
            checkBoxOnChange={(newValue) => {
              onChangeFilters({
                [FilterParamsEnum.isDeveloperProperty]: {
                  value: newValue
                    ? FilterChoicesIsDeveloperPropertyEnum.active
                    : FilterChoicesIsDeveloperPropertyEnum.passive,
                },
                [FilterParamsEnum.sort]: {
                  choices: filterParams[FilterParamsEnum.sort].choices,
                  value:
                    newValue && configGetFeature('new_projects').deliveryDateSortEnabled
                      ? SortParamsEnum.deliveryDateEarliest
                      : SortParamsEnum.featured,
                },
                [FilterParamsEnum.minInstallmentYears]: {
                  choices: filterParams[FilterParamsEnum.minInstallmentYears].choices,
                  value: '',
                },
                [FilterParamsEnum.maxInstallmentYears]: {
                  choices: filterParams[FilterParamsEnum.maxInstallmentYears].choices,
                  value: '',
                },
              });
            }}
            selectFieldMinValue={filterParams[FilterParamsEnum.minInstallmentYears]?.value}
            selectFieldMaxValue={filterParams[FilterParamsEnum.maxInstallmentYears]?.value}
            selectFieldMinValueChange={(value) => {
              onChangeFilters({
                [FilterParamsEnum.minInstallmentYears]: {
                  choices: filterParams[FilterParamsEnum.minInstallmentYears].choices,
                  value,
                },
              });
            }}
            selectFieldMaxValueChange={(value) => {
              onChangeFilters({
                [FilterParamsEnum.maxInstallmentYears]: {
                  choices: filterParams[FilterParamsEnum.maxInstallmentYears].choices,
                  value,
                },
              });
            }}
            selectFieldMinOptions={installmentMinOptions.map((option) => ({ value: option.value, label: option.text }))}
            selectFieldMaxOptions={installmentMaxOptions.map((option) => ({ value: option.value, label: option.text }))}
          />
        </SearchBarMoreFiltersSectionTemplate>
      );
    },
    Component: () => null,
    shouldRender: () => configGetFeature('new_projects').isDeveloperPropertyFilterEnabled,
  },
};
