export const enum StatsSnowplow2ActionEnum {
  /**
   * Flush buffer (is needed for cta events)
   */
  flushBuffer = 'flushBuffer',

  /**
   * Track self describing event action
   */
  trackSelfDescribingEvent = 'trackSelfDescribingEvent',

  /**
   * Track structurized event action
   */
  trackStructEvent = 'trackStructEvent',

  /**
   * Set user id action
   */
  setUserId = 'setUserId',

  /**
   * Page view action
   */
  trackPageView = 'trackPageView',
}
