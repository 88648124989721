import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { configGetLanguage } from 'common/helper/config/get-language';
import { configGetSettings } from 'common/helper/config/get-settings';
import { FilterSearchUrlAdapterFactory } from 'common/module/filter/search/url/adapter.factory';
import { PropertySerpLightViewStore } from 'common/module/property/serp/light/view-store';
import { FilterService } from 'common/service/filter/service';
import { LocationAutocompleteHistoryService } from 'common/service/location-autocomplete-history/service';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { PropertyAutocompleteStoreFactory } from 'desktop/module/property/autocomplete/store.factory';
import { PropertySerpLightAutocompleteService } from 'desktop/service/property-serp-light-autocomplete/service';

export const PropertySerpLightViewStoreFactory = () =>
  new PropertySerpLightViewStore(
    EventEmitterFactory(),
    FilterService(),
    configGetSettings(),
    StatsGtmProviderService(),
    PropertySerpLightAutocompleteService(),
    PropertyAutocompleteStoreFactory(FilterService()),
    LocationAutocompleteHistoryService(),
    configGetLanguage().current,
    FilterSearchUrlAdapterFactory(),
    StatsTealiumProviderService()
  );
