/**
 * Return the first item of an array which satisfies an inputted comparator
 */
export function arrayFind<T>(arr: T[], comparatorFn: (item: T, index: number) => boolean): T {
  let result = null;

  arr.some((item, index) => {
    if (!comparatorFn(item, index)) {
      return false;
    }

    result = item;
    return true;
  });

  return result;
}
