import { DefaultContext, EventObject, MachineConfig, StateSchema } from 'xstate/es';

import { PropertyContactedIdbStatechartEvent } from 'common/data/property-contacted/idb/statechart.event';
import { PropertyContactedIdbStatechartActionEnum } from 'common/data/property-contacted/idb/statechart-action.enum';
import { PropertyContactedIdbStatechartStateEnum } from 'common/data/property-contacted/idb/statechart-state.enum';

export const PropertyContactedIdbStatechart: MachineConfig<DefaultContext, StateSchema, EventObject> = {
  initial: PropertyContactedIdbStatechartStateEnum.iddle,
  states: {
    [PropertyContactedIdbStatechartStateEnum.iddle]: {
      on: {
        [PropertyContactedIdbStatechartEvent.sync]: PropertyContactedIdbStatechartStateEnum.syncing,
        [PropertyContactedIdbStatechartEvent.loadAll]: PropertyContactedIdbStatechartStateEnum.loadingAll,
      },
    },
    [PropertyContactedIdbStatechartStateEnum.loadingAll]: {
      entry: [PropertyContactedIdbStatechartActionEnum.loadAll],
      on: {
        [PropertyContactedIdbStatechartEvent.sync]: PropertyContactedIdbStatechartStateEnum.syncing,
        [PropertyContactedIdbStatechartEvent.loadAllSuccess]: {
          target: PropertyContactedIdbStatechartStateEnum.iddle,
          actions: [
            PropertyContactedIdbStatechartActionEnum.updateLocalCache,
            PropertyContactedIdbStatechartActionEnum.updateDb,
            PropertyContactedIdbStatechartActionEnum.firstLoading,
          ],
        },
      },
    },
    [PropertyContactedIdbStatechartStateEnum.syncing]: {
      entry: [PropertyContactedIdbStatechartActionEnum.updateDb],
      on: {
        [PropertyContactedIdbStatechartEvent.synced]: {
          target: PropertyContactedIdbStatechartStateEnum.iddle,
          actions: [
            PropertyContactedIdbStatechartActionEnum.updateLocalCache,
            PropertyContactedIdbStatechartActionEnum.updateDb,
          ],
        },
        [PropertyContactedIdbStatechartEvent.sync]: {
          target: PropertyContactedIdbStatechartStateEnum.iddle,
          // cancel update and start update again
          actions: [
            PropertyContactedIdbStatechartActionEnum.cancelUpdate,
            PropertyContactedIdbStatechartActionEnum.updateDb,
          ],
        },
      },
    },
  },
};
