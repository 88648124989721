import { FunctionalComponent, h } from 'preact';

import { Input2Component } from 'common/module/input2/component';
import { TextFieldTemplatePropsInterface } from 'library/text-field/template-props.interface';

// TODO-FE[TPNX-1872]: Remove dependency from Input2Component https://pfinder.atlassian.net/browse/TPNX-1872
export const TextFieldTemplate: FunctionalComponent<TextFieldTemplatePropsInterface> = (props) => {
  const isPlaceholderFloating = props.floatPlaceholder && !!props.value;

  return (
    <div className={['text-field'].concat(props.containerClassName || []).join(' ')}>
      {props.floatPlaceholder && !!props.placeholder && (
        <div className='text-field__placeholder'>
          <span
            className={`${
              isPlaceholderFloating ? 'text-field__placeholder--floating' : 'text-field__placeholder-content'
            }`}
          >
            {props.placeholder}
          </span>
        </div>
      )}
      <Input2Component
        value={props.value}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        prefix={props.prefix}
        suffix={props.suffix}
        suffixCssClass={props.suffixCssClass}
        isError={props.isError}
      >
        <input
          type={props.type}
          placeholder={props.floatPlaceholder ? null : props.placeholder}
          className={[
            'text-field__input',
            isPlaceholderFloating ? 'text-field__input--floating' : '',
            props.prefix ? 'text-field__input--with-prefix' : '',
            props.suffix ? 'text-field__input--with-suffix' : '',
          ]
            .concat(props.inputClassName || [])
            .join(' ')}
          value={props.value}
          onChange={props.onChange}
          onInput={props.handleChange}
          onClick={props.onClick}
        />
      </Input2Component>
    </div>
  );
};
