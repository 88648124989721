import { GtmStatsAdapterListingStatus } from 'pf-frontend-common/src/module/gtm/stats/adapter/listing-status';

export const PropertyListStatsGtmVideoTourPropertyImpression = (pageType: string, listingStatusLabel: string) => {
  const listingStatus = new GtmStatsAdapterListingStatus(listingStatusLabel).getData();
  return {
    event: 'customEvent',
    eventCategory: 'Virtual Tour',
    eventAction: `Virtual Tour - Impression - ${listingStatus}`,
    eventLabel: `${pageType} - Virtual Tour - Impression - ${listingStatus}`,
  };
};
