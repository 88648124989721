import { Component, h } from 'preact';

import { functionNoop } from 'common/helper/function/noop';

import { CheckboxPropsInterface } from './props.interface';
import { CheckboxStateInterface } from './state.interface';
import { CheckboxComponentTemplate } from './template';

export class CheckboxComponent extends Component<CheckboxPropsInterface, CheckboxStateInterface> {
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    onChange: functionNoop,
  };

  constructor(props: CheckboxPropsInterface) {
    super(props);
    this.state = {
      onInputClick: this.onInputClick,
    };
  }

  /**
   * Returns input checked status
   */
  public onInputClick = () => {
    const newValue: boolean = !this.props.checked;
    this.props.onChange(newValue);
  };

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return <CheckboxComponentTemplate {...this.state} {...this.props} />;
  }
}
