import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { BrowserDocumentService } from 'pf-frontend-common/src/service/browser-document/service';

import { configGetEnv } from 'common/helper/config/get-env';
import { PropertyCtaStatechartService } from 'common/module/property-cta/statechart/service';
import { PropertyCtaStore } from 'common/module/property-cta/store';
import { CaptchaService } from 'common/service/captcha/service';
import { CaptchaStorageService } from 'common/service/captcha-storage/service';
import { PropertyContactedStoreService } from 'common/service/property-contacted-store/service';

export const PropertyCtaStoreFactory = () =>
  new PropertyCtaStore(
    EventEmitterFactory(),
    PropertyContactedStoreService(),
    PropertyCtaStatechartService(),
    CaptchaService(),
    CaptchaStorageService(),
    BrowserDocumentService(),
    configGetEnv()
  );
