import { LocationModel } from 'common/data/location/model';

import { MultiLocationSelectorMakeOnAddLocationPropsInterface } from './make-on-add-location-props.interface';

export const multiLocationSelectorMakeOnAddLocation =
  ({
    locations,
    locationAutocompleteHistoryService,
    onNewLocations,
  }: MultiLocationSelectorMakeOnAddLocationPropsInterface) =>
  (newLocation: LocationModel): string[] => {
    if (locations.find((location) => location.id === newLocation.id)) {
      return;
    }

    locationAutocompleteHistoryService.add(newLocation, { maxHistoryLength: 8 });

    const newLocations = [
      // Filter is selected location is a children or parent of existing
      ...locations
        .filter((location) => {
          const isChild = newLocation.path?.split('.').includes(location.id);
          const isParent = location.path?.split('.').includes(newLocation.id);

          return !isChild && !isParent;
        })
        .map(({ id }) => id),
      newLocation.id,
    ];

    onNewLocations(newLocations);
  };
