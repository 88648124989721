import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { ChangePasswordModel } from 'common/data/change-password/model';
import { JwtTokenStore } from 'common/data/jwt/token/store';
import { UserAutoRegisterModel } from 'common/data/user/auto-register/model';
import { UserAutoRegisterRequestInterface } from 'common/data/user/auto-register/request.interface';
import { UserChangePasswordModel } from 'common/data/user/change-password/model';
import { UserChangePasswordRequestInterface } from 'common/data/user/change-password/request.interface';
import { UserEmailOtpResendModel } from 'common/data/user/email-otp-resend/model';
import { UserEmailOtpResendRequestInterface } from 'common/data/user/email-otp-resend/request.interface';
import { UserEmailVerificationModel } from 'common/data/user/email-verification/model';
import { UserEmailVerificationRequestInterface } from 'common/data/user/email-verification/request.interface';
import { UserExistsRequestInterface } from 'common/data/user/exists/request.interface';
import { UserExistsResponseModel } from 'common/data/user/exists/response-model';
import { UserLoginModel } from 'common/data/user/login/model';
import { UserLoginRequestInterface } from 'common/data/user/login/request.interface';
import { OtpRequestInterface } from 'common/data/user/otp/request.interface';
import { UserPhoneNumberLoginModel } from 'common/data/user/phone-number-login/model';
import { UserPhoneNumberLoginRequestInterface } from 'common/data/user/phone-number-login/request.interface';
import { UserRegisterModel } from 'common/data/user/register/model';
import { UserRegisterRequestInterface } from 'common/data/user/register/request.interface';
import { UserResetPasswordModel } from 'common/data/user/reset-password/model';
import { UserResetPasswordRequestInterface } from 'common/data/user/reset-password/request.interface';
import { UserSmsCountriesResponseInterface } from 'common/data/user/sms-countries/response.interface';
import { UserUpdateModel } from 'common/data/user/update/model';
import { UserUpdateRequestInterface } from 'common/data/user/update/request.interface';
import { emailVerificationEnabled } from 'common/features/email-verification/enabled';
import { UserAuthSmsCountriesInterface } from 'common/module/user/auth/sms-countries/interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { UserAuthenticationStoreServiceInterface } from 'common/service/user-authentication-store/service.interface';

export class UserAuthenticationStore implements UserAuthenticationStoreServiceInterface {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    userExists: {
      path: this.apiEndpointService.getPath('/user/exists'),
      method: 'POST',
    },
    signIn: {
      path: this.apiEndpointService.getPath('/user/login'),
      method: 'POST',
    },
    signInV2: {
      path: this.apiEndpointService.getPath('/v2/user/login'),
      method: 'POST',
    },
    register: {
      path: this.apiEndpointService.getPath('/user/register'),
      method: 'POST',
    },
    registerV2: {
      path: this.apiEndpointService.getPath('/v2/user/register'),
      method: 'POST',
    },
    autoRegister: {
      path: this.apiEndpointService.getPath('/user/auto-register'),
      method: 'POST',
    },
    resetPassword: {
      path: this.apiEndpointService.getPath('/user/reset-password'),
      method: 'POST',
    },
    changePassword: {
      path: this.apiEndpointService.getPath('/user/change-password'),
      method: 'POST',
    },
    updateUser: {
      path: this.apiEndpointService.getPath('/me'),
      method: 'PUT',
    },
    otpRequest: {
      path: this.apiEndpointService.getPath('/user/phone-login/otp'),
      method: 'POST',
    },
    emailOtpResend: {
      path: this.apiEndpointService.getPath('/user/email/verify/resend'),
      method: 'POST',
    },
    emailVerification: {
      path: this.apiEndpointService.getPath('/user/email/verify'),
      method: 'POST',
    },
    phoneLoginAuthenticate: {
      path: this.apiEndpointService.getPath('/user/phone-login/authenticate'),
      method: 'POST',
    },
    smsCountries: {
      path: this.apiEndpointService.getPath('/sms/countries'),
      method: 'GET',
    },
    logout: {
      path: this.apiEndpointService.getPath('/user/logout'),
      method: 'POST',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private apiEndpointService: ApiEndpointServiceInterface,
    private apiService: ApiServiceInterface,
    private userLoginStore: JsonApiStore<UserLoginModel>,
    private userRegisterStore: JsonApiStore<UserRegisterModel>,
    private userResetPasswordStore: JsonApiStore<UserResetPasswordModel>,
    private userChangePasswordStore: JsonApiStore<ChangePasswordModel>,
    private userAutoRegisterStore: JsonApiStore<UserAutoRegisterModel>,
    private userExistsStore?: JsonApiStore<UserExistsResponseModel>,
    private userUpdateStore?: JsonApiStore<UserUpdateModel>,
    private userPhoneNumberLoginStore?: JsonApiStore<UserPhoneNumberLoginModel>,
    private userEmailOtpResendStore?: JsonApiStore<UserEmailOtpResendModel>,
    private userEmailVerificationStore?: JsonApiStore<UserEmailVerificationModel>
  ) {}

  /**
   * @inheritDoc
   */
  public resetPassword(model: UserResetPasswordModel): Promise<UserResetPasswordModel> {
    const request: UserResetPasswordRequestInterface = {
      data: {
        type: model.jsonApiType,
        attributes: {
          email: model.email,
        },
      },
    };

    if (model.captcha_token) {
      request.data.attributes.captcha_token = model.captcha_token;
    }

    return this.apiService
      .request(this.endpoint.resetPassword.method, this.endpoint.resetPassword.path, request)
      .then((httpResponse) => {
        const sync = this.userResetPasswordStore.syncWithMeta(httpResponse.data);

        return <UserResetPasswordModel>sync.data;
      });
  }

  public changePassword(model: UserChangePasswordModel): Promise<ChangePasswordModel> {
    const request: UserChangePasswordRequestInterface = {
      data: {
        type: model.jsonApiType,
        attributes: {
          password: model.password,
          repeat_password: model.repeat_password,
          reset_token: model.reset_token,
        },
      },
    };

    return this.apiService
      .request(this.endpoint.changePassword.method, this.endpoint.changePassword.path, request)
      .then((httpResponse) => {
        const sync = this.userChangePasswordStore.syncWithMeta(httpResponse.data);

        return <ChangePasswordModel>sync.data;
      });
  }

  /**
   * @inheritDoc
   */
  public signIn(model: UserLoginModel, isNewModal: boolean = false): Promise<UserLoginModel> {
    const enableV2 = isNewModal && emailVerificationEnabled();
    const requestData: UserLoginRequestInterface = {
      data: {
        type: model.jsonApiType,
        attributes: {
          email: model.email,
          password: model.password,
          remember_me: model.remember_me,
        },
      },
    };

    if (model.captcha_token) {
      requestData.data.attributes.captcha_token = model.captcha_token;
    }

    return this.apiService
      .request(
        enableV2 ? this.endpoint.signInV2.method : this.endpoint.signIn.method,
        enableV2 ? this.endpoint.signInV2.path : this.endpoint.signIn.path,
        requestData
      )
      .then((httpResponse) => {
        const sync = this.userLoginStore.syncWithMeta(httpResponse.data);

        return <UserLoginModel>sync.data;
      });
  }

  public otpRequest(phoneNumber: string, captcha?: string): Promise<UserLoginModel> {
    const requestData: OtpRequestInterface = {
      data: {
        type: 'phone_login_otp',
        attributes: {
          phone: phoneNumber,
          ...{ ...(captcha ? { captcha_token: captcha } : {}) },
        },
      },
    };
    return this.apiService
      .request(this.endpoint.otpRequest.method, this.endpoint.otpRequest.path, requestData)
      .then((httpResponse) => {
        const sync = this.userLoginStore.syncWithMeta(httpResponse.data);

        return <UserLoginModel>sync.data;
      });
  }

  public phoneLoginAuthenticate(model: UserPhoneNumberLoginModel): Promise<UserPhoneNumberLoginModel> {
    const requestData: UserPhoneNumberLoginRequestInterface = {
      data: {
        type: UserPhoneNumberLoginModel.JSONAPI_TYPE,
        attributes: {
          phone: model.phone,
          otp: model.otp,
        },
      },
    };

    return this.apiService
      .request(this.endpoint.phoneLoginAuthenticate.method, this.endpoint.phoneLoginAuthenticate.path, requestData)
      .then((httpResponse) => {
        const sync = this.userPhoneNumberLoginStore.syncWithMeta(httpResponse.data);

        return <UserPhoneNumberLoginModel>sync.data;
      });
  }

  public emailOtpResend(credentials: UserEmailOtpResendModel): Promise<UserEmailOtpResendModel> {
    const requestData: UserEmailOtpResendRequestInterface = {
      data: {
        type: UserEmailOtpResendModel.JSONAPI_TYPE,
        attributes: {
          email: credentials.email,
        },
      },
    };

    return this.apiService
      .request(this.endpoint.emailOtpResend.method, this.endpoint.emailOtpResend.path, requestData)
      .then((httpResponse) => {
        const sync = this.userEmailOtpResendStore.syncWithMeta(httpResponse.data);

        return <UserEmailOtpResendModel>sync.data;
      });
  }

  public emailVerification(credentials: UserEmailVerificationModel): Promise<UserEmailVerificationModel> {
    const requestData: UserEmailVerificationRequestInterface = {
      data: {
        type: UserEmailVerificationModel.JSONAPI_TYPE,
        attributes: {
          email: credentials.email,
          code: credentials.code,
        },
      },
    };

    return this.apiService
      .request(this.endpoint.emailVerification.method, this.endpoint.emailVerification.path, requestData)
      .then((httpResponse) => {
        const sync = this.userEmailVerificationStore.syncWithMeta(httpResponse.data);

        return <UserEmailVerificationModel>sync.data;
      });
  }

  /**
   * @inheritDoc
   */
  public userExists(email: string): Promise<UserExistsResponseModel> {
    const requestData: UserExistsRequestInterface = {
      data: {
        type: UserExistsResponseModel.JSONAPI_TYPE,
        attributes: {
          email,
        },
      },
    };

    return this.apiService
      .request(this.endpoint.userExists.method, this.endpoint.userExists.path, requestData)
      .then((httpResponse) => {
        const sync = this.userExistsStore.syncWithMeta(httpResponse.data);
        return <UserExistsResponseModel>sync.data;
      });
  }

  /**
   * @inheritDoc
   */
  public register(model: UserRegisterModel, isNewModal: boolean = false): Promise<UserRegisterModel> {
    const enableV2 = isNewModal && emailVerificationEnabled();
    const request: UserRegisterRequestInterface = {
      data: {
        type: model.jsonApiType,
        attributes: {
          first_name: model.first_name,
          last_name: model.last_name,
          email: model.email,
          password: model.password,
          opted_in: model.opted_in,
        },
      },
    };

    if (model.captcha_token) {
      request.data.attributes.captcha_token = model.captcha_token;
    }

    return this.apiService
      .request(
        enableV2 ? this.endpoint.registerV2.method : this.endpoint.register.method,
        enableV2 ? this.endpoint.registerV2.path : this.endpoint.register.path,
        request
      )
      .then((httpResponse) => {
        const sync = this.userRegisterStore.syncWithMeta(httpResponse.data);

        return <UserRegisterModel>sync.data;
      });
  }

  /**
   * @inheritDoc
   */
  public autoRegister(model: UserAutoRegisterModel): Promise<UserAutoRegisterModel> {
    const request: UserAutoRegisterRequestInterface = {
      data: {
        type: UserAutoRegisterModel.JSONAPI_TYPE,
        attributes: {
          first_name: model.first_name,
          last_name: model.last_name,
          email: model.email,
          phone: model.phone,
        },
      },
    };

    return this.apiService
      .request(this.endpoint.autoRegister.method, this.endpoint.autoRegister.path, request)
      .then(this.onAutoRegisterSucceeded);
  }

  /**
   * @description API request to update user details on the BE side
   * @param model model for new user data
   * @param token token value
   */
  public updateUser(model: UserUpdateModel, token: string): Promise<UserUpdateModel> {
    const requestData: UserUpdateRequestInterface = {
      data: {
        type: UserUpdateModel.JSONAPI_TYPE,
        attributes: {
          email: model.email,
          first_name: model.first_name,
        },
      },
    };

    return this.apiService
      .request(
        this.endpoint.updateUser.method,
        this.endpoint.updateUser.path,
        requestData,
        true,
        this.getApiHeaders(token)
      )
      .then((httpResponse) => {
        const sync = this.userUpdateStore.syncWithMeta(httpResponse.data);
        return <UserUpdateModel>sync.data;
      });
  }

  /**
   *
   * @description API request to clear the token on the BE side
   * @param token
   */
  public logout(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiService
        .request(this.endpoint.logout.method, this.endpoint.logout.path, {}, false, {
          [JwtTokenStore.HEADER_JWT]: 'Bearer ' + token,
        })
        .then((res) => {
          if (res.status >= 300) {
            reject(res);
          }
          resolve();
        })
        .catch(reject);
    });
  }

  public getSmsCountriesList(): Promise<UserAuthSmsCountriesInterface[]> {
    return new Promise((resolve, reject) => {
      this.apiService
        .request(this.endpoint.smsCountries.method, this.endpoint.smsCountries.path, {})
        .then((httpResponse) => resolve(this.mapSmsCountriesResponse(httpResponse.data.data)))
        .catch(reject);
    });
  }

  public mapSmsCountriesResponse(
    response: Pick<UserSmsCountriesResponseInterface['data'], 'attributes'>
  ): UserAuthSmsCountriesInterface[] {
    return response.attributes.countries.map((country) => {
      return <UserAuthSmsCountriesInterface>{
        name: country.name,
        code: country.code.toLocaleLowerCase(),
        phoneCode: country.phone_code,
        smsSupported: country.supported,
      };
    });
  }

  /**
   * Auto register succeeded
   */
  private onAutoRegisterSucceeded = (httpResponse: HttpApiResponseInterface): UserAutoRegisterModel => {
    const sync = this.userAutoRegisterStore.syncWithMeta(httpResponse.data);

    return <UserAutoRegisterModel>sync.data;
  };

  private getApiHeaders(token: string): DataKeyValueStringInterface {
    const headers: DataKeyValueStringInterface = {};
    headers[JwtTokenStore.HEADER_JWT] = 'Bearer ' + token;

    return headers;
  }
}
