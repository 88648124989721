import { BackendConfigCountryInterface } from 'common/data/backend/config/country.interface';
import { CountryCodeEnum } from 'common/data/country/code.enum';

/**
 * Default country configuration
 */
export const BackendConfigCountryDefault: BackendConfigCountryInterface = {
  code: CountryCodeEnum.ae,
  currencyCode: 'AED',
  areaUnit: 'sqft',
  propertyDetails: {
    displayRera: false,
    moversServices: false,
    mortgageRent: false,
    mortgageSpecialist: false,
  },
  propertyShare: {
    captchaRequired: false,
  },
  agentSection: {
    displayHeadOffice: true,
    agentsEnabled: true,
  },
};
