import { UserModel } from 'common/data/user/model';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { StatsTealiumDataLayerEventTypeEnum } from 'common/module/stats/tealium/data-layer/event-type.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

import { UserAuthenticationEvent } from '../authentication.event';
import { UserPopupLoginStatusEnum } from '../popup/login-status.enum';

export class UserAuthTracker {
  private eventReference: StatsTealiumDataLayerEventReferenceEnum;

  private user: {
    user_id?: string;
    user_email?: string;
    user_phone_number?: string;
    user_full_name?: string;
    user_status: UserPopupLoginStatusEnum;
  } = {
    user_status: UserPopupLoginStatusEnum.notLoggedIn,
  };

  constructor(
    private statsTealium: StatsTealiumProviderInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface
  ) {
    this.observers();
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onLoginSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onLoginSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.phoneNumberLoginSucceeded, this.onPhoneNumberLoginSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.userDataCleared, this.onLogout);
  }

  private onLogout = (): void => {
    this.user = {
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    };
  };

  private onPhoneNumberLoginSucceeded = ({ user }: { user: UserModel }): void => {
    this.setUser(user);
  };

  private onLoginSucceeded = (user: {
    userId: string;
    userEmail: string;
    userFirstName: string;
    userLastName: string;
    phone: string;
  }): void => {
    this.setUser({
      id: user.userId || this.user.user_id,
      email: user.userEmail || this.user.user_email,
      first_name: user.userFirstName,
      last_name: user.userLastName,
      phone: user.phone || this.user.user_phone_number,
    } as UserModel);
  };

  public setUser = (user?: UserModel): void => {
    this.user = user
      ? {
          user_id: user.id,
          user_email: user.email,
          user_full_name: `${user.first_name || ''} ${user.last_name || ''}`.trim(),
          user_phone_number: user.phone || '',
          user_status: UserPopupLoginStatusEnum.loggedIn,
        }
      : {
          user_status: UserPopupLoginStatusEnum.notLoggedIn,
        };
  };

  public onLoginModalOpen = (eventReference: StatsTealiumDataLayerEventReferenceEnum): void => {
    this.eventReference = eventReference;
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'login_modal_opened',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'login_modal_opened',
      event_reference: eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onOptionButtonClick = (button: 'google' | 'facebook' | 'email' | 'phone'): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'login_option_clicked',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'login_option_clicked',
      event_reference: this.eventReference,
      event_label: button === 'phone' ? 'phone_number' : button,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onCountrySelected = (phoneCode: string, supported: boolean): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: supported ? 'phone_country_selected' : 'unsupported_country_prompt_opened',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: supported ? 'phone_country_selected' : 'unsupported_country_prompt_opened',
      event_reference: this.eventReference,
      event_label: phoneCode,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onPhoneLoginSubmission = (phoneCode: string, phoneInvalid?: boolean): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: phoneInvalid ? 'invalid_phone_number_error' : 'login_phone_number_submitted',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: phoneInvalid ? 'invalid_phone_number_error' : 'login_phone_number_submitted',
      event_reference: this.eventReference,
      event_label: phoneCode,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onOtpScreenOpened = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'phone_number_confirmation_screen_opened',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'phone_number_confirmation_screen_opened',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onOtpCodeSubmitted = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'phone_number_confirmation_code_entered',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'phone_number_confirmation_code_entered',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onOtpCodeSubmittedError = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'invalid_confirmation_code_error',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'invalid_confirmation_code_error',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onOtpRetrySubmitted = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'phone_number_try_again_button_clicked',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'phone_number_try_again_button_clicked',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onCompleteProfileSkipped = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'skip_complete_profile_button_clicked',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'skip_complete_profile_button_clicked',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      ...this.user,
      user_status: UserPopupLoginStatusEnum.loggedIn,
    });
  };

  public onCompleteProfileSubmitted = ({ email, name }: { email?: string; name?: string }): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'complete_profile_button_submitted',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'complete_profile_button_submitted',
      event_reference: this.eventReference,
      event_label: email && name ? 'email,name' : email ? 'email' : name ? 'name' : '',
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      ...this.user,
      user_status: UserPopupLoginStatusEnum.loggedIn,
      user_email: email || this.user?.user_email,
      user_full_name: name || this.user?.user_full_name,
    });
  };

  public onCompleteProfileSubmittedError = (errors?: Record<string, string>): void => {
    if (!errors?.email) {
      return;
    }
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'existing_email_entered',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'existing_email_entered',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      ...this.user,
      user_status: UserPopupLoginStatusEnum.loggedIn,
    });
  };

  public onEmailVerificationModalOpen = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'email_verification_modal_opened',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'email_verification_modal_opened',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onEmailVerificationOtpCodeSubmitted = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'email_verification_code_entered',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'email_verification_code_entered',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onEmailVerificationInvalidOtpCode = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'invalid_email_verification_code_error',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'invalid_email_verification_code_error',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };

  public onEmailVerificationCompleted = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'email_verification_completed',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'email_verification_completed',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      ...this.user,
    });
  };

  public onEmailVerificationOtpTryAgainClicked = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      tealium_event: 'email_verification_try_again_button_clicked',
      event_category: StatsTealiumDataLayerEventCategoryEnum.user,
      event_action: 'email_verification_try_again_button_clicked',
      event_reference: this.eventReference,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      user_status: UserPopupLoginStatusEnum.notLoggedIn,
    });
  };
}
