import { CountryCodeEnum } from 'common/data/country/code.enum';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { FilterWidgetTypeEnum } from 'desktop/module/filter-widget/type.enum';

import { FilterWidgetConfigInterface } from './config.interface';

// TODO-FE[TPNX-1878] Move this configuration to backend
/**
 * returns the visible filter widgets in search-form
 */
export const filterWidgetGetFiltersConfig = (country: CountryCodeEnum) => {
  const configs: Record<CountryCodeEnum, FilterWidgetConfigInterface> = {
    [CountryCodeEnum.ae]: {
      [PropertyCategoryIdentifierEnum.residentialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.furnished,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.amenities,
        FilterWidgetTypeEnum.keywords,
        FilterWidgetTypeEnum.virtualViewings,
      ],
      [PropertyCategoryIdentifierEnum.residentialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.completion,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.amenities,
        FilterWidgetTypeEnum.keywords,
        FilterWidgetTypeEnum.virtualViewings,
        FilterWidgetTypeEnum.isDeveloperProperty,
      ],
      [PropertyCategoryIdentifierEnum.commercialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
        FilterWidgetTypeEnum.virtualViewings,
      ],
      [PropertyCategoryIdentifierEnum.commercialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
        FilterWidgetTypeEnum.virtualViewings,
      ],
    },
    [CountryCodeEnum.eg]: {
      [PropertyCategoryIdentifierEnum.residentialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.furnished,
        FilterWidgetTypeEnum.keywords,
      ],
      [PropertyCategoryIdentifierEnum.residentialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.isDeveloperProperty,
        FilterWidgetTypeEnum.keywords,
      ],
      [PropertyCategoryIdentifierEnum.commercialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
      ],
      [PropertyCategoryIdentifierEnum.commercialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
      ],
    },
    [CountryCodeEnum.bh]: {
      [PropertyCategoryIdentifierEnum.residentialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.furnished,
        FilterWidgetTypeEnum.utilitiesPriceType,
        FilterWidgetTypeEnum.keywords,
      ],
      [PropertyCategoryIdentifierEnum.residentialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
        FilterWidgetTypeEnum.isDeveloperProperty,
      ],
      [PropertyCategoryIdentifierEnum.commercialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
      ],
      [PropertyCategoryIdentifierEnum.commercialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
      ],
    },
    [CountryCodeEnum.sa]: {
      [PropertyCategoryIdentifierEnum.residentialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.furnished,
        FilterWidgetTypeEnum.keywords,
      ],
      [PropertyCategoryIdentifierEnum.residentialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.bedBath,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
        FilterWidgetTypeEnum.isDeveloperProperty,
      ],
      [PropertyCategoryIdentifierEnum.commercialForRent]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
      ],
      [PropertyCategoryIdentifierEnum.commercialForSale]: [
        FilterWidgetTypeEnum.propertyType,
        FilterWidgetTypeEnum.price,
        FilterWidgetTypeEnum.area,
        FilterWidgetTypeEnum.keywords,
      ],
    },
  } as Record<CountryCodeEnum, FilterWidgetConfigInterface>;

  return configs[country] || configs.ae;
};
