import { BrowserStorageLocalService } from 'pf-frontend-common/src/service/browser-storage-local/service';

import { AgentStoreFactory } from 'common/data/agent/store.factory';
import { AutocompleteCacheAgentsStore } from 'common/data/autocomplete/cache/agents/store';
import { AutocompleteCacheWorkerFactory } from 'common/data/autocomplete/cache/worker.factory';
import { configGetLanguage } from 'common/helper/config/get-language';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { DatabaseService } from 'common/service/database/service';

const service = new AutocompleteCacheAgentsStore(
  BrowserStorageLocalService(),
  configGetLanguage(),
  ApiEndpointService(),
  DatabaseService(),
  AgentStoreFactory(),
  AutocompleteCacheWorkerFactory(),
  window.location.origin
);

export function AutocompleteCacheAgentsService(): AutocompleteCacheAgentsStore {
  return service;
}
