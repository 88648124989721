import { domClassMerge } from '@propertyfinder/helpers';
import { BrowserWindowEvent } from '@propertyfinder/pf-frontend-common/src/module/browser/window.event';
import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';

import { BackendConfigSettingsInterface } from 'common/data/backend/config/settings.interface';
import { CountryCodeEnum } from 'common/data/country/code.enum';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { abTestsIsVariant } from 'common/helper/ab-tests/is-variant';
import { configGetLanguage } from 'common/helper/config/get-language';
import { configGetSettings } from 'common/helper/config/get-settings';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterSearchUrlParamsEnum } from 'common/module/filter/search/url/params.enum';
import { FilterServerInterface } from 'common/module/filter/server.interface';
import { useFilterStoreParams } from 'common/module/filter/store/params';
import { DataGuruLinkTemplate } from 'common/module/home-page/data-guru-link/template';
import { IconChevronTemplate } from 'common/module/icon/chevron';
import { KeywordsComponent } from 'common/module/keywords/component';
import { LanguageContext } from 'common/module/language/context';
import { FilterFormSearchButton } from 'desktop/module/filter-form/search-button-component';
import { FilterWidgetComponent } from 'desktop/module/filter-widget/component';
import { filterWidgetComputeVisibleAndHiddenFilters } from 'desktop/module/filter-widget/compute-visible-and-hidden-filters';
import { FilterWidgetResetValues } from 'desktop/module/filter-widget/reset-values';
import { FilterWidgetTypeEnum } from 'desktop/module/filter-widget/type.enum';
import { MultiLocationSelectorComponent } from 'desktop/module/multi-location-selector/component';
import { CheckboxComponent } from 'library/checkbox/component';
import { ChipComponent } from 'library/chip/component';

import { BuyVsRentPageLink } from './buy-vs-rent-page-link/element';
import { HomePageSearchFormComponentFactoryPropsInterface } from './component-factory-props.interface';

// these widgets would be excluded on rendering home-page filters
const HomePageSearchFormGetFilterBlacklist = [
  FilterWidgetTypeEnum.keywords,
  FilterWidgetTypeEnum.virtualViewings,
  FilterWidgetTypeEnum.isDeveloperProperty,
];

const VerticalSpace = ({ className }: { className?: string }) => (
  <div className={`filter-form-component-variant__v-space ${className ? className : ''}`} />
);

const getDefaultCategory = () =>
  configGetSettings<{ searchForm: FilterServerInterface } & BackendConfigSettingsInterface>().searchForm[
    FilterSearchUrlParamsEnum.categoryId
  ].value;

const categoryIdentifierAdapter = new PropertyCategoryIdentifierAdapter();

const computeCategoryOptions = ({
  category,
  setCategory,
}: {
  category: PropertyCategoryIdentifierEnum;
  setCategory: (category: string) => void;
}) => {
  const isCommercial = categoryIdentifierAdapter.isCommercial(category);

  const BuyChip = () => (
    <ChipComponent
      className='chip-component'
      label={i18nTranslate('Buy')}
      isSelected={
        category === PropertyCategoryIdentifierEnum.commercialForSale ||
        category === PropertyCategoryIdentifierEnum.residentialForSale
      }
      onClick={() =>
        setCategory(
          isCommercial
            ? PropertyCategoryIdentifierEnum.commercialForSale
            : PropertyCategoryIdentifierEnum.residentialForSale
        )
      }
    />
  );

  const RentChip = () => (
    <ChipComponent
      className='chip-component'
      label={i18nTranslate('Rent')}
      isSelected={
        category === PropertyCategoryIdentifierEnum.commercialForRent ||
        category === PropertyCategoryIdentifierEnum.residentialForRent
      }
      onClick={() =>
        setCategory(
          isCommercial
            ? PropertyCategoryIdentifierEnum.commercialForRent
            : PropertyCategoryIdentifierEnum.residentialForRent
        )
      }
    />
  );

  return categoryIdentifierAdapter.isRental(getDefaultCategory()) ? [RentChip, BuyChip] : [BuyChip, RentChip];
};

const computeFilterRows = ({
  filterParams,
  countryCode,
  onChangeFilters,
}: {
  filterParams: FilterParametersBaseInterface;
  countryCode: CountryCodeEnum;
  onChangeFilters: (value: FilterParametersBaseInterface) => void;
}) => {
  const { visibleFilterTypes, hiddenFiltersTypes } = filterWidgetComputeVisibleAndHiddenFilters(
    {
      filterParams,
      countryCode,
    },
    HomePageSearchFormGetFilterBlacklist
  );

  // Home page is designed to render up to 3 hidden filters
  if (hiddenFiltersTypes.length > 3) {
    hiddenFiltersTypes.length = 3;
  }

  const renderFilters = (filtersTypes: FilterWidgetTypeEnum[], className?: string) =>
    filtersTypes.map((filterType) => (
      <FilterWidgetComponent
        key={filterType}
        filterType={filterType}
        className={className}
        onChangeFilters={onChangeFilters}
        filterParams={filterParams}
      />
    ));

  return {
    firstRowFilters: renderFilters(visibleFilterTypes, 'filter-form-component-variant__sm-hide'),
    secondRowFilters: renderFilters(visibleFilterTypes, 'filter-form-component-variant__m-hide'),
    hiddenRowFilters: renderFilters(hiddenFiltersTypes),
  };
};

// TODO-FE[TPNX-2076] Add unit tests for home-page filter form
export const HomePageSearchFormComponentFactory = (props: HomePageSearchFormComponentFactoryPropsInterface) => () => {
  // State
  const [expanded, setExpanded] = useState(false);
  const [isSearchFormScrolled, setIsSearchFormScrolled] = useState(false);

  // Variables
  const filterParams = useFilterStoreParams(props.filterStore);
  const categoryValue = filterParams[FilterParamsEnum.categoryId].value;
  const category: PropertyCategoryIdentifierEnum = categoryIdentifierAdapter.getData(
    parseInt(categoryValue, 10)
  ) as PropertyCategoryIdentifierEnum;
  const isCommercial = categoryIdentifierAdapter.isCommercial(category);

  // Helpers
  /**
   * On change filters
   * @param value
   */
  const onChangeFilters = (value: FilterParametersBaseInterface) => props.filterStore.update(value);

  /**
   * resets filter values on category updates
   * @param categoryIdentifier
   */
  const resetFiltersOnCategoryChange = (categoryIdentifier: PropertyCategoryIdentifierEnum) => {
    const selectedCategory = categoryIdentifierAdapter.getValue(categoryIdentifier);
    if (selectedCategory !== filterParams[FilterParamsEnum.categoryId].value) {
      FilterWidgetResetValues(onChangeFilters)(selectedCategory);
    }
  };

  /**
   * On submit
   */
  const onSubmit = (): void => {
    props.filterSearchUrlAdapter.redirect(props.searchUri, props.filterStore.getState());
  };

  /**
   * Toggle commercial
   */
  const toggleCommercial = (): void => {
    if (categoryIdentifierAdapter.isSale(categoryValue)) {
      resetFiltersOnCategoryChange(
        isCommercial
          ? PropertyCategoryIdentifierEnum.residentialForSale
          : PropertyCategoryIdentifierEnum.commercialForSale
      );
    } else {
      resetFiltersOnCategoryChange(
        isCommercial
          ? PropertyCategoryIdentifierEnum.residentialForRent
          : PropertyCategoryIdentifierEnum.commercialForRent
      );
    }
  };

  const isAE = props.countryCode === CountryCodeEnum.ae;

  // Render
  const { firstRowFilters, secondRowFilters, hiddenRowFilters } = computeFilterRows({
    filterParams,
    countryCode: props.countryCode,
    onChangeFilters,
  });

  const onScrollWindow = (): void => {
    if (window.pageYOffset > 500) {
      setIsSearchFormScrolled(true);
    } else {
      setIsSearchFormScrolled(false);
    }
  };

  useEffect(() => {
    if (abTestsIsVariant('test123', 'variantA')) {
      props.WindowService.getEventEmitter().addListener(BrowserWindowEvent.scroll, onScrollWindow);

      return (): void => {
        props.WindowService.getEventEmitter().removeListener(BrowserWindowEvent.scroll, onScrollWindow);
      };
    }
  }, []);

  return (
    <LanguageContext.Provider value={configGetLanguage()}>
      <div
        className={domClassMerge(
          'filter-form-component-variant',
          isSearchFormScrolled ? 'filter-form-component-variant--fixed' : ''
        )}
      >
        <div className='filter-form-component-variant__category-selector'>
          {computeCategoryOptions({ category, setCategory: resetFiltersOnCategoryChange }).map((Option) => (
            <Option />
          ))}
          {abTestsIsVariant('test116', 'variantA') && <BuyVsRentPageLink />}
          {isAE && (
            <div className='filter-form-component-variant__checkbox-container'>
              <div className='filter-form-component-variant__separator' />
              <CheckboxComponent
                id='commercial-checkbox-top'
                checked={isCommercial}
                containerClassName='filter-form-component-variant__checkbox'
                onChange={toggleCommercial}
                labelClassName='filter-form-component-variant__checkbox-label'
              >
                {i18nTranslate('Show commercial properties only')}
              </CheckboxComponent>
            </div>
          )}
        </div>
        <VerticalSpace />
        <div
          className={`
            filter-form-component-variant__row
            filter-form-component-variant__row-${firstRowFilters.length}
          `}
        >
          <MultiLocationSelectorComponent
            onChange={(locationsIds: string[]) =>
              onChangeFilters({ [FilterParamsEnum.locationsIds]: { value: locationsIds } })
            }
            value={filterParams[FilterParamsEnum.locationsIds]?.value || []}
          />
          <FilterFormSearchButton className='filter-form-component-variant__m-hide' onClick={onSubmit} />
          {firstRowFilters}
          <FilterFormSearchButton className='filter-form-component-variant__sm-hide' onClick={onSubmit} />
        </div>

        <VerticalSpace className='filter-form-component-variant__m-hide' />
        <div
          className={`
            filter-form-component-variant__row
            filter-form-component-variant__row-second-${secondRowFilters.length}
            filter-form-component-variant__m-hide
          `}
        >
          {secondRowFilters}
        </div>
        <div id='toggle-row' style={{ display: expanded ? 'block' : 'none' }}>
          <VerticalSpace />
          <div
            className={`
              filter-form-component-variant__row
              filter-form-component-variant__row-last-${hiddenRowFilters.length}
            `}
          >
            {hiddenRowFilters}
            <KeywordsComponent
              category={category}
              onChange={(keywords) => onChangeFilters({ [FilterParamsEnum.keyword]: { value: keywords } })}
              value={filterParams[FilterParamsEnum.keyword]?.value || ''}
            />
          </div>
        </div>
        <footer className='filter-form-component-variant__footer'>
          {isAE ? (
            <DataGuruLinkTemplate category={category} />
          ) : (
            <CheckboxComponent
              id='commercial-checkbox-bottom'
              checked={isCommercial}
              containerClassName='filter-form-component-variant__checkbox'
              onChange={toggleCommercial}
              labelClassName='filter-form-component-variant__checkbox-label'
            >
              {i18nTranslate('Show commercial properties only')}
            </CheckboxComponent>
          )}
          <button
            className={`filter-form-component-variant__show-more-options${
              expanded ? ' filter-form-component-variant__show-more-options--opened' : ''
            }`}
            onClick={(): void => setExpanded(!expanded)}
            type='button'
            data-testid='show-more-options'
          >
            {i18nTranslate(expanded ? 'Show less search options' : 'Show more search options')}{' '}
            <IconChevronTemplate class='filter-form-component-variant__show-more-options-icon' />
          </button>
        </footer>
      </div>
    </LanguageContext.Provider>
  );
};
