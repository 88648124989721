import { FunctionalComponent, h } from 'preact';
import { useMemo, useRef } from 'preact/hooks';

import { configGetCountry } from 'common/helper/config/get-country';

import { NewEverydayCardInterface } from './card.interface';
import { NewEverydayComponentPropsInterface } from './component-props.interface';
import { convertSomethingNewlist } from './convert-something-newlist';
import { NewEverydayCategory } from './enum';
import { NewEverydayCardRent } from './new-for-rent';
import { NewEverydayCardSale } from './new-for-sale';
import { NewEverydayTemplate } from './template';
import { NewEverydayTemplatePropsInterface } from './template-props.interface';
import { NewEverydayTracker } from './tracker';
import { NewEverydayTrackerFactory } from './tracker.factory';

const NewEverydayHOC =
  (tracker: NewEverydayTracker) =>
  (
    WrappedComponent: FunctionalComponent<NewEverydayTemplatePropsInterface>
  ): FunctionalComponent<NewEverydayComponentPropsInterface> =>
  ({ cards, salepath, rentpath }) => {
    const saleNextItemRef = useRef(null);
    const salePrevItemRef = useRef(null);

    const rentNextItemRef = useRef(null);
    const rentPrevItemRef = useRef(null);

    const cardData: NewEverydayCardInterface[] = useMemo(() => JSON.parse(cards), [cards]);

    const newEveryDayRent = NewEverydayCardRent[configGetCountry().code] || [];
    const newEveryDaySale = NewEverydayCardSale[configGetCountry().code] || [];

    const saleCards: NewEverydayCardInterface[] = convertSomethingNewlist(
      newEveryDaySale,
      cardData,
      NewEverydayCategory.SALE_TYPE
    );
    const rentCards: NewEverydayCardInterface[] = convertSomethingNewlist(
      newEveryDayRent,
      cardData,
      NewEverydayCategory.RENT_TYPE
    );

    const categories = [
      {
        category: NewEverydayCategory.RENT,
        data: rentCards,
        nextRef: rentNextItemRef,
        prevRef: rentPrevItemRef,
        allPath: useMemo<string>(() => JSON.parse(rentpath), [rentpath]),
      },
      {
        category: NewEverydayCategory.SALE,
        data: saleCards,
        nextRef: saleNextItemRef,
        prevRef: salePrevItemRef,
        allPath: useMemo<string>(() => JSON.parse(salepath), [salepath]),
      },
    ];

    return <WrappedComponent categories={categories} tracker={tracker} />;
  };

export const NewEverydayComponent = NewEverydayHOC(NewEverydayTrackerFactory())(NewEverydayTemplate);
