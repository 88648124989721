import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from './template-props.interface';

export const IconHyphen: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg className={props.class} viewBox='0 0 16 2'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      clipRule='evenodd'
      /* tslint:disable-next-line: max-line-length */
      d='M15.5 0.5C15.7761 0.5 16 0.723858 16 1C16 1.24546 15.7761 1.49194 15.5 1.49194L0.5 1.5C0.223858 1.5 0 1.27614 0 1C0 0.75454 0.206389 0.5 0.5 0.508056L15.5 0.5Z'
    />
  </svg>
);
