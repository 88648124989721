import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { BackendConfigSettingsInterface } from 'common/data/backend/config/settings.interface';
import { configGetCountry } from 'common/helper/config/get-country';
import { ConfigLanguageCodeEnum } from 'common/helper/config/language-code.enum';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteViewStore } from 'common/module/autocomplete/view-store';
import { AutocompleteViewStoreEvent } from 'common/module/autocomplete/view-store.event';
import { FilterHandlerViewStore } from 'common/module/filter/handler/view-store';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterSearchUrlAdapter } from 'common/module/filter/search/url/adapter';
import { FilterStore } from 'common/module/filter/store';
import { PropertyAutocompleteStoreDecoratorEvent } from 'common/module/property/autocomplete/store/decorator.event';
import { PropertyAutocompleteStoreDecoratorInterface } from 'common/module/property/autocomplete/store/decorator.interface';
import { PropertySerpLightPopularSearches } from 'common/module/property/serp/light/popular-searches';
import { PropertySerpLightViewStoreStateInterface } from 'common/module/property/serp/light/view-store-state.interface';
import { StatsGtmDataLayerInterface } from 'common/module/stats/gtm/data-layer.interface';
import { StatsGtmProviderInterface } from 'common/module/stats/gtm/provider.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { LocationAutocompleteHistoryServiceInterface } from 'common/service/location-autocomplete-history/service.interface';

import { PropertySerpLightOptionsInterface } from './options.interface';
import { propertySerpLightStatsGtmChangedFilter } from './stats/gtm/changed-filter';
import { PropertySerpLightStatsGtmFormSubmit } from './stats/gtm/form-submit';
import { PropertySerpLightStatsGtmLocationClick } from './stats/gtm/location-click';

export class PropertySerpLightViewStore extends FilterHandlerViewStore<
  PropertySerpLightViewStoreStateInterface,
  PropertySerpLightOptionsInterface
> {
  protected timer: NodeJS.Timeout;

  /**
   * Popular searches section id
   */
  private readonly popularSearchesSectionId: string = 'popular-searches';

  /**
   * Historical searches section id
   */
  private readonly historicalSearchesSectionId: string = 'history';

  /**
   * Autocomplete search uri
   */
  private searchUri: string;

  constructor(
    eventEmitter: EventEmitterInterface,
    filterStore: FilterStore,
    private settings: BackendConfigSettingsInterface,
    private statsGtm: StatsGtmProviderInterface,
    private autocompleteViewStoreService: AutocompleteViewStore,
    private autocompleteStore: PropertyAutocompleteStoreDecoratorInterface,
    private locationAutocompleteHistoryService: LocationAutocompleteHistoryServiceInterface,
    private languageCode: ConfigLanguageCodeEnum,
    private filterSearchUrlAdapter: FilterSearchUrlAdapter,
    private statsTealium: StatsTealiumProviderInterface
  ) {
    super(eventEmitter, filterStore);
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    this.autocompleteViewStoreService
      .getEventEmitter()
      .removeListener(AutocompleteViewStoreEvent.clickInput, this.onClickInputAutocomplete);

    this.autocompleteViewStoreService
      .getEventEmitter()
      .removeListener(AutocompleteViewStoreEvent.keyUp, this.onKeyUpAutocomplete);

    this.autocompleteViewStoreService
      .getEventEmitter()
      .removeListener(AutocompleteViewStoreEvent.selectResultItem, this.onSelectResultItemAutocomplete);

    this.autocompleteStore
      .getEventEmitter()
      .removeListener(PropertyAutocompleteStoreDecoratorEvent.searchSuccess, this.onSearchSuccessAutocompleteStore);

    super.destroy();
  }

  /**
   * @inheritdoc
   */
  public initialize(props: PropertySerpLightOptionsInterface): void {
    super.initialize(props);
    this.state = {
      ...this.state,
      onChangeFilters: this.onChangeFilters,
      onApplyFilters: this.onApplyFilters,
      sendStatsGtm: this.sendStatsGtm,
      onSubmit: this.onSubmit,
      onCommercialToggleClick: this.onCommercialToggleClick,
      onShowPropertiesClick: this.onShowPropertiesClick,
    };

    // Store search uri
    this.searchUri = props.searchUri;
    // Init autocomplete service
    this.initAutocomplete();
  }

  /**
   * Attaches event listeners
   */
  protected observers(): void {
    super.observers();

    this.autocompleteViewStoreService
      .getEventEmitter()
      .addListener(AutocompleteViewStoreEvent.clickInput, this.onClickInputAutocomplete);

    this.autocompleteViewStoreService
      .getEventEmitter()
      .addListener(AutocompleteViewStoreEvent.keyUp, this.onKeyUpAutocomplete);

    this.autocompleteViewStoreService
      .getEventEmitter()
      .addListener(AutocompleteViewStoreEvent.selectResultItem, this.onSelectResultItemAutocomplete);

    this.autocompleteStore
      .getEventEmitter()
      .addListener(PropertyAutocompleteStoreDecoratorEvent.searchSuccess, this.onSearchSuccessAutocompleteStore);
  }

  /**
   * @inheritdoc
   */
  protected onChangeFilters = (filters: FilterParametersInterface) => {
    const statsGtmData = propertySerpLightStatsGtmChangedFilter(this.settings.gtm.pageType, filters);
    this.changeFilters(filters);

    if (!statsGtmData.length) {
      return;
    }

    // Send analytics event
    this.sendStatsGtm(statsGtmData);
  };

  /**
   * Send data to the GTM
   */
  private sendStatsGtm = (data: StatsGtmDataLayerInterface | StatsGtmDataLayerInterface[]): void => {
    // Send analytics event
    this.statsGtm.send(data);
  };

  /**
   * Init autocomplete
   */
  private initAutocomplete(): void {
    this.autocompleteViewStoreService.initialize({
      placeholder: i18nTranslate('City, community or building'),
    });

    this.setAutocompleteHistory();

    this.autocompleteStore.initialize({
      searchUri: this.searchUri,
    });
  }

  /**
   * Autocomplete input click event handler
   */
  private onClickInputAutocomplete = (): void => {
    const state = this.autocompleteViewStoreService.getState();

    // Show dropdown when there are autocomplete results
    this.autocompleteViewStoreService.toggle(!!state.autocompleteResults.length);
  };

  /**
   * Autocomplete keyup event handler
   */
  private onKeyUpAutocomplete = () => {
    const autocompleteState = this.autocompleteViewStoreService.getState();

    this.autocompleteStore.search(autocompleteState.searchString);

    this.showLoader();
  };

  /**
   * Autocomplete select result item event handler
   */
  private onSelectResultItemAutocomplete = (e: Event, result: AutocompleteResultInterface) => {
    // Send analytics event
    this.sendStatsGtm(PropertySerpLightStatsGtmLocationClick(this.settings.gtm.pageType, result.sectionId));

    this.statsTealium.send({
      sendToGa: true,
      event_action: result.sectionId === this.historicalSearchesSectionId ? 'history_click' : 'autocomplete_click',
      tealium_event: 'text_search_input_click',
      event_label: result.text,
      event_category: StatsTealiumDataLayerEventCategoryEnum.autocompleteSelection,
    });

    return this.autocompleteStore.addResult(result).then(() => {
      // Update autocomplete with locations from history
      this.setAutocompleteHistory();
    });
  };

  /**
   * Triggers when autocomplete search succeded
   */
  private onSearchSuccessAutocompleteStore = (): void => {
    const results = this.autocompleteStore.getAutocompleteResults();

    if (results && results.length) {
      this.autocompleteViewStoreService.setAutocompleteResults(results);
    } else {
      // Update autocomplete with locations from history
      this.setAutocompleteHistory();
    }

    const autocompleteState = this.autocompleteViewStoreService.getState();

    this.onSearchBarTyped(autocompleteState.searchString, autocompleteState.autocompleteResults);
    this.autocompleteViewStoreService.toggle(!!autocompleteState.autocompleteResults.length);
    this.hideLoader();
  };

  /**
   * Form submitted
   */
  private onSubmit = (e: Event) => {
    e.preventDefault();

    this.submit();
  };

  /**
   * Form submitted
   */
  private submit(): void {
    // Send analytics event
    this.sendStatsGtm(PropertySerpLightStatsGtmFormSubmit(this.settings.gtm.pageType));

    this.redirect();
  }

  /**
   * Redirect user
   */
  private redirect(): void {
    this.filterSearchUrlAdapter.redirect(this.searchUri, this.getState().filterParams);
  }

  /**
   * Apply the filters and redirect
   */
  private onApplyFilters = (filters: FilterParametersInterface): void => {
    this.changeFilters(filters).then(() => {
      this.redirect();
    });
  };

  /**
   * Show autocomplete loader
   */
  private showLoader(): void {
    this.autocompleteViewStoreService.toggleLoader(true);
  }

  /**
   * Hide autocomplete loader
   */
  private hideLoader(): void {
    this.autocompleteViewStoreService.toggleLoader(false);
  }

  /**
   * Set autocomplete history
   */
  private setAutocompleteHistory(): void {
    const storage = this.locationAutocompleteHistoryService.getLocations();

    if (storage.length) {
      this.autocompleteViewStoreService.setAutocompleteResults([
        {
          name: i18nTranslate('History'),
          id: this.historicalSearchesSectionId,
          suggestions: storage.map((location) => ({
            id: location.id,
            text: `${location.abbreviation ? `${location.abbreviation} - ` : ''}${location.name}`,
            description: location.path_name,
            sectionId: this.historicalSearchesSectionId,
          })),
        },
      ]);
    } else {
      this.setPopularSearch();
    }
  }

  /**
   * Set autocomplete popular searches
   */
  private setPopularSearch(): void {
    const popularSearches = PropertySerpLightPopularSearches[configGetCountry().code]?.[this.languageCode] || [];
    this.autocompleteViewStoreService.setAutocompleteResults([
      {
        name: i18nTranslate('Popular searches'),
        id: this.popularSearchesSectionId,
        suggestions: popularSearches.map((location) => ({
          id: location.id,
          text: location.text,
          description: location.description,
          sectionId: this.popularSearchesSectionId,
        })),
      },
    ]);
  }

  /**
   * Sends data to tealium
   */
  private onShowPropertiesClick = () => {
    const { filterParams } = this.getState();
    this.statsTealium.send({
      sendToGa: true,
      event_action: 'show_properties_click',
      tealium_event: 'text_search_show_properties',
      event_label: filterParams[FilterParamsEnum.locationsIds].value.join('|'),
      event_category: StatsTealiumDataLayerEventCategoryEnum.autocompleteSelection,
    });
  };

  /**
   * Send data to tealium
   */
  private onCommercialToggleClick = () => {
    this.statsTealium.send({
      sendToGa: true,
      event_action: 'click',
      tealium_event: 'secondary_link_clicks',
      event_label: 'view_commercial_properties_only',
      event_category: StatsTealiumDataLayerEventCategoryEnum.homePageClickOutideSearchArea,
    });
  };

  private onSearchBarTyped(value: string, results: AutocompleteSectionInterface[]): void {
    const hasSuggestions = results.some((result) => result.id === '1' && !!result.suggestions.length);

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      if (value && !hasSuggestions) {
        this.statsTealium.send({
          sendToGa: true,
          tealium_event: 'search_bar_typed',
          event_category: StatsTealiumDataLayerEventCategoryEnum.search,
          event_action: 'search_bar_typed',
          event_label: value,
          search_keywords: value,
        });
      }
    }, 500);
  }
}
