import { StatsDebuggerService } from 'pf-frontend-common/src/service/stats-debugger/service';
import { StatsGuardService } from 'pf-frontend-common/src/service/stats-guard/service';

import { StatsSnowplow2Provider } from 'common/module/stats/snowplow2/provider';
import { StatsSnowplow2ProviderInterface } from 'common/module/stats/snowplow2/provider.interface';
import { StatsSnowplow2 } from 'common/module/stats/snowplow2/types';

const service = new StatsSnowplow2Provider(
  (<{ snowplow?: StatsSnowplow2 }>window).snowplow,
  StatsDebuggerService(),
  StatsGuardService()
);

export function StatsSnowplow2ProviderService(): StatsSnowplow2ProviderInterface {
  return service;
}
