import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';
import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { PromiseCancelableFactory } from 'common/module/promise/cancelable.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { LocationStoreService } from 'common/service/location-store/service';

import { AutocompleteStore } from './store';
import { AutocompleteStoreInterface } from './store.interface';

export function AutocompleteStoreFactory(): AutocompleteStoreInterface {
  return new AutocompleteStore(
    EventEmitterFactory(),
    ApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<JsonApiModel>(JsonApiModel.JSONAPI_TYPE),
    PromiseCancelableFactory,
    LocationStoreService()
  );
}
