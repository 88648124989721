import { BrowserDocumentServiceInterface } from '@propertyfinder/pf-frontend-common/dist/service/browser-document/service.interface';
import { WindowServiceInterface } from '@propertyfinder/pf-frontend-common/dist/service/window/service.interface';

import { LoginSocialProviderEnum } from 'common/data/login-social/provider.enum';
import { LoginSocialStore } from 'common/data/login-social/store';
import { UserFrontendLoginModel } from 'common/data/user/frontend/login/model';
import { UserFrontendLoginProviderInterface } from 'common/data/user/frontend/login-provider.interface';
import { UserFrontendLoginServiceInterface } from 'common/service/user-frontend-login/service.interface';

import { UserFrontendLoginEvent } from '../login.event';
import { UserFrontendLoginProvidersEnum } from '../login/providers.enum';
import { UserFrontendLoginProviderTypeEnum } from './type.enum';

export class UserFrontendLoginProviderGoogle implements UserFrontendLoginProviderInterface {
  /**
   * @inheritdoc
   */
  public initialization: UserFrontendLoginProviderTypeEnum = UserFrontendLoginProviderTypeEnum.manual;

  /**
   * Script url
   */
  private readonly scriptUrl: string = 'https://accounts.google.com/gsi/client';

  /**
   * Constructor
   */
  public constructor(
    private loginSocialStore: LoginSocialStore,
    private browserDocumentService: BrowserDocumentServiceInterface,
    private windowService: WindowServiceInterface,
    private clientId: string,
    private userFrontendLoginService: UserFrontendLoginServiceInterface
  ) {
    this.preLoad();
  }

  public renderButton = (parentElem: HTMLElement): void => {
    const google = this.windowService.getNative().google;

    if (google) {
      google.accounts.id.renderButton(parentElem, {
        type: 'standard',
        width: 400,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('Google is not initialize yet');
    }
  };

  /**
   * Preload script to fix safari first click popup block issue
   */
  private preLoad = (): Promise<void> => {
    if (this.windowService.getNative().google) {
      this.userFrontendLoginService.emitLoginEvent(UserFrontendLoginEvent.signInReady);
      return Promise.resolve();
    }

    return this.browserDocumentService.importScript(this.scriptUrl).then(() => {
      const google = this.windowService.getNative().google;

      if (!google) {
        // eslint-disable-next-line no-console
        console.error('Google is unable to initialize');
        return;
      }

      google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.singInCallback,
      });

      this.userFrontendLoginService.emitLoginEvent(UserFrontendLoginEvent.signInReady);
    });
  };

  private singInCallback = (result: { credential: string }): Promise<UserFrontendLoginModel> => {
    return this.loginSocialStore.fetchUser(LoginSocialProviderEnum.google, result.credential).then((user) => {
      this.userFrontendLoginService.emitLoginEvent(
        UserFrontendLoginEvent.signInSucceeded,
        user,
        UserFrontendLoginProvidersEnum.google
      );
      return user;
    });
  };
}
