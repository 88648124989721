import { DataStorePaginationInterface } from 'common/module/data/store/pagination.interface';
import { JsonApiMetaPaginationInterface } from 'common/module/json-api/meta/pagination.interface';

/**
 * Transforms JsonApiMetaPaginationInterface into DataStorePaginationInterface
 */
export class DataStorePaginationAdapter {
  /**
   * Constructor
   */
  constructor(private jsonApiMetaPagination: JsonApiMetaPaginationInterface) {}

  /**
   * Returns the store pagination data
   */
  public getData(): DataStorePaginationInterface {
    return {
      page: this.jsonApiMetaPagination.page || 1,
      pageCount: this.jsonApiMetaPagination.page_count || 1,
      perPage: this.jsonApiMetaPagination.per_page || 1,
      totalCount: this.jsonApiMetaPagination.total_count || 0,
    };
  }
}
