import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from './template-props.interface';

export const IconBrick: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg className={props.class} viewBox='0 0 20 18'>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.78387 2.029C8.51713 2.10047 8.35884 2.37464 8.43031 2.64138L9.46559 6.50508C9.53706 6.77181 9.81123 6.9301 10.078 6.85863L16.1873 5.22163C16.4541 5.15016 16.6124 4.87599 16.5409 4.60925L15.5056 0.745551C15.4342 0.478818 15.16 0.320527 14.8933 0.391997L8.78387 2.029ZM10.6557 5.15092L10.138 3.21907L14.3156 2.09971L14.8332 4.03156L10.6557 5.15092ZM0.5 4.1253C0.5 3.84916 0.723857 3.6253 1 3.6253H7.8249V7.12515H16.0841C16.3602 7.12515 16.5841 7.34901 16.5841 7.62515V10.6251H19C19.2761 10.6251 19.5 10.849 19.5 11.1251V15.1251C19.5 15.4013 19.2761 15.6251 19 15.6251H1C0.723858 15.6251 0.5 15.4013 0.5 15.1251V10.6251H3.41667V8.6253H0.5V4.1253ZM7.3249 8.6253H4.91667V10.6251H9.25001V8.6253H10.75V10.6251H15.0833V8.6253H15.0841V8.62515H7.33738L7.3249 8.6253ZM6.3249 5.1253V7.12515H3.91667L3.9042 7.1253H2V5.1253H6.3249ZM3.41667 12.1251H2V14.1251H6.33334V12.1253H7.83334V14.1251H12.1667V12.1253H13.6667V14.1251H18V12.1251H16.5841H3.41667Z'
    />
  </svg>
);
