import { WindowLocationServiceInterface } from 'pf-frontend-common/src/service/window-location/service.interface';

import { FilterParametersInterface } from 'common/module/filter/parameters.interface';

import { FilterSearchQueryInterface } from '../query.interface';
import { filterSearchUrlDeserialize } from './deserialize';
import { filterSearchUrlSerialize } from './serialize';

export class FilterSearchUrlAdapter {
  public constructor(private windowLocationService: WindowLocationServiceInterface) {}

  // TODO-FE[TPNX-2256] Remove serialize and deserialize methods from FilterSearchUrlAdapter
  /**
   * Serialized the property search parameters into search query object
   */
  public serialize(params: FilterParametersInterface): FilterSearchQueryInterface {
    return filterSearchUrlSerialize(params);
  }

  // TODO-FE[TPNX-2256] Remove serialize and deserialize methods from FilterSearchUrlAdapter
  /**
   * Transform the url query object to the property search parameters
   */
  public deserialize(queryParams: FilterSearchQueryInterface): FilterParametersInterface {
    return filterSearchUrlDeserialize(queryParams);
  }

  /**
   * Update the url based on the supplied params
   */
  public push(uri: string, params: FilterParametersInterface): void {
    this.windowLocationService.replace(uri, {
      hash: this.windowLocationService.getHash(),
      queryData: this.serialize(params),
    });
  }

  /**
   * Redirect to the url based on the supplied params
   */
  public redirect(uri: string, params: FilterParametersInterface): void {
    this.windowLocationService.redirect(uri, {
      hash: this.windowLocationService.getHash(),
      queryData: this.serialize(params),
    });
  }
}
