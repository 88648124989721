export enum DropdownSizeEnum {
  /**
   * Small size dropdown
   */
  small = 'small',

  /**
   * A regular size dropdown
   */
  normal = 'normal',
}
