import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { LocationModel } from 'common/data/location/model';
import { LocationStore } from 'common/data/location/store';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';

export function LocationStoreFactory(): LocationStore {
  return new LocationStore(
    ApiService(),
    EventEmitterFactory(),
    JsonApiStoreFactory<LocationModel>(LocationModel.JSONAPI_TYPE),
    ApiEndpointService()
  );
}
