import { domQuerySelectorAll } from 'pf-frontend-common/src/helper/dom/query-selector-all';

import { PropertyContactedStoreEvent } from 'common/data/property-contacted/store.event';
import { PropertySavedStoreEvent } from 'common/data/property-saved/store.event';
import { SaveSearchModel } from 'common/data/save-search/model';
import { SaveSearchStore } from 'common/data/save-search/store';
import { SaveSearchStoreEvent } from 'common/data/save-search/store.event';
import { DataStoreEvent } from 'common/module/data/store.event';
import { MyAccountMenuHtmlElementInterface } from 'common/module/my-account/menu/html-element.interface';
import { MyAccountMenuViewCssSelectorEnum } from 'common/module/my-account/menu/view-css-selector.enum';
import { MyAccountStore } from 'common/module/my-account/store';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { UserInfoViewStore } from 'common/module/user/info/view-store';
import { ViewDynamicInterface } from 'common/module/view/dynamic.interface';
import { PropertyContactedStoreServiceInterface } from 'common/service/property-contacted-store/service.interface';
import { PropertySavedStoreServiceInterface } from 'common/service/property-saved-store/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';
import { domQuerySelector } from 'common/typings/dom-query-selector';

import { MyAccountViewCssSelectorEnum } from '../view-css-selector.enum';
import { MyAccountMenuViewInterface } from './view.interface';
import { MyAccountMenuViewOptionsInterface } from './view-options.interface';

export class MyAccountMenuView implements MyAccountMenuViewInterface {
  /**
   * Main HTML element
   */
  private element: HTMLElement;

  /**
   * View html elements
   */
  private htmlElement: MyAccountMenuHtmlElementInterface;

  /**
   * Constructor
   */
  constructor(
    private propertySavedStoreService: PropertySavedStoreServiceInterface,
    private propertyContactedStoreService: PropertyContactedStoreServiceInterface,
    private myAccountStore: MyAccountStore,
    private saveSearchStoreService: SaveSearchStore,
    private userInfoView: ViewDynamicInterface<UserInfoViewStore>,
    private userAuthenticationService: UserAuthenticationServiceInterface,
    private domQuerySelectorHelper: domQuerySelector,
    private myNotesGetIsAvailable: boolean
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: MyAccountMenuViewOptionsInterface): void {
    this.element = options.element;

    this.htmlElement = {
      myNotesLink: this.domQuerySelectorHelper(this.element, MyAccountMenuViewCssSelectorEnum.myNotesLink),
    };

    // Initialize subviews
    this.initUserInfo(options);

    // Update the visibility of my-account menu items
    this.updateMyAccountMenu();

    // Attach observers
    this.observers();
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    this.renderUserInfo();
  }

  /**
   * Init user info view
   */
  private initUserInfo(options: MyAccountMenuViewOptionsInterface): void {
    this.userInfoView.initialize({
      element: this.domQuerySelectorHelper(this.element, MyAccountMenuViewCssSelectorEnum.userInfo),
    });

    // User info initialization
    this.userInfoView.getViewStore().initialize({
      isEnabledMessage: true,
      isUserInfoVisible: !options.isUserInfoHidden,
    });
  }

  /**
   * Render user info
   */
  private renderUserInfo(): void {
    this.userInfoView.render();
  }

  /**
   * Update quantity of saved properties
   */
  private updateSavedProperties(): void {
    domQuerySelectorAll(this.element, MyAccountMenuViewCssSelectorEnum.savedProperty).forEach(
      (element: HTMLElement) => {
        element.innerHTML = String(this.propertySavedStoreService.getAllFromCache()?.length || 0);
      }
    );
  }

  /**
   * Update quantity of contacted properties
   */
  private updateContactedProperties(): void {
    domQuerySelectorAll(this.element, MyAccountMenuViewCssSelectorEnum.contactedProperty).forEach(
      (element: HTMLElement) => {
        element.innerHTML = String(this.propertyContactedStoreService.getAllFromCache()?.length || 0);
      }
    );
  }

  /**
   * Update quantity of my notes properties
   */
  private updateMyNotesProperties(): void {
    domQuerySelectorAll(this.element, MyAccountMenuViewCssSelectorEnum.myNotesProperty).forEach(
      (element: HTMLElement) => {
        element.innerHTML = String(Object.keys(this.myAccountStore.getState().notes)?.length || 0);
      }
    );
  }

  /**
   * Update quantity of saved searches
   */
  private updateSavedSearches(count: number): void {
    domQuerySelectorAll(this.element, MyAccountViewCssSelectorEnum.savedSearchCount).forEach(
      async (element: HTMLElement) => {
        element.innerHTML = String(count);
      }
    );
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.propertySavedStoreService
      .getEventEmitter()
      .addListener(PropertySavedStoreEvent.sync, this.onSyncPropertySavedStore);

    this.propertyContactedStoreService
      .getEventEmitter()
      .addListener(PropertyContactedStoreEvent.sync, this.onSyncPropertyContactedStore);

    this.saveSearchStoreService
      .getEventEmitter()
      .addListener(SaveSearchStoreEvent.searchSuccess, this.onFetchSavedSearches);

    this.myAccountStore.getEventEmitter().addListener(DataStoreEvent.updateState, this.onUpdateStateMyAccountStore);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.logOutSucceeded, this.onLogOutSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onRegistrationSucceeded);
  }

  /**
   * Sync property saved store
   */
  private onSyncPropertySavedStore = () => {
    this.updateSavedProperties();
  };

  /**
   * Sync property contacted store
   */
  private onSyncPropertyContactedStore = () => {
    this.updateContactedProperties();
  };

  /**
   * Sync my notes store
   */
  private onUpdateStateMyAccountStore = () => {
    this.updateMyNotesProperties();
  };

  /**
   * Sync saved searches store
   */
  private onFetchSavedSearches = (savedSearches: SaveSearchModel[]) => {
    this.updateSavedSearches(savedSearches?.length);
  };

  /**
   * Update the visibility of my-account menu items
   */
  private async updateMyAccountMenu(): Promise<void> {
    if (!this.htmlElement.myNotesLink) {
      return;
    }

    const isLoggedIn = !!this.userAuthenticationService.getToken();
    const isMyNotesAvailable = this.myNotesGetIsAvailable && isLoggedIn;
    const savedSearches = await this.saveSearchStoreService.getAll(!isLoggedIn);

    this.htmlElement.myNotesLink.style.display = isMyNotesAvailable ? '' : 'none';
    this.updateSavedSearches(savedSearches?.length);
  }

  /**
   * Sign-In Succeeded
   */
  private onSignInSucceeded = (): void => {
    this.updateMyAccountMenu();
  };

  /**
   * Log-Out Succeeded
   */
  private onLogOutSucceeded = (): void => {
    this.updateSavedSearches(0);
    this.updateMyAccountMenu();
  };

  /**
   * Registration Succeeded
   */
  private onRegistrationSucceeded = (): void => {
    this.updateMyAccountMenu();
  };
}
