import { LocationTypeEnum } from 'common/data/location/type.enum';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { areaMetricToImperial } from 'common/helper/area/metric-to-imperial';
import { arrayFind } from 'common/helper/array/find';
import { configGetCountry } from 'common/helper/config/get-country';
import { StatsTealiumDataLayerConversionEventInterface } from 'common/module/stats/tealium/data-layer/conversion-event.interface';
import { StatsTealiumDataLayerConversionModelInterface } from 'common/module/stats/tealium/data-layer/conversion-model.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerEventOptionsInterface } from 'common/module/stats/tealium/data-layer/event-options.interface';
import { StatsTealiumDataLayerEventTypeEnum } from 'common/module/stats/tealium/data-layer/event-type.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from 'common/module/stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumAdapterPropertySubcategoriesMapping } from 'common/module/stats/tealium/property-subcategories.mapping';
import { tagListGetItems } from 'common/module/tag-list/get-items';

// eslint-disable-next-line @propertyfinder/rules/export-name-validation
export const StatsTealiumConversionEvent = (
  tealiumEvent: StatsTealiumDataLayerTealiumEventEnum,
  model: StatsTealiumDataLayerConversionModelInterface,
  options?: StatsTealiumDataLayerEventOptionsInterface
): StatsTealiumDataLayerConversionEventInterface => {
  const propertyLocationIds = model['location_tree'].reduce((acc, location) => {
    acc[`property_location_${location.location_type?.toLowerCase()}_id`] = location.id;

    return acc;
  }, {} as Record<string, string>);

  const tealiumVars: StatsTealiumDataLayerConversionEventInterface = {
    sendToGa: options?.sendToGa || false,
    tealium_event: tealiumEvent,
    event_type: StatsTealiumDataLayerEventTypeEnum.click,
    event_category: options?.eventCategory || StatsTealiumDataLayerEventCategoryEnum.conversion,
    event_action: options?.eventAction || '',
    event_label: options?.eventLabel || '',
    property_category: model.category_identifier,
    property_sub_category: StatsTealiumAdapterPropertySubcategoriesMapping[model.category_identifier],
    property_completion: model.completion_status || '',
    property_listed_days: new Date(model.date_insert).toDateString(),
    property_listing_id: model.id,
    property_location_city:
      arrayFind(model.location_tree, (location) => location.location_type === LocationTypeEnum.city)?.name || '',
    property_location_community:
      arrayFind(model.location_tree, (location) => location.location_type === LocationTypeEnum.community)?.name || '',
    property_location_subcommunity:
      arrayFind(model.location_tree, (location) => location.location_type === LocationTypeEnum.subcommunity)?.name ||
      '',
    property_location_tower:
      arrayFind(model.location_tree, (location) => location.location_type === LocationTypeEnum.tower)?.name || '',
    ...propertyLocationIds,
    property_name: model.name,
    property_price: `${model.default_price}`,
    property_lv: `${model.lead_value}`,
    property_reference: model.reference,
    property_rental_period: [
      PropertyCategoryIdentifierEnum.residentialForRent,
      PropertyCategoryIdentifierEnum.commercialForRent,
    ].includes(model.category_identifier)
      ? model.price_period_identifier
      : '',
    property_size_sqft: `${configGetCountry().areaUnit === 'sqm' ? areaMetricToImperial(model.size) : model.size}`,
    property_type: model.type_identifier?.toLowerCase(),
    property_verified_status: `${model.verified}`,
    property_bathrooms: `${model.bathroom_value || 0}`,
    property_bedrooms: `${model.bedroom_value}`,
    property_tags: tagListGetItems(model).map((item) => (typeof item === 'object' ? item.type : item)),
    project_id: model.project?.id ?? '',
  };

  if (options?.isTransactionalEvent) {
    tealiumVars.property_transaction_id = model.id;
  }

  if (options?.hasOnlyPropertVariables) {
    return tealiumVars;
  }

  if (tealiumEvent === StatsTealiumDataLayerTealiumEventEnum.emailNow && options.userInfo) {
    tealiumVars.user_full_name = options.userInfo.user_full_name;
    tealiumVars.user_email = options.userInfo.user_email;
    tealiumVars.user_phone_number = options.userInfo.user_phone_number;
  }
  if (
    [
      StatsTealiumDataLayerTealiumEventEnum.emailNow,
      StatsTealiumDataLayerTealiumEventEnum.callNow,
      StatsTealiumDataLayerTealiumEventEnum.whatsappNow,
      StatsTealiumDataLayerTealiumEventEnum.smsNow,
      StatsTealiumDataLayerTealiumEventEnum.phoneReveal,
    ].includes(tealiumEvent)
  ) {
    tealiumVars.property_community_topspot = `${model.cts}`;
  }

  return {
    ...tealiumVars,
    agent_id: model?.agent.id || '',
    agent_name: model?.agent.name,
    broker_id: model?.broker.id,
    broker_name: model?.broker.name,
  };
};
