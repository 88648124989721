import { configGetAbTests } from 'common/helper/config/get-ab-tests';

/**
 * Returns true if ab test has variants and if variant other than original is active.
 */
export function abTestsIsVariant(test: string, specificVariant?: string): boolean {
  const abTests = configGetAbTests();
  const baseCondition = abTests && test && abTests[test] && abTests[test].variants && !abTests[test].variants.original;
  if (specificVariant) {
    return !!(baseCondition && abTests[test].variants[specificVariant]);
  } else {
    return !!baseCondition;
  }
}
