import { h } from 'preact';
import { useState } from 'preact/hooks';

import { functionSelf } from 'common/helper/function/self';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { useLocationStoreService } from 'common/helper/use/location-store-service';
import { IconLocationTemplate, IconTimeTemplate } from 'common/module/icon';
import { MultiSelectionAutocompleteComponent } from 'common/module/multi-selection-autocomplete/component';
import { PropertyAutocompleteStoreFactory } from 'common/module/property/autocomplete/store.factory';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { FilterService } from 'common/service/filter/service';
import { LocationAutocompleteHistoryService } from 'common/service/location-autocomplete-history/service';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';

import { MultiLocationSelectorComponentPropsInterface } from './component-props.interface';
import { multiLocationSelectorMakeOnAddLocation } from './make-on-add-location';
import { multiLocationSelectorMakeQueryForInputValue } from './make-query-for-input-value';

const statsTealium = StatsTealiumProviderService();

let timer: NodeJS.Timeout;

const handleInputKeyUp = <T extends unknown>(e: KeyboardEvent, suggestions: T[]): void => {
  const value = (e.currentTarget as HTMLInputElement).value;

  clearTimeout(timer);

  timer = setTimeout(() => {
    if (value && !suggestions.length) {
      statsTealium.send({
        sendToGa: true,
        tealium_event: 'search_bar_typed',
        event_category: StatsTealiumDataLayerEventCategoryEnum.search,
        event_action: 'search_bar_typed',
        event_label: value,
        search_keywords: value,
      });
    }
  }, 500);
};

export const MultiLocationSelectorComponent = ({ onChange, value }: MultiLocationSelectorComponentPropsInterface) => {
  const [autocompleteStore] = useState(() => PropertyAutocompleteStoreFactory(FilterService()));
  const [inputValue, setInputValue] = useState('');

  const locationStoreService = useLocationStoreService();

  /**
   * Current selected location in LocationModel format
   */
  const locations = value.map((id: string) => locationStoreService.getOneById(id)).filter(functionSelf);

  /**
   * On add location
   */
  const onAddItem = multiLocationSelectorMakeOnAddLocation({
    locations,
    locationAutocompleteHistoryService: LocationAutocompleteHistoryService(),
    onNewLocations: onChange,
  });

  /**
   * Return promise of locations for current input value
   */
  const queryLocationsForInputValue = multiLocationSelectorMakeQueryForInputValue({
    autocompleteStore,
    locationAutocompleteHistoryService: LocationAutocompleteHistoryService(),
  });

  return (
    <MultiSelectionAutocompleteComponent
      value={locations}
      getTitle={(location) => {
        const text = `${location.abbreviation ? location.abbreviation + ' - ' : ''}${location.name}`;
        const description = location.path_name;

        return `${text}${description ? ` (${description})` : ''}`;
      }}
      getChipTitle={(location) => `${location.abbreviation ? location.abbreviation + ' - ' : ''}${location.name}`}
      placeholder={i18nTranslate('City, community or building')}
      onInputChange={queryLocationsForInputValue}
      onInputValueChange={setInputValue}
      onInputKeyUp={(e, suggestions): void => handleInputKeyUp(e, suggestions)}
      onFocus={queryLocationsForInputValue}
      onAddItem={onAddItem}
      onItemRemove={(location) => onChange(locations.filter((l) => location.id !== l.id).map(({ id }) => id))}
      onItemGroupRemoveClick={() => onChange(locations.slice(0, 1).map(({ id }) => id))}
      renderNoSuggestions={i18nTranslate('multi-location-selector-no-suggestions-new')}
      suggestionIcon={
        inputValue ? (
          <IconLocationTemplate class='multi-location-selector__suggestion_icon' />
        ) : (
          <IconTimeTemplate class='multi-location-selector__suggestion_icon' />
        )
      }
    />
  );
};
