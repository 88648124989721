import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { AgentModel } from 'common/data/agent/model';
import { AgentStore } from 'common/data/agent/store';
import { CookieAuthenticatorApiService } from 'common/module/cookie-authenticator/api.service';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';

export function AgentStoreFactory(): AgentStore {
  return new AgentStore(
    EventEmitterFactory(),
    CookieAuthenticatorApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<AgentModel>(AgentModel.JSONAPI_TYPE)
  );
}
