import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';

import { AutocompleteCacheChecksumKeysEnum } from 'common/data/autocomplete/cache/checksum-keys.enum';
import { AutocompleteCacheStore } from 'common/data/autocomplete/cache/store';
import { LocationModel } from 'common/data/location/model';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { LocationStoreServiceInterface } from 'common/service/location-store/service.interface';

export class AutocompleteCacheLocationsStore extends AutocompleteCacheStore<LocationStoreServiceInterface> {
  /**
   * @inheritDoc
   */
  protected endpoint: string = '/autocomplete/location';

  /**
   * @inheritDoc
   */
  protected checksumKey: string = AutocompleteCacheChecksumKeysEnum.locations;

  /**
   * @inheritDoc
   */
  protected dataKey: DatabaseTableEnum = DatabaseTableEnum.autocompleteLocations;

  /**
   * @inheritDoc
   */
  public getCache(): LocationModel[] {
    return this.dataStore.getAll();
  }

  /**
   * @inheritDoc
   */
  protected resetDataStore(): void {
    this.dataStore.reset();
  }

  /**
   * @inheritDoc
   */
  protected initializeDataStore(payload: HttpApiResponseInterface): void {
    this.dataStore.initialize({
      payload: payload.data,
    });
  }

  /**
   * @inheritDoc
   */
  protected addToDataStore(models: LocationModel[]): void {
    this.dataStore.initialize({
      models,
    });
  }
}
