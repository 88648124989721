import { PropertyPriceTypeShortEnum } from 'common/data/property-price-type/short.enum';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

import { FilterValueBaseInterface } from '../value-base.interface';
import { filterDefaultParamsCommon } from './common';

export const defaultFilterParamsQA: FilterValueBaseInterface = {
  ...filterDefaultParamsCommon,
  [FilterParamsEnum.pricePeriod]: PropertyPriceTypeShortEnum.monthly,
};
