import { UserFrontendLoginProvidersEnum } from 'common/data/user/frontend/login/providers.enum';
import { UserModel } from 'common/data/user/model';
import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { StatsTealiumDataLayerEventTypeEnum } from 'common/module/stats/tealium/data-layer/event-type.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from 'common/module/stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumDataLayerUserEventInterface } from 'common/module/stats/tealium/data-layer/user-event.interface';
import { UserPopupLoginStatusEnum } from 'common/module/user/popup/login-status.enum';

// eslint-disable-next-line @propertyfinder/rules/export-name-validation
export const UserSigninStatsTealiumUserRegister = (
  userModel: UserModel,
  provider?: UserFrontendLoginProvidersEnum,
  eventReference?: StatsTealiumDataLayerEventReferenceEnum
): StatsTealiumDataLayerUserEventInterface => ({
  sendToGa: true,
  tealium_event: StatsTealiumDataLayerTealiumEventEnum.userRegister,
  event_type: StatsTealiumDataLayerEventTypeEnum.click,
  event_category: StatsTealiumDataLayerEventCategoryEnum.user,
  event_action: StatsTealiumDataLayerEventActionEnum.register,
  ...(provider && {
    event_label: provider.toLowerCase(),
  }),
  user_id: `${userModel.id}` || '',
  user_status: userModel.id ? UserPopupLoginStatusEnum.loggedIn : UserPopupLoginStatusEnum.notLoggedIn,
  user_email: userModel.email,
  user_full_name: `${userModel.first_name || ''} ${userModel.last_name || ''}`.trim() || undefined,
  user_phone_number: userModel.phone || '',
  event_reference:
    provider == UserFrontendLoginProvidersEnum.googleOneTap
      ? StatsTealiumDataLayerEventReferenceEnum.HEADER
      : eventReference,
});
