export const enum PropertyCtaStatechartActionEnum {
  /**
   * Show captcha
   */
  showCaptcha = 'showCaptcha',

  /**
   * Hide captcha
   */
  hideCaptcha = 'hideCaptcha',

  /**
   * Set whatsapp button loading state
   */
  setWhatsAppLoading = 'setWhatsAppLoading',

  /**
   * Reset whatsapp button loading state
   */
  resetWhatsAppLoading = 'resetWhatsAppLoading',

  /**
   * Set call button loading state
   */
  setCallLoading = 'setCallLoading',

  /**
   * Reset call button loading state
   */
  resetCallLoading = 'resetCallLoading',

  /**
   * Store event payload
   */
  setPayload = 'setPayload',

  /**
   * Log stats for call button
   */
  logStatsCallButton = 'logStatsCallButton',

  /**
   * Log stats for click on email cta button
   */
  logStatsCtaEmailButton = 'logStatsCtaEmailButton',

  /**
   * Log stats for click on call cta button
   */
  logStatsCtaCallButton = 'logStatsCtaCallButton',

  /**
   * Log stats for click on sms cta button
   */
  logStatsCtaSmsButton = 'logStatsCtaSmsButton',

  /**
   * Log stats for click on WhatsApp cta button
   */
  logStatsCtaWhatsAppButton = 'logStatsCtaWhatsAppButton',

  /**
   * Log stats for click on call back cta button
   */
  logStatsCtaCallBackButton = 'logStatsCtaCallBackButton',

  /**
   * Check if captcha is needed for WhatsApp
   */
  checkIfCaptchaNeededWhatsApp = 'checkIfCaptchaNeededWhatsApp',

  /**
   * Check if captcha is needed for call
   */
  checkIfCaptchaNeededCall = 'checkIfCaptchaNeededCall',

  /**
   * Update captcha local storage
   */
  updateCaptchaLocalStorage = 'updateCaptchaLocalStorage',

  /**
   * Save to contacted via phone action handler
   */
  saveToContactedViaPhone = 'saveToContactedViaPhone',

  /**
   * Save to contacted via sms
   */
  saveToContactedViaSms = 'saveToContactedViaSms',

  /**
   * Save to contacted via WhatsApp
   */
  saveToContactedViaWhatsApp = 'saveToContactedViaWhatsApp',

  /**
   * Save to contacted via email
   */
  saveToContactedViaEmail = 'saveToContactedViaEmail',

  /**
   * Save to contacted via call back
   */
  saveToContactedViaCallBack = 'saveToContactedViaCallBack',

  /**
   * Open phone lead popup
   */
  openPhoneLeadPopup = 'openPhoneLeadPopup',

  /**
   * Open email lead popup
   */
  openEmailLeadPopup = 'openEmailLeadPopup',

  /**
   * Open native whatsapp popup
   */
  openNativeWhatsAppPopup = 'openNativeWhatsAppPopup',

  /**
   * Open call back popup
   */
  openCallBackLeadPopup = 'openCallBackLeadPopup',

  /**
   * Open phone lead popup
   */
  openAgentInfoPopup = 'openAgentInfoPopup',

  /**
   * Display login modal when clicking on display phone number in property description (PLP)
   */
  displayLoginModal = 'displayLoginModal',

  revealPhoneNumber = 'revealPhoneNumber',
}
