import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { DeveloperModel } from 'common/data/developer/model';
import { FloorPlanModel } from 'common/data/floor-plan/model';
import { ProjectImageModel } from 'common/data/project-image/model';
import { ProjectLogoModel } from 'common/data/project-logo/model';
export class ProjectModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'project';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = ProjectModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof ProjectModel> = [
    'id',
    'title',
    'images',
    'description',
    'developer',
    'delivery_date',
    'phase_id',
    'down_payment_rate',
    'installment_years',
    'enable_pre_qualification',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof ProjectModel> = ['floor_plan', 'project_logo', 'developer'];

  /**
   * @inheritDoc
   */
  public jsonApiLinks: string[] = [];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['floor_plan_id'];

  /**
   * @inheritDoc
   */
  public meta: {};

  /**
   * ID
   */
  public id: string;

  /**
   * Title
   */
  public title: string;

  /**
   * Project image model
   */
  public images: ProjectImageModel[];

  /**
   * Description
   */
  public description: string;

  /**
   * Developer
   */
  public developer: DeveloperModel;

  // tslint:disable:variable-name

  /**
   * Delivery Date
   */
  public delivery_date: string;

  /**
   * Phase id
   */
  public phase_id: number;

  /**
   * Down payment rate
   */
  public down_payment_rate: string;

  /**
   * Installment years
   */
  public installment_years: number;

  /**
   * Enable pre qualification
   */
  public enable_pre_qualification: boolean;

  /**
   * Floor plan of project
   */
  public floor_plan: FloorPlanModel;

  /**
   * Logo of project
   */
  public project_logo: ProjectLogoModel;

  // tslint:enable:variable-name
}
