import { configGetSettings } from 'common/helper/config/get-settings';
import { HeaderSavedPropertiesView } from 'common/module/header-saved-properties/view';
import { PropertySavedStoreService } from 'common/service/property-saved-store/service';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

export function HeaderSavedPropertiesViewFactory(): HeaderSavedPropertiesView {
  return new HeaderSavedPropertiesView(
    PropertySavedStoreService(),
    StatsGtmProviderService(),
    configGetSettings(),
    UserAuthenticationService()
  );
}
