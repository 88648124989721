import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class UserRegisterModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'user_register';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserRegisterModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserRegisterModel> = [
    'first_name',
    'last_name',
    'email',
    'password',
    'captcha_token',
    'opted_in',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof UserRegisterModel> = ['user'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['token'];

  // tslint:disable:variable-name
  /**
   * First name
   */
  public first_name: string = '';

  /**
   * Last name
   */
  public last_name: string = '';

  /**
   * Captcha token
   */
  public captcha_token: string = '';

  /**
   * Did user allow us to use email for marketing purposes?
   */
  public opted_in: boolean = false;

  // tslint:enable:variable-name

  /**
   * Email
   */
  public email: string = '';

  /**
   * Password
   */
  public password: string = '';

  /**
   * Is agreed with first conditional?
   */
  public termsAndConditions: boolean = false;

  /**
   * Is agreed with using ther email?
   */
  public useEmail: boolean = false;

  /**
   * User
   */
  public user: UserModel;

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Authorization token
     */
    token: string;

    /**
     * Refresh token
     */
    refresh_token: string;
  };
}
