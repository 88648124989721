import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { PropertyStoreFactory } from 'common/data/property/store.factory';
import { MyAccountStore } from 'common/module/my-account/store';
import { MyAccountStatechartService } from 'common/service/my-account-statechart/service';
import { MyNotesService } from 'common/service/my-notes/service';
import { PropertyStoreService } from 'common/service/property-store/service';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

const service = new MyAccountStore(
  EventEmitterFactory(),
  MyAccountStatechartService(),
  MyNotesService(),
  PropertyStoreFactory(),
  PropertyStoreService(),
  UserAuthenticationService(),
  StatsTealiumProviderService()
);

export function MyAccountStoreService(): MyAccountStore {
  return service;
}
