import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class JwtTokenModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'jwt_token';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = JwtTokenModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof JwtTokenModel> = ['id', 'payload', 'exp'];

  /**
   * Id
   */
  public id: string;

  /**
   * JWT token
   */
  public payload: string;

  /**
   * Expiration timestamp
   */
  public exp: number;
}
