import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from 'common/module/icon/template-props.interface';

export const IconSearchTemplate: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg width='15' height='15' viewBox='0 0 15 15' className={props.class}>
    {/* tslint:disable-next-line: max-line-length */}
    <path
      fill='white'
      fillRule='evenodd'
      clipRule='evenodd'
      d='M10.7399 9.67922L14.7806 13.7197C15.0735 14.0126 15.0735 14.4875 14.7806 14.7804C14.4877 15.0733 14.0128 15.0733 13.7199 14.7804L9.67925 10.7399C8.66323 11.5297 7.38654 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6C12 7.38653 11.5297 8.66321 10.7399 9.67922ZM6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5Z'
    />
  </svg>
);
