import { BrowserStorageLocalService } from 'pf-frontend-common/src/service/browser-storage-local/service';

import { AutocompleteCacheBrokersStore } from 'common/data/autocomplete/cache/brokers/store';
import { AutocompleteCacheWorkerFactory } from 'common/data/autocomplete/cache/worker.factory';
import { BrokerStoreFactory } from 'common/data/broker/store.factory';
import { configGetLanguage } from 'common/helper/config/get-language';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { DatabaseService } from 'common/service/database/service';

const service = new AutocompleteCacheBrokersStore(
  BrowserStorageLocalService(),
  configGetLanguage(),
  ApiEndpointService(),
  DatabaseService(),
  BrokerStoreFactory(),
  AutocompleteCacheWorkerFactory(),
  window.location.origin
);

export function AutocompleteCacheBrokersService(): AutocompleteCacheBrokersStore {
  return service;
}
