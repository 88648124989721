import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { PropertyModel } from 'common/data/property/model';
import { PropertyStore } from 'common/data/property/store';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';

export function PropertyStoreFactory(): PropertyStore {
  return new PropertyStore(
    EventEmitterFactory(),
    ApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<PropertyModel>(PropertyModel.JSONAPI_TYPE)
  );
}
