export const enum HeaderSavedPropertiesCssClassEnum {
  hidden = 'header-saved-properties--hidden',
  invisible = 'header-saved-properties--invisible',
  loggedIn = 'header-saved-properties--logged-in',
  loggedOut = 'header-saved-properties--logged-out',
  withIconLabel = 'header-saved-properties__button--with-icon-label',
  counterHidden = 'header-saved-properties__counter--hidden',
  counterWithIconLabel = 'header-saved-properties__counter--with-icon-label',
  notificationHidden = 'notification-dot--hidden',
}
