import { IDBPCursorWithValue, IDBPDatabase } from 'idb';

import { FilterSettingsModel } from 'common/data/filter-settings/model';
import { configGetLanguage } from 'common/helper/config/get-language';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { DatabaseServiceInterface } from 'common/service/database/service.interface';

export class FilterSettingsDatabaseStore {
  /**
   * Table name
   */
  private tableName: string = `${configGetLanguage().current}_${DatabaseTableEnum.filterSettings}`;

  /**
   * Constructor
   */
  constructor(private databaseService: DatabaseServiceInterface<FilterSettingsModel>) {}

  /**
   * Update database
   */
  public updateDatabase(settings: FilterSettingsModel[]): Promise<void> {
    return this.databaseService
      .openDatabase({
        languages: configGetLanguage(),
      })
      .then(this.onUpdateDatabase(settings));
  }

  /**
   * Get settings
   */
  public getAll(): Promise<FilterSettingsModel[]> {
    return this.databaseService
      .openDatabase({
        languages: configGetLanguage(),
      })
      .then(this.onGetAll);
  }

  /**
   * Update settings in database
   */
  private onUpdateDatabase = (settings: FilterSettingsModel[]) => (database: IDBPDatabase<FilterSettingsModel>) => {
    if (Object.keys(settings).length && database) {
      const transaction = database.transaction(this.tableName, 'readwrite');
      const store = transaction.objectStore(this.tableName);

      // Clear old data from DB
      return store.clear().then(() => {
        // Update DB with new data
        store.put(settings);
      });
    }
    return Promise.reject();
  };

  /**
   * Get all settings from database
   */
  private onGetAll = async (database: IDBPDatabase<FilterSettingsModel>) => {
    const settings: FilterSettingsModel[][] = [];
    const transaction = database?.transaction(this.tableName, 'readonly');

    if (transaction) {
      const store = transaction.objectStore(this.tableName);

      let cursor = await store.openCursor();

      const onIterateCursor = this.onIterateCursor(settings);

      while (cursor) {
        cursor = await onIterateCursor(cursor);
      }

      return transaction.done.then(() => settings[0]);
    }
  };

  /**
   * Update settings list
   */
  private onIterateCursor =
    (settings: FilterSettingsModel[][]) =>
    (
      cursor: IDBPCursorWithValue<FilterSettingsModel, [string]>
    ): Promise<IDBPCursorWithValue<FilterSettingsModel, [string]>> => {
      settings.push(cursor.value);
      return cursor.continue();
    };
}
