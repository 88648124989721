import { FunctionalComponent, h } from 'preact';

import { AutocompleteResultInterface } from '../result.interface';
import { AutocompleteSuggestionTemplate } from '../suggestion/template';
import { AutocompleteSectionTemplatePropsInterface } from './template-props.interface';

export const AutocompleteSectionTemplate: FunctionalComponent<AutocompleteSectionTemplatePropsInterface> = (props) => (
  <div className='autocomplete__section'>
    <div className='autocomplete__section-name'>{props.suggestionSection.name}</div>
    <div className='autocomplete__suggestions'>
      {props.suggestionSection.suggestions.map((suggestion: AutocompleteResultInterface, index) => (
        <AutocompleteSuggestionTemplate
          section={props.suggestionSection}
          searchString={props.searchString}
          suggestion={suggestion}
          isFocused={props.isFocused && props.focusedSuggestion === index}
          onFocus={props.onFocusResultItem}
          onSelectResultItem={props.onSelectResultItem}
        />
      ))}
    </div>
  </div>
);
