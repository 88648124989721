import { FunctionalComponent, h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteSectionTemplate } from 'common/module/autocomplete/section/template';
import { AutocompleteTemplatePropsInterface } from 'common/module/autocomplete/template-props.interface';
import { Loader4Template } from 'common/module/loader/4/template';
import { preactLifecycleComponent } from 'common/module/preact/lifecycle-component';

const Markup: FunctionalComponent<AutocompleteTemplatePropsInterface> = (props) => (
  <div className={`autocomplete ${props.className || ''}`}>
    <div className='autocomplete__input-wrapper'>
      <input
        ref={(el) => props.onInputRendered && props.onInputRendered(el as HTMLInputElement)}
        className='autocomplete__input light-search__input'
        onKeyUp={props.onKeyUpInput}
        onKeyDown={props.onKeyDownInput}
        onClick={props.onClickInput}
        placeholder={i18nTranslate('Start your search')}
        onBlur={props.onBlurInput}
        onFocus={props.onFocusInput}
        autoComplete='off'
      />
      {!props.isLoaderVisible && props.showClearBtn && !!props.onClear && (
        <div className='autocomplete__clear' onClick={props.onClear}>
          <span
            className='svg'
            dangerouslySetInnerHTML={{
              __html: `
                <svg viewBox='0 0 30 20' class='autocomplete__clear-icon'>
                  <use xlink:href='#svg-close'></use>
                </svg>
              `,
            }}
          />
        </div>
      )}

      {props.isLoaderVisible && (
        <div className='autocomplete__loader'>
          <Loader4Template />
        </div>
      )}
    </div>
    <div
      className='autocomplete__dropdown light-search__dropdown'
      ref={props.onDropdownRendered}
      style={{
        display: props.isOpened && !!props.autocompleteResults.length ? 'block' : 'none',
      }}
    >
      {props.autocompleteResults.map(
        (result: AutocompleteSectionInterface, index: number) =>
          !!result.suggestions.length && (
            <AutocompleteSectionTemplate
              isFocused={props.focusedSection === index}
              focusedSuggestion={props.focusedSuggestion}
              suggestionSection={result}
              searchString={props.searchString || ''}
              onFocusResultItem={props.onFocusResultItem}
              onSelectResultItem={props.onSelectResultItem}
            />
          )
      )}
    </div>
  </div>
);

export const PropertySerpLightAutocompleteInputTemplate = preactLifecycleComponent(Markup);
