import { JsonApiStore } from '@propertyfinder/pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from '@propertyfinder/pf-frontend-common/src/service/api/service.interface';

import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';

import { UserFrontendLoginModel } from '../user/frontend/login/model';
import { LoginSocialFetchUserRequestInterface } from './fetch-user-request.interface';
import { LoginSocialProviderEnum } from './provider.enum';

export class LoginSocialStore {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: Record<LoginSocialProviderEnum, Record<string, string>> = {
    facebook: {
      path: this.apiEndpointService.getPath('/user/b2c-login-social/facebook'),
      method: 'POST',
    },
    google: {
      path: this.apiEndpointService.getPath('/user/b2c-login-social/google'),
      method: 'POST',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private jsonApiStore: JsonApiStore<UserFrontendLoginModel>
  ) {}

  /**
   * Fetch user
   */
  public fetchUser(provider: LoginSocialProviderEnum, token: string): Promise<UserFrontendLoginModel> {
    const request: LoginSocialFetchUserRequestInterface = {
      data: {
        type: 'access_token_request',
        attributes: {
          access_token: token,
        },
      },
    };

    return this.apiService
      .request(this.endpoint[provider].method, this.endpoint[provider].path, request)
      .then((httpResponse) => {
        const sync = this.jsonApiStore.syncWithMeta(httpResponse.data);

        return <UserFrontendLoginModel>sync.data;
      });
  }
}
