import { StatsGuardServiceInterface } from '@propertyfinder/pf-frontend-common/src/service/stats-guard/service.interface';

import { UserFrontendLoginProvidersEnum } from 'common/data/user/frontend/login/providers.enum';
import { configGetFeature } from 'common/helper/config/get-feature';
import { BrowserCookieInterface } from 'common/module/browser/cookie.interface';
import { StatsTealiumCollectTypeEnum } from 'common/module/stats/tealium/collect-type.enum';
import { StatsTealiumDataLayerInterface } from 'common/module/stats/tealium/data-layer.interface';
import { StatsTealiumDataLayerEventInterface } from 'common/module/stats/tealium/data-layer/event.interface';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { StatsTealiumWindowInterface } from 'common/module/stats/tealium/window.interface';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { UserSigninStatsTealiumUserLogOut } from 'common/module/user/sign-in/stats/tealium/user-log-out';
import { UserSigninStatsTealiumUserLogin } from 'common/module/user/sign-in/stats/tealium/user-login';
import { UserSigninStatsTealiumUserRegister } from 'common/module/user/sign-in/stats/tealium/user-register';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

import { StatsGtmProviderInterface } from '../gtm/provider.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from './data-layer/event-category.enum';

export class StatsTealiumProvider implements StatsTealiumProviderInterface {
  /**
   * Queue the fired events as utag is not ready yet
   */
  private collectedQueue: Array<{
    tealiumEventType: StatsTealiumCollectTypeEnum;
    data: StatsTealiumDataLayerInterface;
  }> = [];

  private eventReference: StatsTealiumDataLayerEventReferenceEnum;

  constructor(
    private window: StatsTealiumWindowInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface,
    private statsGuardService: StatsGuardServiceInterface,
    private statsGtm: StatsGtmProviderInterface,
    private cookies: BrowserCookieInterface
  ) {
    if (window.trackers?.tealium?.blocked === true) {
      // eslint-disable-next-line no-console
      console.info('tealium is blocked', { tealium_blocked: true });

      this.statsGtm.send({
        event: 'customEvent',
        eventCategory: 'Trackers',
        eventAction: 'tealium_blocked',
      });
    }
  }

  /**
   * @inheritdoc
   */
  public initialize(): void {
    if (configGetFeature('flagship').enabled) {
      this.send({
        tealium_event: 'flagship',
        event_category: StatsTealiumDataLayerEventCategoryEnum.view,
        event_value: '1',
        event_action: 'web_user',
        event_label: this.window.tealium?.page_category,
      });
    }

    this.observers();
  }

  public setEventReference(eventReference: StatsTealiumDataLayerEventReferenceEnum): void {
    this.eventReference = eventReference;
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    this.userAuthenticationService
      .getEventEmitter()
      .removeListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSuccess);
    this.userAuthenticationService
      .getEventEmitter()
      .removeListener(UserAuthenticationEvent.logOutSucceeded, this.onLogOutSuccess);
    this.userAuthenticationService
      .getEventEmitter()
      .removeListener(UserAuthenticationEvent.registrationSucceeded, this.onRegistrationSuccess);

    this.window.removeEventListener('tealium:loaded', this.onTealiumLoaded);
  }

  /**
   * @inheritdoc
   */
  public send(
    data: StatsTealiumDataLayerEventInterface,
    tealiumEventType: StatsTealiumCollectTypeEnum = StatsTealiumCollectTypeEnum.link
  ): void {
    const { sendToGa, ...rest } = data;
    if (sendToGa) {
      const { event_action, event_label, event_category, ...remaining } = rest;
      this.collect(tealiumEventType, {
        ...remaining,
        ga_event_action: event_action,
        ga_event_label: event_label,
        ga_event_category: event_category,
      } as StatsTealiumDataLayerInterface);
    } else {
      this.collect(tealiumEventType, data);
    }
  }

  /**
   * @inheritdoc
   */
  public view(data: StatsTealiumDataLayerInterface): void {
    this.collect(StatsTealiumCollectTypeEnum.view, data);
  }

  /**
   * Triggered when tealium (utag) is loaded
   */
  private onTealiumLoaded = (): void => {
    this.collectedQueue.reverse();

    while (this.collectedQueue.length) {
      this.collect(
        ...(Object.values(this.collectedQueue.pop()) as [StatsTealiumCollectTypeEnum, StatsTealiumDataLayerInterface])
      );
    }
  };

  /**
   * on log-in event listener
   */
  private onSignInSuccess = (payload?: { provider?: UserFrontendLoginProvidersEnum }): void => {
    const userModel = this.userAuthenticationService.getUser();
    this.send(UserSigninStatsTealiumUserLogin(userModel, payload.provider, this.eventReference));
  };

  /**
   * on register event listener
   * @param payload { provider: UserFrontendLoginProvidersEnum }
   */
  private onRegistrationSuccess = (payload?: { provider?: UserFrontendLoginProvidersEnum }): void => {
    const userModel = this.userAuthenticationService.getUser();
    this.send(UserSigninStatsTealiumUserRegister(userModel, payload.provider, this.eventReference));
  };

  /**
   * on log-out event listener
   */
  private onLogOutSuccess = (): void => {
    const userModel = this.userAuthenticationService.getUser();
    this.send(UserSigninStatsTealiumUserLogOut(userModel));
  };

  /**
   * Attach observers
   */
  private observers(): void {
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSuccess);
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.logOutSucceeded, this.onLogOutSuccess);
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onRegistrationSuccess);

    this.window.addEventListener('tealium:loaded', this.onTealiumLoaded);
  }

  /**
   *
   * @param tealiumEventType StatsTealiumCollectTypeEnum
   * @param data StatsTealiumDataLayerInterface
   */
  private collect(tealiumEventType: StatsTealiumCollectTypeEnum, data: StatsTealiumDataLayerInterface): void {
    // Guard
    if (this.statsGuardService.isAuthorized() === false) {
      return;
    }

    if (this.window.utag) {
      const flagshipVisitorId = this.cookies.getData('flagship_user_id');

      const payload = {
        ...data,
        page_currency_code: this.window.tealium.page_currency_code,
        page_type: this.window.tealium.page_type,
        flagship_visitor_id: flagshipVisitorId,
        page_framework: this.window.tealium.page_framework,
        page_environment: this.window.tealium.page_environment,
      };

      this.window.dispatchEvent(new CustomEvent('PF_TEALIUM_EVENT', { detail: { data: payload, tealiumEventType } }));

      if (tealiumEventType === StatsTealiumCollectTypeEnum.track) {
        this.window.utag[tealiumEventType](payload.tealium_event, payload);
      } else {
        this.window.utag[tealiumEventType](payload);
      }
    } else {
      // collect the event so that they will be triggered if utag is available.
      this.collectedQueue.push({ tealiumEventType, data });
    }
  }
}
