import { DomElementEvent } from 'pf-frontend-common/src/module/dom/element.event';
import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { ViewOptionsInterface } from 'pf-frontend-common/src/module/view/options.interface';
import { BrowserDocumentServiceInterface } from 'pf-frontend-common/src/service/browser-document/service.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

export class GoBackView implements ViewBasicInterface {
  /**
   * View element
   */
  private element: HTMLElement;

  /**
   * Constructor
   */
  constructor(
    private browserDocumentService: BrowserDocumentServiceInterface,
    private windowService: WindowServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: ViewOptionsInterface): void {
    // Keep element
    this.element = options.element;

    // Attach observers
    this.observers();
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    // Nothing special to render for now
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.element.addEventListener(DomElementEvent.click, this.onClickElement);
  }

  /**
   * Element was clicked.
   * Go back only if referer is from current domain.
   * Works slightly different than JS history back (ignore historyState updates)
   */
  private onClickElement = (e: Event): void => {
    const document = this.browserDocumentService.getDocument();
    const referrer = document.referrer;
    const hasValidReferrer = referrer && -1 !== document.referrer.indexOf(this.windowService.getNative().location.host);
    const noHistory = (<HTMLElement>e.currentTarget).dataset.noHistory;

    // If history is disabled for this link then navigate to link
    if (noHistory) {
      return;
    }

    // If referer is not valid then navigate to link
    if (!hasValidReferrer) {
      return;
    }

    e.preventDefault();

    // Navigate back
    this.windowService.getNative().history.back();
  };
}
