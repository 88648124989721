import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { functionNoop } from 'common/helper/function/noop';
import { functionSelf } from 'common/helper/function/self';
import { Input2Component } from 'common/module/input2/component';

import { AllowedKeysEnum } from './allowed-keys.enum';
import { DropdownListOptionInterface } from './option.interface';
import { DropdownListOptionTypeEnum } from './option-type.enum';
import { DropdownListPropsInterface } from './props.interface';

// TODO-FE[TPNX-2230]: Add test coverage
export const DropdownListComponent = <T extends {}>(props: DropdownListPropsInterface<T>) => {
  const {
    mapToLabel = functionSelf,
    mapToValue = functionSelf,
    onInput = functionNoop,
    onChange = functionNoop,
    onInputBlur = functionNoop,
    onInputFocus = functionNoop,
    onInputChange = functionNoop,
    onESC = functionNoop,

    className,
    listClassName,
    focused,
    inputPlaceholder,
    isSearchable,
    options,
    query,
    value,
  } = props;

  const selectedValues = (Array.isArray(value) ? value : [value]).map((item) => mapToValue(item));

  const [optionsIndexes] = useState(() =>
    (options as unknown as DropdownListOptionInterface[]).reduce((result, option, index) => {
      if (!option.type || option.type === DropdownListOptionTypeEnum.default) {
        result.push(index);
      }
      return result;
    }, [])
  );

  const [allowedKeys] = useState(() => Object.values(AllowedKeysEnum));

  const [navigated, setNavigated] = useState(() =>
    selectedValues
      ? optionsIndexes.findIndex((currentIndex) => mapToValue(options[currentIndex]) === selectedValues[0])
      : null
  );

  const listRef = useRef(null);

  useEffect(() => {
    if (focused) {
      const handleKeyEvent = (evt: KeyboardEvent) => {
        if (allowedKeys.includes(evt.key as AllowedKeysEnum)) {
          evt.preventDefault();
          switch (evt.key) {
            case AllowedKeysEnum.DOWN:
              setNavigated((navigated + 1) % optionsIndexes.length);
              break;
            case AllowedKeysEnum.UP:
              setNavigated(
                Number.isInteger(navigated) ? (navigated - 1 < 0 ? optionsIndexes.length - 1 : navigated - 1) : 0
              );
              break;
            case AllowedKeysEnum.ENTER:
              onChange(options[optionsIndexes[navigated]]);
              break;

            case AllowedKeysEnum.ESC:
              onESC();
              break;
          }
        }
      };

      document.addEventListener('keydown', handleKeyEvent);

      return () => {
        const navigatedElement: HTMLLIElement = listRef.current.querySelector('.dropdown-list__item--navigated');
        if (navigatedElement) {
          navigatedElement.querySelector('button').focus();
        }
        document.removeEventListener('keydown', handleKeyEvent);
      };
    }
  }, [focused, navigated]);

  const mapTypeToClassName: Record<DropdownListOptionTypeEnum, string> = {
    [DropdownListOptionTypeEnum.default]: 'dropdown-list__item--default',
    [DropdownListOptionTypeEnum.divider]: 'dropdown-list__item--divider',
    [DropdownListOptionTypeEnum.section]: 'dropdown-list__item--section',
  };

  return (
    <div className={`dropdown-list ${listClassName || ''}`}>
      {isSearchable && (
        <div className='dropdown-list__search'>
          <Input2Component value={query} onFocus={onInputFocus} onBlur={onInputBlur}>
            <input
              className='dropdown-list__search-input'
              placeholder={inputPlaceholder}
              value={query}
              onChange={onInputChange}
              onInput={onInput}
            />
          </Input2Component>
        </div>
      )}
      <ul className={['dropdown-list__items'].concat(className || []).join(' ')} ref={listRef}>
        {options.map((option, index) => {
          const item: DropdownListOptionInterface = {
            label: mapToLabel(option as any),
            key: mapToValue(option as any),
            ...(typeof option === 'object' ? option : {}),
          };

          return (
            <li
              className={`
                dropdown-list__item
                ${selectedValues.includes(item.key) ? 'dropdown-list__item--active' : ''}
                ${focused && index === optionsIndexes[navigated] ? 'dropdown-list__item--navigated' : ''}
                ${mapTypeToClassName[item.type] || ''}
              `}
              key={item.key}
            >
              {!item.type || item.type === DropdownListOptionTypeEnum.default ? (
                <button
                  className='dropdown-list__item-content dropdown-list__item-button'
                  onClick={() => onChange(option)}
                >
                  {item.label}
                </button>
              ) : (
                <div className='dropdown-list__item-content'>{item.label}</div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
