import { FunctionalComponent, h } from 'preact';

import { chooseAOrB } from 'common/helper/choose/a-or-b';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { ProgressiveImageComponent } from 'common/module/progressive-image/component';
import { PropertyCardTemplatePropsInterface } from 'common/module/property/card/template-props.interface';

export const HomePageRecommendedPropertiesListItemTemplate: FunctionalComponent<PropertyCardTemplatePropsInterface> = ({
  property,
  ...props
}) => (
  <a
    href={chooseAOrB('#', property.links.self)(props.isDisabled)}
    onClick={chooseAOrB(
      (e: Event) => e.preventDefault(),
      (e: Event) => props.onClickProperty(e, property)
    )(props.isDisabled)}
    target={chooseAOrB('_blank', '_self')(props.isTargetBlank)}
    className='featured-properties__block'
  >
    <ProgressiveImageComponent
      classname='featured-properties__image'
      src={property.links.image_property_homepage}
      loadedClass='card__img--loaded'
      alt={property.name}
      placeholder={__('assets/common/property-placeholder-sm.svg')}
      preloader={__('assets/common/image-loader.svg')}
    />
    {property.verified && (
      <div className='verified-tag tag tag--style2 tag__arrow-bottom featured-properties__tag'>
        <svg viewBox='0 0 12 9' className='verified-tag__icon'>
          <use xlinkHref='#svg-check-icon' />
        </svg>
        {i18nTranslate('Verified listing')}
        <div className='tooltip2 tooltip2--bottom'>
          <div className='tooltip2__content'>
            {i18nTranslate('Property Finder has verified the accuracy and authenticity of this listing.')}
          </div>
        </div>
      </div>
    )}
    {property.broker && (
      <ProgressiveImageComponent
        classname='featured-properties__broker-logo'
        src={property.broker.links.logo_178_98}
        loadedClass='card__img--loaded'
        alt={property.broker.name}
        placeholder={__('assets/common/broker-tile-placeholder.jpg')}
      />
    )}
    <div className='featured-properties__detail'>
      <h3 className='featured-properties__title'>{property.name}</h3>
      <div className='featured-properties__info'>
        <div className='featured-properties__type'>{(property.property_type && property.property_type.name) || ''}</div>
        {property.bedroom_value > 0 ? (
          <div className='featured-properties__bed'>
            {property.bedroom_name}
            <span className='svg'>
              <svg viewBox='0 0 70.098 53.605' className='button__icon--style5'>
                <use xlinkHref='#svg-bed' />
              </svg>
            </span>
          </div>
        ) : property.bedroom_value === 0 ? (
          <div className='featured-properties__bed'>
            {i18nTranslate('Studio')}
            <span className='svg'>
              <svg viewBox='0 0 70.098 53.605' className='button__icon--style5'>
                <use xlinkHref='#svg-bed' />
              </svg>
            </span>
          </div>
        ) : (
          ''
        )}

        {property.bathroom_value > 0 ? (
          <div className='featured-properties__bath'>
            {property.bathroom_name}
            <span className='svg'>
              <svg viewBox='0 0 70.098 53.605' className='button__icon--style5'>
                <use xlinkHref='#svg-bath' />
              </svg>
            </span>
          </div>
        ) : (
          ''
        )}
        <div className='featured-properties__price'>
          <strong>{property.meta.price_text}</strong>
        </div>
      </div>
      <div className='featured-properties__image-icons-area'>
        <span className='svg'>
          <svg viewBox='0 0 480 448' className='featured-properties__image-icon featured-properties__image-icon--photo'>
            <use xlinkHref='#svg-camera' />
          </svg>
        </span>
        {property.meta.images_count || 0}
        {property.view_360 && (
          <span className='svg'>
            <svg viewBox='0 0 24 25' className='featured-properties__image-icon'>
              <use xlinkHref='#svg-360-view' />
            </svg>
          </span>
        )}
        {property.meta?.video_metadata?.url && (
          <span className='svg'>
            <svg viewBox='0 0 24 24' className='featured-properties__image-icon'>
              <use xlinkHref='#svg-play-circle-2' />
            </svg>
          </span>
        )}
      </div>
    </div>
  </a>
);
