import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';

import { FooterViewFactory } from 'common/module/footer/view.factory';

import { FooterServiceInterface } from './service.interface';

const footerService = FooterViewFactory();

const element = domQuerySelector(document, '[data-qs="footer"]');

if (element) {
  footerService.initialize({
    element,
  });

  footerService.render();
}

export function FooterService(): FooterServiceInterface {
  return footerService;
}
