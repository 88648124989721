import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteViewStore as CommonAutocompleteViewStore } from 'common/module/autocomplete/view-store';
import { AutocompleteViewStoreEvent } from 'common/module/autocomplete/view-store.event';
import { KeyboardCharcodeEnum } from 'common/module/keyboard/charcode.enum';
import { AutocompleteViewStoreEvent as DesktopAutocompleteViewStoreEvent } from 'desktop/module/autocomplete/view-store.event';
import { AutocompleteViewStoreOptionsInterface } from 'desktop/module/autocomplete/view-store-options.interface';

export class AutocompleteViewStore extends CommonAutocompleteViewStore {
  /**
   * Autocomplete dropdown element
   */
  protected dropdownEl: HTMLElement;

  /**
   * Cached search string
   */
  protected cachedSearchString: string = '';

  /**
   * @inheritDoc
   */
  constructor(eventEmitter: EventEmitterInterface, windowService: WindowServiceInterface) {
    super(eventEmitter, windowService);

    this.state = {
      ...this.state,
      focusedSection: null,
      focusedSuggestion: null,
      onKeyDownInput: this.onKeyDownInput.bind(this),
      onDropdownRendered: this.onDropdownRendered.bind(this),
      onFocusResultItem: this.onFocusResultItem.bind(this),
    };
  }

  /**
   * @inheritDoc
   */
  public initialize(options: AutocompleteViewStoreOptionsInterface): void {
    this.setInitialValue(options.initialValue);
    this.setSearchString(options.initialValue);
    this.setPlaceholder(options.placeholder);
    this.toggleClearButtonVisibility(!!this.inputInitialValue);
  }

  /**
   * Sets focused section
   *
   * @param focusedSection - focused section index
   */
  public setFocusedSection(focusedSection: number): void {
    this.state.focusedSection = focusedSection;
  }

  /**
   * Sets focused suggestion
   *
   * @param focusedSuggestion - focused suggestion index
   */
  public setFocusedSuggestion(focusedSuggestion: number): void {
    this.state.focusedSuggestion = focusedSuggestion;
  }

  /**
   * @inheritDoc
   */
  public clear(): void {
    this.cachedSearchString = '';
    this.resetFocused();
    this.toggleClearButtonVisibility(false);
    super.clear();
  }

  /**
   * Focuses next result item
   */
  protected focusNext(): void {
    // if there is no results or dropdown is not opened then do nothing
    if (!this.state.autocompleteResults.length || !this.state.isOpened) {
      return;
    }

    if (!this.getFocusedSectionResult()) {
      return;
    }

    // if current focused suggestion is last in section
    if (!this.getFocusedSectionResult().suggestions[this.state.focusedSuggestion + 1]) {
      // if focused section is last section in list
      if (this.state.focusedSection === this.state.autocompleteResults.length - 1) {
        this.setFocusedSection(0);
      } else {
        this.setFocusedSection(this.state.focusedSection + 1);
      }

      // focus first suggestion in section
      this.setFocusedSuggestion(0);

      this.setSearchString(this.getFocusedSectionResult().suggestions[this.state.focusedSuggestion].text);
      this.setInputValue(this.state.searchString);

      return;
    }

    // focus next suggestion in section
    this.setFocusedSuggestion(this.state.focusedSuggestion + 1);

    this.setSearchString(this.getFocusedSectionResult().suggestions[this.state.focusedSuggestion].text);
    this.setInputValue(this.state.searchString);

    return;
  }

  /**
   * Focuses prev result item
   */
  protected focusPrev(): void {
    // if there is no results or dropdown is not opened then do nothing
    if (!this.state.autocompleteResults.length || !this.state.isOpened) {
      return;
    }

    if (!this.getFocusedSectionResult()) {
      return;
    }

    // if current focused suggestion is first in focused section
    if (!this.getFocusedSectionResult().suggestions[this.state.focusedSuggestion - 1]) {
      // if focused section is first in list
      if (this.state.focusedSection === 0) {
        this.setFocusedSection(this.state.autocompleteResults.length - 1);
      } else {
        this.setFocusedSection(this.state.focusedSection - 1);
      }

      // focus last suggestion in section
      this.setFocusedSuggestion(this.getFocusedSectionResult().suggestions.length - 1);

      this.setSearchString(this.getFocusedSectionResult().suggestions[this.state.focusedSuggestion].text);
      this.setInputValue(this.state.searchString);

      return;
    }

    // focus prev suggestion
    this.setFocusedSuggestion(this.state.focusedSuggestion - 1);

    this.setSearchString(this.getFocusedSectionResult().suggestions[this.state.focusedSuggestion].text);
    this.setInputValue(this.state.searchString);

    return;
  }

  /**
   * Focus last result item
   */
  protected focusLast(): void {
    const section = this.state.autocompleteResults.length - 1;

    const results = this.state.autocompleteResults[section];

    if (!results) {
      return;
    }

    this.focus(section, results.suggestions.length - 1);
  }

  /**
   * Focus autocompltete element
   */
  protected focus(section: number, suggestion: number): void {
    this.setFocusedSection(section);
    this.setFocusedSuggestion(suggestion);

    const focusedResult = this.getFocusedSectionResult();

    if (focusedResult) {
      this.setSearchString(focusedResult.suggestions[this.state.focusedSuggestion].text);
      this.setInputValue(this.state.searchString);
    }
  }

  /**
   * Resets focused suggestion to first suggestion of first section
   */
  protected resetFocused(): void {
    this.setFocusedSection(null);
    this.setFocusedSuggestion(null);
  }

  /**
   * Selects focused result item
   *
   * @param e - Event
   */
  protected selectFocusedResultItem(e: Event): void {
    // close dropdown
    this.toggle(false);

    if (!this.state.autocompleteResults[this.state.focusedSection]) {
      this.toggleClearButtonVisibility(true);

      // reset focused section and suggestion
      this.resetFocused();
      // reset cached string
      this.cachedSearchString = '';

      return;
    }

    const focusedSuggestion =
      this.state.autocompleteResults[this.state.focusedSection].suggestions[this.state.focusedSuggestion];

    // if focused result text is not the same as autocomplete search string
    if (focusedSuggestion.text !== this.state.searchString) {
      this.setSearchString(this.state.searchString);

      return;
    }
    e.preventDefault();

    this.toggleClearButtonVisibility(true);

    // select result item
    this.getEventEmitter().emit(AutocompleteViewStoreEvent.selectResultItem, e, focusedSuggestion);

    // reset focused section and suggestion
    this.resetFocused();
    // reset cached string
    this.cachedSearchString = '';
  }

  /**
   * @inheritDoc
   *
   * @param e - Event
   */
  protected onSelectResultItem(e: Event, result: AutocompleteResultInterface): void {
    this.resetFocused();
    this.setSearchString(result.text);
    this.setInputValue(this.state.searchString);
    this.cachedSearchString = '';

    super.onSelectResultItem(e, result);
  }

  /**
   * Handles autocomplete keyup event
   *
   * @param e - Event
   */
  protected onKeyUpInput(e: KeyboardEvent): void {
    // prevent default action for special keys
    switch (e.keyCode) {
      case KeyboardCharcodeEnum.enter:
        e.preventDefault();

        return;
      case KeyboardCharcodeEnum.up:
        e.preventDefault();

        return;
      case KeyboardCharcodeEnum.down:
        e.preventDefault();

        return;
    }

    super.onKeyUpInput(e);
    this.cachedSearchString = this.state.searchString;
    this.toggleClearButtonVisibility(!!this.state.searchString);
  }

  /**
   * Handles autocomplete keydown event
   *
   * @param e - Event
   */
  protected onKeyDownInput(e: KeyboardEvent): void {
    switch (e.keyCode) {
      case KeyboardCharcodeEnum.enter:
        if (this.state.isOpened) {
          this.selectFocusedResultItem(e);
        }

        this.getEventEmitter().emit(DesktopAutocompleteViewStoreEvent.enterPressed, e);

        return;
      case KeyboardCharcodeEnum.up:
        e.preventDefault();
        if (!this.cachedSearchString) {
          this.cachedSearchString = this.state.searchString;
        }

        if (!this.getFocusedSectionResult()) {
          this.focusLast();

          return;
        }

        this.focusPrev();

        return;
      case KeyboardCharcodeEnum.down:
        e.preventDefault();
        if (!this.cachedSearchString) {
          this.cachedSearchString = this.state.searchString;
        }

        if (!this.getFocusedSectionResult()) {
          this.focus(0, 0);

          return;
        }

        this.focusNext();

        return;
    }
  }

  /**
   * Triggers when user clicks on window
   *
   * @param e - Event
   */
  protected onClickWindow(e: Event): void {
    const target = <HTMLElement>e.target;

    if (!this.state.isOpened) {
      return;
    }

    if (this.base.contains(target)) {
      return;
    }

    this.resetFocused();

    if (this.cachedSearchString) {
      this.setSearchString(this.cachedSearchString);
      this.setInputValue(this.state.searchString);

      this.cachedSearchString = '';
    }

    super.onClickWindow(e);
  }

  /**
   * Triggers when dropdown is being rendered
   *
   * @param el - dropdown element
   */
  protected onDropdownRendered(el: HTMLElement): void {
    this.dropdownEl = el;
  }

  /**
   * Triggers when result item has being focused
   *
   * @param el - result item
   */
  protected onFocusResultItem(el: HTMLElement): void {
    if (!el) {
      return;
    }

    if (!this.dropdownEl) {
      return;
    }

    // if focused element is on bottom and is not visible in dropdown
    if (el.offsetTop + el.clientHeight >= this.dropdownEl.clientHeight + this.dropdownEl.scrollTop) {
      // then scroll dropdown to focused element
      this.dropdownEl.scrollTop = el.offsetTop - this.dropdownEl.clientHeight + el.clientHeight;

      return;
    }

    // if focused element is on top and is not visible in dropdown
    if (el.offsetTop < this.dropdownEl.scrollTop) {
      // then scroll dropdown to focused element
      this.dropdownEl.scrollTop = el.offsetTop;
    }
  }

  /**
   * Returns current focused section autocomplete result
   */
  private getFocusedSectionResult(): AutocompleteSectionInterface {
    return this.state.autocompleteResults[this.state.focusedSection];
  }
}
