import { BrowserStorageLocalService } from '@propertyfinder/pf-frontend-common/src/service/browser-storage-local/service';
import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { PropertySavedModel } from 'common/data/property-saved/model';
import { PropertySavedStore } from 'common/data/property-saved/store';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { StatechartStoreFactory } from 'common/module/statechart/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { DatabaseService } from 'common/service/database/service';
import { JwtTokenService } from 'common/service/jwt-token/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

export function PropertySavedStoreFactory(): PropertySavedStore {
  return new PropertySavedStore(
    DatabaseService(),
    StatechartStoreFactory(),
    EventEmitterFactory(),
    JsonApiStoreFactory<PropertySavedModel>('saved_property'),
    ApiService(),
    ApiEndpointService(),
    UserAuthenticationService(),
    JwtTokenService(),
    BrowserStorageLocalService()
  );
}
