import { EventEmitterFactory } from '@propertyfinder/pf-frontend-common/src/module/event/emitter.factory';

import { PropertyCtaStatechartService } from 'common/module/property-cta/statechart/service';
import { WhatsappContext } from 'common/module/whatsapp/context';

import { PropertyStoreService } from '../property-store/service';

const service = new WhatsappContext(EventEmitterFactory(), PropertyStoreService(), PropertyCtaStatechartService());

export const WhatsappContextService = (): WhatsappContext => service;
