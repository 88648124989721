import { BrowserDocumentServiceInterface } from '@propertyfinder/pf-frontend-common/dist/service/browser-document/service.interface';
import { WindowServiceInterface } from '@propertyfinder/pf-frontend-common/dist/service/window/service.interface';
import { BrowserWindowEvent } from 'pf-frontend-common/src/module/browser/window.event';

import { BackendConfigEnvInterface } from 'common/data/backend/config/env.interface';
import { BackendConfigEnvDebugEnum } from 'common/data/backend/config/env-debug.enum';
import { LoginSocialProviderEnum } from 'common/data/login-social/provider.enum';
import { LoginSocialStore } from 'common/data/login-social/store';
import { UserFrontendLoginModel } from 'common/data/user/frontend/login/model';
import { UserFrontendLoginProviderInterface } from 'common/data/user/frontend/login-provider.interface';

import { UserFrontendLoginProviderTypeEnum } from './type.enum';

export class UserFrontendLoginProviderGoogleOneTap implements UserFrontendLoginProviderInterface {
  /**
   * @inheritdoc
   */
  public initialization: UserFrontendLoginProviderTypeEnum = UserFrontendLoginProviderTypeEnum.onInit;

  /**
   * Script url
   */
  private readonly scriptUrl: string = 'https://accounts.google.com/gsi/client';

  /**
   * Constructor
   */
  public constructor(
    private loginSocialStore: LoginSocialStore,
    private browserDocumentService: BrowserDocumentServiceInterface,
    private windowService: WindowServiceInterface,
    private clientId: string,
    private env: BackendConfigEnvInterface
  ) {}

  /**
   * @inheritdoc
   */
  public signIn(): Promise<UserFrontendLoginModel> {
    return new Promise((resolve, reject) => {
      if (this.env.debug?.includes(BackendConfigEnvDebugEnum.delay_gsso)) {
        this.windowService.getNative().addEventListener(BrowserWindowEvent.load, () => {
          this.browserDocumentService.importScript(this.scriptUrl, true).then(this.onImportScript(resolve, reject));
        });
      } else {
        this.browserDocumentService
          .importScript(this.scriptUrl, true)
          .then(this.onImportScript(resolve, reject))
          .catch((err) => {
            console.error('Google One Tap:', err);
            reject();
          });
      }
    });
  }

  /**
   * Handle import script
   */
  private onImportScript = (resolve: (model: UserFrontendLoginModel) => void, reject: () => void) => () => {
    const google = this.windowService.getNative().google;

    if (google) {
      google.accounts.id.initialize({
        client_id: this.clientId,
        callback: this.onSignIn(resolve, reject),
      });
      google.accounts.id.prompt();
    }
  };

  /**
   * Handle sign in
   */
  private onSignIn =
    (resolve: (model: UserFrontendLoginModel) => void, reject: () => void) => (result: { credential: string }) => {
      this.loginSocialStore.fetchUser(LoginSocialProviderEnum.google, result.credential).then(resolve).catch(reject);
    };
}
