import { Snowplow2StatsAdapterFurnishings } from 'pf-frontend-common/src/module/snowplow2/stats/adapter/furnishings';
import { Snowplow2StatsAdapterPricePeriod } from 'pf-frontend-common/src/module/snowplow2/stats/adapter/price-period';
import { Snowplow2StatsSchemaListingContextInterface } from 'pf-frontend-common/src/module/snowplow2/stats/schema/listing-context.interface';
import { MarketType, OfferingTypeType, RelType } from 'pf-frontend-common/src/module/snowplow2/stats/types';

import { PropertyModel } from 'common/data/property/model';
import { PropertyStatsSnowplow2CompletionStatusAdapter } from 'common/data/property/stats/snowplow2/completion-status-adapter';
import { objectFilterNonValue } from 'common/helper/object/filter/non-value';

export class PropertyStatsSnowplow2ListingContextAdapter {
  /**
   * Constructor
   */
  constructor(private model: PropertyModel, private rel: RelType) {}

  /**
   * Get transformed data
   */
  public getData(): Snowplow2StatsSchemaListingContextInterface {
    const data: Snowplow2StatsSchemaListingContextInterface = {
      schema: 'iglu:ae.propertyfinder/listing_context/jsonschema/1-0-4',
      data: {
        web_id: parseInt(this.model.id, 10),
        title: this.model.name,
        bedrooms: this.model.bedroom_value,
        bathrooms: this.model.bathroom_value,
        furnishings: new Snowplow2StatsAdapterFurnishings(this.model.furnished || '').getData(),
        price_period: new Snowplow2StatsAdapterPricePeriod(this.model.price_period_identifier || '').getData(),
        price: this.model.meta.price,
        location_id: this.getLocationId(),
        broker_id: this.getBrokerId(),
        agent_id: this.getAgentId(),
        area_sqft: this.model.size,
        offering_type: this.getOfferingType(),
        property_type_id: this.getPropertyTypeId(),
        market: this.getMarket(),
        reference: this.model.reference,
        listing_status: this.model.listing_level,
        quality_score: this.model.quality_score,
        verified: this.model.verified,
        completion_status: new PropertyStatsSnowplow2CompletionStatusAdapter(this.model.completion_status).getData(),
        rel: this.rel,
        rera_permit_no: this.model.rera,
      },
    };

    data.data = objectFilterNonValue(data.data);

    return data;
  }

  /**
   * Get last location id in location tree
   */
  private getLocationId(): number {
    if (!this.model.location_tree || !this.model.location_tree.length) {
      return null;
    }

    return parseInt(this.model.location_tree[this.model.location_tree.length - 1].id, 10);
  }

  /**
   * Get Broker id
   */
  private getBrokerId(): number {
    if (!this.model.broker) {
      return null;
    }

    return parseInt(this.model.broker.id, 10);
  }

  /**
   * Get agent id
   */
  private getAgentId(): number {
    if (!this.model.agent) {
      return null;
    }

    return parseInt(this.model.agent.id, 10);
  }

  /**
   * Get offering type
   */
  private getOfferingType(): OfferingTypeType {
    if (!this.model.category_identifier) {
      return null;
    }

    return this.model.category_identifier.toLowerCase().match(/rent$/g) === null ? 'buy' : 'rent';
  }

  /**
   * Get property type id
   */
  private getPropertyTypeId(): number {
    if (!this.model.property_type || !this.model.property_type.id) {
      return null;
    }

    return parseInt(this.model.property_type.id, 10);
  }

  /**
   * Get market type
   */
  private getMarket(): MarketType {
    if (!this.model.category_identifier) {
      return null;
    }

    return this.model.category_identifier.toLowerCase().match(/^commercial/g) === null ? 'residential' : 'commercial';
  }
}
