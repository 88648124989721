import { FunctionalComponent, h } from 'preact';

import { CheckboxPropsInterface } from './props.interface';
import { CheckboxStateInterface } from './state.interface';

export const CheckboxComponentTemplate: FunctionalComponent<CheckboxPropsInterface & CheckboxStateInterface> = (
  props
) => (
  <div className={['checkbox-component'].concat(props.containerClassName || []).join(' ')}>
    <input
      className={['checkbox-component__input'].concat(props.inputClassName || []).join(' ')}
      type='checkbox'
      id={props.id}
      checked={props.checked}
      onClick={() => props.onInputClick()}
    />
    <label className={['checkbox-component__label'].concat(props.labelClassName || []).join(' ')} htmlFor={props.id}>
      <span className='checkbox-component__box' />
      {props.children}
    </label>
  </div>
);
