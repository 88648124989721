import { AutocompleteTemplatePropsInterface } from 'common/module/autocomplete/template-props.interface';
import { preactConnectStore } from 'common/module/preact/connect-store';
import { AutocompleteViewStore } from 'desktop/module/autocomplete/view-store';
import { PropertySerpLightAutocompleteInputTemplate } from 'desktop/module/property/serp/light/autocomplete/input/template';
import { PropertySerpLightAutocompleteService } from 'desktop/service/property-serp-light-autocomplete/service';

export const PropertySerpLightAutocompleteInputComponent = preactConnectStore<
  AutocompleteViewStore,
  AutocompleteTemplatePropsInterface
>(PropertySerpLightAutocompleteService, (store) => ({
  ...store.getState(),
}))(PropertySerpLightAutocompleteInputTemplate);
