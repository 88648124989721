import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { JwtTokenStore } from 'common/data/jwt/token/store';
import { MyNotesModel } from 'common/data/my-notes/model';
import { MyNotesRequestInterface } from 'common/data/my-notes/request.interface';
import { jsonApiFlatParams } from 'common/helper/json-api/flat-params';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { MyNotesServiceInterface } from 'common/service/my-notes/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

export class MyNotesStore implements MyNotesServiceInterface {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'my-notes';

  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    save: {
      path: this.apiEndpointService.getPath('/user/my-notes'),
      method: 'POST',
    },
    update: {
      path: this.apiEndpointService.getPath('/user/my-notes/{noteId}'),
      method: 'PATCH',
    },
    list: {
      path: this.apiEndpointService.getPath('/user/my-notes'),
      method: 'GET',
    },
    delete: {
      path: this.apiEndpointService.getPath('/user/my-notes'),
      method: 'DELETE',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface,
    private jsonApiStore: JsonApiStore<MyNotesModel>
  ) {}

  /**
   * @inheritDoc
   */
  public save(noteModel: MyNotesModel): Promise<string> {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const payload: MyNotesRequestInterface = {
      data: {
        type: noteModel.jsonApiType,
        attributes: {
          property_id: noteModel.property_id,
          description: noteModel.description,
        },
      },
    };

    // Send API request
    return this.apiService
      .request(
        this.endpoint.save.method,
        this.endpoint.save.path,
        objectFilterNonOrEmptyValue(payload),
        true,
        this.getApiHeaders(token)
      )
      .then((response: HttpApiResponseInterface) => {
        return response.data.data.id;
      })
      .catch((response) => {
        console.warn('Saving Note: ' + response.statusText);
        return Promise.reject(null);
      });
  }

  /**
   * @inheritDoc
   */
  public update(noteModel: MyNotesModel): Promise<string> {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const payload: MyNotesRequestInterface = {
      data: {
        type: noteModel.jsonApiType,
        attributes: {
          description: noteModel.description,
        },
      },
    };

    // Send API request
    return this.apiService
      .request(
        this.endpoint.update.method,
        this.endpoint.update.path.replace('{noteId}', noteModel.id),
        objectFilterNonOrEmptyValue(payload),
        true,
        this.getApiHeaders(token)
      )
      .then(() => {
        return noteModel.id;
      })
      .catch((response) => {
        console.warn('Updating Note: ' + response.statusText);
        return Promise.reject(null);
      });
  }

  /**
   * Load contacted properties
   */
  public loadOneById(propertyId: string): Promise<MyNotesModel> {
    const token = this.getToken();
    const data = jsonApiFlatParams({
      filter: {
        properties_ids: [propertyId],
      },
    });

    if (!token) {
      return;
    }

    return this.apiService
      .request(
        this.endpoint.list.method,
        this.endpoint.list.path,
        objectFilterNonOrEmptyValue(data),
        true,
        this.getApiHeaders(this.userAuthenticationService.getToken())
      )
      .then((response: HttpApiResponseInterface) => {
        if (!response.data.data.length) {
          return new MyNotesModel();
        }

        this.jsonApiStore.sync(response.data);
        return this.jsonApiStore.find(response.data.data[0].id);
      })
      .catch(() => {
        return Promise.reject(null);
      });
  }

  /**
   * @inheritDoc
   */
  public loadAll(): Promise<MyNotesModel[]> {
    const token = this.getToken();

    if (!token) {
      return Promise.resolve([]);
    }

    return this.apiService
      .request(
        this.endpoint.list.method,
        this.endpoint.list.path,
        {},
        true,
        this.getApiHeaders(this.userAuthenticationService.getToken())
      )
      .then((response: HttpApiResponseInterface) => {
        // Empty data
        if (!response.data.data) {
          // Consider data as an empty array
          return Promise.resolve([]);
        }

        this.jsonApiStore.reset();
        this.jsonApiStore.sync(response.data);

        return this.jsonApiStore.findAll();
      });
  }

  /**
   * @inheritDoc
   */
  public getAll(): MyNotesModel[] {
    return this.jsonApiStore.findAll();
  }

  /**
   * @inheritDoc
   */
  public find(id: string): MyNotesModel {
    return this.jsonApiStore.find(id);
  }

  /**
   * Delete my note
   */
  public deleteById = (noteId: string): Promise<boolean> => {
    const token = this.getToken();

    if (!token) {
      return;
    }

    // Send API request
    return this.apiService
      .request(
        this.endpoint.delete.method,
        `${this.endpoint.delete.path}/${noteId}`,
        null,
        true,
        this.getApiHeaders(token)
      )
      .then(() => true)
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
        return false;
      });
  };

  /**
   * Delete all my notes
   */
  public deleteAll = (): void => {
    const token = this.getToken();

    if (!token) {
      return;
    }

    // Send API request
    this.apiService
      .request(this.endpoint.delete.method, this.endpoint.delete.path, null, true, this.getApiHeaders(token))
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  };

  /**
   * Get token
   */
  private getToken(): string {
    // User authentication token
    return this.userAuthenticationService.getToken();
  }

  /**
   * Returns the API headers to attach to each request
   *
   */
  private getApiHeaders(token: string): DataKeyValueStringInterface {
    const headers: DataKeyValueStringInterface = {};
    headers[JwtTokenStore.HEADER_JWT] = 'Bearer ' + token;

    return headers;
  }
}
