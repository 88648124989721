import { AmenityModel } from 'common/data/amenity/model';
import { AreaModel } from 'common/data/area/model';
import { BathroomModel } from 'common/data/bathroom/model';
import { BedroomModel } from 'common/data/bedroom/model';
import { FilterModel } from 'common/data/filter/model';

export class PropertyTypeModel extends FilterModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'property_type';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = PropertyTypeModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof PropertyTypeModel> = ['id', 'name', 'value', 'key'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof PropertyTypeModel> = ['bedroom', 'amenities', 'bathroom', 'area'];

  /**
   * Property type bedroom
   */
  public bedroom: BedroomModel[];

  /**
   * Property type amenities
   */
  public amenities: AmenityModel[];

  /**
   * Property type bathroom
   */
  public bathroom: BathroomModel[];

  /**
   * Property type area
   */
  public area: AreaModel[];

  public key: string;
}
