import { PropertyStatsSnowplow2ListingContextAdapter } from 'common/data/property/stats/snowplow2/listing-context-adapter';
import { PropertyCardOnClickImagePayloadInterface } from 'common/module/property/card/onclick-image-payload.interface';
import { PropertyPageStatsSnowplow2LayoutContext } from 'common/module/property/page/stats/snowplow2/layout-context';
import { StatsSnowplow2ActionEnum } from 'common/module/stats/snowplow2/action.enum';
import { StatsSnowplow2ActionImageClickInterface } from 'common/module/stats/snowplow2/action/image-click.interface';
import { StatsSnowplow2SelfDescribingSchemaImageClickViewTypeEnum } from 'common/module/stats/snowplow2/self-describing/schema/image-click/view-type.enum';
import { StatsSnowplow2SelfDescribingSchemaImageContextInterface } from 'common/module/stats/snowplow2/self-describing/schema/image-context.interface';

export class PropertyListStatsSnowplow2ImageAdapter {
  /**
   * Constructor
   */
  constructor(
    private viewType: StatsSnowplow2SelfDescribingSchemaImageClickViewTypeEnum,
    private payload: PropertyCardOnClickImagePayloadInterface
  ) {}

  /**
   * Get image click action data
   */
  public getImageClick(): StatsSnowplow2ActionImageClickInterface {
    return {
      action: StatsSnowplow2ActionEnum.trackSelfDescribingEvent,
      options: {
        event: {
          schema: 'iglu:ae.propertyfinder/image_click/jsonschema/1-0-1',
          data: {
            view_type: this.viewType,
          },
        },
        context: [
          this.getImageContext(),
          PropertyPageStatsSnowplow2LayoutContext,
          new PropertyStatsSnowplow2ListingContextAdapter(this.payload.propertyModel, 'page').getData(),
        ],
      },
    };
  }

  /**
   * Get image context
   */
  private getImageContext(): StatsSnowplow2SelfDescribingSchemaImageContextInterface {
    return {
      schema: 'iglu:ae.propertyfinder/image_context/jsonschema/1-0-1',
      data: {
        image_src: this.payload.sourceUrl,
        target_image_src: this.payload.sourceUrl,
        image_number: this.payload.imageIndex,
        is_cover_image: this.payload.isCoverImage,
      },
    };
  }
}
