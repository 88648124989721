import { configGetLanguage } from 'common/helper/config/get-language';

/*
 * @description   Get the alternative URI for the current language
 * @param         {string} uri
 * @returns       {string}
 */
export function languageGetAltUri(path: string): string {
  const lang = configGetLanguage();
  const regex = new RegExp(`^/(${lang.current})($|/)`);

  return path.replace(regex, `/${lang.alternative}$2`);
}
