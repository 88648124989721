import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';

export class PropertyCategoryIdentifierAdapter {
  /**
   * Map a category ID to a category identifier.
   *
   * TODO-FE[WEB-1895] Mapping implemented for the sake of releasing, confirmed with Ben from BE.
   */
  private mapping: { [key: string]: PropertyCategoryIdentifierEnum } = {
    1: PropertyCategoryIdentifierEnum.residentialForSale,
    2: PropertyCategoryIdentifierEnum.residentialForRent,
    3: PropertyCategoryIdentifierEnum.commercialForSale,
    4: PropertyCategoryIdentifierEnum.commercialForRent,
  };

  /**
   * Returns a PropertyCategoryIdentifierEnum or empty string
   */
  public getData(categoryId: number): PropertyCategoryIdentifierEnum | string {
    return this.mapping[String(categoryId)] || '';
  }

  /**
   * Returns a category id or empty null
   */
  public getValue(categoryType: PropertyCategoryIdentifierEnum): string {
    return String(Object.keys(this.mapping)[Object.values(this.mapping).indexOf(categoryType)]) || null;
  }

  /**
   * Whether category is commercial
   */
  public isCommercial(category: PropertyCategoryIdentifierEnum): boolean {
    return (
      [PropertyCategoryIdentifierEnum.commercialForRent, PropertyCategoryIdentifierEnum.commercialForSale].indexOf(
        category
      ) !== -1
    );
  }

  /**
   * Whether category is residential
   */
  public isResidential(category: PropertyCategoryIdentifierEnum): boolean {
    return (
      [PropertyCategoryIdentifierEnum.residentialForSale, PropertyCategoryIdentifierEnum.residentialForRent].indexOf(
        category
      ) !== -1
    );
  }

  /**
   * Is residental and sale ?
   */
  public isResidentalSale(category: string): boolean {
    return PropertyCategoryIdentifierEnum.residentialForSale === (this.getData(parseInt(category, 10)) as string);
  }

  /**
   * Is the category identifier a rental category ?
   */
  public isRental(category: string): boolean {
    const rentalCategories: string[] = [
      PropertyCategoryIdentifierEnum.residentialForRent,
      PropertyCategoryIdentifierEnum.commercialForRent,
    ];
    return rentalCategories.includes(this.getData(parseInt(category, 10)) as string);
  }

  /**
   * Is the category identifier a sale category ?
   */
  public isSale(category: string): boolean {
    const saleCategories: string[] = [
      PropertyCategoryIdentifierEnum.residentialForSale,
      PropertyCategoryIdentifierEnum.commercialForSale,
    ];
    return saleCategories.includes(this.getData(parseInt(category, 10)) as string);
  }
}
