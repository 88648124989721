import { IconDataguru } from '@propertyfinder/icons';
import { FunctionalComponent, h } from 'preact';
import { useCallback, useMemo } from 'react';

import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from 'common/module/stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';

const RENT_SLUGS = ['rent', 'commercial_rent'];

export const DataGuruLinkTemplate: FunctionalComponent<{ category: string }> = ({ category }) => {
  const statsTealium = StatsTealiumProviderService();
  const eventLabelCategory = useMemo(() => (RENT_SLUGS.includes(category) ? 'rent' : 'buy'), [category]);
  const locale = configGetLanguage().current;
  const onDataGuruLinkClick = useCallback((): void => {
    statsTealium.send({
      sendToGa: true,
      tealium_event: StatsTealiumDataLayerTealiumEventEnum.dataGuruCtaClick,
      event_action: StatsTealiumDataLayerEventActionEnum.dataGuruSearchBarCtaClick,
      event_category: StatsTealiumDataLayerEventCategoryEnum.dataGuru,
      event_label: `search_entry_${eventLabelCategory}`,
      page_category: 'Home Portal',
      page_type: 'home',
    });
  }, [eventLabelCategory]);
  return (
    <div className='data-guru-link'>
      <span className='data-guru-link__text'>{i18nTranslate('DataGuru_HomePageLinkPreText')}</span>
      <a
        data-testid='data-guru-link'
        onClick={onDataGuruLinkClick}
        href={`/${locale}/area-insights/explore/dubai`}
        className='data-guru-link__link'
      >
        {i18nTranslate('DataGuru_HomePageLinkExplore')}

        <span className='data-guru-link__logo'>
          {i18nTranslate('DataGuru_DataGuru')}
          <IconDataguru className='data-guru-link__icon' />
        </span>
      </a>
    </div>
  );
};
