import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UnitModel } from 'common/data/unit/model';

export class ProjectPropertyModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'project_property';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = ProjectPropertyModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof ProjectPropertyModel> = [
    'id',
    'additional_payment',
    'additional_payment_amount',
    'down_payment_amount',
    'down_payment_rate',
    'installment_years',
    'monthly_payment_amount',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof ProjectPropertyModel> = ['unit'];

  /**
   * @inheritDoc
   */
  public jsonApiLinks: string[] = [];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = [];

  /**
   * @inheritDoc
   */
  public meta: {};

  /**
   * ID
   */
  public id: string;

  /**
   * Unit model
   */
  public unit: UnitModel;

  // tslint:disable:variable-name

  /**
   * Is there additional payment
   */
  public additional_payment: boolean;

  /**
   * Additional payment amount
   */
  public additional_payment_amount: number;

  /**
   * Down payment amount
   */
  public down_payment_amount: number;

  /**
   * Down payment rate
   */
  public down_payment_rate: number;

  /**
   * Installment years
   */
  public installment_years: number;

  /**
   * Monthly payment amount
   */
  public monthly_payment_amount: number;

  // tslint:enable:variable-name
}
