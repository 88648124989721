import { Component, h } from 'preact';

import { ChipChoicePropsInterface } from './props.interface';
import { ChipChoiceTemplate } from './template';

/**
 * Chip choice allow users to make selections from a list of values.
 *
 * Design: https://www.figma.com/file/gRARY1Vi4W2Ow1vRLw02am/PF_Consumer-Web-Kit?node-id=16%3A781
 */
export class ChipChoiceComponent extends Component<ChipChoicePropsInterface> {
  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    const Template = this.props.template || ChipChoiceTemplate;

    return <Template {...this.props} />;
  }
}
