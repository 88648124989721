import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { BrowserStorageLocalService } from 'pf-frontend-common/src/service/browser-storage-local/service';

import { LocationAutocompleteHistoryStore } from 'common/data/location/autocomplete/history/store';
import { AutocompleteCacheLocationsService } from 'common/service/autocomplete-cache-locations/service';
import { LocationAutocompleteHistoryServiceInterface } from 'common/service/location-autocomplete-history/service.interface';
import { LocationStoreService } from 'common/service/location-store/service';

const service = new LocationAutocompleteHistoryStore(
  EventEmitterFactory(),
  BrowserStorageLocalService(),
  AutocompleteCacheLocationsService(),
  LocationStoreService()
);

export function LocationAutocompleteHistoryService(): LocationAutocompleteHistoryServiceInterface {
  return service;
}
