import { Component, ComponentFactory, h } from 'preact';

export class PreactAsyncComponent<P = {}> extends Component<
  P & {
    component: () => Promise<ComponentFactory<P>>;
    loadingComponent?: ComponentFactory<P> | null;
  },
  {
    Component: ComponentFactory<P>;
  }
> {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      Component: null,
    };
  }

  /**
   * @inheritDoc
   */
  public componentWillMount(): void {
    if (!this.state.Component) {
      this.props.component().then((component) => this.setState({ Component: component }));
    }
  }

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    const C = this.state.Component;
    const LoadingComponent = this.props.loadingComponent as ComponentFactory<{}>;

    return C ? <C {...this.props}>{this.props.children}</C> : <LoadingComponent />;
  }
}
