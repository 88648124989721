import { ViewBasicInterface } from 'pf-frontend-common/dist/module/view/basic.interface';
import { ViewOptionsInterface } from 'pf-frontend-common/dist/module/view/options.interface';

import { GoogleRecaptchaViewStore } from 'common/module/google/recaptcha/view-store';
import { ViewDynamicInterface } from 'common/module/view/dynamic.interface';
import { CaptchaServiceInterface } from 'common/service/captcha/service.interface';

export class CaptchaView implements ViewBasicInterface, CaptchaServiceInterface {
  /**
   * View html element
   */
  private element: HTMLElement;

  /**
   * Is captcha visible
   */
  private isVisible: boolean = false;

  /**
   * Constructor
   */
  public constructor(private viewDynamic: ViewDynamicInterface<GoogleRecaptchaViewStore>) {}

  /**
   * @inheritdoc
   */
  public initialize(options: ViewOptionsInterface): void {
    this.element = options.element;

    this.viewDynamic.getViewStore().initialize({
      executeImmediately: true,
    });
  }

  /**
   * @inheritdoc
   */
  public render(): void {
    return;
  }

  /**
   * Toggle captcha
   */
  public toggle(isVisible: boolean): void {
    if (this.isVisible === isVisible) {
      return;
    }

    this.isVisible = isVisible;

    if (isVisible) {
      this.viewDynamic.initialize({
        element: this.element,
      });
      this.viewDynamic.render();

      return;
    }

    this.viewDynamic.destroy();
  }

  /**
   * Get view store
   */
  public getViewStore(): GoogleRecaptchaViewStore {
    return this.viewDynamic.getViewStore();
  }
}
