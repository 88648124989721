import { cookieAuthenticatorGetSecret } from './get-secret';
import { cookieAuthenticatorSha1 } from './sha1';

// this magic with class is required to avoid webpack inline the secret into the places where it is used
// making it more difficult to reverse engineer cookie sign algorithm
// tslint:disable-next-line: pf-file
class A {
  public static e(salt: string, cookie: string): string {
    return cookieAuthenticatorSha1(`${salt}${cookie}${cookieAuthenticatorGetSecret()}`);
  }
}

/**
 * Generate a hash from the cookie and salt
 */
export const cookieAuthenticatorGenerateHash = function e(salt: string, cookie: string): string {
  return A.e(salt, cookie);
};
