import { stringToNumber } from 'common/helper/string/to-number';
import { DropdownViewStoreOptionsInterface } from 'common/module/dropdown/view-store-options.interface';
import { FilterParametersFieldChoiceInterface } from 'common/module/filter/parameters/field-choice.interface';

export function propertySerpFormToRangeOptions(
  choices: Array<FilterParametersFieldChoiceInterface<string | number>>,
  value: string,
  isMinimum: boolean,
  isOptionsEqualityDisallowed?: boolean
): DropdownViewStoreOptionsInterface['options'] {
  if (!choices?.length) {
    return [];
  }

  if (isOptionsEqualityDisallowed) {
    if (isMinimum) {
      choices = choices.filter((choice) => !value || stringToNumber(choice.value as string) < stringToNumber(value));
    } else {
      choices = choices.filter((choice) => !value || stringToNumber(choice.value as string) > stringToNumber(value));
    }
  } else if (isMinimum) {
    choices = choices.filter((choice) => !value || stringToNumber(choice.value as string) <= stringToNumber(value));
  } else {
    choices = choices.filter((choice) => !value || stringToNumber(choice.value as string) >= stringToNumber(value));
  }

  return choices.map((choice) => ({ value: String(choice.value), text: choice.label }));
}
