import { DataStore } from 'common/module/data/store';
import { EmailAlertUnsubscribeTemplatePropsInterface } from 'common/module/email-alert/unsubscribe/template-props.interface';

export class EmailAlertUnsubscribeViewStore extends DataStore {
  /**
   * @inheritDoc
   */
  protected state: EmailAlertUnsubscribeTemplatePropsInterface;

  /**
   * @inheritDoc
   */
  public initialize(options: EmailAlertUnsubscribeTemplatePropsInterface): void {
    this.state = options;
  }
}
