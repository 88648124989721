import { asyncDebounce } from 'common/helper/async/debounce';
import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerSomethingNewEverydayEventInterface } from 'common/module/stats/tealium/data-layer/something-new-everyday-event.interface';
import { StatsTealiumDataLayerTealiumEventEnum } from 'common/module/stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';

import { NewEverydayCategory } from './enum';

export class NewEverydayTracker {
  private tealiumScrollDebounce: (type: NewEverydayCategory) => void;

  constructor(private statsTealium: StatsTealiumProviderInterface) {
    this.tealiumScrollDebounce = asyncDebounce((type: NewEverydayCategory) => this.scrollTealiumData(type), 500);
  }

  public onCommunityCardClick = (neighborhood: string, index: number): void => {
    const tealiumData: StatsTealiumDataLayerSomethingNewEverydayEventInterface = {
      sendToGa: true,
      event_category: StatsTealiumDataLayerEventCategoryEnum.somethingNew,
      event_action: StatsTealiumDataLayerEventActionEnum.clickCommunityCard,
      event_label: neighborhood,
      tealium_event: StatsTealiumDataLayerTealiumEventEnum.somethingNewCommunity,
      community_card_position: index.toString(),
    };
    this.statsTealium.send(tealiumData);
  };

  public onLinkClick = (type: NewEverydayCategory): void => {
    this.statsTealium.send({
      sendToGa: true,
      event_category: StatsTealiumDataLayerEventCategoryEnum.somethingNew,
      event_action: StatsTealiumDataLayerEventActionEnum.clickNewProperties,
      event_label: type.toString(),
      tealium_event: StatsTealiumDataLayerTealiumEventEnum.somethingNewProperties,
    });
  };

  public onGalleryScroll = (type: NewEverydayCategory): void => {
    this.tealiumScrollDebounce(type);
  };

  private scrollTealiumData = (type: NewEverydayCategory) => {
    this.statsTealium.send({
      sendToGa: true,
      event_category: StatsTealiumDataLayerEventCategoryEnum.somethingNew,
      event_action: StatsTealiumDataLayerEventActionEnum.clickScroll,
      event_label: type.toString(),
      tealium_event: StatsTealiumDataLayerTealiumEventEnum.somethingNewScroll,
    });
  };
}
