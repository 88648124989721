export const enum StatsTealiumDataLayerEventReferenceEnum {
  /**
   * Header
   */
  HEADER = 'header',

  /**
   * Save Property
   */
  SAVED_PROPERTIES = 'saved_properties',

  /**
   * Save Search
   */
  SAVED_SEARCH = 'saved_search',

  /**
   * Email Lead
   */
  EMAIL_LEAD = 'email_lead',

  /**
   * Email Lead
   */
  CALL_LEAD = 'email_lead',

  WHATSAPP_LEAD = 'sign_up_wall',

  /**
   * Google One Tap
   */
  GOOGLE_ONE_TAP = 'google_one_tap',

  /**
   * Report Property
   */
  REPORT_PROPERTY = 'report_property',

  BOTTOM_NAVIGATION_BAR = 'bottom_navigation_bar',

  POST_CALL_POPUP = 'post_call_popup',
}
