import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';

import { HeaderViewFactory } from 'common/module/header/view.factory';
import { HeaderViewOptionsInterface } from 'common/module/header/view-options.interface';
import { HeaderSavedPropertiesCssSelectorEnum } from 'common/module/header-saved-properties/css-selector.enum';

import { HeaderServiceInterface } from './service.interface';

const headerService = HeaderViewFactory();

const element = domQuerySelector(document, '[data-qs="header"]');

if (element) {
  // View options
  const options: HeaderViewOptionsInterface = {
    element,
    subview: {},
  };

  // Optional view: go back
  const elementGoBack = domQuerySelector(element, '[data-qs="go-back"]');

  if (elementGoBack) {
    options.subview.goBack = {
      viewOptions: {
        element: elementGoBack,
      },
    };
  }

  // Optional view: user save icon
  const elementUserSaveIcon = domQuerySelector(element, HeaderSavedPropertiesCssSelectorEnum.element);

  if (elementUserSaveIcon) {
    options.subview.headerSavedProperties = {
      viewOptions: {
        element: elementUserSaveIcon,
      },
    };
  }

  // Initialize view
  headerService.initialize(options);
}

export function HeaderService(): HeaderServiceInterface {
  return headerService;
}
