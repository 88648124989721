import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';
import { BrowserDocumentService } from 'pf-frontend-common/src/service/browser-document/service';
import { StatsContexterService } from 'pf-frontend-common/src/service/stats-contexter/service';

import { configGetSettings } from 'common/helper/config/get-settings';
import { preactMarkup } from 'common/helper/preact/markup';
import { HomePageHighlightedFeaturesComponent } from 'common/module/home-page-highlighted-features/component';
import { NewEverydayComponent } from 'common/module/new-everyday/component';
import { SuperAgentSliderComponent } from 'common/module/super-agent/slider/component';
import { FilterService } from 'common/service/filter/service';
import { FilterCacheService } from 'common/service/filter-cache/service';
import { PropertyStoreService } from 'common/service/property-store/service';
import { StatsService } from 'common/service/stats/service';
import { HomePageBundleSettingsInterface } from 'desktop/entry/home-page/bundle/settings.interface';
import { EmailAlertUnsubscribeViewFactory } from 'desktop/module/email-alert/unsubscribe/view.factory';
import { HomePageRecommendedPropertiesComponent } from 'desktop/module/home-page/recommended-properties/component';
import { HomePageSearchFormComponent } from 'desktop/module/home-page/search-form/component';
import { HomePageView } from 'desktop/module/home-page/view';
import { PropertySerpLightComponent } from 'desktop/module/property/serp/light/component';
import { RecommendedPropertiesService } from 'desktop/service/recommended-properties/service';

import { HomePageAgentHubCardComponent } from './agent-hub/card.component';
import { HomePageHeroContentComponent } from './hero-content/component';
import { HomePageMarketingAdsComponent } from './marketing-ads/component';
import { HomePagePrestigeComponent } from './prestige/component';

export function HomePageViewFactory(): HomePageView {
  return new HomePageView(
    FilterCacheService(),
    PropertyStoreService(),
    FilterService(),
    StatsContexterService(),
    StatsService(),
    EmailAlertUnsubscribeViewFactory(),
    domQuerySelector,
    configGetSettings<HomePageBundleSettingsInterface>(),
    preactMarkup,
    BrowserDocumentService(),
    {
      NewEverydayComponent,
      HomePageSearchFormComponent,
      HomePageRecommendedPropertiesComponent,
      HomePageMarketingAdsComponent,
      HomePageAgentHubCardComponent,
      PropertySerpLightComponent,
      HomePageHighlightedFeaturesComponent,
      SuperAgentSliderComponent,
      HomePagePrestigeComponent,
      HomePageHeroContentComponent,
    },
    RecommendedPropertiesService()
  );
}
