import { FunctionalComponent, h } from 'preact';

import { configGetLanguage } from 'common/helper/config/get-language';
import { PreactAsyncComponent } from 'common/module/preact/async/component';
import { UserAuthDrawerComponentPropsInterface } from 'common/module/user/auth/drawer-component-props.interface';
import { AssetLoaderCssService } from 'common/service/asset-loader-css/service';

export const UserAuthDrawerAsyncComponent: FunctionalComponent<UserAuthDrawerComponentPropsInterface> = (props) => (
  <PreactAsyncComponent
    {...props}
    component={() =>
      Promise.all([
        import(
          /* webpackChunkName: "user-auth-drawer-component" */
          './drawer-component'
        ),
        AssetLoaderCssService().importBundle(__css('css/async-auth-drawer.css')[configGetLanguage().current]),
      ]).then((response) => response[0].UserAuthDrawerComponent)
    }
  />
);
