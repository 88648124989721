import { WindowServiceInterface } from 'pf-frontend-common/dist/service/window/service.interface';

import { AutocompleteCacheAgentsStore } from 'common/data/autocomplete/cache/agents/store';
import { AutocompleteCacheBrokersStore } from 'common/data/autocomplete/cache/brokers/store';
import { AutocompleteCacheLocationsStore } from 'common/data/autocomplete/cache/locations/store';
import { LocationModel } from 'common/data/location/model';
import { AutocompleteStoreEvent } from 'common/module/autocomplete/store.event';
import { AutocompleteStoreInterface } from 'common/module/autocomplete/store.interface';
import { AutocompleteStoreDecorator } from 'common/module/autocomplete/store/decorator';
import { AutocompleteStoreSearchConfigInterface } from 'common/module/autocomplete/store/search-config.interface';

export class AutocompleteStoreCacheableDecorator extends AutocompleteStoreDecorator {
  /**
   * Locations animation frame id
   */
  private locationsAnimationFrameId: number;

  /**
   * Agents animation frame
   */
  private agentsAnimationFrameId: number;

  /**
   * Brokers animation frame
   */
  private brokersAnimationFrameId: number;

  /**
   * @inheritDoc
   */
  constructor(
    autocompleteStore: AutocompleteStoreInterface,
    private autocompleteCacheLocations: AutocompleteCacheLocationsStore,
    private autocompleteCacheBrokers: AutocompleteCacheBrokersStore,
    private autocompleteCacheAgents: AutocompleteCacheAgentsStore,
    private windowService: WindowServiceInterface
  ) {
    super(autocompleteStore);
  }

  /**
   * @inheritDoc
   */
  public searchLocations(
    searchString: string,
    config?: AutocompleteStoreSearchConfigInterface
  ): Promise<LocationModel[]> {
    if (this.autocompleteCacheLocations.getCache().length) {
      this.autocompleteStore.getEventEmitter().emit(AutocompleteStoreEvent.searchLocationsStart, searchString);

      if (this.locationsAnimationFrameId) {
        this.windowService.getNative().cancelAnimationFrame(this.locationsAnimationFrameId);
      }

      return new Promise((resolve) => {
        this.locationsAnimationFrameId = this.windowService.getNative().requestAnimationFrame(() => {
          resolve(this.searchLocationsInCache(searchString, config)());
        });
      });
    }

    return this.autocompleteStore.searchLocations(searchString, config);
  }

  /**
   * @inheritDoc
   */
  public searchAgents(escapedSearch: string): void {
    if (this.autocompleteCacheAgents.getCache().length) {
      this.autocompleteStore.getEventEmitter().emit(AutocompleteStoreEvent.searchAgentsStart, escapedSearch);

      if (this.agentsAnimationFrameId) {
        this.windowService.getNative().cancelAnimationFrame(this.agentsAnimationFrameId);
      }

      this.agentsAnimationFrameId = this.windowService
        .getNative()
        .requestAnimationFrame(this.searchAgentsInCache(escapedSearch));
      return;
    }

    this.autocompleteStore.searchAgents(escapedSearch);
  }

  /**
   * @inheritDoc
   */
  public searchBrokers(escapedSearch: string): void {
    if (this.autocompleteCacheBrokers.getCache().length) {
      this.autocompleteStore.getEventEmitter().emit(AutocompleteStoreEvent.searchBrokersStart, escapedSearch);

      if (this.brokersAnimationFrameId) {
        this.windowService.getNative().cancelAnimationFrame(this.brokersAnimationFrameId);
      }

      this.brokersAnimationFrameId = this.windowService
        .getNative()
        .requestAnimationFrame(this.searchBrokersInCache(escapedSearch));

      return;
    }

    this.autocompleteStore.searchBrokers(escapedSearch);
  }

  /**
   * Search locations in cache
   */
  private searchLocationsInCache = (searchString: string, config?: AutocompleteStoreSearchConfigInterface) => () => {
    const locations = this.autocompleteCacheLocations.getDataStore().getAllByName({
      name: searchString,
      limit: config && config['page[limit]'] ? config['page[limit]'] : 5,
    });

    this.autocompleteStore.setLocations(locations);

    this.getEventEmitter().emit(AutocompleteStoreEvent.searchLocationsSuccess, locations);

    return locations;
  };

  /**
   * Search agents in cache
   */
  private searchAgentsInCache = (searchString: string) => () => {
    this.autocompleteStore.setAgents(
      this.autocompleteCacheAgents.getDataStore().getAllByName({
        name: searchString,
        limit: 8,
      })
    );

    this.getEventEmitter().emit(AutocompleteStoreEvent.searchAgentsSuccess, this.autocompleteStore.getState().agents);
  };

  /**
   * Search brokers in cache
   */
  private searchBrokersInCache = (searchString: string) => () => {
    this.autocompleteStore.setBrokers(
      this.autocompleteCacheBrokers.getDataStore().getAllByName({
        name: searchString,
        limit: 8,
      })
    );

    this.getEventEmitter().emit(AutocompleteStoreEvent.searchBrokersSuccess, this.autocompleteStore.getState().brokers);
  };
}
