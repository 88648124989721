import { UserFrontendLoginProvidersEnum } from 'common/data/user/frontend/login/providers.enum';
import { UserFrontendLoginStoreFactory } from 'common/data/user/frontend/login/store.factory';
import { UserFrontendLoginProviderFacebookFactory } from 'common/data/user/frontend/login-provider/facebook.factory';
import { UserFrontendLoginProviderGoogleFactory } from 'common/data/user/frontend/login-provider/google.factory';
import { UserFrontendLoginProviderGoogleOneTapFactory } from 'common/data/user/frontend/login-provider/google-one-tap.factory';
import { UserFrontendLoginServiceInterface } from 'common/service/user-frontend-login/service.interface';

const service = UserFrontendLoginStoreFactory();

service.registerProviders({
  [UserFrontendLoginProvidersEnum.google]: UserFrontendLoginProviderGoogleFactory(),
  [UserFrontendLoginProvidersEnum.facebook]: UserFrontendLoginProviderFacebookFactory(),
  [UserFrontendLoginProvidersEnum.googleOneTap]: UserFrontendLoginProviderGoogleOneTapFactory(),
});

export function UserFrontendLoginService(): UserFrontendLoginServiceInterface {
  return service;
}
