export const enum StatsTealiumDataLayerEventActionEnum {
  /**
   * User sign in
   */
  login = 'login',

  /**
   * User sign in
   */
  signIn = 'sign in',

  /**
   * User sign out
   */
  signOut = 'sign out',

  /**
   * User register
   */
  register = 'register',

  /**
   * Email newsletter
   */
  emailAlert = 'email alert',

  /**
   * find action
   */
  find = 'find',

  /**
   * Call
   */
  call = 'call',

  /**
   * Email
   */
  email = 'email',

  /**
   * Travel time
   */
  travelTime = 'travel Time',

  /**
   * App
   */
  app = 'app',

  /**
   * Mortgage Calculator
   */
  mortgageCalculator = 'mortgage calculator',

  /**
   * Saved Properties
   */
  savedProperties = 'saved properties',

  savePropertyNotificationModalOpen = 'save_property_notification_modal_open',

  savePropertyKeepPostedClick = 'save_property_keep_posted_click',

  savePropertyKeepPostedSkipped = 'save_property_keep_posted_skipped',

  savePropertyEmailSubmit = 'save_property_email_submit',

  /**
   * Saved property add
   */
  savedPropertyAdd = 'save_property_add',

  /**
   * Saved property remove
   */
  savedPropertyRemove = 'save_property_remove',

  /**
   * Saved properties remove all
   */
  savedPropertyRemoveAll = 'save_property_remove_all',

  /**
   * Whatsapp
   */
  whatsapp = 'whatsapp',

  /**
   * sms
   */
  sms = 'sms',

  /**
   * map - amenity selection
   */
  mapAmenitySelection = 'amenity_selected',

  /**
   * map - commute time search open
   */
  mapCommuteTimeSearchOpen = 'search_open',

  /**
   * search
   */
  search = 'search',

  /**
   * Sort option
   */
  sortByOptionClick = 'sort_by_option_click',

  /**
   * Search view
   */
  searchView = 'search_view',

  createOpen = 'create_open',

  saveSearchEmailModalOpen = 'save_search_email_modal_open',

  saveSearchEmailSubmit = 'save_search_email_submit',

  saveSearchAddEmailSkipped = 'save_search_add_email_skipped',

  saveSearchCreate = 'save_search_create',

  /**
   * List with us
   */
  listWithUs = 'click_list_with_us',

  /**
   * Something new everyday community card
   */
  clickCommunityCard = 'click_community_card',

  /**
   * Something new everyday all link
   */
  clickNewProperties = 'click_new_properties',

  /**
   * Something new everyday gallery scroll
   */
  clickScroll = 'click_scroll',

  /**
   * Read more
   */
  readMoreClick = 'read_more_click',

  properties_impression = 'properties_impression',

  /**
   * Open app card impression
   */
  appBannerIosImpression = 'app_banner_ios_impression',
  appBannerAndroidImpression = 'app_banner_android_impression',

  /**
   * Click on open app card
   */
  appBannerIosClick = 'app_banner_ios_click',
  appBannerAndroidClick = 'app_banner_android_click',

  dataGuruQuickLinksCtaClick = 'data_guru_quick_links_cta_click',

  dataGuruCardClick = 'data_guru_card_click',

  insightsHubCtaClick = 'insights_hub_clicked',

  dataGuruSearchBarCtaClick = 'data_guru_search_bar_cta_click',

  knowYourRightsLinkClick = 'know_your_rights_clicked',

  galleryTowerTabClick = 'gallery_tower_tab_click',
  galleryCommunityTabClick = 'gallery_community_tab_click',
  galleryFloorPlanTabClick = 'gallery_floor_plan_tab_click',
  galleryTowerTabTimeTrack = 'gallery_tower_tab_time_track',
  galleryCommunityTabTimeTrack = 'gallery_community_tab_time_track',
  galleryFloorPlanTabTimeTrack = 'gallery_floor_plan_time_track',

  appSelectionBannerImpression = 'app_selection_banner_impression',
  appSelectionBannerClosed = 'app_selection_banner_closed',
  appSelectionSmartButtonClicked = 'open_app_smart_button_clicked',
}
