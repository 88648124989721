import { Component, h } from 'preact';

import { functionNoop } from 'common/helper/function/noop';

import { MultipleSelectionOptionInterface } from './option.interface';
import { MultipleSelectionPropsInterface } from './props.interface';
import { MultipleSelectionStateInterface } from './state.interface';
import { MultipleSelectionTemplate } from './template';

export class MultipleSelectionComponent extends Component<
  MultipleSelectionPropsInterface,
  MultipleSelectionStateInterface
> {
  public static readonly DEFAULT_NO_OF_COLUMNS: number = 3;

  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    columns: MultipleSelectionComponent.DEFAULT_NO_OF_COLUMNS,
    onChange: functionNoop,
  };

  constructor(props: MultipleSelectionPropsInterface) {
    super(props);

    this.state = {
      onOptionClick: this.onOptionClick,
    };
  }

  /**
   * @param index index of option pressed
   * @description handles click on checkbox and returns updated list of options
   */
  public onOptionClick = (index: number) => {
    const option: MultipleSelectionOptionInterface = {
      ...this.props.options[index],
      checked: !this.props.options[index].checked,
    };
    const list = [...this.props.options];
    list[index] = option;

    this.props.onChange(list, option);
  };

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return <MultipleSelectionTemplate {...this.state} {...this.props} />;
  }
}
