import { ComponentFactory, FunctionalComponent, h } from 'preact';

import { ButtonTemplate } from 'common/module/button/template';
import { ButtonTemplatePropsInterface } from 'common/module/button/template-props.interface';

/**
 * Button component
 *
 * TODO-FE[WEB-4817] - implement button behavior logic
 */
export const ButtonComponent: FunctionalComponent<
  ButtonTemplatePropsInterface & {
    /**
     * Optional template
     */
    template?: ComponentFactory<ButtonTemplatePropsInterface>;
  }
> = (props) => {
  const { template: Template = ButtonTemplate } = props;

  return <Template {...props} />;
};
