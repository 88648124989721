import { cookieAuthenticatorGetSaltIndex } from './get-salt-index';

// this magic with class is required to avoid webpack inline the secret into the places where it is used
// making it more difficult to reverse engineer cookie sign algorithm
// tslint:disable-next-line: pf-file
class A {
  public static e(salt: string, hash: string): string {
    return `${hash.substr(0, cookieAuthenticatorGetSaltIndex())}${salt}${hash.substr(
      cookieAuthenticatorGetSaltIndex()
    )}`;
  }
}

/**
 * Mix hash and salt into a single string (salt is hiding in the middle)
 */
export const cookieAuthenticatorMixSaltAndHash = function e(salt: string, hash: string): string {
  return A.e(salt, hash);
};
