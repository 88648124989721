import { chooseAOrB } from 'common/helper/choose/a-or-b';

/**
 * Transform html attributes to preact props
 */
export function preactHtmlAttributesToProps(attrs: NamedNodeMap): {
  [key: string]: string | boolean;
} {
  const length = attrs && attrs.length;

  if (!length) {
    return null;
  }

  const props: {
    [key: string]: string | boolean;
  } = {};

  for (let i = 0; i < length; i++) {
    const { name, value } = attrs[i];

    props[name] = chooseAOrB(value, true)(value !== '');
  }

  return props;
}
