import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { LeadModelInterface } from 'common/data/lead/model.interface';

export class PropertyLeadModel extends JsonApiModel implements LeadModelInterface {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'property_lead';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = PropertyLeadModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof PropertyLeadModel> = ['name', 'message', 'email', 'phone', 'captcha_token'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof PropertyLeadModel> = [];

  /**
   * Lead author name
   */
  public name: string = '';

  /**
   * Message
   */
  public message: string = '';

  /**
   * Email
   */
  public email: string = '';

  /**
   * Phone
   */
  public phone: string = '';

  /**
   * Create an email alert ?
   */
  public createAlert: boolean = false;

  /**
   * Accept terms and conditions
   */
  public acceptTerms: boolean = false;

  /**
   * Accept receive advertising
   */
  public receiveAdvertising: boolean = false;

  // tslint:disable:variable-name

  /**
   * Captcha verification token
   */
  public captcha_token?: string;

  // tslint:enable:variable-name
}
