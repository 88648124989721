import { StatsPlatformEnum } from 'pf-frontend-common/src/module/stats/platform.enum';
import { StatsDebuggerServiceInterface } from 'pf-frontend-common/src/service/stats-debugger/service.interface';
import { StatsGuardServiceInterface } from 'pf-frontend-common/src/service/stats-guard/service.interface';

import { StatsSnowplow2ActionEnum } from 'common/module/stats/snowplow2/action.enum';
import { StatsSnowplow2ActionInterface } from 'common/module/stats/snowplow2/action.interface';
import { StatsSnowplow2ActionTrackSelfDescribingEventInterface } from 'common/module/stats/snowplow2/action/track-self-describing-event.interface';
import { StatsSnowplow2ActionTrackStructEventInterface } from 'common/module/stats/snowplow2/action/track-struct-event.interface';
import { StatsSnowplow2ProviderInterface } from 'common/module/stats/snowplow2/provider.interface';
import { StatsSnowplow2 } from 'common/module/stats/snowplow2/types';

import { StatsSnowplow2ActionPageViewInterface } from './action/page-view.interface';
import { StatsSnowplow2ActionSetUserIdInterface } from './action/set-user-id.interface';

export class StatsSnowplow2Provider implements StatsSnowplow2ProviderInterface {
  /**
   * Constructor
   */
  constructor(
    private snowplow: StatsSnowplow2,
    private statsDebugger: StatsDebuggerServiceInterface,
    private statsGuardService: StatsGuardServiceInterface
  ) {}

  /**
   * @inheritdoc
   */
  public send(data: StatsSnowplow2ActionInterface): void {
    switch (data.action) {
      case StatsSnowplow2ActionEnum.trackSelfDescribingEvent:
        this.trackSelfDescribingEvent(<StatsSnowplow2ActionTrackSelfDescribingEventInterface>data);
        break;
      case StatsSnowplow2ActionEnum.trackStructEvent:
        this.trackStructEvent(<StatsSnowplow2ActionTrackStructEventInterface>data);
        break;
      case StatsSnowplow2ActionEnum.flushBuffer:
        this.snowplow(data.action);
        break;
      case StatsSnowplow2ActionEnum.setUserId:
        this.setUserId(<StatsSnowplow2ActionSetUserIdInterface>data);
        break;
      case StatsSnowplow2ActionEnum.trackPageView:
        this.trackPageView(<StatsSnowplow2ActionPageViewInterface>data);
        break;
    }

    this.statsDebugger.log(StatsPlatformEnum.sp2, data.action, data.options);
  }

  /**
   * Track self describing event
   */
  private trackSelfDescribingEvent(data: StatsSnowplow2ActionTrackSelfDescribingEventInterface): void {
    // Guard
    if (this.statsGuardService.isAuthorized() === false) {
      return;
    }

    this.snowplow(data.action, { event: data.options.event, schema: data.options.context });
  }

  /**
   * Track structurized event
   */
  private trackStructEvent(data: StatsSnowplow2ActionTrackStructEventInterface): void {
    // Guard
    if (this.statsGuardService.isAuthorized() === false) {
      return;
    }

    this.snowplow(data.action, {
      category: data.options.category,
      action: data.options.action,
      label: data.options.label,
      property: data.options.property,
      value: data.options.value,
    });
  }

  /**
   * Set user id
   */
  private setUserId(data: StatsSnowplow2ActionSetUserIdInterface): void {
    this.snowplow(data.action, data.options.userId);
  }

  /**
   * Track page view
   */
  private trackPageView(data: StatsSnowplow2ActionPageViewInterface): void {
    // Guard
    if (this.statsGuardService.isAuthorized() === false) {
      return;
    }

    // Send data
    this.snowplow(data.action);
    this.setUserId({
      ...data,
      action: StatsSnowplow2ActionEnum.setUserId,
    });
  }
}
