import { Link } from '@propertyfinder/link';
import { ScrollableAreaComponent } from '@propertyfinder/scrollable-area';
import { Fragment, FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';

import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { NavigationArrowsComponent } from 'common/module/navigation-arrows/component';
import { NewEverydayCategory } from 'common/module/new-everyday/enum';
import { ProgressiveImageComponent } from 'common/module/progressive-image/component';

import { NewEverydayTemplatePropsInterface } from './template-props.interface';

export const NewEverydayTemplate: FunctionalComponent<NewEverydayTemplatePropsInterface> = ({
  categories,
  tracker,
}) => {
  const [currentGaleryIndex, setCurrentGaleryIndex] = useState({
    [NewEverydayCategory.RENT]: 0,
    [NewEverydayCategory.SALE]: 0,
  });
  const languageRtl = configGetLanguage().isRtl;

  const updateIndex = (index: number, type: NewEverydayCategory) => {
    tracker.onGalleryScroll(type);
    setCurrentGaleryIndex({ ...currentGaleryIndex, [type]: index });
  };
  return (
    <Fragment>
      {categories.map((category) => (
        <div>
          <div className='home-page-layout__section-subtitle'>
            <div className='new-everyday-card__sub-header'>
              {i18nTranslate('new-properties')}&nbsp;
              <Link
                className='new-everyday-card__sub-header-link'
                href={category.allPath}
                onClick={() => tracker.onLinkClick(category.category)}
              >
                {i18nTranslate(`for ${category.category}`)}{' '}
              </Link>
            </div>
            <NavigationArrowsComponent
              isRtl={languageRtl}
              currentGaleryIndex={currentGaleryIndex[category.category as keyof typeof currentGaleryIndex]}
              numberOfItems={category.data.filter((card) => card.value !== 0).length}
              nextRef={category.nextRef}
              prevRef={category.prevRef}
            />
          </div>
          <div className='new-everyday-card__gallery-wrapper'>
            <ScrollableAreaComponent
              itemClassName='home-page-layout__scrollable-card'
              rtl={languageRtl}
              bindNextItem={(nextItem) => {
                category.nextRef.current = nextItem;
              }}
              bindPrevItem={(prevItem) => {
                category.prevRef.current = prevItem;
              }}
              onChange={(e) => updateIndex(e, category.category)}
            >
              {category.data
                .filter((card) => card.value !== 0)
                .map((card, index) => (
                  <Link
                    className='new-everyday-card'
                    href={card.link}
                    onClick={() => tracker.onCommunityCardClick(card.neighborhood, index + 1)}
                  >
                    <div className='new-everyday-card__image-container'>
                      <ProgressiveImageComponent
                        src={card.src.webp}
                        alt={card.neighborhood}
                        placeholder={card.src.jpeg}
                        classname='new-everyday-card__image'
                      />
                      <div className='new-everyday-card__image-overlay' />
                      <div className='new-everyday-card__image-text'>
                        <div className='new-everyday-card__city'>{i18nTranslate(card.city)}</div>
                        <div className='new-everyday-card__neighborhood'>{i18nTranslate(card.neighborhood)}</div>
                      </div>
                    </div>
                    <div className='new-everyday-card__footer'>
                      {card.value} {i18nTranslate(`new-properties-for-${category.category}`)}
                    </div>
                  </Link>
                ))}
            </ScrollableAreaComponent>
          </div>
        </div>
      ))}
    </Fragment>
  );
};
