import { StatsDataUserInterface } from 'pf-frontend-common/src/module/stats/data/user.interface';

import { UserModel } from 'common/data/user/model';

export class UserStatsDataAdapter {
  /**
   * Return a user model as user stats data
   */
  public getData(userModel: UserModel): StatsDataUserInterface {
    return {
      id: parseInt(userModel.id, 10),
      firstName: userModel.first_name,
      lastName: userModel.last_name,
      email: userModel.email,
    };
  }
}
