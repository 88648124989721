import { BackendConfigKeysInterface } from 'common/data/backend/config/keys.interface';

/**
 * Returns third party configuration keys
 */
export function configGetKeys(): BackendConfigKeysInterface {
  // Default keys
  const keys: BackendConfigKeysInterface = {
    recaptcha: '',
    recaptchaAuthenticator: '',
    googleMap: '',
    googleTagAccount: '',
    mapboxMap: '',
    dataDog: '',
    googleAuth: '',
    facebookAppId: '',
  };

  return (window.propertyfinder && window.propertyfinder.keys) || keys;
}
