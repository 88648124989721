export const enum UserFrontendLoginProviderTypeEnum {
  /**
   * Should be manually triggered
   */
  manual,

  /**
   * Will happen on auth service init
   */
  onInit,
}
