import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class MyNotesModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'my_note';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = MyNotesModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof MyNotesModel> = ['property_id', 'description', 'modified_date'];

  // tslint:disable:variable-name

  /**
   * Property id
   */
  public property_id: number;

  /**
   * Note description
   */

  public description: string;

  /**
   * Note date
   */
  public modified_date: string;

  // tslint:enable:variable-name
}
