import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { ApiService } from 'pf-frontend-common/src/service/api/service';
import { BrowserStorageLocalService } from 'pf-frontend-common/src/service/browser-storage-local/service';

import { JwtTokenModel } from 'common/data/jwt/token/model';
import { JwtTokenStore } from 'common/data/jwt/token/store';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { JwtTokenServiceInterface } from 'common/service/jwt-token/service.interface';

const service = new JwtTokenStore(
  EventEmitterFactory(),
  ApiService(),
  ApiEndpointService(),
  BrowserStorageLocalService(),
  JsonApiStoreFactory<JwtTokenModel>('jwt_token')
);

// TODO-FE[WEB-1639]: add refresh token here when more secure refresh logic will be implemented

export function JwtTokenService(): JwtTokenServiceInterface {
  return service;
}
