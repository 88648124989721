import { arrayAreEqual } from 'common/helper/array/are-equal';
import { objectCompare } from 'common/helper/object/compare';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';

/**
 * Compare filter parameters for equality.
 * Will return true if both are equal.
 */
export function filterParamsEquality(
  srcParams: FilterParametersInterface,
  destParams: FilterParametersInterface
): boolean {
  const params1Keys = Object.keys(srcParams) as Array<keyof FilterParametersInterface>;
  const params2Keys = Object.keys(destParams) as Array<keyof FilterParametersInterface>;

  if (!arrayAreEqual(params1Keys, params2Keys)) {
    return false;
  }

  for (let index = params1Keys.length - 1; index >= 0; index--) {
    const value1 = srcParams[params1Keys[index]];
    const value2 = destParams[params1Keys[index]];

    if (value1 instanceof Object && value2 instanceof Object && !objectCompare(value1, value2)) {
      return false;
    }

    if ((typeof value1 === 'number' || typeof value1 === 'boolean') && value1 !== value2) {
      return false;
    }
  }

  return true;
}
