import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { FilterSettingsModel } from 'common/data/filter-settings/model';
import { FilterSettingsStore } from 'common/data/filter-settings/store';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';

export function FilterSettingsStoreFactory(): FilterSettingsStore {
  return new FilterSettingsStore(
    ApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<FilterSettingsModel>(FilterSettingsModel.JSONAPI_TYPE)
  );
}
