import { FunctionalComponent, h } from 'preact';

import { ChipPropsInterface } from './props.interface';

export const ChipTemplate: FunctionalComponent<ChipPropsInterface> = (props) => {
  return (
    <label
      htmlFor={props.htmlFor}
      className={`text text--size2 text--capitalize chip
     ${props.className ? props.className : ''}
     ${props.isDisabled ? 'chip--disabled' : ''}
     ${!props.isDisabled && props.isSelected ? 'chip--selected' : ''}
   `}
      onClick={props.onClick}
    >
      {props.prefixIcon && <span className='chip__prefix text--right'>{props.prefixIcon}</span>}
      {<span className={props.labelClassName}>{props.label}</span>}
      {props.suffixIcon && <span className='chip__suffix'>{props.suffixIcon}</span>}
    </label>
  );
};
