import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { CommunityGuideModel } from 'common/data/community-guide/model';
import { MapCoordinatesInterface } from 'common/module/map/coordinates.interface';

export class LocationModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'location';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = LocationModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof LocationModel> = [
    'id',
    'name',
    'coordinates',
    'abbreviation',
    'location_type',
    'review_score',
    'reviews_count',
    'image_token',
    'path_name',
    'url_slug',
    'path',
    'children_count',
    'level',
    'current_language_slug',
    'images',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof LocationModel> = ['community_guide'];

  /**
   * @inheritDoc
   */
  public jsonApiLinks: string[] = ['building_reviews'];

  /**
   * Additional links
   */
  public links: {
    /**
     * Profile URL
     */
    building_reviews?: string;

    /**
     * Location image
     */
    image_location?: string;

    /**
     * Tower insights link
     */
    tower_insights_url?: string;

    /**
     * Community insights link
     */
    community_insights_url?: string;
  };

  // tslint:disable:variable-name

  /**
   * Location ID
   */
  public id: string;

  /**
   * Location name
   */
  public name: string;

  /**
   * Abbreviation
   */
  public abbreviation: string;

  /**
   * Location slug, ex: jumeirah-village-circle-bloom-heights
   */
  public current_language_slug: string;

  /**
   * Location path name, ex: Abu Dhabi, Khalifa City
   */
  public path_name: string;

  /**
   * URL slug, ex: dubai-marina-princess-tower
   */
  public url_slug: string;

  /**
   * Location path, ex: 1.50.280
   */
  public path: string;

  /**
   * Location number of children
   */
  public children_count: number;

  /**
   * Level within the location tree, starts at 0
   */
  public level: number;

  /**
   * Location coordinates
   */
  public coordinates: MapCoordinatesInterface = {
    lat: 0,
    lon: 0,
  };

  /**
   * Location type, ex: SUBCOMMUNITY
   */
  public location_type: string;

  /**
   * Location review score
   */
  public review_score: string;

  /**
   * Location reviews count
   */
  public reviews_count: string;

  /**
   * Location image token (this is a hash, not an image URL!)
   */
  public image_token: string;

  /**
   * Community guide relation
   */
  public community_guide: CommunityGuideModel;

  /**
   * Is tower insights card visible flag
   */
  public is_tower_insights_visible: boolean;

  /**
   * Location Images
   */
  public images: Array<{ full_url: string; thumb_url: string }>;

  // tslint:enable:variable-name
}
