import { GtmStatsAdapterListingStatus } from 'pf-frontend-common/src/module/gtm/stats/adapter/listing-status';
import { StatsDataPropertyInterface } from 'pf-frontend-common/src/module/stats/data/property.interface';

export const PropertyListStatsGtmGalleryPropertyScroll = (pageType: string, statsData: StatsDataPropertyInterface) => {
  const listingStatus = new GtmStatsAdapterListingStatus(statsData.listingStatusDisplayed).getData();
  return {
    event: 'customEvent',
    eventCategory: 'Gallery Scroll',
    eventAction: `Gallery - Scroll - ${listingStatus}`,
    eventLabel: `${pageType} - Gallery - Scroll - ${listingStatus} - ${statsData.id}`,
  };
};
