import { BrowserStorageLocalServiceInterface } from 'pf-frontend-common/src/service/browser-storage-local/service.interface';

import { configGetLanguage } from 'common/helper/config/get-language';
import { FilterServerInterface } from 'common/module/filter/server.interface';
import { FilterCacheServiceInterface } from 'common/service/filter-cache/service.interface';

export class FilterCacheStore implements FilterCacheServiceInterface {
  /**
   * Cache key
   */
  public static readonly cacheKey: string = 'property-search-form-cache-v2';

  /**
   * Constructor
   */
  constructor(private browserStorageLocalService: BrowserStorageLocalServiceInterface) {}

  /**
   * @inheritDoc
   */
  public setCache(formCache: FilterServerInterface): void {
    this.browserStorageLocalService.setData(this.getCacheKey(), formCache);
  }

  /**
   * @inheritDoc
   */
  public getCache(): FilterServerInterface {
    return <FilterServerInterface>this.browserStorageLocalService.getData(this.getCacheKey());
  }

  /**
   * Generate cache key
   */
  private getCacheKey(): string {
    return configGetLanguage().current + '_' + FilterCacheStore.cacheKey;
  }
}
