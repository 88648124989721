import { CountryAreaUnitEnum } from 'common/data/country/area-unit.enum';
import { CountryCodeEnum } from 'common/data/country/code.enum';
import { configGetCountry } from 'common/helper/config/get-country';

// TODO-BE[TPNX-887] - move it to initial payload
export function countryGetAreaUnit(code: CountryCodeEnum = configGetCountry().code): CountryAreaUnitEnum {
  switch (code) {
    case CountryCodeEnum.bh:
      return CountryAreaUnitEnum.sqm;
    case CountryCodeEnum.qa:
      return CountryAreaUnitEnum.sqm;
    case CountryCodeEnum.ae:
      return CountryAreaUnitEnum.sqft;
    case CountryCodeEnum.eg:
      return CountryAreaUnitEnum.sqm;
    case CountryCodeEnum.lb:
      return CountryAreaUnitEnum.sqm;
    case CountryCodeEnum.sa:
      return CountryAreaUnitEnum.sqm;
    case CountryCodeEnum.ma:
      return CountryAreaUnitEnum.m2;
  }
}
