import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { useWindowMouseDown } from 'common/helper/use/window-mouse-down';
import { KeyboardKeyEnum } from 'common/module/keyboard/key.enum';

import { MultiSelectionAutocompleteComponentPropsInterface } from './component-props.interface';
import { MultiSelectionAutocompleteExtensionInterface } from './extension.interface';
import { multiSelectionAutocompleteMakeKeyboardAccessibilityHandler } from './make-keyboard-accessibility-handler';
import { MultiSelectionAutocompleteTemplate } from './template';

// TODO-FE[TPNX-1968] Test MultiSelectionAutocompleteComponent

export const MultiSelectionAutocompleteComponent = <T extends unknown>(
  props: MultiSelectionAutocompleteComponentPropsInterface<T>
) => {
  /* Refs */
  const rootRef = useRef(null);
  // It is used to keep focus inside the component while navigating up and down with arrows
  const hiddenInputRef = useRef(null);
  const inputRef = useRef(null);

  /* State */
  const [isActive, setIsActive] = useState(false);
  const [suggestions, setSuggestions] = useState<T[]>([]);
  const [inputValue, setInputValueInternal] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState<number>(null);

  const setInputValue = (value: string) => {
    setInputValueInternal(value);
    if (props.onInputValueChange) {
      props.onInputValueChange(value);
    }
  };

  /* Helpers */
  const addInputFocus = () => inputRef.current.select();

  /**
   * Removes the focus from input and keeps the hidden input focused,
   * so the keydown event can be received by the root component
   */
  const removeInputFocus = () => {
    hiddenInputRef.current?.focus();
    inputRef.current?.blur();
  };

  /**
   * Deactivate the component
   */
  const deactivate = () => {
    setIsActive(false);
    setInputValue('');
    setSelectedItemIndex(null);
    removeInputFocus();
    setSuggestions([]);
  };

  /**
   * Handle suggestions promise
   * @param promise will eventually return suggestions
   */
  const handleSuggestionsResult = (promise: Promise<T[]>) => {
    setIsLoading(true);

    promise
      .then(setSuggestions)
      .catch((e) => {
        console.error(`MultiSelectionAutocompleteComponent.handleSuggestionsResult promise error`, e);
      })
      .then(() => setIsLoading(false));
  };

  /**
   * Add item and deactivate
   * @param item
   */
  const addItemAndDeactivate = (item: T) => {
    deactivate();
    props.onAddItem(item);
  };

  /**
   * Handle input value change
   */
  const handleInputChange = (value: string) => {
    setInputValue(value);
    handleSuggestionsResult(props.onInputChange(value));
  };

  /**
   * On input keydown
   * @param e KeyboardEvent
   */
  const onInputKeyDown = (e: KeyboardEvent) => {
    // deactivate widget if user pressed tab during input is in focus
    if (e.key === KeyboardKeyEnum.tab) {
      deactivate();
    }
  };

  /**
   * On suggestion click
   * @param item
   */
  const suggestionOnClick = (item: T) => {
    deactivate();
    props.onAddItem(item);
  };

  const extensionInterfaceProps: MultiSelectionAutocompleteExtensionInterface<T> = {
    addItem: props.onAddItem,
    deactivate,
    clearInput: () => handleInputChange(''),
    inputValue,
  };

  /* Effects */
  // deactivate on windows mouse down outside of component
  useWindowMouseDown({
    shouldListen: isActive,
    ignoreElementRef: rootRef,
    onWindowMouseDown: (e) => {
      if (props.onWindowMouseDownOutsideAutocomplete) {
        props.onWindowMouseDownOutsideAutocomplete({ e, ...extensionInterfaceProps });
      }
      deactivate();
    },
  });

  // focus input when component becomes active
  useEffect(() => isActive && addInputFocus(), [isActive]);

  return (
    <MultiSelectionAutocompleteTemplate
      chipsOnTheBottom={props.chipsOnTheBottom}
      className={props.className}
      chipClassName={props.chipClassName}
      value={props.value}
      placeholder={props.placeholder}
      onItemCrossClick={(item, isMore) => (isMore ? props.onItemGroupRemoveClick() : props.onItemRemove(item))}
      getTitle={props.getTitle}
      getChipTitle={props.getChipTitle}
      renderNoSuggestions={props.renderNoSuggestions}
      suggestionIcon={props.suggestionIcon}
      isLoading={isLoading}
      isActive={isActive}
      inputRef={inputRef}
      inputValue={inputValue}
      onInputChange={() => handleInputChange(inputRef.current.value)}
      onInputKeyDown={onInputKeyDown}
      onInputCrossButtonClick={deactivate}
      suggestions={suggestions}
      selectedItemIndex={selectedItemIndex}
      suggestionOnClick={suggestionOnClick}
      rootRef={rootRef}
      hiddenInputRef={hiddenInputRef}
      onInputFocus={() => {
        setSelectedItemIndex(null);
        handleSuggestionsResult(props.onFocus(inputValue));
      }}
      onInputKeyUp={(e): void => {
        props.onInputKeyUp?.(e, suggestions);
      }}
      onInputKeyPress={(e: KeyboardEvent) =>
        props.onInputKeyPress && props.onInputKeyPress({ e, ...extensionInterfaceProps })
      }
      onRootClick={isActive ? undefined : () => setIsActive(true)}
      onRootKeyDown={multiSelectionAutocompleteMakeKeyboardAccessibilityHandler({
        selectedItemIndex,
        suggestions,
        removeInputFocus,
        addInputFocus,
        deactivate,
        setSelectedItemIndex,
        addItemAndDeactivate,
      })}
    />
  );
};
