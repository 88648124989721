import { DefaultContext, EventObject, MachineConfig, StateSchema } from 'xstate/es';

import { MyAccountContactedPropertiesStatechart } from 'common/module/my-account/contacted-properties/statechart';
import { MyAccountNotesPropertiesStatechart } from 'common/module/my-account/notes/statechart';
import { MyAccountSavedPropertiesStatechart } from 'common/module/my-account/saved-properties/statechart';
import { MyAccountStatechartEvent } from 'common/module/my-account/statechart.event';
import { MyAccountStatechartActionEnum } from 'common/module/my-account/statechart/action.enum';
import { MyAccountStatechartStateEnum } from 'common/module/my-account/statechart/state.enum';

export const MyAccountStatechart: MachineConfig<DefaultContext, StateSchema, EventObject> = {
  initial: MyAccountStatechartStateEnum.iddle,
  states: {
    [MyAccountStatechartStateEnum.iddle]: {
      on: {
        [MyAccountStatechartEvent.filter]: MyAccountStatechartStateEnum.filtering,
        [MyAccountStatechartEvent.unsave]: MyAccountStatechartStateEnum.unsaving,
        [MyAccountStatechartEvent.sort]: MyAccountStatechartStateEnum.sorting,
        [MyAccountStatechartEvent.delete]: MyAccountStatechartStateEnum.deleting,
        [MyAccountStatechartEvent.deleteNote]: MyAccountStatechartStateEnum.deletingNote,
        [MyAccountStatechartEvent.deleteAll]: MyAccountStatechartStateEnum.showingConfirmationPopup,
        [MyAccountStatechartEvent.clearAll]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.clearAll],
        },
        [MyAccountStatechartEvent.fetchPropertiesSuccess]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.checkIfRefreshListIsNeeded],
        },
        [MyAccountStatechartEvent.fetchSavedProperties]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.disableList],
        },
        [MyAccountStatechartEvent.fetchContactedProperties]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.disableList],
        },
        [MyAccountStatechartEvent.fetchNotesProperties]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.disableList],
        },
        [MyAccountStatechartEvent.refreshList]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.updateListProperties, MyAccountStatechartActionEnum.enableList],
        },
      },
      type: 'parallel',
      states: {
        [MyAccountStatechartStateEnum.fetchingSavedProperties]: MyAccountSavedPropertiesStatechart,
        [MyAccountStatechartStateEnum.fetchingContactedProperties]: MyAccountContactedPropertiesStatechart,
        [MyAccountStatechartStateEnum.fetchingNotes]: MyAccountNotesPropertiesStatechart,
      },
    },
    [MyAccountStatechartStateEnum.filtering]: {
      onEntry: [MyAccountStatechartActionEnum.filter],
      on: {
        [MyAccountStatechartEvent.filterSuccess]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.updateListProperties],
        },
      },
    },
    [MyAccountStatechartStateEnum.sorting]: {
      onEntry: [MyAccountStatechartActionEnum.sort],
      on: {
        [MyAccountStatechartEvent.sortSuccess]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.updateListProperties],
        },
      },
    },
    [MyAccountStatechartStateEnum.showingConfirmationPopup]: {
      onEntry: [MyAccountStatechartActionEnum.showConfirmationPopup],
      on: {
        [MyAccountStatechartEvent.rejectDeleting]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.hideConfirmationPopup],
        },
        [MyAccountStatechartEvent.approveDeleting]: {
          target: MyAccountStatechartStateEnum.deletingAll,
          actions: [MyAccountStatechartActionEnum.hideConfirmationPopup],
        },
      },
    },
    [MyAccountStatechartStateEnum.deleting]: {
      onEntry: [
        MyAccountStatechartActionEnum.deleteIndexDBData,
        MyAccountStatechartActionEnum.deleteApiData,
        MyAccountStatechartActionEnum.deleteViewData,
      ],
      on: {
        [MyAccountStatechartEvent.deleteSuccess]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.updateListProperties],
        },
      },
    },
    [MyAccountStatechartStateEnum.deletingNote]: {
      onEntry: [
        MyAccountStatechartActionEnum.deleteNoteFromBackend,
        MyAccountStatechartActionEnum.deleteNoteFromStore,
        MyAccountStatechartActionEnum.deleteNoteViewData,
      ],
      on: {
        [MyAccountStatechartEvent.deleteSuccess]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.updateListProperties],
        },
      },
    },
    [MyAccountStatechartStateEnum.unsaving]: {
      onEntry: [MyAccountStatechartActionEnum.deleteViewData],
      on: {
        [MyAccountStatechartEvent.deleteSuccess]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.updateListProperties],
        },
      },
    },
    [MyAccountStatechartStateEnum.deletingAll]: {
      onEntry: [
        MyAccountStatechartActionEnum.disableList,
        MyAccountStatechartActionEnum.deleteAllIndexDBData,
        MyAccountStatechartActionEnum.deleteAllApiData,
        MyAccountStatechartActionEnum.deleteAllViewData,
      ],
      on: {
        [MyAccountStatechartEvent.deleteAllSuccess]: {
          target: MyAccountStatechartStateEnum.iddle,
          actions: [MyAccountStatechartActionEnum.updateListProperties],
        },
        [MyAccountStatechartEvent.fetchNotesSuccess]: {
          target:
            MyAccountStatechartStateEnum.iddle +
            '.' +
            MyAccountStatechartStateEnum.fetchingNotes +
            '.' +
            MyAccountStatechartStateEnum.fetchingNotesProperties,
          actions: [MyAccountStatechartActionEnum.updateNotesInStore],
        },
      },
    },
  },
};
