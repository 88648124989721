/**
 * TODO-FE[later] Harmonize the event names, start with a verb, remove passive form, and use opposite meaning
 *
 * Failed => Failure
 * Succeeded => Success
 *
 * registration => register
 * askPasswordReset => resetPassword
 * logOut => signOut
 *
 * Don't forget to update callback names AND comments
 */
export const enum UserAuthenticationEvent {
  emailOtpIsResent = 'emailOtpIsResent',
  apiCallingFailed = 'apiCallingFailed',
  fieldsValidationFailed = 'fieldsValidationFailed',
  registrationStart = 'registration-start',
  registrationFailed = 'registration-failed',
  registrationSucceeded = 'registration-succeeded',
  registrationValidationFailed = 'registration-validation-failed',
  alreadyRegistered = 'already-registered',
  autoRegistrationFailed = 'auto-registration-failed',
  signInStart = 'sign-in-start',
  signInSucceeded = 'sign-in-succeeded',
  signInFailed = 'sign-in-failed',
  signInStopped = 'sign-in-stopped',
  signInValidationFailed = 'sign-in-validation-failed',
  userExistsSucceeded = 'user-exists-succeeded',
  userExistsFailed = 'user-exists-failed',
  userExistsValidationFailed = 'user-exists-validation-failed',
  loginFailed = 'login-failed',
  loginValidationFailed = 'login-validation-failed',
  otpIsSent = 'otp-is-sent',
  phoneNumberLoginSucceeded = 'phone-number-login-succeeded',
  phoneNumberLoginFailed = 'phone-number-login-failed',
  phoneNumberLoginValidationFailed = 'phone-number-login-validation-failed',
  registrationV2Failed = 'registration-v2-failed',
  registrationValidationV2Failed = 'registration-validation-v2-failed',
  forgotPasswordFailed = 'forgot-password-failed',
  forgotPasswordValidationFailed = 'forgot-password-validation-failed',
  askPasswordResetStart = 'ask-password-reset-start',
  askPasswordResetSucceeded = 'ask-password-reset-succeeded',
  askPasswordResetFailed = 'ask-password-reset-failed',
  askPasswordResetValidationFailed = 'ask-password-reset-validation-failed',
  logOutSucceeded = 'log-out-succeeded',
  changePasswordStart = 'change-password-start',
  changePasswordFailed = 'change-password-failed',
  changePasswordValidationFailed = 'change-password-validation-failed',
  changePasswordSucceeded = 'change-password-succeeded',
  userDataCleared = 'user-data-cleared',
  searchIsFetched = 'saved searches are fetched',
  updateUserSucceeded = 'update-user-succeeded',
  updateUserFailed = 'update-user-failed',
  updateUserValidationFailed = 'update-user-validation-failed',
  countriesData = 'countries-data',
  captchaTokenIsRequired = 'captcha-token-is-required',
}
