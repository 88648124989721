import { cloneElement, Component, h, toChildArray, VNode } from 'preact';

import { Input2ComponentPropsInterface } from 'common/module/input2/component-props.interface';
import { Input2ComponentStateInterface } from 'common/module/input2/component-state.interface';
import { Loader4Template } from 'common/module/loader/4/template';

export class Input2Component extends Component<Input2ComponentPropsInterface, Input2ComponentStateInterface> {
  /**
   * Constructor
   */
  constructor() {
    super();

    this.state = {
      isFocused: false,
    };
  }

  /**
   * @inheritdoc
   */
  public render(): preact.JSX.Element {
    const props = this.props;
    const input = (toChildArray(props.children) as [VNode])[0];

    return (
      <div
        className={`
          input2
          ${this.state.isFocused ? 'input2--focused' : ''}
          ${this.props.isError ? 'input2--error' : ''}
          ${this.props.isDisabled ? 'input2--disabled' : ''}
          ${props.className ? props.className : ''}
        `}
        onClick={props.onClick}
      >
        {!!props.label && (
          <div
            className={`
              input2__label
              ${this.state.isFocused || this.props.value ? 'input2__label--focused' : ''}
              ${this.props.isError ? 'input2__label--error' : ''}
            `}
          >
            {props.label}
          </div>
        )}
        <div className={`input2__input-container ${props.containerClassName || ''}`}>
          {props.prefix &&
            cloneElement(props.prefix, {
              isFocused: this.state.isFocused,
              isError: this.props.isError,
              onFocus: this.onFocus,
              onBlur: (e: Event) => {
                if (props.prefix.props.onBlur) {
                  props.prefix.props.onBlur.call(this, e);
                }
              },
            })}
          {input &&
            cloneElement(input, {
              onFocus: this.onFocus,
              onBlur: this.onBlur,
            })}
          {(this.state.isFocused || props.value) && this.props.suffix && !this.props.isLoading && (
            <div className={`input2__input-suffix ${props.suffixCssClass ? props.suffixCssClass : ''}`}>
              {props.suffix}
            </div>
          )}
          {this.props.isLoading && <Loader4Template cssClass='input2__input-loader' />}
        </div>
      </div>
    );
  }

  /**
   * Handle onfocus event
   */
  private onFocus = (e: Event) => {
    this.setState({
      isFocused: true,
    });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  };

  /**
   * Handle onblur event
   */
  private onBlur = (e: Event) => {
    this.setState({
      isFocused: false,
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };
}
