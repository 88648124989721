import { FunctionalComponent, h } from 'preact';

import { ButtonComponent } from 'common/module/button/component';
import { ButtonComponentTypeEnum } from 'common/module/button/component-type.enum';
import { PropertySerpLightViewStoreStateInterface } from 'common/module/property/serp/light/view-store-state.interface';

import { PropertySerpLightAutocompleteInputComponent } from './input/component';

export const PropertySerpLightAutocompleteTemplate: FunctionalComponent<PropertySerpLightViewStoreStateInterface> = (
  props
) => (
  <div className='light-search__input-container'>
    <PropertySerpLightAutocompleteInputComponent />
    <ButtonComponent
      cssClass={'light-search__input-suffix-button'}
      componentType={ButtonComponentTypeEnum.type2}
      icon={() => (
        <svg viewBox='0 0 16 16' className='input-search__icon'>
          <use xlinkHref='#svg-search' />
        </svg>
      )}
      onClick={props.onSubmit}
    />
  </div>
);
