import { JsonApiConfigInterface } from 'pf-frontend-common/src/module/json-api/config.interface';
import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { AgentModel } from 'common/data/agent/model';
import { AgentLeadModel } from 'common/data/agent-lead/model';
import { BedroomModel } from 'common/data/bedroom/model';
import { BrokerModel } from 'common/data/broker/model';
import { BrokerLeadModel } from 'common/data/broker-lead/model';
import { BuildingReviewModel } from 'common/data/building-review/model';
import { EmailAlertModel } from 'common/data/email-alert/model';
import { EstimationSettingsModel } from 'common/data/estimation-settings/model';
import { HomeValuationModel } from 'common/data/home-valuation/model';
import { LocationModel } from 'common/data/location/model';
import { ProjectModel } from 'common/data/project/model';
import { ProjectPropertyModel } from 'common/data/project-property/model';
import { PropertyModel } from 'common/data/property/model';
import { PropertyLeadModel } from 'common/data/property-lead/model';
import { PropertyTypeModel } from 'common/data/property-type/model';
import { TransactionModel } from 'common/data/transaction/model';
import { TransactionsModel } from 'common/data/transactions/model';

const config: JsonApiConfigInterface = {
  mapping: {},
};

config.mapping[AgentModel.JSONAPI_TYPE] = <typeof JsonApiModel>AgentModel;
config.mapping[AgentLeadModel.JSONAPI_TYPE] = <typeof JsonApiModel>AgentLeadModel;
config.mapping[BrokerModel.JSONAPI_TYPE] = <typeof JsonApiModel>BrokerModel;
config.mapping[BrokerLeadModel.JSONAPI_TYPE] = <typeof JsonApiModel>BrokerLeadModel;
config.mapping[BuildingReviewModel.JSONAPI_TYPE] = <typeof JsonApiModel>BuildingReviewModel;
config.mapping[ProjectModel.JSONAPI_TYPE] = <typeof JsonApiModel>ProjectModel;
config.mapping[ProjectPropertyModel.JSONAPI_TYPE] = <typeof JsonApiModel>ProjectPropertyModel;
config.mapping[EmailAlertModel.JSONAPI_TYPE] = <typeof JsonApiModel>EmailAlertModel;
config.mapping[LocationModel.JSONAPI_TYPE] = <typeof JsonApiModel>LocationModel;
config.mapping[PropertyModel.JSONAPI_TYPE] = <typeof JsonApiModel>PropertyModel;
config.mapping[PropertyLeadModel.JSONAPI_TYPE] = <typeof JsonApiModel>PropertyLeadModel;
config.mapping[TransactionModel.JSONAPI_TYPE] = <typeof JsonApiModel>TransactionModel;
config.mapping[HomeValuationModel.JSONAPI_TYPE] = <typeof JsonApiModel>HomeValuationModel;
config.mapping[TransactionsModel.JSONAPI_TYPE] = <typeof JsonApiModel>TransactionsModel;
config.mapping[EstimationSettingsModel.JSONAPI_TYPE] = <typeof JsonApiModel>EstimationSettingsModel;
config.mapping[BedroomModel.JSONAPI_TYPE] = <typeof JsonApiModel>BedroomModel;
config.mapping[PropertyTypeModel.JSONAPI_TYPE] = <typeof JsonApiModel>PropertyTypeModel;

export function configGetJsonApi(): JsonApiConfigInterface {
  return config;
}
