// this magic with class is required to avoid webpack inline the secret into the places where it is used
// making it more difficult to reverse engineer cookie sign algorithm
// tslint:disable-next-line: pf-file
class A {
  public static e(): string {
    return 'X-Utgz';
  }
}

export const cookieAuthenticatorGetCookieSignName = function e(): string {
  return A.e();
};
