import { FilterModel } from 'common/data/filter/model';

export class BedroomModel extends FilterModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'bedroom';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = BedroomModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof this> = ['id', 'name', 'value'];
}
