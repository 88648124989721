import { BrowserDocumentServiceInterface } from 'pf-frontend-common/src/service/browser-document/service.interface';

import { AssetLoaderCssServiceInterface } from 'common/service/asset-loader-css/service.interface';

export class AssetLoaderCss implements AssetLoaderCssServiceInterface {
  /**
   * List of loaded CSS bundles
   */
  private loadedBundle: string[] = [];

  /**
   * Constructor
   */
  constructor(private browserDocumentService: BrowserDocumentServiceInterface) {}

  /**
   * Import a CSS bundle
   */
  public importBundle(bundleName: string): Promise<void> {
    // Bundle already loaded
    if (this.loadedBundle.includes(bundleName)) {
      return Promise.resolve();
    }

    // Keep the bundle as loaded
    this.loadedBundle.push(bundleName);

    return this.browserDocumentService.importStylesheet(bundleName);
  }
}
