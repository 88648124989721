import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { PropertyContactedTypeEnum } from 'common/data/property-contacted/type.enum';

export class PropertyContactedModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'contacted_property';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = PropertyContactedModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof PropertyContactedModel> = ['property_id', 'contact_date'];

  // tslint:disable:variable-name

  /**
   * Property id
   */
  public property_id: number;

  /**
   * Contact date
   */
  public contact_date: string;

  /**
   * Contact type
   */
  public contact_type: PropertyContactedTypeEnum;

  // tslint:enable:variable-name
}
