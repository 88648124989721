import { i18nTranslate } from 'common/helper/i18n/translate';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

export const PropertySerpFormPlaceholder = {
  [FilterParamsEnum.categoryId]: i18nTranslate('Select category'),
  [FilterParamsEnum.propertyTypeId]: i18nTranslate('Property type'),
  [FilterParamsEnum.pricePeriod]: i18nTranslate('Price period'),
  [FilterParamsEnum.minPrice]: i18nTranslate('Min. price'),
  [FilterParamsEnum.maxPrice]: i18nTranslate('Max. price'),
  [FilterParamsEnum.minArea]: i18nTranslate('Min. area'),
  [FilterParamsEnum.maxArea]: i18nTranslate('Max. area'),
  [FilterParamsEnum.furnishing]: i18nTranslate('Furnishing'),
  [FilterParamsEnum.amenities]: i18nTranslate('Amenities'),
  [FilterParamsEnum.bedrooms]: i18nTranslate('Bedrooms'),
  [FilterParamsEnum.bathrooms]: i18nTranslate('Bathrooms'),
  [FilterParamsEnum.virtualViewings]: i18nTranslate('All Virtual Viewings'),
  [FilterParamsEnum.utilitiesPriceType]: i18nTranslate('Price incl/excl'),
  [FilterParamsEnum.completionStatus]: i18nTranslate('Completion status'),
  [FilterParamsEnum.keyword]: i18nTranslate('Keywords'),
};
