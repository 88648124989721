import { BrowserStorageInterface } from 'pf-frontend-common/src/module/browser/storage.interface';

import { stringCryptSymmetric } from 'common/helper/string/crypt-symmetric';

export class CaptchaStorageStore {
  /**
   * Number of cta clicks after which captcha should be executed
   */
  private threshold: number = 5;

  /**
   * Constructor
   */
  constructor(private localStorage: BrowserStorageInterface) {}

  /**
   * Check if local storage key is valid
   */
  public checkIfValid(localStorageKey: string): boolean {
    const localStorageValue = this.localStorage.getData(localStorageKey);

    if (!localStorageValue) {
      return true;
    }

    if (parseInt(stringCryptSymmetric(localStorageValue as string), 10) < this.threshold) {
      return true;
    }

    return false;
  }

  /**
   * Set captcha with new iterated value
   */
  public setData(localStorageKey: string): void {
    const localStorageValue =
      this.localStorage.getData(localStorageKey) === null || this.localStorage.getData(localStorageKey) === ''
        ? '0'
        : stringCryptSymmetric(this.localStorage.getData(localStorageKey) as string);

    const encryptedLocalStorageValue = stringCryptSymmetric(String(parseInt(localStorageValue as string, 10) + 1));

    this.localStorage.setData(localStorageKey, encryptedLocalStorageValue);
  }

  /**
   * Check if captcha was validated before
   */
  public checkIfValidated(validationLocalStorageKey: string): boolean {
    const localStorageValue = this.localStorage.getData(validationLocalStorageKey);

    if (!localStorageValue) {
      return false;
    }

    if (parseInt(localStorageValue as string, 10) === 1) {
      return true;
    }

    return false;
  }

  /**
   * Set local storage value
   */
  public setIsCaptchaValidated(validationLocalStorageKey: string): void {
    this.localStorage.setData(validationLocalStorageKey, 1);
  }

  /**
   * Reset local storage value
   */
  public resetIsCaptchaValidated(validationLocalStorageKey: string): void {
    this.localStorage.removeData(validationLocalStorageKey);
  }
}
