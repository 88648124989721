import { BrowserStorageLocalService } from 'pf-frontend-common/src/service/browser-storage-local/service';

import { FilterCacheStore } from 'common/data/filter/cache/store';
import { FilterCacheServiceInterface } from 'common/service/filter-cache/service.interface';

const service = new FilterCacheStore(BrowserStorageLocalService());

export function FilterCacheService(): FilterCacheServiceInterface {
  return service;
}
