import { StatsContexterServiceInterface } from 'pf-frontend-common/src/service/stats-contexter/service.interface';

import { BackendConfigAbTestsInterface } from 'common/data/backend/config/ab-tests.interface';
import { abTestStatsGtmTransformer } from 'common/module/ab-test/stats/gtm/transformer';
import { StatsGtmProviderInterface } from 'common/module/stats/gtm/provider.interface';
import { StatsServiceInterface } from 'common/service/stats/service.interface';

export class AbTestStore {
  /**
   * Constructor
   */
  public constructor(
    protected statsService: StatsServiceInterface,
    private abTests: BackendConfigAbTestsInterface,
    private statsGtmProvider: StatsGtmProviderInterface,
    private statsContexterService: StatsContexterServiceInterface
  ) {}

  /**
   * Init store
   */
  public initialize(): void {
    Object.keys(this.abTests).forEach((abTest) => {
      if (this.abTests[abTest].async) {
        return;
      }

      // Find and perform actions on the enabled variant (break early when found)
      Object.keys(this.abTests[abTest].variants).some((variant) => {
        if (this.abTests[abTest].variants[variant]) {
          this.statsGtmProvider.send(abTestStatsGtmTransformer(abTest, variant));
        }

        return this.abTests[abTest].variants[variant];
      });
    });

    this.statsContexterService.setAbTests(this.abTests);
  }
}
