import { FilterParametersInterface } from '../../parameters.interface';
import { FilterParamsEnum } from '../../params.enum';
import { FilterSearchQueryInterface } from '../query.interface';
import { FilterSearchUrlParamsEnum } from './params.enum';

export const filterSearchUrlDeserialize = (queryParams: FilterSearchQueryInterface): FilterParametersInterface => {
  const mergeWithChoices = <T, C>(value: T) => ({
    value,
    choices: <C[]>[],
  });
  return {
    [FilterParamsEnum.query]: {
      value: queryParams[FilterSearchUrlParamsEnum.query],
    },
    [FilterParamsEnum.locationsIds]: {
      value: queryParams[FilterSearchUrlParamsEnum.locationsIds]?.length
        ? queryParams[FilterSearchUrlParamsEnum.locationsIds].split('-')
        : [],
    },
    [FilterParamsEnum.categoryId]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.categoryId]),
    [FilterParamsEnum.propertyTypeId]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.propertyTypeId]),
    [FilterParamsEnum.minPrice]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.minPrice]),
    [FilterParamsEnum.maxPrice]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.maxPrice]),
    [FilterParamsEnum.furnishing]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.furnishing]),
    [FilterParamsEnum.minArea]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.minArea]),
    [FilterParamsEnum.maxArea]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.maxArea]),
    [FilterParamsEnum.pricePeriod]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.pricePeriod]),
    [FilterParamsEnum.keyword]: { value: queryParams[FilterSearchUrlParamsEnum.keyword] },
    [FilterParamsEnum.amenities]: mergeWithChoices(queryParams?.[FilterSearchUrlParamsEnum.amenities] || []),
    [FilterParamsEnum.bedrooms]: mergeWithChoices(queryParams?.[FilterSearchUrlParamsEnum.bedrooms] || []),
    [FilterParamsEnum.bathrooms]: mergeWithChoices(queryParams?.[FilterSearchUrlParamsEnum.bathrooms] || []),
    [FilterParamsEnum.completionStatus]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.completionStatus]),
    [FilterParamsEnum.utilitiesPriceType]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.utilitiesPriceType]),
    [FilterParamsEnum.virtualViewings]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.virtualViewings]),
    [FilterParamsEnum.pageNumber]: { value: queryParams[FilterSearchUrlParamsEnum.pageNumber] },
    [FilterParamsEnum.sort]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.sort]),
    [FilterParamsEnum.verified]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.verified]),
    [FilterParamsEnum.trustedAgent]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.trustedAgent]),
    [FilterParamsEnum.listedWithin]: mergeWithChoices(queryParams[FilterSearchUrlParamsEnum.listedWithin]),
    [FilterParamsEnum.paymentMethods]: mergeWithChoices(queryParams?.[FilterSearchUrlParamsEnum.paymentMethods] || []),
  };
};
