import { preactConnectStore } from 'common/module/preact/connect-store';
import { RecommendedPropertiesTemplatePropsInterface } from 'common/module/recommended-properties/template-props.interface';
import { RecommendedPropertiesViewStore } from 'common/module/recommended-properties/view-store';
import { HomePageRecommendedPropertiesTemplate } from 'desktop/module/home-page/recommended-properties/template';
import { RecommendedPropertiesService } from 'desktop/service/recommended-properties/service';

export const HomePageRecommendedPropertiesComponent = preactConnectStore<
  RecommendedPropertiesViewStore,
  RecommendedPropertiesTemplatePropsInterface
>(RecommendedPropertiesService, (store) => store.getState())(HomePageRecommendedPropertiesTemplate);
