import { ApplicationFeatureFactory } from 'common/module/application/feature.factory';
import { ApplicationFeatureServiceInterface } from 'common/service/application-feature/service.interface';

const applicationFeatureService = ApplicationFeatureFactory();

// Initialize service
applicationFeatureService.initialize();

export function ApplicationFeatureService(): ApplicationFeatureServiceInterface {
  return applicationFeatureService;
}
