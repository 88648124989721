export const enum StatsSnowplow2SelfDescribingSchemaImageClickViewTypeEnum {
  /**
   * Clicked on arrow key
   */
  arrow = 'arrow',

  /**
   * Clicked on thumbnail
   */
  thumbnail = 'thumbnail',

  /**
   * Clicked on image on serp page
   */
  image_serp_click = 'image_serp_click',
}
