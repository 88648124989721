import { GtmStatsAdapterListingStatus } from 'pf-frontend-common/src/module/gtm/stats/adapter/listing-status';

export const PropertyListStatsGtmView360PropertyImpression = (pageType: string, listingStatusLabel: string) => {
  const listingStatus = new GtmStatsAdapterListingStatus(listingStatusLabel).getData();
  return {
    event: 'customEvent',
    eventCategory: '360',
    eventAction: `Impression - ${listingStatus}`,
    eventLabel: `${pageType} - 360 - Impression - ${listingStatus}`,
  };
};
