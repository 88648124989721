export const enum PropertyCtaStatechartEvent {
  /**
   * Click on call back cta button
   */
  clickCtaCallBackButton = 'clickCtaCallBackButton',

  /**
   * Click on call cta button
   */
  clickCtaCallButton = 'clickCtaCallButton',

  /**
   * Click on email cta button
   */
  clickCtaEmailButton = 'clickCtaEmailButton',

  /**
   * Click on sms cta button
   */
  clickCtaSmsButton = 'clickCtaSmsButton',

  /**
   * Click on whatsapp
   */
  clickCtaWhatsAppButton = 'clickCtaWhatsAppButton',

  /**
   * Captcha validation is needed for WhatsApp
   */
  captchaValidationNeededWhatsApp = 'captchaValidationNeededWhatsApp',

  /**
   * Captcha validation is not needed for WhatsApp
   */
  captchaValidationIsNotNeededWhatsApp = 'captchaValidationIsNotNeededWhatsApp',

  /**
   * Captcha validation is needed for Call
   */
  captchaValidationNeededCall = 'captchaValidationNeededCall',

  /**
   * Captcha validation is not needed for Call
   */
  captchaValidationIsNotNeededCall = 'captchaValidationIsNotNeededCall',

  /**
   * Captcha executed successfully
   */
  captchaExecuted = 'captchaExecuted',
}
