import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { AutocompleteViewStoreInterface } from 'common/module/autocomplete/view-store.interface';

import { AutocompleteViewStore } from './view-store';

export function AutocompleteViewStoreFactory(): AutocompleteViewStoreInterface {
  return new AutocompleteViewStore(EventEmitterFactory(), WindowService());
}
