export enum RangeSelectorFieldsEnum {
  /**
   * field name for min input
   */
  min = 'min',

  /**
   * field name for max input
   */
  max = 'max',
}
