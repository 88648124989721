import { FunctionalComponent, h } from 'preact';

import { ButtonComponentTypeEnum } from 'common/module/button/component-type.enum';
import { ButtonTemplatePropsInterface } from 'common/module/button/template-props.interface';
import { Loader4Template } from 'common/module/loader/4/template';

/**
 * Button main template
 * TODO-FE[WEB-4816] - update this template when button UI component would be present in design system
 */
export const ButtonTemplate: FunctionalComponent<ButtonTemplatePropsInterface> = ({
  icon: Icon,
  componentType = ButtonComponentTypeEnum.type1,
  ...props
}) => {
  const componentProps: Partial<preact.JSX.HTMLAttributes<HTMLButtonElement & HTMLAnchorElement>> = {
    class: `
      button-2
      ${'button-' + componentType}
      ${props.cssClass ? props.cssClass : ''}
      ${props.isPressed ? 'button-' + componentType + '--pressed' : ''}
      ${props.isLoading ? 'button-' + componentType + '--loading' : ''}
      ${props.isInverted ? 'button-' + componentType + '--inverted' : ''}
    `,
    disabled: props.isDisabled,
    type: props.type,
    onClick: !props.isLoading && props.onClick,
  };

  const Component = props.href ? 'a' : 'button';

  if (props.href) {
    componentProps.href = props.href;
    if (props.download) {
      componentProps.download = props.download;
    }
    if (props.target) {
      componentProps.target = props.target;
    }

    if (props.rel) {
      componentProps.rel = props.rel;
    }
  }

  return (
    <Component {...componentProps}>
      {!props.isLoading && Icon && (
        <Icon
          class={`
        button-${componentType}__icon
        ${props.iconCssClass ? props.iconCssClass : ''}
      `}
        />
      )}
      {props.isLoading ? (
        <span
          className={`
      button-${componentType}__content
      button-${componentType}__content--loading
    `}
        >
          {Icon && (
            <Icon
              class={`
          button-${componentType}__icon
          ${props.iconCssClass ? props.iconCssClass : ''}
        `}
            />
          )}
          {props.children}
        </span>
      ) : (
        props.children
      )}

      {props.isLoading && (
        <Loader4Template isInverted={props.isInverted} cssClass={`button-${componentType}__loader`} />
      )}
    </Component>
  );
};
