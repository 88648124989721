import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class TransactionModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'transaction';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = TransactionModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof TransactionModel> = [
    'id',
    'type',
    'price',
    'transaction_date',
    'location_path',
    'property_type',
    'beds_count',
    'unit_size',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof TransactionModel> = [];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['price_text'];

  // tslint:disable:variable-name

  /**
   * Transaction ID
   */
  public id: string = '';

  /**
   * Transaction type
   */
  public type: string;

  /**
   * Transaction price
   */
  public price: number;

  /**
   * Transaction date
   */
  public transaction_date: string;

  /**
   * Transaction location
   */
  public location_path: string;

  /**
   * Transaction type
   */
  public property_type: string;

  /**
   * Transaction beds count
   */
  public beds_count: string;

  /**
   * Transaction property unit size
   */
  public unit_size: number;

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Price text
     */
    price_text: string;
  };

  // tslint:enable:variable-name
}
