import { BackendConfigLanguageInterface } from 'common/data/backend/config/language.interface';
import { BackendConfigLanguageDefault } from 'common/data/backend/config/language-default';
import { ConfigLanguageCodeEnum } from 'common/helper/config/language-code.enum';

/**
 * Get language config from backend
 */
export function configGetLanguage(): BackendConfigLanguageInterface {
  const language = window.propertyfinder && window.propertyfinder.language;

  if (!language) {
    return BackendConfigLanguageDefault;
  }

  if (language.isRtl === undefined && language.current === ConfigLanguageCodeEnum.ar) {
    language.isRtl = true;
  }

  return language;
}
