import { FunctionalComponent, h } from 'preact';

import { configGetLanguage } from 'common/helper/config/get-language';
import { PreactAsyncComponent } from 'common/module/preact/async/component';
import { UserAuthModalComponentPropsInterface } from 'common/module/user/auth/modal-component-props.interface';
import { AssetLoaderCssService } from 'common/service/asset-loader-css/service';

export const UserAuthAsyncComponent: FunctionalComponent<UserAuthModalComponentPropsInterface> = (props) => (
  <PreactAsyncComponent
    {...props}
    component={() =>
      Promise.all([
        import(
          /* webpackChunkName: "user-auth-modal-component" */
          './modal-component'
        ),
        AssetLoaderCssService().importBundle(__css('css/async-auth-modal.css')[configGetLanguage().current]),
      ]).then((response) => response[0].UserAuthModalComponent)
    }
  />
);
