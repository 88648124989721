import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

import { FilterWidgetComputeVisibleAndHiddenFiltersPropsInterface } from './compute-visible-and-hidden-filters-props.interface';
import { filterWidgetGetFiltersConfig } from './get-filters-config';
import { filterWidgetRenderMap } from './render-map';
import { FilterWidgetTypeEnum } from './type.enum';

const NUMBER_OF_ALWAYS_VISIBLE_FILTERS = 3;

const categoryIdentifierAdapter = new PropertyCategoryIdentifierAdapter();

export const filterWidgetComputeVisibleAndHiddenFilters = (
  { filterParams, countryCode }: FilterWidgetComputeVisibleAndHiddenFiltersPropsInterface,
  blacklist?: FilterWidgetTypeEnum[]
) => {
  const visibleFilterTypes: FilterWidgetTypeEnum[] = [];
  const hiddenFiltersTypes: FilterWidgetTypeEnum[] = [];

  const category = categoryIdentifierAdapter.getData(
    parseInt(filterParams[FilterParamsEnum.categoryId].value, 10)
  ) as PropertyCategoryIdentifierEnum;

  let filterWidgets = filterWidgetGetFiltersConfig(countryCode)[category];

  if (blacklist) {
    filterWidgets = filterWidgets.filter((filterWidget) => !blacklist.includes(filterWidget));
  }

  filterWidgets.forEach((filterWidgetType: FilterWidgetTypeEnum, index: number) => {
    const renderConfig = filterWidgetRenderMap[filterWidgetType];

    if (!renderConfig.shouldRender || renderConfig.shouldRender({ filterParams })) {
      (index < NUMBER_OF_ALWAYS_VISIBLE_FILTERS ? visibleFilterTypes : hiddenFiltersTypes).push(filterWidgetType);
    }
  });

  return {
    visibleFilterTypes,
    hiddenFiltersTypes,
  };
};
