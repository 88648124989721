import { DefaultContext, EventObject, MachineConfig, StateSchema } from 'xstate/es';

import { PropertySavedIdbStatechartEvent } from 'common/data/property-saved/idb/statechart.event';
import { PropertySavedIdbStatechartActionEnum } from 'common/data/property-saved/idb/statechart-action.enum';
import { PropertySavedIdbStatechartStateEnum } from 'common/data/property-saved/idb/statechart-state.enum';

export const PropertySavedIdbStatechart: MachineConfig<DefaultContext, StateSchema, EventObject> = {
  initial: PropertySavedIdbStatechartStateEnum.iddle,
  states: {
    [PropertySavedIdbStatechartStateEnum.iddle]: {
      on: {
        [PropertySavedIdbStatechartEvent.sync]: PropertySavedIdbStatechartStateEnum.syncing,
        [PropertySavedIdbStatechartEvent.loadAll]: PropertySavedIdbStatechartStateEnum.loadingAll,
      },
    },
    [PropertySavedIdbStatechartStateEnum.loadingAll]: {
      onEntry: [PropertySavedIdbStatechartActionEnum.loadAll],
      on: {
        [PropertySavedIdbStatechartEvent.sync]: PropertySavedIdbStatechartStateEnum.syncing,
        [PropertySavedIdbStatechartEvent.loadAllSuccess]: {
          target: PropertySavedIdbStatechartStateEnum.iddle,
          actions: [
            PropertySavedIdbStatechartActionEnum.updateLocalCache,
            PropertySavedIdbStatechartActionEnum.updateDb,
            PropertySavedIdbStatechartActionEnum.firstLoading,
          ],
        },
      },
    },
    [PropertySavedIdbStatechartStateEnum.syncing]: {
      entry: [PropertySavedIdbStatechartActionEnum.updateDb],
      on: {
        [PropertySavedIdbStatechartEvent.synced]: {
          target: PropertySavedIdbStatechartStateEnum.iddle,
          actions: [
            PropertySavedIdbStatechartActionEnum.updateLocalCache,
            PropertySavedIdbStatechartActionEnum.updateDb,
          ],
        },
        [PropertySavedIdbStatechartEvent.sync]: {
          target: PropertySavedIdbStatechartStateEnum.iddle,
          // cancel update and start update again
          actions: [PropertySavedIdbStatechartActionEnum.cancelUpdate, PropertySavedIdbStatechartActionEnum.updateDb],
        },
      },
    },
  },
};
