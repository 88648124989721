import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { FilterStore } from 'common/module/filter/store';
import { AutocompleteCacheLocationsService } from 'common/service/autocomplete-cache-locations/service';
import { FilterSettingsService } from 'common/service/filter-settings/service';
import { LocationDatabaseService } from 'common/service/location-database/service';

export function FilterStoreFactory(): FilterStore {
  return new FilterStore(
    EventEmitterFactory(),
    FilterSettingsService(),
    LocationDatabaseService(),
    AutocompleteCacheLocationsService()
  );
}
