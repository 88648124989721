/**
 * Crypts string symmetrically
 *
 * @example
 * stringCryptSymmetric("password") = "fWx+fnpif2k="
 * stringCryptSymmetric("fWx+fnpif2k=", true) = "password"
 */
export const stringCryptSymmetric = (str: string, key: number = 13) => {
  str = decodeURIComponent(str);

  // to array of characters.
  const charArray = str
    .split('')
    .map((c) => c.charCodeAt(0)) // to array of numbers (each is character's ASCII value)
    // tslint:disable-next-line:no-bitwise
    .map((i) => i ^ key); // XOR ""ENCRYPTION""

  // one-liner trick: array-of-numbers to array-of-characters (ASCII value), join to single string.
  // may result in buffer-overflow on long string!
  return encodeURIComponent(String.fromCharCode.apply(undefined, charArray));
};
