import { RangeSelectorValueInterface } from 'library/range-selector/value.interface';

import { rangeSelectorGetValueAsNumber } from './get-value-as-number';

// TODO-FE[TPNX-2387] move functions to RangeSelectorInput
/**
 * return the given options bigger than min value or lower than max value
 * @param options input-field selection options
 * @param value RangeSelectorValueInterface
 * @returns filtered values based on input-field values
 */
export const rangeSelectorFilterOptions = (
  options: string[],
  value: RangeSelectorValueInterface
): [string[], string[]] => {
  const minValueInt = rangeSelectorGetValueAsNumber(value.min);
  const maxValueInt = rangeSelectorGetValueAsNumber(value.max);

  const minOptions = options.filter((option) => parseInt(option, 10) <= maxValueInt);
  const maxOptions = options.filter((option) => parseInt(option, 10) >= minValueInt);

  return [minOptions.length ? minOptions : options, maxOptions.length ? maxOptions : options];
};
