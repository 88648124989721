import { IDBPCursorWithValue, IDBPDatabase } from 'idb';

import { LocationModel } from 'common/data/location/model';
import { configGetLanguage } from 'common/helper/config/get-language';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { DatabaseServiceInterface } from 'common/service/database/service.interface';
import { LocationDatabaseServiceInterface } from 'common/service/location-database/service.interface';

export class LocationDatabaseStore implements LocationDatabaseServiceInterface {
  /**
   * Table name
   */
  private tableName: string = configGetLanguage().current + '_' + DatabaseTableEnum.locations;

  /**
   * Constructor
   */
  constructor(private databaseService: DatabaseServiceInterface<LocationModel>) {}

  /**
   * @inheritDoc
   */
  public updateDatabase(models: LocationModel[]): void {
    this.databaseService
      .openDatabase({
        languages: configGetLanguage(),
      })
      .then(this.onUpdateDatabase(models));
  }

  /**
   * @inheritDoc
   */
  public getAll(): Promise<LocationModel[]> {
    return this.databaseService
      .openDatabase({
        languages: configGetLanguage(),
      })
      .then(this.onGetAll);
  }

  /**
   * Update locations in database
   */
  private onUpdateDatabase = (models: LocationModel[]) => (database: IDBPDatabase<LocationModel>) => {
    const transaction = database?.transaction(this.tableName, 'readwrite');

    if (transaction) {
      const store = transaction.objectStore(this.tableName);

      models.forEach((model) => store.put(model));
    }
  };

  /**
   * Get all locations from database
   */
  private onGetAll = async (database: IDBPDatabase<LocationModel>) => {
    const locations: LocationModel[] = [];
    const transaction = database?.transaction(this.tableName, 'readonly');

    if (transaction) {
      const store = transaction.objectStore(this.tableName);

      let cursor = await store.openCursor();

      const onIterateCursor = this.onIterateCursor(locations);

      while (cursor) {
        cursor = await onIterateCursor(cursor);
      }

      return transaction.done.then(() => locations);
    }
  };

  /**
   * Update locations list
   */
  private onIterateCursor =
    (locations: LocationModel[]) =>
    (cursor: IDBPCursorWithValue<LocationModel, [string]>): Promise<IDBPCursorWithValue<LocationModel, [string]>> => {
      if (!cursor) {
        return;
      }

      locations.push(cursor.value);
      return cursor.continue();
    };
}
