export const enum PropertyCtaStatechartStateEnum {
  /**
   * Cta state
   */
  cta = 'cta',

  /**
   * Cta idle state
   */
  ctaIdle = 'ctaIdle',

  /**
   * Call state
   */
  call = 'call',

  /**
   * Call idle state
   */
  callIdle = 'callIdle',

  /**
   * User clicked on call cta
   */
  clickedOnCtaCall = 'clickedOnCtaCall',

  /**
   * WhatsApp state
   */
  whatsapp = 'whatsapp',

  /**
   * WhatsApp idle state
   */
  whatsappIdle = 'whatsappIdle',

  /**
   * User clicked on WhatsApp cta
   */
  clickedOnCtaWhatsApp = 'clickedOnCtaWhatsApp',
}
