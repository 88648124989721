import { h } from 'preact';

import { IconCrossTemplate } from 'common/module/icon/cross-template';
import { ChipComponent } from 'library/chip/component';

// TODO-FE[TPNX-1980] Extract it to the library as a ChipWithCross to be reused in wizard
export const MultiSelectionAutocompleteChipTemplate = ({
  title,
  onCrossClick,
  className,
}: MultiSelectionAutocompleteChipTemplatePropsInterface) => (
  <ChipComponent
    label={title}
    isSelected
    className={`multi-selection-autocomplete__chip
        ${className || ''}
    `}
    labelClassName='multi-selection-autocomplete__chip-label'
    onClick={(e) => e.preventDefault()}
    suffixIcon={
      <button
        className='multi-selection-autocomplete__chip-cross-button'
        onClick={(e: Event) => {
          e.stopPropagation();
          onCrossClick();
        }}
      >
        <IconCrossTemplate class='multi-selection-autocomplete__chip-cross-icon' />
      </button>
    }
  />
);
