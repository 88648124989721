import { h } from 'preact';

import { configGetSettings } from 'common/helper/config/get-settings';
import { settingsGetPageType } from 'common/helper/settings/get-page-type';
import { preactConnectStore } from 'common/module/preact/connect-store';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { UserAuthTrackerFactory } from 'common/module/user/auth/tracker.factory';
import { userAvatarStatsGtmClickUserProfile } from 'common/module/user/avatar/stats/gtm/click-user-profile';
import { UserAvatarTemplate } from 'common/module/user/avatar/template';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';
import { UserAvatarDropdownComponent } from 'desktop/module/user/avatar/dropdown/component';
import { UserAvatarService } from 'desktop/service/user-avatar/service';

export const UserAvatarComponent = preactConnectStore(UserAvatarService, (store) => ({
  ...store.getState(),
  onClickUserAvatar(e: Event): void {
    if (store.getState().isLoggedIn) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    UserAuthTrackerFactory().onLoginModalOpen(StatsTealiumDataLayerEventReferenceEnum.HEADER);
    StatsGtmProviderService().send(userAvatarStatsGtmClickUserProfile(settingsGetPageType(configGetSettings())));
  },
  children: [<UserAvatarDropdownComponent isUserLoggedIn={store.getState().isLoggedIn} />],
}))(UserAvatarTemplate);
