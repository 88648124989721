import { i18nTranslate } from 'common/helper/i18n/translate';

import { AmenitiesSelectorOptionInterface } from './option.interface';

/**
 * @param options list of options
 * @description returns the text to be put in the dropdown component
 */
export function amenitiesSelectorComputeText(options: AmenitiesSelectorOptionInterface[]): string {
  const checkedItems: AmenitiesSelectorOptionInterface[] = options.filter(({ checked }) => checked);

  switch (checkedItems.length) {
    case 0:
      return undefined;
    case 1:
      return checkedItems[0].label as string;
    case 2:
      return i18nTranslate('Amenities >1').replace('{count}', String(checkedItems.length));
    default:
      return (checkedItems.length > 9 ? i18nTranslate('Amenities >9') : i18nTranslate('Amenities >2')).replace(
        '{count}',
        String(checkedItems.length)
      );
  }
}
