import { EventEmitterInterface } from '@propertyfinder/pf-frontend-common/src/module/event/emitter.interface';

import { LocationModel } from 'common/data/location/model';
import { objectCompare } from 'common/helper/object/compare';
import { objectDrop } from 'common/helper/object/drop';
import { DataStore } from 'common/module/data/store';
import { DataStoreEvent } from 'common/module/data/store.event';
import { FilterActionEvent } from 'common/module/filter/action.event';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { filterParamsEquality } from 'common/module/filter/params-equality';
import { FilterStore } from 'common/module/filter/store';

import { getFilterDefaultParams } from '../get-filter-default-params';
import { FilterHandlerViewStoreStateInterface } from './view-store-state.interface';

/**
 * Abstract class to be extended by a ViewStore that needs to handle Filter behaviour.
 */
export abstract class FilterHandlerViewStore<T, U = {}> extends DataStore<FilterHandlerViewStoreStateInterface & T> {
  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private appliedFiltersStore: FilterStore,
    private temporaryFiltersStore?: FilterStore
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritDoc
   */
  public initialize(options?: U): void {
    this.state = {
      ...this.state,
      filterParams: this.appliedFiltersStore.getState(),
    };

    this.observers();

    if (this.temporaryFiltersStore) {
      // Synchronize temporary and applied filters stores
      this.temporaryFiltersStore.update(this.appliedFiltersStore.getState());
    }
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    if (this.temporaryFiltersStore) {
      this.temporaryFiltersStore
        .getEventEmitter()
        .removeListener(DataStoreEvent.updateState, this.syncStateFromTemporaryFiltersStore);

      this.temporaryFiltersStore
        .getEventEmitter()
        .removeListener(FilterActionEvent.locationUpdated, this.onTemporaryStoreLocationUpdated);
    }

    this.appliedFiltersStore
      .getEventEmitter()
      .removeListener(DataStoreEvent.updateState, this.syncStateFromAppliedFiltersStore);
  }

  /**
   * Setup event listeners
   */
  protected observers(): void {
    if (this.temporaryFiltersStore) {
      this.temporaryFiltersStore
        .getEventEmitter()
        .addListener(DataStoreEvent.updateState, this.syncStateFromTemporaryFiltersStore);

      this.temporaryFiltersStore
        .getEventEmitter()
        .addListener(FilterActionEvent.locationUpdated, this.onTemporaryStoreLocationUpdated);
    }

    this.appliedFiltersStore
      .getEventEmitter()
      .addListener(DataStoreEvent.updateState, this.syncStateFromAppliedFiltersStore);
  }

  /**
   * Return the default filter settings
   */
  protected getDefaultSettings(): Promise<FilterParametersInterface> {
    const activeStore = this.temporaryFiltersStore || this.appliedFiltersStore;
    return this.appliedFiltersStore.defaultSettingsByCategoryPromise.then((defaultSettingsByCategory) => {
      return defaultSettingsByCategory[activeStore.getState()[FilterParamsEnum.categoryId].value];
    });
  }

  /**
   * Handle filter parameter value change
   */
  protected changeFilters(filters: FilterParametersInterface): Promise<void> {
    const store = this.temporaryFiltersStore ? this.temporaryFiltersStore : this.appliedFiltersStore;
    const state = store.getState();

    // When we changing the categoryId, all the filters, except location & sort should be reseted to default values
    if (filters[FilterParamsEnum.categoryId]) {
      return store.reset({
        ...filters,
        [FilterParamsEnum.locationsIds]:
          filters?.[FilterParamsEnum.locationsIds] || state[FilterParamsEnum.locationsIds],
        [FilterParamsEnum.sort]: filters?.[FilterParamsEnum.sort] || state[FilterParamsEnum.sort],
      });
    }
    return store.update(filters);
  }

  /**
   * Handle applying current filter parameter values
   */
  protected applyFilters(): Promise<void> {
    if (this.temporaryFiltersStore) {
      return this.temporaryFiltersStore
        .update({
          [FilterParamsEnum.pageNumber]: { value: getFilterDefaultParams()[FilterParamsEnum.pageNumber] },
        })
        .then(() => {
          return this.appliedFiltersStore.update(this.temporaryFiltersStore.getState());
        });
    }
    return Promise.resolve();
  }

  /**
   * Handle resetting filter parameter values
   */
  protected resetFilters(filters?: FilterParametersInterface): Promise<void> {
    if (this.temporaryFiltersStore) {
      return this.temporaryFiltersStore.reset(filters);
    }

    return this.appliedFiltersStore.reset(filters);
  }

  /**
   * Handle reverting filter parameter values
   */
  protected revertFilters(): Promise<void> {
    return this.temporaryFiltersStore
      ? this.temporaryFiltersStore.update(this.appliedFiltersStore.getState())
      : Promise.resolve();
  }

  /**
   * Did parameters change
   * Compared default params with the changed once
   */
  protected async isDirty(extraExcludedParams?: Array<keyof FilterParametersInterface>): Promise<boolean> {
    const excludedParams: Array<keyof FilterParametersInterface> = [
      ...(extraExcludedParams || []),
      FilterParamsEnum.categoryId,
      FilterParamsEnum.sort,
    ];

    // should return false if temporary filters are not provided
    if (!this.temporaryFiltersStore) {
      return false;
    }

    const defaultState = objectDrop(await this.getDefaultSettings(), ...excludedParams);
    const newState = objectDrop(this.temporaryFiltersStore.getState(), ...excludedParams);

    return !objectCompare(defaultState, newState);
  }

  /**
   * On filter state update
   */
  protected onFilterStateUpdate = (filterParams: FilterParametersInterface) => {
    // To override in extendable view-store
  };

  /**
   * Property search form locations updated
   */
  protected onTemporaryStoreLocationUpdated = () => {
    // To override in extendable view-store
  };

  /**
   * Get locations from the cache(indexDB) by id
   */
  protected getLocations(): Promise<LocationModel[]> {
    return this.temporaryFiltersStore
      ? this.temporaryFiltersStore.getLocations()
      : this.appliedFiltersStore.getLocations();
  }

  /**
   * Sync data from temporary filter store to applied service
   */
  private syncStateFromTemporaryFiltersStore = () => {
    const filterParams = this.temporaryFiltersStore.getState();
    this.setState({
      ...this.state,
      filterParams,
    });
    this.onFilterStateUpdate(filterParams);
  };

  /**
   * Sync data from  applied service to temporary filter store
   */
  private syncStateFromAppliedFiltersStore = () => {
    const filterParams = this.appliedFiltersStore.getState();

    this.setState({
      ...this.state,
      filterParams,
    });

    if (this.temporaryFiltersStore) {
      // update the temporary filter
      // Use Case: If we change applied filters from outside (for example order by dropdown)
      // then temporary filter should also be updated.
      if (!filterParamsEquality(filterParams, this.temporaryFiltersStore.getState())) {
        this.temporaryFiltersStore.update(filterParams);
      }
    } else {
      this.onFilterStateUpdate(filterParams);
    }
  };
}
