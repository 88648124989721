import { FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';

import { functionNoop } from 'common/helper/function/noop';
import { FilterParametersFieldChoiceInterface } from 'common/module/filter/parameters/field-choice.interface';
import { FilterSingleSelectionTemplatePropsInterface } from 'common/module/filter-single-selection/template-props.interface';
import { DropdownComponent } from 'library/dropdown/component';
import { DropdownSizeEnum } from 'library/dropdown/size.enum';
import { DropdownVariantEnum } from 'library/dropdown/variant.enum';
import { DropdownListComponent } from 'library/dropdown-list/component';

export const FilterSingleSelectionTemplate: FunctionalComponent<FilterSingleSelectionTemplatePropsInterface> = ({
  variant,
  value,
  label,
  choices,
  className,
  forcePrimary,
  onChange,
  onDropdownOpenStatusChange = functionNoop,
  style,
  dropdownSize = DropdownSizeEnum.normal,
}: FilterSingleSelectionTemplatePropsInterface) => {
  const selectedValue = choices.find((choice) => choice.value === value) || { value, label: '' };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <DropdownComponent
      className={className}
      style={style}
      variant={
        variant
          ? variant
          : forcePrimary || !!parseInt(value, 10)
          ? DropdownVariantEnum.primary
          : DropdownVariantEnum.secondary
      }
      label={label}
      text={selectedValue.label}
      onChangeOpen={(open: boolean) => {
        setIsOpen(open);
        onDropdownOpenStatusChange(open);
      }}
      isOpen={isOpen}
      size={dropdownSize}
    >
      <DropdownListComponent
        className='filter-single-selection__dropdown-list'
        value={selectedValue}
        options={choices}
        mapToValue={(option) => option.value}
        onChange={(selected: FilterParametersFieldChoiceInterface<string>) => {
          setIsOpen(false);
          onChange(selected);
        }}
        focused={isOpen}
        onESC={() => setIsOpen(false)}
      />
    </DropdownComponent>
  );
};
