import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';

import { AutocompleteCacheChecksumKeysEnum } from 'common/data/autocomplete/cache/checksum-keys.enum';
import { AutocompleteCacheStore } from 'common/data/autocomplete/cache/store';
import { BrokerModel } from 'common/data/broker/model';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { BrokerStoreServiceInterface } from 'common/service/broker-store/service.interface';

export class AutocompleteCacheBrokersStore extends AutocompleteCacheStore<BrokerStoreServiceInterface> {
  /**
   * @inheritDoc
   */
  protected endpoint: string = '/autocomplete/broker';

  /**
   * @inheritDoc
   */
  protected checksumKey: string = AutocompleteCacheChecksumKeysEnum.brokers;

  /**
   * @inheritDoc
   */
  protected dataKey: DatabaseTableEnum = DatabaseTableEnum.autocompleteBrokers;

  /**
   * @inheritDoc
   */
  public getCache(): BrokerModel[] {
    return this.dataStore.getAll();
  }

  /**
   * @inheritDoc
   */
  protected resetDataStore(): void {
    this.dataStore.reset();
  }

  /**
   * @inheritDoc
   */
  protected initializeDataStore(payload: HttpApiResponseInterface): void {
    this.dataStore.initialize({
      payload: payload.data,
    });
  }

  /**
   * @inheritDoc
   */
  protected addToDataStore(models: BrokerModel[]): void {
    this.dataStore.initialize({
      models,
    });
  }
}
