import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { ChangePasswordModel } from 'common/data/change-password/model';
import { UserAuthenticationStore } from 'common/data/user/authentication/store';
import { UserAutoRegisterModel } from 'common/data/user/auto-register/model';
import { UserEmailOtpResendModel } from 'common/data/user/email-otp-resend/model';
import { UserEmailVerificationModel } from 'common/data/user/email-verification/model';
import { UserExistsResponseModel } from 'common/data/user/exists/response-model';
import { UserLoginModel } from 'common/data/user/login/model';
import { UserPhoneNumberLoginModel } from 'common/data/user/phone-number-login/model';
import { UserRegisterModel } from 'common/data/user/register/model';
import { UserResetPasswordModel } from 'common/data/user/reset-password/model';
import { UserUpdateModel } from 'common/data/user/update/model';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { UserAuthenticationStoreServiceInterface } from 'common/service/user-authentication-store/service.interface';

const service = new UserAuthenticationStore(
  ApiEndpointService(),
  ApiService(),
  JsonApiStoreFactory<UserLoginModel>(UserLoginModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserRegisterModel>(UserRegisterModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserResetPasswordModel>(UserResetPasswordModel.JSONAPI_TYPE),
  JsonApiStoreFactory<ChangePasswordModel>(ChangePasswordModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserAutoRegisterModel>(UserAutoRegisterModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserExistsResponseModel>(UserExistsResponseModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserUpdateModel>(UserUpdateModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserPhoneNumberLoginModel>(UserPhoneNumberLoginModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserEmailOtpResendModel>(UserEmailOtpResendModel.JSONAPI_TYPE),
  JsonApiStoreFactory<UserEmailVerificationModel>(UserEmailVerificationModel.JSONAPI_TYPE)
);

export function UserAuthenticationStoreService(): UserAuthenticationStoreServiceInterface {
  return service;
}
