import { BackendConfigEnvInterface } from 'common/data/backend/config/env.interface';
import { BackendConfigEnvNameEnum } from 'common/data/backend/config/env-name.enum';
import { BackendConfigEnvPlatformEnum } from 'common/data/backend/config/env-platform.enum';

/**
 * Default environment configuration
 */
export const BackendConfigEnvDefault: BackendConfigEnvInterface = {
  name: BackendConfigEnvNameEnum.development,
  platform: BackendConfigEnvPlatformEnum.desktop,
  managerUrl: '',
  buildNumber: '',
  userAccountDashboardUrl: '',
  isChrome: false,
  isSafari: false,
};
