export const enum MyAccountStatechartStateEnum {
  iddle = 'iddle',
  sorting = 'sorting',
  filtering = 'filtering',
  unsaving = 'unsaving',
  deleting = 'deleting',
  /**
   * Deleting user note
   */
  deletingNote = 'deletingNote',
  deletingAll = 'deletingAll',
  fetchingSavedProperties = 'fetchingSavedProperties',
  fetchingContactedProperties = 'fetchingContactedProperties',
  fetchingNotesProperties = 'fetchingNotesProperties',
  /**
   * Fetching user notes
   */
  fetchingNotes = 'fetchingNotes',
  showingConfirmationPopup = 'showing-confirmation-popup',
}
