import { DataKeyValueStringInterface } from 'pf-frontend-common/dist/module/data/key-value/string.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/dist/module/http/api-response.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { LocationModel } from 'common/data/location/model';
import { LocationStoreEvent } from 'common/data/location/store.event';
import { LocationStoreFetchAllInputInterface } from 'common/data/location/store/fetch-all/input.interface';
import { LocationStoreGetAllByNameInputInterface } from 'common/data/location/store/get-all-by-name/input.interface';
import { LocationStoreOptionsInterface } from 'common/data/location/store-options.interface';
import { stringEscape } from 'common/helper/string/escape';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { LocationStoreServiceInterface } from 'common/service/location-store/service.interface';

export class LocationStore implements LocationStoreServiceInterface {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    fetch: {
      path: this.apiEndpointService.getPath('/location'),
      method: 'GET',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private apiService: ApiServiceInterface,
    private eventEmitter: EventEmitterInterface,
    private jsonApiStore: JsonApiStore<LocationModel>,
    private apiEndpointService: ApiEndpointServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: LocationStoreOptionsInterface): void {
    // From models
    if (options.models) {
      options.models.forEach((locationModel) => this.jsonApiStore.add(locationModel));
    }

    // From JsonApi payload
    if (options.payload) {
      this.jsonApiStore.syncWithMeta(options.payload);
    }

    this.eventEmitter.emit(LocationStoreEvent.updated);
  }

  /**
   * @inheritDoc
   */
  public add(location: LocationModel): void {
    this.jsonApiStore.add(location);
  }

  /**
   * @inheritDoc
   */
  public reset(): void {
    this.jsonApiStore.reset();
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public loadOneById(id: string): Promise<LocationModel> {
    // Emit event: start
    this.eventEmitter.emit(LocationStoreEvent.loadOneByIdStart, id);

    return new Promise((resolve, reject) => {
      // Get existing location (no API call in location store)
      const locationModel = this.getOneById(id);

      // Location not found
      if (!locationModel) {
        // Emit event: failure
        this.eventEmitter.emit(LocationStoreEvent.loadOneByIdFailure, id);

        // Reject promise
        reject();
        return;
      }

      // Emit event
      this.eventEmitter.emit(LocationStoreEvent.loadOneByIdSuccess, locationModel);

      // Resolve promise
      resolve(locationModel);
    });
  }

  /**
   * @inheritDoc
   */
  public getOneById(id: string): LocationModel | null {
    return this.jsonApiStore.find(id);
  }

  /**
   * @inheritDoc
   */
  public getAll(): LocationModel[] {
    return this.jsonApiStore.findAll();
  }

  /**
   * @inheritDoc
   */
  public getAllByName(input: LocationStoreGetAllByNameInputInterface): LocationModel[] {
    // Clear all brackets and trim the string
    const name = stringEscape(input.name.replace(/\(|\)/g, '')).trim();

    // Allow to find name which start from latter or brackets like: "Tecom" or "(Tecom", result will be the same
    const regexp = new RegExp(`\\s\\(*${name}[^\\s]*|^\\(*${name}[^\\s]*`, 'gi');

    // No name provided
    if (!name) {
      return [];
    }

    // Filter loaded locations
    const locationModels = this.getAll().filter((locationModel) => {
      const searchString = [locationModel.abbreviation || '', locationModel.name].join(' ').trim();

      return searchString.match(regexp);
    });

    // Limit results
    return input.limit ? locationModels.slice(0, input.limit) : locationModels;
  }

  /**
   * @inheritdoc
   */
  public fetchAll(input: LocationStoreFetchAllInputInterface): Promise<LocationModel[]> {
    return this.apiService
      .request(this.endpoint.fetch.method, this.endpoint.fetch.path, input)
      .then(this.onFetchAllSucceeded);
  }

  /**
   * Locations fetched successfully
   */
  private onFetchAllSucceeded = (response: HttpApiResponseInterface): LocationModel[] => {
    this.jsonApiStore.reset();
    this.jsonApiStore.syncWithMeta(response.data);

    return this.jsonApiStore.findAll();
  };
}
