import { Component, h } from 'preact';

import { functionNoop } from 'common/helper/function/noop';
import { objectCompare } from 'common/helper/object/compare';
import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { PriceSelectorComponentPropsInterface } from 'common/module/price-selector/component-props.interface';
import { PriceSelectorTemplate } from 'common/module/price-selector/template';

import { FilterParamsEnum } from '../filter/params.enum';
import { PriceSelectorValueInterface } from './value.interface';

export class PriceSelectorComponent extends Component<PriceSelectorComponentPropsInterface> {
  /**
   * @inheritDoc
   */
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    onChange: functionNoop,
    onClear: functionNoop,
    onDropdownOpenStatusChange: functionNoop,
    currencyCode: 'AED',
  };

  /**
   * translation key to get related translations
   */
  public static readonly translationKey: string = 'pricePeriod';

  /**
   * default price period value
   */
  private defaultSelectionValue: PriceSelectorValueInterface;

  /**
   * @inheritdoc
   */
  constructor(props: PriceSelectorComponentPropsInterface) {
    super(props);

    const defaultFilterParams = getFilterDefaultParams();
    this.defaultSelectionValue = {
      min: defaultFilterParams[FilterParamsEnum.minPrice],
      max: defaultFilterParams[FilterParamsEnum.maxPrice],
      period: defaultFilterParams[FilterParamsEnum.pricePeriod],
    };
  }

  /**
   * @inheritdoc
   */
  public render(): preact.JSX.Element {
    const priceOptionChoices = this.props.priceTypes.map((type) => ({
      text: type.label,
      value: type.value,
    }));

    return (
      <PriceSelectorTemplate
        onChange={this.props.onChange}
        onClear={this.onClear}
        value={this.props.value}
        priceOptions={this.props.priceOptions}
        activeCategoryId={this.props.activeCategoryId}
        currencyCode={this.props.currencyCode}
        pricePeriodChoices={priceOptionChoices}
        className={this.props.className}
        showClear={
          !objectCompare(
            {
              min: this.defaultSelectionValue.min,
              max: this.defaultSelectionValue.max,
            },
            {
              min: this.props.value.min,
              max: this.props.value.max,
            }
          )
        }
        onDropdownOpenStatusChange={this.props.onDropdownOpenStatusChange}
        style={this.props.style}
        dropdownSize={this.props.dropdownSize}
      />
    );
  }

  /**
   * resets values
   */
  private onClear = (): void => {
    this.props.onChange(this.defaultSelectionValue, null);
  };
}
