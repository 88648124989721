import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { BrowserStorageLocalServiceInterface } from 'pf-frontend-common/src/service/browser-storage-local/service.interface';

import { AutocompleteCacheLocationsStore } from 'common/data/autocomplete/cache/locations/store';
import { LocationModel } from 'common/data/location/model';
import { LocationAutocompleteHistoryServiceInterface } from 'common/service/location-autocomplete-history/service.interface';
import { LocationStoreServiceInterface } from 'common/service/location-store/service.interface';

import { LocationAutocompleteHistoryAddConfigInterface } from './add-config.interface';

export class LocationAutocompleteHistoryStore implements LocationAutocompleteHistoryServiceInterface {
  /**
   * Local storage key
   */
  private storageKey: string = 'location-autocomplete-history';

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private browserStorageLocalService: BrowserStorageLocalServiceInterface,
    private autocompleteCacheStore: AutocompleteCacheLocationsStore,
    private locationStoreService: LocationStoreServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public getStorage(): string[] {
    let storage: string[] = [];

    // Try to get history from local storage
    try {
      storage = JSON.parse(<string>this.browserStorageLocalService.getData(this.storageKey)).split(',');
    } catch (e) {
      storage = [];
    }

    return storage;
  }

  /**
   * @inheritDoc
   */
  public getLocations(): LocationModel[] {
    // Sync location store service with data from history
    this.locationStoreService.initialize({
      models: this.autocompleteCacheStore.getCache(),
    });

    return this.getStorage().reduce((result, locationId) => {
      const location = this.locationStoreService.getOneById(locationId);

      if (location) {
        result.push(location);
      }

      return result;
    }, []);
  }

  /**
   * @inheritDoc
   */
  public add(model: LocationModel, conf?: LocationAutocompleteHistoryAddConfigInterface): void {
    const storage: string[] = this.getStorage();

    // If history already has max number of items then remove last one
    if (storage.length === (conf?.maxHistoryLength || 10)) {
      storage.pop();
    }

    // If item already exists in history then push it up
    if (storage.indexOf(model.id) !== -1) {
      storage.splice(storage.indexOf(model.id), 1);
    }

    // Place new item at beginning of history
    storage.unshift(model.id);

    this.browserStorageLocalService.setData(this.storageKey, JSON.stringify(storage.join(',')));
  }
}
