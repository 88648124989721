/**
 * Returns snowplow user id from cookie
 *
 * @see https://github.com/snowplow/snowplow/wiki/1-General-parameters-for-the-Javascript-tracker#state
 */
export const snowplowGetUserId = (cookieName: string = '_sp_') => {
  const matcher = new RegExp(cookieName + 'id\\.[a-f0-9]+=([^;]+);?');
  const match = document.cookie.match(matcher);

  if (match && match[1]) {
    return match[1].split('.')[0];
  }

  return null;
};
