import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from 'common/module/stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { StatsTealiumWindowInterface } from 'common/module/stats/tealium/window.interface';

export class HomePageListWithUsTracker {
  constructor(private statsTealium: StatsTealiumProviderInterface, private window: StatsTealiumWindowInterface) {}

  public onClickListWithUs = (): void => {
    this.statsTealium.send({
      sendToGa: true,
      event_category: StatsTealiumDataLayerEventCategoryEnum.agentHub,
      event_action: StatsTealiumDataLayerEventActionEnum.listWithUs,
      event_label: this.window.tealium.page_type,
      tealium_event: StatsTealiumDataLayerTealiumEventEnum.agentHubLinks,
    });
  };
}
