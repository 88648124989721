import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { SaveSearchFiltersInterface } from './filters.interface';
import { SaveSearchFrequencyEnum } from './frequency.enum';

export class SaveSearchModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'saved_search';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = SaveSearchModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof SaveSearchModel> = ['name', 'frequency', 'filters', 'formatted_filters'];
  /**
   * Name of the saved search
   */
  public name: string;
  /**
   * Frequency to which email will be sent: Weekly | daily | off
   */
  public frequency: SaveSearchFrequencyEnum;
  /**
   * Filters in use for the current saved search
   */
  public filters: SaveSearchFiltersInterface;

  public created_at: string;

  /**
   * Formatted name of the search according to selected filters
   */
  // tslint:disable-next-line
  public formatted_filters: string;
}
