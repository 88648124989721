export const enum MyAccountStatechartEvent {
  fetchSavedProperties = 'fetchSavedProperties',
  fetchContactedProperties = 'fetchContactedProperties',
  fetchNotesProperties = 'fetchNotesProperties',
  /**
   * Fetch user notes succeeded
   */
  fetchNotesSuccess = 'fetchNotesSuccess',

  /**
   * Fetch notes properties succeeded
   */
  fetchNotesPropertiesSuccess = 'fetchingNotesPropertiesSuccess',

  fetchPropertiesSuccess = 'fetch-properties-success',
  unsave = 'unsave',
  unsaveSuccess = 'unsaveSuccess',
  delete = 'delete',
  /**
   * Delete note
   */
  deleteNote = 'deleteNote',
  deleteSuccess = 'deleteSuccess',
  deleteAll = 'deleteAll',
  clearAll = 'clearAll',
  deleteAllSuccess = 'deleteAllSuccess',
  clearingSuccess = 'clearingSuccess',
  /**
   * Filter
   */
  filter = 'filter',
  filterSuccess = 'filter-success',
  sort = 'sort',
  sortSuccess = 'sort-success',
  rejectDeleting = 'reject-deleting',
  approveDeleting = 'approve-deleting',

  /**
   * Ask to refresh property list with new items
   */
  refreshList = 'refreshList',
}
