export const enum BackendRouteEnum {
  /**
   * Saved properties route
   */
  savedProperties = 'user_account_saved_properties',

  /**
   * Contacted properties route
   */
  contactedProperties = 'user_account_contacted_properties',

  /**
   * Saved searches route
   */
  savedSearches = 'user_account_saved_searches',
}
