import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { EventEmitterAwareInterface } from 'pf-frontend-common/src/module/event/emitter-aware.interface';
import { BrowserDocumentServiceInterface } from 'pf-frontend-common/src/service/browser-document/service.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { configGetLanguage } from 'common/helper/config/get-language';
import { GoogleRecaptchaWindowInterface } from 'common/module/google/recaptcha/window.interface';

import { GoogleRecaptchaScriptEvent } from './script.event';

export class GoogleRecaptchaScript implements EventEmitterAwareInterface {
  /**
   * Is recaptcha ready for usage ?
   */
  protected isReady: boolean = false;

  /**
   * Is the script currently loading ?
   */
  protected isLoading: boolean = false;

  /**
   * Queue of Promise.resolve to call once the Recaptcha script will be ready
   */
  protected promiseQueue: Array<(success: boolean) => void> = [];

  /**
   * Constructor
   */
  constructor(
    protected eventEmitter: EventEmitterInterface,
    protected browserDocumentService: BrowserDocumentServiceInterface,
    protected windowService: WindowServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * Load Recaptcha script
   */
  public load(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      // Script already ready
      if (this.isReady) {
        resolve(true);
        return;
      }

      // Add resolve function to queue
      this.promiseQueue.push(resolve);

      // Script is loading
      if (this.isLoading) {
        return;
      }

      // Script is now loading
      this.isLoading = true;

      // Define global callback function
      const window = this.windowService.getNative();
      (<GoogleRecaptchaWindowInterface>window).onReadyGoogleRecaptcha = this.onReadyGoogleRecaptcha;

      this.importScript();
    });
  }

  /**
   * Returns the script "src" value
   */
  public getSrc(): string {
    const langCode = configGetLanguage().current;

    return `https://www.google.com/recaptcha/api.js?render=explicit&onload=onReadyGoogleRecaptcha&hl=${langCode}`;
  }

  /**
   * Google Recaptcha ready for usage
   */
  protected onReadyGoogleRecaptcha = (): void => {
    this.isReady = true;
    this.isLoading = false;

    // Call every promise.resolve in queue
    this.promiseQueue.forEach((resolve) => resolve(true));

    this.eventEmitter.emit(GoogleRecaptchaScriptEvent.ready);
  };

  /**
   * Import script
   */
  private importScript(): void {
    this.browserDocumentService.importScript(this.getSrc()).then(() => {
      this.eventEmitter.emit(GoogleRecaptchaScriptEvent.load);
    });
  }
}
