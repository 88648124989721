import { CaptchaView } from 'common/module/captcha/view';
import { GoogleRecaptchaTemplate } from 'common/module/google/recaptcha/template';
import { GoogleRecaptchaViewStoreFactory } from 'common/module/google/recaptcha/view-store.factory';
import { PreactView } from 'common/module/preact/view';
import { CaptchaServiceInterface } from 'common/service/captcha/service.interface';

const service = new CaptchaView(new PreactView(GoogleRecaptchaTemplate, GoogleRecaptchaViewStoreFactory()));

service.initialize({
  element: document.querySelector('[data-qs=captcha]'),
});

export const CaptchaService = (): CaptchaServiceInterface => service;
