import { StatsDataPropertyInterface } from 'pf-frontend-common/src/module/stats/data/property.interface';
import { PropertyCompletionStatusType } from 'pf-frontend-common/src/module/stats/types';

import { BackendConfigCountryInterface } from 'common/data/backend/config/country.interface';
import { PropertyModel } from 'common/data/property/model';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';

export class PropertyStatsDataAdapter {
  /**
   * Listing display label for gtm tracking
   */
  private readonly AREA_SPECIALIST_PROPERTY_TEXT: string = 'Area Specialist Property';

  /**
   * Similar property near label for snowplow tracking
   */
  private readonly SIMILAR_PROPERTY_NEAR_TEXT: string = 'similar_properties_near';

  /**
   * Constructor
   */
  constructor(private countryConfig: BackendConfigCountryInterface) {}

  /**
   * Return an property model as property stats data
   *
   * @param {PropertyModel} propertyModel
   * @param {localContext} localContext
   */
  public getData(
    propertyModel: PropertyModel,
    localContext: { position?: number; positionPage?: number; propertySimilar?: boolean } = {}
  ): StatsDataPropertyInterface {
    const { propertySimilar = false, position, positionPage } = localContext;

    // Property type not set
    if (!propertyModel.property_type) {
      // eslint-disable-next-line no-console
      console.error('PropertyModel.property_type is not set, did you include it in your JSONAPI request ?');
    }

    // Location tree not set
    if (!propertyModel.location_tree) {
      // eslint-disable-next-line no-console
      console.error('PropertyModel.location_tree is not set, did you include it in your JSONAPI request ?');
    }

    return {
      id: parseInt(propertyModel.id, 10),
      referenceNumber: propertyModel.reference,
      bedrooms: String(propertyModel.bedroom_value),
      bathrooms: String(propertyModel.bathroom_value),
      agent: {
        id: propertyModel.agent && parseInt(propertyModel.agent.id, 10),
        name: propertyModel.agent && propertyModel.agent.name,
        isVerified: propertyModel.agent && propertyModel.agent.verification_status === 'verified',
      },
      broker: {
        id: propertyModel.broker && parseInt(propertyModel.broker.id, 10),
        name: propertyModel.broker && propertyModel.broker.name,
      },
      title: propertyModel.name,
      url: propertyModel.links.self,
      image: propertyModel.links.image_property,
      licenseRera: propertyModel.rera,
      offeringType: propertyModel.category_identifier,
      housingType: propertyModel.type_identifier,
      housingTypeId: Number(propertyModel.property_type?.id),
      listingStatus: propertyModel.listing_level,
      listingStatusDisplayed: this.getListingStatusDisplayed(propertyModel, propertySimilar),
      rentalPeriod: this.isRental(propertyModel.category_identifier) ? propertyModel.price_period_label : '',
      rentalPeriodId: this.isRental(propertyModel.category_identifier) ? propertyModel.price_period_identifier : '',
      size: propertyModel.size,
      agentUserId: (propertyModel.agent && propertyModel.agent.user_id) || 0,
      verified: propertyModel.verified ? 'Verified' : 'Not verified',
      qs: propertyModel.quality_score,
      rsp: propertyModel.rsp,
      rss: propertyModel.rss,
      furnished: propertyModel.furnished,
      images: propertyModel.meta.images_count,
      locations: (propertyModel.location_tree || []).map((location) => parseInt(location.id, 10)),
      getPriceOnApp: !!propertyModel.price_on_application,
      price: propertyModel.meta.price,
      priceTxt: propertyModel.meta.price_text,
      currency: this.countryConfig.currencyCode,
      subtitle: propertyModel.meta.subtitle,
      mortgageRent: this.countryConfig.propertyDetails.mortgageRent,
      moveSouq: this.countryConfig.propertyDetails.moversServices,
      position: position || 0,
      positionPage: positionPage || position || 0,
      isSmartAd: propertyModel.smart_ad ? 1 : 0,
      isCtsProperty: propertyModel.cts ? 1 : 0,
      isAreaSpecialistProperty: propertyModel.areaSpecialistProperty ? 1 : 0,
      completionStatus: this.getCompletionStatus(propertyModel.completion_status),
      isExclusive: propertyModel.exclusive,
      has360View: !!propertyModel.view_360,
      newProjects: !!propertyModel.new_projects,
      leadValue: propertyModel.lead_value,
    } as StatsDataPropertyInterface;
  }

  /**
   * Get the completion status type based on the property model completion status input
   */
  private getCompletionStatus(completionStatus: string): PropertyCompletionStatusType {
    // Back end will return either 'off_plan', off plan', 'completed' or NULL
    const completionStatusMapping: {
      [key: string]: PropertyCompletionStatusType;
    } = {
      off_plan: 'off_plan',
      'off plan': 'off_plan',
      completed: 'completed',
    };

    return completionStatusMapping[completionStatus] || null;
  }

  /**
   * Is the category identifier a rental category ?
   */
  public isRental(category: PropertyCategoryIdentifierEnum): boolean {
    const rentalCategories = [
      PropertyCategoryIdentifierEnum.residentialForRent,
      PropertyCategoryIdentifierEnum.commercialForRent,
    ];

    return rentalCategories.indexOf(category) > -1;
  }

  /**
   * Get listing status displayed value
   * In gtm impression dimension6 value would be listing_level_label or area specialist property
   */
  private getListingStatusDisplayed(property: PropertyModel, isSimilarPropertyNear: boolean = false): string {
    if (property.areaSpecialistProperty) {
      return this.AREA_SPECIALIST_PROPERTY_TEXT;
    }

    if (isSimilarPropertyNear) {
      return this.SIMILAR_PROPERTY_NEAR_TEXT;
    }

    return property.listing_level_label;
  }
}
