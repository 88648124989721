import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';
import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { DomElementEvent } from 'pf-frontend-common/src/module/dom/element.event';
import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { ViewOptionsInterface } from 'pf-frontend-common/src/module/view/options.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { FooterHtmlElementInterface } from 'common/module/footer/html-element.interface';
import { FooterServiceInterface } from 'common/service/footer/service.interface';
import { StatsServiceInterface } from 'common/service/stats/service.interface';

import { StatsTealiumDataLayerEventActionEnum } from '../stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from '../stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerEventLabelEnum } from '../stats/tealium/data-layer/event-label.enum';
import { StatsTealiumDataLayerEventTypeEnum } from '../stats/tealium/data-layer/event-type.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from '../stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumProviderInterface } from '../stats/tealium/provider.interface';

export class FooterView implements ViewBasicInterface, FooterServiceInterface {
  /**
   * View's main HTML element
   */
  private element: HTMLElement;

  /**
   * CSS selectors
   */
  private cssSelector: DataKeyValueStringInterface = {
    clientLogin: '[data-qs="client-login"]',
    clientRegister: '[data-qs="client-register"]',
    downloadAppAndroid: '[data-qs="dl-app-android"]',
    downloadAppIos: '[data-qs="dl-app-ios"]',
    downloadApp: '[data-qs="dl-app"]',
    countrySelect: '[data-qs="country-select"]',
  };

  /**
   * View html elements
   */
  private htmlElement: FooterHtmlElementInterface;

  /**
   * Constructor
   */
  constructor(
    private statsService: StatsServiceInterface,
    private statsTealium: StatsTealiumProviderInterface,
    private windowService: WindowServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: ViewOptionsInterface): void {
    this.element = options.element;

    // Attach observers
    this.observers();
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    // Nothing special to render for now
  }

  /**
   * Attach observers
   */
  private observers(): void {
    const clientLogin: HTMLElement = domQuerySelector(this.element, this.cssSelector.clientLogin);
    const clientRegister: HTMLElement = domQuerySelector(this.element, this.cssSelector.clientRegister);
    const downloadAppAndroid: HTMLElement = domQuerySelector(this.element, this.cssSelector.downloadAppAndroid);
    const downloadAppIos: HTMLElement = domQuerySelector(this.element, this.cssSelector.downloadAppIos);
    const downloadApp: HTMLElement = domQuerySelector(this.element, this.cssSelector.downloadApp);

    // Client login listener
    if (clientLogin) {
      clientLogin.addEventListener(DomElementEvent.click, this.onClickClientLogin);
    }

    // Client register listener
    if (clientRegister) {
      clientRegister.addEventListener(DomElementEvent.click, this.onClickClientRegister);
    }

    // App download android listener
    if (downloadAppAndroid) {
      downloadAppAndroid.addEventListener(DomElementEvent.click, this.onClickDownloadAppAndroid);
    }

    // App download ios listener
    if (downloadAppIos) {
      downloadAppIos.addEventListener(DomElementEvent.click, this.onClickDownloadAppIos);
    }

    // App download listener
    if (downloadApp) {
      downloadApp.addEventListener(DomElementEvent.click, this.onClickDownloadApp);
    }

    this.htmlElement = {
      countrySelect: <HTMLSelectElement>domQuerySelector(this.element, this.cssSelector.countrySelect),
    };

    if (this.htmlElement.countrySelect) {
      this.htmlElement.countrySelect.addEventListener(DomElementEvent.change, this.onChangeCountrySelect);
    }
  }

  /**
   * Clicked on client login link
   */
  private onClickClientLogin = (): void => {
    // Log stats
    this.statsService.ctaClick({
      cta: {
        client: 'login',
      },
    });
  };

  /**
   * Clicked on client register link
   */
  private onClickClientRegister = (): void => {
    // Log stats
    this.statsService.ctaClick({
      cta: {
        client: 'register',
      },
    });
  };

  /**
   * Clicked on download app android link
   */
  private onClickDownloadAppAndroid = (): void => {
    // Log stats
    this.statsService.ctaClick({
      cta: {
        download: 'app-android',
      },
    });
    this.sendTealiumStats();
  };

  /**
   * Clicked on download app ios link
   */
  private onClickDownloadAppIos = (): void => {
    // Log stats
    this.statsService.ctaClick({
      cta: {
        download: 'app-ios',
      },
    });
    this.sendTealiumStats();
  };

  /**
   * Clicked on download app link
   */
  private onClickDownloadApp = (): void => {
    // Log stats
    this.statsService.ctaClick({
      cta: {
        download: 'app',
      },
    });
  };

  /**
   * Changed country select
   */
  private onChangeCountrySelect = (): void => {
    // Redirect to absolute href
    this.windowService.getNative().location.href = this.htmlElement.countrySelect.value;
  };

  /**
   * send tealium stats to service
   */
  private sendTealiumStats = (): void => {
    this.statsTealium.send({
      tealium_event: StatsTealiumDataLayerTealiumEventEnum.appDownload,
      event_type: StatsTealiumDataLayerEventTypeEnum.click,
      event_category: StatsTealiumDataLayerEventCategoryEnum.productFeature,
      event_action: StatsTealiumDataLayerEventActionEnum.app,
      event_label: StatsTealiumDataLayerEventLabelEnum.download,
    });
  };
}
