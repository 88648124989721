import { domClassMerge } from '@propertyfinder/helpers';
import {
  IconDataguru,
  IconPropsInterface,
  IconSpriteLiveThick,
  IconSpriteShieldCheckmarkFilled,
  IconSpriteStarTrophyFilled,
} from '@propertyfinder/icons';
import { FunctionComponent, h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';

import { TagNewComponentPropsInterface } from './component-props.interface';
import { TagNewTypeEnum } from './type.enum';

export const TagNewComponent: FunctionComponent<TagNewComponentPropsInterface> = ({
  additionalText,
  className,
  type,
}) => {
  let Icon: FunctionComponent<IconPropsInterface> | null = null;
  let colorClass: string;
  let text: string = '';

  switch (type) {
    case TagNewTypeEnum.DATA_GURU:
      Icon = IconDataguru;
      colorClass = 'tag-new--data-guru';
      text = i18nTranslate('DataGuru_DataGuru');
      break;

    case TagNewTypeEnum.VERIFIED:
      Icon = IconSpriteShieldCheckmarkFilled;
      colorClass = 'tag-new--verified';
      text = i18nTranslate('Verified');
      break;

    case TagNewTypeEnum.SUPER_AGENT:
      Icon = IconSpriteStarTrophyFilled;
      colorClass = 'tag-new--super-agent';
      text = 'SuperAgent';
      break;

    case TagNewTypeEnum.LIVE_VIEWING:
      Icon = IconSpriteLiveThick;
      colorClass = 'tag-new--live-viewing';
      text = `${i18nTranslate('Live Viewing')} - ${additionalText}`;
      break;
    case TagNewTypeEnum.DEVELOPER:
      colorClass = 'tag-new--developer';
      text = i18nTranslate('Direct from developer');
      break;
    case TagNewTypeEnum.NEW_PROPERTY:
      colorClass = 'tag-new--new-property';
      text = i18nTranslate('new-listing');
      break;
    case TagNewTypeEnum.UNAVAILABLE:
      colorClass = 'tag-new--unavailable';
      text = i18nTranslate('Unavailable');
      break;
    case TagNewTypeEnum.COMMUNITY_EXPERT:
      colorClass = 'tag-new--community-expert';
      text = i18nTranslate('Community expert');
      break;
    case TagNewTypeEnum.UNDER_OFFER:
      colorClass = 'tag-new--under-offer';
      text = i18nTranslate('Tags_UnderOffer');
      break;
    case TagNewTypeEnum.SPOTLIGHT_LISTING:
      colorClass = 'tag-new--spotlight-listing';
      text = i18nTranslate('Tags_Spotlight');
      break;
    case TagNewTypeEnum.NEW_LISTING:
      colorClass = 'tag-new--first-sale';
      text = i18nTranslate('Tags_FirstSale');
      break;
  }

  return (
    <div className={domClassMerge('tag-new', colorClass, className)}>
      {Icon && <Icon className='tag-new__icon' />}
      {text}
    </div>
  );
};
