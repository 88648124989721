import { Googletag, SizeMappingBuilder, Slot, SlotOnloadEvent, SlotRenderEndedEvent } from 'Googletag';
import { BrowserWindowEvent } from 'pf-frontend-common/src/module/browser/window.event';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { BrowserDocumentServiceInterface } from 'pf-frontend-common/src/service/browser-document/service.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { BackendConfigAdsInterface } from 'common/data/backend/config/ads.interface';
import { BackendConfigEnvInterface } from 'common/data/backend/config/env.interface';
import { BackendConfigEnvDebugEnum } from 'common/data/backend/config/env-debug.enum';
import { AdSlotInterface } from 'common/module/ad/slot.interface';
import { AdStrategyEvent } from 'common/module/ad/strategy.event';
import { AdStrategyInterface } from 'common/module/ad/strategy.interface';
import { AdTargetingInterface } from 'common/module/ad/targeting.interface';
import { AdUnitInterface } from 'common/module/ad/unit.interface';
import { DfpAdStrategyOptionsInterface } from 'common/module/dfp/ad-strategy-options.interface';

import { asyncDebounce } from '../../helper/async/debounce';

export class DfpAdStrategy implements AdStrategyInterface {
  /**
   * Google publisher tag script path
   */
  private apiPath: string = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

  /**
   * DFP account id
   */
  private accountId: string;

  /**
   * DFP targeting
   */
  private targeting: AdTargetingInterface;

  /**
   * Googletag global object
   * using any because currently googletag has no typings
   *
   */
  private googletag: Googletag;

  /**
   * GPT ad slots
   * using any because currently googletag has no typings
   *
   */
  private adSlots: { [key: string]: Slot } = {};

  /**
   * GPT responsive ad slots
   * using any because currently googletag has no typings
   *
   */
  private responsiveAdSlots: { [key: string]: Slot } = {};

  /**
   * Ad queues
   */
  private adQueues: Array<Array<[string, AdUnitInterface]>> = [];

  /**
   * Debounced refresh of responsive ads on scroll
   */
  private refreshResponsiveAdsDebounced: () => void = asyncDebounce(this.refreshResponsiveAds.bind(this), 300);

  /**
   * Constructor
   */
  public constructor(
    private eventEmitter: EventEmitterInterface,
    private browserDocumentService: BrowserDocumentServiceInterface,
    private windowService: WindowServiceInterface,
    private ads: BackendConfigAdsInterface,
    private env: BackendConfigEnvInterface
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public initialize(options: DfpAdStrategyOptionsInterface): void {
    this.accountId = options.accountId;

    this.initAdUnits();

    this.initGoogletag();

    let loadDfpScript: () => void = () => this.browserDocumentService.importScript(this.apiPath, true);

    // Load gpt script
    if (this.env.debug) {
      if (this.env.debug.includes(BackendConfigEnvDebugEnum.dfp)) {
        loadDfpScript = (): void => null;
      } else if (this.env.debug.includes(BackendConfigEnvDebugEnum.delay_dfp)) {
        loadDfpScript = (): void =>
          this.windowService.getNative().addEventListener(BrowserWindowEvent.load, () => {
            this.browserDocumentService.importScript(this.apiPath, true);
          });
      }
    }

    loadDfpScript();

    this.windowService.getEventEmitter().addListener(BrowserWindowEvent.resize, this.onResizeWindow);
  }

  /**
   * @inheritDoc
   */
  public setTargeting(targeting: AdTargetingInterface): void {
    this.googletag.cmd.push(() => {
      // Clear current targeting
      if (this.targeting) {
        this.googletag.pubads().clearTargeting();
      }

      this.targeting = targeting;

      Object.keys(this.targeting).forEach((target) => {
        this.googletag.pubads().setTargeting(target, this.targeting[target]);
      });
    });
  }

  /**
   * @inheritDoc
   */
  public enableLazyLoad(): void {
    let lazyLoadConfig = { fetchMarginPercent: 50, renderMarginPercent: 50, mobileScaling: 2.0 };

    if (this.env.debug?.includes(BackendConfigEnvDebugEnum.delay_dfp)) {
      lazyLoadConfig = { fetchMarginPercent: 10, renderMarginPercent: 10, mobileScaling: 1 };
    }

    this.googletag.cmd.push(() => {
      this.googletag.pubads().enableLazyLoad(lazyLoadConfig);
    });
  }

  /**
   * @inheritDoc
   */
  public registerAdQueue(queueItems: Array<[string, AdUnitInterface]>): number {
    this.adQueues.push(queueItems);

    const adQueueId = this.adQueues.length - 1;

    this.adQueues[adQueueId].forEach((queueItem) => {
      this.registerAdUnit(queueItem[1], queueItem[0]);
    });

    return adQueueId;
  }

  /**
   * @inheritDoc
   */
  public renderAdQueue(queueId: number): void {
    if (!this.adQueues[queueId]) {
      return;
    }

    this.googletag.cmd.push(() => {
      const slots = this.adQueues[queueId].map((queueItem) => this.adSlots[queueItem[0]]);

      // Then display all of ads in ad queue
      this.googletag.pubads().refresh(slots);
    });
  }

  /**
   * @inheritDoc
   */
  public registerAdUnit(adUnit: AdUnitInterface, containerId: string): void {
    this.googletag.cmd.push(() => {
      this.createAdSlot(adUnit, containerId);
    });
  }

  /**
   * @inheritDoc
   */
  public renderAdUnit(adUnit: AdUnitInterface, containerId: string): void {
    this.googletag.cmd.push(() => {
      this.googletag.display(containerId);
    });
  }

  /**
   * @inheritDoc
   */
  public removeAdUnit(containerId: string): void {
    this.googletag.cmd.push(() => {
      if (!this.adSlots[containerId]) {
        return;
      }

      this.googletag.pubads().clear([this.adSlots[containerId]]);

      delete this.adSlots[containerId];
    });
  }

  /**
   * @inheritDoc
   */
  public refreshAllAds(): void {
    this.googletag.cmd.push(() => {
      // Refresh currently rendered ads
      this.googletag.pubads().refresh();
    });
  }

  /**
   * Create ad slot
   *
   */
  private createAdSlot(adUnit: AdUnitInterface, containerId: string): Slot {
    if (this.adSlots[containerId]) {
      return this.adSlots[containerId];
    }

    const isFluid = adUnit.size.length === 1 && adUnit.size[0].isFluid;

    const sizes = adUnit.size.map((adSize) => [adSize.width || 0, adSize.height || 0]);

    let sizeMapping: SizeMappingBuilder = null;

    adUnit.size.forEach((size) => {
      if (size.viewportWidth) {
        if (!sizeMapping) {
          sizeMapping = this.googletag.sizeMapping();
        }

        sizeMapping = sizeMapping.addSize([size.viewportWidth, 0], [size.width || 0, size.height || 0]);
      }
    });

    const slot = this.googletag.defineSlot(`/${this.accountId}/${adUnit.id}`, isFluid ? 'fluid' : sizes, containerId);

    if (sizeMapping) {
      slot.defineSizeMapping(sizeMapping.build());
      this.responsiveAdSlots[containerId] = slot;
    }

    slot.addService(this.googletag.pubads());

    this.adSlots[containerId] = slot;

    return slot;
  }

  /**
   * Init ad units from backend
   */
  private initAdUnits(): void {
    // using any here in order to add googletag property to window object
    const nativeWindow = <any>this.windowService.getNative();

    nativeWindow.googletag = this.googletag = nativeWindow.googletag || {};
    this.googletag.cmd = this.googletag.cmd || [];

    this.ads.ad_units.forEach((adUnit) => this.registerAdUnit(adUnit, adUnit.id));
  }

  /**
   * Initialize googletag global object
   */
  private initGoogletag(): void {
    // preconfigure all add slots and enable services
    this.googletag.cmd.push(() => {
      this.googletag.pubads().addEventListener('slotRenderEnded', this.onSlotRenderEnded);
      this.googletag.pubads().addEventListener('slotOnload', this.onSlotLoad);
      this.googletag.pubads().collapseEmptyDivs();
      this.googletag.pubads().enableSingleRequest();

      this.googletag.enableServices();
    });
  }

  /**
   * Refresh responsive ads
   */
  private refreshResponsiveAds(): void {
    this.googletag.cmd.push(() => {
      const responsiveAds = Object.keys(this.responsiveAdSlots).map((id) => this.responsiveAdSlots[id]);

      if (!responsiveAds.length) {
        return;
      }

      this.googletag.pubads().refresh(responsiveAds);
    });
  }

  /**
   * Slot render ended
   *
   */
  private onSlotRenderEnded = (event: SlotRenderEndedEvent): void => {
    const renderedSlot: AdSlotInterface = {
      id: event.slot.getSlotElementId(),
      isEmpty: event.isEmpty,
    };

    this.getEventEmitter().emit(AdStrategyEvent.slotRenderFinished, renderedSlot);
  };

  /**
   * Slot loaded
   *
   */
  private onSlotLoad = (event: SlotOnloadEvent): void => {
    const slot: AdSlotInterface = {
      id: event.slot.getSlotElementId(),
      isEmpty: event.isEmpty,
    };

    this.getEventEmitter().emit(AdStrategyEvent.slotLoaded, slot);
  };

  /**
   * Window resized
   */
  private onResizeWindow = () => {
    this.refreshResponsiveAdsDebounced();
  };
}
