import { FunctionComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { LanguageContext } from 'common/module/language/context';
import { priceSelectorComputeText } from 'common/module/price-selector/compute-text';
import { PriceSelectorTemplatePropsInterface } from 'common/module/price-selector/template-props.interface';
import { ChipChoiceComponent } from 'library/chip-choice/component';
import { RangeSelectorComponent } from 'library/range-selector/component';
import { RangeSelectorEditTypeEnum } from 'library/range-selector/edit-type.enum';
import { RangeSelectorValueInterface } from 'library/range-selector/value.interface';

export const PriceSelectorTemplate: FunctionComponent<PriceSelectorTemplatePropsInterface> = (
  props: PriceSelectorTemplatePropsInterface
) => {
  const { isRtl } = useContext(LanguageContext);

  return (
    <div style={props.style} className={['price-selector'].concat(props.className || '').join(' ')}>
      <RangeSelectorComponent
        inputContainerClassName='price-selector__input-container '
        label={i18nTranslate('Price')}
        rtl={isRtl}
        text={priceSelectorComputeText(
          {
            value: props.value,
            currencyCode: props.currencyCode,
            activeCategoryId: props.activeCategoryId,
          },
          isRtl
        )}
        value={props.value}
        options={props.priceOptions.map((option) => option.value)}
        showClear={props.showClear}
        min={{
          placeholder: i18nTranslate(`Min Price ({currency})`).replace('{currency}', i18nTranslate(props.currencyCode)),
        }}
        max={{
          placeholder: i18nTranslate(`Max Price ({currency})`).replace('{currency}', i18nTranslate(props.currencyCode)),
        }}
        onChange={(value: RangeSelectorValueInterface, editType: RangeSelectorEditTypeEnum) => {
          props.onChange(
            {
              min: value.min.replace(/,/g, ''),
              max: value.max.replace(/,/g, ''),
              period: props.value.period,
            },
            editType
          );
        }}
        onClear={props.onClear}
        onDropdownOpenStatusChange={props.onDropdownOpenStatusChange}
        dropdownSize={props.dropdownSize}
      >
        {props.pricePeriodChoices.length ? (
          <div className='price-selector__field'>
            <p className='price-selector__field-title'>{i18nTranslate('Rental Period')}</p>
            <ChipChoiceComponent
              name='price-period'
              containerClassName='price-selector__field-choices'
              options={props.pricePeriodChoices}
              selected={props.value.period}
              onCheck={(_, selectedOption) => {
                props.onChange(
                  {
                    min: '',
                    max: '',
                    period: selectedOption.value,
                  },
                  null
                );
              }}
            />
          </div>
        ) : null}
      </RangeSelectorComponent>
    </div>
  );
};
