/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { EventEmitterAwareInterface } from 'pf-frontend-common/src/module/event/emitter-aware.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { AuthenticationProviderType } from 'pf-frontend-common/src/module/stats/types';
import { BrowserStorageLocalServiceInterface } from 'pf-frontend-common/src/service/browser-storage-local/service.interface';
import { StatsContexterServiceInterface } from 'pf-frontend-common/src/service/stats-contexter/service.interface';
import { StatsDataServiceInterface } from 'pf-frontend-common/src/service/stats-data/service.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { ChangePasswordModel } from 'common/data/change-password/model';
import { UserAutoRegisterModel } from 'common/data/user/auto-register/model';
import { UserChangePasswordModel } from 'common/data/user/change-password/model';
import { UserEmailOtpResendModel } from 'common/data/user/email-otp-resend/model';
import { UserEmailVerificationModel } from 'common/data/user/email-verification/model';
import { UserExistsResponseModel } from 'common/data/user/exists/response-model';
import { UserFrontendLoginEvent } from 'common/data/user/frontend/login.event';
import { UserFrontendLoginModel } from 'common/data/user/frontend/login/model';
import { UserFrontendLoginProvidersEnum } from 'common/data/user/frontend/login/providers.enum';
import { UserLoginModel } from 'common/data/user/login/model';
import { UserModel } from 'common/data/user/model';
import { UserPhoneNumberLoginModel } from 'common/data/user/phone-number-login/model';
import { UserRegisterModel } from 'common/data/user/register/model';
import { UserResetPasswordModel } from 'common/data/user/reset-password/model';
import { UserStatsDataAdapter } from 'common/data/user/stats/data-adapter';
import { UserUpdateModel } from 'common/data/user/update/model';
import { abTestsIsVariant } from 'common/helper/ab-tests/is-variant';
import { appBannerIsVisible } from 'common/helper/app-banner/is-visible';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { ApiErrorInterface } from 'common/module/api/error.interface';
import { UserAuthFieldErrorsInterface } from 'common/module/user/auth/field-errors.interface';
import { UserAuthSmsCountriesInterface } from 'common/module/user/auth/sms-countries/interface';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { UserChangePasswordFieldErrorsInterface } from 'common/module/user/change-password/field-errors.interface';
import { UserForgotPasswordFieldErrorsInterface } from 'common/module/user/forgot-password/field-errors.interface';
import { UserRegistrationFieldErrorsInterface } from 'common/module/user/registration/field-errors.interface';
import { UserSignInFieldErrorsInterface } from 'common/module/user/sign-in/field-errors.interface';
import { JwtTokenServiceEvent } from 'common/service/jwt-token/service.event';
import { JwtTokenServiceInterface } from 'common/service/jwt-token/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';
import { UserAuthenticationStoreServiceInterface } from 'common/service/user-authentication-store/service.interface';
import { UserFrontendLoginServiceInterface } from 'common/service/user-frontend-login/service.interface';

import { PRIVACY_PROMPT } from '../privacy-prompt/constant';

// eslint-disable-next-line @propertyfinder/rules/export-name-validation
export class UserAuthentication implements UserAuthenticationServiceInterface, EventEmitterAwareInterface {
  /**
   * User key in browser storage
   */
  private readonly userKey: string = 'user-authentication-user';

  /**
   * User authentication provider key in browser storage
   */
  private readonly authenticationProviderKey: string = 'user-authentication-provider';

  private regExp = /\.|\//;

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private userAuthenticationStoreService: UserAuthenticationStoreServiceInterface,
    private browserStorageLocal: BrowserStorageLocalServiceInterface,
    private userFrontendLoginService: UserFrontendLoginServiceInterface,
    private jwtTokenService: JwtTokenServiceInterface,
    private statsContexterService: StatsContexterServiceInterface,
    private userStatsDataAdapter: UserStatsDataAdapter,
    private statsDataService: StatsDataServiceInterface,
    private windowService: WindowServiceInterface
  ) {
    this.userFrontendLoginService
      .getEventEmitter()
      .addListener(UserFrontendLoginEvent.signInFailed, this.onSignInFailedUserFrontendLogin);

    this.userFrontendLoginService
      .getEventEmitter()
      .addListener(UserFrontendLoginEvent.signInSucceeded, this.onSignInSucceededUserFrontendLogin);

    this.userFrontendLoginService
      .getEventEmitter()
      .addListener(UserFrontendLoginEvent.signInStopped, this.onSignInStoppedUserFrontendLogin);

    this.jwtTokenService
      .getEventEmitter()
      .addListener(JwtTokenServiceEvent.refreshTokenFailed, this.onRefreshTokenFailed);

    this.jwtTokenService.getEventEmitter().addListener(JwtTokenServiceEvent.tokenReset, this.onTokenReset);
  }

  /**
   * Initialize instance
   */
  public initialize(): void {
    if (!this.getUser()) {
      const pathname = this.windowService.getNative().location.pathname;
      const isHomePage = pathname === '/' || pathname === '/ar/';

      if (abTestsIsVariant('test128', 'variantA')) {
        if (this.browserStorageLocal.getData(PRIVACY_PROMPT) === 'true') {
          this.userFrontendLoginService.initialize();
        }
      } else if (abTestsIsVariant('test143') && isHomePage) {
        // if the banner is not visible then initialize the Google tap
        if (!appBannerIsVisible(this.browserStorageLocal)) {
          this.userFrontendLoginService.initialize();
        }
      } else {
        this.userFrontendLoginService.initialize();
      }
    }

    // Update the stats context with the current user
    this.updateStatsContext(this.getUser());

    const token = this.getToken();

    // Remove user if there is no token in system
    if (!token) {
      this.setUser(null);
      this.setProvider(null);

      return null;
    }
  }

  /**
   * @inheritDoc
   */
  public register(model: UserRegisterModel, isNewModal: boolean = false): void {
    const registerResult = this.userAuthenticationStoreService.register(model, isNewModal);

    registerResult.then(this.onRegistrationResolved);
    registerResult.catch((response: HttpApiResponseInterface) => {
      this.onRegistrationRejected(response, isNewModal);
    });
  }

  /**
   * @inheritDoc
   */
  public autoRegister(model: UserAutoRegisterModel): void {
    const registerResult = this.userAuthenticationStoreService.autoRegister(model);

    registerResult.then(this.onAutoRegistrationResolved);
    registerResult.catch(this.onAutoRegistrationRejected);
  }

  /**
   * @inheritDoc
   */
  public updateUser(model: UserUpdateModel, provider?: UserFrontendLoginProvidersEnum): void {
    this.userAuthenticationStoreService
      .updateUser(model, this.getToken())
      .then(this.onUpdateUserResolved(provider))
      .catch(this.onUpdateUserRejected);
  }

  /**
   * @inheritDoc
   */
  public signIn(model: UserLoginModel, isNewModal: boolean = false): void {
    const authResult = this.userAuthenticationStoreService.signIn(model, isNewModal);

    authResult.then(this.onSignInResolved);
    authResult.catch((response: HttpApiResponseInterface) => {
      this.onSignInRejected(response, isNewModal);
    });
  }

  public otpRequest(phoneNumber: string, captcha?: string): void {
    const result = this.userAuthenticationStoreService.otpRequest(phoneNumber, captcha);

    result.then(() => this.getEventEmitter().emit(UserAuthenticationEvent.otpIsSent));
    result.catch((response: HttpApiResponseInterface) => {
      this.onAuthenticatePhoneNumberRejected(response);
    });
  }

  public authenticatePhoneNumber(modal: UserPhoneNumberLoginModel): void {
    const result = this.userAuthenticationStoreService.phoneLoginAuthenticate(modal);

    result.then(this.onAuthenticatePhoneNumberResolved);
    result.catch((response: HttpApiResponseInterface) => {
      this.onAuthenticatePhoneNumberRejected(response);
    });
  }

  public emailOtpResend(credentials: UserEmailOtpResendModel): void {
    const result = this.userAuthenticationStoreService.emailOtpResend(credentials);

    result.then(() => this.getEventEmitter().emit(UserAuthenticationEvent.emailOtpIsResent));
    result.catch((response: HttpApiResponseInterface) => {
      this.onRejected(response);
    });
  }

  public emailVerification(credentials: UserEmailVerificationModel): void {
    const result = this.userAuthenticationStoreService.emailVerification(credentials);

    result.then(this.onEmailVerificationResolved);
    result.catch((response: HttpApiResponseInterface) => {
      this.onRejected(response);
    });
  }

  private onEmailVerificationResolved = (model: UserEmailVerificationModel) => {
    // Update data
    this.updateUserData(model, UserFrontendLoginProvidersEnum.email);

    this.getEventEmitter().emit(
      model.user.existing_user === true
        ? UserAuthenticationEvent.signInSucceeded
        : UserAuthenticationEvent.registrationSucceeded,
      {
        provider: UserFrontendLoginProvidersEnum.email,
        userId: this.getStatsUserId(),
        userEmail: model.user.email,
        userFirstName: model.user.first_name,
        userLastName: model.user.last_name,
        existingUser: model.user.existing_user,
        phone: model.user.phone,
      }
    );
  };

  private onRejected = (response: HttpApiResponseInterface) => {
    if (response.status === 400) {
      this.getEventEmitter().emit(
        UserAuthenticationEvent.fieldsValidationFailed,
        this.toAuthFieldErrors(response.data.errors)
      );
      return;
    }

    this.getEventEmitter().emit(
      UserAuthenticationEvent.apiCallingFailed,
      i18nTranslate('Something went wrong! Please try again later')
    );
  };

  /**
   * @inheritDoc
   */
  public userExists(email: string): void {
    const authResult = this.userAuthenticationStoreService.userExists(email);

    authResult.then(this.onUserExistsResolved);
    authResult.catch(this.onUserExistsRejected);
  }

  /**
   * @inheritDoc
   */
  public signInWithFacebook(): void {
    this.getEventEmitter().emit(UserAuthenticationEvent.signInStart);

    this.userFrontendLoginService.signIn(UserFrontendLoginProvidersEnum.facebook);
  }

  /**
   * @inheritDoc
   */
  public signInWithGoogle(): void {
    this.getEventEmitter().emit(UserAuthenticationEvent.signInStart);
  }

  /**
   * @inheritDoc
   */
  public askPasswordReset(model: UserResetPasswordModel, isNewModal: boolean = false): void {
    this.getEventEmitter().emit(UserAuthenticationEvent.askPasswordResetStart);

    // Try to reset password
    const result = this.userAuthenticationStoreService.resetPassword(model);

    result.then(this.onAskPasswordResetResolved);
    result.catch((response: HttpApiResponseInterface) => {
      this.onAskPasswordResetRejected(response, isNewModal);
    });
  }

  /**
   * @inheritDoc
   */
  public changePassword(model: UserChangePasswordModel): void {
    this.getEventEmitter().emit(UserAuthenticationEvent.changePasswordStart);

    // Try to reset password
    const result = this.userAuthenticationStoreService.changePassword(model);

    result.then(this.onChangePasswordResolved);
    result.catch(this.onChangePasswordRejected);
  }

  public getSmsCountriesList(): void {
    this.userAuthenticationStoreService
      .getSmsCountriesList()
      .then((data: UserAuthSmsCountriesInterface[]) =>
        this.getEventEmitter().emit(UserAuthenticationEvent.countriesData, data)
      );
  }

  /**
   * @inheritDoc
   */
  public getToken(): string | null {
    const model = this.jwtTokenService.getToken();

    if (!model) {
      return null;
    }

    return model.payload;
  }

  /**
   * @inheritDoc
   */
  public logOut(): void {
    this.userAuthenticationStoreService.logout(this.getToken()).then(() => {
      this.resetToken();
      this.signOut();
      // Emit event
      this.getEventEmitter().emit(UserAuthenticationEvent.userDataCleared);
    });
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public getUser(): UserModel | null {
    const model = new UserModel();
    const data = <UserModel>this.browserStorageLocal.getData(this.userKey);

    if (!data || typeof data !== 'object') {
      return null;
    }

    model.id = typeof data.id === 'string' ? data.id : '';
    model.first_name = typeof data.first_name === 'string' ? data.first_name : '';
    model.last_name = typeof data.last_name === 'string' ? data.last_name : '';
    model.email = typeof data.email === 'string' ? data.email : '';
    model.image = typeof data.image === 'string' ? data.image : '';
    model.phone = typeof data.phone === 'string' ? data.phone : '';

    return model;
  }

  /**
   * Reset token and refresh token
   */
  private resetToken(): void {
    this.setToken();
    this.jwtTokenService.setRefreshToken();
  }

  /**
   * Sign out user
   */
  private signOut(): void {
    // User is already signed out
    if (!this.getUser()) {
      return;
    }

    // Emit event
    this.getEventEmitter().emit(UserAuthenticationEvent.logOutSucceeded, {
      userId: this.getStatsUserId(),
    });

    this.setUser(null);
    this.setProvider(null);
    this.updateStatsContext(this.getUser());
  }

  /**
   * Set token
   */
  private setToken(token: string = null): void {
    this.jwtTokenService.setToken(this.jwtTokenService.createFromPayload(token));
  }

  /**
   * Set user
   */
  private setUser(model: UserModel | null): void {
    if (!model) {
      this.browserStorageLocal.removeData(this.userKey);

      return;
    }

    this.browserStorageLocal.setData(this.userKey, {
      id: model.id,
      first_name: model.first_name,
      last_name: model.last_name,
      image: model.image,
      email: model.email,
      phone: model.phone,
    });
  }

  /**
   * Transforms api errors to UserSignInFieldErrorsInterface
   */
  private toUserSignInFieldErrors(errors: ApiErrorInterface[]): UserSignInFieldErrorsInterface {
    return errors.reduce(
      (accumulator, error) => {
        const errorKey = error.source.pointer.split(this.regExp).pop();

        return {
          ...accumulator,
          [errorKey]: error.detail,
        };
      },
      {
        email: '',
        password: '',
        captcha_token: '',
      }
    );
  }

  /**
   * Transforms api errors to UserRegistrationFieldErrorsInterface
   */
  private toUserRegistrationFieldErrors(errors: ApiErrorInterface[]): UserRegistrationFieldErrorsInterface {
    return errors.reduce(
      (accumulator, error) => {
        const errorKeyArr = error.source.pointer.split(this.regExp);
        let errorKey = errorKeyArr[errorKeyArr.length - 1];

        if (errorKey === 'first_name' || errorKey === 'last_name') {
          errorKey = 'userName';
        }

        return {
          ...accumulator,
          [errorKey]: error.detail,
        };
      },
      {
        userName: '',
        email: '',
        password: '',
        captcha_token: '',
      }
    );
  }

  /**
   * Transforms api errors to UserForgotPasswordFieldErrorsInterface
   */
  private toUserForgotPasswordFieldErrors(errors: ApiErrorInterface[]): UserForgotPasswordFieldErrorsInterface {
    return errors.reduce(
      (accumulator, error) => {
        const errorKeyArr = error.source.pointer.split(this.regExp);
        const errorKey = errorKeyArr[errorKeyArr.length - 1];

        return {
          ...accumulator,
          [errorKey]: error.detail,
        };
      },
      {
        email: '',
        captcha_token: '',
      }
    );
  }

  /**
   * Transforms api errors to UserChangePasswordFieldErrorsInterface
   */
  private toUserChangePasswordValidationErrors(errors: ApiErrorInterface[]): UserChangePasswordFieldErrorsInterface {
    return errors.reduce(
      (accumulator, error) => {
        const errorKeyArr = error.source.pointer.split(this.regExp);
        const errorKey = errorKeyArr[errorKeyArr.length - 1];

        return {
          ...accumulator,
          [errorKey]: error.detail,
        };
      },
      {
        password: '',
        repeat_password: '',
      }
    );
  }

  /**
   * Update stats context
   */
  private updateStatsContext(userModel: UserModel | null): void {
    this.statsContexterService.setAuthenticationUser(userModel ? this.userStatsDataAdapter.getData(userModel) : null);
    this.statsContexterService.setAuthenticationProvider(this.getAuthenticationProvider());
  }

  /**
   * Get user authentication provider type
   */
  private getAuthenticationProvider(): AuthenticationProviderType | null {
    const provider = <AuthenticationProviderType>this.browserStorageLocal.getData(this.authenticationProviderKey);

    if (provider && ['Google', 'Facebook', 'Email'].indexOf(provider) !== -1) {
      return provider;
    }

    if (this.getToken()) {
      return 'Email';
    }

    return null;
  }

  /**
   * Get stats user ID
   */
  private getStatsUserId(): string {
    const user = this.getUser();

    // Update stats context
    this.updateStatsContext(user);

    if (!user) {
      return '0';
    }

    // Register stats entities
    this.statsDataService.getUserStore().add(this.userStatsDataAdapter.getData(user));

    return user.id;
  }

  /**
   * Set authentication provider in local storage
   */
  private setProvider(provider: UserFrontendLoginProvidersEnum | null): void {
    if (!provider) {
      this.browserStorageLocal.removeData(this.authenticationProviderKey);

      return;
    }

    this.browserStorageLocal.setData(this.authenticationProviderKey, provider);
  }

  /**
   * User exists promise resolved then emit event
   */
  private onUserExistsResolved = (response: UserExistsResponseModel) => {
    this.getEventEmitter().emit(UserAuthenticationEvent.userExistsSucceeded, {
      isExist: response.exists,
    });
  };

  /**
   * User exists promise rejected then emit event
   */
  private onUserExistsRejected = (response: HttpApiResponseInterface) => {
    if ([400, 422].indexOf(response.status) !== -1) {
      this.getEventEmitter().emit(
        UserAuthenticationEvent.userExistsValidationFailed,
        this.toAuthFieldErrors(response.data.errors)
      );

      return;
    }

    if (response.status === 401) {
      this.getEventEmitter().emit(UserAuthenticationEvent.userExistsFailed, response.data.errors[0].title);

      return;
    }

    this.getEventEmitter().emit(
      UserAuthenticationEvent.userExistsFailed,
      i18nTranslate('Something went wrong! Please try again later')
    );
  };

  /**
   * Transforms api errors to object
   */
  private toAuthFieldErrors(errors: ApiErrorInterface[]): UserAuthFieldErrorsInterface {
    return (errors || []).reduce(
      (accumulator, error) => {
        const errorKey = error.source.pointer.split(this.regExp).pop();

        return {
          ...accumulator,
          [errorKey]: error.detail,
        };
      },
      {
        email: '',
        password: '',
        captcha_token: '',
        phone: '',
        code: '',
      }
    );
  }

  /**
   * User sign in promise resolved
   */
  private onSignInResolved = (model: UserLoginModel) => {
    this.getEventEmitter().emit(UserAuthenticationEvent.signInStart);

    // Update data
    this.updateUserData(model, UserFrontendLoginProvidersEnum.email);

    // Emit event
    this.getEventEmitter().emit(UserAuthenticationEvent.signInSucceeded, {
      provider: UserFrontendLoginProvidersEnum.email,
      userId: this.getStatsUserId(),
      userEmail: model.user.email,
      userFirstName: model.user.first_name,
      userLastName: model.user.last_name,
      existingUser: model.user.existing_user,
    });
  };

  /**
   * User sign in promise rejected
   */
  private onSignInRejected = (response: HttpApiResponseInterface, isNewModal: boolean = false) => {
    this.getEventEmitter().emit(UserAuthenticationEvent.signInStart);
    if (response.status === 412) {
      this.getEventEmitter().emit(UserAuthenticationEvent.emailOtpIsResent);
      return;
    } else if ([400, 422].indexOf(response.status) !== -1) {
      if (isNewModal) {
        this.getEventEmitter().emit(
          UserAuthenticationEvent.loginValidationFailed,
          this.toAuthFieldErrors(response.data.errors)
        );
      } else {
        this.getEventEmitter().emit(
          UserAuthenticationEvent.signInValidationFailed,
          this.toUserSignInFieldErrors(response.data.errors)
        );
      }
      return;
    }

    if (isNewModal === true && response.status === 449) {
      this.getEventEmitter().emit(UserAuthenticationEvent.captchaTokenIsRequired);
      return;
    }

    if (response.status === 401) {
      this.getEventEmitter().emit(
        isNewModal ? UserAuthenticationEvent.loginFailed : UserAuthenticationEvent.signInFailed,
        response.data.errors[0].title
      );
      return;
    }

    this.getEventEmitter().emit(
      isNewModal ? UserAuthenticationEvent.loginFailed : UserAuthenticationEvent.signInFailed,
      i18nTranslate('Something went wrong! Please try again later')
    );
  };

  /**
   * User update promise resolver
   * @param provider login/sign up provider
   */
  private onUpdateUserResolved =
    (provider?: UserFrontendLoginProvidersEnum) =>
    (model: UserUpdateModel): void => {
      const userModel = new UserModel();
      userModel.id = model.id;
      userModel.email = model.email;
      userModel.first_name = model.first_name;
      userModel.last_name = model.last_name;
      userModel.image = model.image;
      userModel.phone = model.phone;

      this.setUser(userModel);

      if (provider) {
        this.setProvider(provider);
      }

      this.updateStatsContext(this.getUser());

      this.getEventEmitter().emit(UserAuthenticationEvent.updateUserSucceeded);
    };

  /**
   * User update promise rejected
   */
  private onUpdateUserRejected = (response: HttpApiResponseInterface): void => {
    if (response.status === 400) {
      this.getEventEmitter().emit(
        UserAuthenticationEvent.updateUserValidationFailed,
        this.toAuthFieldErrors(response.data.errors)
      );
      return;
    }
    this.getEventEmitter().emit(
      UserAuthenticationEvent.updateUserFailed,
      i18nTranslate('Something went wrong! Please try again later')
    );
  };

  private onAuthenticatePhoneNumberResolved = (model: UserPhoneNumberLoginModel) => {
    // Update data
    this.updateUserData(model, UserFrontendLoginProvidersEnum.PHONE_NUMBER);

    this.getEventEmitter().emit(UserAuthenticationEvent.phoneNumberLoginSucceeded, {
      user: model.user,
    });

    this.getEventEmitter().emit(
      model.user.existing_user === true
        ? UserAuthenticationEvent.signInSucceeded
        : UserAuthenticationEvent.registrationSucceeded,
      {
        provider: UserFrontendLoginProvidersEnum.PHONE_NUMBER,
        userId: this.getStatsUserId(),
        userEmail: model.user.email,
        userFirstName: model.user.first_name,
        userLastName: model.user.last_name,
        existingUser: model.user.existing_user,
        phone: model.user.phone,
      }
    );
  };

  private onAuthenticatePhoneNumberRejected = (response: HttpApiResponseInterface) => {
    if (response.status === 400) {
      this.getEventEmitter().emit(
        UserAuthenticationEvent.phoneNumberLoginValidationFailed,
        this.toAuthFieldErrors(response.data.errors)
      );
      return;
    } else if (response.status === 449) {
      this.getEventEmitter().emit(UserAuthenticationEvent.captchaTokenIsRequired);
      return;
    }
    this.getEventEmitter().emit(
      UserAuthenticationEvent.phoneNumberLoginFailed,
      i18nTranslate('Something went wrong! Please try again later')
    );
  };

  /**
   * User registration promise resolved
   */
  private onRegistrationResolved = (model: UserRegisterModel) => {
    this.getEventEmitter().emit(UserAuthenticationEvent.registrationStart);
    // Update data
    this.updateUserData(model, UserFrontendLoginProvidersEnum.email);

    this.getEventEmitter().emit(UserAuthenticationEvent.registrationSucceeded, {
      provider: UserFrontendLoginProvidersEnum.email,
      userId: this.getStatsUserId(),
      userEmail: model.user.email,
      userFirstName: model.user.first_name,
      userLastName: model.user.last_name,
    });
  };

  /**
   * User auto registration promise resolved
   */
  private onAutoRegistrationResolved = (model: UserAutoRegisterModel) => {
    // Update data
    this.updateUserData(model, UserFrontendLoginProvidersEnum.email);

    // Emit event
    this.getEventEmitter().emit(UserAuthenticationEvent.registrationSucceeded, {
      userId: this.getStatsUserId(),
      userEmail: model.user.email,
      userFirstName: model.user.first_name,
      userLastName: model.user.last_name,
    });
  };

  /**
   * User registration promise rejected
   */
  private onRegistrationRejected = (response: HttpApiResponseInterface, isNewModal: boolean = false) => {
    this.getEventEmitter().emit(UserAuthenticationEvent.registrationStart);

    if (response.status === 412) {
      this.getEventEmitter().emit(UserAuthenticationEvent.emailOtpIsResent);
      return;
    } else if ([400, 422].indexOf(response.status) !== -1) {
      if (isNewModal) {
        this.getEventEmitter().emit(
          UserAuthenticationEvent.registrationValidationV2Failed,
          this.toAuthFieldErrors(response.data.errors)
        );
      } else {
        this.getEventEmitter().emit(
          UserAuthenticationEvent.registrationValidationFailed,
          this.toUserRegistrationFieldErrors(response.data.errors)
        );
      }
      return;
    }

    this.getEventEmitter().emit(
      isNewModal ? UserAuthenticationEvent.registrationV2Failed : UserAuthenticationEvent.registrationFailed,
      response
    );
  };

  /**
   * User auto registration promise rejected
   */
  private onAutoRegistrationRejected = (response: HttpApiResponseInterface) => {
    if (response.status !== 400) {
      this.getEventEmitter().emit(UserAuthenticationEvent.autoRegistrationFailed);

      return;
    }

    this.getEventEmitter().emit(UserAuthenticationEvent.alreadyRegistered);
  };

  /**
   * User ask reset password promise resolved
   */
  private onAskPasswordResetResolved = () => {
    this.getEventEmitter().emit(UserAuthenticationEvent.askPasswordResetSucceeded);
  };

  /**
   * User ask reset password promise rejected
   */
  private onAskPasswordResetRejected = (response: HttpApiResponseInterface, isNewModal: boolean = false) => {
    if ([400, 422].indexOf(response.status) !== -1) {
      if (isNewModal) {
        this.getEventEmitter().emit(
          UserAuthenticationEvent.forgotPasswordValidationFailed,
          this.toAuthFieldErrors(response.data.errors)
        );
      } else {
        this.getEventEmitter().emit(
          UserAuthenticationEvent.askPasswordResetValidationFailed,
          this.toUserForgotPasswordFieldErrors(response.data.errors)
        );
      }
      return;
    }

    this.getEventEmitter().emit(
      isNewModal ? UserAuthenticationEvent.forgotPasswordFailed : UserAuthenticationEvent.askPasswordResetFailed,
      i18nTranslate('Something went wrong! Please try again later')
    );
  };

  /**
   * Change password resolved
   */
  private onChangePasswordResolved = (model: ChangePasswordModel) => {
    if (!model) {
      this.getEventEmitter().emit(UserAuthenticationEvent.changePasswordSucceeded);

      return;
    }

    // Update data
    this.setToken(model.meta.token);
    this.setUser(model.user);
    this.setProvider(UserFrontendLoginProvidersEnum.email);
    this.updateStatsContext(this.getUser());

    this.getEventEmitter().emit(UserAuthenticationEvent.changePasswordSucceeded);
  };

  /**
   * Change password rejected
   */
  private onChangePasswordRejected = (response: HttpApiResponseInterface) => {
    if ([400, 422].indexOf(response.status) !== -1) {
      this.getEventEmitter().emit(
        UserAuthenticationEvent.changePasswordValidationFailed,
        this.toUserChangePasswordValidationErrors(response.data.errors)
      );

      return;
    }

    this.getEventEmitter().emit(
      UserAuthenticationEvent.changePasswordFailed,
      i18nTranslate('Something went wrong! Please try again later')
    );
  };

  /**
   * User frontend sign in failed
   */
  private onSignInFailedUserFrontendLogin = (errors: ApiErrorInterface[]) => {
    this.getEventEmitter().emit(UserAuthenticationEvent.signInFailed, errors?.[0]?.detail);
  };

  /**
   * User frontend sign in succeeded
   */
  private onSignInSucceededUserFrontendLogin = (
    model: UserFrontendLoginModel,
    provider: UserFrontendLoginProvidersEnum
  ) => {
    // Update data
    this.updateUserData(model, provider);

    if (!model.user.existing_user) {
      this.getEventEmitter().emit(UserAuthenticationEvent.registrationSucceeded, {
        provider,
        userId: this.getStatsUserId(),
        userEmail: model.user.email,
        userFirstName: model.user.first_name,
        userLastName: model.user.last_name,
      });
    } else {
      // Emit event
      this.getEventEmitter().emit(UserAuthenticationEvent.signInSucceeded, {
        provider,
        userId: this.getStatsUserId(),
        userEmail: model.user.email,
      });
    }
  };

  /**
   * Refresh token failed
   */
  private onRefreshTokenFailed = () => {
    this.signOut();
  };

  /**
   * Token reset
   */
  private onTokenReset = () => {
    this.signOut();
  };

  /**
   * On sign in with facebook/google popup window closed
   */
  private onSignInStoppedUserFrontendLogin = () => {
    this.getEventEmitter().emit(UserAuthenticationEvent.signInStopped);
  };

  private updateUserData = (
    model: {
      user: UserModel;
      meta: {
        token: string;
        refresh_token: string;
      };
    },
    provider: UserFrontendLoginProvidersEnum
  ) => {
    this.setToken(model.meta.token);
    this.jwtTokenService.setRefreshToken(model.meta.refresh_token);
    this.setUser(model.user);

    // Set authentication provider
    this.setProvider(provider);
    this.updateStatsContext(this.getUser());
  };
}
