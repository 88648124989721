export const enum RecommendedPropertiesViewStoreEventEnum {
  /**
   * Showing fallback properties
   */
  showingFallback = 'showingFallback',

  /**
   * Showing recommended properties
   */
  showingRecommendedProperties = 'showingRecommendedProperties',
}
