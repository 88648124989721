import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { EventEmitterAwareInterface } from 'pf-frontend-common/src/module/event/emitter-aware.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiPayloadInterface } from 'pf-frontend-common/src/module/json-api/payload.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { JwtTokenStore } from 'common/data/jwt/token/store';
import { SaveSearchFiltersInterface } from 'common/data/save-search/filters.interface';
import { jsonApiFlatParams } from 'common/helper/json-api/flat-params';
import { DataKeyValueFlatInterface } from 'common/module/data/key-value/flat.interface';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

import { saveSearchFilterEquality } from './filter-equality';
import { SaveSearchFrequencyEnum } from './frequency.enum';
import { saveSearchMapper } from './mapper';
import { SaveSearchModel } from './model';
import { SaveSearchStoreEvent } from './store.event';

export class SaveSearchStore implements EventEmitterAwareInterface {
  /**
   * Use this page limit to fetch all the record
   */
  public static readonly PAGE_LIMIT_TO_FETCH_ALL: number = 9999;

  /**
   * Is fetching the saved searches already in progress
   */
  private isFetchInProgress: boolean = false;

  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    getAll: {
      path: this.apiEndpointService.getPath('/v2/saved-search'),
      method: 'GET',
    },
    create: {
      path: this.apiEndpointService.getPath('/saved-search'),
      method: 'POST',
    },
    update: {
      path: this.apiEndpointService.getPath('/saved-search/{searchId}'),
      method: 'PATCH',
    },
    deleteSingle: {
      path: this.apiEndpointService.getPath('/saved-search/{searchId}'),
      method: 'DELETE',
    },
    deleteAll: {
      path: this.apiEndpointService.getPath('/saved-search'),
      method: 'DELETE',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private jsonApiStore: JsonApiStore<SaveSearchModel>,
    private userAuthenticationService: UserAuthenticationServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   *
   * @description - Retrieve all saved searches for the given user from the store or from the api
   * @returns All saved searches
   */
  public getAll(forced: boolean = false): Promise<SaveSearchModel[]> {
    const stored = this.jsonApiStore.findAll();
    const isLoggedIn = !!this.userAuthenticationService.getToken();

    if (stored?.length && !forced) {
      return Promise.resolve(stored);
    }

    // Return empty for guest or is already fetching
    if (!isLoggedIn || this.isFetchInProgress) {
      return Promise.resolve([]);
    }
    return this.getAllSavedSearches();
  }

  /**
   * Get save searches filter by filter parameters
   */
  public getByFilters(params: FilterParametersInterface): SaveSearchModel[] {
    const filters = saveSearchMapper(params);

    return this.jsonApiStore.findAll().filter((item) => saveSearchFilterEquality(item.filters, filters));
  }

  /**
   *
   * @description - Creates new saved search
   * @param name - name to give to the saved search
   * @param frequency - frequency to receive the mail alerts
   * @param filters  - Active filters
   * @returns Promise with saved searches
   */
  public create(
    name: string,
    frequency: SaveSearchFrequencyEnum,
    filters: FilterParametersInterface
  ): Promise<SaveSearchModel[]> {
    const payload: JsonApiPayloadInterface = {
      data: {
        type: SaveSearchModel.JSONAPI_TYPE,
        attributes: {
          name,
          frequency,
          filters: saveSearchMapper(filters),
        },
      },
    };

    return this.doRequest(this.endpoint.create.method, this.endpoint.create.path, true, payload);
  }

  public reCreate(
    name: string,
    frequency: SaveSearchFrequencyEnum,
    filters: SaveSearchFiltersInterface
  ): Promise<SaveSearchModel[]> {
    const payload: JsonApiPayloadInterface = {
      data: {
        type: SaveSearchModel.JSONAPI_TYPE,
        attributes: {
          name,
          frequency,
          filters,
        },
      },
    };

    return this.doRequest(this.endpoint.create.method, this.endpoint.create.path, true, payload);
  }

  /**
   *
   * @description - Updates frequency of the emails sent for this given saved search
   * @param id - id of the saved search
   * @param frequency - frequency to update
   * @returns Promise with saved searches
   */
  public updateFrequency(id: string, frequency: SaveSearchFrequencyEnum): Promise<SaveSearchModel[]> {
    const payload: JsonApiPayloadInterface = {
      data: {
        type: SaveSearchModel.JSONAPI_TYPE,
        attributes: {
          frequency,
        },
      },
    };
    const path: string = this.endpoint.update.path.replace('{searchId}', id);

    return this.doRequest(this.endpoint.update.method, path, false, payload);
  }

  /**
   * Update the single saved search with attributes
   */
  public update(id: string, attributes: Pick<SaveSearchModel, 'name' | 'frequency'>): Promise<SaveSearchModel[]> {
    const payload: JsonApiPayloadInterface = {
      data: {
        type: SaveSearchModel.JSONAPI_TYPE,
        attributes,
      },
    };
    const path: string = this.endpoint.update.path.replace('{searchId}', id);

    return this.doRequest(this.endpoint.update.method, path, true, payload, true);
  }

  /**
   *
   * @description - Deletes single saved search
   * @param id - id of the saved search
   * @returns Promise with saved searches
   */
  public deleteSingle(id: string): Promise<SaveSearchModel[]> {
    const path: string = this.endpoint.deleteSingle.path.replace('{searchId}', id);

    return this.doRequest(this.endpoint.deleteSingle.method, path, true);
  }

  /**
   * @description - Deletes all saved searches for the current user
   * @returns Promise with saved searches
   */
  public deleteAll(): Promise<SaveSearchModel[]> {
    return this.doRequest(this.endpoint.deleteAll.method, this.endpoint.deleteAll.path, true);
  }

  /**
   *
   * @description - Retrieves and sync saved searches with the store
   * @returns Promise with saved searches
   */
  private getAllSavedSearches(): Promise<SaveSearchModel[]> {
    this.isFetchInProgress = true;

    return this.doRequest(
      this.endpoint.getAll.method,
      this.endpoint.getAll.path,
      false,
      jsonApiFlatParams({
        page: {
          limit: SaveSearchStore.PAGE_LIMIT_TO_FETCH_ALL,
        },
      }),
      true,
      true
    )
      .then((data) => {
        this.getEventEmitter().emit(SaveSearchStoreEvent.searchSuccess, data);
        return data;
      })
      .finally(() => {
        this.isFetchInProgress = false;
      });
  }

  /**
   *
   * @description - Manages all api request for this service
   * @param method - Request method
   * @param path - Request url
   * @param updateStore - Whether to update the store or not
   * @param payload - Payload to be sent with the request
   * @param useResponse - Whether to use or not the response from the BE
   * @param sync
   * @returns Promise with saved searches
   */
  private doRequest(
    method: string,
    path: string,
    updateStore: boolean = false,
    payload: JsonApiPayloadInterface | DataKeyValueFlatInterface = null,
    useResponse: boolean = false,
    sync: boolean = false
  ): Promise<SaveSearchModel[]> {
    return new Promise((resolve, reject) => {
      this.apiService
        .request(method, path, payload, true, this.getApiHeaders(this.userAuthenticationService.getToken()))
        .then(async (response: HttpApiResponseInterface) => {
          // Failure
          if (response.status >= 300) {
            reject(response);
          }
          if (updateStore) {
            await this.getAllSavedSearches();
          }
          if (sync) {
            // if sync is required then reset first
            this.jsonApiStore.reset();
          }
          const data = sync ? (this.jsonApiStore.sync(response.data) as SaveSearchModel[]) : response.data.data;
          resolve(useResponse ? data : this.getAll());
        })
        .catch(reject);
    });
  }

  /**
   * @param token - Authentication token
   * @returns the API headers to attach to each request
   */
  private getApiHeaders(token: string): DataKeyValueStringInterface {
    const headers: DataKeyValueStringInterface = {};
    headers[JwtTokenStore.HEADER_JWT] = 'Bearer ' + token;

    return headers;
  }
}
