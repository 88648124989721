import { BrowserDocumentService } from '@propertyfinder/pf-frontend-common/src/service/browser-document/service';
import { WindowService } from '@propertyfinder/pf-frontend-common/src/service/window/service';

import { LoginSocialStoreFactory } from 'common/data/login-social/store.factory';
import { configGetKeys } from 'common/helper/config/get-keys';

import { UserFrontendLoginProviderFacebook } from './facebook';

export const UserFrontendLoginProviderFacebookFactory = () =>
  new UserFrontendLoginProviderFacebook(
    LoginSocialStoreFactory(),
    BrowserDocumentService(),
    WindowService(),
    configGetKeys().facebookAppId
  );
