import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { Component, h } from 'preact';

import { ProgressiveImageComponentPropsInterface } from 'common/module/progressive-image/component-props.interface';
import { ProgressiveImageComponentStateInterface } from 'common/module/progressive-image/component-state.interface';
import { PromiseCancelableFactory } from 'common/module/promise/cancelable.factory';
import { PromiseCancelableInterface } from 'common/module/promise/cancelable.interface';
import { BrowserSupportService } from 'common/service/browser-support/service';

export class ProgressiveImageComponent extends Component<
  ProgressiveImageComponentPropsInterface,
  ProgressiveImageComponentStateInterface
> {
  /**
   * Element used to load the image;
   */
  private imageLoader: HTMLImageElement;

  /**
   * Test for webp image
   */
  private testWebp: PromiseCancelableInterface<boolean>;

  /**
   * CSS classes
   */
  private cssClass: DataKeyValueStringInterface = {
    imgInitialError: 'progressive-image--initial-error',
    imgLoading: 'progressive-image--loading',
    imgLoaded: 'progressive-image--loaded',
    imgPreloader: 'progressive-image__preloader',
    imgPlaceholder: 'progressive-image__placeholder',
    imgError: 'progressive-image--error',
  };

  /**
   * Constructor
   */
  constructor(props: ProgressiveImageComponentPropsInterface) {
    super(props);

    let src: string = props.src;

    // This is a case where the src is injected in html as empty string
    // When preact mounts the component it treats it's value as true, where actually it should have been an empty string
    if ((src as unknown) === true) {
      src = props.webpPlaceholder || (typeof props.placeholder === 'string' ? props.placeholder : '');
    }

    this.testWebp = PromiseCancelableFactory(BrowserSupportService().testWebp());

    if (!props.webpPlaceholder) {
      src = this.jpgToWebP(src);
    }

    this.state = {
      // this initial src should be set to a value that will render
      // as already existing <picture> <source> and <img> tags
      src,
      alt: props.alt,
      title: props.title,
      empty: true,
      loading: false,
      error: false,
    };
  }

  /**
   * @inheritdoc
   */
  public componentWillMount(): void {
    this.loadImage(this.state.src);
  }

  /**
   * @inheritdoc
   */
  public componentWillReceiveProps(nextProps: ProgressiveImageComponentPropsInterface): void {
    if (this.props.webp === nextProps.webp && this.props.src === nextProps.src) {
      return;
    }

    this.loadImage(this.ensureWebP(nextProps.src, nextProps.webp));
  }

  /**
   * @inheritdoc
   */
  public render(): preact.JSX.Element {
    if (!this.state.src && !this.props.placeholder && !this.props.webpPlaceholder) {
      return null;
    }

    if (this.state.error && this.props.placeholder === true) {
      return <div style={this.props.style} className={this.props.classname || this.props.pictureClass} />;
    }

    const src = this.getSrcToDisplay();

    // We can't use webp inside <img> because of browsers that lack support for both webp and <source>
    // Those browsers wouldn't load webp and they wouldn't pick up the jpeg source so we have to make sure
    // we have jpg in <img> src.
    const srcForImageElement = this.isWebP(src) ? this.webpToJpg(src) : src;
    const imageElement = (
      <img
        src={srcForImageElement}
        className={this.getClasses(srcForImageElement)}
        alt={this.props.alt}
        title={this.props.title}
      />
    );

    return (
      <picture
        style={this.props.style}
        className={this.props.pictureClass || this.props.classname}
        onClick={this.props.onClick}
      >
        <source srcSet={src} type={this.getMimeType(src)} />

        {/* fallback jpg src */}
        {this.isWebP(src) && <source srcSet={this.webpToJpg(src)} type='image/jpeg' />}

        {imageElement}
      </picture>
    );
  }

  /**
   * @inheritdoc
   */
  public componentWillUnmount(): void {
    if (this.testWebp) {
      this.testWebp.cancel();
    }

    this.clearLoader();
  }

  /**
   * Makes sure the source is in webp if it is possible.
   */
  private ensureWebP(src: string, convertToWebp: boolean = true): string {
    if (this.isWebP(src)) {
      return src;
    }

    return convertToWebp ? this.jpgToWebP(src) : src;
  }

  /**
   * Returns src of the image that should be displayed.
   */
  private getSrcToDisplay(): string {
    // loading state
    // we do not show it for the first load as this might overwrite already rendered image
    // and cause flickering
    if (!this.state.empty && this.state.loading && this.props.preloader) {
      return this.props.preloader;
    }

    // webp placeholder image for error state if exists
    if (this.state.error && this.props.webpPlaceholder) {
      return this.props.webpPlaceholder;
    }

    // placeholder image for error state
    if (this.state.error && this.props.placeholder && typeof this.props.placeholder === 'string') {
      return this.props.placeholder;
    }

    if (!this.props.src && typeof this.props.placeholder === 'string') {
      return this.props.placeholder;
    }

    // the original image
    return this.state.src;
  }

  /**
   * Load image
   */
  private loadImage(src: string): void {
    if (!src) {
      return;
    }

    this.setState(() => ({
      loading: true,
    }));

    this.clearLoader();

    this.imageLoader = new Image();
    this.imageLoader.onload = this.onLoad;
    this.imageLoader.onerror = this.onError;

    if (this.isWebP(src)) {
      this.testWebp.getPromise().then((isWebpSupported) => {
        // start loading
        this.imageLoader.src = isWebpSupported ? src : this.webpToJpg(src);
      });
    } else {
      // start loading
      this.imageLoader.src = src;
    }
  }

  /**
   * Clear previous handlers so that they don't get called and clear reference to the image.
   */
  private clearLoader(): void {
    if (this.imageLoader) {
      this.imageLoader.onload = null;
      this.imageLoader.onerror = null;
    }
    this.imageLoader = null;
  }

  /**
   * On load image
   */
  private onLoad = (): void => {
    this.setState(() => ({
      empty: false,
      loading: false,
      error: false,
      src: this.imageLoader.src,
    }));
  };

  /**
   * On error loading
   */
  private onError = (e: Event): void => {
    const src = this.imageLoader.src;

    if (this.isWebP(src)) {
      this.setState(() => ({
        empty: false,
      }));

      // fallback to jpg
      this.loadImage(this.webpToJpg(src));
    } else {
      this.setState(() => ({
        error: true,
        empty: false,
        loading: false,
      }));

      const { onError } = this.props;
      if (onError) {
        onError(e);
      }
    }
  };

  /**
   * Transforms src from jpg to webp.
   */
  private jpgToWebP(src: string): string {
    return src ? src.replace('.jpg', '.webp') : '';
  }

  /**
   * Transforms src from webp to jpg.
   */
  private webpToJpg(src: string): string {
    return src ? src.replace('.webp', '.jpg') : '';
  }

  /**
   * Returns mime type for image src.
   */
  private getMimeType(src: string): string {
    if (!src) {
      // we want to avoid empty mime type
      return 'image/jpeg';
    }

    if (src.indexOf('.webp') !== -1) {
      return 'image/webp';
    }

    if (src.indexOf('.jpg') !== -1 || src.indexOf('.jpeg') !== -1) {
      return 'image/jpeg';
    }

    if (src.indexOf('.svg') !== -1) {
      return 'image/svg+xml';
    }
    if (src.indexOf('.png') !== -1) {
      return 'image/png';
    }

    if (src.indexOf('.gif') !== -1) {
      return 'image/gif';
    }

    // we want to avoid empty mime type
    return 'image/jpeg';
  }

  /**
   * True is current source is webp
   */
  private isWebP(src: string): boolean {
    return this.getMimeType(src) === 'image/webp';
  }

  /**
   * Get css classes
   */
  private getClasses(src: string): string {
    let classes = this.props.classname ? this.props.classname : '';

    if (!this.state.empty) {
      classes = `${classes} ${
        this.state.loading
          ? this.props.loadingClass || this.cssClass.imgLoading
          : this.props.loadedClass || this.cssClass.imgLoaded
      } ${this.state.loading && this.props.preloader ? this.cssClass.imgPreloader : ''} ${
        this.state.error ? this.cssClass.imgError : ''
      } ${
        (this.state.error && this.props.placeholder) || this.props.webpPlaceholder ? this.cssClass.imgPlaceholder : ''
      }`;
    }
    // Determine if image failed to load during first render
    else if (window.propertyfinder.progressiveImages?.errors[src]) {
      // Hide failed image
      classes = `${classes} ${this.cssClass.imgInitialError}`;
    }

    return classes;
  }
}
