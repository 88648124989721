import { IconSpritePlayThickShape } from '@propertyfinder/icons';
import { FunctionComponent, h } from 'preact';

import { CountryCodeEnum } from 'common/data/country/code.enum';
import { configGetCountry } from 'common/helper/config/get-country';
import { configGetLanguage } from 'common/helper/config/get-language';
import { ConfigLanguageCodeEnum } from 'common/helper/config/language-code.enum';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';

export const HomePageHeroContentComponent: FunctionComponent = () => {
  const tracking = StatsTealiumProviderService();
  const language = configGetLanguage().current;
  const isCurrentCountryAe = configGetCountry().code === CountryCodeEnum.ae;
  const link =
    language === ConfigLanguageCodeEnum.en
      ? `https://www.propertyfinder.${configGetCountry().code}/blog/every-home`
      : isCurrentCountryAe
      ? `https://propertyfinder.ae/blog/كل-العقارات`
      : 'https://www.propertyfinder.eg/blog/كل-البيوت';

  return (
    <a
      href={link}
      className='home-hero__link'
      data-testid='home-page-hero-link'
      onClick={(): void => {
        tracking.send({
          sendToGa: true,
          tealium_event: isCurrentCountryAe ? 'marketing_homepage_banner_click' : 'banner_click',
          event_action: isCurrentCountryAe ? 'banner_click' : 'home_finder_banner_click',
          event_category: isCurrentCountryAe
            ? StatsTealiumDataLayerEventCategoryEnum.marketingCampaings
            : StatsTealiumDataLayerEventCategoryEnum.bannerClick,
          event_label: 'hp_banner_every_home',
          page_category: 'Portal Home',
          page_country: configGetCountry().code,
          page_lang: language,
        });
      }}
    >
      <h1 className='home-hero__title'>
        {isCurrentCountryAe ? i18nTranslate('HomePage_EveryHome') : i18nTranslate('HomePage_EveryHome_EG')}
      </h1>
      <IconSpritePlayThickShape className='home-hero__play-icon' />
    </a>
  );
};
