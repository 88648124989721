import { BrowserStorageInterface } from 'pf-frontend-common/src/module/browser/storage.interface';

import { BackendConfigLanguageInterface } from 'common/data/backend/config/language.interface';
import { FilterSettingsDatabaseStore } from 'common/data/filter-settings/database/store';
import { FilterSettingsModel } from 'common/data/filter-settings/model';
import { FilterSettingsStore } from 'common/data/filter-settings/store';
import { JsonApiSyncedInterface } from 'common/helper/json-api/synced.interface';

export class FilterSettingsCacheStore {
  /**
   * Checksum key
   */
  private checksumKey: string = 'filter_settings_checksum';

  /**
   * Promise that resolves once cache is initialized
   */
  private initializedPromise: Promise<void>;

  /**
   * Autocomplete etag
   */
  private etag: string;

  /**
   * Constructor
   */
  public constructor(
    protected localStorage: BrowserStorageInterface,
    protected languageConfig: BackendConfigLanguageInterface,
    protected databaseService: FilterSettingsDatabaseStore,
    private filterSettingsStore: FilterSettingsStore
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(): Promise<void> {
    this.initializedPromise = this.filterSettingsStore.fetchChecksum().then(({ etag }) => {
      return this.fetchCacheOrUpdateItFromDatabase(etag);
    });

    return this.initializedPromise;
  }

  /**
   * Get cached settings
   */
  public getAll(): Promise<FilterSettingsModel[]> {
    return this.initializedPromise.then(() => this.databaseService.getAll());
  }

  /**
   * Decorates key with language specific data
   */
  private decorateKey(key: string): string {
    return `${this.languageConfig.current}_${key}`;
  }

  /**
   * Fetch Checksum completed
   */
  private fetchCacheOrUpdateItFromDatabase = (etag: string): Promise<void> => {
    if (!etag || etag !== this.localStorage.getData(this.decorateKey(this.checksumKey))) {
      return this.filterSettingsStore.fetch().then(this.updateDatabaseWithDataFromCache);
    }
    return Promise.resolve();
  };

  /**
   * Fetch cache completed
   */
  private updateDatabaseWithDataFromCache = (result: JsonApiSyncedInterface<FilterSettingsModel>) => {
    this.etag = result.headers.etag;
    this.databaseService.updateDatabase(result.data as FilterSettingsModel[]).then(() => {
      this.updateChecksumCache();
    });
  };

  /**
   * Update checksum cache
   */
  private updateChecksumCache(): void {
    this.localStorage.setData(this.decorateKey(this.checksumKey), this.etag);
  }
}
