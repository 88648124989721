import { BrowserStorageInterface } from 'pf-frontend-common/src/module/browser/storage.interface';
import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { JwtTokenStore } from 'common/data/jwt/token/store';
import { PropertySavedIdbStatechart } from 'common/data/property-saved/idb/statechart';
import { PropertySavedIdbStatechartEvent } from 'common/data/property-saved/idb/statechart.event';
import { PropertySavedIdbStatechartActionEnum } from 'common/data/property-saved/idb/statechart-action.enum';
import { PropertySavedModel } from 'common/data/property-saved/model';
import { PropertySavedSaveRequestInterface } from 'common/data/property-saved/save/request.interface';
import { PropertySavedStoreEvent } from 'common/data/property-saved/store.event';
import { arrayFind } from 'common/helper/array/find';
import { configGetLanguage } from 'common/helper/config/get-language';
import { dateToIso } from 'common/helper/date/to-iso';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { DatabaseServiceInterface } from 'common/service/database/service.interface';
import { JwtTokenServiceEvent } from 'common/service/jwt-token/service.event';
import { PropertySavedStoreServiceInterface } from 'common/service/property-saved-store/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

import { JwtTokenServiceInterface } from '../../service/jwt-token/service.interface';

export class PropertySavedStore implements PropertySavedStoreServiceInterface {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    save: {
      path: this.apiEndpointService.getPath('/user/saved-property'),
      method: 'POST',
    },
    update: {
      path: this.apiEndpointService.getPath('/user/saved-property'),
      method: 'PATCH',
    },
    list: {
      path: this.apiEndpointService.getPath('/user/saved-property'),
      method: 'GET',
    },
    delete: {
      path: this.apiEndpointService.getPath('/user/saved-property'),
      method: 'DELETE',
    },
  };

  /**
   * Abort last transaction function
   */
  private abortLastTransaction: () => void;

  /**
   * A value to understand if the data being moved to local storage
   */
  private readonly migratedToLocalStorageKey: string = 'property_saved_migrated_to_local_storage';

  /**
   * Constructor
   */
  constructor(
    private databaseService: DatabaseServiceInterface,
    private idbStatechartStore: StatechartStoreInterface,
    private eventEmitter: EventEmitterInterface,
    private jsonApiStore: JsonApiStore<PropertySavedModel>,
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface,
    private jwtTokenService: JwtTokenServiceInterface,
    private localStorage: BrowserStorageInterface
  ) {
    this.idbStatechartStore.initialize({
      statechart: PropertySavedIdbStatechart,
    });

    // Attach observers
    this.observers();

    this.idbStatechartStore.transit({
      event: PropertySavedIdbStatechartEvent.loadAll,
      payload: {},
    });
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public getAll(): Promise<PropertySavedModel[]> {
    // Local cache available
    if (this.jsonApiStore.findAll().length) {
      return Promise.resolve(this.jsonApiStore.findAll());
    }

    if (this.localStorage.getData(this.migratedToLocalStorageKey)) {
      return Promise.resolve(this.getAllFromLocalStorage());
    }

    return this.databaseService
      .openDatabase({
        languages: configGetLanguage(),
      })
      .then(async (db) => {
        const savedProperties: PropertySavedModel[] = [];
        const transaction = db.transaction(DatabaseTableEnum.savedProperties, 'readwrite');
        const store = transaction.objectStore(DatabaseTableEnum.savedProperties);

        let cursor = await store.openCursor();

        while (cursor) {
          savedProperties.push(cursor.value);

          cursor = await cursor.continue();
        }

        return transaction.done.then(() => {
          this.storeSavedProperties(savedProperties);
          this.localStorage.setData(this.migratedToLocalStorageKey, 1);

          return savedProperties;
        });
      });
  }

  /**
   * Remove saved property
   */
  public delete = (propertyId: string): void => {
    const token = this.getToken();

    if (!token) {
      return;
    }

    // Send API request
    this.apiService
      .request(
        this.endpoint.delete.method,
        `${this.endpoint.delete.path}/${propertyId}`,
        null,
        true,
        this.getApiHeaders(token)
      )
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  };

  /**
   * Remove all saved properties
   */
  public deleteAll = (): void => {
    const token = this.getToken();

    if (!token) {
      return;
    }

    // Send API request
    this.apiService
      .request(this.endpoint.delete.method, this.endpoint.delete.path, null, true, this.getApiHeaders(token))
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  };

  /**
   * Delete from local storage
   */
  public deleteAllFromLocalStorage = (): void => {
    this.localStorage.removeData(DatabaseTableEnum.savedProperties);
    this.sync([]);
  };

  /**
   * @inheritDoc
   */
  public getAllFromCache(): PropertySavedModel[] {
    return this.jsonApiStore.findAll();
  }

  /**
   * @inheritDoc
   */
  public sync(models: PropertySavedModel[]): void {
    // Update local cache
    this.jsonApiStore.reset();
    models.forEach((model) => {
      model.id = String(this.jsonApiStore.findAll().length);

      this.jsonApiStore.add(model);
    });

    // Emit event
    this.getEventEmitter().emit(PropertySavedStoreEvent.sync, models);
  }

  /**
   * Sync indexed db
   */
  public syncIdb(models: PropertySavedModel[]): void {
    this.idbStatechartStore.transit({
      event: PropertySavedIdbStatechartEvent.sync,
      payload: models,
    });
  }

  /**
   * @inheritDoc
   */
  public save(propertyId: string): void {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const payload: PropertySavedSaveRequestInterface = {
      data: {
        type: 'saved_property',
        attributes: {
          property_id: parseInt(propertyId, 10),
          save_date: dateToIso(new Date()),
        },
      },
    };

    // Send API request
    this.apiService
      .request(this.endpoint.save.method, this.endpoint.save.path, payload, true, this.getApiHeaders(token))
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  }

  /**
   * @inheritDoc
   */
  public update(properties: PropertySavedModel[] = []): void {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const data = properties.map((property: PropertySavedModel) => {
      return {
        type: property.jsonApiType,
        attributes: {
          property_id: property.property_id,
          save_date: property.save_date,
        },
      };
    });

    // Send API request
    this.apiService
      .request(this.endpoint.update.method, this.endpoint.update.path, { data }, true, this.getApiHeaders(token))
      .then((response) => {
        this.idbStatechartStore.transit({
          event: PropertySavedIdbStatechartEvent.loadAll,
          payload: {},
        });
      })
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  }

  /**
   * @inheritDoc
   */
  public unsave(propertyId: string): void {
    // Not saved yet
    if (!this.isSaved(propertyId)) {
      return;
    }

    // Local saved properties
    let models = this.jsonApiStore.findAll() || [];

    models = models.filter((model) => model.property_id !== parseInt(propertyId, 10));

    this.sync(models);

    // Update browser storage
    this.syncIdb(models);

    this.getEventEmitter().emit(PropertySavedStoreEvent.unsave, propertyId);
  }

  /**
   * @inheritDoc
   */
  public isSaved(propertyId: string): boolean {
    return !!arrayFind(this.jsonApiStore.findAll(), (model) => model.property_id === parseInt(propertyId, 10));
  }

  /**
   * Merge propertyIds with current ones, by preventing duplicate entries
   */
  public saveInDb(model: PropertySavedModel): void {
    const oldModels = this.jsonApiStore.findAll();

    if (!arrayFind(oldModels, (oldModel) => oldModel.property_id === model.property_id)) {
      model.id = String(this.jsonApiStore.findAll().length);
      this.jsonApiStore.add(model);
    }

    // Update browser storage
    this.syncIdb(this.jsonApiStore.findAll());

    // Sync saved property IDs
    this.sync(this.jsonApiStore.findAll());
  }

  /**
   * Get saved properties
   */
  public getAllFromLocalStorage(): PropertySavedModel[] {
    const properties = (this.localStorage.getData(DatabaseTableEnum.savedProperties) as PropertySavedModel[]) || [];

    return properties?.map((property) => {
      const newProperty = new PropertySavedModel();
      newProperty.property_id = property.property_id;
      newProperty.save_date = property.save_date;

      return newProperty;
    });
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onRegistrationSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.logOutSucceeded, this.onLogOutSucceeded);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertySavedIdbStatechartActionEnum.updateDb, this.onUpdateDbPropertySavedLocalStorage);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertySavedIdbStatechartActionEnum.updateLocalCache, this.onUpdateLocalCachePropertySavedIdb);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertySavedIdbStatechartActionEnum.loadAll, this.onLoadAllPropertySavedIdbAction);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertySavedIdbStatechartActionEnum.firstLoading, this.onFirstDataLoading);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertySavedIdbStatechartActionEnum.cancelUpdate, this.onCancelUpdatePropertySavedIdb);

    this.jwtTokenService
      .getEventEmitter()
      .addListener(JwtTokenServiceEvent.refreshTokenSucceeded, this.onRefreshTokenSucceededJwtToken);
  }

  /**
   * Get token
   */
  private getToken(): string {
    // User authentication token
    const token = this.userAuthenticationService.getToken();

    return token;
  }

  /**
   * Clear the list of saved properties
   */
  private clear(): void {
    this.sync([]);
    this.deleteAllFromLocalStorage();

    this.getEventEmitter().emit(PropertySavedStoreEvent.clear);
  }

  /**
   * Returns the API headers to attach to each request
   *
   * TODO-FE[later] Implement a HTTP interceptor or an ApiService decorator in the pf-website application
   */
  private getApiHeaders(token: string): DataKeyValueStringInterface {
    const headers: DataKeyValueStringInterface = {};
    headers[JwtTokenStore.HEADER_JWT] = 'Bearer ' + token;

    return headers;
  }

  /**
   * Load saved properties
   */
  private loadSavedProperties(): Promise<PropertySavedModel[]> {
    return this.apiService
      .request(
        this.endpoint.list.method,
        this.endpoint.list.path,
        {},
        true,
        this.getApiHeaders(this.userAuthenticationService.getToken())
      )
      .then((response: HttpApiResponseInterface) => {
        // Empty data
        if (!response.data.data) {
          // Consider data as an empty array

          return [];
        }

        this.jsonApiStore.reset();

        // Remote saved property IDs
        this.jsonApiStore.sync(response.data);

        return this.jsonApiStore.findAll();
      });
  }

  /**
   * Sign in succeeded
   */
  private onSignInSucceeded = (): void => {
    this.getAll().then((properties) => this.update(properties));
  };

  /**
   * Registration succeeded
   */
  private onRegistrationSucceeded = (): void => {
    this.getAll().then((properties) => this.update(properties));
  };

  /**
   * User logged out
   */
  private onLogOutSucceeded = (): void => {
    // Clear the list
    this.clear();
  };

  /**
   * Update db action handler
   */
  private onUpdateDbPropertySavedLocalStorage = (): void => {
    const models = this.jsonApiStore.findAll();
    this.storeSavedProperties(models);

    this.idbStatechartStore.transit({
      event: PropertySavedIdbStatechartEvent.synced,
      payload: this.jsonApiStore.findAll(),
    });
  };

  /**
   * Update local cache action handler
   */
  private onUpdateLocalCachePropertySavedIdb = (properties: PropertySavedModel[]): void => {
    this.sync(properties);
  };

  /**
   * Load all saved properties action handler
   */
  private onLoadAllPropertySavedIdbAction = (): void => {
    const token = this.getToken();
    if (!token) {
      this.getAll().then((properties) => {
        this.idbStatechartStore.transit({
          event: PropertySavedIdbStatechartEvent.loadAllSuccess,
          payload: properties,
        });
      });
      return;
    }
    this.loadSavedProperties().then((properties) => {
      this.idbStatechartStore.transit({
        event: PropertySavedIdbStatechartEvent.loadAllSuccess,
        payload: properties,
      });
    });
  };

  /**
   * First data loading
   */
  private onFirstDataLoading = (): void => {
    // Emit event: first data loading
    this.eventEmitter.emit(PropertySavedStoreEvent.firstLoading);
  };

  /**
   * Cancel update property saved idb action handler
   */
  private onCancelUpdatePropertySavedIdb = (): void => {
    try {
      this.abortLastTransaction();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.info('error', e);
    }
  };

  /**
   * Token refresh succeeded
   */
  private onRefreshTokenSucceededJwtToken = (): void => {
    this.sync([]);
  };

  /**
   * Store saved properties
   *
   * @param models PropertySavedModel
   */
  private storeSavedProperties(models: PropertySavedModel[]): void {
    const savedProperties = models.map((model) => {
      return {
        property_id: model.property_id,
        save_date: model.save_date,
      };
    });

    if (savedProperties.length === 0) {
      this.localStorage.removeData(DatabaseTableEnum.savedProperties);
    } else {
      this.localStorage.setData(DatabaseTableEnum.savedProperties, savedProperties);
    }
  }
}
