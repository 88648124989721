import { ScrollableAreaComponent } from '@propertyfinder/scrollable-area';
import { Fragment, FunctionComponent, h } from 'preact';
import { useRef, useState } from 'preact/hooks';

import { configGetEnv } from 'common/helper/config/get-env';
import { configGetLanguage } from 'common/helper/config/get-language';
import { domGetElement } from 'common/helper/dom/get-element';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { NavigationArrowsComponent } from 'common/module/navigation-arrows/component';

export const SuperAgentSliderComponent: FunctionComponent<{ arrowPosition: 'title' | 'subtitle' }> = ({
  arrowPosition,
  children,
}) => {
  const [items] = useState(() => domGetElement(children, 'article'));
  const [currentGaleryIndex, setCurrentGaleryIndex] = useState(0);
  const nextItemRef = useRef(null);
  const prevItemRef = useRef(null);

  const isRtl = configGetLanguage().isRtl;

  const Arrows = () => (
    <NavigationArrowsComponent
      isRtl={isRtl}
      currentGaleryIndex={currentGaleryIndex}
      numberOfItems={items.length}
      nextRef={nextItemRef}
      prevRef={prevItemRef}
    />
  );

  return (
    <Fragment>
      <h3 className='home-page-layout__section-title'>
        {i18nTranslate('super-agent-slider:title')} {arrowPosition === 'title' && <Arrows />}
      </h3>
      <div className='home-page-layout__section-subtitle'>
        <a className='super-agent-slider__link' href={configGetEnv().superAgentBlogUrl}>
          {i18nTranslate('Learn more about {subject}').replace('{subject}', 'SuperAgent')}
        </a>
        {arrowPosition === 'subtitle' && <Arrows />}
      </div>
      <div className='home-page-layout__section-content'>
        <ScrollableAreaComponent
          onChange={(e) => setCurrentGaleryIndex(e)}
          bindNextItem={(nextItem) => {
            nextItemRef.current = nextItem;
          }}
          bindPrevItem={(prevItem) => {
            prevItemRef.current = prevItem;
          }}
          className='home-page-layout__scrollable-area'
          itemClassName='home-page-layout__scrollable-card'
          rtl={isRtl}
        >
          {items}
        </ScrollableAreaComponent>
      </div>
    </Fragment>
  );
};
