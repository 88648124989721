import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { PropertyModel } from 'common/data/property/model';
import { DataStore } from 'common/module/data/store';
import { PropertyListViewStore } from 'common/module/property/list/view-store';
import { RecommendedPropertiesTemplatePropsInterface } from 'common/module/recommended-properties/template-props.interface';
import { RecommendedPropertiesViewStoreEventEnum } from 'common/module/recommended-properties/view-store.event';
import { RecommendedPropertiesViewStoreOptionsInterface } from 'common/module/recommended-properties/view-store-options.interface';
import { PropertyStoreServiceInterface } from 'common/service/property-store/service.interface';

export class RecommendedPropertiesViewStore extends DataStore {
  /**
   * @inheritdoc
   */
  protected state: RecommendedPropertiesTemplatePropsInterface;

  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private propertyListViewStore: PropertyListViewStore,
    private propertyStore: PropertyStoreServiceInterface,
    private userId: string
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritdoc
   */
  public initialize(options: RecommendedPropertiesViewStoreOptionsInterface): void {
    this.state = {
      isRecommendedProperties: false,
      isLoading: true,
      propertyListViewStore: this.propertyListViewStore,
      fallbackProperties: options.fallbackProperties,
    };

    this.propertyListViewStore.initialize({
      noResultMessage: '',
      showActions: false,
      isTargetBlank: options.isTargetBlank,
    });

    this.initProperties(options.isRecommendedPropertiesEnabled);
  }

  /**
   * @inheritdoc
   */
  public getState(): RecommendedPropertiesTemplatePropsInterface {
    return this.state;
  }

  /**
   * Initialize more properties
   */
  private initProperties(isRecommendedPropertiesEnabled: boolean): void {
    if (isRecommendedPropertiesEnabled && this.userId) {
      this.propertyStore
        .fetchRecommendedProperties(this.userId)
        .then(this.onFetchedRecommendedProperties)
        .catch(this.onFailedToFetchRecommendedProperties);

      return;
    }

    this.showFallback();
  }

  /**
   * Show fallback properties (similar properties)
   */
  private showFallback(): void {
    this.updateProperties(this.state.fallbackProperties);

    this.getEventEmitter().emit(RecommendedPropertiesViewStoreEventEnum.showingFallback);
  }

  /**
   * Update properties
   */
  private updateProperties(models: PropertyModel[]): void {
    this.state.isLoading = false;
    this.setState(this.state);
    this.propertyListViewStore.updateProperties(models, {
      property_more: true,
    });
  }

  /**
   * Recommended properties received
   */
  private onFetchedRecommendedProperties = (models: PropertyModel[]): void => {
    if (!models.length) {
      this.showFallback();
      return;
    }

    this.state.isRecommendedProperties = true;
    this.updateProperties(models.slice(0, 5));

    this.getEventEmitter().emit(RecommendedPropertiesViewStoreEventEnum.showingRecommendedProperties);
  };

  /**
   * Failed to receive recommended properties
   */
  private onFailedToFetchRecommendedProperties = () => {
    this.showFallback();
  };
}
