import { BrowserDocumentService } from '@propertyfinder/pf-frontend-common/src/service/browser-document/service';
import { WindowService } from '@propertyfinder/pf-frontend-common/src/service/window/service';

import { LoginSocialStoreFactory } from 'common/data/login-social/store.factory';
import { configGetEnv } from 'common/helper/config/get-env';
import { configGetKeys } from 'common/helper/config/get-keys';

import { UserFrontendLoginProviderGoogleOneTap } from './google-one-tap';

export const UserFrontendLoginProviderGoogleOneTapFactory = () =>
  new UserFrontendLoginProviderGoogleOneTap(
    LoginSocialStoreFactory(),
    BrowserDocumentService(),
    WindowService(),
    configGetKeys().googleAuth,
    configGetEnv()
  );
