import { Component, ComponentFactory, h } from 'preact';

import { PreactLifecycleComponentPropsInterface } from './lifecycle-component-props.interface';
import { PreactStateInterface } from './state.interface';

/**
 * Attaches lifecycle events to WrappedComponent
 */
export const preactLifecycleComponent = <K extends {}>(WrappedComponent: ComponentFactory<K>) =>
  class PreactLifecycleComponent extends Component<
    K & PreactLifecycleComponentPropsInterface<K>,
    PreactStateInterface
  > {
    /**
     * @inheritDoc
     */
    public base: HTMLElement;

    /**
     * @inheritDoc
     */
    public componentWillMount(): void {
      if (!this.props.onComponentWillMount) {
        return;
      }

      this.props.onComponentWillMount();
    }

    /**
     * @inheritDoc
     */
    public componentDidMount(): void {
      if (!this.props.onComponentDidMount) {
        return;
      }

      this.props.onComponentDidMount(this.base);
    }

    /**
     * @inheritDoc
     */
    public componentWillUnmount(): void {
      if (!this.props.onComponentWillUnmount) {
        return;
      }

      this.props.onComponentWillUnmount(this.base);
    }

    /**
     * @inheritDoc
     */
    public componentDidUpdate(): void {
      if (!this.props.onComponentDidUpdate) {
        return;
      }

      this.props.onComponentDidUpdate(this.base);
    }

    /**
     * @inheritDoc
     */
    public shouldComponentUpdate(): boolean {
      if (!this.props.onShouldComponentUpdate) {
        return true;
      }

      return this.props.onShouldComponentUpdate();
    }

    /**
     * @inheritdoc
     */
    public componentWillReceiveProps(props: Readonly<K & PreactLifecycleComponentPropsInterface<K>>): void {
      if (!this.props.onComponentWillReceiveProps) {
        return;
      }

      this.props.onComponentWillReceiveProps(props, this);
    }

    /**
     * @inheritDoc
     */
    public render(): preact.JSX.Element | null {
      return <WrappedComponent {...this.props} />;
    }
  };
