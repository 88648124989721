import { DefaultContext, EventObject, MachineConfig, StateSchema } from 'xstate/es';

import { MyAccountContactedPropertiesStatechartStateEnum } from 'common/module/my-account/contacted-properties/statechart/state.enum';
import { MyAccountStatechartEvent } from 'common/module/my-account/statechart.event';
import { MyAccountStatechartActionEnum } from 'common/module/my-account/statechart/action.enum';

export const MyAccountContactedPropertiesStatechart: MachineConfig<DefaultContext, StateSchema, EventObject> = {
  initial: MyAccountContactedPropertiesStatechartStateEnum.idle,
  states: {
    [MyAccountContactedPropertiesStatechartStateEnum.idle]: {
      on: {
        [MyAccountStatechartEvent.fetchContactedProperties]: MyAccountContactedPropertiesStatechartStateEnum.start,
      },
    },
    [MyAccountContactedPropertiesStatechartStateEnum.start]: {
      onEntry: [MyAccountStatechartActionEnum.fetchContactedProperties],
      on: {
        [MyAccountStatechartEvent.fetchContactedProperties]: {
          target: 'start',
          actions: [MyAccountStatechartActionEnum.cancelPreviousFetch],
        },
      },
    },
  },
};
