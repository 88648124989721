import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { numberFormat } from 'common/helper/number/format';
import { PriceSelectorComponent } from 'common/module/price-selector/component';
import { PriceSelectorTemplatePropsInterface } from 'common/module/price-selector/template-props.interface';

/**
 * formats the given values and returns the concatenated string
 * @param props Partial<PriceSelectorTemplatePropsInterface>
 * @param rtl boolean
 * @returns string
 */
export const priceSelectorComputeText = (props: Partial<PriceSelectorTemplatePropsInterface>, rtl: boolean): string => {
  const { value, currencyCode, activeCategoryId } = props;
  const { min, max, period } = value;
  const text: string[] = [];

  if (!min && !max) {
    // reset text
    return '';
  }

  const unformattedMinValue = min.replace(/,/g, '');
  const unformattedMaxValue = max.replace(/,/g, '');

  const minInt = parseInt(unformattedMinValue, 10);
  const maxInt = parseInt(unformattedMaxValue, 10);

  if (!min && !!max) {
    // only max
    text.push(i18nTranslate(rtl ? 'to' : 'Up to'), numberFormat(maxInt));
  } else if (!!min && !max) {
    // only min
    text.push(i18nTranslate('From'), numberFormat(minInt));
  } else {
    text.push(numberFormat(minInt), '-', numberFormat(maxInt));
  }

  const isCategorySale = new PropertyCategoryIdentifierAdapter().isSale(activeCategoryId);
  let periodText = '';

  if (!isCategorySale) {
    const priceTypeKey = `${PriceSelectorComponent.translationKey}::${period}`;
    periodText = `${!rtl ? '/' : ''} ${i18nTranslate(priceTypeKey).toLowerCase()}`;
  }

  text.push(i18nTranslate(currencyCode), periodText);

  return text.join(' ');
};
