import { StatsEmitterService } from 'pf-frontend-common/src/service/stats-emitter/service';

import { configGetCountry } from 'common/helper/config/get-country';
import { configGetEnv } from 'common/helper/config/get-env';
import { configGetLanguage } from 'common/helper/config/get-language';
import { StatsConfiguratorFactory } from 'common/module/stats/configurator.factory';
import { StatsServiceInterface } from 'common/service/stats/service.interface';

// Initialize stats configurator
StatsConfiguratorFactory().initialize({
  country: configGetCountry(),
  environment: configGetEnv(),
  language: configGetLanguage(),
});

// Get stats emitter service instance
const statsService = StatsEmitterService();

export function StatsService(): StatsServiceInterface {
  return statsService;
}
