import { ConfigLanguageCodeEnum } from 'common/helper/config/language-code.enum';
import { DateOptionsInterface } from 'common/helper/date/options.interface';
import { DateOptionsValueEnum } from 'common/helper/date/options-value.enum';

/**
 * Returns date string in locale readable format
 *
 * @example
 * If current language is Arabic
 * output: ٢٣ مايو ٢٠١٨
 *
 * If current language is French
 * output: 23 mai 2018
 *
 * If current language is English
 * output: 23 May 2018
 *
 * If current language is "arGb"
 * output: 23 مايو 2018
 */
export function dateToLocale(
  date: string,
  currentLanguage: ConfigLanguageCodeEnum,
  options: DateOptionsInterface = {
    day: DateOptionsValueEnum.numeric,
    month: DateOptionsValueEnum.short,
    year: DateOptionsValueEnum.numeric,
  }
): string {
  let locale: string;

  switch (currentLanguage) {
    case ConfigLanguageCodeEnum.ar:
      locale = 'ar-EG';
      break;
    case ConfigLanguageCodeEnum.fr:
      locale = 'fr-FR';
      break;
    case ConfigLanguageCodeEnum.arGb:
      locale = 'ar-GB';
      break;
    default:
      locale = 'en-GB';
  }

  return new Date(date).toLocaleString(locale, options);
}
