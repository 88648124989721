import { h } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';

import { functionSelf } from 'common/helper/function/self';
import { RangeSelectorFieldsEnum } from 'library/range-selector/fields.enum';
import { rangeSelectorGetSwitchedValues } from 'library/range-selector/get-switched-values';

import { RangeSelectorInputComponentPropsInterface } from './component-props.interface';
import { RangeSelectorInputTemplate } from './template';

export const RangeSelectorInputComponent = (props: RangeSelectorInputComponentPropsInterface) => {
  let caretPositionRef = useRef<number>();
  const mappingFieldsRef = {
    [RangeSelectorFieldsEnum.min]: useRef<HTMLDivElement>(),
    [RangeSelectorFieldsEnum.max]: useRef<HTMLDivElement>(),
  };
  const [listOpenedOn, setListOpenedOn] = useState<RangeSelectorFieldsEnum | null>(null);
  const commaRegex = /,/g;

  useEffect(() => {
    if (listOpenedOn) {
      const { current: cursorIndex } = caretPositionRef;
      mappingFieldsRef[listOpenedOn].current.querySelector('input').setSelectionRange(cursorIndex, cursorIndex);
    }
  }, [props.value]);

  const onInputBlur = (e: Event) => {
    const value = rangeSelectorGetSwitchedValues({
      ...props.value,
      [listOpenedOn]: (e.target as HTMLInputElement).value.replace(commaRegex, ''),
    });
    setListOpenedOn(null);
    props.onChange(value);
  };

  const onOptionSelect = (selectedValue: string) => {
    const value = {
      ...props.value,
      [listOpenedOn]: selectedValue.replace(commaRegex, ''),
    };
    props.onChange(value);
    caretPositionRef = useRef<number>();
    setListOpenedOn(null);
  };

  const onInputChange = (field: RangeSelectorFieldsEnum) => (value: string) => {
    props.onChange({
      ...props.value,
      [field]: value.replace(commaRegex, ''),
    });
  };

  return (
    <RangeSelectorInputTemplate
      inputContainerClassName={props.inputContainerClassName}
      value={props.value}
      options={props.options}
      minRef={mappingFieldsRef[RangeSelectorFieldsEnum.min]}
      maxRef={mappingFieldsRef[RangeSelectorFieldsEnum.max]}
      minPlaceholder={props.minPlaceholder}
      maxPlaceholder={props.maxPlaceholder}
      mapToLabel={props.mapToLabel || functionSelf}
      caretPositionRef={caretPositionRef}
      listOpenedOn={listOpenedOn}
      onInputClick={setListOpenedOn}
      onInputBlur={onInputBlur}
      onOptionSelect={onOptionSelect}
      onInputChange={onInputChange}
    />
  );
};
