import { StatsContextLocalInterface } from '@propertyfinder/pf-frontend-common/src/module/stats/context/local.interface';
import { StatsContextLeadInterface } from 'pf-frontend-common/src/module/stats/context/lead.interface';

import { StatsTealiumConversionEvent } from 'common/module/stats/tealium/conversion-event';
import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventLabelEnum } from 'common/module/stats/tealium/data-layer/event-label.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from 'common/module/stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { StatsCtaPropertyLoggerPropsInterface } from 'common/module/stats-cta-property/logger-props.interface';
import { StatsServiceInterface } from 'common/service/stats/service.interface';

export class StatsCtaPropertyStore {
  /**
   * Constructor
   */
  constructor(private statsService: StatsServiceInterface, private statsTealium: StatsTealiumProviderInterface) {}

  /**
   * Log stats when clicking on call back cta
   */
  public logCtaCallBack = (props: StatsCtaPropertyLoggerPropsInterface): void => {
    this.logLeadClick({
      lead: {
        medium: 'call_back',
        cta: 'button',
      },
      ...props,
    });
    this.statsTealium.send(
      StatsTealiumConversionEvent(StatsTealiumDataLayerTealiumEventEnum.callBack, props.propertyModel, {
        eventAction: StatsTealiumDataLayerEventActionEnum.call,
        eventLabel: StatsTealiumDataLayerEventLabelEnum.callBack,
        isTransactionalEvent: true,
      })
    );
  };
  /**
   * Log stats when clicking on email cta
   */
  public logCtaEmail = (props: StatsCtaPropertyLoggerPropsInterface): void => {
    this.logLeadClick({
      lead: {
        medium: 'email',
        cta: 'button',
      },
      ...props,
    });
  };

  /**
   * Log stats when clicking on whatsapp cta
   */
  public logCtaWhatsApp = (props: StatsCtaPropertyLoggerPropsInterface): void => {
    const leadSend: StatsContextLocalInterface = {
      ...props.statsContextLocal,
      lead: {
        medium: 'email',
        email: {
          name: 'whatsapp',
          email: 'whatsapp@whatsapp.com',
          message: 'whatsapp',
          phone: '0000000000',
        },
        cta: 'button',
        variation: props.statsContextLocal?.lead?.variation,
      },
    };
    const leadClick: StatsContextLocalInterface = {
      lead: {
        medium: 'whatsapp',
        cta: 'button',
        variation: props.statsContextLocal?.lead?.variation,
      },
    };

    // Track GTM whatsapp lead click event
    this.statsService.propertyLeadClickWhatsapp(parseInt(props.propertyModel.id, 10), leadClick);

    // Track snoplow/snoplow2 whatsapp lead send event
    this.statsService.propertyLeadSendWhatsapp(parseInt(props.propertyModel.id, 10), leadSend);

    this.statsTealium.send(
      StatsTealiumConversionEvent(StatsTealiumDataLayerTealiumEventEnum.whatsappNow, props.propertyModel, {
        eventAction: StatsTealiumDataLayerEventActionEnum.whatsapp,
        isTransactionalEvent: true,
      })
    );
  };

  /**
   * Log call link click
   */
  public logCallLink = (props: StatsCtaPropertyLoggerPropsInterface): void => {
    this.logLeadClick({
      lead: {
        medium: 'phone',
        cta: 'link',
        variation: 'content-reveal',
      },
      ...props,
    });

    this.statsTealium.send(
      StatsTealiumConversionEvent(StatsTealiumDataLayerTealiumEventEnum.callNow, props.propertyModel, {
        eventAction: StatsTealiumDataLayerEventActionEnum.call,
        eventLabel: StatsTealiumDataLayerEventLabelEnum.callDirect,
        isTransactionalEvent: true,
      })
    );
  };

  /**
   * Log call button click
   */
  public logCallButton = (props: StatsCtaPropertyLoggerPropsInterface): void => {
    this.logLeadClick({
      lead: {
        medium: 'phone',
        cta: 'button',
        variation: 'content-reveal',
      },
      ...props,
    });
    this.statsTealium.send(
      StatsTealiumConversionEvent(StatsTealiumDataLayerTealiumEventEnum.callNow, props.propertyModel, {
        eventAction: StatsTealiumDataLayerEventActionEnum.call,
        eventLabel: StatsTealiumDataLayerEventLabelEnum.callDirect,
        isTransactionalEvent: true,
      })
    );
  };

  /**
   * Log cta call button click
   */
  public logCtaCallButton = (props: StatsCtaPropertyLoggerPropsInterface): void => {
    this.logLeadClick({
      lead: {
        medium: 'phone',
        cta: 'button',
      },
      ...props,
    });

    this.statsTealium.send(
      StatsTealiumConversionEvent(StatsTealiumDataLayerTealiumEventEnum.callNow, props.propertyModel, {
        eventAction: StatsTealiumDataLayerEventActionEnum.call,
        eventLabel: StatsTealiumDataLayerEventLabelEnum.callDirect,
        isTransactionalEvent: true,
      })
    );
  };

  /**
   * Log cta sms button click
   */
  public logCtaSmsButton = (props: StatsCtaPropertyLoggerPropsInterface): void => {
    this.logLeadClick({
      lead: {
        medium: 'sms',
        cta: 'button',
      },
      ...props,
    });

    this.statsTealium.send(
      StatsTealiumConversionEvent(StatsTealiumDataLayerTealiumEventEnum.smsNow, props.propertyModel, {
        eventAction: StatsTealiumDataLayerEventActionEnum.sms,
        isTransactionalEvent: true,
      })
    );
  };

  /**
   * Log lead click
   */
  private logLeadClick(
    props: {
      lead: StatsContextLeadInterface;
    } & StatsCtaPropertyLoggerPropsInterface
  ): void {
    this.statsService.propertyLeadClick(parseInt(props.propertyModel.id, 10), {
      ...props.statsContextLocal,
      lead: props.lead,
    });
  }
}
