import { Link, LinkSizesEnum, LinkThemesEnum } from '@propertyfinder/link';
import { Fragment, FunctionalComponent, h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';

import { agentHubGetLink } from './get-link';

export const HomePageAgentHubCardTemplate: FunctionalComponent<{ onClickListWithUs: () => void }> = ({
  onClickListWithUs,
}) => (
  <Fragment>
    <div className='agent-hub-card__caption'>{i18nTranslate('home-page:agent-hub')}</div>
    <div className='agent-hub-card__title'>{i18nTranslate('home-page:list-your-properties-with-us')}</div>
    <Link
      href={agentHubGetLink()}
      theme={LinkThemesEnum.PRIMARY}
      size={LinkSizesEnum.SMALL}
      className='agent-hub-card__link'
      onClick={onClickListWithUs}
      target='_blank'
    >
      {i18nTranslate('home-page:get-started')}
    </Link>
  </Fragment>
);
