import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { AutocompleteStoreInterface } from 'common/module/autocomplete/store.interface';
import { AutocompleteStoreCacheableDecorator } from 'common/module/autocomplete/store/cacheable-decorator';
import { AutocompleteCacheAgentsService } from 'common/service/autocomplete-cache-agents/service';
import { AutocompleteCacheBrokersService } from 'common/service/autocomplete-cache-brokers/service';
import { AutocompleteCacheLocationsService } from 'common/service/autocomplete-cache-locations/service';

export function AutocompleteStoreCacheableDecoratorFactory(
  autocompleteStore: AutocompleteStoreInterface
): AutocompleteStoreInterface {
  return new AutocompleteStoreCacheableDecorator(
    autocompleteStore,
    AutocompleteCacheLocationsService(),
    AutocompleteCacheBrokersService(),
    AutocompleteCacheAgentsService(),
    WindowService()
  );
}
