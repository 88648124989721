export const enum LocationTypeEnum {
  tower = 'TOWER',
  city = 'CITY',
  community = 'COMMUNITY',
  subcommunity = 'SUBCOMMUNITY',
  town = 'TOWN',
  district = 'DISTRICT',
  street = 'STREET',
  compound = 'COMPOUND',
  subdistrict = 'SUBDISTRICT',
  region = 'REGION',
  area = 'AREA',
}
