import { ApiService } from 'pf-frontend-common/src/service/api/service';

import { MyNotesModel } from 'common/data/my-notes/model';
import { MyNotesStore } from 'common/data/my-notes/store';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

export function MyNotesStoreFactory(): MyNotesStore {
  return new MyNotesStore(
    ApiService(),
    ApiEndpointService(),
    UserAuthenticationService(),
    JsonApiStoreFactory<MyNotesModel>('my_note')
  );
}
