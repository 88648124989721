import { BrowserStorageInterface } from 'pf-frontend-common/src/module/browser/storage.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiModelInterface } from 'pf-frontend-common/src/module/json-api/model.interface';

import { AutocompleteCacheStoreInterface } from 'common/data/autocomplete/cache/store.interface';
import { AutocompleteCacheWorkerMessageType } from 'common/data/autocomplete/cache/types';
import { AutocompleteCacheWorkerEvent } from 'common/data/autocomplete/cache/worker.event';
import { AutocompleteCacheWorkerMessageFetchCacheInterface } from 'common/data/autocomplete/cache/worker-message/fetch-cache.interface';
import { AutocompleteCacheWorkerMessageFetchChecksumInterface } from 'common/data/autocomplete/cache/worker-message/fetch-checksum.interface';
import { AutocompleteCacheWorkerMessageOpenDatabaseToUpdateCacheInterface } from 'common/data/autocomplete/cache/worker-message/open-database-to-update-cache.interface';
import { AutocompleteCacheWorkerMessageOpenDatabaseToUpdateItselfInterface } from 'common/data/autocomplete/cache/worker-message/open-database-to-update-itself.interface';
import { BackendConfigLanguageInterface } from 'common/data/backend/config/language.interface';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { WorkerMessageInterface } from 'common/module/worker/message.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { DatabaseServiceInterface } from 'common/service/database/service.interface';

export abstract class AutocompleteCacheStore<DataStore> implements AutocompleteCacheStoreInterface {
  /**
   * Api endpoint
   */
  protected abstract endpoint: string;

  /**
   * Checksum key
   */
  protected abstract checksumKey: string;

  /**
   * Data key
   */
  protected abstract dataKey: DatabaseTableEnum;

  /**
   * Autocomplete etag
   */
  protected etag: string;

  /**
   * Constructor
   */
  public constructor(
    protected localStorage: BrowserStorageInterface,
    protected languageConfig: BackendConfigLanguageInterface,
    protected apiEndpointService: ApiEndpointServiceInterface,
    protected databaseService: DatabaseServiceInterface,
    protected dataStore: DataStore,
    protected worker: Worker,
    protected origin: string
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(): void {
    this.worker.addEventListener('message', this.onMessageWorker);

    this.fetchChecksum();
  }

  /**
   * @inheritDoc
   */
  public getDataStore(): DataStore {
    return this.dataStore;
  }

  /**
   * @inheritDoc
   */
  public abstract getCache(): JsonApiModelInterface[];

  /**
   * Fetch cache checksum
   *
   * @param endpoint - checksum endpoint
   */
  protected fetchChecksum(): void {
    const eventType: AutocompleteCacheWorkerMessageFetchChecksumInterface = {
      type: AutocompleteCacheWorkerEvent.fetchChecksum,
      endpoint: this.origin + this.apiEndpointService.getPath(this.endpoint),
    };

    this.worker.postMessage(eventType);
  }

  /**
   * Reset data store
   */
  protected abstract resetDataStore(): void;

  /**
   * Initialize data store from http response
   */
  protected abstract initializeDataStore(payload: HttpApiResponseInterface): void;

  /**
   * Add model to data store
   */
  protected abstract addToDataStore(models: JsonApiModelInterface[]): void;

  /**
   * Fetch cache
   *
   * @param endpoint - cache endpoint
   */
  protected fetchCache(endpoint: string): void {
    const eventType: AutocompleteCacheWorkerMessageFetchCacheInterface = {
      type: AutocompleteCacheWorkerEvent.fetchCache,
      endpoint: this.origin + this.apiEndpointService.getPath(endpoint),
    };

    this.worker.postMessage(eventType);
  }

  /**
   * Decorates key with language specific data
   */
  private decorateKey(key: string): string {
    return this.languageConfig.current + '_' + key;
  }

  /**
   * Fetch Checksum completed
   */
  private fetchCacheOrUpdateItFromDatabase = (etag: string): void => {
    if (!etag) {
      this.fetchCache(this.endpoint);

      return;
    }

    if (etag !== this.localStorage.getData(this.decorateKey(this.checksumKey))) {
      this.fetchCache(this.endpoint);

      return;
    }

    this.resetDataStore();

    const eventType: AutocompleteCacheWorkerMessageOpenDatabaseToUpdateCacheInterface = {
      type: AutocompleteCacheWorkerEvent.openDatabaseToUpdateCache,
      languages: this.languageConfig,
      dataKey: this.decorateKey(this.dataKey),
    };

    this.worker.postMessage(eventType);
  };

  /**
   * Fetch cache completed
   */
  private updateDatabaseWithDataFromCache = (result: HttpApiResponseInterface) => {
    this.resetDataStore();
    this.initializeDataStore(result);

    this.etag = result.headers.etag;

    const dataKey = this.decorateKey(this.dataKey);

    const eventType: AutocompleteCacheWorkerMessageOpenDatabaseToUpdateItselfInterface = {
      type: AutocompleteCacheWorkerEvent.openDatabaseToUpdateItself,
      dataKey,
      cache: this.getCache(),
      languages: this.languageConfig,
    };

    this.worker.postMessage(eventType);
  };

  /**
   * Handle worker messages
   */
  private onMessageWorker = (event: WorkerMessageInterface<AutocompleteCacheWorkerMessageType>) => {
    if (event.data.type === AutocompleteCacheWorkerEvent.databaseUpdatedItself) {
      this.localStorage.setData(this.decorateKey(this.checksumKey), this.etag);
    }

    if (event.data.type === AutocompleteCacheWorkerEvent.checksumFetched) {
      this.fetchCacheOrUpdateItFromDatabase(event.data.etag);
    }

    if (event.data.type === AutocompleteCacheWorkerEvent.cacheFetched) {
      this.updateDatabaseWithDataFromCache(event.data.result);
    }

    if (event.data.type === AutocompleteCacheWorkerEvent.addToDataStore) {
      this.addToDataStore(event.data.value);
    }
  };
}
