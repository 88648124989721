import { MyAccountNotesStatechartStateEnum } from 'common/module/my-account/notes/statechart/state.enum';
import { MyAccountStatechartEvent } from 'common/module/my-account/statechart.event';
import { MyAccountStatechartActionEnum } from 'common/module/my-account/statechart/action.enum';

export const MyAccountNotesPropertiesStatechart = {
  initial: MyAccountNotesStatechartStateEnum.idle,
  states: {
    [MyAccountNotesStatechartStateEnum.idle]: {
      on: {
        [MyAccountStatechartEvent.fetchNotesProperties]: MyAccountNotesStatechartStateEnum.start,
        [MyAccountStatechartEvent.fetchNotesSuccess]: {
          target: MyAccountNotesStatechartStateEnum.fetchingNotesProperties,
          actions: [MyAccountStatechartActionEnum.updateNotesInStore],
        },
      },
    },
    [MyAccountNotesStatechartStateEnum.start]: {
      onEntry: [MyAccountStatechartActionEnum.fetchNotes],
      on: {
        [MyAccountStatechartEvent.fetchNotesSuccess]: {
          target: MyAccountNotesStatechartStateEnum.fetchingNotesProperties,
          actions: [MyAccountStatechartActionEnum.updateNotesInStore],
        },
      },
    },
    [MyAccountNotesStatechartStateEnum.fetchingNotesProperties]: {
      onEntry: [MyAccountStatechartActionEnum.fetchNotesProperties],
      on: {
        [MyAccountStatechartEvent.fetchNotesProperties]: {
          target: MyAccountNotesStatechartStateEnum.fetchingNotesProperties,
          actions: [MyAccountStatechartActionEnum.cancelPreviousFetch],
        },
        [MyAccountStatechartEvent.fetchNotesPropertiesSuccess]: MyAccountNotesStatechartStateEnum.idle,
      },
    },
  },
};
