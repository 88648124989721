import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';

import { FilterParametersInterface } from '../../parameters.interface';
import { FilterParamsEnum } from '../../params.enum';
import { FilterSearchQueryInterface } from '../query.interface';
import { FilterSearchUrlParamsEnum } from './params.enum';

export const filterSearchUrlSerialize = (params: FilterParametersInterface): FilterSearchQueryInterface => {
  return objectFilterNonOrEmptyValue<FilterSearchQueryInterface>({
    [FilterSearchUrlParamsEnum.query]: params[FilterParamsEnum.query]?.value,
    [FilterSearchUrlParamsEnum.locationsIds]: params[FilterParamsEnum.locationsIds]?.value.join('-'),
    [FilterSearchUrlParamsEnum.categoryId]: params[FilterParamsEnum.categoryId]?.value,
    [FilterSearchUrlParamsEnum.propertyTypeId]: params[FilterParamsEnum.propertyTypeId]?.value,
    [FilterSearchUrlParamsEnum.minPrice]: params[FilterParamsEnum.minPrice]?.value,
    [FilterSearchUrlParamsEnum.maxPrice]: params[FilterParamsEnum.maxPrice]?.value,
    [FilterSearchUrlParamsEnum.furnishing]: params[FilterParamsEnum.furnishing]?.value,
    [FilterSearchUrlParamsEnum.minArea]: params[FilterParamsEnum.minArea]?.value,
    [FilterSearchUrlParamsEnum.maxArea]: params[FilterParamsEnum.maxArea]?.value,
    [FilterSearchUrlParamsEnum.pricePeriod]: params[FilterParamsEnum.pricePeriod]?.value,
    [FilterSearchUrlParamsEnum.keyword]: params[FilterParamsEnum.keyword]?.value,
    [FilterSearchUrlParamsEnum.amenities]: params[FilterParamsEnum.amenities]?.value,
    [FilterSearchUrlParamsEnum.bedrooms]: params[FilterParamsEnum.bedrooms]?.value,
    [FilterSearchUrlParamsEnum.bathrooms]: params[FilterParamsEnum.bathrooms]?.value,
    [FilterSearchUrlParamsEnum.completionStatus]: params[FilterParamsEnum.completionStatus]?.value,
    [FilterSearchUrlParamsEnum.utilitiesPriceType]: params[FilterParamsEnum.utilitiesPriceType]?.value,
    [FilterSearchUrlParamsEnum.virtualViewings]: params[FilterParamsEnum.virtualViewings]?.value,
    [FilterSearchUrlParamsEnum.pageNumber]: params[FilterParamsEnum.pageNumber]?.value,
    [FilterSearchUrlParamsEnum.isDeveloperProperty]: params[FilterParamsEnum.isDeveloperProperty]?.value,
    [FilterSearchUrlParamsEnum.minInstallmentYears]: params[FilterParamsEnum.minInstallmentYears]?.value,
    [FilterSearchUrlParamsEnum.maxInstallmentYears]: params[FilterParamsEnum.maxInstallmentYears]?.value,
    [FilterSearchUrlParamsEnum.verified]: params[FilterParamsEnum.verified]?.value,
    [FilterSearchUrlParamsEnum.trustedAgent]: params[FilterParamsEnum.trustedAgent]?.value,
    [FilterSearchUrlParamsEnum.sort]: params[FilterParamsEnum.sort]?.value,
    [FilterSearchUrlParamsEnum.listedWithin]: params[FilterParamsEnum.listedWithin]?.value,
    [FilterSearchUrlParamsEnum.paymentMethods]: params[FilterParamsEnum.paymentMethods]?.value,
  });
};
