import { WindowService } from 'pf-frontend-common/dist/service/window/service';
import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';

/**
 * Returns list of translations
 */
export function configGetTranslations(): DataKeyValueStringInterface {
  return WindowService().getNative().propertyfinder && WindowService().getNative().propertyfinder.translations
    ? WindowService().getNative().propertyfinder.translations
    : {};
}
