import { PropertyCtaStatechart } from 'common/module/property-cta/statechart';
import { StatechartStoreFactory } from 'common/module/statechart/store.factory';

const service = StatechartStoreFactory();

service.initialize({
  statechart: PropertyCtaStatechart,
});

export const PropertyCtaStatechartService = () => service;
