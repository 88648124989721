import { Fragment, FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { DropdownWrapperComponent } from 'common/module/dropdown-wrapper/component';
import {
  IconChevronTemplate,
  IconCrossTemplate,
  IconMagnifierTemplate,
  IconThickAmenitiesTemplate,
} from 'common/module/icon';
import { LanguageContext } from 'common/module/language/context';
import { DropdownBodyPositionEnum } from 'library/dropdown/body-position.enum';
import { DropdownComponent } from 'library/dropdown/component';
import { DropdownVariantEnum } from 'library/dropdown/variant.enum';
import { MultipleSelectionComponent } from 'library/multiple-selection/component';
import { TextFieldComponent } from 'library/text-field/component';

import { amenitiesSelectorComputeText } from './compute-text';
import { AmenitiesSelectorPropsInterface } from './props.interface';
import { AmenitiesSelectorStateInterface } from './state.interface';

const Content: FunctionalComponent<AmenitiesSelectorPropsInterface & AmenitiesSelectorStateInterface> = (props) => (
  <Fragment>
    {props.searchable && (
      <div className='amenities-selector__search'>
        <TextFieldComponent
          prefix={
            <div
              className={`amenities-selector__search-icon-container${
                !props.query ? ' amenities-selector__search-icon-container--grey' : ''
              }`}
            >
              <IconMagnifierTemplate clipped={false} />
            </div>
          }
          suffix={
            props.query && (
              <button className='amenities-selector__clear-icon' onClick={() => props.onSearch('')}>
                <IconCrossTemplate clipped={false} />
              </button>
            )
          }
          suffixCssClass='amenities-selector__clear-icon-container'
          floatPlaceholder={false}
          placeholder={i18nTranslate('Search amenities...')}
          onValueChange={(query) => props.onSearch(query)}
          value={props.query}
        />
      </div>
    )}
    <MultipleSelectionComponent
      className='amenities-selector__multiple-selection'
      columns={props.columns}
      options={props.displayedOptions}
      optionsPrefix='amenities'
      onChange={props.onSelection}
    />
    {props.searchable && props.displayNotFoundMessage && (
      <span
        dangerouslySetInnerHTML={{
          __html: i18nTranslate('Amenities selector error').replace('{query}', props.query),
        }}
      />
    )}
  </Fragment>
);

const InDropdown: FunctionalComponent<AmenitiesSelectorPropsInterface & AmenitiesSelectorStateInterface> = (props) => {
  const showActions: boolean = props.options.some(({ checked }) => checked);
  const { isRtl } = useContext(LanguageContext);

  return (
    <DropdownComponent
      {...props}
      label={i18nTranslate('Amenities')}
      position={isRtl ? DropdownBodyPositionEnum.bottomRight : DropdownBodyPositionEnum.bottomLeft}
      variant={DropdownVariantEnum.primary}
      text={amenitiesSelectorComputeText(props.options)}
      onChangeOpen={props.onDropdownStatusChange}
    >
      <DropdownWrapperComponent
        showActions
        actions={
          showActions
            ? [
                <button className='amenities-selector__clear-btn' type='button' onClick={props.onResetClick}>
                  {i18nTranslate('Reset')}
                </button>,
              ]
            : undefined
        }
        wrapperClassName='amenities-selector__content'
      >
        <Content {...props} />
      </DropdownWrapperComponent>
    </DropdownComponent>
  );
};

const InModal: FunctionalComponent<AmenitiesSelectorPropsInterface & AmenitiesSelectorStateInterface> = (props) => (
  <Fragment>
    <h3 className='amenities-selector__title'>
      <IconThickAmenitiesTemplate class='amenities-selector__title-icon' clipped={true} /> {i18nTranslate('Amenities')}
    </h3>
    <div className={`amenities-selector__more-options ${!props.showAllAmenities && 'amenities-selector__clipped'}`}>
      <Content {...props} />
    </div>
    <button className='amenities-selector__show-all-btn' type='button' onClick={props.onShowAllAmenitiesButtonClick}>
      <span className='amenities-selector__show-all-label'>
        {i18nTranslate(props.showAllAmenities ? 'Hide amenities' : 'Show all amenities')}
      </span>
      <IconChevronTemplate
        class={`amenities-selector__chevron ${props.showAllAmenities ? 'amenities-selector__chevron--up' : ''}`}
      />
    </button>
  </Fragment>
);

export const AmenitiesSelectorComponentTemplate: FunctionalComponent<
  AmenitiesSelectorPropsInterface & AmenitiesSelectorStateInterface
> = (props) => {
  const { style, ...rest } = props;
  return (
    <div style={props.style} className={['amenities-selector'].concat(props.className || []).join(' ')}>
      {props.dropdownEnabled ? <InDropdown {...rest} /> : <InModal {...rest} />}
    </div>
  );
};
