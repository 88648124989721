import { AbTestStatsGtmDataLayerInterface } from 'common/module/ab-test/stats/gtm/data-layer.interface';

/**
 * Transforms ab test and active variant to gtm data layer object
 */
export const abTestStatsGtmTransformer = (test: string, variant: string): AbTestStatsGtmDataLayerInterface => ({
  event: 'customEvent',
  eventCategory: 'Experiments',
  eventAction: `${test}-${variant}`,
  eventLabel: '',
});
