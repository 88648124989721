import { functionNoop } from '@propertyfinder/helpers';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { SaveSearchStore } from 'common/data/save-search/store';
import { UserModel } from 'common/data/user/model';
import { DataStore } from 'common/module/data/store';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { UserAvatarBundleSettingsInterface } from 'common/module/user/avatar/bundle-settings.interface';
import { UserAvatarTemplatePropsInterface } from 'common/module/user/avatar/template-props.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

export class UserAvatarViewStore extends DataStore<UserAvatarTemplatePropsInterface> {
  /**
   * @inheritdoc
   */
  protected state: UserAvatarTemplatePropsInterface;

  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface,
    private saveSearchStoreService: SaveSearchStore,
    private settings: UserAvatarBundleSettingsInterface
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritDoc
   */
  public initialize(options: { willFetchSavedSearches?: boolean } = {}): void {
    this.state = {
      userAvatar: null,
      isLoggedIn: false,
      isHeaderLoginButtonHidden: this.settings.isHeaderLoginButtonHidden,
      onClickUserAvatar: functionNoop,
    };

    this.updateUserImage();
    this.updateIsLoggedIn();

    if (options.willFetchSavedSearches) {
      this.fetchSavedSearches();
    }

    // Attach observers
    this.observers();
  }

  /**
   * Fetched user's saved searches
   */
  private fetchSavedSearches(): void {
    this.saveSearchStoreService.getAll(this.getState().isLoggedIn).then(() => {
      this.userAuthenticationService.getEventEmitter().emit(UserAuthenticationEvent.searchIsFetched);
    });
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInChanged);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onSignInChanged);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.logOutSucceeded, this.onSignInChanged);
  }

  /**
   * Render User Avatar Image
   */
  private updateUserImage(): void {
    const userModel = this.userAuthenticationService.getUser() || new UserModel();
    const isLoggedIn = !!this.userAuthenticationService.getToken();

    if (isLoggedIn) {
      this.state.userAvatar = {
        image: userModel.image,
      };
    } else {
      this.state.userAvatar = null;
    }

    this.setState(this.state);
  }

  /**
   * Show or hide this element
   */
  private updateIsLoggedIn(): void {
    this.state.isLoggedIn = !!this.userAuthenticationService.getToken();
    this.setState(this.state);
  }

  /**
   * Sign in changed
   */
  private onSignInChanged = (): void => {
    this.updateUserImage();
    this.updateIsLoggedIn();
    this.fetchSavedSearches();
  };
}
