import { ViewOptionsInterface } from 'pf-frontend-common/src/module/view/options.interface';
import { ComponentFactory, h } from 'preact';
// TODO-FE[https://pfinder.atlassian.net/browse/WEB-6238] - change to standard render method
import { render } from 'preact/compat';

import { DataStoreEvent } from 'common/module/data/store.event';
import { DataStoreInterface } from 'common/module/data/store.interface';
import { ViewDynamicInterface } from 'common/module/view/dynamic.interface';

import { PreactPropsInterface } from './props.interface';

/**
 * @deprecated
 *
 * This view has a BUG! View will be rendered any time view store is initialized
 * even though it wasn't rendered yet using view.render() method
 */
export class PreactView<ViewStore extends DataStoreInterface> implements ViewDynamicInterface<ViewStore> {
  /**
   * Initial HTML element which would be modified by Template preact component
   */
  protected element: HTMLElement;

  /**
   * If element is rendered
   */
  protected isRendered: boolean;

  /**
   * Constructor
   */
  constructor(
    protected Template: ComponentFactory<PreactPropsInterface>,
    private viewStore: ViewStore,
    // TODO-FE[https://pfinder.atlassian.net/browse/TPNX-796] - remove this
    private options?: {
      isAutoRenderDisabled: boolean;
    }
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: ViewOptionsInterface): void {
    this.element = options.element;

    this.viewStore.getEventEmitter().addListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    const Template = this.Template;

    if (this.element) {
      render(<Template {...this.getViewStore().getState()} />, this.element);
      this.isRendered = true;
    }
  }

  /**
   * Return view store
   */
  public getViewStore(): ViewStore {
    return this.viewStore;
  }

  /**
   * Destroy view
   */
  public destroy(): void {
    this.viewStore.getEventEmitter().removeListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);

    if (this.isRendered) {
      render(null, this.element);
    }
  }

  /**
   * Data store state updated
   */
  private onUpdateStateDataStore = () => {
    if (!this.isRendered) {
      // Disable render on update if render method wasn't called yet.
      // TODO-FE[https://pfinder.atlassian.net/browse/TPNX-796] - remove this
      if (this.options?.isAutoRenderDisabled) {
        return;
      }

      // eslint-disable-next-line no-console
      console.warn(`This view was not rendered manually, but using auto render: ${this.Template.name}`);
    }

    this.render();
  };
}
