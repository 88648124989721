import { BrowserStorageLocalServiceInterface } from 'pf-frontend-common/src/service/browser-storage-local/service.interface';

import { NATIVE_APP_DRAWER_STORAGE_KEY } from 'mobile/module/native-app-drawer/storage-key.constant';

export function appBannerIsVisible(browserStorageLocal: BrowserStorageLocalServiceInterface): boolean {
  const storageValue = browserStorageLocal.getData(NATIVE_APP_DRAWER_STORAGE_KEY) as null | {
    expiry?: number;
  };
  return !storageValue || new Date().getTime() > storageValue.expiry;
}
