import { Button } from '@propertyfinder/button';
import { domClassMerge } from '@propertyfinder/helpers';
import { IconChevronLeft, IconChevronRight } from '@propertyfinder/icons';
import { FunctionComponent, h, RefObject } from 'preact';
import { useMemo } from 'preact/hooks';

export const NavigationArrowsComponent: FunctionComponent<{
  currentGaleryIndex: number;
  isRtl: boolean;
  numberOfItems: number;
  nextRef: RefObject<any>;
  prevRef: RefObject<any>;
  onClick?: (direction: 'next' | 'previous') => void;
}> = ({
  currentGaleryIndex,
  isRtl,
  numberOfItems,

  nextRef,
  prevRef,
  onClick,
}) => {
  const NextIcon = useMemo(() => (isRtl ? IconChevronLeft : IconChevronRight), []);
  const PrevIcon = useMemo(() => (isRtl ? IconChevronRight : IconChevronLeft), []);

  return (
    <div className='navigation-arrows'>
      <Button
        className={domClassMerge('navigation-arrows__arrow', {
          'navigation-arrows__arrow--disabled': currentGaleryIndex === 0,
        })}
        onClick={(): void => {
          prevRef?.current?.();
          onClick?.('previous');
        }}
      >
        <PrevIcon />
      </Button>
      <Button
        className={domClassMerge('navigation-arrows__arrow', {
          'navigation-arrows__arrow--disabled': currentGaleryIndex === numberOfItems - 1,
        })}
        onClick={(): void => {
          nextRef?.current?.();
          onClick?.('next');
        }}
      >
        <NextIcon />
      </Button>
    </div>
  );
};
