import { StatsContextLocalInterface } from 'pf-frontend-common/src/module/stats/context/local.interface';

import { PropertyModel } from 'common/data/property/model';
import { PropertyCtaStatechartActionEnum } from 'common/module/property-cta/statechart/action.enum';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { StatsCtaPropertyLoggerPropsInterface } from 'common/module/stats-cta-property/logger-props.interface';
import { StatsCtaPropertyStore } from 'common/module/stats-cta-property/store';

export class PropertyCtaStatsStore {
  /**
   * Constructor
   */
  constructor(
    private propertyCtaStatechartService: StatechartStoreInterface,
    private statsCtaPropertyService: StatsCtaPropertyStore
  ) {}

  /**
   * Initialize store
   */
  public initialize(): void {
    this.propertyCtaStatechartService
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.logStatsCallButton,
        this.logLeadClick(this.statsCtaPropertyService.logCallButton)
      );

    this.propertyCtaStatechartService
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.logStatsCtaCallButton,
        this.logLeadClick(this.statsCtaPropertyService.logCtaCallButton)
      );

    this.propertyCtaStatechartService
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.logStatsCtaSmsButton,
        this.logLeadClick(this.statsCtaPropertyService.logCtaSmsButton)
      );

    this.propertyCtaStatechartService
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.logStatsCtaWhatsAppButton,
        this.logLeadClick(this.statsCtaPropertyService.logCtaWhatsApp)
      );

    this.propertyCtaStatechartService
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.logStatsCtaEmailButton,
        this.logLeadClick(this.statsCtaPropertyService.logCtaEmail)
      );

    this.propertyCtaStatechartService
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.logStatsCtaCallBackButton,
        this.logLeadClick(this.statsCtaPropertyService.logCtaCallBack)
      );
  }

  /**
   * Log lead click
   */
  private logLeadClick =
    (logger: (props: StatsCtaPropertyLoggerPropsInterface) => void) =>
    (payload: { propertyModel: PropertyModel; statsContextLocal: StatsContextLocalInterface }) => {
      logger({
        propertyModel: payload.propertyModel,
        statsContextLocal: payload.statsContextLocal,
      });
    };
}
