import { Component, h } from 'preact';

import { functionNoop } from 'common/helper/function/noop';
import { BedAndBathSelectorComponentPropsInterface } from 'common/module/bed-and-bath-selector/component-props.interface';
import { BedAndBathSelectorComponentStateInterface } from 'common/module/bed-and-bath-selector/component-state.interface';
import { BedAndBathSelectorTemplate } from 'common/module/bed-and-bath-selector/template';

export class BedAndBathSelectorComponent extends Component<
  BedAndBathSelectorComponentPropsInterface,
  BedAndBathSelectorComponentStateInterface
> {
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    onDropdownOpenStatusChange: functionNoop,
  };

  /**
   * @inheritDoc
   */
  public readonly state: BedAndBathSelectorComponentStateInterface = {
    isOpen: false,
  };

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return <BedAndBathSelectorTemplate {...this.props} isOpen={this.state.isOpen} />;
  }
}
