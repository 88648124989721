import { FilterParamsEnum } from 'common/module/filter/params.enum';

import { FilterParametersBaseInterface } from '../parameters-base.interface';
import { FilterSettingsTypeEnum } from './type.enum';

/**
 *  Mapping of filter settings to filter parameters
 * e.x. price -> [minPrice, maxPrice]
 */
export const FilterSettingsParametersMapping: Record<
  FilterSettingsTypeEnum,
  Array<keyof FilterParametersBaseInterface>
> = {
  [FilterSettingsTypeEnum.query]: [FilterParamsEnum.query],
  [FilterSettingsTypeEnum.keyword]: [FilterParamsEnum.keyword],
  [FilterSettingsTypeEnum.bedroom]: [FilterParamsEnum.bedrooms],
  [FilterSettingsTypeEnum.bathroom]: [FilterParamsEnum.bathrooms],
  [FilterSettingsTypeEnum.price]: [FilterParamsEnum.minPrice, FilterParamsEnum.maxPrice],
  [FilterSettingsTypeEnum.area]: [FilterParamsEnum.minArea, FilterParamsEnum.maxArea],
  [FilterSettingsTypeEnum.amenity]: [FilterParamsEnum.amenities],
  [FilterSettingsTypeEnum.completionStatus]: [FilterParamsEnum.completionStatus],
  [FilterSettingsTypeEnum.utilitiesPriceType]: [FilterParamsEnum.utilitiesPriceType],
  [FilterSettingsTypeEnum.virtualViewing]: [FilterParamsEnum.virtualViewings],
  [FilterSettingsTypeEnum.orderBy]: [FilterParamsEnum.sort],
  [FilterSettingsTypeEnum.furnishing]: [FilterParamsEnum.furnishing],
  [FilterSettingsTypeEnum.priceType]: [FilterParamsEnum.pricePeriod],
  [FilterSettingsTypeEnum.propertyType]: [FilterParamsEnum.propertyTypeId],
  [FilterSettingsTypeEnum.categoryId]: [FilterParamsEnum.categoryId],
  [FilterSettingsTypeEnum.locationIds]: [FilterParamsEnum.locationsIds],
  [FilterSettingsTypeEnum.pageLimit]: [FilterParamsEnum.pageLimit],
  [FilterSettingsTypeEnum.pageNumber]: [FilterParamsEnum.pageNumber],
  [FilterSettingsTypeEnum.isDeveloperProperty]: [FilterParamsEnum.isDeveloperProperty],
  [FilterSettingsTypeEnum.verified]: [FilterParamsEnum.verified],
  [FilterSettingsTypeEnum.trustedAgent]: [FilterParamsEnum.trustedAgent],
  [FilterSettingsTypeEnum.installmentYears]: [
    FilterParamsEnum.minInstallmentYears,
    FilterParamsEnum.maxInstallmentYears,
  ],
  [FilterSettingsTypeEnum.listedWithin]: [FilterParamsEnum.listedWithin],
  [FilterSettingsTypeEnum.paymentMethods]: [FilterParamsEnum.paymentMethods],
};
