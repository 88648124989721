import { Fragment, h } from 'preact';

import { highlightString } from 'common/helper/highlight/string';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { IconCrossTemplate } from 'common/module/icon/cross-template';
import { IconMagnifierTemplate } from 'common/module/icon/magnifier-template';

import { MultiSelectionAutocompleteChipTemplate } from './chip/template';
import { multiSelectionAutocompleteComputeInactiveRenderValue } from './compute-inactive-render-value';
import { MultiSelectionAutocompleteTemplatePropsInterface } from './template-props.interface';

export const MultiSelectionAutocompleteTemplate = <T extends unknown>(
  props: MultiSelectionAutocompleteTemplatePropsInterface<T>
) => {
  const renderInput = () => (
    <input
      value={props.inputValue}
      data-testid='input'
      className={`multi-selection-autocomplete__input
        ${props.inputValue ? 'multi-selection-autocomplete__input--not-empty' : ''}
      `}
      placeholder={props.placeholder}
      ref={props.inputRef}
      onInput={props.onInputChange}
      onFocus={props.onInputFocus}
      onKeyDown={props.onInputKeyDown}
      onKeyPress={props.onInputKeyPress}
      onKeyUp={props.onInputKeyUp}
      autoComplete='off'
    />
  );

  const renderChips = () => (
    <Fragment>
      {multiSelectionAutocompleteComputeInactiveRenderValue(props).map(({ item, title, isMore }, index) => {
        return (
          <MultiSelectionAutocompleteChipTemplate
            className={props.chipClassName}
            key={index}
            title={title}
            onCrossClick={() => props.onItemCrossClick(item, isMore)}
          />
        );
      })}
      <div className='multi-selection-autocomplete__add'>{i18nTranslate('+ Add')}</div>
    </Fragment>
  );

  const renderValueSection = () => {
    if (!props.value.length) {
      return;
    }

    return (
      <div className='multi-selection-autocomplete__selected-value-container'>
        {props.value.map((item, index) => {
          return (
            <div className='multi-selection-autocomplete__chip-wrapper__when-opened'>
              <MultiSelectionAutocompleteChipTemplate
                key={index}
                className={`multi-selection-autocomplete__chip__when-opened ${
                  props.chipClassName ? props.chipClassName : ''
                }`}
                title={props.getChipTitle(item)}
                onCrossClick={() => props.onItemCrossClick(item)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const renderSuggestionsSection = () => {
    const shouldRenderNoSuggestions = props.inputValue && !props.suggestions.length && props.renderNoSuggestions;
    if (!props.suggestions.length && !shouldRenderNoSuggestions) {
      return;
    }

    return (
      <div className='multi-selection-autocomplete__suggestions-container'>
        {shouldRenderNoSuggestions ? (
          <div className='multi-selection-autocomplete__no-suggestions'>{props.renderNoSuggestions}</div>
        ) : null}
        {props.suggestions.map((item, index) => {
          return (
            <button
              data-testid='suggestion'
              className={`multi-selection-autocomplete__suggestion
              ${index === props.selectedItemIndex ? 'selected' : ''}
            `}
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                props.suggestionOnClick(item);
              }}
            >
              {props.suggestionIcon && (
                <span className='multi-selection-autocomplete__suggestion-icon'>{props.suggestionIcon}</span>
              )}
              <span
                className='multi-selection-autocomplete__suggestion-text'
                dangerouslySetInnerHTML={{
                  __html: highlightString(props.inputValue, props.getTitle(item)),
                }}
              />
            </button>
          );
        })}
      </div>
    );
  };

  const renderDropDown = () => {
    if ((!props.suggestions.length && !props.inputValue && !props.value.length) || props.isLoading) {
      return;
    }

    return (
      <div className='multi-selection-autocomplete__dropdown' data-testid='autocomplete-dropdown'>
        <div className='multi-selection-autocomplete__separator' />
        {renderValueSection()}
        {renderSuggestionsSection()}
      </div>
    );
  };

  const renderChipsOnTheBottom = () => {
    if (!props.value.length) {
      return null;
    }

    return (
      <div className='multi-selection-autocomplete__selected-value-container--on-the-bottom'>
        {props.value.map((item, index) => {
          return (
            <div className='multi-selection-autocomplete__chip-wrapper__when-opened multi-selection-autocomplete__chip-wrapper--on-the-bottom'>
              <MultiSelectionAutocompleteChipTemplate
                key={index}
                className={`multi-selection-autocomplete__chip__when-opened ${
                  props.chipClassName ? props.chipClassName : ''
                }`}
                title={props.getChipTitle(item)}
                onCrossClick={() => props.onItemCrossClick(item)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const isActiveOrNoValue = props.isActive || !props.value.length;

  const calcContainerExtraClasses = () => {
    if (props.chipsOnTheBottom) {
      return 'multi-selection-autocomplete--on-the-bottom';
    }

    const parts = [];

    if (props.isActive) {
      parts.push('multi-selection-autocomplete--active');
    } else {
      if (props.value.length === 1) {
        parts.push('multi-selection-autocomplete--not-active-single-element');
      }
      if (props.value.length > 1) {
        parts.push('multi-selection-autocomplete--not-active-multiple-elements');
      }
    }

    return parts.join(' ');
  };

  return (
    <button
      ref={props.rootRef}
      onClick={props.onRootClick}
      onKeyDown={props.onRootKeyDown}
      data-testid='root'
      className='multi-selection-autocomplete__root'
    >
      <div
        data-testid='container'
        className={`multi-selection-autocomplete ${calcContainerExtraClasses()} ${
          props.className ? props.className : ''
        }`}
      >
        {isActiveOrNoValue && !props.chipsOnTheBottom && (
          <IconMagnifierTemplate class='multi-selection-autocomplete__search-icon' />
        )}
        {(isActiveOrNoValue || props.chipsOnTheBottom ? renderInput : renderChips)()}
        {props.isActive && props.inputValue && (
          <button className='multi-selection-autocomplete__input-cross-button' onClick={props.onInputCrossButtonClick}>
            <IconCrossTemplate class='multi-selection-autocomplete__input-cross-icon' />
          </button>
        )}
        {props.isActive && !props.chipsOnTheBottom && renderDropDown()}
      </div>
      {props.chipsOnTheBottom && renderChipsOnTheBottom()}
      <input ref={props.hiddenInputRef} className='multi-selection-autocomplete__hidden-input' />
    </button>
  );
};
