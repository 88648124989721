import { objectCompare } from 'common/helper/object/compare';
import { objectReduce } from 'common/helper/object/reduce';

import { SaveSearchFiltersInterface } from './filters.interface';

export function saveSearchFilterEquality(
  filters1: Partial<SaveSearchFiltersInterface>,
  filters2: Partial<SaveSearchFiltersInterface>
): boolean {
  return objectCompare(
    objectReduce(
      filters1,
      (acc, key, value) => ({ ...acc, [key]: typeof value === 'string' ? value.toLowerCase() : value }),
      {}
    ),
    objectReduce(
      filters2,
      (acc, key, value) => ({ ...acc, [key]: typeof value === 'string' ? value.toLowerCase() : value }),
      {}
    )
  );
}
