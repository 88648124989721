import { DomElementEvent } from 'pf-frontend-common/src/module/dom/element.event';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteViewStoreEvent } from 'common/module/autocomplete/view-store.event';
import { DataStore } from 'common/module/data/store';

import { AutocompleteResultInterface } from './result.interface';
import { AutocompleteTemplatePropsInterface } from './template-props.interface';
import { AutocompleteViewStoreInterface } from './view-store.interface';

export class AutocompleteViewStore extends DataStore implements AutocompleteViewStoreInterface {
  /**
   * @inheritDoc
   */
  protected state: AutocompleteTemplatePropsInterface;

  /**
   * Base autocomplete element. Is needed to implement close on click outside of autocomplete feature
   */
  protected base: Node;

  /**
   * Autocomplete input element
   */
  protected inputEl: HTMLInputElement;

  /**
   * Input initial value
   */
  protected inputInitialValue: string = '';

  /**
   * Whether initial value is set
   */
  protected initialValueSet: boolean = false;

  /**
   * @inheritDoc
   */
  constructor(eventEmitter: EventEmitterInterface, protected windowService: WindowServiceInterface) {
    super(eventEmitter);

    /*
      Binding context is being made here in order to
      use onClickWindow as callback for click event on window
      when autocomplete html will be placed in dom
      and remove this event listener after autocomplete will be removed from dom
    */
    this.onClickWindow = this.onClickWindow.bind(this);

    this.state = {
      autocompleteResults: [],
      className: '',
      placeholder: '',
      showClearBtn: false,
      isOpened: false,
      searchString: '',
      isLoaderVisible: false,
      onSelectResultItem: this.onSelectResultItem.bind(this),
      onSelectResultItems: this.onSelectResultItems.bind(this),
      onClear: this.onClear.bind(this),
      onFocusInput: this.onFocusInput.bind(this),
      onKeyUpInput: this.onKeyUpInput.bind(this),
      onClickInput: this.onClickInput.bind(this),
      onComponentDidMount: this.onComponentDidMount.bind(this),
      onComponentWillUnmount: this.onComponentWillUnmount.bind(this),
      onInputRendered: this.onInputRendered.bind(this),
      onBlurInput: this.onBlurInput.bind(this),
    };
  }

  /**
   * @inheritDoc
   */
  public getState(): AutocompleteTemplatePropsInterface {
    return this.state;
  }

  /**
   * @inheritDoc
   */
  public setSearchString(searchString: string): void {
    this.setState({ ...this.state, searchString });
  }

  /**
   * @inheritDoc
   */
  public setAutocompleteResults(autocompleteResults: AutocompleteSectionInterface[]): void {
    this.setState({ ...this.state, autocompleteResults });
  }

  /**
   * @inheritDoc
   */
  public setPlaceholder(placeholder: string): void {
    this.setState({ ...this.state, placeholder });
  }

  /**
   * @inheritDoc
   */
  public setInitialValue(value: string): void {
    this.inputInitialValue = value;
  }

  /**
   * @inheritDoc
   */
  public setInputValue(value: string): void {
    if (!this.inputEl) {
      return;
    }

    this.inputEl.value = value;
  }

  /**
   * @inheritDoc
   */
  public clear(): void {
    this.state.searchString = '';
    this.state.autocompleteResults = [];
    this.initialValueSet = false;
    this.inputInitialValue = '';
    this.setInputValue('');
    this.setState(this.state);
  }

  /**
   * @inheritDoc
   */
  public toggle(isOpened: boolean): void {
    this.setState({ ...this.state, isOpened });
  }

  /**
   * @inheritdoc
   */
  public toggleLoader(isLoaderVisible: boolean): void {
    this.setState({ ...this.state, isLoaderVisible });
  }

  /**
   * @inheritDoc
   */
  public toggleClearButtonVisibility(showClearBtn: boolean): void {
    this.setState({ ...this.state, showClearBtn });
  }

  /**
   * Handles autocomplete select result item event
   *
   * @param e - Event
   */
  protected onSelectResultItem(e: Event, result: AutocompleteResultInterface): void {
    this.toggle(false);

    this.getEventEmitter().emit(AutocompleteViewStoreEvent.selectResultItem, e, result);
  }

  /**
   * Handles autocomplete select result items event
   *
   * @param e - Event
   */
  protected onSelectResultItems(e: Event, result: AutocompleteResultInterface[]): void {
    this.toggle(false);
  }

  /**
   * Handles autocomplete clear button press
   */
  protected onClear(): void {
    this.clear();

    this.getEventEmitter().emit(AutocompleteViewStoreEvent.clear);
  }

  /**
   * Triggers when autocomplete input is focused
   * @param e - Event
   */
  protected onFocusInput(e: Event): void {
    this.getEventEmitter().emit(AutocompleteViewStoreEvent.focus, e);
  }

  /**
   * Handles autocomplete keyup event
   *
   * @param e - Event
   */
  protected onKeyUpInput(e: KeyboardEvent): void {
    this.setSearchString((<HTMLInputElement>e.currentTarget).value);

    this.getEventEmitter().emit(AutocompleteViewStoreEvent.keyUp);
  }

  /**
   * Handles autocomplete click event
   *
   * @param e - Event
   */
  protected onClickInput(e: KeyboardEvent): void {
    // Show dropdown when search string is not empty and there are autocomplete results
    this.toggle(!!this.state.searchString && !!this.state.autocompleteResults.length);

    this.getEventEmitter().emit(AutocompleteViewStoreEvent.clickInput, e);
  }

  /**
   * Handles autocomplete mount event
   *
   * @param base - autocomplete dom element
   */
  protected onComponentDidMount(base: HTMLElement): void {
    this.base = base;

    this.windowService.getNative().addEventListener(DomElementEvent.click, this.onClickWindow);
  }

  /**
   * Handles autocomplete unmount event
   */
  protected onComponentWillUnmount(): void {
    this.windowService.getNative().removeEventListener(DomElementEvent.click, this.onClickWindow);
  }

  /**
   * Triggers when autocomplete input is been rendered
   *
   * @param el - autocomplete input element
   */
  protected onInputRendered(el: HTMLInputElement): void {
    this.inputEl = el;
    if (el && !this.initialValueSet) {
      this.setInputValue(this.inputInitialValue);
      this.initialValueSet = true;
    }
  }

  /**
   * Triggers when user clicks on window
   *
   * @param e - Event
   */
  protected onClickWindow(e: Event): void {
    const target = <HTMLElement>e.target;

    if (!this.state.isOpened) {
      return;
    }

    if (this.base.contains(target)) {
      return;
    }

    this.toggle(false);
  }

  /**
   * Triggers when autocomplete input is blurred
   * @param e - Event
   */
  protected onBlurInput(e: Event): void {
    this.eventEmitter.emit(AutocompleteViewStoreEvent.blurInput, e);
  }
}
