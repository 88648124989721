import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';
import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';

import { MyAccountMenuView } from 'common/module/my-account/menu/view';
import { myNotesGetIsAvailable } from 'common/module/my-notes/get-is-available';
import { PreactView } from 'common/module/preact/view';
import { UserInfoTemplate } from 'common/module/user/info/template';
import { UserInfoViewStoreFactory } from 'common/module/user/info/view-store.factory';
import { MyAccountStoreService } from 'common/service/my-account-store/service';
import { PropertyContactedStoreService } from 'common/service/property-contacted-store/service';
import { PropertySavedStoreService } from 'common/service/property-saved-store/service';
import { SaveSearchStoreService } from 'common/service/save-search-store/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

export function MyAccountMenuViewFactory(): ViewBasicInterface {
  return new MyAccountMenuView(
    PropertySavedStoreService(),
    PropertyContactedStoreService(),
    MyAccountStoreService(),
    SaveSearchStoreService(),
    new PreactView(UserInfoTemplate, UserInfoViewStoreFactory()),
    UserAuthenticationService(),
    domQuerySelector,
    myNotesGetIsAvailable()
  );
}
