import { BrowserDocumentServiceInterface } from 'pf-frontend-common/dist/service/browser-document/service.interface';
import { WindowServiceInterface } from 'pf-frontend-common/dist/service/window/service.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { DataStore } from 'common/module/data/store';
import { GoogleRecaptchaScript } from 'common/module/google/recaptcha/script';
import { GoogleRecaptchaViewStoreEvent } from 'common/module/google/recaptcha/view-store.event';
import { GoogleRecaptchaViewStoreStateInterface } from 'common/module/google/recaptcha/view-store-state.interface';
import { PromiseCancelableInterface } from 'common/module/promise/cancelable.interface';

/**
 * Render a captcha on the site
 */
export class GoogleRecaptchaViewStore extends DataStore {
  /**
   * @inheritDoc
   */
  protected state: GoogleRecaptchaViewStoreStateInterface = {
    onComponentDidMount: this.onComponentDidMount.bind(this),
    value: null,
  };

  /**
   * Recaptcha script load promise
   */
  protected loadPromise: PromiseCancelableInterface<boolean>;

  /**
   * Constructor
   */
  constructor(
    protected eventEmitter: EventEmitterInterface,
    protected googleRecaptchaScript: GoogleRecaptchaScript,
    protected siteKey: string,
    protected promiseCancelableFactory: <T>(promise: Promise<T>) => PromiseCancelableInterface<T>,
    protected windowService: WindowServiceInterface,
    protected documentService: BrowserDocumentServiceInterface
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritDoc
   */
  public getState(): GoogleRecaptchaViewStoreStateInterface {
    return this.state;
  }

  /**
   * @inheritdoc
   */
  public initialize(options: { executeImmediately: boolean }): void {
    this.state.executeImmediately = options.executeImmediately;
  }

  /**
   * Reset view store
   */
  public reset(): void {
    const instanceId = this.getState().instanceId;

    if (instanceId === undefined) {
      return;
    }

    this.windowService.getNative().grecaptcha.reset(instanceId);

    this.setState({
      ...this.getState(),
      value: null,
    });

    this.getEventEmitter().emit(GoogleRecaptchaViewStoreEvent.change, this.getState().value);
  }

  /**
   * Execute grecaptcha
   */
  public execute(): void {
    if (!this.windowService.getNative().grecaptcha) {
      return;
    }

    if (!this.loadPromise) {
      return;
    }

    this.loadPromise.getPromise().then(() => this.windowService.getNative().grecaptcha.execute(this.state.instanceId));
  }

  /**
   * Load captcha script and render inside @containerElement
   */
  private initializeCaptcha(containerElement: HTMLElement): void {
    // Cancel any unresolved load promises
    if (this.loadPromise) {
      this.loadPromise.cancel();
    }

    this.loadPromise = this.promiseCancelableFactory(this.googleRecaptchaScript.load());
    this.loadPromise.getPromise().then(this.onCaptchaInitialized(containerElement));
  }

  /**
   * On captcha is being initialized
   */
  private onCaptchaInitialized = (containerElement: HTMLElement) => () => {
    // Reset captcha
    this.reset();

    // Create a placeholder for the captcha frame
    const captchaPlaceholder = this.documentService.getDocument().createElement('div');

    // Append it to the component
    containerElement.appendChild(captchaPlaceholder);

    // Render inside the placeholder
    const instanceId = this.windowService.getNative().grecaptcha.render(captchaPlaceholder, {
      sitekey: this.siteKey,
      callback: this.onCallbackCaptcha,
      'expired-callback': this.onCallbackCaptchaExpired,
      size: 'invisible',
    });

    this.setState({
      ...this.getState(),
      instanceId,
    });

    if (this.state.executeImmediately) {
      this.windowService.getNative().grecaptcha.execute(instanceId);
    }
  };

  /**
   * Mounted component
   */
  private onComponentDidMount(el: HTMLElement): void {
    this.initializeCaptcha(el);
  }

  /**
   * Captcha token has expired and user needs to reverify
   */
  private onCallbackCaptchaExpired = (): void => {
    this.setState({
      ...this.getState(),
      value: null,
    });

    this.eventEmitter.emit(GoogleRecaptchaViewStoreEvent.change, this.getState().value);
  };

  /**
   * Captcha has responded to the user event
   */
  private onCallbackCaptcha = (response: string): void => {
    this.setState({
      ...this.getState(),
      value: response,
    });

    this.eventEmitter.emit(GoogleRecaptchaViewStoreEvent.change, this.getState().value);
  };
}
