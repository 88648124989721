import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from 'common/module/icon/template-props.interface';

// tslint:disable:max-line-length
export const IconThickAmenitiesTemplate: FunctionalComponent<IconTemplatePropsInterface> = (props) => {
  const { clipped = false } = props;

  return (
    <svg
      className={props.class}
      width={clipped ? null : '24'}
      height={clipped ? null : '24'}
      fill='currentColor'
      viewBox={clipped ? '4 6 16 13' : '0 0 24 24'}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.957 10.5h-1.712l-1.974-3h-.622l-1.904 3H8.04a1.25 1.25 0 0 1 .153-.354L9.877 7.5h-2.21l-2.122 3.489 4.761 4.697L8.451 12h1.678l1.834 3.647L13.852 12h1.686l-1.986 3.826 4.904-4.838L16.352 7.5H14.05l1.752 2.64c.072.113.124.234.156.36ZM7.386 6h9.248a1 1 0 0 1 .857.483l2.437 4.04a.5.5 0 0 1 .072.259v.58a.5.5 0 0 1-.149.356l-7.088 6.994a1 1 0 0 1-.703.288h-.12a1 1 0 0 1-.703-.288L4.15 11.718A.5.5 0 0 1 4 11.362v-.579a.5.5 0 0 1 .073-.26L6.532 6.48A1 1 0 0 1 7.386 6Z'
      />
    </svg>
  );
};
