import { FunctionalComponent, h } from 'preact';
import { useMemo } from 'preact/hooks';

import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { StatsTealiumDataLayerConversionEventInterface } from 'common/module/stats/tealium/data-layer/conversion-event.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';

export const BuyVsRentPageLink: FunctionalComponent = () => {
  const tracking = useMemo(() => StatsTealiumProviderService(), []);

  return (
    <a
      href={`/${configGetLanguage().isRtl ? 'ar' : 'en'}/rent-vs-buy-calculator`}
      className='filter-form-component-variant__link'
      onClick={(): void => {
        tracking.send({
          tealium_event: 'flagship',
          event_category: StatsTealiumDataLayerEventCategoryEnum.userInteraction,
          event_value: '1',
          event_action: 'buy_vs_rent_link_click',
          event_label: 'variantA',
        } as StatsTealiumDataLayerConversionEventInterface);
      }}
    >
      {i18nTranslate('BuyVsRent_NotSure')}
    </a>
  );
};
