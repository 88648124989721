import { BackendConfigFeaturesInterface } from 'common/data/backend/config/features.interface';

/**
 * Returns country specific settings
 */
export function configGetFeature<Key extends keyof BackendConfigFeaturesInterface>(
  key: Key
): BackendConfigFeaturesInterface[Key] {
  return window.propertyfinder?.features?.[key] || {};
}
