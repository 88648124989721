export enum RangeSelectorEditTypeEnum {
  /**
   * input is fulfilled by writing
   */
  text = 'edit',

  /**
   * input is fulfilled by selecting
   */
  select = 'selection',
}
