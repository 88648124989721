import { CountryCodeEnum } from 'common/data/country/code.enum';
import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';

// TODO-FE[https://pfinder.atlassian.net/browse/WEB-6725] - remove this file
export const PropertySerpLightPopularSearches: Partial<
  Record<CountryCodeEnum, Record<string, AutocompleteResultInterface[]>>
> = {
  [CountryCodeEnum.ae]: {
    en: [
      {
        id: '50',
        text: 'Dubai Marina',
        description: 'Dubai',
        sectionId: 'popular-searches',
      },
      {
        id: '41',
        text: 'Downtown Dubai',
        description: 'Dubai',
        sectionId: 'popular-searches',
      },
      {
        id: '36',
        text: 'Business Bay',
        description: 'Dubai',
        sectionId: 'popular-searches',
      },
      {
        id: '86',
        text: 'Palm Jumeirah',
        description: 'Dubai',
        sectionId: 'popular-searches',
      },
      {
        id: '73',
        text: 'Jumeirah Village Circle',
        description: 'Dubai',
        sectionId: 'popular-searches',
      },
      {
        id: '71',
        text: 'Jumeirah Lake Towers',
        description: 'Dubai',
        sectionId: 'popular-searches',
      },
      {
        id: '105',
        text: 'Dubai Hills Estate',
        description: 'Dubai',
        sectionId: 'popular-searches',
      },
    ],
    ar: [
      {
        id: '50',
        text: 'دبي مارينا',
        description: 'دبي',
        sectionId: 'popular-searches',
      },
      {
        id: '41',
        text: 'دبي وسط المدينة',
        description: 'دبي',
        sectionId: 'popular-searches',
      },
      {
        id: '36',
        text: 'الخليج التجاري',
        description: 'دبي',
        sectionId: 'popular-searches',
      },
      {
        id: '86',
        text: 'نخلة الجميرا',
        description: 'دبي',
        sectionId: 'popular-searches',
      },
      {
        id: '73',
        text: 'قرية الجميرا سركل',
        description: 'دبي',
        sectionId: 'popular-searches',
      },
      {
        id: '71',
        text: 'أبراج بحيرة الجميرا',
        description: 'دبي',
        sectionId: 'popular-searches',
      },
      {
        id: '105',
        text: 'دبي هيلز استيت',
        description: 'دبي',
        sectionId: 'popular-searches',
      },
    ],
  },
};
