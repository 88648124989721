import { Component, ComponentFactory, h } from 'preact';

import { DataStoreEvent } from 'common/module/data/store.event';
import { DataStoreInterface } from 'common/module/data/store.interface';

import { PreactDynamicComponentPropsInterface } from './component-props.interface';

/**
 * Attaches viewStore to WrappedComponent
 */
export const preactDynamicComponent = <Props extends {}, ViewStore extends DataStoreInterface>(
  WrappedComponent: ComponentFactory<Readonly<Props | {}>>
) =>
  class PreactLifecycleComponent extends Component<PreactDynamicComponentPropsInterface<Props, ViewStore>> {
    /**
     * @inheritDoc
     */
    public componentWillMount(): void {
      this.setState(this.props.viewStore.getState());

      this.props.viewStore.getEventEmitter().addListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);
    }

    /**
     * @inheritDoc
     */
    public componentWillUnmount(): void {
      this.props.viewStore.getEventEmitter().removeListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);
    }

    /**
     * @inheritDoc
     */
    public componentWillReceiveProps(nextProps: PreactDynamicComponentPropsInterface<Props, ViewStore>): void {
      if (nextProps.viewStore !== this.props.viewStore) {
        this.props.viewStore.getEventEmitter().removeListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);

        nextProps.viewStore.getEventEmitter().addListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);

        this.setState(nextProps.viewStore.getState());
      }
    }

    /**
     * @inheritDoc
     */
    public render(): preact.JSX.Element | null {
      const mapStateProps = this.props.mapState ? this.props.mapState(this.props.viewStore) : null;
      return (
        <WrappedComponent {...this.state} {...mapStateProps}>
          {(mapStateProps && mapStateProps.children) || this.props.children}
        </WrappedComponent>
      );
    }

    /**
     * Data store state updated
     */
    private onUpdateStateDataStore = () => {
      this.setState(this.props.viewStore.getState());
    };
  };
