import { EventEmitterInterface } from '@propertyfinder/pf-frontend-common/src/module/event/emitter.interface';
import { StatsContextLocalInterface } from '@propertyfinder/pf-frontend-common/src/module/stats/context/local.interface';

import { PropertyCtaStatechartEvent } from 'common/module/property-cta/statechart.event';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { PropertyStoreServiceInterface } from 'common/service/property-store/service.interface';

import { WhatsappEventEnum } from './event.enum';

export class WhatsappContext {
  private collection: Map<string, boolean> = new Map();
  private statsContextLocal: StatsContextLocalInterface;

  constructor(
    private eventEmitter: EventEmitterInterface,
    private propertyStoreService: PropertyStoreServiceInterface,
    private propertyCtaStatechartStore: StatechartStoreInterface
  ) {}

  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  public isWhatsAppClicked(propertyId: string): boolean {
    if (!this.collection.has(propertyId)) {
      return this.collection.get(propertyId);
    } else {
      this.collection.set(propertyId, false);
      return false;
    }
  }

  public setContextLocal(statsContextLocal: StatsContextLocalInterface): void {
    this.statsContextLocal = statsContextLocal;
  }

  public onClickWhatsApp = (propertyId: string, e?: Event, submissionId?: string): void => {
    if (e?.stopPropagation) {
      e.stopPropagation();
    }

    const propertyModel = this.propertyStoreService.getOneById(propertyId);

    this.propertyCtaStatechartStore.transit({
      event: PropertyCtaStatechartEvent.clickCtaWhatsAppButton,
      payload: {
        href: submissionId
          ? propertyModel.meta.contact_options.list.developer_whatsapp.link.replace('{#formReference}', submissionId)
          : propertyModel.meta.contact_options.list.whatsapp.link,
        propertyModel,
        submissionId,
        statsContextLocal: this.statsContextLocal,
      },
    });

    this.collection.set(propertyId, true);
    this.eventEmitter.emit(WhatsappEventEnum.CLICKED, propertyId);
  };
}
