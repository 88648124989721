import { i18nTranslate } from 'common/helper/i18n/translate';

import { BedAndBathSelectorOptionsInterface } from '../options.interface';
import { bedAndBathSelectorLabelSanitize } from './sanitize';

export const bedAndBathSelectorLabelCompute = (
  values: BedAndBathSelectorOptionsInterface[],
  postFix: string
): string => {
  // If there are no items, return empty string.
  if (values.length === 0) {
    return '';
  }

  // If there is only one option, then return the label of that option.
  if (values.length === 1) {
    return bedAndBathSelectorLabelSanitize(values[0], postFix);
  }

  // Are the numbers sequential?
  const sequential = values.every((value, index) => {
    if (index === 0) {
      return true;
    }

    return value.num === values[index - 1].num + 1;
  });

  // If the numbers are sequential, then return the first and last number.
  if (sequential) {
    return `${values[0].text} - ${values[values.length - 1].text} ${i18nTranslate(postFix)}`;
  }

  // If the numbers are not sequential, then return all numbers with a comma.
  return `${values.map((value) => value.text).join(', ')} ${i18nTranslate(postFix)}`;
};
