import { UserAuthenticationFactory } from 'common/module/user/authentication.factory';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

const service = UserAuthenticationFactory();

service.initialize();

export function UserAuthenticationService(): UserAuthenticationServiceInterface {
  return service;
}
