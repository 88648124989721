import { BrowserStorageLocalService } from '@propertyfinder/pf-frontend-common/src/service/browser-storage-local/service';

import { FilterSettingsCacheStore } from 'common/data/filter-settings/cache/store';
import { FilterSettingsStoreFactory } from 'common/data/filter-settings/store.factory';
import { configGetLanguage } from 'common/helper/config/get-language';
import { FilterSettingsDatabaseService } from 'common/service/filter-settings-database/service';

export function FilterSettingsCacheStoreFactory(): FilterSettingsCacheStore {
  return new FilterSettingsCacheStore(
    BrowserStorageLocalService(),
    configGetLanguage(),
    FilterSettingsDatabaseService(),
    FilterSettingsStoreFactory()
  );
}
