export enum TagNewTypeEnum {
  VERIFIED = 'verified',
  SUPER_AGENT = 'super_agent',
  DEVELOPER = 'developer',
  NEW_PROPERTY = 'new',
  LIVE_VIEWING = 'live_viewing',
  UNAVAILABLE = 'unavailable',
  COMMUNITY_EXPERT = 'community_expert',
  UNDER_OFFER = 'under_offer',
  DATA_GURU = 'data_guru',
  SPOTLIGHT_LISTING = 'spotlight_listing',
  NEW_LISTING = 'first_sale',
}
