import { domQuerySelector } from 'pf-frontend-common/src/helper/dom/query-selector';

import { preactMarkup } from 'common/helper/preact/markup';
import { PrivacyPromptComponent } from 'common/module/privacy-prompt/component';

export function PrivacyPromptService(): void {
  const element = domQuerySelector(document.body, '[data-qs=privacy-prompt-container]');
  preactMarkup(element, {
    PrivacyPromptComponent,
  });
}
