import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { Component, h } from 'preact';

import { AdComponentPropsInterface } from 'common/module/ad/component-props.interface';
import { AdSlotInterface } from 'common/module/ad/slot.interface';
import { AdService } from 'common/service/ad/service';
import { AdServiceEvent } from 'common/service/ad/service.event';

/**
 * Component for rendering ads into dynamic templates
 */
export class AdComponent extends Component<AdComponentPropsInterface> {
  /**
   * Css classes
   */
  private cssClass: DataKeyValueStringInterface = {
    empty: 'ad-empty',
    visible: 'ad-visible',
  };

  /**
   * Is ad empty
   */
  private isEmpty: boolean = true;

  /**
   * @inheritDoc
   */
  public componentWillMount(): void {
    AdService().getEventEmitter().addListener(AdServiceEvent.slotRenderFinished, this.onSlotRenderFinished);

    AdService().getEventEmitter().addListener(AdServiceEvent.slotLoaded, this.onSlotLoaded);
  }

  /**
   * @inheritDoc
   */
  public componentDidMount(): void {
    AdService().renderAdUnit(this.props.adUnit, this.props.adUnit.id);
  }

  /**
   * @inheritDoc
   */
  public componentWillUnmount(): void {
    AdService().getEventEmitter().removeListener(AdServiceEvent.slotRenderFinished, this.onSlotRenderFinished);

    AdService().getEventEmitter().removeListener(AdServiceEvent.slotLoaded, this.onSlotLoaded);
  }

  /**
   * @inheritDoc
   */
  public shouldComponentUpdate(nextProps: AdComponentPropsInterface): boolean {
    if (nextProps.className !== this.props.className) {
      return true;
    }

    return false;
  }

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return (
      <div
        ref={this.props.ref}
        className={`
          ad ${this.props.className || ''}
          ${this.props.emptyClassName || this.cssClass.empty}
        `}
        id={this.props.adUnit.id}
      />
    );
  }

  /**
   * Slot render finished
   */
  private onSlotRenderFinished = (slot: any) => {
    // Set isEmpty property
    if (slot.id === this.props.adUnit.id) {
      this.isEmpty = slot.isEmpty;
    }
  };

  /**
   * Slot fully loaded
   */
  private onSlotLoaded = (slot: AdSlotInterface) => {
    if (slot.id !== this.props.adUnit.id) {
      return;
    }

    (this.base as HTMLElement).classList.toggle(this.props.emptyClassName || this.cssClass.empty, this.isEmpty);

    (this.base as HTMLElement).classList.toggle(this.props.visibleClassName || this.cssClass.visible, !this.isEmpty);
  };
}
