import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

/**
 * resets price values when category is updated
 * @param onChangeValues filter store update function
 * @returns (category:string) => void
 */
export const FilterWidgetResetValues =
  (onChangeValues: (value: FilterParametersBaseInterface) => Promise<void>) => (category: string) => {
    onChangeValues({
      [FilterParamsEnum.maxPrice]: { value: '', choices: [] },
      [FilterParamsEnum.minPrice]: { value: '', choices: [] },
      [FilterParamsEnum.categoryId]: { value: category, choices: [] },
    });
  };
