import { preactConnectStore } from 'common/module/preact/connect-store';
import { PropertySerpLightComponentPropsInterface } from 'common/module/property/serp/light/component-props.interface';
import { PropertySerpLightTemplate } from 'common/module/property/serp/light/template';
import { PropertySerpLightViewStore } from 'common/module/property/serp/light/view-store';
import { PropertySerpLightViewStoreStateInterface } from 'common/module/property/serp/light/view-store-state.interface';
import { PropertySerpLightAutocompleteComponent } from 'desktop/module/property/serp/light/autocomplete/component';
import { PropertySerpLightViewStoreService } from 'desktop/service/property-serp-light-view-store/service';

export const PropertySerpLightComponent = preactConnectStore<
  PropertySerpLightViewStore,
  PropertySerpLightViewStoreStateInterface,
  PropertySerpLightComponentPropsInterface
>(PropertySerpLightViewStoreService, (store) => ({
  ...store.getState(),
  autocompleteComponent: PropertySerpLightAutocompleteComponent,
}))(PropertySerpLightTemplate);
