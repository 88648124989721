// TODO-FE[TPNX-2007] add unit test

import { stringToNumber } from 'common/helper/string/to-number';

/**
 * removes commas and returns number value if it's valid
 * @param value string
 * @returns number | undefined
 */
export const rangeSelectorGetValueAsNumber = (value: string = ''): number | undefined => {
  const unformattedValue = value.replace(/\D+/g, '');
  return stringToNumber(unformattedValue);
};
