import { domIsChildOf } from 'pf-frontend-common/src/helper/dom/is-child-of';
import { domQuerySelectorAll } from 'pf-frontend-common/src/helper/dom/query-selector-all';
import { objectMerge } from 'pf-frontend-common/src/helper/object/merge';
import { BrowserViewport } from 'pf-frontend-common/src/module/browser/viewport';
import { BrowserViewportAwareInterface } from 'pf-frontend-common/src/module/browser/viewport-aware.interface';
import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { DomElementEvent } from 'pf-frontend-common/src/module/dom/element.event';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { StatsContextLocalInterface } from 'pf-frontend-common/src/module/stats/context/local.interface';
import { StatsEntityEnum } from 'pf-frontend-common/src/module/stats/entity.enum';
import { StatsAttributionServiceInterface } from 'pf-frontend-common/src/service/stats-attribution/service.interface';
import { StatsDataServiceInterface } from 'pf-frontend-common/src/service/stats-data/service.interface';

import { BackendConfigSettingsInterface } from 'common/data/backend/config/settings.interface';
import { PropertyModel } from 'common/data/property/model';
import { PropertyStatsDataAdapter } from 'common/data/property/stats/data-adapter';
import { configGetSettings } from 'common/helper/config/get-settings';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { settingsGetPageType } from 'common/helper/settings/get-page-type';
import { DataStore } from 'common/module/data/store';
import { PropertyCardInteractionEvent } from 'common/module/property/card/interaction.event';
import { PropertyCardInteractionClickPropertyPayloadInterface } from 'common/module/property/card/interaction/click-property-payload.interface';
import { PropertyCardTypeEnum } from 'common/module/property/card/property-card-type.enum';
import { PropertyCardTemplatePropsInterface } from 'common/module/property/card/template-props.interface';
import { PropertyListStatsGtmGalleryPropertyScroll } from 'common/module/property/list/stats/gtm/gallery-property-scroll';
import { PropertyListStatsGtmLiveViewingPropertyClick } from 'common/module/property/list/stats/gtm/live-viewing-property-click';
import { PropertyListStatsGtmLiveViewingPropertyImpression } from 'common/module/property/list/stats/gtm/live-viewing-property-impression';
import { PropertyListStatsGtmVideoTourPropertyClick } from 'common/module/property/list/stats/gtm/video-tour-property-click';
import { PropertyListStatsGtmVideoTourPropertyImpression } from 'common/module/property/list/stats/gtm/video-tour-property-impression';
import { PropertyListStatsGtmView360PropertyClick } from 'common/module/property/list/stats/gtm/view-360-property-click';
import { PropertyListStatsGtmView360PropertyImpression } from 'common/module/property/list/stats/gtm/view-360-property-impression';
import { PropertyListTemplatePropsInterface } from 'common/module/property/list/template-props.interface';
import { PropertyListViewStoreEvent } from 'common/module/property/list/view-store.event';
import { PropertyListViewStoreOptionsInterface } from 'common/module/property/list/view-store-options.interface';
import { PropertyCtaStatechartEvent } from 'common/module/property-cta/statechart.event';
import { PropertyCtaStatechartActionEnum } from 'common/module/property-cta/statechart/action.enum';
import { PropertyCtaStatechartPayloadInterface } from 'common/module/property-cta/statechart/payload.interface';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { StatsGtmProviderInterface } from 'common/module/stats/gtm/provider.interface';
import { StatsSnowplow2ActionFlushBuffer } from 'common/module/stats/snowplow2/action/flush-buffer';
import { StatsSnowplow2ProviderInterface } from 'common/module/stats/snowplow2/provider.interface';
import { StatsSnowplow2SelfDescribingSchemaImageClickViewTypeEnum } from 'common/module/stats/snowplow2/self-describing/schema/image-click/view-type.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { tagListGetItems } from 'common/module/tag-list/get-items';
import { WhatsappContext } from 'common/module/whatsapp/context';
import { PropertySavedStoreServiceInterface } from 'common/service/property-saved-store/service.interface';
import { StatsServiceInterface } from 'common/service/stats/service.interface';

import { PropertyCardOnClickImagePayloadInterface } from '../card/onclick-image-payload.interface';
import { PropertyListStatsSnowplow2ImageAdapter } from './stats/snowplow2/image-adapter';

export class PropertyListViewStore extends DataStore implements BrowserViewportAwareInterface {
  /**
   * @inheritDoc
   */
  protected state: PropertyListTemplatePropsInterface;

  /**
   * CSS Selectors
   */
  private cssSelector: DataKeyValueStringInterface = {
    cta: '[data-qs="cta"]',
  };

  /**
   * Stats local context
   */
  private statsContextLocal: StatsContextLocalInterface = {};

  /**
   * Processing cta button click
   */
  private isProcessingCta: boolean = false;

  /**
   * Previously collected impressions
   */
  private impressionCollected: {
    view360: Record<string, boolean>;
    liveViewing: Record<string, boolean>;
    videoTour: Record<string, boolean>;
  } = {
    view360: {},
    liveViewing: {},
    videoTour: {},
  };

  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private statsDataService: StatsDataServiceInterface,
    private statsAttributionService: StatsAttributionServiceInterface,
    private propertyStatsDataAdapter: PropertyStatsDataAdapter,
    private statsService: StatsServiceInterface,
    private statsGtm: StatsGtmProviderInterface,
    private settings: BackendConfigSettingsInterface,
    private browserViewport: BrowserViewport,
    private propertySavedStoreService: PropertySavedStoreServiceInterface,
    private statsSnowplow2Provider: StatsSnowplow2ProviderInterface,
    private propertyCtaStatechartStore: StatechartStoreInterface,
    private propertyCardInteractionService: EventEmitterInterface,
    private statsTealium: StatsTealiumProviderInterface,
    private whatsappContext: WhatsappContext
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritDoc
   */
  public getBrowserViewport(): BrowserViewport {
    return this.browserViewport;
  }

  /**
   * @inheritDoc
   */
  public getState(): PropertyListTemplatePropsInterface {
    return this.state;
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public initialize(options?: PropertyListViewStoreOptionsInterface): void {
    this.state = {
      disabled: false,
      noResultMessage:
        options && options.hasOwnProperty('noResultMessage')
          ? options.noResultMessage
          : i18nTranslate('Sorry, no properties were found matching your criteria'),
      showActions: options && options.hasOwnProperty('showActions') ? options.showActions : true,
      showDeleteButton: options && options.hasOwnProperty('showDeleteButton') ? options.showDeleteButton : false,
      isTargetBlank: options.isTargetBlank,
      listType: options.listType,
      properties: [],
    };

    // Attach observers
    this.observers();
  }

  /**
   * Update properties
   *
   * @param itemPerPage - Optional itemPerPage value to override stats context. Useful for product-specific pagination.
   */
  public updateProperties(
    propertyModels: PropertyModel[],
    statsContextLocal: StatsContextLocalInterface,
    itemPerPage?: number
  ): void {
    const state = this.getState();

    if (!itemPerPage && statsContextLocal.pagination) {
      itemPerPage = statsContextLocal.pagination.itemPerPage;
    }

    const properties = propertyModels
      .filter((propertyModel) => propertyModel.broker)
      .map((propertyModel, index) => {
        // Default position without pagination
        let position = index + 1;

        // Pagination context
        if (statsContextLocal.pagination) {
          // Add previous records count
          position += (statsContextLocal.pagination.pageCurrent - 1) * itemPerPage;
        }

        // Register property in stats data service
        this.statsDataService.getPropertyStore().add(
          this.propertyStatsDataAdapter.getData(propertyModel, {
            position,
            positionPage: index + 1,
            propertySimilar: statsContextLocal.property_similar,
          })
        );

        // Log stats
        this.statsService.propertyLoad(parseInt(propertyModel.id, 10), statsContextLocal);

        return {
          property: propertyModel,
          action: {
            showPhoneNumber: false,
          },
          showActions: state.showActions,
          showDeleteButton: state.showDeleteButton,
          isSaved: this.propertySavedStoreService.isSaved(propertyModel.id),
          onClickProperty: this.onClickProperty,
          onClickEmail: this.onClickEmail,
          onClickCall: this.onClickCall,
          onClickWhatsApp: this.onClickWhatsApp,
          onClickDelete: this.onClickDelete,
          onCreateTemplate: this.onCreateTemplate,
          onClickImage: this.onClickImage,
          onGalleryIndexChange: this.onGalleryIndexChange,
        };
      });

    // Keep stats local context
    this.statsContextLocal = statsContextLocal;

    this.whatsappContext.setContextLocal(statsContextLocal);

    // Update state
    this.setState(
      {
        ...this.state,
        properties,
      },
      this.updateProperties
    );
  }

  /**
   * @inheritDoc
   */
  public updateViewport(): void {
    // View disabled
    if (this.state.disabled === true) {
      // Don't update the viewport
      return;
    }

    // For each property
    const iMax = this.state.properties.length;
    for (let i = 0; i < iMax; i++) {
      // Reset watcher (which will check the element's position within the viewport)
      this.browserViewport.resetWatcher(String(i));
    }
  }

  /**
   * Updates in the viewport the HTML element linked to the property's index within the list
   */
  public updateHtmlElement(index: number, element: HTMLElement): void {
    this.browserViewport.registerWatcher(String(index), element);
  }

  /**
   * @inheritDoc
   */
  public updateDisabled(disabled: boolean): void {
    // Update state
    this.setState(
      {
        ...this.state,
        disabled,
      },
      this.updateDisabled
    );

    // Update viewport
    this.updateViewport();
  }

  /**
   * Update property cards from strategy
   */
  public updatePropertyCards(propertyCards: PropertyCardTemplatePropsInterface[]): void {
    this.setState(
      {
        ...this.state,
        properties: propertyCards,
      },
      this.updatePropertyCards
    );
  }

  /**
   * Attach observers
   */
  private observers(): void {
    // Browser viewport
    this.browserViewport.getEventEmitter().addListener(DomElementEvent.enterViewport, this.onEnterViewport);

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.setWhatsAppLoading, this.onSetWhatsAppLoadingActionStatechart);

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.resetWhatsAppLoading, this.onResetWhatsAppLoadingActionStatechart);
  }

  /**
   * Created template
   */
  private onCreateTemplate = (element: HTMLElement, index: number): void => {
    this.updateHtmlElement(index, element);
  };

  /**
   * Clicked call button
   */
  private onClickCall = (e: Event, propertyModel: PropertyModel) => {
    e.stopPropagation();

    if (this.isProcessingCta) {
      return;
    }

    this.propertyCtaStatechartStore.transit({
      event: PropertyCtaStatechartEvent.clickCtaCallButton,
      payload: {
        propertyModel,
        statsContextLocal: this.statsContextLocal,
        event: e,
      },
    });
  };

  /**
   * Clicked a property
   */
  private onClickProperty = (
    e: Event,
    propertyModel: PropertyModel,
    propertyCardType: PropertyCardTypeEnum = null
  ): void => {
    const clickedCta = domIsChildOf(
      <HTMLElement>e.target,
      domQuerySelectorAll(<HTMLElement>e.currentTarget, this.cssSelector.cta)
    );

    // Clicked a CTA
    if (clickedCta) {
      return;
    }

    // Log stats
    this.statsService.propertyClick(
      parseInt(propertyModel.id, 10),
      objectMerge({}, this.statsContextLocal, {
        authentication: {
          attributionId: this.statsAttributionService.push(StatsEntityEnum.property, propertyModel.id),
        },
      })
    );

    // Record GTM events
    if (propertyModel.view_360) {
      const statsData = this.propertyStatsDataAdapter.getData(propertyModel);
      this.statsGtm.send(
        PropertyListStatsGtmView360PropertyClick(settingsGetPageType(this.settings), statsData.listingStatusDisplayed)
      );
    }
    if (propertyModel.meta?.video_metadata) {
      const statsData = this.propertyStatsDataAdapter.getData(propertyModel);
      this.statsGtm.send(
        PropertyListStatsGtmVideoTourPropertyClick(settingsGetPageType(this.settings), statsData.listingStatusDisplayed)
      );
    }
    if (propertyModel.meta?.live_event_metadata) {
      const statsData = this.propertyStatsDataAdapter.getData(propertyModel);
      this.statsGtm.send(
        PropertyListStatsGtmLiveViewingPropertyClick(
          settingsGetPageType(this.settings),
          statsData.listingStatusDisplayed
        )
      );
    }

    const communityData = {
      property_community_topspot: `${propertyModel.cts}`,
      property_listing_id: propertyModel.id,
    };

    if (propertyCardType === PropertyCardTypeEnum.moreProperty) {
      this.statsTealium.send({
        sendToGa: true,
        event_action: 'more_properties_card_click',
        tealium_event: 'more_properties_card_click',
        event_category: StatsTealiumDataLayerEventCategoryEnum.propertyClick,
        event_label: '',
        ...communityData,
      });
    } else {
      this.statsTealium.send({
        sendToGa: true,
        tealium_event: 'search_property_card_click',
        event_category: StatsTealiumDataLayerEventCategoryEnum.propertyClick,
        event_action: 'search_property_card_click',
        event_label: configGetSettings().gtm?.pageType,
        project_id: propertyModel?.project?.id,
        property_tags: tagListGetItems(propertyModel).map((item) => (typeof item === 'object' ? item.type : item)),
        ...communityData,
      });
    }

    const payload: PropertyCardInteractionClickPropertyPayloadInterface = {
      event: e,
      model: propertyModel,
      statsContextLocal: this.statsContextLocal,
    };

    this.propertyCardInteractionService.emit(PropertyCardInteractionEvent.clickProperty, payload);
  };

  /**
   * Clicked email button
   */
  private onClickEmail = (e: Event, propertyModel: PropertyModel) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.isProcessingCta) {
      return;
    }

    this.propertyCtaStatechartStore.transit({
      event: PropertyCtaStatechartEvent.clickCtaEmailButton,
      payload: {
        propertyModel,
        statsContextLocal: this.statsContextLocal,
      },
    });

    this.getEventEmitter().emit(PropertyListViewStoreEvent.clickEmail);
  };

  /**
   * Clicked WhatsApp button
   */
  private onClickWhatsApp = (propertyModel: PropertyModel, e?: Event, submissionId?: string) => {
    if (this.isProcessingCta) {
      return;
    }

    this.whatsappContext.onClickWhatsApp(propertyModel.id, e, submissionId);
  };

  /**
   * When whatsapp loading state should be set
   */
  private onSetWhatsAppLoadingActionStatechart = (payload: PropertyCtaStatechartPayloadInterface) => {
    this.isProcessingCta = true;

    const propertyCards = this.state.properties.map((propertyCard) => {
      propertyCard.isWhatsAppButtonLoading = payload.propertyModel.id === propertyCard.property.id;

      return propertyCard;
    });

    this.updatePropertyCards(propertyCards);
  };

  /**
   * When whatsapp loading state should be reset
   */
  private onResetWhatsAppLoadingActionStatechart = (payload: PropertyCtaStatechartPayloadInterface) => {
    const propertyCards = this.state.properties.map((propertyCard) => {
      if (payload.propertyModel.id === propertyCard.property.id) {
        propertyCard.isWhatsAppButtonLoading = false;
      }

      return propertyCard;
    });

    this.isProcessingCta = false;

    this.updatePropertyCards(propertyCards);
  };

  /**
   * Clicked delete button
   */
  private onClickDelete = (e: Event, propertyModel: PropertyModel) => {
    e.preventDefault();
    e.stopPropagation();

    this.getEventEmitter().emit(PropertyListViewStoreEvent.deleteProperty, propertyModel);
  };

  /**
   * An element entered the browser viewport
   */
  private onEnterViewport = (watcherId: string): void => {
    // View is disabled
    if (this.state.disabled === true) {
      return;
    }

    const propertyCard = this.state.properties[parseInt(watcherId, 10)];
    // Property card doesn't exist
    if (!propertyCard) {
      return;
    }

    // Log GTM events
    const statsData = this.propertyStatsDataAdapter.getData(propertyCard.property);
    if (!this.impressionCollected.view360[propertyCard.property.id] && propertyCard.property.view_360) {
      this.statsGtm.send(
        PropertyListStatsGtmView360PropertyImpression(
          settingsGetPageType(this.settings),
          statsData.listingStatusDisplayed
        )
      );
      this.impressionCollected.view360[propertyCard.property.id] = true;
    }
    if (!this.impressionCollected.videoTour[propertyCard.property.id] && propertyCard.property.meta?.video_metadata) {
      this.statsGtm.send(
        PropertyListStatsGtmVideoTourPropertyImpression(
          settingsGetPageType(this.settings),
          statsData.listingStatusDisplayed
        )
      );
      this.impressionCollected.videoTour[propertyCard.property.id] = true;
    }
    if (
      !this.impressionCollected.liveViewing[propertyCard.property.id] &&
      propertyCard.property.meta?.live_event_metadata
    ) {
      this.statsGtm.send(
        PropertyListStatsGtmLiveViewingPropertyImpression(
          settingsGetPageType(this.settings),
          statsData.listingStatusDisplayed
        )
      );
      this.impressionCollected.liveViewing[propertyCard.property.id] = true;
    }

    // Log stats
    this.statsService.propertyImpression(parseInt(propertyCard.property.id, 10), this.statsContextLocal);
  };

  /**
   * Onclick image, track snowplow2 image click event
   */
  private onClickImage = (payload: PropertyCardOnClickImagePayloadInterface) => {
    const dataAdapter = new PropertyListStatsSnowplow2ImageAdapter(
      StatsSnowplow2SelfDescribingSchemaImageClickViewTypeEnum.image_serp_click,
      payload
    );

    this.statsSnowplow2Provider.send(dataAdapter.getImageClick());
    this.statsSnowplow2Provider.send(StatsSnowplow2ActionFlushBuffer);
  };

  /**
   * On scroll gallery, track in GTM
   */
  private onGalleryIndexChange = (propertyModel: PropertyModel) => {
    const statsData = this.propertyStatsDataAdapter.getData(propertyModel);

    this.statsGtm.send(PropertyListStatsGtmGalleryPropertyScroll(settingsGetPageType(this.settings), statsData));
  };
}
