import { FunctionComponent, h } from 'preact';

import { domClassMerge } from 'common/helper/dom/class-merge';
import { DropdownWrapperComponentPropsInterface } from 'common/module/dropdown-wrapper/component-props.interface';

export const DropdownWrapperTemplate: FunctionComponent<DropdownWrapperComponentPropsInterface> = ({
  showActions,
  actions,
  children,
  wrapperClassName,
  contentClassName,
}) => (
  <div className={domClassMerge('dropdown-wrapper', wrapperClassName)}>
    <div className={domClassMerge('dropdown-wrapper__content', contentClassName)}>{children}</div>
    {showActions && (
      <div className='dropdown-wrapper__footer'>
        {actions.map((action) => (
          <div className='dropdown-wrapper__footer-action'>{action}</div>
        ))}
      </div>
    )}
  </div>
);
