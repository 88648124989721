import { h } from 'preact';

import { CheckboxComponent } from '../checkbox/component';
import { MultipleSelectionPropsInterface } from './props.interface';
import { MultipleSelectionStateInterface } from './state.interface';

export const MultipleSelectionTemplate = (props: MultipleSelectionPropsInterface & MultipleSelectionStateInterface) => (
  <section className={['multiple-selection'].concat(props.className || []).join(' ')}>
    <div
      className='multiple-selection__options'
      style={{
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
      }}
    >
      {props.options.map((option, index) => (
        <CheckboxComponent
          checked={option.checked}
          id={`${props.optionsPrefix}-${index}`}
          onChange={() => props.onOptionClick(index)}
        >
          {option.label}
        </CheckboxComponent>
      ))}
    </div>
  </section>
);
