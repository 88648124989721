import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteStoreDecorator } from 'common/module/autocomplete/store/decorator';
import { PropertyAutocompleteStoreDecoratorInterface } from 'common/module/property/autocomplete/store/decorator.interface';
import { LocationAutocompleteHistoryServiceInterface } from 'common/service/location-autocomplete-history/service.interface';
import { LocationStoreServiceInterface } from 'common/service/location-store/service.interface';

export class PropertyAutocompleteStoreHistoryDecorator
  extends AutocompleteStoreDecorator
  implements PropertyAutocompleteStoreDecoratorInterface
{
  /**
   * Constructor
   */
  constructor(
    protected autocompleteStore: PropertyAutocompleteStoreDecoratorInterface,
    protected locationAutocompleteHistoryService: LocationAutocompleteHistoryServiceInterface,
    protected locationStoreService: LocationStoreServiceInterface
  ) {
    super(autocompleteStore);
  }

  /**
   * @inheritDoc
   */
  public addResult(result: AutocompleteResultInterface): Promise<void> {
    const model = this.locationStoreService.getOneById(result.id);

    this.locationAutocompleteHistoryService.add(model);

    return this.autocompleteStore.addResult(result);
  }

  /**
   * @inheritDoc
   */
  public removeResult(result: AutocompleteResultInterface): Promise<void> {
    return this.autocompleteStore.removeResult(result);
  }

  /**
   * @inheritDoc
   */
  public search(searchString: string): void {
    this.autocompleteStore.search(searchString);
  }

  /**
   * @inheritDoc
   */
  public getAutocompleteResults(): AutocompleteSectionInterface[] {
    return this.autocompleteStore.getAutocompleteResults();
  }
}
