import { h } from 'preact';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { DropdownWrapperComponent } from 'common/module/dropdown-wrapper/component';
import { IconHyphen } from 'common/module/icon/hyphen';
import { DropdownBodyPositionEnum } from 'library/dropdown/body-position.enum';
import { DropdownComponent } from 'library/dropdown/component';
import { DropdownVariantEnum } from 'library/dropdown/variant.enum';
import { RangeSelectorFieldTemplate } from 'library/range-selector/field/template';
import { RangeSelectorFieldsEnum } from 'library/range-selector/fields.enum';
import { rangeSelectorFilterOptions } from 'library/range-selector/filter-options';
import { RangeSelectorTemplatePropsInterface } from 'library/range-selector/template-props.interface';

// TODO-FE[TPNX-2387] Reuse RangeSelectorInput in RangeSelector components
export const RangeSelectorTemplate = (props: RangeSelectorTemplatePropsInterface) => {
  const { value, options, inputContainerClassName } = props;
  const { min: minValue, max: maxValue } = value;
  const hasAtleastOnevalue = !!(minValue || maxValue).length;

  const [minOptions, maxOptions] = rangeSelectorFilterOptions(options, value);

  return (
    <DropdownComponent
      isOpen={props.isDropdownOpen}
      onChangeOpen={props.onDropdownOpenChanged}
      size={props.dropdownSize}
      position={props.rtl ? DropdownBodyPositionEnum.bottomRight : DropdownBodyPositionEnum.bottomLeft}
      variant={DropdownVariantEnum.primary}
      text={props.text}
      label={props.label}
    >
      <DropdownWrapperComponent
        showActions
        actions={
          !(hasAtleastOnevalue || props.showClear)
            ? undefined
            : [
                <button className='range-selector__clear-btn' onClick={props.onClear}>
                  {i18nTranslate('Reset')}
                </button>,
              ]
        }
      >
        <div className='range-selector'>
          <RangeSelectorFieldTemplate
            ref={props.minRef}
            inputContainerClassName={inputContainerClassName}
            value={minValue}
            isListOpened={props.listOpenedOn === RangeSelectorFieldsEnum.min}
            placeholder={props.minPlaceholder || i18nTranslate('Any')}
            options={minOptions}
            mapToLabel={props.mapToLabel}
            onInputFocus={props.onInputFocus(RangeSelectorFieldsEnum.min)}
            onInputBlur={props.onInputBlur}
            onOptionChange={props.onOptionSelect}
            onInputClick={() => props.onInputClick(RangeSelectorFieldsEnum.min)}
            onInputChange={props.onInputChange(RangeSelectorFieldsEnum.min)}
            caretPositionRef={props.caretPositionRef}
          />

          <div className='range-selector__divider'>
            <IconHyphen class='range-selector__divider-svg' />
          </div>

          <RangeSelectorFieldTemplate
            ref={props.maxRef}
            inputContainerClassName={inputContainerClassName}
            value={maxValue}
            options={maxOptions}
            mapToLabel={props.mapToLabel}
            isListOpened={props.listOpenedOn === RangeSelectorFieldsEnum.max}
            placeholder={props.maxPlaceholder || i18nTranslate('Any')}
            onInputFocus={props.onInputFocus(RangeSelectorFieldsEnum.max)}
            onInputBlur={props.onInputBlur}
            onOptionChange={props.onOptionSelect}
            onInputClick={() => props.onInputClick(RangeSelectorFieldsEnum.max)}
            onInputChange={props.onInputChange(RangeSelectorFieldsEnum.max)}
            caretPositionRef={props.caretPositionRef}
          />
        </div>
        {props.children}
      </DropdownWrapperComponent>
    </DropdownComponent>
  );
};
