import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { UserModel } from 'common/data/user/model';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { DataStore } from 'common/module/data/store';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { UserInfoTemplatePropsInterface } from 'common/module/user/info/template-props.interface';
import { UserInfoViewStoreOptionsInterface } from 'common/module/user/info/view-store/options.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

export class UserInfoViewStore extends DataStore {
  /**
   * @inheritDoc
   */
  protected state: UserInfoTemplatePropsInterface;

  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritDoc
   */
  public initialize({ isEnabledMessage = true, isUserInfoVisible = true }: UserInfoViewStoreOptionsInterface): void {
    this.state = {
      isLoggedIn: !!this.userAuthenticationService.getToken(),
      isEnabledMessage,
      isUserInfoVisible,
      userName: i18nTranslate('My account'),
      userImage: null,
      showActions: false,
      userModel: new UserModel(),
      onClickLogout: this.onClickLogout,
    };

    this.updateState();

    // Attach observers
    this.observers();
  }

  /**
   * Toggle visibility
   */
  public toggle(isVisible?: boolean): void {
    if (isVisible === undefined) {
      this.state.isLoggedIn = !this.state.isLoggedIn;
      this.setState(this.state);

      return;
    }

    this.state.isLoggedIn = isVisible;
    this.setState(this.state);
  }

  /**
   * Set user info visibility
   */
  public setUserInfoVisible(isUserInfoVisible: boolean): void {
    this.state.isUserInfoVisible = isUserInfoVisible;
    this.setState(this.state);
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSucceededUserAuthentication);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onRegistrationSucceededUserAuthentication);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.logOutSucceeded, this.onLogOutSucceeded);
  }

  /**
   * Sign in succeeded
   */
  private onSignInSucceededUserAuthentication = (): void => {
    // Update the state
    this.updateState();
  };

  /**
   * Registration in succeeded
   */
  private onRegistrationSucceededUserAuthentication = (): void => {
    // Update the state
    this.updateState();
  };

  /**
   * User logged out
   */
  private onLogOutSucceeded = (): void => {
    // Change user login status
    this.toggle(false);
  };

  /**
   * Update state
   */
  private updateState(): void {
    const userModel = this.userAuthenticationService.getUser() || new UserModel();

    let userName = i18nTranslate('My account');

    if (userModel.first_name || userModel.last_name) {
      userName = `${userModel.first_name}${userModel.first_name ? ' ' : ''}${userModel.last_name}`;
    }

    this.state = {
      ...this.state,
      isLoggedIn: !!this.userAuthenticationService.getToken(),
      userModel: this.userAuthenticationService.getUser() || new UserModel(),
      userName,
      userImage: userModel.image,
      showActions: !!this.userAuthenticationService.getToken(),
    };

    this.setState(this.state);
  }

  /**
   * Click Logout button
   */
  private onClickLogout = () => {
    this.userAuthenticationService.logOut();
  };
}
