import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { DefaultContext, EventObject, Machine, State, StateMachine, StateSchema } from 'xstate/es';

import { StatechartInitializeOptionsInterface } from 'common/module/statechart/initialize-options.interface';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { StatechartTransitOptionsInterface } from 'common/module/statechart/transit-options.interface';

export class StatechartStore implements StatechartStoreInterface {
  /**
   * Machine current state
   */
  private currentState: State<DefaultContext>;

  /**
   * Fsm machine
   */
  private machine: StateMachine<DefaultContext, StateSchema, EventObject>;

  /**
   * Constructor
   */
  public constructor(private eventEmitter: EventEmitterInterface, private fsmMachineFactory: typeof Machine) {}

  /**
   * Initialize machine
   */
  public initialize(options: StatechartInitializeOptionsInterface): void {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.machine = this.fsmMachineFactory(options.statechart);
    this.currentState = this.machine.initialState;
  }

  /**
   * Get current state
   */
  public getCurrentState(): string | State<DefaultContext> {
    return this.currentState;
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public transit<Payload>(transitOptions: StatechartTransitOptionsInterface<Payload | object>): void {
    const machineCurrentState = this.currentState;
    const nextMachineState = this.machine.transition(machineCurrentState, transitOptions.event);

    this.currentState = nextMachineState;

    nextMachineState.actions.forEach((action) =>
      this.getEventEmitter().emit(action.type, <object>transitOptions.payload)
    );
  }
}
