import { Fragment, FunctionalComponent, h } from 'preact';

import { arrayFromRange } from 'common/helper/array/from-range';

import { HomePageBannerComponent } from '../banner/component';

export const HomePageMarketingAdsTemplate: FunctionalComponent = () => (
  <Fragment>
    {arrayFromRange(2).map((index) => (
      <div className='home-marketing-ads__item'>
        <HomePageBannerComponent index={String(index + 1)} />
      </div>
    ))}
  </Fragment>
);
