import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';
import { BrowserStorageLocalServiceInterface } from 'pf-frontend-common/src/service/browser-storage-local/service.interface';

import { JwtTokenModel } from 'common/data/jwt/token/model';
import { UserRefreshTokenModel } from 'common/data/user/refresh-token/model';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { JwtTokenServiceEvent } from 'common/service/jwt-token/service.event';
import { JwtTokenServiceInterface } from 'common/service/jwt-token/service.interface';

export class JwtTokenStore implements JwtTokenServiceInterface {
  /**
   * JWT token header name
   */
  public static readonly HEADER_JWT: string = 'X-Pf-JWT';

  /**
   * Token key in browser storage
   */
  private readonly tokenKey: string = 'user-authentication-token-v2';

  /**
   * Refresh token key in browser storage
   */
  private readonly refreshTokenKey: string = 'user-refresh-token-v2';

  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    refreshToken: {
      path: this.apiEndpointService.getPath('/user/refresh-token'),
      method: 'POST',
    },
    checkIsFresh: {
      // TODO-FE[TPNX-2773] don't make any additional requests to validate the token?
      path: this.apiEndpointService.getPath('/user/saved-property'),
      method: 'GET',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private browserStorageLocal: BrowserStorageLocalServiceInterface,
    private jsonApiStore: JsonApiStore<JwtTokenModel>
  ) {
    this.initToken();
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public setToken(model?: JwtTokenModel | null): void {
    // Remove old token from local storage
    this.browserStorageLocal.removeData(this.tokenKey);

    // Reset api store
    this.jsonApiStore.reset();

    if (!model) {
      this.getEventEmitter().emit(JwtTokenServiceEvent.tokenReset);
      return;
    }

    // Add new token to api store
    this.jsonApiStore.add(model);

    // Store token in localStorage
    this.browserStorageLocal.setData(this.tokenKey, model.payload);

    this.getEventEmitter().emit(JwtTokenServiceEvent.tokenSet);
  }

  /**
   * @inheritDoc
   */
  public getToken(): JwtTokenModel {
    return this.jsonApiStore.findAll()[0] || null;
  }

  /**
   * @inheritDoc
   */
  public createFromPayload(payload: string): JwtTokenModel {
    if (!payload) {
      return null;
    }

    const model = new JwtTokenModel();

    model.payload = payload;

    return model;
  }

  /**
   * Refresh token
   */
  public refreshToken(): void {
    const token = this.getToken();

    if (!token) {
      return;
    }

    this.tokenIsFresh().then((tokenIsFresh) => {
      if (tokenIsFresh) {
        return;
      }
      const refreshToken = this.getRefreshToken();

      if (!refreshToken) {
        // Remove old token
        this.setToken();

        this.getEventEmitter().emit(JwtTokenServiceEvent.refreshTokenFailed);

        return;
      }

      this.getEventEmitter().emit(JwtTokenServiceEvent.refreshTokenStart);

      const headers: DataKeyValueStringInterface = {};
      headers[JwtTokenStore.HEADER_JWT] = `Bearer ${token.payload}`;

      this.apiService
        .request(
          this.endpoint.refreshToken.method,
          this.endpoint.refreshToken.path,
          refreshToken.serialize(),
          false,
          headers
        )
        .then((httpResponse: HttpApiResponseInterface) => {
          // Sync api store
          this.jsonApiStore.reset();
          this.jsonApiStore.sync(httpResponse.data);

          // Update data in local storage
          this.browserStorageLocal.setData(this.tokenKey, this.getToken().payload);

          this.getEventEmitter().emit(JwtTokenServiceEvent.refreshTokenSucceeded);
        })
        .catch(() => {
          // Remove old token
          this.setToken();

          // Emit failure event
          this.getEventEmitter().emit(JwtTokenServiceEvent.refreshTokenFailed);
        });
    });
  }

  /**
   * @inheritDoc
   */
  public setRefreshToken(token?: string): void {
    // Remove old token from local storage
    this.browserStorageLocal.removeData(this.refreshTokenKey);

    if (!token) {
      return;
    }

    this.browserStorageLocal.setData(this.refreshTokenKey, token);
  }

  /**
   * @inheritDoc
   */
  public getRefreshToken(): UserRefreshTokenModel {
    const storageData = this.browserStorageLocal.getData(this.refreshTokenKey);

    if (!storageData) {
      return null;
    }

    const refreshToken: UserRefreshTokenModel = new UserRefreshTokenModel();

    refreshToken.value = String(storageData);

    return refreshToken;
  }

  /**
   * Initialize token
   */
  private initToken(): void {
    const token = this.browserStorageLocal.getData(this.tokenKey);

    if (!token) {
      return;
    }

    this.jsonApiStore.reset();
    this.jsonApiStore.add(this.createFromPayload(String(token)));

    // Refresh token
    this.refreshToken();
  }

  /**
   * Check if token is fresh
   */
  private tokenIsFresh(): Promise<boolean> {
    const headers: DataKeyValueStringInterface = {};
    headers[JwtTokenStore.HEADER_JWT] = 'Bearer ' + this.browserStorageLocal.getData(this.tokenKey);

    return new Promise((resolve) => {
      this.apiService
        .request(this.endpoint.checkIsFresh.method, this.endpoint.checkIsFresh.path, {}, true, headers)
        .then((httpResponse: HttpApiResponseInterface) => {
          if (httpResponse.status === 401 || httpResponse.status >= 500) {
            resolve(false);
          }

          resolve(true);
        })
        .catch(() => resolve(false));
    });
  }
}
