import { MyAccountStatechart } from 'common/module/my-account/statechart';
import { StatechartStore } from 'common/module/statechart/store';
import { StatechartStoreFactory } from 'common/module/statechart/store.factory';

const myAccountStatechartService = StatechartStoreFactory();

// Initialize service
myAccountStatechartService.initialize({
  statechart: MyAccountStatechart,
});

export function MyAccountStatechartService(): StatechartStore {
  return myAccountStatechartService;
}
