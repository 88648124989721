import { ComponentChild, ComponentChildren, VNode } from 'preact';

export function domGetElement(children: ComponentChildren, type: string): ComponentChild[] {
  const arrayChildren = Array.from(children as ComponentChild[]);

  let i = 0;
  const list = [];

  while (i < arrayChildren.length) {
    const child = arrayChildren[i];

    if (typeof child === 'object') {
      if ((child as VNode).type === type) {
        list.push(child as JSX.Element);
      } else if (Array.isArray((child as VNode).props?.children)) {
        list.push(...domGetElement((child as VNode).props.children, type));
      }
    }
    i++;
  }
  return list;
}
