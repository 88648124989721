import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { BrowserDocumentService } from 'pf-frontend-common/src/service/browser-document/service';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { configGetAds } from 'common/helper/config/get-ads';
import { configGetEnv } from 'common/helper/config/get-env';
import { configGetKeys } from 'common/helper/config/get-keys';
import { DfpAdStrategy } from 'common/module/dfp/ad-strategy';
import { AdService } from 'common/service/ad/service';
import { AdServiceInterface } from 'common/service/ad/service.interface';

const dfpAdStrategy = new DfpAdStrategy(
  EventEmitterFactory(),
  BrowserDocumentService(),
  WindowService(),
  configGetAds(),
  configGetEnv()
);

dfpAdStrategy.initialize({
  accountId: configGetKeys().googleTagAccount,
});

const adService = AdService();

adService.setStrategy(dfpAdStrategy);

export function DfpAdService(): AdServiceInterface {
  return adService;
}
