import { urlSerializeParams } from 'pf-frontend-common/src/helper/url/serialize-params';
import { BrowserLocationEvent } from 'pf-frontend-common/src/module/browser/location.event';
import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { WindowLocationServiceInterface } from 'pf-frontend-common/src/service/window-location/service.interface';

import { BackendConfigSettingsInterface } from 'common/data/backend/config/settings.interface';
import { languageGetAltUri } from 'common/helper/language/get-alt-uri';
import { uriIsSearch } from 'common/helper/uri/is-search';
import { LanguageSelectorViewOptionsInterface } from 'common/module/language-selector/view-options.interface';

export class LanguageSelectorView implements ViewBasicInterface {
  /**
   * View HTML elements
   */
  private elements: HTMLElement[] = [];

  /**
   * Constructor
   */
  public constructor(
    private windowLocationService: WindowLocationServiceInterface,
    private settings: BackendConfigSettingsInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: LanguageSelectorViewOptionsInterface): void {
    // Keep HTML elements
    this.elements = options.elements;

    // Attach observers
    this.observers();
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    if (!this.elements.length) {
      return;
    }

    this.elements.forEach((element) => {
      element.setAttribute('href', this.getHref(element));
    });
  }

  /**
   * Attach observers
   */
  private observers(): void {
    if (!this.elements.length) {
      return;
    }

    this.windowLocationService.getEventEmitter().addListener(BrowserLocationEvent.update, this.onUpdateWindowLocation);
  }

  /**
   * Get new href
   */
  private getHref(element: HTMLElement): string {
    // Get only path without query params and hash
    const pathname = element.getAttribute('href').split(/[#|?]/)[0];
    const windowPathname = this.windowLocationService.getPathname();

    const hrefArr = [];

    // Check if page is search URI
    if (uriIsSearch(this.settings, windowPathname)) {
      // get alternative language URL
      hrefArr.push(languageGetAltUri(windowPathname));
    } else {
      hrefArr.push(pathname);
    }

    if (Object.keys(this.windowLocationService.getQueryData()).length) {
      hrefArr.push('?' + urlSerializeParams(this.windowLocationService.getQueryData()));
    }

    if (this.windowLocationService.getHash()) {
      hrefArr.push('#' + this.windowLocationService.getHash());
    } else {
      // Clear hash anyway
      hrefArr.push('');
    }

    // produce new alternative language link
    return hrefArr.join('');
  }

  /**
   * Window location updated
   */
  private onUpdateWindowLocation = () => {
    this.render();
  };
}
