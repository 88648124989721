import { domQuerySelector } from '@propertyfinder/pf-frontend-common/src/helper/dom/query-selector';
import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { ViewOptionsInterface } from 'pf-frontend-common/src/module/view/options.interface';

import { configGetCountry } from 'common/helper/config/get-country';
import { configGetLanguage } from 'common/helper/config/get-language';
import { PreactMarkupComponentsInterface } from 'common/helper/preact/markup/components.interface';
import { GoBackView } from 'common/module/go-back/view';
import { HeaderViewOptionsInterface } from 'common/module/header/view-options.interface';
import { HeaderSavedPropertiesView } from 'common/module/header-saved-properties/view';
import { HeaderServiceInterface } from 'common/service/header/service.interface';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { preactMarkup } from 'common/typings/preact-markup';

import { StatsTealiumDataLayerEventActionEnum } from '../stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from '../stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from '../stats/tealium/data-layer/tealium-event.enum';

export class HeaderView implements ViewBasicInterface, HeaderServiceInterface {
  /**
   * Header css classes
   */
  private readonly cssClass: DataKeyValueStringInterface = {
    headerHidden: 'header--hidden',
  };

  /**
   * View's main HTML element
   */
  private element: HTMLElement;

  /**
   * Does the header have the optional subview "go back" ?
   */
  private hasSubviewGoBack: boolean = false;

  /**
   * Does the header have the optional subview "header saved properties" ?
   */
  private hasSubviewHeaderSavedProperties: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private goBackView: GoBackView,
    private headerSavedPropertiesView: HeaderSavedPropertiesView,
    private preactMarkupHelper: preactMarkup,
    private components: PreactMarkupComponentsInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(options: HeaderViewOptionsInterface): void {
    // Keep element
    this.element = options.element;

    // Initialize subviews
    if (options.subview.goBack) {
      this.initGoBack(options.subview.goBack.viewOptions);
    }

    if (options.subview.headerSavedProperties) {
      this.initHeaderSavedPropertiesView(options.subview.headerSavedProperties.viewOptions);
    }

    // Add tealium tracking to Explore with DataGuru link
    const dataGuruHeaderLink = domQuerySelector(document, '[data-qs="data-guru-header-link"]');
    if (dataGuruHeaderLink) {
      dataGuruHeaderLink.onclick = (): void => {
        StatsTealiumProviderService().send({
          sendToGa: true,
          tealium_event: StatsTealiumDataLayerTealiumEventEnum.dataGuruCtaClick,
          event_action: StatsTealiumDataLayerEventActionEnum.dataGuruQuickLinksCtaClick,
          event_category: StatsTealiumDataLayerEventCategoryEnum.navigationClicks,
          page_country: configGetCountry().code,
          page_lang: configGetLanguage().current,
        });
      };
    }

    // Add tealium tracking to Insights Hub link
    const insightsHubHeaderLink = domQuerySelector(document, '[data-qs="insights-hub-header-link"]');
    if (insightsHubHeaderLink) {
      insightsHubHeaderLink.onclick = (): void => {
        StatsTealiumProviderService().send({
          sendToGa: true,
          tealium_event: StatsTealiumDataLayerTealiumEventEnum.insightsHubCtaClick,
          event_action: StatsTealiumDataLayerEventActionEnum.insightsHubCtaClick,
          event_category: StatsTealiumDataLayerEventCategoryEnum.navigationClicks,
        });
      };
    }

    // Add tealium tracking to Know your rights link
    const knowYourRightsHeaderLink = domQuerySelector(document, '[data-qs="know-your-rights-header-link"]');
    if (knowYourRightsHeaderLink) {
      knowYourRightsHeaderLink.onclick = (): void => {
        StatsTealiumProviderService().send({
          sendToGa: true,
          tealium_event: StatsTealiumDataLayerTealiumEventEnum.knowYourRightsLinkClick,
          event_action: StatsTealiumDataLayerEventActionEnum.knowYourRightsLinkClick,
          event_category: StatsTealiumDataLayerEventCategoryEnum.navigationClicks,
        });
      };
    }
  }

  /**
   * Set header components
   */
  public setComponents(components: PreactMarkupComponentsInterface): this {
    this.components = {
      ...this.components,
      ...components,
    };

    return this;
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    if (!this.element) {
      return;
    }

    this.preactMarkupHelper(this.element, this.components);

    // Render subviews
    this.renderGoBack();
    this.renderHeaderSavedProperties();
  }

  /**
   * @inheritDoc
   */
  public setVisible(isVisible: boolean): void {
    if (isVisible) {
      this.element.classList.remove(this.cssClass.headerHidden);
    } else {
      this.element.classList.add(this.cssClass.headerHidden);
    }
  }

  /**
   * Initialize go back
   */
  private initGoBack(viewOptions: ViewOptionsInterface): void {
    this.hasSubviewGoBack = true;

    this.goBackView.initialize(viewOptions);
  }

  /**
   * Render go back
   */
  private renderGoBack(): void {
    if (this.hasSubviewGoBack === false) {
      return;
    }

    this.goBackView.render();
  }

  /**
   * Initialize user save icon
   */
  private initHeaderSavedPropertiesView(viewOptions: ViewOptionsInterface): void {
    this.hasSubviewHeaderSavedProperties = true;

    this.headerSavedPropertiesView.initialize(viewOptions);
  }

  /**
   * Render user save icon
   */
  private renderHeaderSavedProperties(): void {
    if (this.hasSubviewHeaderSavedProperties === false) {
      return;
    }

    this.headerSavedPropertiesView.render();
  }
}
