import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { BedroomModel } from 'common/data/bedroom/model';
import { PropertyTypeModel } from 'common/data/property-type/model';

export class EstimationSettingsModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'estimation_settings';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = EstimationSettingsModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof this> = ['id'];

  /**
   * @inheritdoc
   */
  public jsonApiRelationships: Array<keyof this> = ['bedrooms', 'property_types'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['sqft_area_from', 'sqft_area_to'];

  /**
   * @inheritDoc
   */
  public meta: {
    sqft_area_from: number;
    sqft_area_to: number;
  };

  /**
   * Bedrooms
   */
  public bedrooms: BedroomModel[];

  // tslint:disable:variable-name

  /**
   * Property type
   */
  public property_types: PropertyTypeModel[];
}
