import { h } from 'preact';

import { domClassMerge } from 'common/helper/dom/class-merge';
import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { DropdownSizeEnum } from 'library/dropdown/size.enum';

import { KeepSameWidthWhenOpened } from '../keep-same-width-when-opened/component';
import { filterWidgetRenderMap } from './render-map';
import { FilterWidgetTypeEnum } from './type.enum';

export const FilterWidgetComponent = ({
  filterType,
  className,
  onChangeFilters,
  filterParams,
  dropdownSize = DropdownSizeEnum.normal,
}: {
  filterType: FilterWidgetTypeEnum;
  className?: string;
  onChangeFilters: (value: FilterParametersBaseInterface) => void;
  filterParams: FilterParametersBaseInterface;
  dropdownSize?: DropdownSizeEnum;
}) => {
  const { Component } = filterWidgetRenderMap[filterType];

  return (
    <KeepSameWidthWhenOpened
      Component={Component}
      componentProps={{
        className: domClassMerge(className, `data-filter-type-${filterType}`),
        onChangeFilters,
        filterParams,
        dropdownSize,
      }}
    />
  );
};
