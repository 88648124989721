import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class UserExistsResponseModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'user_exists';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserExistsResponseModel.JSONAPI_TYPE;

  public email: string;

  /**
   * Is email exists or not
   */
  public exists: boolean;
}
