import { BrowserWindowEvent } from '@propertyfinder/pf-frontend-common/src/module/browser/window.event';
import { WindowService } from '@propertyfinder/pf-frontend-common/src/service/window/service';

import { AutocompleteCacheLocationsGetIsDelayed } from '../get-is-delayed';
import { AutocompleteCacheLocationsService } from '../service';

const isDelayed = AutocompleteCacheLocationsGetIsDelayed();

export function AutocompleteCacheLocationsDelayedService(): void {
  if (isDelayed) {
    WindowService()
      .getNative()
      .addEventListener(BrowserWindowEvent.load, () => {
        AutocompleteCacheLocationsService().initialize();
      });
  } else {
    AutocompleteCacheLocationsService().initialize();
  }
}
