import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class UserEmailOtpResendModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'email-verification';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserEmailOtpResendModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserEmailOtpResendModel> = ['email'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof UserEmailOtpResendModel> = ['user'];

  public email: string = '';

  public user: UserModel;

  public success: boolean;
}
