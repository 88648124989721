import { BrowserDocumentService } from 'pf-frontend-common/dist/service/browser-document/service';
import { Component, createRef, h, RefObject } from 'preact';

import { DropdownBodyPositionEnum } from './body-position.enum';
import { DropdownPropsInterface } from './props.interface';
import { DropdownSizeEnum } from './size.enum';
import { DropdownStateInterface } from './state.interface';
import { DropdownTemplate } from './template';
import { DropdownVariantEnum } from './variant.enum';

/**
 * A dropdown component is a lightweight component
 *
 * Design: https://www.figma.com/file/gRARY1Vi4W2Ow1vRLw02am/PF_Consumer-Web-Kit?node-id=229%3A1322
 */
export class DropdownComponent extends Component<DropdownPropsInterface, DropdownStateInterface> {
  /**
   * @inheritDoc
   */
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    onChangeOpen: () => null as DropdownPropsInterface['onChangeOpen'],
    position: DropdownBodyPositionEnum.bottomLeft,
    variant: DropdownVariantEnum.secondary,
    size: DropdownSizeEnum.normal,
    isFloatingLabel: false,
    errorMessage: '',
    tabIndex: 0,
    disabled: false,
    isOpen: false,
    isInvalid: false,
  };

  /**
   * @inheritDoc
   */
  public static getDerivedStateFromProps(
    props: DropdownPropsInterface,
    state: DropdownStateInterface
  ): Partial<DropdownStateInterface> {
    const newState: Partial<DropdownStateInterface> = {
      previousIsOpenFromProps: props.isOpen,
    };

    if (props.isOpen !== undefined && state.previousIsOpenFromProps !== props.isOpen && state.isOpen !== props.isOpen) {
      newState.isOpen = props.isOpen;
    }

    return newState;
  }

  /**
   * Ref for the component
   */
  private readonly componentRef: RefObject<HTMLDivElement> = createRef();

  /**
   * Ref for the dropdown body
   */
  private readonly bodyRef: RefObject<HTMLDivElement> = createRef();

  /**
   * Browser service
   */
  private document: HTMLDocument;

  /**
   * @inheritDoc
   */
  public constructor(props: DropdownPropsInterface) {
    super(props);

    this.document = BrowserDocumentService().getDocument();
  }

  /**
   * @inheritDoc
   */
  public componentDidUpdate(prevProps: DropdownPropsInterface, prevState: DropdownStateInterface): void {
    if (this.state.isOpen !== prevState.isOpen) {
      this[this.state.isOpen ? 'attachEventListeners' : 'removeEventListeners']();
      this.props.onChangeOpen(this.state.isOpen);
      this.calculateBodyPosition();
    }
  }

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return (
      <DropdownTemplate
        {...this.props}
        position={this.state.position || this.props.position}
        componentRef={this.componentRef}
        bodyRef={this.bodyRef}
        isLabelVisible={this.props.label && (!this.props.text || this.props.isFloatingLabel)}
        isOpen={this.state.isOpen}
        onClick={this.onClickHead}
      />
    );
  }

  /**
   * Attach event listeners
   */
  private attachEventListeners(): void {
    this.document.addEventListener('click', this.onClickDocument, { capture: true });
  }

  /**
   * Remove event listeners
   */
  private removeEventListeners(): void {
    this.document.removeEventListener('click', this.onClickDocument, { capture: true });
  }

  /**
   * onClick event handler on the browser's document
   */
  private onClickDocument = (event: MouseEvent) => {
    if (this.state.isOpen && !this.isComponentTargeted(event) && this.doesTargetExists(event)) {
      this.closeDropdown();
    }
  };

  /**
   * Whether the event target is within the component or not
   */
  private isComponentTargeted(event: Event): boolean {
    return this.componentRef?.current && this.componentRef.current.contains(event.target as Node);
  }

  /**
   * Is target still exist in the body
   */
  private doesTargetExists = (event: Event): boolean => {
    return this.document.body.contains(event.target as Node);
  };

  /**
   * Handler when you click on the head
   */
  private readonly onClickHead = (e: MouseEvent): void => {
    if (this.props.preventClickDefault) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (this.props.disabled) {
      return;
    }
    this.setState((state) => ({
      isOpen: !state.isOpen,
    }));
  };

  /**
   * Close the dropdown
   */
  private closeDropdown = (): void => {
    this.setState({
      isOpen: false,
    });
  };

  private calculateBodyPosition = () => {
    const { position } = this.props;
    let newPosition = position;

    if (!this.bodyRef.current) {
      this.setState({ position });
      return;
    }

    const windowRect = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    const bodyRect = this.bodyRef.current.getBoundingClientRect();
    const parentRect = this.componentRef.current.getBoundingClientRect();

    if (position === DropdownBodyPositionEnum.bottomLeft && bodyRect.right > windowRect.width) {
      newPosition =
        parentRect.x + parentRect.width - bodyRect.width < 0
          ? DropdownBodyPositionEnum.bottomCenter
          : DropdownBodyPositionEnum.bottomRight;
    } else if (position === DropdownBodyPositionEnum.bottomRight && bodyRect.left < 0) {
      newPosition =
        parentRect.x + bodyRect.width > windowRect.width
          ? DropdownBodyPositionEnum.bottomCenter
          : DropdownBodyPositionEnum.bottomLeft;
    } else if (position === DropdownBodyPositionEnum.topLeft && bodyRect.right > windowRect.width) {
      newPosition =
        parentRect.x + parentRect.width - bodyRect.width < 0
          ? DropdownBodyPositionEnum.topCenter
          : DropdownBodyPositionEnum.topRight;
    } else if (position === DropdownBodyPositionEnum.topRight && bodyRect.left < 0) {
      newPosition =
        parentRect.x + bodyRect.width > windowRect.width
          ? DropdownBodyPositionEnum.topCenter
          : DropdownBodyPositionEnum.topLeft;
    }

    this.setState({
      position: newPosition,
    });
  };
}
