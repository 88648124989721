import { BackendConfigSettingsInterface } from 'common/data/backend/config/settings.interface';

/*
 * @description   Check if the uri is a search uri
 * @param         {BackendConfigSettingsInterface} settings
 * @param         {string} uri
 * @returns       {boolean}
 */
export function uriIsSearch(settings: BackendConfigSettingsInterface, uri: string): boolean {
  return uri === settings?.search?.uri;
}
