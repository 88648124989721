import { objectCompare } from 'common/helper/object/compare';
import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteStoreDecorator } from 'common/module/autocomplete/store/decorator';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterStore } from 'common/module/filter/store';
import { PropertyAutocompleteStoreDecoratorInterface } from 'common/module/property/autocomplete/store/decorator.interface';
import { LocationStoreServiceInterface } from 'common/service/location-store/service.interface';

export class PropertyAutocompleteStoreSubSelectionDecorator
  extends AutocompleteStoreDecorator
  implements PropertyAutocompleteStoreDecoratorInterface
{
  /**
   * Constructor
   */
  constructor(
    protected autocompleteStore: PropertyAutocompleteStoreDecoratorInterface,
    private locationStoreService: LocationStoreServiceInterface,
    private filterStore: FilterStore
  ) {
    super(autocompleteStore);
  }

  /**
   * @inheritDoc
   */
  public addResult(result: AutocompleteResultInterface): Promise<void> {
    const resultLocation = this.locationStoreService.getOneById(result.id);

    if (!resultLocation) {
      return this.autocompleteStore.addResult(result);
    }

    const filters = this.filterStore.getState();

    const locationIds = filters[FilterParamsEnum.locationsIds].value.filter((id) => {
      const location = this.locationStoreService.getOneById(id);

      if (!location) {
        return true;
      }

      // Filter is selected location is a children or parent of existing
      const isChild = resultLocation.path?.split('.').indexOf(location.id) !== -1;
      const isParent = location.path?.split('.').indexOf(resultLocation.id) !== -1;

      if (isChild || isParent) {
        return false;
      }

      return true;
    });

    const addResult = () => this.autocompleteStore.addResult(result);
    if (objectCompare(locationIds, filters[FilterParamsEnum.locationsIds].value)) {
      return addResult();
    } else {
      return this.filterStore
        .update({
          [FilterParamsEnum.locationsIds]: {
            ...filters[FilterParamsEnum.locationsIds],
            value: locationIds,
          },
        })
        .then(addResult);
    }
  }

  /**
   * @inheritDoc
   */
  public removeResult(result: AutocompleteResultInterface): Promise<void> {
    return this.autocompleteStore.removeResult(result);
  }

  /**
   * @inheritDoc
   */
  public search(searchString: string): void {
    this.autocompleteStore.search(searchString);
  }

  /**
   * @inheritDoc
   */
  public getAutocompleteResults(): AutocompleteSectionInterface[] {
    return this.autocompleteStore.getAutocompleteResults();
  }
}
