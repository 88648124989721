import { Component, h } from 'preact';

import { CountryAreaUnitEnum } from 'common/data/country/area-unit.enum';
import { functionNoop } from 'common/helper/function/noop';
import { AreaSelectorComponentPropsInterface } from 'common/module/area-selector/component-props.interface';
import { AreaSelectorTemplate } from 'common/module/area-selector/template';

export class AreaSelectorComponent extends Component<AreaSelectorComponentPropsInterface> {
  /**
   * @inheritDoc
   */
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    rtl: false,
    unit: CountryAreaUnitEnum.sqft,
    onChange: functionNoop,
    onClear: functionNoop,
    onDropdownOpenStatusChange: functionNoop,
  };

  /**
   * @inheritdoc
   */
  public render(): preact.JSX.Element {
    const { value, unit, className, onChange, onClear, onDropdownOpenStatusChange, style } = this.props;
    const options = this.props.options.map((option) => option.value);

    return (
      <AreaSelectorTemplate
        value={value}
        unit={unit}
        onChange={onChange}
        onClear={onClear}
        options={options}
        className={className}
        onDropdownOpenStatusChange={onDropdownOpenStatusChange}
        style={style}
        dropdownSize={this.props.dropdownSize}
      />
    );
  }
}
