import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { DataStoreEvent } from 'common/module/data/store.event';
import { DataStoreInterface } from 'common/module/data/store.interface';
import { DataStoreOptionsInterface } from 'common/module/data/store/options.interface';

export abstract class DataStore<State = {}> implements DataStoreInterface<State> {
  /**
   * Current state of data
   */
  protected state: State;

  /**
   * Constructor
   */
  constructor(protected eventEmitter: EventEmitterInterface) {}

  /**
   * @inheritDoc
   */
  public initState(): void {
    this.state = {} as State;
  }

  /**
   * @inheritDoc
   */
  public initialize(options?: DataStoreOptionsInterface): void {
    return;
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    return;
  }

  /**
   * @inheritDoc
   */
  public getState(): State {
    return this.state;
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * Pass the modified state data into this to set the state object and fire the update event
   *
   * TODO-FE[later]: STORE: Improve to support dynamic property setting instead of just replacing state.
   * TODO-FE[later]: Use DataStoreDispatchEventInterface to enforce event type checking, currently it would fail.
   */
  protected setState<A>(state: State, action?: A): void {
    this.state = state;

    this.getEventEmitter().emit(DataStoreEvent.updateState, {
      action,
    });
  }
}
