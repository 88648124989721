import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class UserLoginModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'user_login';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserLoginModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserLoginModel> = ['email', 'password', 'remember_me', 'captcha_token'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof UserLoginModel> = ['user'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['token', 'refresh_token'];

  /**
   * Email
   */
  public email: string = '';

  /**
   * Password
   */
  public password: string = '';

  /**
   * User
   */
  public user: UserModel;

  // tslint:disable:variable-name

  /**
   * Remember me?
   */
  public remember_me: boolean = true;

  /**
   * Is agreed with first conditional?
   */
  public termsAndConditions: boolean = false;

  /**
   * Is agreed with using ther email?
   */
  public useEmail: boolean = false;

  /**
   * Captcha verification token
   */
  public captcha_token: string = '';

  // tslint:enable:variable-name

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Authorization token
     */
    token: string;

    /**
     * Refresh token
     */
    refresh_token: string;
  };
}
