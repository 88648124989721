export enum SortParamsEnum {
  /**
   * Bed (least)
   */
  bedLeast = 'ba',

  /**
   * Bed (most)
   */
  bedMost = 'bd',

  /**
   * Delivery Date (latest)
   */
  deliveryDateLatest = 'dd',

  /**
   * Delivery Date (earliest)
   */
  deliveryDateEarliest = 'da',

  /**
   * Featured
   */
  featured = 'mr',

  /**
   * Newest
   */
  newest = 'nd',

  /**
   * Price (low)
   */
  priceLow = 'pa',

  /**
   * Price (hight)
   */
  priceHigh = 'pd',
}
