import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { ModalServiceInterface } from 'pf-frontend-common/src/service/modal/service.interface';
import { WindowLocationServiceInterface } from 'pf-frontend-common/src/service/window-location/service.interface';

import { EmailAlertUnsubscribeViewStore } from 'common/module/email-alert/unsubscribe/view-store';
import { ViewDynamicInterface } from 'common/module/view/dynamic.interface';

export class EmailAlertUnsubscribeView implements ViewBasicInterface {
  /**
   * Url querystring parameter to trigger the 'unsubscribe' alert modal
   */
  private readonly UNSUBSCRIBE_ALERT_PARAM: string = 'uea';

  /**
   * Should the 'email unsubscribed' alert display ?
   */
  private shouldRenderAlertUnsubscribed: boolean = false;

  /**
   * Constructor
   */
  constructor(
    private windowLocationService: WindowLocationServiceInterface,
    private modalService: ModalServiceInterface,
    private emailAlertUnsubscribeViewDynamic: ViewDynamicInterface<EmailAlertUnsubscribeViewStore>
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(): void {
    this.emailAlertUnsubscribeViewDynamic.initialize({
      element: this.modalService.getContentElement(),
    });

    this.emailAlertUnsubscribeViewDynamic.getViewStore().initialize({
      onClickClose: this.onClickClose,
    });
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    this.shouldRenderAlertUnsubscribed =
      this.windowLocationService.getQueryData()[this.UNSUBSCRIBE_ALERT_PARAM] === '1';

    if (!this.shouldRenderAlertUnsubscribed) {
      return;
    }

    this.emailAlertUnsubscribeViewDynamic.render();
    this.modalService.open();
  }

  /**
   * Close email alert
   */
  private onClickClose = (e: Event) => {
    e.preventDefault();

    const queryData = this.windowLocationService.getQueryData();

    // Remove the param from the URL
    delete queryData[this.UNSUBSCRIBE_ALERT_PARAM];

    this.windowLocationService.replace(this.windowLocationService.getPathname(), {
      hash: this.windowLocationService.getHash(),
      queryData,
    });

    this.modalService.close();
  };
}
