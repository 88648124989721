import { asyncDebounce } from 'common/helper/async/debounce';
import { AutocompleteResultInterface } from 'common/module/autocomplete/result.interface';
import { AutocompleteSectionInterface } from 'common/module/autocomplete/section.interface';
import { AutocompleteStoreEvent } from 'common/module/autocomplete/store.event';
import { AutocompleteStoreInterface } from 'common/module/autocomplete/store.interface';
import { AutocompleteStoreDecorator } from 'common/module/autocomplete/store/decorator';
import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterStore } from 'common/module/filter/store';
import { PropertyAutocompleteStoreDecoratorEvent } from 'common/module/property/autocomplete/store/decorator.event';
import { PropertyAutocompleteStoreDecoratorInterface } from 'common/module/property/autocomplete/store/decorator.interface';

export class PropertyAutocompleteStoreDecorator
  extends AutocompleteStoreDecorator
  implements PropertyAutocompleteStoreDecoratorInterface
{
  /**
   * @inheritDoc
   */
  public search: (searchString: string) => void;

  /**
   * @inheritDoc
   */
  constructor(autocompleteStore: AutocompleteStoreInterface, private filterStore: FilterStore) {
    super(autocompleteStore);

    this.search = asyncDebounce((searchString: string) => {
      this.searchLocations(searchString, {
        [FilterParamsEnum.pageLimit]: 10,
      });
    }, 0);

    this.getEventEmitter().addListener(AutocompleteStoreEvent.searchLocationsSuccess, this.onSearchLocationsSuccess);
  }

  /**
   * @inheritDoc
   */
  public addResult(result: AutocompleteResultInterface): Promise<void> {
    const filters = this.filterStore.getState();

    if (!filters[FilterParamsEnum.locationsIds].value.length) {
      return this.filterStore.update({
        [FilterParamsEnum.locationsIds]: {
          ...filters[FilterParamsEnum.locationsIds],
          value: [result.id],
        },
        [FilterParamsEnum.pageNumber]: {
          value: getFilterDefaultParams()[FilterParamsEnum.pageNumber],
        },
      });
    }

    const locationIds = [...filters[FilterParamsEnum.locationsIds].value];

    if (locationIds.indexOf(result.id) !== -1) {
      return;
    }

    locationIds.push(result.id);

    return this.filterStore.update({
      [FilterParamsEnum.locationsIds]: {
        ...filters[FilterParamsEnum.locationsIds],
        value: locationIds,
      },
      [FilterParamsEnum.pageNumber]: {
        value: getFilterDefaultParams()[FilterParamsEnum.pageNumber],
      },
    });
  }

  /**
   * @inheritDoc
   */
  public removeResult(result: AutocompleteResultInterface): Promise<void> {
    const filters = this.filterStore.getState();

    let locationIds = filters[FilterParamsEnum.locationsIds].value;

    if (!locationIds) {
      return;
    }

    locationIds = locationIds.filter((locationId) => locationId !== result.id);

    return this.filterStore.update({
      [FilterParamsEnum.locationsIds]: {
        ...filters[FilterParamsEnum.locationsIds],
        value: locationIds,
      },
      [FilterParamsEnum.pageNumber]: {
        value: getFilterDefaultParams()[FilterParamsEnum.pageNumber],
      },
    });
  }

  /**
   * @inheritDoc
   */
  public getAutocompleteResults(): AutocompleteSectionInterface[] {
    const autocompleteStoreState = this.getState();
    const autocompleteResults: AutocompleteSectionInterface[] = [];

    if (autocompleteStoreState.locations && autocompleteStoreState.locations.length) {
      autocompleteResults.push({
        id: '1',
        name: '',
        suggestions: autocompleteStoreState.locations.map((location) => ({
          id: location.id,
          text: `${location.abbreviation ? location.abbreviation + ' - ' : ''}${location.name}`,
          description: location.path_name,
          sectionId: '1',
        })),
      });
    }

    return autocompleteResults;
  }

  /**
   * Triggers when locations found
   */
  private onSearchLocationsSuccess = () => {
    this.getEventEmitter().emit(PropertyAutocompleteStoreDecoratorEvent.searchSuccess);
  };
}
