import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';
import { JsonApiPayloadInterface } from 'pf-frontend-common/src/module/json-api/payload.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { AgentModel } from 'common/data/agent/model';
import { BrokerModel } from 'common/data/broker/model';
import { LocationModel } from 'common/data/location/model';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { AutocompleteStoreEvent } from 'common/module/autocomplete/store.event';
import { AutocompleteStoreInterface } from 'common/module/autocomplete/store.interface';
import { AutocompleteStoreSearchConfigInterface } from 'common/module/autocomplete/store/search-config.interface';
import { AutocompleteStoreStateInterface } from 'common/module/autocomplete/store/state.interface';
import { AutocompleteStoreFetchFieldsInterface } from 'common/module/autocomplete/store-fetch-fields.interface';
import { DataStore } from 'common/module/data/store';
import { PromiseCancelableInterface } from 'common/module/promise/cancelable.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { LocationStoreServiceInterface } from 'common/service/location-store/service.interface';

export class AutocompleteStore extends DataStore implements AutocompleteStoreInterface {
  /**
   * @inheritDoc
   */
  protected state: AutocompleteStoreStateInterface;

  /**
   * Initial state of the store
   */
  private initialState: AutocompleteStoreStateInterface = {
    agentId: null,
    brokerId: null,
    locationId: null,
  };

  /**
   * Api endpoints
   */
  private endpoints: DataKeyValueStringInterface = {
    fetchLocations: this.apiEndpointService.getPath('/location'),
    fetchAgents: this.apiEndpointService.getPath('/agent'),
    fetchBrokers: this.apiEndpointService.getPath('/broker'),
  };

  /**
   * Last cancelable promise from fetch agents endpoint
   */
  private fetchAgentsCancelable: PromiseCancelableInterface<AgentModel[]>;

  /**
   * Last cancelable promise from fetch locations endpoint
   */
  private fetchLocationsCancelable: PromiseCancelableInterface<LocationModel[]>;

  /**
   * Last cancelable promise from fetch broker endpoint
   */
  private fetchBrokersCancelable: PromiseCancelableInterface<BrokerModel[]>;

  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private store: JsonApiStore<JsonApiModel>,
    private promiseCancelableFactory: <T>(promise: Promise<T>) => PromiseCancelableInterface<T>,
    private locationStoreService: LocationStoreServiceInterface
  ) {
    super(eventEmitter);

    this.state = { ...this.initialState };
  }

  /**
   * @inheritDoc
   */
  public getState(): AutocompleteStoreStateInterface {
    return this.state;
  }

  /**
   * @inheritDoc
   */
  public searchLocations(
    searchString: string,
    config?: AutocompleteStoreSearchConfigInterface
  ): Promise<LocationModel[]> {
    this.getEventEmitter().emit(AutocompleteStoreEvent.searchLocationsStart, searchString);

    if (this.fetchLocationsCancelable) {
      this.fetchLocationsCancelable.cancel();
      this.getEventEmitter().emit(AutocompleteStoreEvent.searchLocationsReset, searchString);
    }

    this.fetchLocationsCancelable = this.promiseCancelableFactory(
      this.fetchAutocomplete<LocationModel>(this.endpoints.fetchLocations, {
        'filter[full_name]': searchString,
        'fields[location]': 'name,path,path_name',
        ...config,
      })
    );

    return this.fetchLocationsCancelable.getPromise().then((result) => {
      if (!result) {
        return;
      }

      this.setLocations(result);
      this.getEventEmitter().emit(AutocompleteStoreEvent.searchLocationsSuccess, result);
      return result;
    });
  }

  /**
   * @inheritDoc
   */
  public searchAgents(searchString: string): void {
    this.getEventEmitter().emit(AutocompleteStoreEvent.searchAgentsStart, searchString);

    if (this.fetchAgentsCancelable) {
      this.fetchAgentsCancelable.cancel();
      this.getEventEmitter().emit(AutocompleteStoreEvent.searchAgentsReset, searchString);
    }

    this.fetchAgentsCancelable = this.promiseCancelableFactory(
      this.fetchAutocomplete<AgentModel>(this.endpoints.fetchAgents, {
        'filter[name]': searchString,
        'fields[agent]': 'name,broker',
        'fields[broker]': 'name',
        include: 'broker',
        'page[limit]': 8,
      })
    );

    this.fetchAgentsCancelable.getPromise().then((result) => {
      if (!result) {
        return;
      }

      this.setAgents(result);
      this.getEventEmitter().emit(AutocompleteStoreEvent.searchAgentsSuccess, result);
    });
  }

  /**
   * @inheritDoc
   */
  public searchBrokers(searchString: string): void {
    this.getEventEmitter().emit(AutocompleteStoreEvent.searchBrokersStart, searchString);

    if (this.fetchBrokersCancelable) {
      this.fetchBrokersCancelable.cancel();
      this.getEventEmitter().emit(AutocompleteStoreEvent.searchBrokersReset, searchString);
    }

    this.fetchBrokersCancelable = this.promiseCancelableFactory(
      this.fetchAutocomplete<BrokerModel>(this.endpoints.fetchBrokers, {
        'filter[name]': searchString,
        'fields[broker]': 'name',
        'page[limit]': 8,
      })
    );

    this.fetchBrokersCancelable.getPromise().then((result) => {
      if (!result) {
        return;
      }

      this.setBrokers(result);
      this.getEventEmitter().emit(AutocompleteStoreEvent.searchBrokersSuccess, searchString);
    });
  }

  /**
   * @inheritDoc
   */
  public getAgentById(agentId: string): AgentModel {
    return this.state.agents.filter((agent) => agent.id === agentId)[0];
  }

  /**
   * @inheritDoc
   */
  public getBrokerById(brokerId: string): BrokerModel {
    return this.state.brokers.filter((broker) => broker.id === brokerId)[0];
  }

  /**
   * @inheritDoc
   */
  public getLocationById(locationId: string): LocationModel {
    return this.state.locations.filter((location) => location.id === locationId)[0];
  }

  /**
   * @inheritDoc
   */
  public setAgentId(id: string): void {
    this.setId('agentId', id);
  }

  /**
   * @inheritDoc
   */
  public setBrokerId(id: string): void {
    this.setId('brokerId', id);
  }

  /**
   * @inheritDoc
   */
  public setLocationId(id: string): void {
    this.setId('locationId', id);
  }

  /**
   * @inheritDoc
   */
  public resetState(): void {
    this.state = { ...this.initialState };
    this.setState(this.state);
  }

  /**
   * Sets locations state
   */
  public setLocations(locations: LocationModel[]): void {
    this.state.locations = locations;
    this.setState(this.state, this.setLocations);
    this.locationStoreService.initialize({
      models: locations,
    });
  }

  /**
   * Sets agents state
   */
  public setAgents(agents: AgentModel[]): void {
    this.state.agents = agents;
    this.setState(this.state, this.setAgents);
  }

  /**
   * Sets brokers state
   */
  public setBrokers(brokers: BrokerModel[]): void {
    this.state.brokers = brokers;
    this.setState(this.state, this.setBrokers);
  }

  /**
   * Set id
   */
  private setId<T extends keyof AutocompleteStoreStateInterface>(
    id: T,
    value: AutocompleteStoreStateInterface[T]
  ): void {
    const idKeys: { [key: string]: boolean } = {
      agentId: true,
      brokerId: true,
      locationId: true,
    };
    delete idKeys[id];

    Object.keys(idKeys).forEach((key: keyof AutocompleteStoreStateInterface) => (this.state[key] = null));

    this.state[id] = value;
    this.setState(this.state);
  }

  /**
   * Fetches autocomplete results from backend
   *
   * @param searchString - search string
   * @param endpoint - api endpoint
   */
  private fetchAutocomplete<T extends JsonApiModel>(
    endpoint: string,
    fields?: AutocompleteStoreFetchFieldsInterface
  ): Promise<T[]> {
    return <Promise<T[]>>this.apiService
      .request(
        'GET',
        endpoint,
        objectFilterNonOrEmptyValue({
          'page[limit]': 5,
          ...fields,
        })
      )
      .then((response) => {
        const data = this.store.sync(<JsonApiPayloadInterface>response.data);

        if (Array.isArray(data)) {
          return data;
        }

        return [];
      });
  }
}
