import { FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';
import { Fragment } from 'react';

import { BackendConfigEnvPlatformEnum } from 'common/data/backend/config/env-platform.enum';
import { configGetEnv } from 'common/helper/config/get-env';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { ButtonComponent } from 'common/module/button/component';
import { UserAuthAsyncComponent } from 'common/module/user/auth/async.component';
import { UserAvatarTemplatePropsInterface } from 'common/module/user/avatar/template-props.interface';

import { UserAuthDrawerAsyncComponent } from '../auth/async-drawer.component';

export const UserAvatarTemplate: FunctionalComponent<UserAvatarTemplatePropsInterface> = (props) => {
  if (!props.isLoggedIn && props.isHeaderLoginButtonHidden) {
    return null;
  }

  const [modalVisible, setModalVisible] = useState(false);

  return (
    <Fragment>
      <div className='user-avatar user-avatar--visible'>
        {props.isLoggedIn ? (
          <a href={configGetEnv().userDetailsUrl} className='user-avatar__img' onClick={props.onClickUserAvatar}>
            {props.userAvatar.image ? (
              <img className='user-avatar__user-photo' src={props.userAvatar.image} />
            ) : (
              <svg className='user-avatar__icon--loggedin' viewBox='0 0 26 26'>
                <use xlinkHref='#svg-user-o-filled' />
              </svg>
            )}
          </a>
        ) : (
          <ButtonComponent
            onClick={(e): void => {
              setModalVisible(true);
              props.onClickUserAvatar(e);
            }}
            href={
              configGetEnv().platform === BackendConfigEnvPlatformEnum.mobile
                ? configGetEnv().userAccountDashboardUrl
                : null
            }
            cssClass='button-supportive--color-1'
          >
            {i18nTranslate('Log in')}
          </ButtonComponent>
        )}

        {props.children}
      </div>

      {modalVisible && configGetEnv().platform === BackendConfigEnvPlatformEnum.desktop && (
        <UserAuthAsyncComponent
          onSuccess={(): void => setModalVisible(false)}
          onClose={(): void => setModalVisible(false)}
        />
      )}

      {modalVisible && configGetEnv().platform === BackendConfigEnvPlatformEnum.mobile && (
        <UserAuthDrawerAsyncComponent
          onSuccess={(): void => setModalVisible(false)}
          onClose={(): void => setModalVisible(false)}
        />
      )}
    </Fragment>
  );
};
