import { h } from 'preact';

import { ButtonComponent } from 'common/module/button/component';
import { ButtonComponentTypeEnum } from 'common/module/button/component-type.enum';
import { IconSearchTemplate } from 'common/module/icon/search';

import { FilterFormSearchButtonComponentPropsInterface } from './search-button-component-props.interface';

export const FilterFormSearchButton = ({ className, onClick }: FilterFormSearchButtonComponentPropsInterface) => (
  <ButtonComponent
    cssClass={`filter-form-search-button ${className ? className : ''}`}
    iconCssClass='filter-form-search-button__icon--reset-margin'
    componentType={ButtonComponentTypeEnum.type2}
    onClick={onClick}
    icon={IconSearchTemplate}
  />
);
