import { Component, h } from 'preact';

import { BackendConfigAdsNamesEnum } from 'common/data/backend/config/ads-names.enum';
import { configGetAds } from 'common/helper/config/get-ads';
import { AdComponent } from 'common/module/ad/component';

export class HomePageBannerComponent extends Component<{ index: string }, {}> {
  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    const homepageAds = configGetAds()
      .ad_units.filter((adUnit) => adUnit.name === BackendConfigAdsNamesEnum.homepage)
      .sort((adUnit1, adUnit2) => adUnit1.position - adUnit2.position);
    const ad = homepageAds[Number(this.props.index) - 1];

    return !!ad && <AdComponent adUnit={ad} className='banner-homepage' />;
  }
}
