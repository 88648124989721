import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { BrokerModel } from 'common/data/broker/model';
import { BrokerStore } from 'common/data/broker/store';
import { CookieAuthenticatorApiService } from 'common/module/cookie-authenticator/api.service';
import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';

export function BrokerStoreFactory(): BrokerStore {
  return new BrokerStore(
    EventEmitterFactory(),
    CookieAuthenticatorApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<BrokerModel>(BrokerModel.JSONAPI_TYPE)
  );
}
