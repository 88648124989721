import { FunctionalComponent, h } from 'preact';

import { arrayFromRange } from 'common/helper/array/from-range';
import { preactLifecycleComponent } from 'common/module/preact/lifecycle-component';
import { PropertyListTemplatePropsInterface } from 'common/module/property/list/template-props.interface';
import { HomePageBannerComponent } from 'desktop/module/home-page/banner/component';
import { HomePageRecommendedPropertiesListItemTemplate } from 'desktop/module/home-page/recommended-properties/list-item-template';

const RecommendedPropertiesListItem = preactLifecycleComponent(HomePageRecommendedPropertiesListItemTemplate);

export const HomePageRecommendedPropertiesListTemplate: FunctionalComponent<
  PropertyListTemplatePropsInterface & {
    /**
     * Is list loading
     */
    isLoading: boolean;

    /**
     * Recommended properties count
     */
    propertiesCount: number;
  }
> = (props) => (
  <div className='featured-properties__list'>
    {arrayFromRange(props.propertiesCount).map((index) => [
      <div
        className={`
              featured-properties__list-item
              ${props.isLoading ? 'animated-placeholder featured-properties__list-item--placeholder' : ''}
             `}
      >
        {!props.isLoading && (
          <RecommendedPropertiesListItem
            {...props.properties[index]}
            onComponentDidMount={(element: HTMLElement) => props.properties[index].onCreateTemplate(element, index)}
          />
        )}
      </div>,
      (index + 1) % 2 === 0 && (
        <div className='featured-properties__list-item featured-properties__list-item--banner'>
          <div className='banner'>
            <HomePageBannerComponent index={String((index + 1) / 2)} />
          </div>
        </div>
      ),
    ])}
  </div>
);
