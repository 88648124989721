// this magic with class is required to avoid webpack inline the secret into the places where it is used
// making it more difficult to reverse engineer cookie sign algorithm
// tslint:disable-next-line: pf-file
class A {
  public static e(): number {
    return 5;
  }
}

/**
 * To make it more difficult to find out the random salt part in the hash, salt is hidden between the hash characters
 */
export const cookieAuthenticatorGetSaltIndex = function e(): number {
  return A.e();
};
