import { PropertyModel } from 'common/data/property/model';
import { configGetFeature } from 'common/helper/config/get-feature';
import { configGetLanguage } from 'common/helper/config/get-language';
import { DateOptionsValueEnum } from 'common/helper/date/options-value.enum';
import { dateToLocale } from 'common/helper/date/to-locale';
import { TagNewTypeEnum } from 'common/module/tag-new/type.enum';

import { TagListItemType } from './item.type';

export function tagListGetItems(property: PropertyModel): TagListItemType[] {
  const list: TagListItemType[] = [];
  const isBrokerProjectProperty = property.is_broker_project_property;

  if (property.is_claimed_by_agent || property.is_under_offer_by_competitor) {
    list.push(TagNewTypeEnum.UNDER_OFFER);
  }

  if (property.verified) {
    list.push(TagNewTypeEnum.VERIFIED);
  }

  if (configGetFeature('trusted_agent').enabled && property.agent?.is_trusted) {
    list.push(TagNewTypeEnum.SUPER_AGENT);
  }

  if (property.completion_status === 'completed_primary' || property.completion_status === 'off_plan_primary') {
    list.push(TagNewTypeEnum.NEW_LISTING);
  }

  if (property.areaSpecialistProperty) {
    list.push(TagNewTypeEnum.COMMUNITY_EXPERT);
  }

  if (property.is_spotlight_listing) {
    list.push(TagNewTypeEnum.SPOTLIGHT_LISTING);
  }

  if (property.is_new_insert && configGetFeature('something_new').enabled) {
    list.push(TagNewTypeEnum.NEW_PROPERTY);
  }

  if (property.new_projects && !isBrokerProjectProperty) {
    list.push(TagNewTypeEnum.DEVELOPER);
  }

  if (property.meta.live_event_metadata) {
    list.push({
      type: TagNewTypeEnum.LIVE_VIEWING,
      text: dateToLocale(property.meta.live_event_metadata.scheduled_start_date, configGetLanguage().current, {
        day: DateOptionsValueEnum.numeric,
        month: DateOptionsValueEnum.short,
        minute: DateOptionsValueEnum.numeric,
        hour: DateOptionsValueEnum.numeric,
      }),
    });
  }

  // Only 3 banners can be shown at any given time
  return list.slice(0, 3);
}
