import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { FilterParametersBaseInterface } from 'common/module/filter/parameters-base.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

import { SaveSearchFiltersInterface } from './filters.interface';
import { SaveSearchPayloadFilterKeysEnum } from './payload-filter-keys.enum';

const mapper: {
  [key in FilterParamsEnum]?: {
    newKey: SaveSearchPayloadFilterKeysEnum;
    filterAndMap: (value: string | number | string[]) => string | number | string[] | boolean;
  };
} = {
  [FilterParamsEnum.categoryId]: {
    filterAndMap: (value: string) => parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.CATEGORY_ID,
  },

  [FilterParamsEnum.propertyTypeId]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.PROPERTY_TYPE_ID,
  },

  [FilterParamsEnum.minPrice]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.MIN_PRICE,
  },

  [FilterParamsEnum.maxPrice]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.MAX_PRICE,
  },

  [FilterParamsEnum.minArea]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.MIN_AREA,
  },

  [FilterParamsEnum.maxArea]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.MAX_AREA,
  },

  [FilterParamsEnum.pricePeriod]: {
    filterAndMap: (value: string) => value,
    newKey: SaveSearchPayloadFilterKeysEnum.PRICE_TYPE,
  },

  [FilterParamsEnum.locationsIds]: {
    filterAndMap: (value: string[]) => !!value.length && value,
    newKey: SaveSearchPayloadFilterKeysEnum.LOCATION_IDS,
  },

  [FilterParamsEnum.query]: {
    filterAndMap: (value: string) => !!value && value,
    newKey: SaveSearchPayloadFilterKeysEnum.QUERY,
  },

  [FilterParamsEnum.keyword]: {
    filterAndMap: (value: string) => !!value && value,
    newKey: SaveSearchPayloadFilterKeysEnum.KEYWORD,
  },

  [FilterParamsEnum.amenities]: {
    filterAndMap: (value: string[]) => !!value.length && value,
    newKey: SaveSearchPayloadFilterKeysEnum.AMENITIES,
  },

  [FilterParamsEnum.bedrooms]: {
    filterAndMap: (value: string[]) => !!value.length && value,
    newKey: SaveSearchPayloadFilterKeysEnum.BEDROOMS,
  },

  [FilterParamsEnum.bathrooms]: {
    filterAndMap: (value: string[]) => !!value.length && value,
    newKey: SaveSearchPayloadFilterKeysEnum.BATHROOMS,
  },

  [FilterParamsEnum.furnishing]: {
    filterAndMap: (value: string) =>
      getFilterDefaultParams()[FilterParamsEnum.furnishing] === value ? undefined : parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.FURNISHED,
  },

  [FilterParamsEnum.completionStatus]: {
    filterAndMap: (value: string) => !!value && value,
    newKey: SaveSearchPayloadFilterKeysEnum.COMPLETION_STATUS,
  },

  [FilterParamsEnum.virtualViewings]: {
    filterAndMap: (value: string) => !!value && value,
    newKey: SaveSearchPayloadFilterKeysEnum.VIRTUAL_VIEWING,
  },

  [FilterParamsEnum.isDeveloperProperty]: {
    filterAndMap: (value: string) => !!value,
    newKey: SaveSearchPayloadFilterKeysEnum.IS_DEVELOPER_PROPERTY,
  },

  [FilterParamsEnum.minInstallmentYears]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.MIN_INSTALLMENT_YEARS,
  },

  [FilterParamsEnum.maxInstallmentYears]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.MAX_INSTALLMENT_YEARS,
  },

  [FilterParamsEnum.listedWithin]: {
    filterAndMap: (value: string) => !!value && parseInt(value, 10),
    newKey: SaveSearchPayloadFilterKeysEnum.LISTED_WITHIN,
  },

  [FilterParamsEnum.paymentMethods]: {
    filterAndMap: (value: string[]) => !!value.length && value,
    newKey: SaveSearchPayloadFilterKeysEnum.PAYMENT_METHODS,
  },
};

/**
 *
 * @param filters active filters on the page
 * @returns filtered object to send to the create save search request
 */
export function saveSearchMapper(filters: FilterParametersBaseInterface): SaveSearchFiltersInterface {
  return Object.keys(filters).reduce((result: any, current: keyof FilterParametersBaseInterface) => {
    const value = mapper[current]?.filterAndMap(filters[current].value);

    if (typeof value !== 'undefined' && value !== false) {
      const index: string = mapper[current].newKey;
      result[index] = value;
    }

    return result;
  }, {}) as SaveSearchFiltersInterface;
}
