import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { BrowserDocumentService } from 'pf-frontend-common/src/service/browser-document/service';
import { WindowService } from 'pf-frontend-common/src/service/window/service';

import { configGetKeys } from 'common/helper/config/get-keys';
import { PromiseCancelableFactory } from 'common/module/promise/cancelable.factory';
import { GoogleRecaptchaScriptService } from 'common/service/google-recaptcha-script/service';

import { GoogleRecaptchaViewStore } from './view-store';

export function GoogleRecaptchaViewStoreFactory(): GoogleRecaptchaViewStore {
  return new GoogleRecaptchaViewStore(
    EventEmitterFactory(),
    GoogleRecaptchaScriptService(),
    configGetKeys().recaptcha,
    PromiseCancelableFactory,
    WindowService(),
    BrowserDocumentService()
  );
}
