export enum AutocompleteViewStoreEvent {
  selectResultItem = 'select-result-item',
  clear = 'clear',
  focus = 'focus',
  keyUp = 'keyup',
  clickInput = 'click-input',
  /**
   * Blurred autocomplete input
   */
  blurInput = 'blur-input',
}
