export enum DropdownVariantEnum {
  /**
   * The primary dropdown
   */
  primary = 'primary',

  /**
   * The default secondary dropdown.
   */
  secondary = 'secondary',
}
