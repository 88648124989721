import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiPayloadInterface } from 'pf-frontend-common/src/module/json-api/payload.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';

import { AgentModel } from 'common/data/agent/model';
import { AgentStoreEvent } from 'common/data/agent/store.event';
import { AgentStoreGetAllByNameInputInterface } from 'common/data/agent/store/get-all-by-name/input.interface';
import { AgentStoreSearchInputInterface } from 'common/data/agent/store/search/input.interface';
import { AgentStoreSearchOutputInterface } from 'common/data/agent/store/search/output.interface';
import { AgentStoreOptionsInterface } from 'common/data/agent/store-options.interface';
import { jsonApiFlatParams } from 'common/helper/json-api/flat-params';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { stringEscape } from 'common/helper/string/escape';
import { CookieAuthenticatorApiServiceInterface } from 'common/module/cookie-authenticator/api-service.interface';
import { DataStorePaginationAdapter } from 'common/module/data/store/pagination-adapter';
import { JsonApiMetaPaginationInterface } from 'common/module/json-api/meta/pagination.interface';
import { AgentStoreServiceInterface } from 'common/service/agent-store/service.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';

export class AgentStore implements AgentStoreServiceInterface {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    search: {
      path: this.apiEndpointService.getPath('/agent'),
      method: 'GET',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private apiService: CookieAuthenticatorApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private jsonApiStore: JsonApiStore<AgentModel>
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public initialize(options: AgentStoreOptionsInterface): void {
    // From models
    if (options.models) {
      options.models.forEach((agentModel) => this.jsonApiStore.add(agentModel));
    }

    // From JsonApi payload
    if (options.payload) {
      this.jsonApiStore.syncWithMeta(options.payload);
    }
  }

  /**
   * @inheritDoc
   */
  public reset(): void {
    this.jsonApiStore.reset();
  }

  /**
   * @inheritDoc
   */
  public add(model: AgentModel): void {
    this.jsonApiStore.add(model);
  }

  /**
   * @inheritDoc
   */
  public getOneById(id: string): AgentModel | null {
    return this.jsonApiStore.find(id);
  }

  /**
   * @inheritDoc
   */
  public getAll(): AgentModel[] {
    return this.jsonApiStore.findAll();
  }

  /**
   * @inheritDoc
   */
  public getAllByName(input: AgentStoreGetAllByNameInputInterface): AgentModel[] {
    const name = stringEscape(input.name.trim());

    // No name provided
    if (!name) {
      return [];
    }

    // Filter loaded agents
    const agentModels = this.getAll().filter((agentModel) =>
      agentModel.name.match(new RegExp(`\\s${name}[^\\s]*|^${name}[^\\s]*`, 'gi'))
    );

    // Limit results
    return input.limit ? agentModels.slice(0, input.limit) : agentModels;
  }

  /**
   * @inheritDoc
   */
  public search(input: AgentStoreSearchInputInterface): Promise<AgentStoreSearchOutputInterface> {
    // Default include
    if (!input.include) {
      input.include = 'languages,broker';
    }

    // Emit event: start
    this.getEventEmitter().emit(AgentStoreEvent.searchStart);

    return new Promise((resolve, reject) => {
      // API request
      this.apiService
        .request(
          this.endpoint.search.method,
          this.endpoint.search.path,
          objectFilterNonOrEmptyValue(jsonApiFlatParams(input))
        )
        .then((httpResponse: HttpApiResponseInterface) => {
          // Get specialized output
          const output = this.getSearchOutput(<JsonApiPayloadInterface>httpResponse.data);

          // Emit event: success
          this.getEventEmitter().emit(AgentStoreEvent.searchSuccess, output);

          // Resolve promise
          resolve(output);
        })
        .catch((httpResponse: HttpApiResponseInterface) => {
          // Emit event: failure
          this.getEventEmitter().emit(AgentStoreEvent.searchFailure, httpResponse);

          // Reject promise
          reject(httpResponse);
        });
    });
  }

  /**
   * @inheritDoc
   */
  public getSearchOutput(payload: JsonApiPayloadInterface): AgentStoreSearchOutputInterface {
    // Get JSONAPI data & meta, while warming up the store
    const sync = this.jsonApiStore.syncWithMeta(payload);

    return {
      agents: Array.isArray(sync.data) ? <AgentModel[]>sync.data : [],
      pagination: new DataStorePaginationAdapter(<JsonApiMetaPaginationInterface>sync.meta).getData(),
    };
  }

  /**
   * @inheritDoc
   */
  public replaceAll(agentModels: AgentModel[]): void {
    this.jsonApiStore.reset();

    agentModels.forEach((agentModel) => this.jsonApiStore.add(agentModel));
  }
}
