import { h } from 'preact';

import { ChipChoiceComponent } from 'library/chip-choice/component';

import { SearchBarMoreFiltersSectionTemplate } from '../section/template';
import { SearchBarMoreFiltersChipChoiceSectionTemplatePropsInterface } from './template-props.interface';

export const SearchBarMoreFiltersChipChoiceSectionTemplate = ({
  value,
  choices,
  onChange,
  ...sectionProps
}: SearchBarMoreFiltersChipChoiceSectionTemplatePropsInterface) => (
  <SearchBarMoreFiltersSectionTemplate {...sectionProps}>
    <ChipChoiceComponent
      containerClassName='more-filters__chip-choices'
      chipClassName='more-filters__chip-choice'
      name='more-filters-choices'
      options={choices.map((choice) => ({
        text: choice.label,
        value: choice.value,
      }))}
      selected={value}
      onCheck={(e, selectedOption) => {
        onChange(selectedOption.value);
      }}
    />
  </SearchBarMoreFiltersSectionTemplate>
);
