import { Component, h } from 'preact';

import { functionNoop } from 'common/helper/function/noop';
import { functionSelf } from 'common/helper/function/self';
import { TextFieldPropsInterface } from 'library/text-field/props.interface';
import { TextFieldTemplate } from 'library/text-field/template';

export class TextFieldComponent extends Component<TextFieldPropsInterface> {
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    floatPlaceholder: true,
    type: 'text',
    onInput: functionNoop,
    onValueChange: functionNoop,
    onBlur: functionNoop,
    onFocus: functionNoop,
    onBeforeChange: functionSelf,
  };

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return <TextFieldTemplate {...this.props} handleChange={this.handleChange} />;
  }

  /**
   * @param ev the input event
   * @description extrapolates value from event and triggers the onValueChange prop
   */
  private handleChange = (ev: Event) => {
    const target = ev.target as HTMLInputElement;
    let newValue: string = target.value;
    const caretPosition = target.selectionStart;

    newValue = this.props.onBeforeChange(newValue, caretPosition);
    this.props.onInput(ev);
    this.props.onValueChange(newValue);
    this.forceUpdate();
  };
}
