import { FunctionalComponent, h } from 'preact';

import { highlightString } from 'common/helper/highlight/string';

import { AutocompleteSuggestionTemplatePropsInterface } from './template-props.interface';

export const AutocompleteSuggestionTemplate: FunctionalComponent<AutocompleteSuggestionTemplatePropsInterface> = (
  props
) => (
  <div
    className={`
    autocomplete__suggestion
    ${props.isFocused ? 'autocomplete__suggestion--focused' : ''}
  `}
    ref={(el) => props.isFocused && props.onFocus && props.onFocus(el as HTMLElement)}
    onClick={(e) =>
      props.onSelectResultItem(e, {
        id: props.suggestion.id,
        sectionId: props.section.id,
        text: props.suggestion.text,
        description: props.suggestion.description,
      })
    }
  >
    <span
      dangerouslySetInnerHTML={{
        __html: highlightString(props.searchString, props.suggestion.text),
      }}
    />{' '}
    {props.suggestion.description && <span>({props.suggestion.description})</span>}
  </div>
);
