import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from 'common/module/icon/template-props.interface';

export const IconCheckTemplate: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg className={props.class} width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
    {/* tslint:disable-next-line:max-line-length */}
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M8.20711 0.305569C8.61539 -0.100009 9.27949 -0.102119 9.69041 0.300858C10.1013 0.703835 10.1035 1.3593 9.69518 1.76488L4.72844 7.69443C4.31702 8.10313 3.64669 8.10166 3.2371 7.69117L0.301551 5.05485C-0.104907 4.64749 -0.0998268 3.99204 0.312899 3.59087C0.725624 3.18969 1.3897 3.19471 1.79616 3.60207L3.98771 5.49273L8.20711 0.305569Z'
    />
  </svg>
);
