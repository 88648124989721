import { DatabaseStoreFactory } from 'common/module/database/store.factory';
import { DatabaseServiceInterface } from 'common/service/database/service.interface';

const service = DatabaseStoreFactory({
  checkBrowserSupport: true,
});

export function DatabaseService<Model>(): DatabaseServiceInterface<Model> {
  return service as DatabaseServiceInterface<Model>;
}
