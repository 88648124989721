import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { BrokerClientTypeEnum } from 'common/data/broker/client-type.enum';

export class BrokerModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'broker';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = BrokerModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof BrokerModel> = [
    'id',
    'name',
    'phone',
    'address',
    'address',
    'ranking',
    'agents',
    'license_label',
    'license_number',
    'location_name',
    'url_slug',
    'transactions_count',
    'total_properties',
    'logo_token',
    'properties_residential_for_rent_count',
    'properties_residential_for_sale_count',
    'properties_commercial_count',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof BrokerModel> = [];

  /**
   * @inheritDoc
   */
  public jsonApiLinks: string[] = ['profile', 'logo_178_98', 'logo_desktop'];

  // tslint:disable:variable-name

  /**
   * ID
   */
  public id: string;

  /**
   * Name
   */
  public name: string;

  /**
   * Phone
   */
  public phone: string;

  /**
   * Address
   */
  public address: string;

  /**
   * Ranking
   */
  public ranking: number;

  /**
   * Total number of agents
   */
  public agents: number;

  /**
   * Image token for the broker logo
   */
  public logo_token: string;

  /**
   * Additional links
   */
  public links: {
    /**
     * Profile URL
     */
    profile: string;

    /**
     * Logo small URL
     */
    logo_178_98: string;

    /**
     * Logo URL
     */
    logo_desktop: string;
  };

  /**
   * Label
   */
  public license_label: string;

  /**
   * License number
   */
  public license_number: string;

  /**
   * Location
   */
  public location_name: string;

  /**
   * Client type
   */
  public client_type: BrokerClientTypeEnum;

  /**
   * URL slug
   */
  public url_slug: string;

  /**
   * Total number of transactions in last 6 months
   */
  public transactions_count: number;

  /**
   * Total number of properties
   */
  public total_properties: number;

  /**
   * Total number of properties residential for rent
   */
  public properties_residential_for_rent_count: number;

  /**
   * Total number of properties residential for sale
   */
  public properties_residential_for_sale_count: number;

  /**
   * Total number of commercial properties
   */
  public properties_commercial_count: number;

  /**
   * Returns the public logo small size, if not defined falls back to a placeholder image
   */
  get public_logo_small(): string {
    return this.links.logo_178_98 || __('assets/common/broker-tile-placeholder.jpg');
  }

  /**
   * Returns the public logo large size, if not defined falls back to a placeholder image
   */
  get public_logo_large(): string {
    return this.links.logo_desktop || __('assets/common/broker-tile-placeholder.jpg');
  }

  /**
   * Is broker verified
   */
  public is_verified: boolean;
  // tslint:enable:variable-name
}
