import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';

import { configGetFeature } from 'common/helper/config/get-feature';
import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { stringRemoveParentheses } from 'common/helper/string/remove-parentheses';
import { IconInstallmentYearsTemplate } from 'common/module/icon';
import { IsDeveloperPropertyComponentInterface } from 'desktop/module/is-developer-property/component-props.interface';
import { IsDeveloperPropertySelectOptionInterface } from 'desktop/module/is-developer-property/select-option.interface';
import { CheckboxComponent } from 'library/checkbox/component';
import { DropdownBodyPositionEnum } from 'library/dropdown/body-position.enum';
import { DropdownComponent } from 'library/dropdown/component';
import { DropdownSizeEnum } from 'library/dropdown/size.enum';
import { DropdownListComponent } from 'library/dropdown-list/component';

export const IsDeveloperPropertyComponent = (props: IsDeveloperPropertyComponentInterface) => {
  const isRtl = configGetLanguage().isRtl;
  const [isOpenMin, setIsOpenMin] = useState(false);
  const [isOpenMax, setIsOpenMax] = useState(false);

  const defaultValueOption = { value: '', label: '' };
  const selectedOptionMin =
    props.selectFieldMinOptions.filter((option) => option.value === props.selectFieldMinValue)[0] || defaultValueOption;
  const selectedOptionMinLabel = selectedOptionMin ? (selectedOptionMin.label as string) : '';

  const selectedOptionMax =
    props.selectFieldMaxOptions.filter((option) => option.value === props.selectFieldMaxValue)[0] || defaultValueOption;
  const selectedOptionMaxLabel = selectedOptionMax ? (selectedOptionMax.label as string) : '';

  const dropDownPoisiton = isRtl ? DropdownBodyPositionEnum.bottomRight : DropdownBodyPositionEnum.bottomLeft;

  return (
    <Fragment>
      <CheckboxComponent
        checked={props.checkBoxValue}
        id='is-developer-property-checkbox'
        onChange={props.checkBoxOnChange}
      >
        {i18nTranslate('filters:is-developer-property:checkbox:label')}
      </CheckboxComponent>
      {props.checkBoxValue && configGetFeature('new_projects').installmentYearsFilterEnabled && (
        <Fragment>
          <div className='is-developer-property__section-header'>
            <IconInstallmentYearsTemplate class='is-developer-property__section-icon' />
            <h3 className='is-developer-property__section-title'>
              {i18nTranslate('filters:years-of-installment:label')}
            </h3>
          </div>
          <div className='is-developer-property__select-fields'>
            <DropdownComponent
              className='is-developer-property__select'
              position={dropDownPoisiton}
              size={DropdownSizeEnum.normal}
              text={stringRemoveParentheses(selectedOptionMinLabel)}
              label={i18nTranslate('From')}
              onChangeOpen={(open: boolean) => setIsOpenMin(open)}
              isOpen={isOpenMin}
              disabled={!props.selectFieldMinOptions.length}
            >
              <DropdownListComponent
                className='is-developer-property__select-option'
                value={selectedOptionMin}
                mapToLabel={(item) => item.label as string}
                mapToValue={(item) => item.value}
                options={props.selectFieldMinOptions}
                onChange={(selected: IsDeveloperPropertySelectOptionInterface) => {
                  setIsOpenMin(false);
                  props.selectFieldMinValueChange(selected.value);
                }}
                focused={isOpenMin}
                onESC={() => setIsOpenMin(false)}
              />
            </DropdownComponent>
            <DropdownComponent
              className='is-developer-property__select'
              position={dropDownPoisiton}
              size={DropdownSizeEnum.normal}
              text={stringRemoveParentheses(selectedOptionMaxLabel)}
              label={i18nTranslate('to')}
              onChangeOpen={(open: boolean) => setIsOpenMax(open)}
              isOpen={isOpenMax}
              disabled={!props.selectFieldMaxOptions.length}
            >
              <DropdownListComponent
                className='is-developer-property__select-option'
                value={selectedOptionMax}
                mapToLabel={(item) => item.label as string}
                mapToValue={(item) => item.value}
                options={props.selectFieldMaxOptions}
                onChange={(selected: IsDeveloperPropertySelectOptionInterface) => {
                  setIsOpenMax(false);
                  props.selectFieldMaxValueChange(selected.value);
                }}
                focused={isOpenMax}
                onESC={() => setIsOpenMax(false)}
              />
            </DropdownComponent>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};
