import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

import { FilterValueBaseInterface } from '../value-base.interface';
import { filterDefaultParamsCommon } from './common';

export const defaultFilterParamsSA: FilterValueBaseInterface = {
  ...filterDefaultParamsCommon,
  [FilterParamsEnum.categoryId]: new PropertyCategoryIdentifierAdapter().getValue(
    PropertyCategoryIdentifierEnum.residentialForSale
  ),
};
