import { BackendConfigCountryInterface } from 'common/data/backend/config/country.interface';
import { BackendConfigCountryDefault } from 'common/data/backend/config/country-default';

/**
 * Returns country specific settings
 */
export function configGetCountry(): BackendConfigCountryInterface {
  return window.propertyfinder && window.propertyfinder.countryConfig
    ? window.propertyfinder.countryConfig
    : BackendConfigCountryDefault;
}
