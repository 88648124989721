import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class UserUpdateModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'update_me';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserUpdateModel.JSONAPI_TYPE;
  public jsonApiAttributes: Array<keyof UserUpdateModel> = [
    'email',
    'first_name',
    'captcha_token',
    'last_name',
    'id',
    'image',
    'phone',
  ];

  public email: string = '';

  public first_name: string = '';

  public last_name: string = '';

  public id: string = '';

  public image: string = '';

  public phone: string = '';

  public captcha_token?: string = '';
}
