import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiPayloadInterface } from 'pf-frontend-common/src/module/json-api/payload.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { PropertyModel } from 'common/data/property/model';
import { PropertyStoreEvent } from 'common/data/property/store.event';
import { PropertyStoreSearchInputInterface } from 'common/data/property/store/search/input.interface';
import { PropertyStoreSearchOutputInterface } from 'common/data/property/store/search/output.interface';
import { PropertyStoreOptionsInterface } from 'common/data/property/store-options.interface';
import { comparatorNonValue } from 'common/helper/comparator/non-value';
import { jsonApiFlatParams } from 'common/helper/json-api/flat-params';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { DataStorePaginationAdapter } from 'common/module/data/store/pagination-adapter';
import { JsonApiMetaPaginationInterface } from 'common/module/json-api/meta/pagination.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { PropertyStoreServiceInterface } from 'common/service/property-store/service.interface';

export class PropertyStore implements PropertyStoreServiceInterface {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    search: {
      path: this.apiEndpointService.getPath('/property'),
      method: 'GET',
    },
    recommendedProperties: {
      path: this.apiEndpointService.getPath('/recommendation/property'),
      method: 'GET',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private jsonApiStore: JsonApiStore<PropertyModel>
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public initialize(options: PropertyStoreOptionsInterface): void {
    this.jsonApiStore.reset();
    // From models
    if (options.models) {
      options.models.forEach((propertyModel) => this.jsonApiStore.add(propertyModel));
    }

    // From JsonApi payload
    if (options.payload) {
      this.jsonApiStore.syncWithMeta(options.payload);
    }

    // From JsonApi payloads
    if (options.payloads) {
      options.payloads.forEach((payload) => this.jsonApiStore.syncWithMeta(payload));
    }
  }

  /**
   * @inheritDoc
   */
  public delete(propertyId: string): void {
    this.jsonApiStore.destroy(this.jsonApiStore.find(propertyId));
  }

  /**
   * @inheritDoc
   */
  public deleteAll(): void {
    this.jsonApiStore.reset();
  }

  /**
   * @inheritDoc
   */
  public getOneById(id: string): PropertyModel | null {
    return this.jsonApiStore.find(id);
  }

  /**
   * @inheritDoc
   */
  public getAll(): PropertyModel[] {
    return this.jsonApiStore.findAll();
  }

  /**
   * @inheritDoc
   */
  public search(input: PropertyStoreSearchInputInterface): Promise<PropertyStoreSearchOutputInterface> {
    // Default include (unless empty include string intentionally provided)
    if (!comparatorNonValue(input.include)) {
      input.include =
        'property_type,location_tree,property_images,agent,broker,agent.languages,project,project.developer,project_property';
    }

    // Emit event: start
    this.getEventEmitter().emit(PropertyStoreEvent.searchStart);

    return new Promise((resolve, reject) => {
      // API request
      this.apiService
        .request(
          this.endpoint.search.method,
          this.endpoint.search.path,
          objectFilterNonOrEmptyValue(jsonApiFlatParams(input))
        )
        .then((httpResponse: HttpApiResponseInterface) => {
          // Get specialized output
          const output = this.getSearchOutput(<JsonApiPayloadInterface>httpResponse.data);

          // Emit event: success
          this.getEventEmitter().emit(PropertyStoreEvent.searchSuccess, output);

          // Resolve promise
          resolve(output);
        })
        .catch((httpResponse: HttpApiResponseInterface) => {
          // Emit event: failure
          this.getEventEmitter().emit(PropertyStoreEvent.searchFailure, httpResponse);

          // Reject promise
          reject(httpResponse);
        });
    });
  }

  /**
   * @inheritDoc
   */
  public getSearchOutput(payload: JsonApiPayloadInterface): PropertyStoreSearchOutputInterface {
    this.jsonApiStore.reset();
    // Get JSONAPI data & meta, while warming up the store
    const sync = this.jsonApiStore.syncWithMeta(payload);

    return {
      properties: Array.isArray(sync.data) ? <PropertyModel[]>sync.data : [],
      pagination: new DataStorePaginationAdapter(<JsonApiMetaPaginationInterface>sync.meta).getData(),
    };
  }

  /**
   * @inheritdoc
   */
  public fetchRecommendedProperties(userId: string): Promise<PropertyModel[]> {
    return this.apiService
      .request(
        this.endpoint.recommendedProperties.method,
        this.endpoint.recommendedProperties.path,
        objectFilterNonOrEmptyValue(
          jsonApiFlatParams({
            filter: {
              sp_id: userId,
            },
          })
        )
      )
      .then(this.onFetchedRecommendedProperties);
  }

  /**
   * Fetched recommended properties
   */
  private onFetchedRecommendedProperties = (response: HttpApiResponseInterface) => {
    this.jsonApiStore.reset();
    this.jsonApiStore.sync(response.data);

    return this.getAll();
  };
}
