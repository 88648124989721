/**
 * TODO-FE[later] Harmonize the event names, start with a verb, remove passive form, and use opposite meaning
 *
 * Failed => Failure
 * Succeeded => Success
 *
 * Don't forget to update callback names AND comments
 */
export const enum UserFrontendLoginEvent {
  /**
   * Login with facebook/google failed
   */
  signInFailed = 'sign-in-failed',

  /**
   * Login with facebook/google successful
   */
  signInSucceeded = 'sign-in-succeeded',

  /**
   * Login with facebook/google stopped by closing popup window
   */
  signInStopped = 'sign-in-stopped',

  signInReady = 'sign-in-ready',
}
