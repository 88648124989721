import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class BuildingReviewModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'building_reviews';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = BuildingReviewModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof BuildingReviewModel> = [
    'ratings',
    'title',
    'text',
    'reviewer_name',
    'reviewer_email',
    'reviewer_country',
    'location_id',
    'created_at',
    'review_score',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['total_count', 'page_count', 'page'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof BuildingReviewModel> = [];

  // tslint:disable:variable-name

  /**
   * Review ratings
   */
  public ratings: {
    maintenance: number;
    staff_and_security: number;
    pool_and_gym: number;
    children_friendly: number;
    noise_in_and_around: number;
    traffic_near: number;
    guest_parking: number;
  } = {
    maintenance: 0,
    staff_and_security: 0,
    pool_and_gym: 0,
    children_friendly: 0,
    noise_in_and_around: 0,
    traffic_near: 0,
    guest_parking: 0,
  };

  /**
   * Location
   */
  public location_id: string = '';

  /**
   * Review title
   */
  public title: string = '';

  /**
   * Review text
   */
  public text: string = '';

  /**
   * Review author name
   */
  public reviewer_name: string = '';

  /**
   * Review author email
   */
  public reviewer_email: string = '';

  /**
   * Review author country
   */
  public reviewer_country: string = '';

  /**
   * Created at
   */
  public created_at: string = '';

  /**
   * Average review score
   */
  public review_score: number;

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Total number of reviews
     */
    total_count: number;

    /**
     * Page count
     */
    page_count: number;

    /**
     * Page number
     */
    page: number;
  };

  // tslint:enable:variable-name
}
