import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { UserFrontendLoginEvent } from 'common/data/user/frontend/login.event';
import { UserFrontendLoginProvidersEnum } from 'common/data/user/frontend/login/providers.enum';
import { UserFrontendLoginProviderInterface } from 'common/data/user/frontend/login-provider.interface';
import { UserFrontendLoginServiceInterface } from 'common/service/user-frontend-login/service.interface';

import { UserFrontendLoginProviderTypeEnum } from '../login-provider/type.enum';
import { UserFrontendLoginGoogleErrorEnum } from './google-error.enum';
import { UserFrontendLoginGoogleErrorInterface } from './google-error.interface';

export class UserFrontendLoginStore implements UserFrontendLoginServiceInterface {
  /**
   * List of registered login providers
   */
  private registeredProviders: Partial<Record<UserFrontendLoginProvidersEnum, UserFrontendLoginProviderInterface>> = {};

  /**
   * Constructor
   */
  constructor(private eventEmitter: EventEmitterInterface) {}

  /**
   * @inheritdoc
   */
  public initialize(): void {
    Object.keys(this.registeredProviders).forEach((provider: UserFrontendLoginProvidersEnum) => {
      if (this.registeredProviders[provider].initialization === UserFrontendLoginProviderTypeEnum.onInit) {
        this.initializeProvider(provider);
      }
    });
  }

  /**
   * @inheritdoc
   */
  public registerProviders(
    providers: Partial<Record<UserFrontendLoginProvidersEnum, UserFrontendLoginProviderInterface>>
  ): void {
    this.registeredProviders = providers;
  }

  /**
   * @inheritDoc
   */
  public signIn(provider: UserFrontendLoginProvidersEnum): void {
    if (this.registeredProviders[provider].initialization === UserFrontendLoginProviderTypeEnum.manual) {
      this.initializeProvider(provider);
    }
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  public emitLoginEvent(event: UserFrontendLoginEvent, ...args: Array<object | number | string | boolean>): void {
    this.getEventEmitter().emit(event, ...args);
  }

  /**
   * Initialize provider
   */
  private initializeProvider(provider: UserFrontendLoginProvidersEnum): void {
    this.registeredProviders[provider]
      .signIn()
      .then((user) => {
        this.emitLoginEvent(UserFrontendLoginEvent.signInSucceeded, user, provider);
      })
      .catch((e: UserFrontendLoginGoogleErrorInterface) => {
        if (!e || e?.error === UserFrontendLoginGoogleErrorEnum.POPUP_CLOSE) {
          this.emitLoginEvent(UserFrontendLoginEvent.signInStopped);
          return;
        }
        this.emitLoginEvent(UserFrontendLoginEvent.signInFailed, e);
      });
  }
}
