import { StatsContexterService } from 'pf-frontend-common/src/service/stats-contexter/service';

import { configGetAbTests } from 'common/helper/config/get-ab-tests';
import { AbTestStore } from 'common/module/ab-test/store';
import { StatsService } from 'common/service/stats/service';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';

const service = new AbTestStore(
  StatsService(), // Ensure StatsService is initialized
  configGetAbTests(),
  StatsGtmProviderService(),
  StatsContexterService()
);

export const AbTestService = () => service;
