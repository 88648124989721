import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { BrowserStorageLocalServiceInterface } from 'pf-frontend-common/src/service/browser-storage-local/service.interface';
import { WindowServiceInterface } from 'pf-frontend-common/src/service/window/service.interface';

import { ApplicationFeatureServiceInterface } from 'common/service/application-feature/service.interface';

import { ApplicationFeatureEnum } from './feature.enum';
import { ApplicationFeatureEvent } from './feature.event';

// eslint-disable-next-line @propertyfinder/rules/export-name-validation
export class ApplicationFeature implements ApplicationFeatureServiceInterface {
  /**
   * Local storage key
   */
  public static readonly STORAGE_KEY: string = 'pf-application-feature';

  /**
   * List of active features
   */
  private activeFeature: ApplicationFeatureEnum[] = [];

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private browserStorageLocalService: BrowserStorageLocalServiceInterface,
    private windowService: WindowServiceInterface
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(): void {
    // Set list of active features from local storage
    const activeFeature = this.browserStorageLocalService.getData(ApplicationFeature.STORAGE_KEY);
    this.activeFeature = Array.isArray(activeFeature) ? activeFeature : [];

    // Get global propertyfinder namespace
    const propertyfinder = this.windowService.getNative().propertyfinder;

    if (propertyfinder) {
      // Expose toggle function
      propertyfinder.feature = this.toggle.bind(this);
    }
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public toggle(name: string): void {
    // Invalid feature name
    if (name in ApplicationFeatureEnum === false) {
      return;
    }

    const feature = <ApplicationFeatureEnum>name;

    // Deactivate/activate
    this.isActive(feature) ? this.deactivate(feature) : this.activate(feature);

    // Emit event
    this.getEventEmitter().emit(ApplicationFeatureEvent.toggle, feature, this.isActive(feature));
  }

  /**
   * @inheritDoc
   */
  public isActive(feature: ApplicationFeatureEnum): boolean {
    return this.activeFeature.indexOf(feature) > -1;
  }

  /**
   * @inheritDoc
   */
  public activate(feature: ApplicationFeatureEnum): void {
    // Already active
    if (this.isActive(feature) === true) {
      return;
    }

    // Add to list of active features
    this.activeFeature.push(feature);

    // Store data
    this.store();

    // Emit events
    this.getEventEmitter().emit(ApplicationFeatureEvent.activate, feature);

    // Log confirmation message
    // eslint-disable-next-line no-console, @propertyfinder/rules/console-first-argument-string
    console.info('Feature [' + feature + '] is now [ activated ]');
  }

  /**
   * @inheritDoc
   */
  public deactivate(feature: ApplicationFeatureEnum): void {
    // Not active
    if (this.isActive(feature) === false) {
      return;
    }

    // Remove from list of active features
    this.activeFeature.splice(this.activeFeature.indexOf(feature), 1);

    // Store data
    this.store();

    // Emit event
    this.getEventEmitter().emit(ApplicationFeatureEvent.deactivate, feature);

    // Log confirmation message
    // eslint-disable-next-line no-console, @propertyfinder/rules/console-first-argument-string
    console.info('Feature [' + feature + '] is now [ deactivated ]');
  }

  /**
   * Store active features in local store
   */
  private store(): void {
    // Store in local storage
    this.browserStorageLocalService.setData(ApplicationFeature.STORAGE_KEY, this.activeFeature);
  }
}
