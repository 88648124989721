import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from './template-props.interface';

export const IconThickPropertySize: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg className={props.class} viewBox='0 0 20 18'>
    <path d='M15 8H9C8.44772 8 8 8.44772 8 9V15C8 15.5523 8.44772 16 9 16H15C15.5523 16 16 15.5523 16 15H18C18 16.6569 16.6569 18 15 18H9C7.34315 18 6 16.6569 6 15V9C6 7.34315 7.34315 6 9 6H15C16.6569 6 18 7.34315 18 9H16C16 8.44772 15.5523 8 15 8Z' />
    <path d='M18 13V11H16V13H18Z' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.2197 0.21967C15.5126 -0.0732233 15.9874 -0.0732233 16.2803 0.21967L18.2803 2.21967C18.5732 2.51256 18.5732 2.98744 18.2803 3.28033L16.2803 5.28033C15.9874 5.57322 15.5126 5.57322 15.2197 5.28033C14.9268 4.98744 14.9268 4.51256 15.2197 4.21967L15.9393 3.5H7.56066L8.28033 4.21967C8.57322 4.51256 8.57322 4.98744 8.28033 5.28033C7.98744 5.57322 7.51256 5.57322 7.21967 5.28033L5.21967 3.28033C4.92678 2.98744 4.92678 2.51256 5.21967 2.21967L7.21967 0.21967C7.51256 -0.0732231 7.98744 -0.0732231 8.28033 0.21967C8.57322 0.512563 8.57322 0.987437 8.28033 1.28033L7.56066 2H15.9393L15.2197 1.28033C14.9268 0.987437 14.9268 0.512563 15.2197 0.21967Z'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0.21967 8.28033C-0.0732233 7.98744 -0.0732233 7.51256 0.21967 7.21967L2.21967 5.21967C2.51256 4.92678 2.98744 4.92678 3.28033 5.21967L5.28033 7.21967C5.57322 7.51256 5.57322 7.98744 5.28033 8.28033C4.98744 8.57322 4.51256 8.57322 4.21967 8.28033L3.5 7.56066L3.5 15.9393L4.21967 15.2197C4.51256 14.9268 4.98744 14.9268 5.28033 15.2197C5.57322 15.5126 5.57322 15.9874 5.28033 16.2803L3.28033 18.2803C2.98744 18.5732 2.51256 18.5732 2.21967 18.2803L0.21967 16.2803C-0.0732228 15.9874 -0.0732228 15.5126 0.21967 15.2197C0.512564 14.9268 0.987437 14.9268 1.28033 15.2197L2 15.9393L2 7.56066L1.28033 8.28033C0.987437 8.57322 0.512563 8.57322 0.21967 8.28033Z'
    />
  </svg>
);
