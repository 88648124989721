import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class ChangePasswordModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'change_password';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = ChangePasswordModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof ChangePasswordModel> = [];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof ChangePasswordModel> = ['user'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['token', 'refresh_token'];

  /**
   * User
   */
  public user: UserModel;

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Authorization token
     */
    token: string;

    /**
     * Refresh token
     */
    refresh_token: string;
  };
}
