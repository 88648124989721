import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

import { UserModel } from 'common/data/user/model';

export class UserEmailVerificationModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'email-verification';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserEmailVerificationModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserEmailVerificationModel> = ['email', 'code'];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof UserEmailVerificationModel> = ['user'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = ['token', 'refresh_token'];

  public email: string = '';

  public code: string = '';

  public user: UserModel;

  public meta: {
    token: string;

    refresh_token: string;
  };
}
