/**
 * Set cookie api request model
 */
export class CookieAuthenticatorSetCookieApiRequestModel {
  public data: {
    type: string;
    id: number;
    attributes: {
      token: string;
    };
  };
  constructor(token: string) {
    this.data = {
      type: 'validation_cookie',
      id: 0,
      attributes: {
        token,
      },
    };
  }
}
