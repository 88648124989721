import { h } from 'preact';

import { functionSelf } from 'common/helper/function/self';
import { i18nTranslate } from 'common/helper/i18n/translate';

import { MultiSelectionAutocompleteComponent } from '../multi-selection-autocomplete/component';
import { KeywordsComponentPropsInterface } from './component-props.interface';
import { keywordsMakeQueryForInputValue } from './make-query-for-input-value';
import { keywordsOnInputKeyPress } from './on-input-key-press';
import { keywordsTryAddItem } from './try-add-item';

const separator = ',';

// TODO-FE[TPNX-2625] add unit tests
export const KeywordsComponent = ({
  onChange,
  value,
  category,
  chipsOnTheBottom,
  className,
  chipClassName,
}: KeywordsComponentPropsInterface) => {
  const keywords = value.split(separator).filter(functionSelf);
  const keywordsQueryForInputValue = keywordsMakeQueryForInputValue(category);

  return (
    <MultiSelectionAutocompleteComponent
      className={className}
      chipClassName={chipClassName}
      chipsOnTheBottom={chipsOnTheBottom}
      value={keywords}
      getTitle={(keyword) => keyword}
      getChipTitle={(keyword) => keyword}
      placeholder={i18nTranslate('Keywords')}
      onInputChange={keywordsQueryForInputValue}
      onFocus={keywordsQueryForInputValue}
      onAddItem={(keyword) => {
        const trimmedWord = keyword.trim();
        const regex = new RegExp(trimmedWord, 'i');
        if (!keywords.some((word) => regex.test(word) && word.length === trimmedWord.length)) {
          onChange([...keywords, keyword].join(separator));
        }
      }}
      onItemRemove={(keyword) => onChange(keywords.filter((k) => keyword !== k).join(separator))}
      onItemGroupRemoveClick={() => onChange(keywords[0])}
      renderNoSuggestions={i18nTranslate('keywords-no-suggestions-message-new')}
      onInputKeyPress={keywordsOnInputKeyPress}
      onWindowMouseDownOutsideAutocomplete={keywordsTryAddItem}
    />
  );
};
