import { ApiService } from '@propertyfinder/pf-frontend-common/src/service/api/service';

import { JsonApiStoreFactory } from 'common/module/json-api/store.factory';
import { ApiEndpointService } from 'common/service/api-endpoint/service';

import { UserFrontendLoginModel } from '../user/frontend/login/model';
import { LoginSocialStore } from './store';

export const LoginSocialStoreFactory = () =>
  new LoginSocialStore(
    ApiService(),
    ApiEndpointService(),
    JsonApiStoreFactory<UserFrontendLoginModel>(UserFrontendLoginModel.JSONAPI_TYPE)
  );
