import { BrowserStorageLocalService } from 'pf-frontend-common/src/service/browser-storage-local/service';

import { AutocompleteCacheLocationsStore } from 'common/data/autocomplete/cache/locations/store';
import { AutocompleteCacheWorkerFactory } from 'common/data/autocomplete/cache/worker.factory';
import { LocationStoreFactory } from 'common/data/location/store.factory';
import { configGetLanguage } from 'common/helper/config/get-language';
import { ApiEndpointService } from 'common/service/api-endpoint/service';
import { DatabaseService } from 'common/service/database/service';

const service = new AutocompleteCacheLocationsStore(
  BrowserStorageLocalService(),
  configGetLanguage(),
  ApiEndpointService(),
  DatabaseService(),
  LocationStoreFactory(),
  AutocompleteCacheWorkerFactory(),
  window.location.origin
);

export function AutocompleteCacheLocationsService(): AutocompleteCacheLocationsStore {
  return service;
}
