import { DataKeyValueFlatInterface } from 'common/module/data/key-value/flat.interface';

/**
 * Recursively converts nested params into a flat object for JSON API usage
 *
 * @param params - Params to make flat
 * @param keys - List of parent keys (or keys tree) to reach params value
 */
export function jsonApiFlatParams(
  params: { [key: string]: any } | DataKeyValueFlatInterface,
  keys: string[] = []
): DataKeyValueFlatInterface {
  let flat: DataKeyValueFlatInterface = {};

  // For each params key
  Object.keys(params).forEach((key) => {
    // Add key to key tree
    const keyTree = keys.slice(0);
    keyTree.push(key);

    // Not array and is an object
    if (Array.isArray(params[key]) === false && params[key] instanceof Object) {
      // Recursive call to flatten params[key] while keeping key tree
      const flatParams = jsonApiFlatParams(<{ [key: string]: object }>params[key], keyTree);

      flat = {
        ...flat,
        ...flatParams,
      };

      // Array or single value
    } else {
      // Generate a string like "page[category][type]", the first key is never between brackets
      const keyFlatten = keyTree.reduce((accumulator, value, i) => (i === 0 ? value : accumulator + '[' + value + ']'));

      // Define flat key/value pair
      flat[keyFlatten] = <string | string[] | number | number[] | boolean | boolean[]>params[key];
    }
  });

  return flat;
}
