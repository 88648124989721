import { ScrollableAreaComponent } from '@propertyfinder/scrollable-area';
import { Fragment, FunctionComponent, h } from 'preact';
import { useRef, useState } from 'preact/hooks';

import { configGetCountry } from 'common/helper/config/get-country';
import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { NavigationArrowsComponent } from 'common/module/navigation-arrows/component';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { TagNewComponent } from 'common/module/tag-new/component';
import { TagNewTypeEnum } from 'common/module/tag-new/type.enum';

import { StatsTealiumDataLayerEventActionEnum } from '../stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from '../stats/tealium/data-layer/tealium-event.enum';

export const HomePageHighlightedFeaturesComponentFactory =
  ({
    statsTealium,
  }: {
    statsTealium: StatsTealiumProviderInterface;
  }): FunctionComponent<{ arrowPosition: 'title' | 'subtitle' }> =>
  ({ arrowPosition }) => {
    const language = configGetLanguage().current;
    const nextItemRef = useRef(null);
    const prevItemRef = useRef(null);
    const [currentGaleryIndex, setCurrentGaleryIndex] = useState(0);
    const [items] = useState(() => [
      <article key='area-insights' className='highlighted-features__card highlighted-features__card--data-guru'>
        <div className='highlighted-features__card-image-container'>
          <div className='highlighted-features__card-tags'>
            <TagNewComponent type={TagNewTypeEnum.DATA_GURU} />
          </div>
          <img
            className='highlighted-features__card-image highlighted-features__card-image--data-guru'
            src={`${__('assets/common/data-guru-illustration.svg')}`}
          />
        </div>
        <div className='highlighted-features__card-body'>
          <h4 className='highlighted-features__card-title'>{i18nTranslate('DataGuru_HomePageBannerTitle')}</h4>
          <p className='highlighted-features__card-description'>
            {i18nTranslate('DataGuru_HomePageBannerDescription')}
          </p>
        </div>
        <a
          href={`/${language}/area-insights/explore/dubai`}
          className='highlighted-features__card-link'
          data-testid='data-guru-banner-link'
          onClick={() =>
            statsTealium.send({
              sendToGa: true,
              tealium_event: StatsTealiumDataLayerTealiumEventEnum.dataGuruCardClick,
              event_action: StatsTealiumDataLayerEventActionEnum.dataGuruCardClick,
              event_category: StatsTealiumDataLayerEventCategoryEnum.bannerClick,
              event_label: 'explore_uae_with_dataguru',
              page_country: configGetCountry().code,
              page_lang: language,
            })
          }
        />
      </article>,
      <article key='super-agent' className='highlighted-features__card highlighted-features__card--super-agent'>
        <div className='highlighted-features__card-image-container'>
          <div className='highlighted-features__card-tags'>
            <TagNewComponent type={TagNewTypeEnum.SUPER_AGENT} />
          </div>
          <img
            className='highlighted-features__card-image'
            src={`${__('assets/common/superagent_illustration.svg')}`}
          />
        </div>
        <div className='highlighted-features__card-body'>
          <h4 className='highlighted-features__card-title'>
            {i18nTranslate('home-page-features-gallery:superagent-title')}
          </h4>
          <p className='highlighted-features__card-description'>
            {i18nTranslate('home-page-features-gallery:superagent')}
          </p>
        </div>
        <a
          href={`/${language}/find-agent`}
          className='highlighted-features__card-link'
          onClick={() =>
            statsTealium.send({
              sendToGa: true,
              event_category: StatsTealiumDataLayerEventCategoryEnum.productHelp,
              event_action: 'super_agent_click',
              event_label: '',
            })
          }
        />
      </article>,
      <article key='verified' className='highlighted-features__card highlighted-features__card--verified'>
        <div className='highlighted-features__card-image-container'>
          <div className='highlighted-features__card-tags'>
            <TagNewComponent type={TagNewTypeEnum.VERIFIED} />
          </div>
          <img
            className='highlighted-features__card-image'
            src={`${__('assets/common/verified_listings_illustration.svg')}`}
          />
        </div>
        <div className='highlighted-features__card-body'>
          <h4 className='highlighted-features__card-title'>{i18nTranslate('Verified Listings')}</h4>
          <p className='highlighted-features__card-description'>
            {i18nTranslate('home-page-features-gallery:verified')}
          </p>
        </div>
        <a
          href={`/${language}/search?v=1`}
          className='highlighted-features__card-link'
          onClick={() =>
            statsTealium.send({
              sendToGa: true,
              event_category: StatsTealiumDataLayerEventCategoryEnum.productHelp,
              event_action: 'verified_listings_click',
              event_label: '',
            })
          }
        />
      </article>,
    ]);

    const languageRtl = configGetLanguage().isRtl;

    const Arrows = () => (
      <NavigationArrowsComponent
        isRtl={configGetLanguage().isRtl}
        currentGaleryIndex={currentGaleryIndex}
        numberOfItems={items.length}
        nextRef={nextItemRef}
        prevRef={prevItemRef}
      />
    );

    return (
      <Fragment>
        <h3 className='home-page-layout__section-title'>
          {i18nTranslate('home-page-features-gallery:title')} {arrowPosition === 'title' && <Arrows />}
        </h3>
        <p className='home-page-layout__section-subtitle'>
          {i18nTranslate('home-page-features-gallery:subtitle')} {arrowPosition === 'subtitle' && <Arrows />}
        </p>
        <div className='home-page-layout__section-content'>
          <ScrollableAreaComponent
            bindNextItem={(nextItem): void => {
              nextItemRef.current = nextItem;
            }}
            bindPrevItem={(prevItem): void => {
              prevItemRef.current = prevItem;
            }}
            className='home-page-layout__scrollable-area'
            itemClassName='home-page-layout__scrollable-card'
            rtl={languageRtl}
            onChange={(e): void => setCurrentGaleryIndex(e)}
          >
            {items.map((card) => card)}
          </ScrollableAreaComponent>
        </div>
      </Fragment>
    );
  };
