import { DataKeyValueStringInterface } from 'pf-frontend-common/dist/module/data/key-value/string.interface';
import { h } from 'preact';

import { preactHtmlAttributesToProps } from 'common/helper/preact/html/attributes-to-props';
import { PreactHtmlToVdomPropsInterface } from 'common/helper/preact/html/to-vdom.props.interface';
import { stringKebabToCamelCase } from 'common/helper/string/kebab-to-camel-case';

/**
 * Transforms html element into virtual dom
 */
export function preactHtmlToVdom(props: PreactHtmlToVdomPropsInterface): preact.JSX.Element | string | null {
  // if Text then return it
  if (props.element.nodeType === 3) {
    const nodeValue = props.element.nodeValue;

    const text = 'textContent' in props.element ? props.element.textContent : nodeValue || '';

    return text;
  }

  // If not html element then return null
  if (props.element.nodeType !== 1) {
    return null;
  }

  const nodeName = String(props.element.nodeName).toLowerCase();
  const componentProps = preactHtmlAttributesToProps(props.element.attributes);

  // Transform children to react components
  let children = Array.from(props.element.childNodes).map((child) =>
    preactHtmlToVdom({
      element: child as HTMLElement,
      components: props.components,
      componentNameAttribute: props.componentNameAttribute,
    })
  );

  // If we don't have component name in component props
  if (!componentProps || !componentProps[props.componentNameAttribute]) {
    return h(nodeName, componentProps, children);
  }

  const componentName = componentProps[props.componentNameAttribute] as string;
  const component = props.components[componentName];

  // if we don't have comopnent registered
  if (!component) {
    return h(nodeName, componentProps, children);
  }

  children = [
    h<{}>(
      props.components[componentName],
      Object.keys(componentProps).reduce((attrs, attrName) => {
        attrs[stringKebabToCamelCase(attrName)] = componentProps[attrName] as string;
        return attrs;
      }, {} as DataKeyValueStringInterface),
      children
    ),
  ];

  return h(nodeName, componentProps, children);
}
