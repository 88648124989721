import { Snowplow2StatsSchemaLayoutContextInterface } from 'pf-frontend-common/src/module/snowplow2/stats/schema/layout-context.interface';

export const PropertyPageStatsSnowplow2LayoutContext: Snowplow2StatsSchemaLayoutContextInterface = {
  schema: 'iglu:ae.propertyfinder/layout_context/jsonschema/1-0-1',
  data: {
    block: '',
    layout_version: '1',
    page_type: 'property_detail',
  },
};
