import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from './template-props.interface';

export const IconPlayCircle: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg className={props.class} viewBox='1 0 22 22'>
    <path
      fill='currentColor'
      fillRule='evenodd'
      d='M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 1c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm-2 4.5l6 4.5-6 4.5v-9z'
    />
  </svg>
);
