export const enum AutocompleteCacheWorkerEvent {
  /**
   * Fetch autocomplete cache from backend
   */
  fetchCache = 'fetchCache',

  /**
   * Fetch checksum value from api
   * TODO-FE[later] - move checksum to propertyfinder global object
   */
  fetchChecksum = 'fetchChecksum',

  /**
   * Open idb to update itself with values from cache
   */
  openDatabaseToUpdateItself = 'openDatabaseToUpdateItself',

  /**
   * Open idb to update cache with values from database
   */
  openDatabaseToUpdateCache = 'openDatabaseToUpdateCache',

  /**
   * Autocomplete cache fetched
   */
  cacheFetched = 'cacheFetched',

  /**
   * Autocomplete checksum fetched
   */
  checksumFetched = 'checksumFetched',

  /**
   * Database updated itself
   */
  databaseUpdatedItself = 'databaseUpdatedItself',

  /**
   * Add json api model to data store
   */
  addToDataStore = 'addToDataStore',

  /**
   * Sync data store with data from indexedDB
   */
  syncDataStore = 'syncDataStore',
}
