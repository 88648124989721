import * as idb from 'idb';

import { DatabaseServiceInterface } from 'common/service/database/service.interface';

import { DatabaseStore } from './store';

export function DatabaseStoreFactory<Model>(options?: {
  checkBrowserSupport: boolean;
}): DatabaseServiceInterface<Model> {
  const databaseStore = new DatabaseStore(idb);

  if (options?.checkBrowserSupport) {
    databaseStore.initialize();
  }

  return databaseStore as DatabaseServiceInterface<Model>;
}
