export enum FilterChoicesIsDeveloperPropertyEnum {
  /**
   * is developer property filter active
   */
  active = '1',

  /**
   * is developer property filter passive
   */
  passive = '',
}
