import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { AutocompleteCacheLocationsStore } from 'common/data/autocomplete/cache/locations/store';
import { LocationModel } from 'common/data/location/model';
import { arrayFind } from 'common/helper/array/find';
import { objectCompare } from 'common/helper/object/compare';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { DataStore } from 'common/module/data/store';
import { FilterActionEvent } from 'common/module/filter/action.event';
import { FilterAdapter } from 'common/module/filter/adapter';
import { getFilterDefaultParams } from 'common/module/filter/get-filter-default-params';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { filterParamsEquality } from 'common/module/filter/params-equality';
import { FilterSettingsStore } from 'common/module/filter/settings/store';
import { LocationDatabaseServiceInterface } from 'common/service/location-database/service.interface';

import { FilterParametersFieldChoiceInterface } from './parameters/field-choice.interface';
import { FilterParametersBaseInterface } from './parameters-base.interface';
import { FilterServerInterface } from './server.interface';
export class FilterStore extends DataStore<FilterParametersInterface> {
  /**
   * Default validated settings
   */
  public defaultSettingsByCategoryPromise: Promise<Record<string, FilterParametersInterface>> = new Promise(
    (resolve) => {
      this.resolveDefaultSettingsByCategory = resolve;
    }
  );

  private resolveDefaultSettingsByCategory: (settings: Record<string, FilterParametersInterface>) => void;

  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private filterSettingsStore: FilterSettingsStore,
    private locationDatabaseService: LocationDatabaseServiceInterface,
    private autocomlpeteCacheLocationDatabaseService: AutocompleteCacheLocationsStore
  ) {
    super(eventEmitter);
    const defaultParamValues = getFilterDefaultParams();
    this.state = Object.keys(defaultParamValues).reduce(
      (acc, key: keyof FilterParametersBaseInterface) => ({
        ...acc,
        [key]: {
          value: defaultParamValues[key],
          choices: [],
        },
      }),
      {}
    );
  }

  /**
   * @inheritDoc
   */
  public async initialize(serverFilter?: FilterServerInterface): Promise<void> {
    if (serverFilter) {
      this.setState(
        this.transformOptions({
          ...serverFilter,
        })
      );
    }

    await this.filterSettingsStore.initialize();

    const defaultFilterSettings = await this.filterSettingsStore.validate({});
    await this.initDefaultSettings(defaultFilterSettings[FilterParamsEnum.categoryId].choices);

    if (serverFilter) {
      await this.update(this.state);
    } else {
      this.setState({ ...defaultFilterSettings });
    }
  }

  /**
   * Reset filters to supplied values, or defaults
   */
  public reset = (filters?: FilterParametersInterface): Promise<void> => {
    return this.filterSettingsStore
      .validate({
        [FilterParamsEnum.categoryId]:
          filters?.[FilterParamsEnum.categoryId] || this.state[FilterParamsEnum.categoryId],
        [FilterParamsEnum.sort]: filters?.[FilterParamsEnum.sort] || this.state[FilterParamsEnum.sort],
        ...filters,
      })
      .then(this.onValidated);
  };

  /**
   * Update filter parameters
   */
  public update(filters: FilterParametersInterface): Promise<void> {
    return this.filterSettingsStore
      .validate({
        ...this.state,
        ...filters,
      })
      .then(this.onValidated);
  }

  /**
   * Sync state from other store. Skips the validation.
   * We do not need to perform validation again in case it was already validated in another store.
   */
  public syncWithoutValidation(filters: FilterParametersInterface): void {
    this.onValidated(filters);
  }

  /**
   * Get locations from the cache(indexDB) by id
   */
  public getLocations(): Promise<LocationModel[]> {
    if (!this.state[FilterParamsEnum.locationsIds]?.value?.length) {
      return Promise.resolve([]);
    }

    const allLocationsFromCache = this.autocomlpeteCacheLocationDatabaseService.getCache();

    return allLocationsFromCache.length
      ? Promise.resolve(this.validateLocations(allLocationsFromCache))
      : this.locationDatabaseService.getAll().then(this.validateLocations);
  }

  /**
   * Initialize default settings by category
   */
  private initDefaultSettings(categoryChoises: Array<FilterParametersFieldChoiceInterface<string>>): Promise<void> {
    return Promise.all(
      categoryChoises.map(({ value }) => {
        return this.filterSettingsStore.validate({
          [FilterParamsEnum.categoryId]: {
            value,
            choices: categoryChoises,
          },
        });
      })
    ).then((values) => {
      const defaultValuesByCategory = values.reduce((acc, settings) => {
        acc[settings[FilterParamsEnum.categoryId].value] = settings;
        return acc;
      }, {} as Record<string, FilterParametersInterface>);

      this.resolveDefaultSettingsByCategory(defaultValuesByCategory);
    });
  }

  /**
   * Filters validated handler
   */
  private onValidated = (filterParams: FilterParametersInterface) => {
    if (!filterParamsEquality(filterParams, this.state)) {
      const prevState = { ...this.state };

      this.setState(filterParams);

      if (
        !objectCompare(
          prevState[FilterParamsEnum.locationsIds]?.value,
          filterParams[FilterParamsEnum.locationsIds]?.value
        )
      ) {
        // Notify all listeners about updating the location
        this.getEventEmitter().emit(FilterActionEvent.locationUpdated);
      }
    }
  };

  /**
   * Return locations for locationIds in state, if they are exist in locations db
   */
  private validateLocations = (locations: LocationModel[]): LocationModel[] => {
    return this.state[FilterParamsEnum.locationsIds].value.reduce((result, id) => {
      const location = arrayFind(locations, (databaseLocation) => databaseLocation.id === id);

      if (location) {
        result.push(location);
      }

      return result;
    }, []);
  };

  /**
   * Transforms supplied options to state
   */
  private transformOptions = (form: FilterServerInterface): FilterParametersInterface => {
    return objectFilterNonOrEmptyValue(FilterAdapter.fromOptionsToParams(form));
  };
}
