import { BackendConfigSettingsInterface } from 'common/data/backend/config/settings.interface';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { stringToNumber } from 'common/helper/string/to-number';
import { filterChoiceAdapter } from 'common/module/filter/choice-adapter';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { FilterParametersFieldInterface } from 'common/module/filter/parameters/field.interface';
import { FilterParametersFieldOptionsInterface } from 'common/module/filter/parameters/field-options.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { FilterSearchUrlParamsEnum } from 'common/module/filter/search/url/params.enum';
import { FilterServerInterface } from 'common/module/filter/server.interface';
import { FilterValueDeprecatedInterface } from 'common/module/filter/value-deprecated.interface';

import { getFilterDefaultParams } from './get-filter-default-params';

const filterSearchUrlParamsEnumValues = Object.values(FilterSearchUrlParamsEnum).reduce(
  (result: Record<string, number>, current, index) => {
    result[current] = index;

    return result;
  },
  {}
);

const filterSearchUrlParamsEnumKeys = Object.keys(FilterSearchUrlParamsEnum);

type FilterWithoutChoices = FilterParametersFieldInterface<string[]>;
type FilterWithChoices = FilterParametersFieldOptionsInterface<string | number | string[], string | number>;

export class FilterAdapter {
  /**
   * Transform the output options to parameters
   */
  public static fromOptionsToParams(options: FilterServerInterface): FilterParametersInterface {
    return Object.keys(options).reduce(
      (result: Record<string, FilterWithChoices | FilterWithoutChoices>, current: FilterSearchUrlParamsEnum) => {
        const key =
          FilterParamsEnum[
            filterSearchUrlParamsEnumKeys[filterSearchUrlParamsEnumValues[current]] as keyof typeof FilterParamsEnum
          ];
        const content: Partial<FilterWithChoices | FilterWithoutChoices> = {};

        if (current === FilterSearchUrlParamsEnum.locationsIds) {
          (content as FilterWithoutChoices).value = options[current].value?.split('-').filter((val) => val);

          result[key] = content as FilterWithoutChoices;
        } else {
          content.value = options[current].value;

          if ((options[current] as FilterWithChoices).choices) {
            (content as FilterWithChoices).choices = filterChoiceAdapter(
              (options[current] as FilterWithChoices).choices
            );
          }

          result[key] = content as FilterWithChoices;
        }

        return result;
      },
      {}
    ) as FilterParametersInterface;
  }

  /**
   * Transform the parameters to server options
   */
  public static fromParamsToOptions(params: FilterParametersInterface): FilterServerInterface {
    return {
      [FilterSearchUrlParamsEnum.query]: {
        value: params?.[FilterParamsEnum.query]?.value || getFilterDefaultParams()[FilterParamsEnum.query],
      },
      [FilterSearchUrlParamsEnum.locationsIds]: {
        value: params?.[FilterParamsEnum.locationsIds]?.value
          ? params[FilterParamsEnum.locationsIds]?.value?.join('-')
          : '',
      },
      [FilterSearchUrlParamsEnum.categoryId]: {
        value: params?.[FilterParamsEnum.categoryId]?.value || getFilterDefaultParams()[FilterParamsEnum.categoryId],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.categoryId]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.propertyTypeId]: {
        value:
          params?.[FilterParamsEnum.propertyTypeId]?.value || getFilterDefaultParams()[FilterParamsEnum.propertyTypeId],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.propertyTypeId]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.minPrice]: {
        value: params?.[FilterParamsEnum.minPrice]?.value || getFilterDefaultParams()[FilterParamsEnum.minPrice],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.minPrice]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.maxPrice]: {
        value: params?.[FilterParamsEnum.maxPrice]?.value || getFilterDefaultParams()[FilterParamsEnum.maxPrice],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.maxPrice]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.furnishing]: {
        value: params?.[FilterParamsEnum.furnishing]?.value || getFilterDefaultParams()[FilterParamsEnum.furnishing],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.furnishing]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.minArea]: {
        value: params?.[FilterParamsEnum.minArea]?.value || getFilterDefaultParams()[FilterParamsEnum.minArea],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.minArea]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.maxArea]: {
        value: params?.[FilterParamsEnum.maxArea]?.value || getFilterDefaultParams()[FilterParamsEnum.maxArea],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.maxArea]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.pricePeriod]: {
        value: params?.[FilterParamsEnum.pricePeriod]?.value || getFilterDefaultParams()[FilterParamsEnum.pricePeriod],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.pricePeriod]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.keyword]: {
        value: params?.[FilterParamsEnum.keyword]?.value || getFilterDefaultParams()[FilterParamsEnum.keyword],
      },
      [FilterSearchUrlParamsEnum.amenities]: {
        value: params?.[FilterParamsEnum.amenities]?.value || getFilterDefaultParams()[FilterParamsEnum.amenities],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.amenities]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.bedrooms]: {
        value: params?.[FilterParamsEnum.bedrooms]?.value || getFilterDefaultParams()[FilterParamsEnum.bedrooms],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.bedrooms]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.bathrooms]: {
        value: params?.[FilterParamsEnum.bathrooms]?.value || getFilterDefaultParams()[FilterParamsEnum.bathrooms],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.bathrooms]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.completionStatus]: {
        value:
          params?.[FilterParamsEnum.completionStatus]?.value ||
          getFilterDefaultParams()[FilterParamsEnum.completionStatus],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.completionStatus]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.utilitiesPriceType]: {
        value:
          params?.[FilterParamsEnum.utilitiesPriceType]?.value ||
          getFilterDefaultParams()[FilterParamsEnum.utilitiesPriceType],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.utilitiesPriceType]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.virtualViewings]: {
        value:
          params?.[FilterParamsEnum.virtualViewings]?.value ||
          getFilterDefaultParams()[FilterParamsEnum.virtualViewings],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.virtualViewings]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.sort]: {
        value: params?.[FilterParamsEnum.sort]?.value || getFilterDefaultParams()[FilterParamsEnum.sort],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.sort]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.pageNumber]: {
        value: params?.[FilterParamsEnum.pageNumber]?.value || getFilterDefaultParams()[FilterParamsEnum.pageNumber],
      },
      [FilterSearchUrlParamsEnum.pageLimit]: {
        value: params?.[FilterParamsEnum.pageLimit]?.value || getFilterDefaultParams()[FilterParamsEnum.pageLimit],
      },
      [FilterSearchUrlParamsEnum.isDeveloperProperty]: {
        value:
          params?.[FilterParamsEnum.isDeveloperProperty]?.value ||
          getFilterDefaultParams()[FilterParamsEnum.isDeveloperProperty],
      },
      [FilterSearchUrlParamsEnum.maxInstallmentYears]: {
        value:
          params?.[FilterParamsEnum.maxInstallmentYears]?.value ||
          getFilterDefaultParams()[FilterParamsEnum.maxInstallmentYears],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.maxInstallmentYears]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.minInstallmentYears]: {
        value:
          params?.[FilterParamsEnum.minInstallmentYears]?.value ||
          getFilterDefaultParams()[FilterParamsEnum.minInstallmentYears],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.minInstallmentYears]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.verified]: {
        value: params?.[FilterParamsEnum.verified]?.value || getFilterDefaultParams()[FilterParamsEnum.verified],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.verified]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.trustedAgent]: {
        value:
          params?.[FilterParamsEnum.trustedAgent]?.value || getFilterDefaultParams()[FilterParamsEnum.trustedAgent],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.trustedAgent]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.listedWithin]: {
        value:
          params?.[FilterParamsEnum.listedWithin]?.value || getFilterDefaultParams()[FilterParamsEnum.listedWithin],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.listedWithin]?.choices || []),
      },
      [FilterSearchUrlParamsEnum.paymentMethods]: {
        value:
          params?.[FilterParamsEnum.paymentMethods]?.value || getFilterDefaultParams()[FilterParamsEnum.paymentMethods],
        choices: filterChoiceAdapter(params?.[FilterParamsEnum.paymentMethods]?.choices || []),
      },
    };
  }

  /**
   * Transform the property parameters into value parameters
   */
  public static fromParamsToValue(params: FilterParametersInterface): FilterValueDeprecatedInterface {
    return objectFilterNonOrEmptyValue<FilterValueDeprecatedInterface>({
      ...params,
      [FilterParamsEnum.pageNumber]: params[FilterParamsEnum.pageNumber]?.value,
      [FilterParamsEnum.pageLimit]: params[FilterParamsEnum.pageLimit]?.value,
      [FilterParamsEnum.query]: params.query?.value,
      [FilterParamsEnum.locationsIds]: params[FilterParamsEnum.locationsIds]?.value,
      [FilterParamsEnum.categoryId]: stringToNumber(params[FilterParamsEnum.categoryId]?.value),
      [FilterParamsEnum.propertyTypeId]: stringToNumber(params[FilterParamsEnum.propertyTypeId]?.value),
      [FilterParamsEnum.minPrice]: stringToNumber(params[FilterParamsEnum.minPrice]?.value),
      [FilterParamsEnum.maxPrice]: stringToNumber(params[FilterParamsEnum.maxPrice]?.value),
      [FilterParamsEnum.furnishing]: stringToNumber(params[FilterParamsEnum.furnishing]?.value),
      [FilterParamsEnum.minArea]: stringToNumber(params[FilterParamsEnum.minArea]?.value),
      [FilterParamsEnum.maxArea]: stringToNumber(params[FilterParamsEnum.maxArea]?.value),
      [FilterParamsEnum.pricePeriod]: params[FilterParamsEnum.pricePeriod]?.value,
      [FilterParamsEnum.keyword]: params[FilterParamsEnum.keyword]?.value,
      [FilterParamsEnum.amenities]: params[FilterParamsEnum.amenities]?.value,
      [FilterParamsEnum.bedrooms]: params[FilterParamsEnum.bedrooms]?.value,
      [FilterParamsEnum.bathrooms]: params[FilterParamsEnum.bathrooms]?.value,
      [FilterParamsEnum.completionStatus]: params[FilterParamsEnum.completionStatus]?.value,
      [FilterParamsEnum.utilitiesPriceType]: params[FilterParamsEnum.utilitiesPriceType]?.value,
      [FilterParamsEnum.virtualViewings]: params[FilterParamsEnum.virtualViewings]?.value,
      [FilterParamsEnum.isDeveloperProperty]: params[FilterParamsEnum.isDeveloperProperty]?.value,
      [FilterParamsEnum.minInstallmentYears]: params[FilterParamsEnum.minInstallmentYears]?.value,
      [FilterParamsEnum.maxInstallmentYears]: params[FilterParamsEnum.maxInstallmentYears]?.value,
      [FilterParamsEnum.verified]: params[FilterParamsEnum.verified]?.value,
      [FilterParamsEnum.trustedAgent]: params[FilterParamsEnum.trustedAgent]?.value,
      [FilterParamsEnum.sort]: params.sort?.value,
      [FilterParamsEnum.listedWithin]: params[FilterParamsEnum.listedWithin]?.value,
      [FilterParamsEnum.paymentMethods]: params[FilterParamsEnum.paymentMethods]?.value,
    });
  }

  /**
   * Adapt to filter server params (add page number and limit to list of settings)
   */
  public static toServerParams(
    bundleSettings: { searchForm: FilterServerInterface } & BackendConfigSettingsInterface
  ): { searchForm: FilterServerInterface } & BackendConfigSettingsInterface {
    const settings = { ...bundleSettings };

    settings.searchForm = {
      ...settings.searchForm,
      page: {
        value:
          settings.search.payload.data.relationships.properties.meta.page ||
          getFilterDefaultParams()[FilterParamsEnum.pageNumber],
      },
      limit: {
        value:
          settings.search.payload.data.relationships.properties.meta.per_page ||
          getFilterDefaultParams()[FilterParamsEnum.pageLimit],
      },
    };

    return settings;
  }
}
