import { cookieAuthenticatorSha1 } from './sha1';

// this magic with class is required to avoid webpack inline the secret into the places where it is used
// making it more difficult to reverse engineer cookie sign algorithm
// tslint:disable-next-line: pf-file
class A {
  public static e(): string {
    return cookieAuthenticatorSha1(Date.now().toString(16)).padStart(40, '0');
  }
}

/**
 * Generates random 4 character salt from 0000 to ffff
 */
export const cookieAuthenticatorGenerateSalt = function e(): string {
  return A.e();
};
