import { FunctionalComponent, h } from 'preact';
import { useContext } from 'preact/hooks';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { areaSelectorComputeText } from 'common/module/area-selector/compute-text';
import { AreaSelectorTemplatePropsInterface } from 'common/module/area-selector/template-props.interface';
import { LanguageContext } from 'common/module/language/context';
import { RangeSelectorComponent } from 'library/range-selector/component';

export const AreaSelectorTemplate: FunctionalComponent<AreaSelectorTemplatePropsInterface> = (
  props: AreaSelectorTemplatePropsInterface
) => {
  const { isRtl } = useContext(LanguageContext);

  return (
    <div style={props.style} className={['area-selector'].concat(props.className || '').join(' ')}>
      <RangeSelectorComponent
        inputContainerClassName='area-selector__input-container'
        label={`${i18nTranslate('Area')} (${i18nTranslate(props.unit)})`}
        text={areaSelectorComputeText(props.value, props.unit)}
        rtl={isRtl}
        options={props.options}
        onChange={(value) => {
          props.onChange({
            min: value.min.replace(/,/g, ''),
            max: value.max.replace(/,/g, ''),
          });
        }}
        onClear={() => {
          props.onChange({ min: '', max: '' });
        }}
        mapToLabel={(option) => `${option} ${i18nTranslate(props.unit)}`}
        value={props.value}
        min={{
          placeholder: i18nTranslate('Min Area'),
        }}
        max={{
          placeholder: i18nTranslate('Max Area'),
        }}
        onDropdownOpenStatusChange={props.onDropdownOpenStatusChange}
        dropdownSize={props.dropdownSize}
      />
    </div>
  );
};
