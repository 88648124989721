import { BrowserStorageInterface } from 'pf-frontend-common/src/module/browser/storage.interface';
import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';
import { ApiServiceInterface } from 'pf-frontend-common/src/service/api/service.interface';

import { JwtTokenStore } from 'common/data/jwt/token/store';
import { PropertyContactedIdbStatechart } from 'common/data/property-contacted/idb/statechart';
import { PropertyContactedIdbStatechartEvent } from 'common/data/property-contacted/idb/statechart.event';
import { PropertyContactedIdbStatechartActionEnum } from 'common/data/property-contacted/idb/statechart-action.enum';
import { PropertyContactedModel } from 'common/data/property-contacted/model';
import { PropertyContactedSaveRequestInterface } from 'common/data/property-contacted/save/request.interface';
import { PropertyContactedStoreEvent } from 'common/data/property-contacted/store.event';
import { PropertyContactedTypeEnum } from 'common/data/property-contacted/type.enum';
import { arrayFind } from 'common/helper/array/find';
import { configGetLanguage } from 'common/helper/config/get-language';
import { dateToIso } from 'common/helper/date/to-iso';
import { DatabaseTableEnum } from 'common/module/database/table.enum';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { DatabaseServiceInterface } from 'common/service/database/service.interface';
import { JwtTokenServiceEvent } from 'common/service/jwt-token/service.event';
import { JwtTokenServiceInterface } from 'common/service/jwt-token/service.interface';
import { PropertyContactedStoreServiceInterface } from 'common/service/property-contacted-store/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

export class PropertyContactedStore implements PropertyContactedStoreServiceInterface {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    save: {
      path: this.apiEndpointService.getPath('/user/contacted-property'),
      method: 'POST',
    },
    update: {
      path: this.apiEndpointService.getPath('/user/contacted-property'),
      method: 'PATCH',
    },
    list: {
      path: this.apiEndpointService.getPath('/user/contacted-property'),
      method: 'GET',
    },
    delete: {
      path: this.apiEndpointService.getPath('/user/contacted-property'),
      method: 'DELETE',
    },
  };

  /**
   * A value to understand if the data being moved to local storage
   */
  private readonly migratedToLocalStorageKey: string = 'property_contacted_migrated_to_local_storage';

  /**
   * Constructor
   */
  constructor(
    private databaseService: DatabaseServiceInterface,
    private idbStatechartStore: StatechartStoreInterface,
    private eventEmitter: EventEmitterInterface,
    private jsonApiStore: JsonApiStore<PropertyContactedModel>,
    private jsonApiStoreFactory: () => JsonApiStore<PropertyContactedModel>,
    private apiService: ApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface,
    private jwtTokenService: JwtTokenServiceInterface,
    private localStorage: BrowserStorageInterface
  ) {
    this.idbStatechartStore.initialize({
      statechart: PropertyContactedIdbStatechart,
    });

    // Attach observers
    this.observers();

    this.idbStatechartStore.transit({
      event: PropertyContactedIdbStatechartEvent.loadAll,
      payload: {},
    });
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public getAll(): Promise<PropertyContactedModel[]> {
    // Local cache available
    if (this.jsonApiStore.findAll().length) {
      return Promise.resolve(this.jsonApiStore.findAll());
    }

    if (this.localStorage.getData(this.migratedToLocalStorageKey)) {
      return Promise.resolve(this.getAllFromLocalStorage());
    }

    return this.databaseService
      .openDatabase({
        languages: configGetLanguage(),
      })
      .then(async (db) => {
        const contactedProperties: PropertyContactedModel[] = [];
        const transaction = db.transaction(DatabaseTableEnum.contactedProperties, 'readwrite');
        const store = transaction.objectStore(DatabaseTableEnum.contactedProperties);

        let cursor = await store.openCursor();

        while (cursor) {
          contactedProperties.push(cursor.value);

          cursor = await cursor.continue();
        }

        return transaction.done.then(() => {
          this.storeContactedProperties(contactedProperties);
          this.localStorage.setData(this.migratedToLocalStorageKey, 1);

          return contactedProperties;
        });
      });
  }

  /**
   * Delete contacted property
   */
  public delete = (propertyId: string): void => {
    const token = this.getToken();

    if (!token) {
      return;
    }

    // Send API request
    this.apiService
      .request(
        this.endpoint.delete.method,
        `${this.endpoint.delete.path}/${propertyId}`,
        null,
        true,
        this.getApiHeaders(token)
      )
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  };

  /**
   * Delete all contacted properties
   */
  public deleteAll = (): void => {
    const token = this.getToken();

    if (!token) {
      return;
    }

    // Send API request
    this.apiService
      .request(this.endpoint.delete.method, this.endpoint.delete.path, null, true, this.getApiHeaders(token))
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  };

  /**
   * Delete all from local storage
   */
  public deleteAllFromLocalStorage = (): void => {
    this.localStorage.removeData(DatabaseTableEnum.contactedProperties);
    this.sync([]);
  };

  /**
   * Delete from local storage
   */
  public deleteFromLocalStorage = (propertyId: string): void => {
    // Local saved properties
    let models = this.jsonApiStore.findAll() || [];

    models = models.filter((model) => model.property_id !== parseInt(propertyId, 10));

    // Re-set the models to local storage
    this.localStorage.setData(DatabaseTableEnum.contactedProperties, models);

    this.sync(models);

    // Update browser storage
    this.syncIdb(models);
  };

  /**
   * @inheritDoc
   */
  public getAllFromCache(): PropertyContactedModel[] {
    return this.jsonApiStore.findAll();
  }

  /**
   * @inheritDoc
   */
  public sync(models: PropertyContactedModel[]): void {
    // Update local cache
    this.jsonApiStore.reset();

    models.forEach((model) => {
      model.id = String(this.jsonApiStore.findAll().length);

      this.jsonApiStore.add(model);
    });

    // Emit event
    this.getEventEmitter().emit(PropertyContactedStoreEvent.sync, models);
  }

  /**
   * Sync indexed db
   */
  public syncIdb(models: PropertyContactedModel[]): void {
    this.idbStatechartStore.transit({
      event: PropertyContactedIdbStatechartEvent.sync,
      payload: models,
    });
  }

  /**
   * @inheritDoc
   */
  public save(propertyId: string, type: PropertyContactedTypeEnum): void {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const payload: PropertyContactedSaveRequestInterface = {
      data: {
        type: 'contacted_property',
        attributes: {
          property_id: parseInt(propertyId, 10),
          contact_date: dateToIso(new Date()),
          contact_type: type,
        },
      },
    };

    // Send API request
    this.apiService
      .request(this.endpoint.save.method, this.endpoint.save.path, payload, true, this.getApiHeaders(token))
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  }

  /**
   * @inheritDoc
   */
  public update(properties: PropertyContactedModel[] = []): void {
    const token = this.getToken();

    if (!token) {
      return;
    }

    const data = properties.map((property: PropertyContactedModel) => {
      return {
        type: property.jsonApiType,
        attributes: {
          property_id: property.property_id,
          contact_date: property.contact_date,
          contact_type: PropertyContactedTypeEnum.call,
        },
      };
    });

    // Send API request
    this.apiService
      .request(this.endpoint.update.method, this.endpoint.update.path, { data }, true, this.getApiHeaders(token))
      .then((response) => {
        const store = this.jsonApiStoreFactory();
        store.sync(response.data);
        this.idbStatechartStore.transit({
          event: PropertyContactedIdbStatechartEvent.loadAll,
          payload: {},
        });
      })
      .catch((response) => {
        // Unauthorized
        if (response.status === 401) {
          this.userAuthenticationService.logOut();
        }
      });
  }

  /**
   * @inheritDoc
   */
  public isSaved(propertyId: string): boolean {
    return !!arrayFind(this.jsonApiStore.findAll(), (model) => model.property_id === parseInt(propertyId, 10));
  }

  /**
   * Merge propertyIds with current ones, by preventing duplicate entries
   */
  public saveInDb(model: PropertyContactedModel): void {
    const oldProperty = arrayFind(
      this.jsonApiStore.findAll(),
      (oldModel) => oldModel.property_id === model.property_id
    );

    if (!oldProperty) {
      // If element not exist, add new element to contacted list
      model.id = String(this.jsonApiStore.findAll().length);
      this.jsonApiStore.add(model);
    } else {
      // If element exist, update contacted date and type
      oldProperty.contact_date = model.contact_date;
      oldProperty.contact_type = model.contact_type;
    }

    // Update browser storage
    this.syncIdb(this.jsonApiStore.findAll());

    // Sync saved property IDs
    this.sync(this.jsonApiStore.findAll());
  }

  /**
   * Attach observers
   */
  private observers(): void {
    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.registrationSucceeded, this.onRegistrationSucceeded);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.logOutSucceeded, this.onLogOutSucceeded);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertyContactedIdbStatechartActionEnum.updateDb, this.onUpdateDbPropertyContactedLocalStorage);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyContactedIdbStatechartActionEnum.updateLocalCache,
        this.onUpdateLocalCachePropertyContactedIdb
      );

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertyContactedIdbStatechartActionEnum.loadAll, this.onLoadAllPropertyContactedIdbAction);

    this.idbStatechartStore
      .getEventEmitter()
      .addListener(PropertyContactedIdbStatechartActionEnum.firstLoading, this.onFirstDataLoading);

    this.jwtTokenService
      .getEventEmitter()
      .addListener(JwtTokenServiceEvent.refreshTokenSucceeded, this.onRefreshTokenSucceededJwtToken);
  }

  /**
   * Get token
   */
  private getToken(): string {
    // User authentication token
    const token = this.userAuthenticationService.getToken();

    return token;
  }

  /**
   * Clear the list of saved properties
   */
  private clear(): void {
    // Empty the list
    this.sync([]);
    this.deleteAllFromLocalStorage();
    this.getEventEmitter().emit(PropertyContactedStoreEvent.clear);
  }

  /**
   * Returns the API headers to attach to each request
   *
   * TODO-FE[later] Implement a HTTP interceptor or an ApiService decorator in the pf-website application
   */
  private getApiHeaders(token: string): DataKeyValueStringInterface {
    const headers: DataKeyValueStringInterface = {};
    headers[JwtTokenStore.HEADER_JWT] = 'Bearer ' + token;

    return headers;
  }

  /**
   * Load contacted properties
   */
  private loadContactedProperties(): Promise<PropertyContactedModel[]> {
    return this.apiService
      .request(
        this.endpoint.list.method,
        this.endpoint.list.path,
        {},
        true,
        this.getApiHeaders(this.userAuthenticationService.getToken())
      )
      .then((response: HttpApiResponseInterface) => {
        // Empty data
        if (!response.data.data) {
          // Consider data as an empty array

          return [];
        }

        this.jsonApiStore.reset();

        // Remote saved property IDs
        this.jsonApiStore.sync(response.data);

        return this.jsonApiStore.findAll();
      });
  }

  /**
   * Sign in succeeded
   */
  private onSignInSucceeded = (): void => {
    this.getAll().then((properties) => this.update(properties));
  };

  /**
   * Registration succeeded
   */
  private onRegistrationSucceeded = (): void => {
    this.getAll().then((properties) => this.update(properties));
  };

  /**
   * User logged out
   */
  private onLogOutSucceeded = (): void => {
    // Clear the list
    this.clear();
  };

  /**
   * Update db action handler
   */
  private onUpdateDbPropertyContactedLocalStorage = (): void => {
    const models = this.jsonApiStore.findAll();

    this.storeContactedProperties(models);

    this.idbStatechartStore.transit({
      event: PropertyContactedIdbStatechartEvent.synced,
      payload: this.jsonApiStore.findAll(),
    });
  };

  /**
   * Update local cache action handler
   */
  private onUpdateLocalCachePropertyContactedIdb = (properties: PropertyContactedModel[]): void => {
    this.sync(properties);
  };

  /**
   * Load all contacted properties action handler
   */
  private onLoadAllPropertyContactedIdbAction = () => {
    const token = this.getToken();

    if (!token) {
      this.getAll().then((properties) => {
        this.idbStatechartStore.transit({
          event: PropertyContactedIdbStatechartEvent.loadAllSuccess,
          payload: properties,
        });
      });
      return;
    }

    this.loadContactedProperties().then((properties) => {
      this.idbStatechartStore.transit({
        event: PropertyContactedIdbStatechartEvent.loadAllSuccess,
        payload: properties,
      });
    });
  };

  /**
   * First data loading
   */
  private onFirstDataLoading = (): void => {
    // Emit event: first data loading
    this.eventEmitter.emit(PropertyContactedStoreEvent.firstLoading);
  };

  /**
   * Token refresh succeeded
   */
  private onRefreshTokenSucceededJwtToken = (): void => {
    this.sync([]);
  };

  /**
   * Store contacted properties
   *
   * @param models PropertyContactedModel
   */
  private storeContactedProperties(models: PropertyContactedModel[]): void {
    const contactedProperties = models.map((model) => {
      return {
        property_id: model.property_id,
        contact_date: model.contact_date,
        contact_type: model.contact_type,
      };
    });

    if (contactedProperties.length === 0) {
      this.localStorage.removeData(DatabaseTableEnum.contactedProperties);
    } else {
      this.localStorage.setData(DatabaseTableEnum.contactedProperties, contactedProperties);
    }
  }

  /**
   * Get contacted properties
   */
  private getAllFromLocalStorage(): PropertyContactedModel[] {
    const properties =
      (this.localStorage.getData(DatabaseTableEnum.contactedProperties) as PropertyContactedModel[]) || [];

    return properties?.map((property) => {
      const newProperty = new PropertyContactedModel();
      newProperty.property_id = property.property_id;
      newProperty.contact_date = property.contact_date;
      newProperty.contact_type = property.contact_type;

      return newProperty;
    });
  }
}
