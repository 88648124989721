import { WindowService } from 'pf-frontend-common/dist/service/window/service';

import { configGetTranslations } from 'common/helper/config/get-translations';

const cache: { [key: string]: boolean } = {};

/**
 * Returns translation of a string from translations object
 *
 * @param key - Translation key (following BE convention, format is "singular" or "singular|plural")
 * @param count - Count to define if the singular or plural form will be returned
 */
export function i18nTranslate(key: string, count?: number): string {
  // Get translation from config
  let translation = configGetTranslations()[key];

  // Plural form support
  if (translation && translation.indexOf('|') > -1) {
    // Split on '|' and pick plural or singular form based on count value
    translation = translation.split('|')[(count || 0) > 1 ? 1 : 0];
  }

  // Translation found
  if (translation) {
    return translation;
  }

  // Missing translation not cached
  if (!cache[key]) {
    cache[key] = true;

    // Prevent spamming the tests with console
    if (WindowService().getNative().propertyfinder && WindowService().getNative().propertyfinder.translations) {
      // eslint-disable-next-line no-console
      console.warn('Missing translation: ', `'${key}': '{{ "${key}"|trans }}',`);
    }
  }

  return key;
}
