import { FunctionalComponent, h } from 'preact';

import { configGetFeature } from 'common/helper/config/get-feature';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { preactDynamicComponent } from 'common/module/preact/dynamic/component';
import { PropertyListViewStore } from 'common/module/property/list/view-store';
import { RecommendedPropertiesTemplatePropsInterface } from 'common/module/recommended-properties/template-props.interface';
import { HomePageRecommendedPropertiesListTemplate } from 'desktop/module/home-page/recommended-properties/list-template';

const RecommendedPropertiesList = preactDynamicComponent(HomePageRecommendedPropertiesListTemplate);

export const HomePageRecommendedPropertiesTemplate: FunctionalComponent<RecommendedPropertiesTemplatePropsInterface> = (
  props
) => (
  <div className='featured-properties'>
    {!configGetFeature('something_new').enabled && (
      <h2 className='heading__divider heading__divider--home-page'>
        <span className='heading__divider-text'>
          {!props.isLoading &&
            (props.isRecommendedProperties
              ? i18nTranslate('Recommended properties for you')
              : i18nTranslate('featured-properties-title'))}
        </span>
      </h2>
    )}
    <RecommendedPropertiesList
      mapState={(store: PropertyListViewStore) => ({
        ...store.getState(),
        isLoading: props.isLoading,
        propertiesCount: props.isLoading
          ? props.fallbackProperties.length
          : Math.min(
              props.fallbackProperties.length,
              configGetFeature('something_new').enabled ? 2 : store.getState().properties.length
            ),
      })}
      viewStore={props.propertyListViewStore}
    />
  </div>
);
