import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { AdStore } from 'common/module/ad/store';
import { AdServiceInterface } from 'common/service/ad/service.interface';

const service = new AdStore(EventEmitterFactory());

export function AdService(): AdServiceInterface {
  return service;
}
