/* eslint-disable no-console */
import { DataKeyValueStringInterface } from '@propertyfinder/pf-frontend-common/src/module/data/key-value/string.interface';
import { HttpAbortableInterface } from '@propertyfinder/pf-frontend-common/src/module/http/abortable.interface';
import { HttpApiOptionsInterface } from '@propertyfinder/pf-frontend-common/src/module/http/api-options.interface';
import { HttpApiResponseInterface } from '@propertyfinder/pf-frontend-common/src/module/http/api-response.interface';
import { ApiServiceInterface } from '@propertyfinder/pf-frontend-common/src/service/api/service.interface';

import { ApiEndpointService } from 'common/service/api-endpoint/service';

import { BrowserCookieInterface } from '../browser/cookie.interface';
import { CookieAuthenticatorApiServiceInterface } from './api-service.interface';
import { cookieAuthenticatorGetCookieSignName } from './cookie-sign-name';
import { cookieAuthenticatorGetSecret } from './get-secret';
import { CookieAuthenticatorSetCookieApiRequestModel } from './set-cookie-api-request-model';
import { cookieAuthenticatorSha1 } from './sha1';
import { signValidationCookie } from './sign-cookie';

const VALIDATION_COOKIE_NAME: string = 'captcha_authenticator';

export const cookieAuthenticatorWrapHttpApi = (
  apiService: ApiServiceInterface,
  cookieService: BrowserCookieInterface,
  captchaAuthenticatorEnabled: boolean
): CookieAuthenticatorApiServiceInterface => {
  if (!captchaAuthenticatorEnabled) {
    return {
      request: apiService.request.bind(apiService),
    };
  }

  let setValidationCookieRequestPromise: null | Promise<string> = null;

  /**
   * Read the validation cookie from cookies or get it from server if not there
   */
  function getValidationCookie(ignoreCookieFromBrowser?: boolean): Promise<string | void> {
    // If we already validating the cookie, do not start another validation, just give the preivous promise back
    if (setValidationCookieRequestPromise) {
      return setValidationCookieRequestPromise;
    }
    const validationCookieFromBrowser = cookieService.getData(VALIDATION_COOKIE_NAME) as string;

    if (!ignoreCookieFromBrowser && validationCookieFromBrowser) {
      return Promise.resolve(validationCookieFromBrowser);
    }
    setValidationCookieRequestPromise = apiService
      .request(
        'POST',
        ApiEndpointService().getPath('/set-validation-cookie'),
        new CookieAuthenticatorSetCookieApiRequestModel(cookieAuthenticatorSha1(cookieAuthenticatorGetSecret()))
      )
      .then(() => {
        return cookieService.getData(VALIDATION_COOKIE_NAME) as string;
      });

    setValidationCookieRequestPromise
      .then(() => {
        setValidationCookieRequestPromise = null;
      })
      .catch((e: Error) => {
        setValidationCookieRequestPromise = null;
        console.error('set validation cookie failed', e);
      });

    return setValidationCookieRequestPromise;
  }

  /**
   * Adds a property to the user's saved properties
   *
   * @param method    GET|POST|PUT|DELETE|PATCH
   * @param endpoint  Ex: "/properties/1337"
   * @param data      Data sent with the request
   * @param jsonAPI   jsonAPI request
   * @param headers   request header
   * @param options   additional request options
   * @param calledToRetryOn401 called after server sent 401
   */
  function request(
    method: string,
    endpoint: string,
    data: object | null,
    jsonAPI?: boolean,
    headers?: DataKeyValueStringInterface,
    options?: HttpApiOptionsInterface,
    calledToRetryOn401?: boolean
  ): Promise<HttpApiResponseInterface> & HttpAbortableInterface {
    let abortablePromise: Promise<HttpApiResponseInterface> & HttpAbortableInterface;
    let innerAbortablePromise: Promise<HttpApiResponseInterface> & HttpAbortableInterface;

    const promise = getValidationCookie(calledToRetryOn401).then((validationCookie) => {
      if (!validationCookie) {
        console.error(`validation cookie is missing after request to set-validation-cookie endpoint`);
        return Promise.reject('Can not call api without validation cookie.');
      }

      return new Promise((resolve, reject) => {
        const patchedHeaders = {
          ...headers,
          [cookieAuthenticatorGetCookieSignName()]: signValidationCookie(validationCookie),
        };

        abortablePromise = apiService.request(method, endpoint, data, jsonAPI, patchedHeaders, options);

        abortablePromise
          .then((output) => {
            // try refreshing the validation cookie after every search call,
            // because if the cookie was removed by the server, we should immediatelly get a new one
            getValidationCookie();

            resolve(output);
          })
          .catch((response: HttpApiResponseInterface | null) => {
            if (response && response.status === 401) {
              if (calledToRetryOn401) {
                console.error(`rejected with 401 more than one time: ${validationCookie}`);
                reject(response);
              } else {
                console.error(`rejected with 401 one time: ${validationCookie}`);
                innerAbortablePromise = request(method, endpoint, data, jsonAPI, headers, options, true);
                innerAbortablePromise.then(resolve).catch(reject);
              }
            } else {
              reject(response);
            }
          });
      });
    }) as Promise<HttpApiResponseInterface> & HttpAbortableInterface;

    promise.abort = () => {
      if (innerAbortablePromise) {
        // if innerAbortablePromise is there, then abortablePromise is already resolved
        innerAbortablePromise.abort();
      } else if (abortablePromise) {
        abortablePromise.abort();
      }
    };

    return promise;
  }

  // Kick off the loading of the validation cookie
  getValidationCookie();

  return {
    request,
  };
};
