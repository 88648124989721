import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class HomeValuationModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'home_valuation';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = HomeValuationModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof this> = [
    'asking_price_from',
    'asking_price_to',
    'rental_price_average',
    'rental_yield',
    'rental_price_average_change',
    'rental_yield_change',
  ];

  // tslint:disable:variable-name

  /**
   * Asking price from
   */
  public asking_price_from: number;

  /**
   * Asking price to
   */
  public asking_price_to: number;

  /**
   * Average asking price
   */
  public rental_price_average: number;

  /**
   * Rental yield
   */
  public rental_yield: number;

  /**
   * Change of the average rental price (%)
   */
  public rental_price_average_change: number;

  /**
   * Change of the rental yield (%)
   */
  public rental_yield_change: number;
}
