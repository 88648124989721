import { Component, ComponentFactory, h, RenderableProps } from 'preact';

import { DataStoreEvent } from 'common/module/data/store.event';
import { DataStoreInterface } from 'common/module/data/store.interface';

/**
 * Connects view store in more efficient way
 * Gives ability to connect to multiple stores:
 * @example
 * compose(
 *  preactConnectStore(store1, mapStore1StateToProps),
 *  preactConnectStore(store2, mapStore2StateToProps),
 * )(Component)
 */
export const preactConnectStore =
  <Store extends DataStoreInterface, ConnectedProps = {}, Props = {}>(
    store: Store | (() => Store),
    stateMapper: (store: Store, self: Component<Props>) => RenderableProps<ConnectedProps>,
    initializeStore?: (store: Store, self: Component<Props>) => void,
    didMount?: (store: Store, self: Component<Props>) => void
  ) =>
  (WrappedComponent: ComponentFactory<Readonly<ConnectedProps> & Readonly<Props>>) =>
    class PreactConnectedStoreComponent<P extends Props> extends Component<P> {
      /**
       * Store instance
       */
      private store: Store;

      /**
       * Constructor
       */
      public constructor(props: P) {
        super(props);

        this.store = store instanceof Function ? store() : store;
        if (initializeStore) {
          initializeStore(this.store, this);
        }
      }

      /**
       * @inheritDoc
       */
      public componentWillMount(): void {
        this.setState(this.store.getState());

        this.store.getEventEmitter().addListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);
      }

      /**
       * @inheritDoc
       */
      public componentWillUnmount(): void {
        this.store.getEventEmitter().removeListener(DataStoreEvent.updateState, this.onUpdateStateDataStore);
        this.store.destroy();
      }

      /**
       * @inheritDoc
       */
      public render(): preact.JSX.Element {
        const storeState = stateMapper(this.store, this) || null;

        // Using any field in order to fix an error with generics order
        return (
          <WrappedComponent {...storeState} {...(this.props as any)}>
            {(storeState && storeState.children) || this.props.children}
          </WrappedComponent>
        );
      }

      public componentDidMount(): void {
        didMount?.(this.store, this);
      }

      /**
       * Data store state updated
       */
      private onUpdateStateDataStore = () => {
        this.setState(this.store.getState());
      };
    };
