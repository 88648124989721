import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapter } from 'common/data/property-category/identifier/adapter';
import { PropertyPriceTypeShortEnum } from 'common/data/property-price-type/short.enum';
import { FilterParamsEnum } from 'common/module/filter/params.enum';

import { FilterValueBaseInterface } from '../value-base.interface';

export const filterDefaultParamsCommon: FilterValueBaseInterface = {
  [FilterParamsEnum.amenities]: [],
  [FilterParamsEnum.bedrooms]: [],
  [FilterParamsEnum.bathrooms]: [],
  [FilterParamsEnum.locationsIds]: [],
  [FilterParamsEnum.furnishing]: '0',
  [FilterParamsEnum.pageNumber]: 1,
  [FilterParamsEnum.pageLimit]: 25,
  [FilterParamsEnum.pricePeriod]: PropertyPriceTypeShortEnum.yearly,
  [FilterParamsEnum.categoryId]: new PropertyCategoryIdentifierAdapter().getValue(
    PropertyCategoryIdentifierEnum.residentialForRent
  ),
  [FilterParamsEnum.sort]: '',
  [FilterParamsEnum.query]: '',
  [FilterParamsEnum.propertyTypeId]: '',
  [FilterParamsEnum.minPrice]: '',
  [FilterParamsEnum.maxPrice]: '',
  [FilterParamsEnum.minArea]: '',
  [FilterParamsEnum.maxArea]: '',
  [FilterParamsEnum.keyword]: '',
  [FilterParamsEnum.completionStatus]: '',
  [FilterParamsEnum.utilitiesPriceType]: '',
  [FilterParamsEnum.virtualViewings]: '',
  [FilterParamsEnum.isDeveloperProperty]: '',
  [FilterParamsEnum.minInstallmentYears]: '',
  [FilterParamsEnum.maxInstallmentYears]: '',
  [FilterParamsEnum.verified]: '',
  [FilterParamsEnum.trustedAgent]: '',
  [FilterParamsEnum.listedWithin]: '',
  [FilterParamsEnum.paymentMethods]: [],
};
