export enum FilterSearchUrlParamsEnum {
  /**
   * How to order the results
   */
  sort = 'ob',

  /**
   * Text of the query
   */
  query = 'q',

  /**
   * Services needed
   */
  categoryId = 'c',

  /**
   * Type
   */
  propertyTypeId = 't',

  /**
   * Location ids
   */
  locationsIds = 'l',

  /**
   * Min price
   */
  minPrice = 'pf',

  /**
   * Max price
   */
  maxPrice = 'pt',

  /**
   * Furnishing
   */
  furnishing = 'fu',

  /**
   * Area (Min)
   */
  minArea = 'af',

  /**
   * Area (Max)
   */
  maxArea = 'at',

  /**
   * Price period
   */
  pricePeriod = 'rp',

  /**
   * Keywords
   */
  keyword = 'kw',

  /**
   * Amenities
   */
  amenities = 'am',

  /**
   * Bedrooms
   */
  bedrooms = 'bdr',

  /**
   * Bathrooms
   */
  bathrooms = 'btr',

  /**
   * Completion status
   */
  completionStatus = 'cs',

  /**
   * Price inclusive/exclusive
   */
  utilitiesPriceType = 'ut',

  /**
   * Virtual viewings
   */
  virtualViewings = 'vv',

  /**
   * Pagination page to request
   */
  pageNumber = 'page',

  /**
   * Pagination page to request
   */
  pageLimit = 'limit',

  /**
   * Is developer property
   */
  isDeveloperProperty = 'dp',

  /**
   * Min installment years
   */
  minInstallmentYears = 'iyf',

  /**
   * Max installment years
   */
  maxInstallmentYears = 'iyt',

  /**
   * Verified
   */
  verified = 'v',

  trustedAgent = 'ta',

  listedWithin = 'lw',

  paymentMethods = 'pms',
}
