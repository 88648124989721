import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { AgentModel } from 'common/data/agent/model';
import { BrokerModel } from 'common/data/broker/model';
import { LocationModel } from 'common/data/location/model';
import { AutocompleteStoreInterface } from 'common/module/autocomplete/store.interface';
import { AutocompleteStoreSearchConfigInterface } from 'common/module/autocomplete/store/search-config.interface';
import { AutocompleteStoreStateInterface } from 'common/module/autocomplete/store/state.interface';
import { DataStoreOptionsInterface } from 'common/module/data/store/options.interface';

export class AutocompleteStoreDecorator implements AutocompleteStoreInterface {
  /**
   * Constructor
   */
  constructor(protected autocompleteStore: AutocompleteStoreInterface) {}

  /**
   * @inheritDoc
   */
  public searchLocations(
    searchString: string,
    props?: AutocompleteStoreSearchConfigInterface
  ): Promise<LocationModel[]> {
    return this.autocompleteStore.searchLocations(searchString, props);
  }

  /**
   * @inheritDoc
   */
  public searchAgents(escapedSearch: string): void {
    return this.autocompleteStore.searchAgents(escapedSearch);
  }

  /**
   * @inheritDoc
   */
  public searchBrokers(escapedSearch: string): void {
    return this.autocompleteStore.searchBrokers(escapedSearch);
  }

  /**
   * @inheritDoc
   */
  public getAgentById(agentId: string): AgentModel {
    return this.autocompleteStore.getAgentById(agentId);
  }

  /**
   * @inheritDoc
   */
  public getBrokerById(brokerId: string): BrokerModel {
    return this.autocompleteStore.getBrokerById(brokerId);
  }

  /**
   * @inheritDoc
   */
  public getLocationById(locationId: string): LocationModel {
    return this.autocompleteStore.getLocationById(locationId);
  }

  /**
   * @inheritDoc
   */
  public getState(): AutocompleteStoreStateInterface {
    return this.autocompleteStore.getState();
  }

  /**
   * @inheritDoc
   */
  public setBrokerId(id: string): void {
    this.autocompleteStore.setBrokerId(id);
  }

  /**
   * @inheritDoc
   */
  public setAgentId(id: string): void {
    this.autocompleteStore.setAgentId(id);
  }

  /**
   * @inheritDoc
   */
  public setLocationId(id: string): void {
    this.autocompleteStore.setLocationId(id);
  }

  /**
   * @inheritDoc
   */
  public resetState(): void {
    this.autocompleteStore.resetState();
  }

  /**
   * @inheritDoc
   */
  public initState(): void {
    this.autocompleteStore.initState();
  }

  /**
   * @inheritDoc
   */
  public initialize(options?: DataStoreOptionsInterface): void {
    this.autocompleteStore.initialize(options);
  }

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.autocompleteStore.getEventEmitter();
  }

  /**
   * @inheritDoc
   */
  public setBrokers(brokers: BrokerModel[]): void {
    this.autocompleteStore.setBrokers(brokers);
  }

  /**
   * @inheritDoc
   */
  public setAgents(agents: AgentModel[]): void {
    this.autocompleteStore.setAgents(agents);
  }

  /**
   * @inheritDoc
   */
  public setLocations(locations: LocationModel[]): void {
    this.autocompleteStore.setLocations(locations);
  }

  /**
   * @inheritdoc
   */
  public destroy(): void {
    this.autocompleteStore.destroy();
  }
}
