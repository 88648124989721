export const enum StatsTealiumDataLayerTealiumEventEnum {
  /**
   * User login
   */
  userLogin = 'user_login',

  /**
   * User logout
   */
  userLogout = 'user_logout',

  /**
   * User register
   */
  userRegister = 'user_register',

  /**
   * User sign-up
   */
  emailSignup = 'email_signup',

  /**
   * Call now
   */
  callNow = 'call_now',

  /**
   * Email now
   */
  emailNow = 'email_now',

  /**
   * Calculate traveltime
   */
  calculateTravelTime = 'calculate_traveltime',

  /**
   * App download
   */
  appDownload = 'app_download',

  /**
   * Saved property add
   */
  savedPropertyAdd = 'save_property_add',

  /**
   * Saved property remove
   */
  savedPropertyRemove = 'save_property_remove',

  /**
   * Saved properties remove all
   */
  savedPropertyRemoveAll = 'save_property_remove_all',

  savePropertyNotificationModalOpen = 'save_property_notification_modal_open',

  savePropertyKeepPostedClick = 'save_property_keep_posted_click',

  savePropertyKeepPostedSkipped = 'save_property_keep_posted_skipped',

  savePropertyEmailSubmit = 'save_property_email_submit',

  /**
   * Call back
   */
  callBack = 'call_back',

  /**
   * Whatsapp
   */
  whatsappNow = 'whatsapp_now',

  /**
   * SMS
   */
  smsNow = 'sms_now',

  /**
   * Default view event
   */
  default = 'view',

  /**
   * Search view
   */
  search = 'search_view',

  saveSearchOpen = 'save_search_open',

  saveSearchEmailModalOpen = 'save_search_email_modal_open',

  saveSearchEmailSubmit = 'save_search_email_submit',

  saveSearchAddEmailSkipped = 'save_search_add_email_skipped',

  saveSearchCreate = 'save_search_create',

  /**
   * Phone reveal
   */
  phoneReveal = 'phone_reveal',

  /**
   * Map commute time find
   */
  mapCommuteTimesFind = 'map_search_commute_times_find',

  /**
   * Map commute time opened
   */
  mapCommuteTimesOpen = 'map_search_commute_times_open',

  /**
   * Map amenity selection
   */
  mapAmenityFilter = 'map_search_amenity_filter',

  /**
   * open gallery modal
   */
  openGalleryModal = 'open_gallery',

  /**
   * close gallery modal
   */
  closeGalleryModal = 'close_gallery',

  /**
   * scroll gallery modal
   */
  scrollGalleryModal = 'gallery_scroll',

  /**
   * Properties sort
   */
  propertiesBySort = 'properties_sort_by',

  /**
   * Flagship
   */
  flagship = 'flagship',

  /**
   * Email alert signup success
   */
  emailAlertSignupSuccess = 'email_alert_signup_success',

  /**
   * Email alert signup start
   */
  emailAlertSignupStart = 'email_alert_signup_start',

  /**
   * Agent Hub links
   */
  agentHubLinks = 'agent_hub_links',

  /**
   * Something new everyday card
   */
  somethingNewCommunity = 'something_new_community',

  /**
   * Something new everyday properties link
   */
  somethingNewProperties = 'something_new_properties',

  /**
   * Something new everyday gallery scroll
   */
  somethingNewScroll = 'something_new_scroll',

  /**
   * Read more
   */
  readMore = 'read_more',

  /**
   * Search Impression Card
   */
  search_impression_card = 'search_impression_card',

  /**
   *  Transaction history impression
   */
  propertyTransactionHistoryImpression = 'property_transaction_history_impression',

  /**
   *  Transaction history time track
   */
  propertiesLikeThisTableTimeTrack = 'properties_like_this_table_time_track',

  /**
   *  Click price trends graph
   */
  priceTrendGraphDateRangeClick = 'price_trend_graph_date_range_click',

  /**
   *  Track time spent on price trends graph
   */
  priceTrendGraphTimeTrack = 'price_trend_graph_time_track',

  /**
   *  Price and trends item impression
   */
  priceTrendItemsImpression = 'price_trend_items_impression',

  /**
   *  Price and trends chart impression
   */
  priceTrendChartImpression = 'price_trend_chart_impression',

  dataGuruCtaClick = 'data_guru_cta_click',

  insightsHubCtaClick = 'insights_hub_clicked',

  dataGuruCardClick = 'data_guru_card_click',

  knowYourRightsLinkClick = 'know_your_rights_clicked',

  galleryTowerTabClick = 'gallery_tower_tab_click',
  galleryCommunityTabClick = 'gallery_community_tab_click',
  galleryFloorPlanTabClick = 'gallery_floor_plan_tab_click',
  galleryTowerTabTimeTrack = 'gallery_tower_tab_time_track',
  galleryCommunityTabTimeTrack = 'gallery_community_tab_time_track',
  galleryFloorPlanTabTimeTrack = 'gallery_floor_plan_time_track',

  appSelectionBannerImpression = 'app_selection_banner_impression',
  appSelectionBannerClosed = 'app_selection_banner_closed',
  appSelectionSmartButtonClicked = 'app_selection_smart_button_clicked',
}
