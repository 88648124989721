export enum FilterWidgetTypeEnum {
  /**
   * Property type
   */
  propertyType = 'filter[propertyTypeId]',

  /**
   * Bedroom and bathroom , min and max
   */
  bedBath = 'filter[number_of_bedrooms]|filter[number_of_bathrooms]',

  /**
   * Furnished
   */
  furnished = 'filter[furnishing]',

  /**
   * Price , min and max
   */
  price = 'filter[min_price]|filter[max_price]',

  /**
   * Utilities price type
   */
  utilitiesPriceType = 'filter[utilities_price_type]',

  /**
   * Amenities
   */
  amenities = 'filter[amenities]',

  /**
   * Area , min and max
   */
  area = 'filter[min_area]|filter[max_area]',

  /**
   * Completion status
   */
  completion = 'filter[completion_status]',

  /**
   * Virtual viewings
   */
  virtualViewings = 'filter[virtual_viewings]',

  /**
   * Keywords
   */
  keywords = 'filter[keyword]',

  /**
   * Is developer property
   */
  isDeveloperProperty = 'filter[is_developer_property]|filter[min_installment_years]&filter[max_installment_years]',
}
