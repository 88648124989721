import { Fragment, FunctionalComponent, h } from 'preact';

import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryIdentifierAdapterFactory } from 'common/data/property-category/identifier/adapter.factory';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { FilterHandlerViewStoreStateInterface } from 'common/module/filter/handler/view-store-state.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { PropertySerpLightComponentPropsInterface } from 'common/module/property/serp/light/component-props.interface';
import { PropertySerpLightViewStoreStateInterface } from 'common/module/property/serp/light/view-store-state.interface';

import { ChipChoiceComponent } from '../../../../../library/chip-choice/component';

const propertyCategoryIdentifierAdapter = PropertyCategoryIdentifierAdapterFactory();

export const PropertySerpLightTemplate: FunctionalComponent<
  PropertySerpLightViewStoreStateInterface &
    PropertySerpLightComponentPropsInterface &
    FilterHandlerViewStoreStateInterface
> = ({ filterParams, autocompleteComponent: Autocomplete, onChangeFilters, onSubmit, onCommercialToggleClick }) => {
  const category = propertyCategoryIdentifierAdapter.getData(
    parseInt(filterParams[FilterParamsEnum.categoryId].value, 10)
  );
  const isCommercial = propertyCategoryIdentifierAdapter.isCommercial(category as PropertyCategoryIdentifierEnum);

  const toggleCommercial = () => {
    let newCategory: PropertyCategoryIdentifierEnum;
    if (isCommercial) {
      newCategory =
        category === PropertyCategoryIdentifierEnum.commercialForRent
          ? PropertyCategoryIdentifierEnum.residentialForRent
          : PropertyCategoryIdentifierEnum.residentialForSale;
    } else {
      newCategory =
        category === PropertyCategoryIdentifierEnum.residentialForRent
          ? PropertyCategoryIdentifierEnum.commercialForRent
          : PropertyCategoryIdentifierEnum.commercialForSale;
    }

    onCommercialToggleClick();
    onChangeFilters({
      [FilterParamsEnum.categoryId]: {
        ...filterParams[FilterParamsEnum.categoryId],
        value: propertyCategoryIdentifierAdapter.getValue(newCategory),
      },
    });
  };

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <div className='light-search'>
          <ChipChoiceComponent
            containerClassName='light-search__list'
            options={[
              {
                text: i18nTranslate('Rent'),
                value: isCommercial
                  ? PropertyCategoryIdentifierEnum.commercialForRent
                  : PropertyCategoryIdentifierEnum.residentialForRent,
              },
              {
                text: i18nTranslate('Buy'),
                value: isCommercial
                  ? PropertyCategoryIdentifierEnum.commercialForSale
                  : PropertyCategoryIdentifierEnum.residentialForSale,
              },
            ]}
            name='category'
            selected={category}
            onCheck={(e, selectedOption) => {
              onChangeFilters({
                [FilterParamsEnum.categoryId]: {
                  ...filterParams[FilterParamsEnum.categoryId],
                  value: propertyCategoryIdentifierAdapter.getValue(
                    selectedOption.value as PropertyCategoryIdentifierEnum
                  ),
                },
              });
            }}
          />

          <Autocomplete />

          <div className='checkbox light-search__checkbox'>
            <input
              id='commercial'
              type='checkbox'
              className='checkbox__input'
              autoComplete='off'
              checked={isCommercial}
              onChange={toggleCommercial}
            />
            <label htmlFor='commercial' className='checkbox__label light-search__checkbox-label'>
              {i18nTranslate('View commercial properties only')}
            </label>
          </div>
        </div>
      </form>
    </Fragment>
  );
};
