export const enum BackendConfigEnvDebugEnum {
  /**
   * Disable dfp ad units
   */
  dfp = 'dfp',

  /**
   * Disable gtm
   */
  gtm = 'gtm',

  /**
   * Disable tealium tracking
   */
  tealium = 'tealium',

  /**
   * Delay load of google single sign on script
   */
  delay_gsso = 'delay_gsso',

  /*
   * Delay dfp ad units after page load
   */
  delay_dfp = 'delay_dfp',

  /**
   * Delay autocomplete cache location initialization
   */
  delaylocations = 'delaylocations',
}
