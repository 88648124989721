import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { configGetSettings } from 'common/helper/config/get-settings';
import { UserAvatarViewStore } from 'common/module/user/avatar/view-store';
import { SaveSearchStoreService } from 'common/service/save-search-store/service';
import { UserAuthenticationService } from 'common/service/user-authentication/service';

export function UserAvatarViewStoreFactory(): UserAvatarViewStore {
  return new UserAvatarViewStore(
    EventEmitterFactory(),
    UserAuthenticationService(),
    SaveSearchStoreService(),
    configGetSettings()
  );
}
