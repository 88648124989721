import { h } from 'preact';

import { BackendRouteEnum } from 'common/data/backend/route.enum';
import { configGetEnv } from 'common/helper/config/get-env';
import { UserAvatarDropdownTemplate } from 'desktop/module/user/avatar/dropdown/template';

export const UserAvatarDropdownComponent = (props: { isUserLoggedIn: boolean }) => (
  <UserAvatarDropdownTemplate
    isContactedPropertiesActive={configGetEnv().currentRoute === BackendRouteEnum.contactedProperties}
    isSavedPropertiesActive={configGetEnv().currentRoute === BackendRouteEnum.savedProperties}
    isSavedSearchesActive={configGetEnv().currentRoute === BackendRouteEnum.savedSearches}
    {...props}
  />
);
