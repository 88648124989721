import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';

import { PropertyStoreFactory } from 'common/data/property/store.factory';
import { snowplowGetUserId } from 'common/helper/snowplow/get-user-id';
import { RecommendedPropertiesViewStore } from 'common/module/recommended-properties/view-store';
import { PropertyListViewStoreFactory } from 'desktop/module/property/list/view-store.factory';

export const RecommendedPropertiesViewStoreFactory = () =>
  new RecommendedPropertiesViewStore(
    EventEmitterFactory(),
    PropertyListViewStoreFactory(),
    PropertyStoreFactory(),
    snowplowGetUserId()
  );
