import { BrowserDocumentServiceInterface } from 'pf-frontend-common/dist/service/browser-document/service.interface';
import { ViewBasicInterface } from 'pf-frontend-common/src/module/view/basic.interface';
import { StatsContexterServiceInterface } from 'pf-frontend-common/src/service/stats-contexter/service.interface';

import { PreactMarkupComponentsInterface } from 'common/helper/preact/markup/components.interface';
import { EmailAlertUnsubscribeView } from 'common/module/email-alert/unsubscribe/view';
import { FilterSearchUrlParamsEnum } from 'common/module/filter/search/url/params.enum';
import { FilterStore } from 'common/module/filter/store';
import { RecommendedPropertiesViewStore } from 'common/module/recommended-properties/view-store';
import { FilterCacheServiceInterface } from 'common/service/filter-cache/service.interface';
import { PropertyStoreServiceInterface } from 'common/service/property-store/service.interface';
import { StatsServiceInterface } from 'common/service/stats/service.interface';
import { preactMarkup } from 'common/typings/preact-markup';
import { HomePageBundleSettingsInterface } from 'desktop/entry/home-page/bundle/settings.interface';
import { HomePageCssSelectorEnum } from 'desktop/module/home-page/css-selector.enum';

export class HomePageView implements ViewBasicInterface {
  /**
   * @inheritDoc
   */
  private element: HTMLElement;

  /**
   * Constructor
   */
  constructor(
    private propertySearchFormCacheService: FilterCacheServiceInterface,
    private propertyStoreService: PropertyStoreServiceInterface,
    private filterService: FilterStore,
    private statsContexterService: StatsContexterServiceInterface,
    private statsService: StatsServiceInterface,
    private emailAlertUnsubscribeView: EmailAlertUnsubscribeView,
    private domQuerySelector: (el: HTMLElement, selector: string) => HTMLElement,
    private settings: HomePageBundleSettingsInterface,
    private preactMarkupHelper: preactMarkup,
    private documentService: BrowserDocumentServiceInterface,
    private components: PreactMarkupComponentsInterface,
    private recommendedPropertiesService: RecommendedPropertiesViewStore
  ) {}

  /**
   * @inheritDoc
   */
  public initialize(): HomePageView {
    this.element = this.domQuerySelector(this.documentService.getDocument().body, HomePageCssSelectorEnum.element);

    this.initPropertySearchFormStore();

    // Initialize property store
    this.propertyStoreService.initialize({
      payload: this.settings.featuredProperties.payload,
    });

    this.initRecommendedProperties();

    // Initialize stats
    this.initStats();

    // Initialize preact markup (should be before initialization of sub views and htmlElement)
    this.preactMarkupHelper(this.element, this.components);

    // Initialize subviews
    this.initEmailAlertUnsubscribe();

    return this;
  }

  /**
   * @inheritDoc
   */
  public render(): void {
    // Log stats
    this.stats();

    // Render subviews
    this.renderEmailAlertUnsubscribe();
  }

  /**
   * Log stats for the page
   */
  private stats(): void {
    // Log stats
    this.statsService.pageView();
    this.statsService.homePage();
  }

  /**
   * Init property search form store
   */
  private initPropertySearchFormStore(): void {
    const cacheData = this.propertySearchFormCacheService.getCache();
    const searchFormCache = cacheData
      ? {
          ...cacheData,
          [FilterSearchUrlParamsEnum.sort]: this.settings.searchForm[FilterSearchUrlParamsEnum.sort],
        }
      : cacheData;

    // Initialize search form store
    this.filterService.initialize(searchFormCache || this.settings.searchForm);
  }

  /**
   * Initialize stats
   */
  private initStats(): void {
    this.statsContexterService.setHomePage(true);
  }

  /**
   * Init recommended properties
   */
  private initRecommendedProperties(): void {
    this.recommendedPropertiesService.initialize({
      isTargetBlank: false,
      fallbackProperties: this.propertyStoreService.getAll(),
      // TODO[WEB-6031] - Enable recommendated properties or remove appropriate code as it is no longer used on any page
      isRecommendedPropertiesEnabled: false,
    });
  }

  /**
   * Init email alert unsubscribe
   */
  private initEmailAlertUnsubscribe(): void {
    this.emailAlertUnsubscribeView.initialize();
  }

  /**
   * Render email alert unsubscribe view
   */
  private renderEmailAlertUnsubscribe(): void {
    this.emailAlertUnsubscribeView.render();
  }
}
