import { render } from 'preact';

import { preactHtmlToVdom } from 'common/helper/preact/html/to-vdom';
import { PreactMarkupComponentsInterface } from 'common/helper/preact/markup/components.interface';

/**
 * Helper function to transform dom into virtual dom and render it via preact
 *
 * @param container - container element.
 * First child that is instance of HTMLElement will be transformed into virtual dom
 * @param components - custom components within container
 */
export const preactMarkup = (container: HTMLElement, components: PreactMarkupComponentsInterface = {}) => {
  if (!container) {
    return;
  }

  const containerFirstElementChild = container.children[0];

  if (!containerFirstElementChild) {
    return;
  }

  const vDom = preactHtmlToVdom({
    element: containerFirstElementChild,
    components,
    componentNameAttribute: 'data-qs',
  });

  render(vDom, container, containerFirstElementChild);
};
