export const enum StatsTealiumDataLayerEventCategoryEnum {
  /**
   * User category
   */
  user = 'user',

  /**
   * conversion category
   */
  conversion = 'conversion',

  /**
   * product feature category
   */
  productFeature = 'product_feature',

  /**
   * user-interaction
   */
  userInteraction = 'user interaction',

  /**
   * Serp filter suggestion experiment variant e
   */
  serpFilterSuggestionVariantE = 'serp_filter_suggestion_variant_e',

  /**
   * Serp filter suggestion experiment variant g
   */
  serpFilterSuggestionVariantG = 'serp_filter_suggestion_variant_g',

  // TODO-FE CX-920
  /**
   * Search box experiment variant b
   */
  searchBoxTestVariantB = 'search_box_test_variant_b',

  /**
   * Home page clicks outside search area
   */
  homePageClickOutideSearchArea = 'non_search_box_click',

  /**
   * Autocomplete selection
   */
  autocompleteSelection = 'text_search',

  /**
   * Authentication
   */
  authentication = 'authentication',

  /**
   * Filter search
   */
  filterSearch = 'filter_search',

  /**
   * Sorted by
   */
  sortBy = 'properties_sort_by',

  /**
   * Save search
   */
  saveSearch = 'saved_search',

  /**
   * Recent searches experiment variant b
   */
  recentSearchesVariantB = 'hp_recent_search_variant_b',

  /**
   * Recent searches experiment variant c
   */
  recentSearchesVariantC = 'hp_recent_search_variant_c',

  /**
   * Click on property card
   */
  propertyClick = 'property_click',

  /**
   * mortgage
   */
  mortgage = 'mortgage',

  /**
   * Email alert signup
   */
  emailAlertSignup = 'email_alert_signup',

  view = 'view',

  /**
   * Agent Hub
   */
  agentHub = 'agent_hub',

  /**
   * Something new everyday
   */
  somethingNew = 'something_new',

  productHelp = 'product_help',

  findAgentPage = 'find_agent_page',

  /**
   * GA event category for saved properties
   */
  savedProperties = 'saved_properties',

  DATA_FEATURE = 'data_feature',

  ecommerce = 'ecommerce',

  /**
   * App banner
   */
  appBanner = 'app_banner',

  towerInsights = 'tower_insights',

  dataGuru = 'data_guru',

  communityInsights = 'community_insights',

  whereCanILive = 'explore_where_to_live',

  bannerClick = 'banner_click',

  /**
   * Marketing
   */
  marketingCampaings = 'marketing_campaigns',

  navigationClicks = 'navigation_clicks',

  bottomNavigationMenu = 'navigation_menu',
  reportProperty = 'report_property',
  search = 'search',
  historicalTransactions = 'historical_transactions',

  bannerActivity = 'banner_activity',

  floorPlanClicks = 'floor_plan_clicks',
}
