import { MyAccountSavedPropertiesStatechartStateEnum } from 'common/module/my-account/saved-properties/statechart/state.enum';
import { MyAccountStatechartEvent } from 'common/module/my-account/statechart.event';
import { MyAccountStatechartActionEnum } from 'common/module/my-account/statechart/action.enum';

export const MyAccountSavedPropertiesStatechart = {
  initial: MyAccountSavedPropertiesStatechartStateEnum.idle,
  states: {
    [MyAccountSavedPropertiesStatechartStateEnum.idle]: {
      on: {
        [MyAccountStatechartEvent.fetchSavedProperties]: MyAccountSavedPropertiesStatechartStateEnum.start,
      },
    },
    [MyAccountSavedPropertiesStatechartStateEnum.start]: {
      onEntry: [MyAccountStatechartActionEnum.fetchSavedProperties],
      on: {
        [MyAccountStatechartEvent.fetchSavedProperties]: {
          target: 'start',
          actions: [MyAccountStatechartActionEnum.cancelPreviousFetch],
        },
      },
    },
  },
};
