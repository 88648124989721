import { AuthenticationTokenUuid } from 'pf-frontend-common/src/module/authentication/token/uuid';
import { StatsContexterServiceInterface } from 'pf-frontend-common/src/service/stats-contexter/service.interface';
import { StatsDebuggerServiceInterface } from 'pf-frontend-common/src/service/stats-debugger/service.interface';

import { ApplicationFeatureEnum } from 'common/module/application/feature.enum';
import { ApplicationFeatureEvent } from 'common/module/application/feature.event';
import { StatsConfiguratorOptionsInterface } from 'common/module/stats/configurator-options.interface';
import { ApplicationFeatureServiceInterface } from 'common/service/application-feature/service.interface';

export class StatsConfigurator {
  /**
   * Constructor
   */
  constructor(
    private statsDebuggerService: StatsDebuggerServiceInterface,
    private statsContexterService: StatsContexterServiceInterface,
    private applicationFeatureService: ApplicationFeatureServiceInterface,
    private authenticationTokenUuid: AuthenticationTokenUuid
  ) {}

  /**
   * Initialize instance
   */
  public initialize(options: StatsConfiguratorOptionsInterface): void {
    // Initialize stats contexter
    this.statsContexterService.initialize({
      authentication: {
        token: this.authenticationTokenUuid.getToken(),
      },
      application: options.environment.platform,
      country: options.country.code,
      currency: options.country.currencyCode,
      language: options.language.current,
    });

    // Activate/deactivate stats debugger
    this.statsDebuggerService.activate(this.applicationFeatureService.isActive(ApplicationFeatureEnum.statsdebug));

    // Attach observers
    this.observers();
  }

  /**
   * Attach observers
   */
  private observers(): void {
    // Application feature
    this.applicationFeatureService.getEventEmitter().addListener(ApplicationFeatureEvent.toggle, this.onToggleFeature);
  }

  /**
   * Toggled a feature
   */
  private onToggleFeature = (feature: ApplicationFeatureEnum, isActive: boolean): void => {
    // Not statsdebug feature
    if (feature !== ApplicationFeatureEnum.statsdebug) {
      return;
    }

    // Activate/deactivate feature
    this.statsDebuggerService.activate(isActive);
  };
}
