import { FunctionalComponent, h } from 'preact';

import { IconTemplatePropsInterface } from 'common/module/icon/template-props.interface';

// tslint:disable:max-line-length

export const IconChevronTemplate: FunctionalComponent<IconTemplatePropsInterface> = (props) => (
  <svg viewBox='4.5 8 11 6' className={props.class}>
    <path
      fillRule='evenodd'
      d='M4.646 8.9l4.47 4.714c.488.515 1.28.515 1.768 0l4.47-4.714a.548.548 0 000-.746.482.482 0 00-.708 0l-4.47 4.714a.241.241 0 01-.353 0l-4.47-4.714a.482.482 0 00-.707 0 .548.548 0 000 .746z'
    />
  </svg>
);
