import { EventEmitterInterface } from 'pf-frontend-common/dist/module/event/emitter.interface';
import { BrowserDocumentServiceInterface } from 'pf-frontend-common/dist/service/browser-document/service.interface';

import { BackendConfigEnvInterface } from 'common/data/backend/config/env.interface';
import { BackendConfigEnvPlatformEnum } from 'common/data/backend/config/env-platform.enum';
import { PropertyModel } from 'common/data/property/model';
import { PropertyContactedModel } from 'common/data/property-contacted/model';
import { PropertyContactedTypeEnum } from 'common/data/property-contacted/type.enum';
import { dateToIso } from 'common/helper/date/to-iso';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { CaptchaStorageStore } from 'common/module/captcha-storage/store';
import { DataStore } from 'common/module/data/store';
import { GoogleRecaptchaViewStoreEvent } from 'common/module/google/recaptcha/view-store.event';
import { PropertyCtaInteractionEvent } from 'common/module/property-cta/interaction.event';
import { PropertyCtaStateInterface } from 'common/module/property-cta/state.interface';
import { PropertyCtaStatechartEvent } from 'common/module/property-cta/statechart.event';
import { PropertyCtaStatechartActionEnum } from 'common/module/property-cta/statechart/action.enum';
import { PropertyCtaStatechartPayloadInterface } from 'common/module/property-cta/statechart/payload.interface';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { CaptchaServiceInterface } from 'common/service/captcha/service.interface';
import { PropertyContactedStoreServiceInterface } from 'common/service/property-contacted-store/service.interface';

export class PropertyCtaStore extends DataStore {
  /**
   * @inheritdoc
   */
  protected state: PropertyCtaStateInterface;

  /**
   * Captcha local storage key
   */
  private captchaStorageKey: string = 'e47ee0f4cbd';

  /**
   * Captcha validation local storage key
   */
  private captchaValidationStorageKey: string = 'A54AFF0';

  /**
   * Statechart payload
   */
  private payload: PropertyCtaStatechartPayloadInterface = null;

  private propertyModel: PropertyModel;

  /**
   * Constructor
   */
  constructor(
    eventEmitter: EventEmitterInterface,
    private propertyContactedStoreService: PropertyContactedStoreServiceInterface,
    private propertyCtaStatechartStore: StatechartStoreInterface,
    private captchaService: CaptchaServiceInterface,
    private captchaStorageService: CaptchaStorageStore,
    private browserDocumentService: BrowserDocumentServiceInterface,
    private envConfig: BackendConfigEnvInterface
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritdoc
   */
  public initialize(): void {
    this.state = {
      isCallLoading: false,
      isWhatsAppLoading: false,
      isDrawerVisible: false,
      currentClickedButton: undefined,
      getLoginDrawerProps: this.getLoginDrawerProps,
      getLoginDrawerReference: this.getLoginDrawerReference,
      handleActionButtonClick: this.handleActionButtonClick,
      handleLoginDrawerClose: this.handleLoginDrawerClose,
      setPropertyModel: this.setPropertyModel,
    };

    this.observers();
  }

  /**
   * @inheritdoc
   */
  public getState(): PropertyCtaStateInterface {
    return this.state;
  }

  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * Save property to contacted properties list
   */
  public saveToContacted(propertyId: string, type: PropertyContactedTypeEnum): void {
    this.propertyContactedStoreService.save(propertyId, type);

    const contactedModel = new PropertyContactedModel();

    contactedModel.property_id = parseInt(propertyId, 10);
    contactedModel.contact_date = dateToIso(new Date());
    contactedModel.contact_type = type;

    this.propertyContactedStoreService.saveInDb(contactedModel);
  }

  private setPropertyModel = (propertyModel: PropertyModel): void => {
    this.propertyModel = propertyModel;
  };

  private getEventDetails = (): PropertyCtaStatechartEvent | '' => {
    return this.state.currentClickedButton === 'call'
      ? PropertyCtaStatechartEvent.clickCtaCallButton
      : this.state.currentClickedButton === 'whatsapp'
      ? PropertyCtaStatechartEvent.clickCtaWhatsAppButton
      : this.state.currentClickedButton === 'email'
      ? PropertyCtaStatechartEvent.clickCtaEmailButton
      : '';
  };

  private getLoginDrawerReference = (): {
    reference: StatsTealiumDataLayerEventReferenceEnum;
  } => {
    switch (this.state.currentClickedButton) {
      case 'call':
      case 'displayPhone':
        return {
          reference: StatsTealiumDataLayerEventReferenceEnum.CALL_LEAD,
        };
      case 'whatsapp':
        return {
          reference: StatsTealiumDataLayerEventReferenceEnum.WHATSAPP_LEAD,
        };
      case 'email':
        return {
          reference: StatsTealiumDataLayerEventReferenceEnum.EMAIL_LEAD,
        };
    }
  };

  private getLoginDrawerProps = (): {
    customModalImage: string;
    customModalTitle: string;
    customModalDescription: string;
  } => {
    switch (this.state.currentClickedButton) {
      case 'call':
      case 'displayPhone':
        return {
          customModalImage: __('assets/common/call.svg'),
          customModalTitle: i18nTranslate('Auth_CallAgentModalTitle'),
          customModalDescription: i18nTranslate('Auth_CallAgentModalDescription').replace(
            '{{name}}',
            this.propertyModel.agent.name
          ),
        };
      case 'whatsapp':
        return {
          customModalImage: __('assets/common/whatsapp.svg'),
          customModalTitle: i18nTranslate('Auth_ContactAgentModalTitle'),
          customModalDescription: i18nTranslate('Auth_ContactAgentModalDescription'),
        };
      case 'email':
        return {
          customModalImage: __('assets/common/email.svg'),
          customModalTitle: i18nTranslate('Auth_EmailAgentModalTitle'),
          customModalDescription: i18nTranslate('Auth_EmailAgentModalDescription'),
        };
    }
  };

  private onDisplayLoginModal = (): void => {
    this.handleActionButtonClick('displayPhone');
  };

  private handleActionButtonClick = (
    actionButton: 'call' | 'whatsapp' | 'email' | 'displayPhone',
    interactionEvent?: PropertyCtaInteractionEvent,
    href?: string
  ): void => {
    this.state.currentClickedButton = actionButton;
    this.setState(this.state);

    const event = this.getEventDetails();

    if (this.state.currentClickedButton === 'displayPhone') {
      this.propertyCtaStatechartStore.getEventEmitter().emit(PropertyCtaStatechartActionEnum.revealPhoneNumber);
    } else {
      this.handleStatechart(event, interactionEvent, href);
    }
  };

  private handleLoginDrawerClose = (): void => {
    this.displayLoginDrawer(false);
  };

  private handleStatechart = (
    event: PropertyCtaStatechartEvent | '',
    interactionEvent?: PropertyCtaInteractionEvent,
    href?: string
  ): void => {
    if (this.state.currentClickedButton === 'call') {
      window.location.href = this.propertyModel.meta.contact_options.details.phone.link;
    }
    this.propertyCtaStatechartStore.transit<PropertyCtaStatechartPayloadInterface>({
      event,
      payload: {
        propertyModel: this.propertyModel,
        ...(interactionEvent ? { interactionEvent } : {}),
        ...(href ? { href } : {}),
      },
    });
  };

  private displayLoginDrawer = (visible: boolean = true): void => {
    this.state.isDrawerVisible = visible;
    this.setState(this.state);
  };

  /**
   * @inheritdoc
   */
  private observers(): void {
    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.saveToContactedViaPhone,
        this.onSaveToContacted(PropertyContactedTypeEnum.call)
      );

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.saveToContactedViaEmail,
        this.onSaveToContacted(PropertyContactedTypeEnum.email)
      );

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.saveToContactedViaCallBack,
        this.onSaveToContacted(PropertyContactedTypeEnum.callBack)
      );

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.saveToContactedViaWhatsApp,
        this.onSaveToContacted(PropertyContactedTypeEnum.whatsApp)
      );

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.showCaptcha, this.onShowCaptchaActionStatechart);

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.hideCaptcha, this.onHideCaptchaActionStatechart);

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.setWhatsAppLoading, this.setIsWhatsAppLoading(true));

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.setPayload, this.onSetPayloadActionStatechart);

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.resetWhatsAppLoading, this.setIsWhatsAppLoading(false));

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.setCallLoading, this.setIsCallLoading(true));

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.resetCallLoading, this.setIsCallLoading(false));

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.checkIfCaptchaNeededWhatsApp,
        this.onCheckIfCaptchaNeededActionStatechart(this.captchaIsNeededWhatsApp, this.captchaIsNotNeededWhatsApp)
      );

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.checkIfCaptchaNeededCall,
        this.onCheckIfCaptchaNeededActionStatechart(this.captchaIsNeededCall, this.captchaIsNotNeededCall)
      );

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.updateCaptchaLocalStorage,
        this.onUpdateCaptchaLocalStorageActionStatechart
      );

    this.captchaService
      .getViewStore()
      .getEventEmitter()
      .addListener(GoogleRecaptchaViewStoreEvent.change, this.onChangeCaptcha);

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(
        PropertyCtaStatechartActionEnum.openNativeWhatsAppPopup,
        this.onOpenNativeWhatsAppPopupActionStatechart
      );

    this.propertyCtaStatechartStore
      .getEventEmitter()
      .addListener(PropertyCtaStatechartActionEnum.displayLoginModal, this.onDisplayLoginModal);
  }

  /**
   * Set whatsapp button loading state
   */
  private setIsWhatsAppLoading = (isWhatsAppLoading: boolean) => (): void => {
    this.state.isWhatsAppLoading = isWhatsAppLoading;
    this.setState(this.state);
  };

  /**
   * Set call button loading state
   */
  private setIsCallLoading = (isCallLoading: boolean) => (): void => {
    this.state.isCallLoading = isCallLoading;
    this.setState(this.state);
  };

  /**
   * Open native whatsapp popup
   */
  private onOpenNativeWhatsAppPopupActionStatechart = (payload: PropertyCtaStatechartPayloadInterface): void => {
    let phone = payload.propertyModel.meta.contact_options.details.whatsapp
      ? payload.propertyModel.meta.contact_options.details.whatsapp.link
      : payload.propertyModel.meta.contact_options.list.whatsapp.link;

    if (payload.submissionId) {
      phone = payload.propertyModel.meta.contact_options.details.developer_whatsapp
        ? payload.propertyModel.meta.contact_options.details.developer_whatsapp.link
        : payload.propertyModel.meta.contact_options.list.developer_whatsapp.link;
      phone = phone.replace('{#formReference}', payload.submissionId);
    }

    this.browserDocumentService.openLink({
      href: phone,
      target: this.envConfig.platform === BackendConfigEnvPlatformEnum.desktop ? '_blank' : '_self',
      rel: 'noopener noreferrer',
    });
  };

  /**
   * Save property to contacted properties list
   */
  private onSaveToContacted =
    (type: PropertyContactedTypeEnum) =>
    (payload: PropertyCtaStatechartPayloadInterface): void => {
      this.saveToContacted(payload.propertyModel.id, type);
    };

  /**
   * Show captcha
   */
  private onShowCaptchaActionStatechart = (): void => {
    this.captchaService.toggle(true);
  };

  /**
   * Hide captcha
   */
  private onHideCaptchaActionStatechart = (): void => {
    this.captchaService.toggle(false);
  };

  /**
   * Set statechart payload
   */
  private onSetPayloadActionStatechart = (payload: PropertyCtaStatechartPayloadInterface): void => {
    this.payload = payload;
  };

  /**
   * Check if captcha validation is needed
   */
  private onCheckIfCaptchaNeededActionStatechart =
    (
      isNeeded: (payload: PropertyCtaStatechartPayloadInterface) => void,
      isNotNeeded: (payload: PropertyCtaStatechartPayloadInterface) => void
    ) =>
    (payload: PropertyCtaStatechartPayloadInterface): void => {
      if (
        this.captchaStorageService.checkIfValid(this.captchaStorageKey) ||
        this.captchaStorageService.checkIfValidated(this.captchaValidationStorageKey)
      ) {
        isNotNeeded(payload);
        return;
      }

      isNeeded(payload);
      this.captchaStorageService.resetIsCaptchaValidated(this.captchaValidationStorageKey);
    };

  /**
   * Captcha changed
   */
  private onChangeCaptcha = (): void => {
    if (this.captchaService.getViewStore().getState().value) {
      this.captchaStorageService.setIsCaptchaValidated(this.captchaValidationStorageKey);
      this.propertyCtaStatechartStore.transit({
        event: PropertyCtaStatechartEvent.captchaExecuted,
        payload: this.payload,
      });
    }
  };

  /**
   * Update captcha local storage
   */
  private onUpdateCaptchaLocalStorageActionStatechart = (): void => {
    this.captchaStorageService.setData(this.captchaStorageKey);
  };

  /**
   * Transit to captcha is not needed for call
   */
  private captchaIsNotNeededCall = (payload: PropertyCtaStatechartPayloadInterface): void => {
    this.propertyCtaStatechartStore.transit({
      event: PropertyCtaStatechartEvent.captchaValidationIsNotNeededCall,
      payload,
    });
  };

  /**
   * Transit to captcha is needed for call
   */
  private captchaIsNeededCall = (payload: PropertyCtaStatechartPayloadInterface): void => {
    this.propertyCtaStatechartStore.transit({
      event: PropertyCtaStatechartEvent.captchaValidationNeededCall,
      payload,
    });
  };

  /**
   * Transit to captcha no needed for WhatsApp
   */
  private captchaIsNotNeededWhatsApp = (payload: PropertyCtaStatechartPayloadInterface): void => {
    this.propertyCtaStatechartStore.transit({
      event: PropertyCtaStatechartEvent.captchaValidationIsNotNeededWhatsApp,
      payload,
    });
  };

  /**
   * Transit to captcha is needed for WhatsApp
   */
  private captchaIsNeededWhatsApp = (payload: PropertyCtaStatechartPayloadInterface): void => {
    this.propertyCtaStatechartStore.transit({
      event: PropertyCtaStatechartEvent.captchaValidationNeededWhatsApp,
      payload,
    });
  };
}
