import { AuthenticationTokenUuidFactory } from 'pf-frontend-common/src/module/authentication/token/uuid.factory';
import { StatsContexterService } from 'pf-frontend-common/src/service/stats-contexter/service';
import { StatsDebuggerService } from 'pf-frontend-common/src/service/stats-debugger/service';

import { StatsConfigurator } from 'common/module/stats/configurator';
import { ApplicationFeatureService } from 'common/service/application-feature/service';

export function StatsConfiguratorFactory(): StatsConfigurator {
  return new StatsConfigurator(
    StatsDebuggerService(),
    StatsContexterService(),
    ApplicationFeatureService(),
    AuthenticationTokenUuidFactory()
  );
}
