import { IconSpriteChevronLeft, IconSpriteChevronRight } from '@propertyfinder/icons';
import { FunctionalComponent, h } from 'preact';

import { configGetEnv } from 'common/helper/config/get-env';
import { configGetLanguage } from 'common/helper/config/get-language';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { MyAccountMenuComponent } from 'common/module/my-account/menu/component';

export const UserAvatarDropdownTemplate: FunctionalComponent<{
  isSavedPropertiesActive: boolean;
  isContactedPropertiesActive: boolean;
  isSavedSearchesActive: boolean;
  isUserLoggedIn: boolean;
}> = (props) => {
  const Icon = configGetLanguage().isRtl ? IconSpriteChevronLeft : IconSpriteChevronRight;

  return (
    <div className='user-avatar__dropdown'>
      <MyAccountMenuComponent forceUpdate={props.isUserLoggedIn ? 1 : 0}>
        <div className='user-menu--dropdown' data-qs='my-account-menu'>
          <div data-qs='user-info' />

          <div className='user-menu__link-area'>
            {props.isUserLoggedIn && (
              <a href={configGetEnv().userDetailsUrl} className={`user-menu__link`}>
                <div>{i18nTranslate('UserMenu_PersonaInformation')}</div>
                <Icon className='user-menu__link-icon' />
              </a>
            )}
            <a
              href={configGetEnv().userAccountSavedPropertiesUrl}
              className={`user-menu__link ${props.isSavedPropertiesActive ? 'user-menu__active' : ''}`}
            >
              <div>
                {i18nTranslate('Saved properties')} (<span data-qs='saved-property' />)
              </div>
              <Icon className='user-menu__link-icon' />
            </a>
            <a
              href={configGetEnv().userAccountContactedPropertiesUrl}
              className={`user-menu__link ${props.isContactedPropertiesActive ? 'user-menu__active' : ''}`}
            >
              <div>
                {i18nTranslate('Contacted properties')} (<span data-qs='contacted-property' />)
              </div>
              <Icon className='user-menu__link-icon' />
            </a>
            <a
              href={configGetEnv().userAccountSavedSearchesUrl}
              className={`user-menu__link ${props.isSavedSearchesActive ? 'user-menu__active' : ''}`}
            >
              <div>
                {i18nTranslate('save_search:user-menu:label')} (<span data-qs='saved-search-count'>0</span>)
              </div>
              <Icon className='user-menu__link-icon' />
            </a>
          </div>
        </div>
      </MyAccountMenuComponent>
    </div>
  );
};
