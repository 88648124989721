import { DefaultContext, EventObject, MachineConfig, StateSchema } from 'xstate/es';

import { PropertyCtaStatechartEvent } from 'common/module/property-cta/statechart.event';
import { PropertyCtaStatechartActionEnum } from 'common/module/property-cta/statechart/action.enum';
import { PropertyCtaStatechartStateEnum } from 'common/module/property-cta/statechart/state.enum';

export const PropertyCtaStatechart: MachineConfig<DefaultContext, StateSchema, EventObject> = {
  type: 'parallel',
  states: {
    [PropertyCtaStatechartStateEnum.ctaIdle]: {
      initial: PropertyCtaStatechartStateEnum.ctaIdle,
      states: {
        [PropertyCtaStatechartStateEnum.ctaIdle]: {
          on: {
            [PropertyCtaStatechartEvent.clickCtaCallBackButton]: {
              target: PropertyCtaStatechartStateEnum.ctaIdle,
              actions: [
                PropertyCtaStatechartActionEnum.saveToContactedViaCallBack,
                PropertyCtaStatechartActionEnum.logStatsCtaCallBackButton,
                PropertyCtaStatechartActionEnum.openCallBackLeadPopup,
              ],
            },
            [PropertyCtaStatechartEvent.clickCtaEmailButton]: {
              target: PropertyCtaStatechartStateEnum.ctaIdle,
              actions: [
                PropertyCtaStatechartActionEnum.logStatsCtaEmailButton,
                PropertyCtaStatechartActionEnum.openEmailLeadPopup,
              ],
            },
            [PropertyCtaStatechartEvent.clickCtaSmsButton]: {
              target: PropertyCtaStatechartStateEnum.ctaIdle,
              actions: [
                PropertyCtaStatechartActionEnum.saveToContactedViaSms,
                PropertyCtaStatechartActionEnum.logStatsCtaSmsButton,
              ],
            },
          },
        },
      },
    },
    [PropertyCtaStatechartStateEnum.whatsapp]: {
      initial: PropertyCtaStatechartStateEnum.whatsappIdle,
      states: {
        [PropertyCtaStatechartStateEnum.whatsappIdle]: {
          on: {
            [PropertyCtaStatechartEvent.captchaValidationNeededWhatsApp]: {
              target: PropertyCtaStatechartStateEnum.clickedOnCtaWhatsApp,
              actions: [
                PropertyCtaStatechartActionEnum.setWhatsAppLoading,
                PropertyCtaStatechartActionEnum.setPayload,
                PropertyCtaStatechartActionEnum.showCaptcha,
              ],
            },
            [PropertyCtaStatechartEvent.captchaValidationIsNotNeededWhatsApp]: {
              target: PropertyCtaStatechartStateEnum.whatsappIdle,
              actions: [
                PropertyCtaStatechartActionEnum.saveToContactedViaWhatsApp,
                PropertyCtaStatechartActionEnum.logStatsCtaWhatsAppButton,
                PropertyCtaStatechartActionEnum.updateCaptchaLocalStorage,
                PropertyCtaStatechartActionEnum.openNativeWhatsAppPopup,
              ],
            },
            [PropertyCtaStatechartEvent.clickCtaWhatsAppButton]: {
              target: PropertyCtaStatechartStateEnum.whatsappIdle,
              actions: [PropertyCtaStatechartActionEnum.checkIfCaptchaNeededWhatsApp],
            },
          },
        },
        [PropertyCtaStatechartStateEnum.clickedOnCtaWhatsApp]: {
          on: {
            [PropertyCtaStatechartEvent.clickCtaCallButton]: {
              target: PropertyCtaStatechartStateEnum.whatsappIdle,
              actions: [
                PropertyCtaStatechartActionEnum.hideCaptcha,
                PropertyCtaStatechartActionEnum.resetWhatsAppLoading,
              ],
            },
            [PropertyCtaStatechartEvent.captchaExecuted]: {
              target: PropertyCtaStatechartStateEnum.whatsappIdle,
              actions: [
                PropertyCtaStatechartActionEnum.hideCaptcha,
                PropertyCtaStatechartActionEnum.saveToContactedViaWhatsApp,
                PropertyCtaStatechartActionEnum.logStatsCtaWhatsAppButton,
                PropertyCtaStatechartActionEnum.openNativeWhatsAppPopup,
                PropertyCtaStatechartActionEnum.resetWhatsAppLoading,
              ],
            },
          },
        },
      },
    },
    [PropertyCtaStatechartStateEnum.call]: {
      initial: PropertyCtaStatechartStateEnum.callIdle,
      states: {
        [PropertyCtaStatechartStateEnum.callIdle]: {
          on: {
            [PropertyCtaStatechartEvent.captchaValidationNeededCall]: {
              target: PropertyCtaStatechartStateEnum.clickedOnCtaCall,
              actions: [
                PropertyCtaStatechartActionEnum.setCallLoading,
                PropertyCtaStatechartActionEnum.setPayload,
                PropertyCtaStatechartActionEnum.showCaptcha,
              ],
            },
            [PropertyCtaStatechartEvent.captchaValidationIsNotNeededCall]: {
              target: PropertyCtaStatechartStateEnum.callIdle,
              actions: [
                PropertyCtaStatechartActionEnum.saveToContactedViaPhone,
                PropertyCtaStatechartActionEnum.logStatsCtaCallButton,
                PropertyCtaStatechartActionEnum.updateCaptchaLocalStorage,
                PropertyCtaStatechartActionEnum.openAgentInfoPopup,
              ],
            },
            [PropertyCtaStatechartEvent.clickCtaCallButton]: {
              target: PropertyCtaStatechartStateEnum.callIdle,
              actions: [PropertyCtaStatechartActionEnum.checkIfCaptchaNeededCall],
            },
          },
        },
        [PropertyCtaStatechartStateEnum.clickedOnCtaCall]: {
          on: {
            [PropertyCtaStatechartEvent.clickCtaCallButton]: {
              target: PropertyCtaStatechartStateEnum.callIdle,
              actions: [PropertyCtaStatechartActionEnum.hideCaptcha, PropertyCtaStatechartActionEnum.resetCallLoading],
            },
            [PropertyCtaStatechartEvent.captchaExecuted]: {
              target: PropertyCtaStatechartStateEnum.callIdle,
              actions: [
                PropertyCtaStatechartActionEnum.hideCaptcha,
                PropertyCtaStatechartActionEnum.saveToContactedViaPhone,
                PropertyCtaStatechartActionEnum.logStatsCtaCallButton,
                PropertyCtaStatechartActionEnum.openPhoneLeadPopup,
                PropertyCtaStatechartActionEnum.resetCallLoading,
              ],
            },
          },
        },
      },
    },
  },
};
