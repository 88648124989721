import { DataKeyValueStringInterface } from 'pf-frontend-common/src/module/data/key-value/string.interface';
import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';
import { HttpApiResponseInterface } from 'pf-frontend-common/src/module/http/api-response.interface';
import { JsonApiPayloadInterface } from 'pf-frontend-common/src/module/json-api/payload.interface';
import { JsonApiStore } from 'pf-frontend-common/src/module/json-api/store';

import { BrokerModel } from 'common/data/broker/model';
import { BrokerStoreEvent } from 'common/data/broker/store.event';
import { BrokerStoreGetAllByNameInputInterface } from 'common/data/broker/store/get-all-by-name/input.interface';
import { BrokerStoreSearchInputInterface } from 'common/data/broker/store/search/input.interface';
import { BrokerStoreSearchOutputInterface } from 'common/data/broker/store/search/output.interface';
import { BrokerStoreOptionsInterface } from 'common/data/broker/store-options.interface';
import { jsonApiFlatParams } from 'common/helper/json-api/flat-params';
import { objectFilterNonOrEmptyValue } from 'common/helper/object/filter/non-or-empty-value';
import { stringEscape } from 'common/helper/string/escape';
import { CookieAuthenticatorApiServiceInterface } from 'common/module/cookie-authenticator/api-service.interface';
import { DataStorePaginationAdapter } from 'common/module/data/store/pagination-adapter';
import { JsonApiMetaPaginationInterface } from 'common/module/json-api/meta/pagination.interface';
import { ApiEndpointServiceInterface } from 'common/service/api-endpoint/service.interface';
import { BrokerStoreServiceInterface } from 'common/service/broker-store/service.interface';

export class BrokerStore implements BrokerStoreServiceInterface {
  /**
   * API endpoints to connect with the backend
   */
  private endpoint: { [key: string]: DataKeyValueStringInterface } = {
    search: {
      path: this.apiEndpointService.getPath('/broker'),
      method: 'GET',
    },
  };

  /**
   * Constructor
   */
  constructor(
    private eventEmitter: EventEmitterInterface,
    private apiService: CookieAuthenticatorApiServiceInterface,
    private apiEndpointService: ApiEndpointServiceInterface,
    private jsonApiStore: JsonApiStore<BrokerModel>
  ) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public initialize(options: BrokerStoreOptionsInterface): void {
    // From models
    if (options.models) {
      options.models.forEach((brokerModel) => this.jsonApiStore.add(brokerModel));
    }

    // From JsonApi payload
    if (options.payload) {
      this.jsonApiStore.syncWithMeta(options.payload);
    }
  }

  /**
   * @inheritDoc
   */
  public reset(): void {
    this.jsonApiStore.reset();
  }

  /**
   * @inheritDoc
   */
  public add(model: BrokerModel): void {
    this.jsonApiStore.add(model);
  }

  /**
   * @inheritDoc
   */
  public getOneById(id: string): BrokerModel | null {
    return this.jsonApiStore.find(id);
  }

  /**
   * @inheritDoc
   */
  public getAll(): BrokerModel[] {
    return this.jsonApiStore.findAll();
  }

  /**
   * @inheritDoc
   */
  public getAllByName(input: BrokerStoreGetAllByNameInputInterface): BrokerModel[] {
    const name = stringEscape(input.name.trim());

    // No name provided
    if (!name) {
      return [];
    }

    // Filter loaded brokers
    const brokerModels = this.getAll().filter((brokerModel) =>
      brokerModel.name.match(new RegExp(`\\s${name}[^\\s]*|^${name}[^\\s]*`, 'gi'))
    );

    // Limit results
    return input.limit ? brokerModels.slice(0, input.limit) : brokerModels;
  }

  /**
   * @inheritDoc
   */
  public search(input: BrokerStoreSearchInputInterface): Promise<BrokerStoreSearchOutputInterface> {
    // Emit event: start
    this.getEventEmitter().emit(BrokerStoreEvent.searchStart);

    return new Promise((resolve, reject) => {
      // API request
      this.apiService
        .request(
          this.endpoint.search.method,
          this.endpoint.search.path,
          objectFilterNonOrEmptyValue(jsonApiFlatParams(input))
        )
        .then((httpResponse: HttpApiResponseInterface) => {
          // Get specialized output
          const output = this.getSearchOutput(<JsonApiPayloadInterface>httpResponse.data);

          // Emit event: success
          this.getEventEmitter().emit(BrokerStoreEvent.searchSuccess, output);

          // Resolve promise
          resolve(output);
        })
        .catch((httpResponse: HttpApiResponseInterface) => {
          // Emit event: failure
          this.getEventEmitter().emit(BrokerStoreEvent.searchFailure, httpResponse);

          // Reject promise
          reject(httpResponse);
        });
    });
  }

  /**
   * @inheritDoc
   */
  public getSearchOutput(payload: JsonApiPayloadInterface): BrokerStoreSearchOutputInterface {
    // Get JSONAPI data & meta, while warming up the store
    const sync = this.jsonApiStore.syncWithMeta(payload);

    return {
      brokers: Array.isArray(sync.data) ? <BrokerModel[]>sync.data : [],
      pagination: new DataStorePaginationAdapter(<JsonApiMetaPaginationInterface>sync.meta).getData(),
    };
  }
}
