import { UserModel } from 'common/data/user/model';
import { StatsTealiumDataLayerEventActionEnum } from 'common/module/stats/tealium/data-layer/event-action.enum';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumDataLayerEventTypeEnum } from 'common/module/stats/tealium/data-layer/event-type.enum';
import { StatsTealiumDataLayerTealiumEventEnum } from 'common/module/stats/tealium/data-layer/tealium-event.enum';
import { StatsTealiumDataLayerUserEventInterface } from 'common/module/stats/tealium/data-layer/user-event.interface';
import { UserPopupLoginStatusEnum } from 'common/module/user/popup/login-status.enum';

export const UserSigninStatsTealiumUserLogOut = (userModel: UserModel): StatsTealiumDataLayerUserEventInterface => ({
  tealium_event: StatsTealiumDataLayerTealiumEventEnum.userLogout,
  event_type: StatsTealiumDataLayerEventTypeEnum.click,
  event_label: '',
  event_category: StatsTealiumDataLayerEventCategoryEnum.user,
  event_action: StatsTealiumDataLayerEventActionEnum.signOut,
  user_id: `${userModel.id}` || '',
  user_status: UserPopupLoginStatusEnum.notLoggedIn,
  user_email: userModel.email || '',
});
