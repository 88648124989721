import { domQuerySelectorAll } from 'pf-frontend-common/src/helper/dom/query-selector-all';
import { WindowService } from 'pf-frontend-common/src/service/window/service';
import { WindowLocationService } from 'pf-frontend-common/src/service/window-location/service';

import { configGetSettings } from 'common/helper/config/get-settings';
import { LanguageSelectorView } from 'common/module/language-selector/view';

const selectorElements = domQuerySelectorAll(
  WindowService().getNative().document.body,
  '[data-qs="language-selector"]'
);

const view = new LanguageSelectorView(WindowLocationService(), configGetSettings());

view.initialize({
  element: null,
  elements: selectorElements,
});

export function LanguageSelectorService(): LanguageSelectorView {
  return view;
}
