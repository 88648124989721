import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class FilterModel extends JsonApiModel {
  /**
   * ID
   */
  public id: string;

  /**
   * Value
   */
  public value: string;

  /**
   * Filter label
   */
  public name: string;

  /**
   * Fields
   */
  public fields?: Array<{
    id: string;
    value: string;
  }>;
}
