import { Fragment, FunctionalComponent, h } from 'preact';

import { domClassMerge } from 'common/helper/dom/class-merge';

import { ChipComponent } from '../chip/component';
import { ChipFilterPropsInterface } from './props.interface';

export const ChipFilterTemplate: FunctionalComponent<ChipFilterPropsInterface> = (props) => {
  const ItemComponent = props.component;
  const Icon = props.selectedIcon;
  const WrapperComponent = props.isList ? 'ul' : Fragment;

  return (
    <WrapperComponent className={props.className}>
      {props.options.map((option, id) => {
        const isSelected =
          props.selected.length && !!props.selected.find((selected) => selected.value === option.value);

        return (
          <ItemComponent className={domClassMerge('chip-filter__item', props.classNameItem)} key={props.name + id}>
            <input
              key={`${props.name}-${id}`}
              type='checkbox'
              autoComplete='off'
              className='chip-filter__input'
              disabled={option.isDisabled}
              id={option.value}
              value={option.value}
              name={props.name}
              checked={isSelected}
            />
            <ChipComponent
              label={option.text}
              htmlFor={option.value}
              className={props.chipClassName}
              prefixIcon={
                props.showIcon && isSelected && props.iconPosition === 'start' ? (
                  <Icon class='chip-filter__item-icon' />
                ) : null
              }
              suffixIcon={
                props.showIcon && isSelected && props.iconPosition === 'end' ? (
                  <Icon class='chip-filter__item-icon' />
                ) : null
              }
              isSelected={isSelected}
              isDisabled={option.isDisabled}
              onClick={(e) => {
                // Check if element already selected
                const selected = props.selected.filter((item) => item.value !== option.value);

                props.onCheck(e, selected.length >= props.selected.length ? [...props.selected, option] : selected);
              }}
            />
          </ItemComponent>
        );
      })}
    </WrapperComponent>
  );
};
