import { useEffect, useState } from 'preact/hooks';

import { LocationStoreEvent } from 'common/data/location/store.event';
import { AutocompleteCacheLocationsService } from 'common/service/autocomplete-cache-locations/service';
import { LocationStoreService } from 'common/service/location-store/service';

import { asyncDebounce } from '../async/debounce';

/**
 * Returns the locationStoreService.
 * Will rerender the component when location store service underlying data is changed.
 * Initial state of location store service is always empty, it is populated asyncronously
 */
export const useLocationStoreService = () => {
  // This store will contain partial locations map populated
  // from different places in autocomplte view store and decorators
  // Eventually it will have all the locations from the autocompleteCacheLocationStore
  const locationStoreService = LocationStoreService();
  // This store will be eventually populated with data from indexed DB
  const autocompleteCacheLocationStore = AutocompleteCacheLocationsService().getDataStore();

  // counter is used to rerender component when the location store underlying data changes.
  const [, setCounter] = useState(0);

  useEffect(() => {
    // Since the locations are loaded in batches of 500 from indexedDB location store is updated multiple times.
    // Debouncing the update to minimize the number of unnecessery rerenders.
    // TODO-FE[TPNX-2145] remove the debounce logic
    const onLocationUpdate = asyncDebounce(() => setCounter((current) => current + 1), 250);
    autocompleteCacheLocationStore.getEventEmitter().addListener(LocationStoreEvent.updated, onLocationUpdate);

    return () =>
      autocompleteCacheLocationStore.getEventEmitter().removeListener(LocationStoreEvent.updated, onLocationUpdate);
  }, []);

  if (locationStoreService.getAll().length) {
    return locationStoreService;
  }

  return autocompleteCacheLocationStore;
};
