import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class UserModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'user';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserModel> = ['first_name', 'last_name', 'image', 'email', 'phone'];

  // tslint:disable:variable-name
  /**
   * First name
   */
  public first_name: string = '';

  /**
   * Last name
   */
  public last_name: string = '';

  // tslint:enable:variable-name

  /**
   * Email
   */
  public email: string = '';

  /**
   * Image
   */
  public image: string = '';

  public phone: string = '';

  /**
   * If user exist this flag will be true
   */
  public existing_user: boolean = false;
}
