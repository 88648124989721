import { BrowserDocumentServiceInterface } from '@propertyfinder/pf-frontend-common/dist/service/browser-document/service.interface';
import { WindowServiceInterface } from '@propertyfinder/pf-frontend-common/src/service/window/service.interface';

import { LoginSocialProviderEnum } from 'common/data/login-social/provider.enum';
import { LoginSocialStore } from 'common/data/login-social/store';
import { UserFrontendLoginProviderInterface } from 'common/data/user/frontend/login-provider.interface';

import { UserFrontendLoginModel } from '../login/model';
import { UserFrontendLoginProviderTypeEnum } from './type.enum';

export class UserFrontendLoginProviderFacebook implements UserFrontendLoginProviderInterface {
  /**
   * @inheritdoc
   */
  public initialization: UserFrontendLoginProviderTypeEnum = UserFrontendLoginProviderTypeEnum.manual;

  /**
   * API version
   */
  private readonly apiVersion: string = 'v7.0';

  /**
   * Script url
   */
  private readonly scriptUrl: string = 'https://connect.facebook.net/en_US/sdk.js';

  /**
   * User scope
   */
  private readonly scope: string = 'public_profile,email';

  /**
   * Constructor
   */
  public constructor(
    private loginSocialStore: LoginSocialStore,
    private browserDocumentService: BrowserDocumentServiceInterface,
    private windowService: WindowServiceInterface,
    private appId: string
  ) {
    this.preLoad();
  }

  /**
   * @inheritdoc
   */
  public signIn(): Promise<UserFrontendLoginModel> {
    return new Promise((resolve, reject) => {
      const facebook = this.windowService.getNative().FB;
      facebook.init({
        appId: this.appId,
        version: this.apiVersion,
      });
      facebook.login(this.onLogin(resolve, reject), { scope: this.scope });
    });
  }

  /**
   * Handle login
   */
  private onLogin =
    (resolve: (model: UserFrontendLoginModel) => void, reject: () => void) => (response: fb.StatusResponse) => {
      if (response.authResponse) {
        this.loginSocialStore
          .fetchUser(LoginSocialProviderEnum.facebook, response.authResponse.accessToken)
          .then(resolve)
          .catch(reject);
      } else {
        reject();
      }
    };

  /**
   * Preload script to fix safari first click popup block issue
   */
  private preLoad = async () => {
    return this.browserDocumentService.importScript(this.scriptUrl, true);
  };
}
