import { EventEmitterInterface } from 'pf-frontend-common/dist/module/event/emitter.interface';

import { MyNotesModel } from 'common/data/my-notes/model';
import { PropertyModel } from 'common/data/property/model';
import { PropertyStore } from 'common/data/property/store';
import { PropertyStoreSearchOutputInterface } from 'common/data/property/store/search/output.interface';
import { DataStore } from 'common/module/data/store';
import { MyAccountListTypeEnum } from 'common/module/my-account/list-type.enum';
import { MyAccountStatechartEvent } from 'common/module/my-account/statechart.event';
import { MyAccountStatechartActionEnum } from 'common/module/my-account/statechart/action.enum';
import { MyAccountStoreStateInterface } from 'common/module/my-account/store-state.interface';
import { StatechartStoreInterface } from 'common/module/statechart/store.interface';
import { StatsTealiumDataLayerEventCategoryEnum } from 'common/module/stats/tealium/data-layer/event-category.enum';
import { StatsTealiumProviderInterface } from 'common/module/stats/tealium/provider.interface';
import { UserAuthenticationEvent } from 'common/module/user/authentication.event';
import { MyNotesServiceInterface } from 'common/service/my-notes/service.interface';
import { PropertyStoreServiceInterface } from 'common/service/property-store/service.interface';
import { UserAuthenticationServiceInterface } from 'common/service/user-authentication/service.interface';

export class MyAccountStore extends DataStore {
  /**
   * @inheritDoc
   */
  protected state: MyAccountStoreStateInterface;

  /**
   * Constructor
   */
  public constructor(
    eventEmitter: EventEmitterInterface,
    private myAccountStatechartStore: StatechartStoreInterface,
    private myNotesService: MyNotesServiceInterface,
    private propertyStore: PropertyStore,
    private propertyStoreService: PropertyStoreServiceInterface,
    private userAuthenticationService: UserAuthenticationServiceInterface,
    private statsTealium: StatsTealiumProviderInterface
  ) {
    super(eventEmitter);
  }

  /**
   * @inheritDoc
   */
  public initialize(): void {
    this.state = {
      notes: {},
      properties: this.propertyStore,
    };

    this.observers();
  }

  /**
   * @inheritDoc
   */
  public getState(): MyAccountStoreStateInterface {
    return this.state;
  }

  /**
   * Delete a note
   */
  public deleteNote(id: string): void {
    this.myAccountStatechartStore.transit({
      event: MyAccountStatechartEvent.deleteNote,
      payload: id,
    });
  }

  /**
   * Delete all note from backend
   */
  public deleteAllNotesFromApiAction(): void {
    this.myNotesService.deleteAll();
    this.state.notes = {};
    this.setState(this.state);
  }

  /**
   * Sort properties by note date
   */
  public sortPropertiesByNoteModifiedDate(collection: PropertyModel[]): PropertyModel[] {
    const notesProperties: MyNotesModel[] = Object.keys(this.state.notes)
      .map((propertyId) => this.state.notes[propertyId])
      .sort((a: MyNotesModel, b: MyNotesModel) => {
        return new Date(b.modified_date).getTime() - new Date(a.modified_date).getTime();
      });

    const properties: PropertyModel[] = notesProperties.map((notesModel: MyNotesModel) => {
      return collection.find((collectionModel: PropertyModel) => {
        return parseInt(collectionModel.id, 10) === notesModel.property_id;
      });
    });

    return properties.filter((property) => {
      return property !== undefined;
    });
  }

  /**
   * Attach listeners
   */
  private observers(): void {
    this.myAccountStatechartStore
      .getEventEmitter()
      .addListener(MyAccountStatechartActionEnum.updateNotesInStore, this.onUpdateNotesInStoreStatechartAction);

    // Fetching notes
    this.myAccountStatechartStore
      .getEventEmitter()
      .addListener(MyAccountStatechartActionEnum.fetchNotes, this.onFetchNotesAction);

    // Fetching notes properties
    this.myAccountStatechartStore
      .getEventEmitter()
      .addListener(MyAccountStatechartActionEnum.fetchNotesProperties, this.onFetchNotesPropertiesAction);

    this.myAccountStatechartStore
      .getEventEmitter()
      .addListener(MyAccountStatechartActionEnum.deleteNoteFromBackend, this.onDeleteNoteFromBackendAction);

    this.myAccountStatechartStore
      .getEventEmitter()
      .addListener(MyAccountStatechartActionEnum.deleteNoteFromStore, this.onDeleteNoteFromStoreAction);

    this.userAuthenticationService
      .getEventEmitter()
      .addListener(UserAuthenticationEvent.signInSucceeded, this.onSignInSucceeded);
  }

  /**
   * Update notes in store state (propertyId: note)
   */
  private onUpdateNotesInStoreStatechartAction = (notes: MyNotesModel[]) => {
    this.state.notes = {};

    notes.forEach((note) => (this.state.notes[note.property_id] = note));

    this.setState(this.state);
  };

  /**
   * Fetch my notes
   */
  private onFetchNotesAction = () => {
    this.myNotesService.loadAll().then((notes: MyNotesModel[]) => {
      this.myAccountStatechartStore.transit({
        event: MyAccountStatechartEvent.fetchNotesSuccess,
        payload: notes,
      });
    });
  };

  /**
   * Fetch notes properties
   */
  private onFetchNotesPropertiesAction = (notes: MyNotesModel[]) => {
    if (!notes.length) {
      this.myAccountStatechartStore.transit({
        event: MyAccountStatechartEvent.fetchNotesPropertiesSuccess,
        payload: null,
      });

      return;
    }

    this.propertyStore
      .search({
        filter: {
          ids: Object.keys(this.state.notes),
          allow_expired: true,
        },
      })
      .then(this.onSearchPropertiesFinished);
  };

  /**
   * Delete note from backend
   */
  private onDeleteNoteFromBackendAction = (noteId: string) => {
    this.myNotesService.deleteById(noteId);
  };

  /**
   * Delete note from store
   */
  private onDeleteNoteFromStoreAction = (noteId: string) => {
    const propertyId = this.myNotesService.find(noteId).property_id;

    delete this.state.notes[propertyId];

    this.setState(this.state);
  };

  /**
   * Search properties finished
   */
  private onSearchPropertiesFinished = (searchOutput: PropertyStoreSearchOutputInterface) => {
    this.myAccountStatechartStore.transit({
      event: MyAccountStatechartEvent.fetchNotesPropertiesSuccess,
      payload: null,
    });

    if (!searchOutput) {
      return;
    }

    const sortPropertiesByDescribedDate: PropertyModel[] = this.sortPropertiesByNoteModifiedDate(
      searchOutput.properties
    );

    // Add properties to property store global service
    this.propertyStoreService.initialize({
      models: sortPropertiesByDescribedDate,
    });

    this.myAccountStatechartStore.transit({
      event: MyAccountStatechartEvent.fetchPropertiesSuccess,
      payload: {
        listType: MyAccountListTypeEnum.notes,
        properties: sortPropertiesByDescribedDate,
      },
    });
  };

  /**
   * When user sign in successfully
   */
  private onSignInSucceeded = () => {
    this.myAccountStatechartStore.transit({
      event: MyAccountStatechartEvent.fetchNotesProperties,
      payload: {},
    });
  };

  public onSaveSearchTabClicked(): void {
    this.statsTealium.send({
      event_category: StatsTealiumDataLayerEventCategoryEnum.saveSearch,
      event_action: 'tab_clicked',
      tealium_event: 'tab_clicked',
      sendToGa: true,
    });
  }

  public onCreateAlertClicked(): void {
    this.statsTealium.send({
      event_category: StatsTealiumDataLayerEventCategoryEnum.saveSearch,
      event_action: 'start_new_search_clicked',
      tealium_event: 'start_new_search_clicked',
      sendToGa: true,
    });
  }

  public onFrequencyClicked(): void {
    this.statsTealium.send({
      event_category: StatsTealiumDataLayerEventCategoryEnum.saveSearch,
      event_action: 'receive_updates-setting_clicked',
      tealium_event: 'receive_updates-setting_clicked',
      sendToGa: true,
    });
  }

  public onFrequencyChanged(): void {
    this.statsTealium.send({
      event_category: StatsTealiumDataLayerEventCategoryEnum.saveSearch,
      event_action: 'receive_updates_setting_selected',
      tealium_event: 'receive_updates_setting_selected',
      sendToGa: true,
    });
  }

  public onActionsClicked(): void {
    this.statsTealium.send({
      event_category: StatsTealiumDataLayerEventCategoryEnum.saveSearch,
      event_action: 'option_clicked',
      tealium_event: 'option_clicked',
      sendToGa: true,
    });
  }
}
