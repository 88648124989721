import { FunctionalComponent, h } from 'preact';
import { useState } from 'preact/hooks';

import { i18nTranslate } from 'common/helper/i18n/translate';
import { StatsTealiumDataLayerEventReferenceEnum } from 'common/module/stats/tealium/data-layer/event-reference.enum';
import { UserAuthAsyncComponent } from 'common/module/user/auth/async.component';
import { UserInfoTemplatePropsInterface } from 'common/module/user/info/template-props.interface';

import { UserAuthTrackerFactory } from '../auth/tracker.factory';

export const UserInfoTemplate: FunctionalComponent<UserInfoTemplatePropsInterface> = (props) => {
  const [modalVisible, setModalVisible] = useState(false);

  const onClick = (): void => {
    UserAuthTrackerFactory().onLoginModalOpen(StatsTealiumDataLayerEventReferenceEnum.HEADER);
    setModalVisible(true);
  };

  return (
    <div>
      {!props.isLoggedIn ? (
        <div className='user-menu'>
          <div className='user-menu__signin-area'>
            {props.isEnabledMessage && (
              <div className='user-menu__title-text'>
                {i18nTranslate('Sign in or register to sync your favorite properties across devices')}
              </div>
            )}
            <button onClick={onClick} className='button user-menu__button'>
              {i18nTranslate('Sign in')}
            </button>
            <button onClick={onClick} className='user-menu__new-account'>
              {i18nTranslate('Create new account')}
            </button>
          </div>
        </div>
      ) : (
        props.isUserInfoVisible && (
          <div className='user-info' key={'loggedIn'}>
            <div className='user-info__profile-photo'>
              {props.userImage ? (
                <img src={props.userImage} className='user-info__img' alt='' />
              ) : (
                <div className='user-info__placeholder'>
                  <svg className='user-info__placeholder-icon' viewBox='0 0 26 26'>
                    <use xlinkHref='#svg-user-o-filled' />
                  </svg>
                </div>
              )}
            </div>
            <div className='user-info__detail'>
              <div className='user-info__name'>{props.userName}</div>
              <div className='user-info__logout'>
                {props.showActions && <button onClick={props.onClickLogout}>{i18nTranslate('Logout')}</button>}
              </div>
            </div>
          </div>
        )
      )}

      {modalVisible && (
        <UserAuthAsyncComponent
          onSuccess={(): void => setModalVisible(false)}
          onClose={(): void => setModalVisible(false)}
        />
      )}
    </div>
  );
};
