import { functionSelf } from 'common/helper/function/self';
import { FilterParametersInterface } from 'common/module/filter/parameters.interface';
import { FilterParametersFieldOptionsInterface } from 'common/module/filter/parameters/field-options.interface';
import { FilterParamsEnum } from 'common/module/filter/params.enum';
import { StatsGtmDataLayerInterface } from 'common/module/stats/gtm/data-layer.interface';

const mapFilterToEventType: Partial<Record<FilterParamsEnum, string>> = {
  [FilterParamsEnum.categoryId]: 'Category',
  [FilterParamsEnum.propertyTypeId]: 'Property Type',
  [FilterParamsEnum.minPrice]: 'Price Min',
  [FilterParamsEnum.maxPrice]: 'Price Max',
  [FilterParamsEnum.pricePeriod]: 'Price Period',
};

export const propertySerpLightStatsGtmChangedFilter = (
  pageType: string,
  filters: FilterParametersInterface
): StatsGtmDataLayerInterface[] => {
  return (Object.keys(filters) as Array<keyof typeof filters>)
    .map((key) => {
      const eventType = mapFilterToEventType[key];
      const value = (filters[key] as FilterParametersFieldOptionsInterface<string, string>)?.value;

      if (!eventType || value === '') {
        return;
      }

      return {
        event: 'customEvent',
        eventCategory: 'Light Search',
        eventAction: `Light Search - ${eventType} - Click`,
        eventLabel: `${pageType} - Light Search - ${eventType} - Click - ${value}`,
      };
    })
    .filter(functionSelf);
};
