import { Component, h } from 'preact';

import { DropdownWrapperComponentPropsInterface } from 'common/module/dropdown-wrapper/component-props.interface';
import { DropdownWrapperTemplate } from 'common/module/dropdown-wrapper/template';

export class DropdownWrapperComponent extends Component<DropdownWrapperComponentPropsInterface> {
  // tslint:disable-next-line: typedef
  public static readonly defaultProps = {
    actions: [] as DropdownWrapperComponentPropsInterface['actions'],
    showActions: false,
  };

  /**
   * @inheritDoc
   */
  public render(): preact.JSX.Element {
    return <DropdownWrapperTemplate {...this.props} />;
  }
}
