import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';

export class UserResetPasswordModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'user_reset_password';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = UserResetPasswordModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof UserResetPasswordModel> = ['email', 'captcha_token'];

  /**
   * Email
   */
  public email: string = '';

  // tslint:disable:variable-name

  /**
   * Captcha token
   */
  public captcha_token: string = '';

  // tslint:enable:variable-name
}
