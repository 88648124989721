import { BrowserViewportFactory } from 'pf-frontend-common/src/module/browser/viewport.factory';
import { EventEmitterFactory } from 'pf-frontend-common/src/module/event/emitter.factory';
import { StatsAttributionService } from 'pf-frontend-common/src/service/stats-attribution/service';
import { StatsDataService } from 'pf-frontend-common/src/service/stats-data/service';

import { PropertyStatsDataAdapterFactory } from 'common/data/property/stats/data-adapter.factory';
import { configGetSettings } from 'common/helper/config/get-settings';
import { PropertyListViewStore } from 'common/module/property/list/view-store';
import { PropertyCtaStatechartService } from 'common/module/property-cta/statechart/service';
import { PropertyCardInteractionService } from 'common/service/property-card-interaction/service';
import { PropertySavedStoreService } from 'common/service/property-saved-store/service';
import { StatsService } from 'common/service/stats/service';
import { StatsGtmProviderService } from 'common/service/stats-gtm-provider/service';
import { StatsSnowplow2ProviderService } from 'common/service/stats-snowplow2-provider/service';
import { StatsTealiumProviderService } from 'common/service/stats-tealium-provider/service';
import { WhatsappContextService } from 'common/service/whatsapp/context.service';

export function PropertyListViewStoreFactory(): PropertyListViewStore {
  const propertyListViewStore = new PropertyListViewStore(
    EventEmitterFactory(),
    StatsDataService(),
    StatsAttributionService(),
    PropertyStatsDataAdapterFactory(),
    StatsService(),
    StatsGtmProviderService(),
    configGetSettings(),
    BrowserViewportFactory(),
    PropertySavedStoreService(),
    StatsSnowplow2ProviderService(),
    PropertyCtaStatechartService(),
    PropertyCardInteractionService(),
    StatsTealiumProviderService(),
    WhatsappContextService()
  );

  return propertyListViewStore;
}
