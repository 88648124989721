import { numberFormat } from 'common/helper/number/format';
import { stringToNumber } from 'common/helper/string/to-number';

/**
 * Replace number in the pattern with input string.
 */
export function filterSettingsGenerateLabel(input: string, pattern: string): string {
  // TODO-FE[TPNX-2158] - simplify the bellow lines
  const inputNumber = stringToNumber(input);
  const formatedNumber = typeof inputNumber !== 'undefined' ? numberFormat(inputNumber) : '';
  if (!pattern || !formatedNumber) {
    return formatedNumber || input;
  }

  return pattern.replace(/(\d|,)+/, formatedNumber);
}
