import { JsonApiModel } from 'pf-frontend-common/src/module/json-api/model';
import { ListingStatusType } from 'pf-frontend-common/src/module/stats/types';

import { AgentModel } from 'common/data/agent/model';
import { BrokerModel } from 'common/data/broker/model';
import { LocationModel } from 'common/data/location/model';
import { ProjectModel } from 'common/data/project/model';
import { ProjectPropertyModel } from 'common/data/project-property/model';
import { PropertyCategoryIdentifierEnum } from 'common/data/property-category/identifier.enum';
import { PropertyCategoryModel } from 'common/data/property-category/model';
import { PropertyImageModel } from 'common/data/property-image/model';
import { PropertyPriceModel } from 'common/data/property-price/model';
import { PropertyTypeModel } from 'common/data/property-type/model';
import { MapCoordinatesInterface } from 'common/module/map/coordinates.interface';

import { PropertyLiveEventMetadataInterface } from '../property-live-event/metadata.interface';
import { TransactionsModel } from '../transactions/model';

export class PropertyModel extends JsonApiModel {
  /**
   * @inheritDoc
   */
  public static readonly JSONAPI_TYPE: string = 'property';

  /**
   * @inheritDoc
   */
  public jsonApiType: string = PropertyModel.JSONAPI_TYPE;

  /**
   * @inheritDoc
   */
  public jsonApiAttributes: Array<keyof PropertyModel> = [
    'id',
    'name',
    'premium',
    'featured',
    'verified',
    'image',
    'coordinates',
    'property_category',
    'property_type',
    'area',
    'category_id',
    'date_insert',
    'type_id',
    'location_tree_path',
    'default_price',
    'bedroom_name',
    'bedroom_value',
    'bathroom_name',
    'bathroom_value',
    'reference',
    'listing_level',
    'listing_level_label',
    'price_period_label',
    'price_period_identifier',
    'offering_type',
    'offering_type_id',
    'size',
    'size_unit',
    'quality_score',
    'furnished',
    'is_expired',
    'price_on_application',
    'smart_ad',
    'cts',
    'view_360',
    'category_identifier',
    'type_identifier',
    'completion_status',
    'utilities_price_type',
    'rsp',
    'rss',
    'community',
    'location',
    'exclusive',
    'is_price_period_visible',
    'share_url',
    'new_projects',
    'similar_transaction_history',
    'is_broker_project_property',
    'is_new_insert',
    'is_claimed_by_agent',
    'is_under_offer_by_competitor',
    'is_spotlight_listing',
    'payment_method',
    'down_payment_price',
    'lead_value',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiRelationships: Array<keyof PropertyModel> = [
    'agent',
    'broker',
    'location_tree',
    'property_category',
    'property_type',
    'property_images',
    'property_prices',
    'community',
    'location',
    'project',
    'project_property',
  ];

  /**
   * @inheritDoc
   */
  public jsonApiLinks: string[] = ['self', 'self_alternate', 'image_broker', 'image_property'];

  /**
   * @inheritDoc
   */
  public jsonApiMeta: string[] = [
    'subtitle',
    'price',
    'price_text',
    'contact_options',
    'images_count',
    'video_metadata',
    'live_event_metadata',
  ];

  // tslint:disable:variable-name

  /**
   * Property ID
   */
  public id: string;

  /**
   * Property name
   */
  public name: string;

  /**
   * Is the property premium
   */
  public premium: boolean;

  /**
   * Is the property featured
   */
  public featured: boolean;

  /**
   * Is the property offline
   */
  public is_expired: boolean;

  /**
   * Is the property exclusive
   */
  public exclusive?: boolean;

  /**
   * Is the property verified
   */
  public verified: boolean;

  /**
   * Path to the property image
   */
  public image: string;

  /**
   * Property's area with dimensions
   */
  public area: string;

  /**
   * Insertion date
   */
  public date_insert: string;

  /**
   * Share url
   */
  public share_url?: string;

  /**
   * Links
   */
  public links: {
    /**
     * Link to the property
     */
    self: string;

    /**
     * Alternative link to property (in alternate language)
     */
    self_alternate: string;

    /**
     * Broker thumbnail image
     */
    image_broker: string;

    /**
     * Property image
     */
    image_property: string;

    /**
     * Property image small (for serp pages)
     */
    image_property_small: string;

    /**
     * Property image on home page
     */
    image_property_homepage: string;

    /**
     * Floor plan image
     */
    image_property_floor_plan?: string;
  };

  /**
   * @inheritDoc
   */
  public meta: {
    /**
     * Subtitle
     */
    subtitle: string;

    /**
     * Price
     */
    price: number;

    /**
     * Price text
     */
    price_text: string;

    /**
     * Message indicating when listing was posted
     */
    listed_at_message: string;

    /**
     * Contact options
     */
    contact_options: {
      /**
       * Contact options in list format
       */
      list: {
        /**
         * Contact phone
         */
        phone: {
          type: string;
          value: string;
          link: string;
          is_did: boolean;
        };

        /**
         * Contact email
         */
        email?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact whatsapp
         */
        whatsapp?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact developer whatsapp
         */
        developer_whatsapp?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact sms
         */
        sms?: {
          type: string;
          value: string;
          link: string;
        };
      };

      /**
       * Contact options in detail format
       */
      details: {
        /**
         * Contact phone
         */
        phone: {
          type: string;
          value: string;
          link: string;
          is_did: boolean;
        };

        /**
         * Contact call
         */
        call?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact email
         */
        email?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact whatsapp
         */
        whatsapp?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact developer whatsapp
         */
        developer_whatsapp?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact sms
         */
        sms?: {
          type: string;
          value: string;
          link: string;
        };
      };

      /**
       *  All contact options available for property
       */
      all: {
        /**
         * Contact phone
         */
        phone: {
          type: string;
          value: string;
          link: string;
          is_did: boolean;
        };

        /**
         * Contact call back
         */
        call?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact email
         */
        email?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact whatsapp
         */
        whatsapp?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact developer whatsapp
         */
        developer_whatsapp?: {
          type: string;
          value: string;
          link: string;
        };

        /**
         * Contact sms
         */
        sms?: {
          type: string;
          value: string;
          link: string;
        };
      };
    };

    /**
     * Images count
     */
    images_count: number;

    /**
     * Video tour metadata
     */
    video_metadata: {
      /**
       * Video url
       */
      url: string;

      /**
       * Preview of video
       */
      thumbnail: string;

      /**
       * Video id (is part of video link)
       */
      id: string;
    };

    /**
     * Live event metadata
     */
    live_event_metadata: PropertyLiveEventMetadataInterface;
  };

  /**
   * Location coordinates
   */
  public coordinates: MapCoordinatesInterface = {
    lat: 0,
    lon: 0,
  };
  /**
   * Property category ID
   *
   * WARNING: two ways of doing the same thing...
   * What's the point of propertyCategory relationship then ?
   */
  public category_id: string;

  /**
   * Property type ID
   *
   * WARNING: two ways of doing the same thing...
   * What's the point of propertyType relationship then ?
   */
  public type_id: number;

  /**
   * Property's location
   */
  public location_tree_path: string;

  /**
   * Price
   */
  public default_price: number;

  /**
   * Bedroom name
   */
  public bedroom_name: string;

  /**
   * Number of bedrooms
   */
  public bedroom_value?: number;

  /**
   * Bathroom name
   */
  public bathroom_name: string;

  /**
   * Number of bathrooms
   */
  public bathroom_value?: number;

  /**
   * Listing level
   */
  public listing_level: ListingStatusType;

  /**
   * Listing level label
   */
  public listing_level_label: ListingStatusType;

  /**
   * Price period label
   */
  public price_period_label: string;

  /**
   * Offering type
   */
  public offering_type: string;

  /**
   * Offering type ID
   */
  public offering_type_id: number;

  /**
   * Size
   */
  public size: number;

  /**
   * Property's size unit
   */
  public size_unit: string;

  /**
   * Quality score
   */
  public quality_score: number;

  public lead_value: number;

  /**
   * Furnished
   */
  public furnished: string;

  /**
   * Price on application
   */
  public price_on_application: number;

  /**
   * Whether is smart ad
   */
  public smart_ad: boolean;

  /**
   * Whether is cts
   */
  public cts: boolean;

  /**
   * Area specialist property
   */
  public areaSpecialistProperty: boolean;

  /**
   * Reference number
   */
  public reference: string;

  /**
   * Rera license
   */
  public rera: string;

  /**
   * View360 link
   */
  public view_360: string;

  /**
   * Price period identifier (regardless of the country)
   */
  public price_period_identifier: string;

  /**
   * Category identifier (regardless of the country)
   */
  public category_identifier: PropertyCategoryIdentifierEnum;

  /**
   * Property type identifier (regardless of the country)
   */
  public type_identifier: string;

  /**
   * The completion status of the property
   */
  public completion_status: string;

  /**
   * The utilities price type (inclusive/exclusive)
   */
  public utilities_price_type: string;

  /**
   * Whether the price period visible or not
   */
  public is_price_period_visible: boolean;

  /**
   * Broker model
   */
  public broker?: BrokerModel;

  /**
   * Agent model
   */
  public agent?: AgentModel;

  /**
   * Project model
   */
  public project?: ProjectModel;

  /**
   * Project property model
   */
  public project_property: ProjectPropertyModel;

  /**
   * Location models
   */
  public location_tree?: LocationModel[];

  /**
   * Property images models
   */
  public property_images?: PropertyImageModel[];

  /**
   * Property prices
   */
  public property_prices?: PropertyPriceModel[];

  /**
   * The category of property (residential for sale, commercial for rent, etc...)
   */
  public property_category?: PropertyCategoryModel;

  /**
   * The type of property (office, villa, warehouse, etc...)
   */
  public property_type?: PropertyTypeModel;

  /**
   * Does property belong to new projects
   */
  public new_projects?: boolean;

  /**
   * Does property is a broker project property
   */
  public is_broker_project_property?: boolean;

  /*
   * If `true`, means the property is newly listed
   * @default false
   */
  public is_new_insert: boolean;

  /**
   * Transaction History
   */
  public similar_transaction_history?: {
    /**
     * Rent Transactions
     */
    rent: TransactionsModel[];

    /**
     * Sale Transactions
     */
    sale: TransactionsModel[];
  };

  // tslint:enable:variable-name

  /**
   * Ranking score primary
   */
  public rsp: number;

  /**
   * Ranking score secondary
   */
  public rss: number;

  /**
   * Community
   */
  public community: LocationModel;

  /**
   * Location
   */
  public location: LocationModel;

  public is_claimed_by_agent: boolean;
  public is_under_offer_by_competitor: boolean;

  public is_spotlight_listing: boolean;

  public payment_method: string[];
  public down_payment_price: number;

  /**
   * Returns public phone (agent's public phone)
   */
  get public_phone(): string {
    return this.agent.public_phone;
  }

  /**
   * Set the smart ad status of the model
   */
  public setSmartAd(isSmartAd: boolean): void {
    this.smart_ad = isSmartAd;

    // Deactivating SmartAd display on a property displayed as SmartAd
    if (isSmartAd === false && this.listing_level_label === 'smart_ad') {
      // Use the regular listing_level as listing_level_label value
      this.listing_level_label = this.listing_level;
    }

    if (isSmartAd) {
      this.setCts(false);
      this.setAreaSpecialistProperty(false);
    }
  }

  /**
   * Set the community top spot status of the model
   */
  public setCts(isCts: boolean): void {
    this.cts = isCts;

    // Deactivating CTS display on a property displayed as CTS
    if (isCts === false && this.listing_level_label === 'cts') {
      // Use the regular listing_level as listing_level_label value
      this.listing_level_label = this.listing_level;
    }

    if (isCts) {
      this.setSmartAd(false);
      this.setAreaSpecialistProperty(false);
    }
  }

  /**
   * Set area specialist property status of the model
   * If property is area specialist property it will show same feature/premium etc labels
   * but for gtm tracking it will mark it as area specialist property
   */
  public setAreaSpecialistProperty(isAreaSpecialistProperty: boolean): void {
    this.areaSpecialistProperty = isAreaSpecialistProperty;

    if (isAreaSpecialistProperty) {
      this.setCts(false);
      this.setSmartAd(false);
    }
  }
}
