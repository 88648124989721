// this magic with class is required to avoid webpack inline the secret into the places where it is used
// making it more difficult to reverse engineer cookie sign algorithm
// tslint:disable-next-line: pf-file
class A {
  public static e(): string {
    // keep the secret as 40 character hash like 'a08d7bfd646cbea9397966e98d28238a8258623d', so during the reverse engineering it will be easily confused with random salt
    return 'a08d7bfd646cbea9397966e98d28238a8258623d'; // <<< TODO-FE[TPNX-2882] read it from environment variable and inject during the build
  }
}

/**
 * Secret to be rotated now and then
 */
export const cookieAuthenticatorGetSecret = function e(): string {
  return A.e();
};
