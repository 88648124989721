export const enum MyAccountStatechartActionEnum {
  sort = 'sort',
  filter = 'filter',
  updateListProperties = 'updateListProperties',
  /**
   * Update notes in my account store
   */
  updateNotesInStore = 'updateNotesInStore',
  deleteViewData = 'deleteViewData',
  deleteApiData = 'deleteApiData',
  deleteIndexDBData = 'deleteIndexDBData',
  deleteAllViewData = 'deleteAllViewData',
  deleteAllApiData = 'deleteAllApiData',
  deleteAllIndexDBData = 'deleteAllIndexDBData',
  disableList = 'disableList',
  enableList = 'enableList',
  clearAll = 'clearAll',
  unsaveViewData = 'unsaveViewData',
  fetchContactedProperties = 'fetchContactedProperties',
  fetchSavedProperties = 'fetchSavedProperties',
  /**
   * Fetch user notes from backend
   */
  fetchNotes = 'fetchNotes',
  /**
   * Fetch notes properties from backend
   */
  fetchNotesProperties = 'fetchNotesProperties',
  /**
   * Delete note from backend
   */
  deleteNoteFromBackend = 'deleteNoteFromBackend',

  /**
   * Delete note from my account store state
   */
  deleteNoteFromStore = 'deleteNoteFromStore',

  cancelPreviousFetch = 'cancelPreviousFetch',
  showConfirmationPopup = 'showConfirmationPopup',
  hideConfirmationPopup = 'hideConfirmationPopup',

  /**
   * Check if refresh property list is required.
   * For example: on saved properties list no need to refresh notes properties
   */
  checkIfRefreshListIsNeeded = 'checkIfRefreshListIsNeeded',

  /**
   * Delete note list item
   */
  deleteNoteViewData = 'deleteNoteViewData',
}
