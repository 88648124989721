import { cookieAuthenticatorGenerateHash } from './generate-hash';
import { cookieAuthenticatorGenerateSalt } from './generate-salt';
import { cookieAuthenticatorMixSaltAndHash } from './mix-salt-and-hash';

// this magic with class is required to avoid webpack inline the secret into the places where it is used
// making it more difficult to reverse engineer cookie sign algorithm
// tslint:disable-next-line: pf-file
class A {
  public static e(randomSalt: string, validationCookie: string): string {
    return cookieAuthenticatorMixSaltAndHash(randomSalt, cookieAuthenticatorGenerateHash(randomSalt, validationCookie));
  }
}

/**
 * Set validation cookie check that will be used by the server to verify that request is comming from this javascript build
 * Make scrappers life harder
 * @param validationCookie
 */
export const signValidationCookie = function e(validationCookie: string): string {
  const randomSalt = cookieAuthenticatorGenerateSalt();

  return A.e(randomSalt, validationCookie);
};
