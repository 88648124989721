import { EventEmitterInterface } from 'pf-frontend-common/src/module/event/emitter.interface';

import { AdSlotInterface } from 'common/module/ad/slot.interface';
import { AdStrategyEvent } from 'common/module/ad/strategy.event';
import { AdStrategyInterface } from 'common/module/ad/strategy.interface';
import { AdTargetingInterface } from 'common/module/ad/targeting.interface';
import { AdUnitInterface } from 'common/module/ad/unit.interface';
import { AdServiceEvent } from 'common/service/ad/service.event';
import { AdServiceInterface } from 'common/service/ad/service.interface';

export class AdStore implements AdServiceInterface {
  /**
   * Ad strategy
   */
  private adStrategy: AdStrategyInterface;

  /**
   * Constructor
   */
  constructor(private eventEmitter: EventEmitterInterface) {}

  /**
   * @inheritDoc
   */
  public getEventEmitter(): EventEmitterInterface {
    return this.eventEmitter;
  }

  /**
   * @inheritDoc
   */
  public setStrategy(adStrategy: AdStrategyInterface): void {
    if (this.adStrategy) {
      this.adStrategy.getEventEmitter().removeListener(AdStrategyEvent.slotRenderFinished, this.onSlotRenderFinished);
    }

    this.adStrategy = adStrategy;

    this.adStrategy.getEventEmitter().addListener(AdStrategyEvent.slotRenderFinished, this.onSlotRenderFinished);

    this.adStrategy.getEventEmitter().addListener(AdStrategyEvent.slotLoaded, this.onSlotLoaded);
  }

  /**
   * @inheritDoc
   */
  public setTargeting(targeting: AdTargetingInterface): void {
    this.adStrategy.setTargeting(targeting);
  }

  /**
   * @inheritDoc
   */
  public enableLazyLoad(): void {
    this.adStrategy.enableLazyLoad();
  }

  /**
   * @inheritDoc
   */
  public registerAdQueue(queueItems: Array<[string, AdUnitInterface]>): number {
    return this.adStrategy.registerAdQueue(queueItems);
  }

  /**
   * @inheritDoc
   */
  public renderAdQueue(queueId: number): void {
    this.adStrategy.renderAdQueue(queueId);
  }

  /**
   * @inheritDoc
   */
  public registerAdUnit(adUnit: AdUnitInterface, containerId: string): void {
    this.adStrategy.registerAdUnit(adUnit, containerId);
  }

  /**
   * @inheritDoc
   */
  public renderAdUnit(adUnit: AdUnitInterface, containerId: string): void {
    this.adStrategy.renderAdUnit(adUnit, containerId);
  }

  /**
   * @inheritDoc
   */
  public removeAdUnit(containerId: string): void {
    this.adStrategy.removeAdUnit(containerId);
  }

  /**
   * @inheritDoc
   */
  public refreshAllAds(): void {
    this.adStrategy.refreshAllAds();
  }

  /**
   * Slot render finished
   */
  private onSlotRenderFinished = (slot: AdSlotInterface): void => {
    this.getEventEmitter().emit(AdServiceEvent.slotRenderFinished, slot);
  };

  /**
   * Slot loaded
   */
  private onSlotLoaded = (slot: AdSlotInterface): void => {
    this.getEventEmitter().emit(AdServiceEvent.slotLoaded, slot);
  };
}
