import { PromiseCancelableInterface } from 'common/module/promise/cancelable.interface';

export class PromiseCancelable<T> implements PromiseCancelableInterface<T> {
  /**
   * Whether promise is been canceled
   */
  private hasCanceled: boolean = false;

  /**
   * Cancelable promise
   */
  private promise: Promise<void | T>;

  /**
   * Constructor
   */
  constructor(promise: Promise<T>) {
    this.promise = new Promise<T>((resolve, reject) =>
      promise
        .then((val) => (this.hasCanceled ? reject({ hasCanceled: true }) : resolve(val)))
        .catch((err) => (this.hasCanceled ? reject({ hasCanceled: true }) : reject(err)))
    ).catch((err) => {
      if (err && !err.hasCanceled) {
        throw err;
      }
    });
  }

  /**
   * @inheritDoc
   */
  public cancel(): void {
    this.hasCanceled = true;
  }

  /**
   * @inheritDoc
   */
  public getPromise(): Promise<void | T> {
    return this.promise;
  }
}
