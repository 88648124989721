import { CountryAreaUnitEnum } from 'common/data/country/area-unit.enum';
import { i18nTranslate } from 'common/helper/i18n/translate';
import { numberFormat } from 'common/helper/number/format';
import { RangeSelectorValueInterface } from 'library/range-selector/value.interface';

export const areaSelectorComputeText = (value: RangeSelectorValueInterface, unit: CountryAreaUnitEnum): string => {
  const { min, max } = value;
  const text: string[] = [];

  if (!min && !max) {
    // reset text
    return '';
  } else {
    const unformattedMinValue = min.replace(/,/g, '');
    const unformattedMaxValue = max.replace(/,/g, '');
    const minInt = parseInt(unformattedMinValue, 10);
    const maxInt = parseInt(unformattedMaxValue, 10);

    if (!min && !!max) {
      // only max
      text.push(i18nTranslate('Up to'), numberFormat(maxInt));
    } else if (!!min && !max) {
      // only min
      text.push(i18nTranslate('From'), numberFormat(minInt));
    } else {
      text.push(i18nTranslate('From'), numberFormat(minInt), i18nTranslate('to'), numberFormat(maxInt));
    }
  }
  text.push(i18nTranslate(unit));

  return text.join(' ');
};
