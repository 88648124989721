import { i18nTranslate } from 'common/helper/i18n/translate';

import { BedAndBathSelectorOptionsInterface } from '../options.interface';

export const bedAndBathSelectorLabelSanitize = (value: BedAndBathSelectorOptionsInterface, postFix: string): string => {
  // If the label is 0, dont include the postfix.
  if (value.num === 0) {
    return i18nTranslate(value.text);
  }

  // If the label is 1, then remove the 's' from the postfix.
  let postFixGrammer = postFix;
  if (value.num === 1) {
    postFixGrammer = postFix.replace('s', '');
  }

  // replace count after translation.
  return i18nTranslate(`{count} ${postFixGrammer}`).replace('{count}', value.text);
};
