export enum FilterSettingsTypeEnum {
  /**
   * Search query
   */
  query = 'query',

  /**
   * Keywords
   */
  keyword = 'keyword',

  /**
   * Min and max bedroom
   */
  bedroom = 'bedroom',

  /**
   * Min and max bathroom
   */
  bathroom = 'bathroom',

  /**
   * Min and max price
   */
  price = 'price',

  /**
   * Min and max area
   */
  area = 'area',

  /**
   * Amenities
   */
  amenity = 'amenity',

  /**
   * Completion status
   */
  completionStatus = 'completion-status',

  /**
   * Utilities price type
   */
  utilitiesPriceType = 'utilities-price-type',

  /**
   * Virtual viewing
   */
  virtualViewing = 'virtual-viewing',

  /**
   * Order by
   */
  orderBy = 'order-by',

  /**
   * Furnishing
   */
  furnishing = 'furnishing',

  /**
   * Price type
   */
  priceType = 'price-type',

  /**
   * Property type
   */
  propertyType = 'property-type',

  /**
   * Category id
   */
  categoryId = 'category_id',

  /**
   * Location Ids
   */
  locationIds = 'location_ids',

  /**
   * Page limit
   */
  pageLimit = 'page_limit',

  /**
   * Current page number
   */
  pageNumber = 'page_number',

  /**
   * Is developer property
   */
  isDeveloperProperty = 'is_developer_property',

  /**
   * Min and Max installment years
   */
  installmentYears = 'installment-years',

  /**
   * Verified
   */
  verified = 'verified',

  trustedAgent = 'trusted-agent',
  listedWithin = 'listed_within',
  paymentMethods = 'payment_methods',
}
